import type { DictionaryType } from '@edapp/utils';

import type { ThomasPlayer } from './thomas-player';

/**
 * Singleton to keep references of thomas player.
 *
 * We need to access them at any point in time from maggie. We might wanna dispatch some actions or close it from the outside.
 *
 * !Note: Do not access the singleton object directly.
 */
const LESSON_PLAYERS: DictionaryType<ThomasPlayer | undefined> = {};

const hasPlayer = (id: string) => {
  return !!LESSON_PLAYERS[id];
};

const getPlayer = <T extends ThomasPlayer>(id: string): T => {
  const player = LESSON_PLAYERS[id];
  if (!player) {
    throw Error(`cannot find thomas lesson player for id: ${id}`);
  }

  return player as T;
};

const setPlayer = (id: string, player: ThomasPlayer) => {
  LESSON_PLAYERS[id] = player;
};

const deletePlayer = (id: string) => {
  delete LESSON_PLAYERS[id];
};

export const ThomasPlayerMemo = {
  hasPlayer,
  getPlayer,
  setPlayer,
  deletePlayer
};
