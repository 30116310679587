import { put } from 'redux-saga/effects';

import { AwardedCertificatesActions } from '@maggie/store/courseware/awarded-certificates/actions';
import { Flags } from '@maggie/store/feature-flags/constants';
import { FeatureFlagsSelectors } from '@maggie/store/feature-flags/selectors';

export function* handleDidNavigateToAwardedCertificates() {
  if (FeatureFlagsSelectors.getFlag(Flags.awardedCertificates)?.enabled) {
    yield put(AwardedCertificatesActions.fetchAllAwardedCertificates({ page: 1 }));
  } else {
    window.__router.navigate('home', {});
  }
}
