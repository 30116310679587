import { t } from 'i18next';

import { ReactLazyRetry } from '@maggie/core/dynamic-import/lazy-retry';
import { routePaths } from '@maggie/core/router/routes';
import type { RouteData, RouteName, RouteParams } from '@maggie/core/router/types';
import { ToolbarBehaviour } from '@maggie/core/router/types';
import { CollectionsSelectors } from '@maggie/store/courseware/collections/selectors';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import { PlaylistsSelectors } from '@maggie/store/courseware/playlists/selectors';
import { HomeSelectors } from '@maggie/store/home/selectors';
import { LeaderboardsSelectors } from '@maggie/store/leaderboards/selectors';
import { SocialLearningSelectors } from '@maggie/store/social-learning/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';
import { DocumentSelectors } from '@maggie/store/courseware/documents/selectors';
import { RapidRefreshSelectors } from '@maggie/store/courseware/rapid-refreshes/selectors';
import { DiscussionSelectors } from '@maggie/store/courseware/discussions/selectors';
import { ConferenceSelectors } from '@maggie/store/courseware/conferences/selectors';
import { AssessmentSelectors } from '@maggie/store/courseware/assessments/selectors';
import { ObservationSelectors } from '@maggie/store/courseware/observations/selectors';

const Login = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Login" */ '@maggie/containers/login/Login').then(module => ({
    default: module.Login
  }))
);

const Course = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Course" */ '@maggie/containers/course/Course').then(module => ({
    default: module.Course
  }))
);

const Discussion = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Discussion" */ '@maggie/containers/discussion/Discussion').then(
    module => ({
      default: module.Discussion
    })
  )
);

const Lesson = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Lesson" */ '@maggie/containers/lesson/Lesson').then(module => ({
    default: module.Lesson
  }))
);

const SignUp = ReactLazyRetry(() =>
  import(/* webpackChunkName: "SignUp" */ '@maggie/containers/sign-up/SignUp').then(module => ({
    default: module.SignUp
  }))
);

const SSOLogin = ReactLazyRetry(() =>
  import(/* webpackChunkName: "SSOLogin" */ '@maggie/containers/sso-login/SSOLogin').then(
    module => ({
      default: module.SSOLogin
    })
  )
);

const SSOLoginFaiulre = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "SSOLoginFailure" */ '@maggie/containers/sso-login-failure/SSOLoginFailure'
  ).then(module => ({
    default: module.SSOLoginFailure
  }))
);

const ForgotPassword = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ForgotPassword" */ '@maggie/containers/forgot-password/ForgotPassword'
  ).then(module => ({
    default: module.ForgotPassword
  }))
);

const MagicLink = ReactLazyRetry(() =>
  import(/* webpackChunkName: "MagicLink" */ '@maggie/containers/magic-link/MagicLink').then(
    module => ({
      default: module.MagicLink
    })
  )
);

const UserNotVerified = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "UserNotVerified" */ '@maggie/containers/user-not-verified/UserNotVerified'
  ).then(module => ({
    default: module.UserNotVerified
  }))
);

const ResetPassword = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ResetPassword" */ '@maggie/containers/reset-password/ResetPassword'
  ).then(module => ({
    default: module.ResetPassword
  }))
);

const Playlist = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Playlist" */ '@maggie/containers/playlist/Playlist').then(
    module => ({
      default: module.Playlist
    })
  )
);

const PlaylistDetailPlaceholder = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "PlaylistDetailPlaceholder" */ '@maggie/components/course/detail-view-placeholder/PlaylistDetailPlaceholder'
  ).then(module => ({
    default: module.PlaylistDetailPlaceholder
  }))
);

const CourseCollection = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "CourseCollection" */ '@maggie/containers/course-collection/CourseCollection'
  ).then(module => ({
    default: module.CourseCollection
  }))
);

const CourseCollectionDetailPlaceholder = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "CourseCollectionDetailPlaceholder" */ '@maggie/components/course/detail-view-placeholder/CourseCollectionDetailPlaceholder'
  ).then(module => ({
    default: module.CourseCollectionDetailPlaceholder
  }))
);

const Performance = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Performance" */ '@maggie/containers/performance/Performance').then(
    module => ({ default: module.Performance })
  )
);

const Achievements = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "Achievements" */ '@maggie/containers/achievements/Achievements'
  ).then(module => ({ default: module.Achievements }))
);

const Brainboost = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Brainboost" */ '@maggie/containers/brainboost/Brainboost').then(
    module => ({
      default: module.Brainboost
    })
  )
);

const Game = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Game" */ '@maggie/containers/game/Game').then(module => ({
    default: module.Game
  }))
);

const PastWinners = ReactLazyRetry(() =>
  import(/* webpackChunkName: "PastWinners" */ '@maggie/containers/past-winners/PastWinners').then(
    module => ({ default: module.PastWinners })
  )
);

const UpcomingPrizes = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "UpcomingPrizes" */ '@maggie/containers/upcoming-prizes/UpcomingPrizes'
  ).then(module => ({
    default: module.UpcomingPrizes
  }))
);

const Claim = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Claim" */ '@maggie/containers/claim/Claim').then(module => ({
    default: module.Claim
  }))
);

const Congratulations = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "Congratulations" */ '@maggie/containers/congratulations/Congratulations'
  ).then(module => ({
    default: module.Congratulations
  }))
);

const Profile = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Profile" */ '@maggie/containers/profile/Profile').then(module => ({
    default: module.Profile
  }))
);

const Leaderboards = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "Leaderboards" */ '@maggie/containers/leaderboards/Leaderboards'
  ).then(module => ({ default: module.Leaderboards }))
);

const Leaderboard = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Leaderboard" */ '@maggie/containers/leaderboard/Leaderboard').then(
    module => ({ default: module.Leaderboard })
  )
);

const ContentLibrary = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ContentLibrary" */ '@maggie/containers/content-library/ContentLibrary'
  ).then(module => ({
    default: module.ContentLibrary
  }))
);

const ContentLibraryCourse = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ContentLibraryCourse" */ '@maggie/containers/content-library-course/ContentLibraryCourse'
  ).then(module => ({
    default: module.ContentLibraryCourse
  }))
);

const ContentLibrarySection = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ContentLibrarySection" */ '@maggie/containers/content-library-section/ContentLibrarySection'
  ).then(module => ({
    default: module.ContentLibrarySection
  }))
);

const MicroCredentials = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "MicroCredentials" */ '@maggie/containers/micro-credentials/MicroCredentials'
  ).then(module => ({
    default: module.MicroCredentials
  }))
);

const Briefcase = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Briefcase" */ '@maggie/containers/briefcase/Briefcase').then(
    module => ({
      default: module.Briefcase
    })
  )
);

const RapidRefresh = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "RapidRefresh" */ '@maggie/containers/rapid-refresh/RapidRefresh'
  ).then(module => ({
    default: module.RapidRefresh
  }))
);

const Conference = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Conference" */ '@maggie/containers/conference/Conference').then(
    module => ({
      default: module.Conference
    })
  )
);

const Thread = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Thread" */ '@maggie/containers/thread/Thread').then(module => ({
    default: module.Thread
  }))
);

const Submission = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Submission" */ '@maggie/containers/submission/Submission').then(
    module => ({
      default: module.Submission
    })
  )
);

const Assessment = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Assessment" */ '@maggie/containers/assessment/Assessment').then(
    module => ({
      default: module.Assessment
    })
  )
);

const AccountSettings = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "AccountSettings" */ '@maggie/containers/account-settings/AccountSettings'
  ).then(module => ({
    default: module.AccountSettings
  }))
);

const ChangePassword = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ChangePassword" */ '@maggie/containers/change-password/ChangePassword'
  ).then(module => ({
    default: module.ChangePassword
  }))
);

const LessonPlay = ReactLazyRetry(() =>
  import(/* webpackChunkName: "LessonPlay" */ '@maggie/containers/lesson-play/LessonPlay').then(
    module => ({ default: module.LessonPlay })
  )
);

const LessonReview = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "LessonReview" */ '@maggie/containers/lesson-review/LessonReview'
  ).then(module => ({ default: module.LessonReview }))
);

const Register = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Register" */ '@maggie/containers/register/Register').then(
    module => ({
      default: module.Register
    })
  )
);

const SocialLearningSlide = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "SocialLearningSlide" */ '@maggie/containers/social-learning/SocialLearningSlide'
  ).then(module => ({
    default: module.SocialLearningSlide
  }))
);

const SocialLearningLesson = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "SocialLearningLesson" */ '@maggie/containers/social-learning/SocialLearningLesson'
  ).then(module => ({
    default: module.SocialLearningLesson
  }))
);

const CourseDetailPlaceholder = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "CourseDetailPlaceholder" */ '@maggie/components/course/detail-view-placeholder/CourseDetailPlaceholder'
  ).then(module => ({
    default: module.CourseDetailPlaceholder
  }))
);

const ThreadDetailPlaceholder = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ThreadDetailPlaceholder" */ '@maggie/components/course/detail-view-placeholder/ThreadDetailPlaceholder'
  ).then(module => ({
    default: module.ThreadDetailPlaceholder
  }))
);

const SubmissionDetailPlaceholder = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "SubmissionDetailPlaceholder" */ '@maggie/components/course/detail-view-placeholder/SubmissionDetailPlaceholder'
  ).then(module => ({
    default: module.SubmissionDetailPlaceholder
  }))
);

const Observation = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Observation" */ '@maggie/containers/observation/Observation').then(
    module => ({ default: module.Observation })
  )
);

const ObservationResults = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ObservationResults" */ '@maggie/containers/observation-results/ObservationResults'
  ).then(module => ({
    default: module.ObservationResults
  }))
);

const ObservationFeedback = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ObservationFeedback" */ '@maggie/containers/observation-feedback/ObservationFeedback'
  ).then(module => ({
    default: module.ObservationFeedback
  }))
);

const ObservationDetailPlaceholder = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "ObservationDetailPlaceholder" */

    '@maggie/components/course/detail-view-placeholder/ObservationDetailPlaceholder'
  ).then(module => ({ default: module.ObservationDetailPlaceholder }))
);

const AwardedCertificates = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "AwardedCertificates" */ '@maggie/containers/awarded-certificates/AwardedCertificates'
  ).then(module => ({
    default: module.AwardedCertificates
  }))
);

const Home = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Home" */ '@maggie/containers/home/Home').then(module => {
    return { default: module.Home };
  })
);

const HomeSection = ReactLazyRetry(() =>
  import(/* webpackChunkName: "HomeSection" */ '@maggie/containers/home-section/HomeSection').then(
    module => ({ default: module.HomeSection })
  )
);

const GroupTraining = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "GroupTraining" */ '@maggie/containers/group-training/GroupTraining'
  ).then(module => ({
    default: module.GroupTraining
  }))
);

const LinkAccounts = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "LinkAccounts" */ '@maggie/containers/link-accounts/LinkAccounts'
  ).then(module => ({
    default: module.LinkAccounts
  }))
);

const Notifications = ReactLazyRetry(() =>
  import(/* webpackChunkName: "Notifications" */ '@maggie/containers/notifications').then(
    ({ Notifications }) => ({ default: Notifications })
  )
);

const Search = ReactLazyRetry(() =>
  import(
    /* webpackChunkName: "Search" */
    '@maggie/containers/search/Search'
  ).then(module => ({
    default: module.Search
  }))
);

export const appRoutes: Record<RouteName, RouteData> = {
  // #region Public Routes
  login: {
    component: Login,
    path: routePaths.login.template(),
    stackIndex: 0,
    locked: true,
    transitionEffect: false,
    splitViewLayout: {
      main: {
        size: 'lg',
        getToolbarBehaviour: () => ToolbarBehaviour.none
      },
      parents: []
    }
  },
  signup: {
    component: SignUp,
    path: routePaths.signup.template(),
    stackIndex: 0,
    locked: true,
    transitionEffect: false,
    splitViewLayout: {
      main: {
        size: 'lg',
        getToolbarBehaviour: () => ToolbarBehaviour.none
      },
      parents: []
    }
  },
  ssoLogin: {
    component: SSOLogin,
    path: routePaths.ssoLogin.template(),
    stackIndex: 0,
    locked: true,
    transitionEffect: false,
    splitViewLayout: {
      main: {
        size: 'lg',
        getToolbarBehaviour: () => ToolbarBehaviour.none
      },
      parents: []
    }
  },
  ssoLoginFailure: {
    component: SSOLoginFaiulre,
    path: routePaths.ssoLoginFailure.template(),
    stackIndex: 1,
    locked: true,
    viewLayout: {}
  },
  forgotPassword: {
    component: ForgotPassword,
    path: routePaths.forgotPassword.template(),
    stackIndex: 0,
    locked: true
  },
  magicLink: {
    component: MagicLink,
    path: routePaths.magicLink.template(),
    stackIndex: 0,
    locked: true
  },
  userNotVerified: {
    component: UserNotVerified,
    path: routePaths.userNotVerified.template(),
    stackIndex: 0,
    locked: true
  },
  resetPassword: {
    component: ResetPassword,
    path: routePaths.resetPassword.template(),
    stackIndex: 0,
    locked: true
  },
  register: {
    component: Register,
    splitViewLayout: {
      main: {
        size: 'lg',
        getToolbarBehaviour: () => ToolbarBehaviour.none
      },
      parents: []
    },
    path: routePaths.register.template(),
    stackIndex: 0,
    locked: true
  },
  // #endregion

  // #region Top Navigation
  home: {
    component: Home,
    path: routePaths.home.template(),
    stackIndex: 0,
    viewLayout: {
      getViewHeadType: state =>
        UserSelectors.getProvisioningType(state) === 'external'
          ? 'none'
          : { mobile: 'fixed', desktop: 'none' },
      getTitle: () => t('home.courseware', { ns: 'learners-experience' })
    }
  },
  homeSection: {
    component: HomeSection,
    path: routePaths.homeSection.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: (state, routeParams) =>
        HomeSelectors.getHomeSectionTitle(state, routeParams as RouteParams<'homeSection'>)
    }
  },
  search: {
    component: Search,
    path: routePaths.search.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: state =>
        UserSelectors.getProvisioningType(state) === 'external'
          ? 'fixed'
          : { mobile: 'fixed', desktop: 'none' },
      getTitle: () => t('home.search', { ns: 'learners-experience' })
    }
  },
  profile: {
    component: Profile,
    path: routePaths.profile.template(),
    stackIndex: 0,
    viewLayout: {
      getTitle: () => t('profile.title', { ns: 'learners-experience' })
    }
  },
  performance: {
    component: Performance,
    path: routePaths.performance.template(),
    get stackIndex() {
      const isNewNavbar = UserSelectors.hasNewNavbarEnabled(window.__store.getState());
      return isNewNavbar ? 1 : 0;
    },
    viewLayout: {
      getTitle: () => t('performance.title', { ns: 'learners-experience' })
    }
  },
  game: {
    component: Game,
    path: routePaths.game.template(),
    get stackIndex() {
      const isNewNavbar = UserSelectors.hasNewNavbarEnabled(window.__store.getState());
      return isNewNavbar ? 1 : 0;
    },
    viewLayout: {
      getTitle: () => t('game.title', { ns: 'learners-experience' })
    }
  },
  leaderboards: {
    component: Leaderboards,
    path: routePaths.leaderboards.template(),
    get stackIndex() {
      const isNewNavbar = UserSelectors.hasNewNavbarEnabled(window.__store.getState());
      const isUxp = UserSelectors.getProvisioningType(window.__store.getState()) === 'external';
      return isNewNavbar || isUxp ? 1 : 0;
    },
    viewLayout: {
      getTitle: () => t('menu.item.leaderboards.title', { ns: 'learners-experience' })
    }
  },
  brainBoost: {
    component: Brainboost,
    path: routePaths.brainBoost.template(),
    stackIndex: 1,
    locked: true,
    splitViewLayout: {
      parents: [],
      main: {
        size: 'lg',
        getTitle: () => t('brain-boost.title', { ns: 'learners-experience' }),
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  // #endregion

  // #region Leaderboards
  leaderboard: {
    component: Leaderboard,
    path: routePaths.leaderboard.template(),
    get stackIndex() {
      const isNewNavbar = UserSelectors.hasNewNavbarEnabled(window.__store.getState());
      return isNewNavbar ? 2 : 1;
    },
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: LeaderboardsSelectors.getTitle
    }
  },
  // #endregion

  // #region Game
  claim: {
    component: Claim,
    path: routePaths.claim.template(),
    stackIndex: 0,
    locked: true
  },
  congratulations: {
    component: Congratulations,
    path: routePaths.congratulations.template(),
    stackIndex: 0,
    locked: true
  },
  pastWinners: {
    component: PastWinners,
    path: routePaths.pastWinners.template(),
    stackIndex: 1,
    viewLayout: {
      getTitle: () => t('past-winners.title', { ns: 'learners-experience' })
    }
  },
  upcomingPrizes: {
    component: UpcomingPrizes,
    path: routePaths.upcomingPrizes.template(),
    stackIndex: 1,
    viewLayout: {
      getTitle: () => t('upcoming-prizes.title', { ns: 'learners-experience' })
    }
  },
  // #endregion

  // #region Profile
  achievements: {
    component: Achievements,
    path: routePaths.achievements.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: () => t('achievements.title', { ns: 'learners-experience' })
    }
  },
  accountSettings: {
    component: AccountSettings,
    path: routePaths.accountSettings.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: () => t('profile.account-settings', { ns: 'learners-experience' })
    }
  },
  awardedCertificates: {
    component: AwardedCertificates,
    path: routePaths.awardedCertificates.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: () => t('certificates.awarded-certs-title', { ns: 'learners-experience' })
    }
  },
  observationResults: {
    component: ObservationResults,
    path: routePaths.observationResults.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: () => t('observations.resultsTitle', { ns: 'learners-experience' })
    }
  },
  // #endregion

  // #region Inside Account Settings
  linkAccounts: {
    component: LinkAccounts,
    path: routePaths.linkAccounts.template(),
    stackIndex: 2,
    locked: true
  },
  changePassword: {
    component: ChangePassword,
    path: routePaths.changePassword.template(),
    stackIndex: 2,
    locked: true
  },
  // #endregion

  // #region Performance
  microCredentials: {
    component: MicroCredentials,
    path: routePaths.microCredentials.template(),
    stackIndex: 2,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: () => t('micro-credentials.title', { ns: 'learners-experience' })
    }
  },
  // #endregion

  // #region Courseware
  playlist: {
    path: routePaths.playlist.template(),
    stackIndex: 1,
    locked: true,
    component: Playlist,
    splitViewLayout: {
      parents: [],
      getDetailPlaceholder: () => PlaylistDetailPlaceholder,
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getTitle: PlaylistsSelectors.getTitle,
        getToolbarTextColor: PlaylistsSelectors.getTextColor,
        getToolbarBackground: PlaylistsSelectors.getBackground
      }
    }
  },
  rapidRefresh: {
    component: RapidRefresh,
    path: routePaths.rapidRefresh.template(),
    stackIndex: 1,
    locked: true,
    splitViewLayout: {
      parents: [],
      main: {
        size: 'lg',
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getTitle: RapidRefreshSelectors.getTitle
      }
    }
  },
  courseCollection: {
    path: routePaths.courseCollection.template(),
    stackIndex: 1,
    locked: true,
    component: CourseCollection,
    splitViewLayout: {
      parents: [],
      getDetailPlaceholder: () => CourseCollectionDetailPlaceholder,
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getTitle: CollectionsSelectors.getTitle,
        getToolbarTextColor: CollectionsSelectors.getTextColor,
        getToolbarBackground: CollectionsSelectors.getBackground
      }
    }
  },
  course: {
    path: routePaths.course.template(),
    stackIndex: 2,
    locked: true,
    component: Course,
    splitViewLayout: {
      parents: ['courseCollection', 'playlist'],
      getDetailPlaceholder: s =>
        CourseSelectors.getCourseDetailPlaceholder(s, CourseDetailPlaceholder),
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getTitle: CourseSelectors.getTitle,
        getToolbarTextColor: CourseSelectors.getTextColor,
        getToolbarBackground: CourseSelectors.getBackground
      }
    }
  },
  briefcaseDocument: {
    component: Briefcase,
    locked: true,
    path: routePaths.briefcaseDocument.template(),
    stackIndex: 3,
    splitViewLayout: {
      parents: ['course'],
      main: {
        getTitle: DocumentSelectors.getTitle,
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  lesson: {
    path: routePaths.lesson.template(),
    stackIndex: 3,
    locked: true,
    component: Lesson,
    splitViewLayout: {
      parents: ['course'],
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getTitle: LessonSelectors.getTitle,
        getToolbarTextColor: LessonSelectors.getTextColor
      }
    }
  },
  lessonPlay: {
    component: LessonPlay,
    path: routePaths.lessonPlay.template(),
    stackIndex: 5,
    locked: true,
    splitViewLayout: {
      parents: [],
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.none
      }
    }
  },
  lessonReview: {
    component: LessonReview,
    path: routePaths.lessonReview.template(),
    stackIndex: 4,
    locked: true,
    splitViewLayout: {
      parents: [],
      main: {
        getToolbarBehaviour: state =>
          // If contains error, display toolbar so user can press the back button
          LessonSelectors.getFetchErrorCode(state) || CourseSelectors.getCourseSyncErrorCode(state)
            ? ToolbarBehaviour.fixed
            : ToolbarBehaviour.none
      }
    }
  },
  discussion: {
    component: Discussion,
    path: routePaths.discussion.template(),
    stackIndex: 3,
    locked: true,
    splitViewLayout: {
      parents: ['course'],
      getDetailPlaceholder: () => ThreadDetailPlaceholder,
      main: {
        getTitle: DiscussionSelectors.getTitle,
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  conference: {
    path: routePaths.conference.template(),
    stackIndex: 3,
    locked: true,
    component: Conference,
    splitViewLayout: {
      parents: ['course'],
      main: {
        getTitle: ConferenceSelectors.getTitle,
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  assignment: {
    path: routePaths.assignment.template(),
    stackIndex: 3,
    locked: true,
    component: Assessment,
    splitViewLayout: {
      parents: ['course'],
      getDetailPlaceholder: () => SubmissionDetailPlaceholder,
      main: {
        getTitle: AssessmentSelectors.getTitle,
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  submission: {
    component: Submission,
    path: routePaths.submission.template(),
    stackIndex: 4,
    locked: true,
    splitViewLayout: {
      parents: ['assignment'],
      main: {
        getTitle: AssessmentSelectors.getTitle,
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  thread: {
    component: Thread,
    path: routePaths.thread.template(),
    stackIndex: 4,
    locked: true,
    splitViewLayout: {
      parents: ['discussion'],
      main: {
        getTitle: DiscussionSelectors.getTitle,
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  socialLearning: {
    component: SocialLearningLesson,
    locked: true,
    splitViewLayout: {
      parents: [],
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getTitle: SocialLearningSelectors.getTitle,
        getToolbarTextColor: SocialLearningSelectors.getTextColor,
        getToolbarBackground: SocialLearningSelectors.getBackground
      }
    },
    path: routePaths.socialLearning.template(),
    stackIndex: 4
  },
  socialLearningSlide: {
    component: SocialLearningSlide,
    path: routePaths.socialLearningSlide.template(),
    stackIndex: 5,
    locked: true,
    splitViewLayout: {
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getToolbarTextColor: () => 'black'
      },
      parents: ['socialLearning']
    }
  },
  observation: {
    component: Observation,
    path: routePaths.observation.template(),
    stackIndex: 3,
    locked: true,
    splitViewLayout: {
      parents: ['course'],
      getDetailPlaceholder: () => ObservationDetailPlaceholder,
      main: {
        getTitle: ObservationSelectors.getSelectedObservationTitle,
        getToolbarBehaviour: () => ToolbarBehaviour.fixed
      }
    }
  },
  observationFeedback: {
    component: ObservationFeedback,
    path: routePaths.observationFeedback.template(),
    stackIndex: 4,
    locked: true,
    splitViewLayout: {
      parents: ['observation'],
      main: {
        getToolbarBehaviour: () => ToolbarBehaviour.fixed,
        getTitle: () => t('observations.feedback.title', { ns: 'learners-experience' })
      }
    }
  },
  groupTraining: {
    component: GroupTraining,
    path: routePaths.groupTraining.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      viewHeadComponents: [],
      getTitle: (s, p: RouteParams<'groupTraining'>) =>
        CourseSelectors.getCourse(p.courseId, s)?.title || ''
    }
  },
  //#endregion

  // #region Content Library
  contentLibrary: {
    component: ContentLibrary,
    path: routePaths.contentLibrary.template(),
    stackIndex: 0,
    locked: true
  },
  contentLibrarySection: {
    component: ContentLibrarySection,
    path: routePaths.contentLibrarySection.template(),
    stackIndex: 0,
    locked: true
  },
  contentLibraryCourse: {
    component: ContentLibraryCourse,
    path: routePaths.contentLibraryCourse.template(),
    stackIndex: 0,
    locked: true
  },
  // #endregion

  // #region Notifications
  notifications: {
    component: Notifications,
    path: routePaths.notifications.template(),
    stackIndex: 1,
    viewLayout: {
      getViewHeadType: () => 'fixed',
      getTitle: () => t('notifications.title', { ns: 'learners-experience' })
    }
  },
  // #endregion

  // #region Redirection routes
  launchLesson: {
    component: () => null,
    path: routePaths.launchLesson.template(),
    stackIndex: 0,
    locked: true
  },
  /**
   *  @deprecated - redirects to `virtual-classroom/:conferenceId`
   */
  oldConferenceRoute: {
    component: () => null,
    path: routePaths.oldConferenceRoute.template(),
    stackIndex: 0,
    locked: true
  },
  oldPlaylist: {
    component: () => null,
    path: routePaths.oldPlaylist.template(),
    stackIndex: 0,
    locked: true
  },
  peerAuthoringLesson: {
    component: () => null,
    path: routePaths.peerAuthoringLesson.template(),
    stackIndex: 0,
    locked: true
  },
  oldLessonReview: {
    component: () => null,
    path: routePaths.oldLessonReview.template(),
    stackIndex: 0,
    locked: true
  }
  // #endregion
};
