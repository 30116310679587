import { isAfter } from 'date-fns';
import { uniqBy } from 'lodash';

import type { DictionaryType } from '@edapp/utils';
import type { ConferenceActionsUnionType } from '@maggie/store/courseware/conferences/actions';
import { ConferenceActionTypes } from '@maggie/store/courseware/conferences/actions';
import { StarActionTypes } from '@maggie/store/star/actions';
import type { StarActionUnionType } from '@maggie/store/star/types';

import { CourseActionTypes } from '../courses/actions';
import type { CourseActionsUnionType } from '../courses/types';
import { LessonActionTypes } from './actions';
import { initialLessonsState } from './constants';
import {
  applyProgressUpdates,
  lessonProgressAttemptFailureUpdated,
  lessonProgressCompleteUpdated,
  lessonProgressEarnedStarsUpdated,
  lessonProgressOpenUpdated,
  lessonProgressUnlockUpdated,
  lessonsProgressReset
} from './reducerUtils';
import type { LessonActionsUnionType, LessonAttemptType, LessonType, LessonsState } from './types';

/**
 * Checks if date1 is ahead of date2
 */
const isAhead = (d1?: string | null, d2?: string | null) => {
  if (!d1) {
    return false;
  }

  if (!d2) {
    return true;
  }

  return isAfter(new Date(d1), new Date(d2));
};

export const lessonsReducer = (
  state: LessonsState = initialLessonsState,
  action:
    | LessonActionsUnionType
    | CourseActionsUnionType
    | StarActionUnionType
    | ConferenceActionsUnionType
): LessonsState => {
  switch (action.type) {
    case CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS: {
      const { lessonsProgress } = action.payload;

      const newProgressState = lessonsProgress.reduce(
        (newState, progress) => {
          const currentProgress = { ...newState[progress.lessonId] };

          newState[progress.lessonId] = applyProgressUpdates(currentProgress, progress);

          return newState;
        },
        { ...state.lessonsProgress }
      );

      return {
        ...state,
        lessonsProgress: {
          ...newProgressState
        }
      };
    }

    case LessonActionTypes.FETCH_LESSON: {
      return { ...state, fetchLessonErrorCode: undefined };
    }

    case LessonActionTypes.FETCH_LESSON_SUCCESS: {
      const lessonsFormatted = action.payload.items.reduce<DictionaryType<LessonType>>(
        (obj, lesson) => {
          obj[lesson.id] = lesson;
          return obj;
        },
        {}
      );
      return {
        ...state,
        fetchLessonErrorCode: undefined,
        lessons: { ...state.lessons, ...lessonsFormatted }
      };
    }
    case LessonActionTypes.FETCH_LESSON_FAILURE: {
      const errorCode = action.payload?.code;
      return { ...state, fetchLessonErrorCode: errorCode };
    }

    case LessonActionTypes.FETCH_LESSON_ATTEMPT_SUCCESS: {
      const { lessonId, attemptId, lastAttemptEventDateTime, slides } = action.payload;

      // attempt in the FE is ahead from the server, do not override
      const clientAttemptEventDate = state.lessonsAttempts[lessonId]?.lastAttemptEventDateTime;
      const serverAttemptEventDate = lastAttemptEventDateTime;
      if (isAhead(clientAttemptEventDate, serverAttemptEventDate)) {
        return state;
      }

      // lesson was completed after the FE attempt, do not override
      const clientCompletedDate = state.lessonsProgress[lessonId]?.completedDate;
      if (isAhead(clientCompletedDate, clientAttemptEventDate)) {
        return state;
      }

      return {
        ...state,
        lessonsAttempts: {
          ...state.lessonsAttempts,
          [lessonId]: { attemptId, slides, lastAttemptEventDateTime }
        }
      };
    }

    case LessonActionTypes.FETCH_LESSON_ATTEMPT_LIST_SUCCESS: {
      return {
        ...state,
        lessonsAttempts: {
          ...state.lessonsAttempts,
          ...Object.keys(action.payload).reduce<Record<string, LessonAttemptType>>((result, lessonId) => {
            const attempt = action.payload[lessonId];

            // attempt in the FE is ahead from the server, do not override
            const clientAttemptDate = state.lessonsAttempts[lessonId]?.lastAttemptEventDateTime;
            const serverAttemptDate = attempt.lastAttemptEventDateTime;
            if (isAhead(clientAttemptDate, serverAttemptDate)) {
              return result;
            }

            // lesson was completed after the FE attempt, do not override
            const clientCompletedDate = state.lessonsProgress[lessonId]?.completedDate;
            if (isAhead(clientCompletedDate, clientAttemptDate)) {
              return result;
            }

            result[lessonId] = attempt;
            return result;
          }, {})
        }
      };
    }

    case LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS:
    case LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS: {
      const newProgressState = action.payload.reduce(
        (newState, progress) => {
          const currentProgress = { ...newState[progress.lessonId] };

          newState[progress.lessonId] = applyProgressUpdates(currentProgress, progress);

          return newState;
        },
        { ...state.lessonsProgress }
      );

      return {
        ...state,
        lessonsProgress: {
          ...newProgressState
        }
      };
    }

    case LessonActionTypes.UPDATE_LESSON_OPENED: {
      const { lessonId } = action.payload;
      const progress = state.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        lessonsProgress: {
          ...state.lessonsProgress,
          ...lessonProgressOpenUpdated(progress, lessonId)
        }
      };
    }

    case LessonActionTypes.UPDATE_LESSON_COMPLETED: {
      const { lessonId, score } = action.payload;
      const progress = state.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      const newProgress = lessonProgressCompleteUpdated(progress, lessonId, score);

      return {
        ...state,
        lessonsProgress: { ...state.lessonsProgress, ...newProgress },
        lastLessonsScore: {
          ...state.lastLessonsScore,
          [lessonId]: {
            prevBestScore: state.lessonsProgress[lessonId].bestScore ?? 0,
            currBestScore:
              score > state.lessonsProgress[lessonId].bestScore
                ? score
                : state.lessonsProgress[lessonId].bestScore
          }
        }
      };
    }

    case LessonActionTypes.UPDATE_LESSON_ATTEMPT_FAILURE: {
      const { id: lessonId, score } = action.payload;
      const progress = state.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        lessonsProgress: {
          ...state.lessonsProgress,
          ...lessonProgressAttemptFailureUpdated(progress, lessonId, score)
        }
      };
    }

    case LessonActionTypes.UPDATE_LESSONS_UNLOCK: {
      const { items } = action.payload;
      const lessonsProgress = state.lessonsProgress;

      return {
        ...state,
        lessonsProgress: {
          ...lessonsProgress,
          ...lessonProgressUnlockUpdated(lessonsProgress, items)
        }
      };
    }

    case LessonActionTypes.RESET_LESSONS_PROGRESS: {
      const { lessonIds } = action.payload;
      const lessonsProgress = state.lessonsProgress;
      return {
        ...state,
        lessonsProgress: {
          ...lessonsProgress,
          ...lessonsProgressReset(lessonsProgress, lessonIds)
        }
      };
    }

    case StarActionTypes.REWARD_STARS_FROM_SLIDE: {
      const { lessonId, stars } = action.payload;
      const progress = state.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        lessonsProgress: {
          ...state.lessonsProgress,
          ...lessonProgressEarnedStarsUpdated(progress, lessonId, stars)
        }
      };
    }

    case ConferenceActionTypes.MARK_CONFERENCE_COMPLETED: {
      const { id } = action.payload;

      const currentLessonProgress = state.lessonsProgress[id];

      if (!currentLessonProgress) {
        return state;
      }

      return {
        ...state,
        lessonsProgress: {
          ...state.lessonsProgress,
          ...lessonProgressCompleteUpdated(currentLessonProgress, id, 0)
        }
      };
    }

    case LessonActionTypes.SET_LEADERBOARD_SUMMARY_DIALOG_OPEN: {
      return {
        ...state,
        leaderboardSummaryDialogIsOpen: action.payload.open
      };
    }

    case LessonActionTypes.OPEN_LESSON_SUMMARY_DIALOG: {
      return { ...state, summaryDialogOpen: true };
    }

    case LessonActionTypes.CLOSE_LESSON_SUMMARY_DIALOG: {
      return { ...state, summaryDialogOpen: false };
    }

    case LessonActionTypes.START_ATTEMPT_LESSON: {
      const { attempt, lessonId } = action.payload;

      return {
        ...state,
        lastAttempt: attempt,
        lessonsAttempts: {
          [lessonId]: {
            attemptId: attempt.attemptId,
            slides: uniqBy(attempt.interactions, v => v.id),
            lastAttemptEventDateTime: new Date().toISOString()
          }
        }
      };
    }

    case LessonActionTypes.FINISH_ATTEMPT_LESSON: {
      const { attempt, lessonId } = action.payload;

      const lessonsAttempts = { ...state.lessonsAttempts };
      delete lessonsAttempts[lessonId];

      return {
        ...state,
        lastAttempt: attempt,
        lessonsAttempts: { ...lessonsAttempts }
      };
    }

    case LessonActionTypes.ATTEMPT_SLIDE: {
      const { lessonId, attemptSlide } = action.payload;

      /**
       * Attempt does not exist!
       *
       * When can this happen?
       *      - when user reaches the final slide, the ATTEMPT_LESSON gets triggered before the slide interaction is raised
       */
      if (!state.lessonsAttempts[lessonId]) {
        return state;
      }

      // Attempt exists, we will add the slide to the existing one
      return {
        ...state,
        lessonsAttempts: {
          ...state.lessonsAttempts,
          [lessonId]: {
            ...state.lessonsAttempts[lessonId],
            lastAttemptEventDateTime: new Date().toISOString(),
            slides: uniqBy([...state.lessonsAttempts[lessonId].slides, attemptSlide], v => v.id)
          }
        }
      };
    }

    default:
      return state;
  }
};
