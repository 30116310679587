import { HomeActionTypes } from './actions';
import { initialHomeState } from './constants';
import type { HomeActionsUnionType, HomeState } from './types';

export const homeReducer = (
  state: HomeState = initialHomeState,
  action: HomeActionsUnionType
): HomeState => {
  switch (action.type) {
    case HomeActionTypes.SET_IS_LOADING: {
      const { loading } = action.payload;
      return { ...state, isLoading: loading };
    }

    case HomeActionTypes.FETCH_FOR_YOU_WIDGETS_SUCCESS: {
      const forYouWidgets = action.payload;
      return { ...state, forYouWidgets: [...forYouWidgets] };
    }

    case HomeActionTypes.COMPLETE_UP_NEXT: {
      const { courses } = action.payload;
      return { ...state, upNext: [...courses] };
    }

    case HomeActionTypes.SELECT_TAB_INDEX: {
      const { tabIndex } = action.payload;
      return { ...state, tabIndex };
    }

    default: {
      return state;
    }
  }
};
