import type {
  ReviewLessonInteraction,
  ReviewSlideInteraction
} from '@maggie/core/tracker/hippo-interaction-types';
import { TrackerInteractions } from '@maggie/core/tracker/tracker-interactions';
import type { LessonType } from '@maggie/store/courseware/lessons/types';

import type { ThomasViewInteraction } from '../thomas/thomas-interaction-types';
import { Attempt } from './attempt';

export class ReviewAttempt extends Attempt {
  private lesson: LessonType;

  constructor(lesson: LessonType) {
    super(lesson.configuration.slides);
    this.lesson = lesson;
  }

  public finishAttempt = () => {
    super.finishAttempt();

    const interaction: ReviewLessonInteraction = {
      type: 'review-lesson',
      courseId: this.lesson.courseId,
      courseVersionNumber: this.lesson.courseVersionNumber,
      startTimestamp: this.startedAt,
      endTimestamp: this.endedAt,
      lessonId: this.lesson.id,
      reviewId: this.attemptId
    };
    TrackerInteractions.trackReviewLessonInteraction(interaction);
  };

  protected recordView(view: ThomasViewInteraction) {
    super.recordView(view);

    const interaction: ReviewSlideInteraction = {
      type: 'review-slide',
      courseId: this.lesson.courseId,
      courseVersionNumber: this.lesson.courseVersionNumber,
      duration: view.duration,
      lessonId: this.lesson.id,
      reviewId: this.attemptId,
      slideId: view.id
    };
    TrackerInteractions.trackReviewSlideInteraction(interaction);
  }
}
