import type { Reducer } from 'redux';

import type { ActionsUnion } from '@edapp/utils';
import type { ActionDialogActions } from './actions';
import { ActionDialogActionTypes } from './actions';
import type { ActionDialogState } from './types';
import { initialActionDialogState } from './constants';
import { generateTitle } from '@edapp/translations/src/i18n';

export const actionDialogReducer: Reducer<ActionDialogState> = (
  state = initialActionDialogState,
  action: ActionsUnion<typeof ActionDialogActions>
) => {
  switch (action.type) {
    case ActionDialogActionTypes.OPEN_ACTION_DIALOG: {
      const {
        title = generateTitle('dialog.error.titles'),
        message = initialActionDialogState.message,
        confirmText = initialActionDialogState.confirmText,
        onConfirm = initialActionDialogState.onConfirm
      } = action.payload;

      return {
        ...state,
        isOpen: true,
        title,
        message,
        confirmText,
        onConfirm
      };
    }

    case ActionDialogActionTypes.CLOSE_ACTION_DIALOG: {
      return { ...state, isOpen: false };
    }

    default:
      return state;
  }
};
