import * as React from 'react';

import styled, { css } from 'styled-components';

import { Text } from '@edapp/sc-web-ui';
import type { ColorsKeys } from '@edapp/themes';

import { Box } from '../box';
import type { IconComponent } from '../icons/base/BaseIcon';
import { EdTypography } from '../typography/EdTypography';

export type Props = {
  icon?: IconComponent | null;
  iconColor?: ColorsKeys;
  image?: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  showBackground?: boolean;
  /**
   * Show card in the middle of the screen
   */
  isFullScreen?: boolean;
  maxTextWidth?: number;
} & React.ComponentProps<typeof Box>;

export const EmptyStateCard: React.FC<Props> = ({
  icon: IconComponent,
  iconColor = 'text',
  image: ImageComponent,
  title,
  description,
  showBackground = true,
  maxTextWidth,
  children,
  gap = 1,
  ...rest
}) => {
  return (
    <Wrapper showBackground={showBackground} rounded p={6} {...rest}>
      <Box flex flexDirection="column" alignItems="center" justifyContent="center" gap={gap}>
        {!!ImageComponent ? (
          ImageComponent
        ) : !!IconComponent ? (
          <IconBackground
            flex
            alignItems="center"
            justifyContent="center"
            mb={2}
            showBackground={showBackground}
          >
            <IconComponent size="xl" color={iconColor} />
          </IconBackground>
        ) : (
          <></>
        )}
        {title && (
          <StyledText variant="titleLarge" component="h2" $maxWidth={maxTextWidth}>
            {title}
          </StyledText>
        )}
        {description &&
          (typeof description === 'string' ? (
            <StyledTypography color="textMuted" variant="small" $maxWidth={maxTextWidth}>
              {description}
            </StyledTypography>
          ) : (
            description
          ))}
        {children}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)<{ showBackground?: boolean; isFullScreen?: boolean }>`
  ${({ showBackground, isFullScreen, theme }) => css`
    text-align: center;
    ${showBackground &&
    css`
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.greyHover};
    `}
    ${isFullScreen
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 576px;
          width: 100%;
        `
      : ''}
  `}
`;

const IconBackground = styled(Box)<{ showBackground?: boolean }>`
  ${({ showBackground, theme }) =>
    showBackground &&
    css`
      border-radius: 50%;
      background-color: ${theme.colors.lightGrey};
      padding: ${theme.space(3)}px;
    `}
`;

const StyledText = styled(Text)<{ $maxWidth?: number }>(
  ({ $maxWidth }) =>
    !!$maxWidth &&
    css`
      max-width: ${$maxWidth}px;
    `
);
const StyledTypography = styled(EdTypography)<{ $maxWidth?: number }>(
  ({ $maxWidth }) =>
    !!$maxWidth &&
    css`
      max-width: ${$maxWidth}px;
    `
);
