export enum ObservationStatus {
  InProgress = 'InProgress',
  Passed = 'Passed',
  Failed = 'Failed',
  Unattempted = 'Unattempted'
}

export enum SubmissionStatus {
  submitted = 'submitted',
  draft = 'draft'
}
