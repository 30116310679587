import { createAction } from '@edapp/utils';

export enum LogoutActionTypes {
  CLOSE_LOGOUT_DIALOG = 'CLOSE_LOGOUT_DIALOG',
  OPEN_LOGOUT_DIALOG = 'OPEN_LOGOUT_DIALOG'
}

export const LogoutActions = {
  closeLogoutDialog: () => createAction(LogoutActionTypes.CLOSE_LOGOUT_DIALOG),
  openLogoutDialog: () => createAction(LogoutActionTypes.OPEN_LOGOUT_DIALOG)
};
