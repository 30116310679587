import { fork, put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';

import { SlideProgressActionTypes } from './actions';
import type { SlideProgressUnionType } from './types';

export type SlideProgressAction<ActionType extends string> = ActionFromActionType<
  SlideProgressUnionType,
  ActionType
>;

function* watchFetchSlideProgress() {
  yield takeLatest(SlideProgressActionTypes.FETCH_SLIDE_PROGRESS, handleFetchSlideProgress);
}

function* handleFetchSlideProgress(
  action: SlideProgressAction<SlideProgressActionTypes.FETCH_SLIDE_PROGRESS>
) {
  const lessonIds = action.payload.lessonIds;
  yield put(
    RequestActions.getAuthed(
      Urls.SLIDE_PROGRESS,
      SlideProgressActionTypes.FETCH_SLIDE_PROGRESS_SUCCESS,
      SlideProgressActionTypes.FETCH_SLIDE_PROGRESS_FAILURE,
      undefined,
      {
        lessonIds
      }
    )
  );
}

const slideProgressSagas = [fork(watchFetchSlideProgress)];
export { slideProgressSagas };
