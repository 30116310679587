import * as React from 'react';
import { retry } from '@edapp/utils';

type DynamicImport<T = any> = Promise<{ default: React.ComponentType<T> }>;

export const ReactLazyRetry = (
  component: () => DynamicImport,
  retries?: number,
  interval?: number
) => {
  return React.lazy(() => retry(component, { retries, interval }));
};
