import { RequestUtils } from '@edapp/request';
import type { Token } from '@edapp/request';
import { ENV } from '@maggie/config/env';

import type { UploadMediaRequest, UploadMediaResponse } from './types';

export const uploadDocument = (
  userToken: Token | undefined,
  request: UploadMediaRequest
): Promise<UploadMediaResponse> => {
  const formData = new FormData();
  formData.append('file', request.file);
  formData.append('mediaType', request.mediaType);
  formData.append('fileName', request.fileName || request.file.name);

  return RequestUtils.httpFetch<UploadMediaResponse, FormData>(
    'POST',
    `${ENV.HIPPO_API}/api/media`,
    userToken,
    formData,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
};
