import { createGlobalStyle } from 'styled-components';

import { themeCommon } from '@edapp/themes';

export const THEME_CHANGED_EVENT = 'sc:theme-changed';
export const DEFAULT_THEME = 'light';

/*
  Duplicating the constant from @safetyculture/shared-vars/constants/layout for now 
  since this constant is currently not within the scope of sc-web-ui. 
  We need to figure out a way to share domain-relevant common packages with training.
*/
export const SC_NAVBAR_WIDTH = 13.75;

export const GlobalStyle = createGlobalStyle`
  html, body, #training-root {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Noto Sans', 'Helvetica Neue', Arial, sans-serif;
    background-color: ${p => p.theme.colors.background};
  }

  #training-root {
    display: flex;
    flex-direction: column;
  }

  * {
    font-family: 'Noto Sans', 'Helvetica Neue', Arial, sans-serif;
  }

  :root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }
`;

export const scColors = {
  blue: '#675DF4',
  lightBlue: '#F0F1FE',
  blueHover: '#736FF1',
  blueActive: '#564BE7',
  textBlue: '#675DF4'
};

export const themeTraining = {
  ...themeCommon,
  flexboxgrid: {
    ...themeCommon.flexboxgrid,
    container: {
      sm: themeCommon.flexboxgrid.container.sm - SC_NAVBAR_WIDTH, // rem
      md: themeCommon.flexboxgrid.container.md - SC_NAVBAR_WIDTH, // rem
      lg: themeCommon.flexboxgrid.container.lg - SC_NAVBAR_WIDTH, // rem
      xlg: themeCommon.flexboxgrid.container.xlg - SC_NAVBAR_WIDTH // rem
    }
  }
};
