import { t } from 'i18next';
import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import type { ActionFromActionType, ActionsUnion } from '@edapp/utils';
import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';
import { CourseSummariesSelectors } from '@maggie/store/courseware/course-summaries/selectors';
import { CourseActionTypes } from '@maggie/store/courseware/courses/actions';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { CourseType } from '@maggie/store/courseware/courses/types';
import { DocumentSelectors } from '@maggie/store/courseware/documents/selectors';
import type {
  DocumentPrereqProps,
  DocumentWithProgressAndStatusType
} from '@maggie/store/courseware/documents/types';
import { LessonActivitySelectors } from '@maggie/store/courseware/lesson-activity/selectors';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import { RapidRefreshSelectors } from '@maggie/store/courseware/rapid-refreshes/selectors';
import type { RapidRefreshType } from '@maggie/store/courseware/rapid-refreshes/types';
import { CoursewareUtils } from '@maggie/store/courseware/utils';
import type { LxStoreState } from '@maggie/store/types';

import { LockedDialogActionTypes, LockedDialogActions } from './actions';
import { watchOpenLockedLesson } from './locked-lesson-sagas';
import type { DoRedirectParams } from './types';
import { checkLockedDates, shouldRedirectToPlaylist } from './utils';

const written = require('written');

type LockedDialogAction<ActionType extends string> = ActionFromActionType<
  ActionsUnion<typeof LockedDialogActions>,
  ActionType
>;

function* handleOpenLockedRapidRefresh(
  action: LockedDialogAction<LockedDialogActionTypes.OPEN_LOCKED_RAPID_REFRESH>
): any {
  const { courseId, session } = action.payload;
  const rapidRefresh: RapidRefreshType | undefined = yield select<LxStoreState>(
    RapidRefreshSelectors.getRapidRefreshItem(courseId, session)
  );

  const dialogTitle = t('locked.rapid-refresh.title', { ns: 'learners-experience' });

  const prerequisites = rapidRefresh?.prerequisites.map(i => i.title) || [];
  if (prerequisites.length > 0) {
    const writtenOpts = {
      more: `more course`,
      wrap: 'strong',
      quantify: true,
      written: true
    };

    const dialogText = t('locked.rapid-refresh.prerequisites', {
      prerequisites: written.prettyList(prerequisites, 3, writtenOpts),
      interpolation: { escapeValue: false }, // allows html from prettyList
      ns: 'learners-experience'
    });

    return yield put(LockedDialogActions.openLockedDialog(true, dialogTitle, dialogText));
  }
}

function* handleOpenLockedCourse(
  action: LockedDialogAction<LockedDialogActionTypes.OPEN_LOCKED_COURSE>
): any {
  const { shouldOpenDialog, lockedCourseId, redirectParams } = action.payload;

  const dialogTitle = t('locked.course.title', { ns: 'learners-experience' });

  const courses: CourseSummaryType[] = yield select<LxStoreState>(
    CourseSummariesSelectors.getAllCourseSummaries
  );
  const course = courses.find(c => c.courseId === lockedCourseId);

  if (shouldRedirectToPlaylist(redirectParams)) {
    return yield openPlaylistLockedCourseDialog(shouldOpenDialog, dialogTitle, redirectParams);
  }

  if (course && course.sequentialPlaylists.length > 0) {
    const dialogText = t('locked.course.playlist-no-redirect', { ns: 'learners-experience' });
    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        dialogTitle,
        dialogText,
        redirectParams
      )
    );
  }

  const lockedByDates = checkLockedDates(course?.planning, 'course');
  if (!!lockedByDates) {
    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        lockedByDates.dialogTitle,
        lockedByDates.dialogText,
        redirectParams
      )
    );
  }

  const prerequisites = course?.prerequisites?.map(i => i.title) ?? [];
  if (prerequisites.length > 0) {
    const writtenOpts = {
      more: `more course`,
      wrap: 'strong',
      quantify: true,
      written: true
    };

    const dialogText = t(`locked.course.prerequisites`, {
      prerequisites: written.prettyList(prerequisites, 3, writtenOpts),
      interpolation: { escapeValue: false }, // allows html from prettyList
      ns: 'learners-experience'
    });

    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        dialogTitle,
        dialogText,
        redirectParams
      )
    );
  }

  const dialogText = t('locked.course.default', { ns: 'learners-experience' });
  return yield put(
    LockedDialogActions.openLockedDialog(shouldOpenDialog, dialogTitle, dialogText, redirectParams)
  );
}

function* openPlaylistLockedCourseDialog(
  shouldOpenDialog: boolean,
  dialogTitle: string,
  redirectParams: DoRedirectParams<'playlist'>
): any {
  const dialogText = t('locked.course.playlist', { ns: 'learners-experience' });
  const acceptText = t('locked.course.playlist-accept', { ns: 'learners-experience' });

  return yield put(
    LockedDialogActions.openLockedDialog(
      shouldOpenDialog,
      dialogTitle,
      dialogText,
      redirectParams,
      acceptText
    )
  );
}

function* handleOpenLockedBriefcase(
  action: LockedDialogAction<LockedDialogActionTypes.OPEN_LOCKED_BRIEFCASE>
): any {
  const { lockedBriefcaseId } = action.payload;

  const doc: DocumentWithProgressAndStatusType | undefined = yield select<LxStoreState>(s =>
    DocumentSelectors.getCourseDocumentWithProgress(lockedBriefcaseId, s)
  );
  if (!doc) {
    return ErrorLogger.captureEvent(
      'Opened a locked briefcase document that is not in the store?',
      'warning',
      { lockedBriefcaseId }
    );
  }

  const course: CourseType | undefined = yield select<LxStoreState>(s =>
    CourseSelectors.getCourse(doc.courseId, s)
  );
  if (!course) {
    return ErrorLogger.captureEvent(
      'Opened a locked briefcase document that has no course in the store?',
      'warning',
      { courseId: doc.courseId, lockedBriefcaseId }
    );
  }

  const prereqs: DocumentPrereqProps[] = yield select(
    DocumentSelectors.getDocumentPrerequisites(doc.courseId, doc)
  );

  const prerequisites = prereqs.map(prereq => {
    const lessonSummary = course.lessonSummaries.find(l => l.lessonId === prereq.prereqId);
    return lessonSummary?.title || '';
  });

  const writtenOpts = {
    more: `more lesson`,
    wrap: 'strong',
    quantify: true,
    written: true
  };

  const dialogText = t('locked.document.prerequisites', {
    prerequisites: written.prettyList(prerequisites, 3, writtenOpts),
    interpolation: { escapeValue: false }, // allows html from prettyList
    ns: 'learners-experience'
  });

  const dialogTitle = t('locked.document.title', { ns: 'learners-experience' });
  return yield put(LockedDialogActions.openLockedDialog(false, dialogTitle, dialogText));
}

/**
 * We have to introduce this due to the poor design of the locking functionality in the learners-app.
 * More context here: https://ed-app.atlassian.net/browse/ED-12437?focusedCommentId=33816
 */
function* handleWorkaroundCloseLockedDialog() {
  const state: LxStoreState = yield select<LxStoreState>(s => s);
  if (!window.__router.isSplitViewLayout('lesson') && state.navigation.route === 'lesson') {
    const lessonId = state.navigation.lessonId;
    const lessonSummary = LessonActivitySelectors.findLessonSummaryWithProgress(
      lessonId,
      'Lesson'
    )(state);

    const isAvailable = LessonSelectors.isLessonAvailable(lessonSummary, state);
    const isAvailableForPlatform = CoursewareUtils.isAvailableForPlatform(lessonSummary);

    if (!!isAvailable && !!isAvailableForPlatform) {
      yield put(LockedDialogActions.closeLockedDialog());
    }
  }
}

function* watchOpenLockedCourse() {
  yield takeLatest(LockedDialogActionTypes.OPEN_LOCKED_COURSE, handleOpenLockedCourse);
}

function* watchOpenLockedRapidRefresh() {
  yield takeLatest(LockedDialogActionTypes.OPEN_LOCKED_RAPID_REFRESH, handleOpenLockedRapidRefresh);
}

function* watchOpenLockedBriefcase() {
  yield takeLatest(LockedDialogActionTypes.OPEN_LOCKED_BRIEFCASE, handleOpenLockedBriefcase);
}

function* watchWorkaroundCloseLockedDialog() {
  yield takeLatest(LessonActionTypes.UPDATE_LESSONS_UNLOCK, handleWorkaroundCloseLockedDialog);
  yield takeLatest(CourseActionTypes.UPDATE_COURSES_UNLOCK, handleWorkaroundCloseLockedDialog);
}

export const lockedDialogSagas = [
  fork(watchOpenLockedLesson),
  fork(watchOpenLockedCourse),
  fork(watchOpenLockedBriefcase),
  fork(watchOpenLockedRapidRefresh),
  fork(watchWorkaroundCloseLockedDialog)
];
