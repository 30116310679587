import { createAction } from '@edapp/utils';

export enum GroupTrainingActionTypes {
  REGISTER_GROUP_TRAINING_SESSION = 'REGISTER_GROUP_TRAINING_SESSION',
  REGISTER_GROUP_TRAINING_SESSION_SUCCESS = 'REGISTER_GROUP_TRAINING_SESSION_SUCCESS',
  REGISTER_GROUP_TRAINING_SESSION_FAILURE = 'REGISTER_GROUP_TRAINING_SESSION_FAILURE'
}

export const GroupTrainingActions = {
  registerGroupTrainingSession: (sessionId: string) =>
    createAction(GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION, { sessionId })
};
