import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import type { RequestTypes } from '@edapp/request';
import { RequestActionTypes, RequestUtils } from '@edapp/request';
import { ENV } from '@maggie/config/env';
import { checkOnline } from '@maggie/cordova/network_utils';
import type { LxStoreState } from '@maggie/store/types';
import { UserActions } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

import { getErrorMessage } from './errors';

/**
 * If error is not valid, we don't want to send to sentry...
 * We wanna see logs that make sense
 */
const isValidError = (error?: RequestTypes.EdExceptionType) => {
  // Looks like when we're in offline mode - requests are still triggered and logged to sentry
  if (!checkOnline()) {
    return false;
  }

  if (!error) {
    return false;
  }

  if (error.message === 'The Internet connection appears to be offline') {
    return false;
  }

  if (error.message === 'Failed to fetch') {
    return false;
  }

  return true;
};

function* handleRequest(action: RequestTypes.Action<RequestTypes.RequestPayloadType>): any {
  const {
    type,
    payload: {
      uri,
      successAction,
      failureAction,
      data,
      headers,
      overrideErrorMessage = true,
      url: baseUrl
    }
  } = action;

  const url = `${baseUrl || ENV.HIPPO_API}/${uri}`;

  const requestParams = type.split('_');
  const requestMode = requestParams[0] as RequestTypes.FetchAuthorizationType;
  const requestMethod = requestParams[1] as RequestTypes.FetchMethodType;

  const payload: Record<string, unknown> | null = data || null;

  const userIdWhenRequestBegins: string = yield select<LxStoreState>(state => state.user.id);

  const userToken: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );

  if (requestMode === 'AUTHED' && !userToken) {
    yield put({
      type: failureAction,
      payload: {
        type: 'Error',
        Message: 'Unauthorized.'
      }
    });

    yield put(UserActions.userLogout(true, false));
    return;
  }

  const startTime = new Date().getTime();

  try {
    const locale = payload?.locale || window.__contentLang || window.__l10n.language;

    const response = yield call(
      RequestUtils.httpFetch,
      requestMethod,
      url,
      userToken,
      payload,
      undefined,
      headers,
      undefined,
      locale
    );

    const endRequestTimeMs = new Date().getTime() - startTime;
    window.__networkMonitor.addNetworkLatency(endRequestTimeMs);

    // Check to ensure that response received is for a request sent from current user
    const userIdWhenRequestCompletes = yield select<LxStoreState>(state => state.user.id);

    if (
      requestMode === 'AUTHED' &&
      userIdWhenRequestBegins &&
      userIdWhenRequestBegins !== userIdWhenRequestCompletes
    ) {
      ErrorLogger.captureEvent('Received response for different user!', 'warning', {
        type,
        uri,
        userIdWhenRequestBegins,
        userIdWhenRequestCompletes
      });
      return;
    }

    const responseAction =
      typeof successAction === 'function'
        ? successAction(response)
        : { type: successAction, payload: response };
    yield put(responseAction);
  } catch (e) {
    const endRequestTimeMs = new Date().getTime() - startTime;
    window.__networkMonitor.addNetworkLatency(endRequestTimeMs);

    const error: RequestTypes.EdExceptionType = e;
    const errorResponse = error.errorResponse;

    if (!!errorResponse && overrideErrorMessage != false) {
      errorResponse.message = getErrorMessage(errorResponse.code);
    }

    if (!!errorResponse && !!error?.statusCode) {
      errorResponse.statusCode = error.statusCode;
    }

    if (isValidError(error)) {
      const { requestBody, ...errorData } = error;
      const extraData = {
        requestMethod,
        url,
        tokenType: userToken?.type,
        payload:
          requestMode === 'UNAUTHED'
            ? {} // hide payload to avoid displaying password in logs
            : payload,
        error:
          requestMode === 'UNAUTHED'
            ? errorData // hide payload to avoid displaying password in logs
            : { ...errorData, requestBody },
        errorResponse,
        time: endRequestTimeMs + ' ms',
        statusCode: errorData?.statusCode,
        message: errorData?.message
      };

      if ((error?.statusCode || 0) >= 500 || (error?.statusCode || 0) === 0) {
        ErrorLogger.captureEvent(`${requestMethod} /${uri} - failed`, 'error', extraData);
      }
    }

    const errorAction =
      typeof failureAction === 'function'
        ? failureAction(errorResponse)
        : { type: failureAction, payload: errorResponse };
    yield put(errorAction);
  } finally {
    yield put({ type: RequestActionTypes.REQUEST_COMPLETED });
  }
}

function* watchRequests() {
  yield takeEvery(
    [
      RequestActionTypes.AUTHED_GET_REQUEST,
      RequestActionTypes.AUTHED_POST_REQUEST,
      RequestActionTypes.AUTHED_DELETE_REQUEST,
      RequestActionTypes.UNAUTHED_GET_REQUEST,
      RequestActionTypes.UNAUTHED_POST_REQUEST,
      RequestActionTypes.AUTHED_PUT_REQUEST,
      RequestActionTypes.AUTHED_PATCH_REQUEST
    ],
    handleRequest
  );
}

const requestSagas = [fork(watchRequests)];

export { requestSagas };
