import { createAction } from '@edapp/utils';

export enum ForgotPasswordActionTypes {
  SET_EMAIL = 'SET_EMAIL',

  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
}

export const ForgotPasswordActions = {
  setEmail: (email: string) => createAction(ForgotPasswordActionTypes.SET_EMAIL, { email }),

  resetPassword: (email: string) =>
    createAction(ForgotPasswordActionTypes.RESET_PASSWORD, { email }),
  resetPasswordSuccess: () => createAction(ForgotPasswordActionTypes.RESET_PASSWORD_SUCCESS, {}),
  resetPasswordFailure: (error: EdErrorResponseType) =>
    createAction(ForgotPasswordActionTypes.RESET_PASSWORD_FAILURE, error)
};
