import { RegisterActionsTypes } from './actions';
import type { RegisterState } from './types';
import type { RegisterActionUnionType } from './actions';

export const initialRegisterState: RegisterState = {
  loading: false,
  error: null,
  registrationSettings: {
    applicationId: '',
    customBackgroundUrl: '',
    customLogoUrl: '',
    useCustomLogo: false,
    fields: []
  }
};

export const registerReducer = (
  state: RegisterState = initialRegisterState,
  action: RegisterActionUnionType
): RegisterState => {
  switch (action.type) {
    case RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_SUCCESS:
    case RegisterActionsTypes.CHECK_INVITE_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        registrationSettings: action.payload
      };
    }

    case RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_FAILURE:
    case RegisterActionsTypes.CHECK_INVITE_CODE_FAILURE: 
    case RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_FAILURE: {
      return { ...state, loading: false, error: action.payload.code };
    }

    case RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_SUCCESS: {
      return { ...state, loading: false, error: null };
    }

    case RegisterActionsTypes.CHECK_INVITE_CODE:
    case RegisterActionsTypes.REGISTER_WITH_INVITE_CODE: {
      return { ...state, loading: true, error: null };
    }

    default: {
      return state;
    }
  }
};
