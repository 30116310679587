import { ContentLibraryActionTypes } from '@edapp/content-library';
import type { DictionaryType } from '@edapp/utils';
import { CourseActionTypes } from '@maggie/store/courseware/courses/actions';

import type { MicroCredentialActionsUnionType } from './actions';
import { MicroCredentialActionTypes } from './actions';
import { initialMicroCredentialsState } from './constants';
import type { MicroCredential, MicroCredentialsState } from './types';

export const microCredentialsReducer = (
  state: MicroCredentialsState = initialMicroCredentialsState,
  action: MicroCredentialActionsUnionType
): MicroCredentialsState => {
  switch (action.type) {
    case ContentLibraryActionTypes.FETCH_SECTIONS_SUCCESS: {
      const byId: DictionaryType<MicroCredential> = {};
      const byCourseId: DictionaryType<string | undefined> = {};

      action.payload.response.items.forEach(section => {
        section.courses.items.forEach(course => {
          byCourseId[course.id] = course.microCredential?.id;

          if (course.microCredential) {
            byId[course.microCredential.id] = course.microCredential;
          }
        });
      });

      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId
        },
        byCourseId: {
          ...state.byCourseId,
          ...byCourseId
        }
      };
    }

    case ContentLibraryActionTypes.FETCH_SECTION_SUCCESS: {
      const byId: DictionaryType<MicroCredential> = {};
      const byCourseId: DictionaryType<string | undefined> = {};

      action.payload.response.courses.items.forEach(course => {
        byCourseId[course.id] = course.microCredential?.id;

        if (course.microCredential) {
          byId[course.microCredential.id] = course.microCredential;
        }
      });

      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId
        },
        byCourseId: {
          ...state.byCourseId,
          ...byCourseId
        }
      };
    }

    case CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS: {
      const { microCredential: newMicroCredential, courseInfo } = action.payload;
      if (!newMicroCredential) {
        return state;
      }

      const byId: DictionaryType<MicroCredential> = {};
      const byCourseId: DictionaryType<string | undefined> = {};

      byCourseId[courseInfo.id] = newMicroCredential?.id;

      if (newMicroCredential) {
        byId[newMicroCredential.id] = newMicroCredential;
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId
        },
        byCourseId: {
          ...state.byCourseId,
          ...byCourseId
        }
      };
    }

    case MicroCredentialActionTypes.POLL_MICRO_CREDENTIAL: {
      const { id } = action.payload;

      return {
        ...state,
        polling: {
          ...state.polling,
          [id]: true
        }
      };
    }

    case MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL_SUCCESS: {
      const microCredential = action.payload;

      let newPolling = state.polling;

      if (microCredential.earned) {
        // remove id of earned micro-credential from polling dictionary
        const { [microCredential.id]: deleted, ...restOfPolling } = state.polling;
        newPolling = restOfPolling;
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [microCredential.id]: microCredential
        },
        polling: newPolling
      };
    }

    case MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS: {
      return {
        ...state,
        earnedList: {
          ...state.earnedList,
          isLoading: true,
          error: ''
        }
      };
    }

    case MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS_SUCCESS: {
      const newMicroCredentials: DictionaryType<MicroCredential> = {};

      action.payload.items.forEach(microCredential => {
        newMicroCredentials[microCredential.id] = microCredential;
      });

      return {
        ...state,
        byId: {
          ...state.byId,
          ...newMicroCredentials
        },
        earnedList: {
          ...state.earnedList,
          isLoading: false,
          totalCount: action.payload.totalCount
        }
      };
    }

    case MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS_FAILURE: {
      return {
        ...state,
        earnedList: {
          ...state.earnedList,
          isLoading: false,
          error: action.payload.message
        }
      };
    }

    default:
      return state;
  }
};
