import { buffers } from 'redux-saga';
import { actionChannel, call, put, select, take } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { FileManager } from '@maggie/cordova/file';
import { Asset } from '@maggie/core/lessons/asset';

import { OfflineActionTypes, OfflineActions } from './actions';
import { handleCleanOfflineFiles } from './clean-offline-files-sagas';
import { sharedDownloadBuffer } from './download-buffer';
import { OfflineSelectors } from './selectors';
import type { OfflineActionsMap, OfflineType } from './types';

export function* handleDeleteLesson(
  action: OfflineActionsMap<OfflineActionTypes.DELETE_LESSON_OFFLINE>
): any {
  const { lessonId } = action.payload;

  // Remove the lesson action from the download queue if present
  sharedDownloadBuffer.remove(lessonId);

  // Cancel the download task if it is in progress
  // Check if there is already an existing download in progress
  const existingProgress: OfflineType = yield select(OfflineSelectors.getStatus(lessonId));
  if (existingProgress.progress > 0 && existingProgress.progress < 100) {
    yield put({ type: OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE_CANCEL_CURRENT });
  }

  // Attempt to remove lesson folders,
  // we allow it to fail since a lesson might not have assets
  try {
    if (!window.__scWebview.platform) {
      // CORDOVA
      const path = `${FileManager.dataDirectory}downloads/lessons/${lessonId}`;
      yield FileManager.deleteFolder(path);
    } else {
      // SC WEBVIEW
      // we don't need the asset of the path itself, just the folder, so we can use empty string as url
      const asset = new Asset(null, 'lessons', lessonId);
      yield window.__scWebview.deleteGroupOfAssets(asset.folderPath);
    }
  } catch (e) {
    console.warn(`Error deleting lesson: ${lessonId}`, e);
  }

  // Delete lesson from store
  yield put(OfflineActions.deleteLessonOfflineSuccess(lessonId));

  // Clean data
  yield call(handleCleanOfflineFiles);
}

export function* watchDeleteLessonOffline(): any {
  // Create a queue for the delete lesson action
  // Checkout for implementation https://github.com/redux-saga/redux-saga/tree/master/docs/api#buffers
  const deleteChannel = yield actionChannel(
    OfflineActionTypes.DELETE_LESSON_OFFLINE,
    buffers.expanding(10)
  );

  while (true) {
    const action: OfflineActionsMap<OfflineActionTypes.DELETE_LESSON_OFFLINE> = yield take(
      deleteChannel
    );
    try {
      yield call(handleDeleteLesson, action);
    } catch (e) {
      ErrorLogger.captureException(e);
    }
  }
}
