/**
 * @file This file handles our internationalisation using node-polygot and locale yaml files stored in 'data/locales.
 * There is still some legacy code remaining to be refactored out once we move away from backbone, e.g.
 *
 * This code replaces the window.i18n with node-polygot and then mutates that function object to include another function 'has'
 * We are deprecating this method, right now it is used only in one place that will be removed when moving to react
 */

import { isEqual } from 'lodash';

/**
 * breaks down into code and subcode from language locale
 * Example: `en-GB` -> code: `en`, subcode: 'GB'
 */
const sanitizeLanguage = (code: string = '') => {
  return {
    code: code.split(/-|_/)[0]?.toLocaleLowerCase(),
    subcode: code.split(/-|_/)[1]?.toLocaleLowerCase()
  };
};

/**
 * Compares languages
 * 1. consider full language code (zh-cn === zh-cn)
 * 2. consider main language only (zh-cn === zh)
 */
export const isSameLocale = (code1: string = '', code2: string = '', exactMatch = false) => {
  if (code1.toLocaleLowerCase() === code2.toLocaleLowerCase()) {
    return true;
  }

  const lang1 = sanitizeLanguage(code1);
  const lang2 = sanitizeLanguage(code2);

  if (exactMatch || (lang1.subcode && lang2.subcode)) {
    return isEqual(lang1, lang2);
  }

  return lang1.code === lang2.code;
};

export const i18n = {
  isSameLocale
};
