import type { SupportedLanguage } from '@edapp/hippo-client';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { CourseSelectors } from '../courses/selectors';

const getSelectedLanguageOfCourse = (state: LxStoreState, courseId?: string): string | null => {
  courseId = courseId || state.navigation.course?.id; // default to the current course, handy for lesson page, discussion, etc
  if (!courseId) {
    return null;
  }

  return state.courseware.courseLanguage.courseLanguageById[courseId]?.languageCode || null;
};

const getCourseLanguage = (state: LxStoreState, courseId?: string): SupportedLanguage | null => {
  courseId = courseId || state.navigation.course?.id; // default to the current course, handy for lesson page, discussion, etc
  if (!courseId) {
    return null;
  }

  const supportedLanguages = CourseSelectors.getSupportedLanguages(state, courseId);

  // Previously selected language which is persisted in the store
  const selectedLanguageCode = getSelectedLanguageOfCourse(state, courseId);
  const selectedLanguage = supportedLanguages.find(lang => lang.code === selectedLanguageCode);
  if (selectedLanguage) {
    return selectedLanguage;
  }

  // User's preferred language based on the device locale
  const userLocale = UserSelectors.getLocale(state) || window.__l10n.language;
  const preferredLanguage = supportedLanguages.find(lang => lang.code === userLocale);
  if (preferredLanguage) {
    return preferredLanguage;
  }

  // Default to the original language of the course
  const originalLanguage = supportedLanguages.find(lang => lang.isOriginalLanguage);
  if (originalLanguage) {
    return originalLanguage;
  }

  // Should never happen, but show whatever is available
  return supportedLanguages?.[0] || null;
};

export const CourseLanguageSelectors = {
  getSelectedLanguageOfCourse,
  getCourseLanguage
};
