import { fork, put, race, select, take, takeEvery } from 'redux-saga/effects';

import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';

import { CourseActionTypes, CourseActions } from '../courses/actions';
import { CourseSelectors } from '../courses/selectors';
import type { UnlockPayload } from '../types';
import { CollectionsActionTypes, CollectionsActions } from './actions';
import type {
  CollectionActionsUnionType,
  CollectionType,
  FetchCollectionsResponse,
  FetchCollectionsSuccess
} from './types';
import { CollectionUtils } from './utils';

type CoursewareAction<ActionType extends string> = ActionFromActionType<
  CollectionActionsUnionType,
  ActionType
>;

const DEFAULT_PAGE_SIZE = 25;

function* handleFetchCollections(
  action: CoursewareAction<CollectionsActionTypes.FETCH_COLLECTIONS>
) {
  const { options } = action.payload;
  const payload = {
    pageSize: options.pageSize || DEFAULT_PAGE_SIZE,
    includeDrafts: false,
    ...options
  };

  yield put(
    RequestActions.getAuthed<FetchCollectionsResponse, FetchCollectionsSuccess>(
      Urls.COLLECTIONS,
      responseFromServer => ({
        type: CollectionsActionTypes.FETCH_COLLECTIONS_SUCCESS,
        payload: {
          page: options.page,
          ...responseFromServer
        }
      }),
      CollectionsActionTypes.FETCH_COLLECTIONS_FAILURE,
      undefined,
      payload
    )
  );
}

function* handleFetchCollectionsWithProgress(
  action: CoursewareAction<CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS>
): any {
  yield put(CollectionsActions.fetchCollections(action.payload));

  const { collectionsAction, failure } = yield race({
    collectionsAction: take(CollectionsActionTypes.FETCH_COLLECTIONS_SUCCESS),
    failure: take(CollectionsActionTypes.FETCH_COLLECTIONS_FAILURE)
  });

  if (failure) {
    yield put({
      type: CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS_FAILURE,
      payload: failure as EdErrorResponseType
    });
    return yield put(CollectionsActions.fetchCollectionsWithProgressCompleted());
  }

  const collections = collectionsAction.payload as RequestTypes.PaginatedResponse<CollectionType>;

  // Get Progress
  const courseIds = CollectionUtils.getCollectionsCourseIds(collections.items);
  const prerequisitesIds = CollectionUtils.getCollectionsPrerequisitesIds(collections.items);

  if (courseIds.length > 0 || prerequisitesIds.length > 0) {
    yield put(CourseActions.fetchCoursesProgress([...courseIds, ...prerequisitesIds]));

    const { failureProgress } = yield race({
      coursesProgress: take(CourseActionTypes.FETCH_COURSES_PROGRESS_SUCCESS),
      failureProgress: take(CourseActionTypes.FETCH_COURSES_PROGRESS_FAILURE)
    });

    if (failureProgress) {
      yield put({
        type: CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS_FAILURE,
        payload: failureProgress.payload as EdErrorResponseType
      });
      return yield put(CollectionsActions.fetchCollectionsWithProgressCompleted());
    }
  }

  // Check prereq
  const courses = collections.items.reduce((acc, item) => [...acc, ...item.courses], []);
  const items: UnlockPayload[] = yield select(
    CourseSelectors.getUnlockPayloadFromPrerequisitesForCourseSummaries(courses)
  );
  yield put(CourseActions.updateCoursesUnlock(items));

  yield put({ type: CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS_SUCCESS });
  yield put(CollectionsActions.fetchCollectionsWithProgressCompleted());
}

function* watchFetchCollectionsWithProgress() {
  yield takeEvery(
    CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS,
    handleFetchCollectionsWithProgress
  );
}

function* watchFetchCollections() {
  yield takeEvery(CollectionsActionTypes.FETCH_COLLECTIONS, handleFetchCollections);
}

const collectionSagas = [fork(watchFetchCollections), fork(watchFetchCollectionsWithProgress)];
export { collectionSagas };
