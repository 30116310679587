import { put, race, select, take, takeLatest } from 'redux-saga/effects';

import type { ActionWithPayload } from '@edapp/utils';
import type { LxStoreState } from '@maggie/store/types';

import type { CourseSummaryType } from '../collections/types';
import { CourseActionTypes, CourseActions } from '../courses/actions';
import type { CourseLessonsPayload } from '../courses/fetch-course-lessons-sagas';
import { CourseSelectors } from '../courses/selectors';
import type { CourseType } from '../courses/types';
import { LessonActionTypes, LessonActions } from '../lessons/actions';
import { LessonSelectors } from '../lessons/selectors';
import { PlaylistsActionTypes, PlaylistsActions } from './actions';
import { PlaylistsSelectors } from './selectors';
import type { PlaylistAction } from './types';

/**
 *
 * This action will be triggered/handled when PLAYLISTS_WITH_COURSE_PROGRESS
 * is finished, which means, it will contain all the courseProgress for its course Summaries
 *
 */
function* handleUpdatePlaylistNextItem(
  action: PlaylistAction<PlaylistsActionTypes.UPDATE_PLAYLIST_NEXT_ITEM>
): any {
  const { id } = action.payload;

  const courseSummary: CourseSummaryType | undefined = yield select<LxStoreState>(
    PlaylistsSelectors.getFirstIncompleteCourse(id)
  );

  if (!courseSummary) {
    // all completed
    return yield put(PlaylistsActions.updatePlaylistNextItemSuccess(id, { id: null, type: null }));
  }

  const course: CourseType | undefined = yield select<LxStoreState>(s =>
    CourseSelectors.getCourse(courseSummary.courseId, s)
  );

  if (!!course) {
    const lessonIds = course.lessonSummaries.map(l => l.lessonId);
    const lessonId: string | undefined = yield select<LxStoreState>(
      LessonSelectors.getFirstIncompleteLessonId(lessonIds)
    );

    if (!lessonId) {
      // all completed? course should be completed as well?
      return;
    }

    return yield put(
      PlaylistsActions.updatePlaylistNextItemSuccess(id, { id: lessonId, type: 'lesson' })
    );
  }

  // Fetch the course to find out which lesson the user stopped at
  yield put(CourseActions.fetchCourseLessons(courseSummary.courseId));

  type RaceType = {
    success: ActionWithPayload<
      CourseActionTypes.FETCH_COURSE_LESSONS_SUCCESS,
      CourseLessonsPayload
    >;
    failure: any;
  };
  const { success, failure }: RaceType = yield race({
    success: take(CourseActionTypes.FETCH_COURSE_LESSONS_SUCCESS),
    failure: take(CourseActionTypes.FETCH_COURSE_LESSONS_FAILURE)
  });

  if (failure) {
    return;
  }

  const lessonIds = success.payload.map(l => l.lessonId);
  yield put(LessonActions.fetchLessonsProgress(lessonIds));
  yield race({
    success: take(LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS),
    failure: take(LessonActionTypes.FETCH_LESSON_PROGRESS_FAILURE)
  });

  const lessonId: string | undefined = yield select<LxStoreState>(
    LessonSelectors.getFirstIncompleteLessonId(lessonIds)
  );

  if (!lessonId) {
    // all completed? course should be completed as well?
    return;
  }

  yield put(PlaylistsActions.updatePlaylistNextItemSuccess(id, { id: lessonId, type: 'lesson' }));
}

function* handleUpdatePlaylistNextItemSuccess(
  action: PlaylistAction<PlaylistsActionTypes.UPDATE_PLAYLIST_NEXT_ITEM_SUCCESS>
) {
  const { nextItem } = action.payload;

  if (nextItem.id && nextItem.type === 'lesson') {
    yield put(LessonActions.fetchLessonWithProgress(nextItem.id, false));
    yield race({
      success: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS),
      failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
    });
  }
}

export function* watchUpdatePlaylistNextItemSuccess() {
  yield takeLatest(
    PlaylistsActionTypes.UPDATE_PLAYLIST_NEXT_ITEM_SUCCESS,
    handleUpdatePlaylistNextItemSuccess
  );
}

export function* watchUpdatePlaylistNextItem() {
  yield takeLatest(PlaylistsActionTypes.UPDATE_PLAYLIST_NEXT_ITEM, handleUpdatePlaylistNextItem);
}
