import type { RapidRefreshesState } from './types';

export const initialRapidRefreshesState: RapidRefreshesState = {
  loading: false,
  error: '',
  errorCode: undefined,
  items: [],
  totalCount: 0,
  summaryDialogOpen: false,
  currentScore: 0,
  currentInteractions: []
};
