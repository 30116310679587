import { put, race, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorCode } from '@edapp/request';
import { RegisterActions, RegisterActionsTypes } from '@maggie/store/register/actions';
import { getErrorMessage } from '@maggie/store/request/errors';

import { SessionActionTypes, SessionActions } from '../actions';
import type { SessionAction } from '../actions';

function* handleLoginUserInvited(action: SessionAction<SessionActionTypes.LOGIN_USER_INVITED>) {
  // user is invited - first time login
  const { app, username } = action.payload;
  itly.invitedUserLogin();

  yield put(RegisterActions.fetchInviteCodeSettings(app));
  const { failure } = yield race({
    success: take(RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_SUCCESS),
    failure: take(RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_FAILURE)
  });

  if (failure) {
    const error: EdErrorResponseType = {
      type: 'Error',
      code: ErrorCode.NotSpecified, // error-server
      message: getErrorMessage()
    };
    yield put(SessionActions.loginFailure(error, 'email-invite'));
    return;
  }

  // redirect to register page to fill in name, custom fields, etc
  window.__router.navigate('register', { code: 'invited', query: { email: username } });
}

export function* watchLoginUserInvited() {
  yield takeLatest(SessionActionTypes.LOGIN_USER_INVITED, handleLoginUserInvited);
}
