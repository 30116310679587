import { put, select, takeLatest } from 'redux-saga/effects';

import type { RankingParticipant } from '@edapp/leaderboards';
import type { ThomasSlideInteraction } from '@maggie/core/lessons/thomas/thomas-interaction-types';
import { LeaderboardsActions } from '@maggie/store/leaderboards/actions';
import { LeaderboardsSelectors } from '@maggie/store/leaderboards/selectors';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';
import type { UserState } from '@maggie/store/user/types';

import { RapidRefreshActionTypes, RapidRefreshActions } from './actions';
import { RapidRefreshSelectors } from './selectors';
import type { RapidRefreshAction, RapidRefreshType } from './types';

function* handleCompleteRapidRefresh(
  action: RapidRefreshAction<RapidRefreshActionTypes.COMPLETE_RAPID_REFRESH>
) {
  const { interactions } = action.payload;

  const user: UserState = yield select<LxStoreState>(UserSelectors.getUser);
  const { rapidRefreshId, session } = yield select<LxStoreState>(
    s => s.navigation.rapidRefreshSessionId
  );

  const participant: RankingParticipant | undefined = yield select<LxStoreState>(
    LeaderboardsSelectors.findParticipantFromRapidRefreshRanking(rapidRefreshId, user.id)
  );

  const rapidRefresh: RapidRefreshType | undefined = yield select<LxStoreState>(
    RapidRefreshSelectors.getRapidRefreshItem(rapidRefreshId, session)
  );

  const totalScore = interactions
    .filter((i: ThomasSlideInteraction) => i.correct)
    .map((i: ThomasSlideInteraction) => rapidRefresh?.slideScoreWeights[i.id] ?? 0)
    .reduce((totalScore, score) => totalScore + score, 0);

  const score = totalScore * 100;

  // To make sure we're not mutating the datastore
  let copyParticipant = participant;
  if (!participant) {
    // create new participant of leaderboard
    copyParticipant = {
      isMe: true,
      participantId: user.id,
      participantName: UserSelectors.getUserFriendlyName(user),
      totalScore: score,
      rank: 0,
      totalParticipants: 0,
      type: 'individual',
      isNotAttempted: false
    };
  } else {
    // update existing participant of leaderboard
    copyParticipant = {
      ...participant,
      totalScore: participant.totalScore + score,
      isNotAttempted: false
    };
  }

  yield put(
    LeaderboardsActions.setRapidRefreshLeaderboardParticipant(rapidRefreshId, copyParticipant)
  );

  yield put(RapidRefreshActions.updateRapidRefreshScore(score));
}

export function* watchCompleteRapidRefresh() {
  yield takeLatest(RapidRefreshActionTypes.COMPLETE_RAPID_REFRESH, handleCompleteRapidRefresh);
}
