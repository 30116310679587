import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import { PlaylistsActionTypes } from './actions';
import { initialPlaylistsState } from './constants';
import type { PlaylistsActionsUnionType, PlaylistsState } from './types';

export const playlistsReducer = (
  state: PlaylistsState = initialPlaylistsState,
  action: PlaylistsActionsUnionType
): PlaylistsState => {
  switch (action.type) {
    case PlaylistsActionTypes.FETCH_PLAYLISTS: {
      return {
        ...state,
        fetchPlaylistsLoading: true,
        fetchPlaylistsError: ''
      };
    }
    case PlaylistsActionTypes.FETCH_PLAYLISTS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return {
        ...state,
        fetchPlaylistsError: message,
        fetchPlaylistsLoading: false
      };
    }
    case PlaylistsActionTypes.FETCH_PLAYLISTS_SUCCESS: {
      const { items, totalCount } = action.payload;

      return {
        ...state,
        playlists: items,
        totalCount,
        fetchPlaylistsError: '',
        fetchPlaylistsLoading: false
      };
    }

    case PlaylistsActionTypes.FETCH_PLAYLIST_ITEM: {
      return {
        ...state,
        fetchPlaylistItemLoading: true,
        fetchPlaylistItemError: '',
        fetchPlaylistItemErrorCode: undefined
      };
    }
    case PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_SUCCESS: {
      const playlistItem = action.payload;

      return {
        ...state,
        playlistItems: {
          ...state.playlistItems,
          [playlistItem.id]: {
            ...(state.playlistItems[playlistItem.id] ?? {}),
            ...playlistItem
          }
        },
        fetchPlaylistItemError: '',
        fetchPlaylistItemErrorCode: undefined,
        fetchPlaylistItemLoading: false
      };
    }
    case PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES_SUCCESS: {
      return {
        ...state,
        playlistProgressesByCourseId: {
          // create a reverse look-up - course -> playlist -> status
          ...action.payload.playlists.reduce((result, progressItem) => {
            for (const { courseId, isLocked } of progressItem.courses) {
              result[courseId] = {
                ...result[courseId],
                [progressItem.playlistId]: isLocked || false
              };
            }
            return result;
          }, state.playlistProgressesByCourseId)
        }
      };
    }
    case PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      const errorCode = action.payload?.code;

      return {
        ...state,
        fetchPlaylistItemLoading: false,
        fetchPlaylistItemError: message,
        fetchPlaylistItemErrorCode: errorCode
      };
    }

    case PlaylistsActionTypes.UPDATE_PLAYLIST_NEXT_ITEM_SUCCESS: {
      const { id, nextItem } = action.payload;
      return {
        ...state,
        playlistItems: {
          ...state.playlistItems,
          [id]: {
            ...state.playlistItems[id],
            nextItem
          }
        },
        fetchPlaylistItemError: '',
        fetchPlaylistItemLoading: false
      };
    }

    default:
      return state;
  }
};
