/**
 * @file This file manages the PowerManagement plugin that offers access to the devices power management functionality.
 */

import { Platform } from './platform';

export interface CordovaPowerManagement {
  acquire: (successCallback: () => void, errorCallback: () => void) => void;
  dim: (successCallback: () => void, errorCallback: () => void) => void;
  release: (successCallback: () => void, errorCallback: () => void) => void;
  setReleaseOnPause: (
    enabled: boolean,
    successCallback: () => void,
    errorCallback: () => void
  ) => void;
}

export class PowerManagement {
  static hasPowerManagement = () =>
    window.powerManagement &&
    (window.device != null ? window.device.platform : undefined) !== 'iOS' &&
    Platform.isCordova();
  /**
   * Acquire a wakelock from the device. It is not neccessary to await this promise.
   *
   * @static
   * @param {boolean} allowDim Acquire a partial wakelock, allowing the screen to be dimmed.
   * @returns Promise<void>
   * @memberof PowerManagement
   */
  static async acquireWakeLock(allowDim: boolean) {
    return new Promise<void>((resolve, reject) => {
      if (!this.hasPowerManagement()) {
        return resolve();
      }
      if (allowDim) {
        return window.powerManagement.dim(resolve, reject);
      } else {
        return window.powerManagement.acquire(resolve, reject);
      }
    });
  }

  /**
   * Release the wakelock. It's important to do this when you're finished with the wakelock, to avoid unnecessary
   * battery drain. It is not neccessary to await this promise.
   *
   * @static
   * @returns Promise<void>
   * @memberof PowerManagement
   */
  static async releaseWakeLock() {
    return new Promise<void>((resolve, reject) => {
      if (!this.hasPowerManagement()) {
        return resolve();
      }
      window.powerManagement.release(resolve, reject);
    });
  }

  /**
   * Initialize the power plugin. On Android this will call 'setReleaseOnPause' to false. It is not neccessary to
   * await this promise.
   *
   * @static
   * @returns Promise<void>
   * @memberof PowerManagement
   */
  static async initialize() {
    return new Promise<void>((resolve, reject) => {
      if (window.device && window.device.platform === 'Android') {
        window.powerManagement.setReleaseOnPause(false, resolve, reject);
      } else {
        resolve();
      }
    });
  }
}
