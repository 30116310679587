import { CourseActionTypes } from '@maggie/store/courseware/courses/actions';
import type { CourseActionsUnionType } from '@maggie/store/courseware/courses/types';
import { DocumentActionTypes } from '@maggie/store/courseware/documents/actions';
import type { DocumentsActionsUnionType } from '@maggie/store/courseware/documents/types';
import { StarActionTypes } from '@maggie/store/star/actions';
import type { StarActionUnionType } from '@maggie/store/star/types';

import { PerformanceActionTypes } from './actions';
import type { PerformanceActionsUnionType, PerformanceState } from './types';

export const initialPerformanceState: PerformanceState = {
  coursePerformances: [],
  totalCoursePerformances: -1,
  isFetching: false,
  currentPage: 1,
  pageSize: 25
};

export const performanceReducer = (
  state: PerformanceState = initialPerformanceState,
  action:
    | PerformanceActionsUnionType
    | StarActionUnionType
    | CourseActionsUnionType
    | DocumentsActionsUnionType
): PerformanceState => {
  switch (action.type) {
    case PerformanceActionTypes.RESET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: 1
      };

    case PerformanceActionTypes.FETCH_USER_PERFORMANCE:
      return {
        ...state,
        isFetching: true
      };

    case PerformanceActionTypes.FETCH_USER_PERFORMANCE_SUCCESS:
      return {
        coursePerformances:
          state.currentPage === 1
            ? [...action.payload.items]
            : [...state.coursePerformances, ...action.payload.items],
        totalCoursePerformances: action.payload.totalCount,
        currentPage: state.currentPage + 1,
        isFetching: false,
        pageSize: state.pageSize
      };

    case PerformanceActionTypes.FETCH_USER_PERFORMANCE_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    case StarActionTypes.REWARD_STARS_FROM_SLIDE: {
      const { courseId, stars } = action.payload;
      const matchingCourseIndex = state.coursePerformances.findIndex(
        perf => perf.courseId === courseId
      );

      if (matchingCourseIndex === -1) {
        return state;
      }

      const performanceForCourse = state.coursePerformances[matchingCourseIndex];
      const updatedPerformanceForCourse = {
        ...performanceForCourse,
        starsEarned: performanceForCourse.starsEarned + stars
      };

      return {
        ...state,
        coursePerformances: [
          ...state.coursePerformances.slice(0, matchingCourseIndex),
          updatedPerformanceForCourse,
          ...state.coursePerformances.slice(matchingCourseIndex + 1)
        ]
      };
    }

    case CourseActionTypes.UPDATE_COURSE_COMPLETED: {
      const { courseId, lessonsCompleted } = action.payload;
      const matchingCourseIndex = state.coursePerformances.findIndex(
        perf => perf.courseId === courseId
      );

      if (matchingCourseIndex === -1) {
        return state;
      }

      const performanceForCourse = state.coursePerformances[matchingCourseIndex];
      const updatedPerformanceForCourse = {
        ...performanceForCourse,
        lessonsCompleted
      };

      return {
        ...state,
        coursePerformances: [
          ...state.coursePerformances.slice(0, matchingCourseIndex),
          updatedPerformanceForCourse,
          ...state.coursePerformances.slice(matchingCourseIndex + 1)
        ]
      };
    }

    case DocumentActionTypes.UPDATE_OPEN_DOCUMENT: {
      const { courseId } = action.payload;
      const matchingCourseIndex = state.coursePerformances.findIndex(
        perf => perf.courseId === courseId
      );

      if (matchingCourseIndex === -1) {
        return state;
      }

      const performanceForCourse = state.coursePerformances[matchingCourseIndex];
      const updatedPerformanceForCourse = {
        ...performanceForCourse,
        documentsOpened: performanceForCourse.documentsOpened + 1
      };

      return {
        ...state,
        coursePerformances: [
          ...state.coursePerformances.slice(0, matchingCourseIndex),
          updatedPerformanceForCourse,
          ...state.coursePerformances.slice(matchingCourseIndex + 1)
        ]
      };
    }

    default:
      return state;
  }
};
