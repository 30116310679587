import type { Buffer } from 'redux-saga';

import type { OfflineActionTypes } from './actions';
import type { OfflineActionsMap } from './types';

/**
 * The action buffer implementation for the download queue.
 * We need a custom implementation since the native downloader can't handle duplicate file downloads,
 * which can happen when lessons share similar assets or courses.
 *
 * @class DownloadBuffer
 * @implements {Buffer<OfflineActionsMap<OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE>>}
 */
class DownloadBuffer
  implements Buffer<OfflineActionsMap<OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE>> {
  buffer: Array<OfflineActionsMap<OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE>>;
  constructor() {
    this.buffer = [];
  }
  put(action: OfflineActionsMap<OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE>) {
    this.buffer.push(action);
  }
  take() {
    return this.buffer.shift();
  }
  isEmpty() {
    return this.buffer.length === 0;
  }
  flush() {
    this.buffer = [];
  }
  remove(lessonId: string) {
    this.buffer = this.buffer.filter(a => a.payload.lessonId !== lessonId);
  }
}

export const sharedDownloadBuffer = new DownloadBuffer();
