import { takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { Tracker } from '@maggie/core/tracker/tracker';

import { CourseActionTypes } from './actions';
import type { CourseAction } from './sagas';

function* handleUpdateCourseOpened({
  payload: { courseId, courseVersionNumber }
}: CourseAction<CourseActionTypes.UPDATE_COURSE_OPENED>) {
  Tracker.trackEvent({
    type: 'event',
    name: 'open-course',
    id: courseId,
    courseVersionNumber: courseVersionNumber
  });

  itly.openCourse({
    id: courseId
  });
}

export function* watchUpdateCourseOpened() {
  yield takeLatest(CourseActionTypes.UPDATE_COURSE_OPENED, handleUpdateCourseOpened);
}
