import type { RankingParticipant } from '@edapp/leaderboards';
import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type {
  LeaderboardInstance,
  LeaderboardInstanceListItem
} from '@maggie/containers/leaderboard/LeaderboardRequests';

import type { RapidRefreshLeaderboardType } from './types';

enum LeaderboardsActionTypes {
  FETCH_LEADERBOARDS = 'FETCH_LEADERBOARDS',
  FETCH_LEADERBOARDS_SUCCESS = 'FETCH_LEADERBOARDS_SUCCESS',
  FETCH_LEADERBOARDS_FAILURE = 'FETCH_LEADERBOARDS_FAILURE',

  FETCH_LEADERBOARD = 'FETCH_LEADERBOARD',
  FETCH_LEADERBOARD_SUCCESS = 'FETCH_LEADERBOARD_SUCCESS',
  FETCH_LEADERBOARD_FAILURE = 'FETCH_LEADERBOARD_FAILURE',

  FETCH_RAPID_REFRESH_LEADERBOARDS = 'FETCH_RAPID_REFRESH_LEADERBOARDS',
  FETCH_RAPID_REFRESH_LEADERBOARDS_SUCCESS = 'FETCH_RAPID_REFRESH_LEADERBOARDS_SUCCESS',
  FETCH_RAPID_REFRESH_LEADERBOARDS_FAILURE = 'FETCH_RAPID_REFRESH_LEADERBOARDS_FAILURE',

  SET_RAPID_REFRESH_LEADERBOARD_PARTICIPANT = 'SET_RAPID_REFRESH_LEADERBOARD_PARTICIPANT',

  SET_COURSE_LEADERBOARD_RANKINGS = 'SET_COURSE_LEADERBOARD_RANKINGS'
}

const LeaderboardsActions = {
  fetchRapidRefreshLeaderboards: (courseId: string) =>
    createAction(LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS, { courseId }),
  fetchRapidRefreshLeaderboardsSuccess: (courseId: string, rankings: RapidRefreshLeaderboardType) =>
    createAction(LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS_SUCCESS, {
      courseId,
      rankings
    }),
  fetchLeaderboards: () => createAction(LeaderboardsActionTypes.FETCH_LEADERBOARDS),
  fetchLeaderboard: (id: string) => createAction(LeaderboardsActionTypes.FETCH_LEADERBOARD, { id }),

  fetchLeaderboardSuccess: (leaderboard: LeaderboardInstance) =>
    createAction(LeaderboardsActionTypes.FETCH_LEADERBOARD_SUCCESS, leaderboard),

  fetchLeaderboardsSuccess: (leaderboards: LeaderboardInstanceListItem[]) =>
    createAction(LeaderboardsActionTypes.FETCH_LEADERBOARDS_SUCCESS, leaderboards),

  setRapidRefreshLeaderboardParticipant: (courseId: string, participant: RankingParticipant) =>
    createAction(LeaderboardsActionTypes.SET_RAPID_REFRESH_LEADERBOARD_PARTICIPANT, {
      courseId,
      participant
    }),

  setCourseLeaderboardRankings: (rankings: RankingParticipant[] | undefined) =>
    createAction(LeaderboardsActionTypes.SET_COURSE_LEADERBOARD_RANKINGS, {
      rankings
    })
};

type LeaderboardsActionUnionType =
  | ActionsUnion<typeof LeaderboardsActions>
  | ActionWithPayload<
      LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS_FAILURE,
      EdErrorResponseType
    >
  | ActionWithPayload<LeaderboardsActionTypes.FETCH_LEADERBOARDS_FAILURE, EdErrorResponseType>
  | ActionWithPayload<LeaderboardsActionTypes.FETCH_LEADERBOARD_FAILURE, EdErrorResponseType>;
export { LeaderboardsActionUnionType, LeaderboardsActionTypes, LeaderboardsActions };
