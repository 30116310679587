import { initialConfigState } from './constants';
import type { ConfigActionsUnionType, ConfigState } from './types';
import { ConfigActionTypes } from './actions';

const configReducer = (state = initialConfigState, action: ConfigActionsUnionType): ConfigState => {
  switch (action.type) {
    case ConfigActionTypes.SET_PLATFORM:
      return {
        ...state,
        platform: action.payload.platform
      };

    case ConfigActionTypes.SET_RESTRICT_TO_COURSE_SCREEN: {
      const { restricted } = action.payload;
      return { ...state, restrictToCourseScreen: restricted };
    }

    case ConfigActionTypes.SET_RESTRICT_TO_LESSON_SCREEN: {
      const { restricted } = action.payload;
      return { ...state, restrictToLessonScreen: restricted };
    }

    case ConfigActionTypes.SET_MOBILE_PUSH_TOKEN: {
      return {
        ...state,
        devicePushNotificationToken: action.payload.token
      };
    }

    case ConfigActionTypes.SET_ONLINE: {
      return {
        ...state,
        isOnline: action.payload.online
      };
    }

    case ConfigActionTypes.SET_IS_SLOW_CONNECTION: {
      const { isSlowConnection } = action.payload;
      return { ...state, isSlowConnection };
    }

    case ConfigActionTypes.SET_ORIENTATION: {
      return { ...state, orientation: action.payload.orientation };
    }

    default: {
      return state;
    }
  }
};

export default configReducer;
