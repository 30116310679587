// we use the 'main' width to centralise content
// and margin:auto auto
// we might need to provide a "fluid" setting to override the behaviour above`

export const splitViewLayoutSizes = {
  sm: {
    width: 400,
    breakpoint: 950
  },
  md: {
    width: 460,
    breakpoint: 950
  },
  lg: {
    width: 550,
    breakpoint: 1024
  }
};
