import { createAction } from '@edapp/utils';

enum LoadingActionTypes {
  START_COURSEWARE_LOADING = 'START_COURSEWARE_LOADING',
  STOP_COURSEWARE_LOADING = 'STOP_COURSEWARE_LOADING'
}

const LoadingActions = {
  startLoading: () => createAction(LoadingActionTypes.START_COURSEWARE_LOADING),
  stopLoading: () => createAction(LoadingActionTypes.STOP_COURSEWARE_LOADING)
};

export { LoadingActions, LoadingActionTypes };
