import { put, select, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import type { LxStoreState } from '@maggie/store/types';

import { LessonSelectors } from '../lessons/selectors';
import { CourseActionTypes, CourseActions } from './actions';
import type { CourseAction } from './sagas';
import { CourseSelectors } from './selectors';
import type { CourseType } from './types';

function* handleCalculateCourseCompletion(
  action: CourseAction<CourseActionTypes.CALCULATE_COURSE_COMPLETION>
) {
  const { courseId } = action.payload;
  const course: CourseType | undefined = yield select<LxStoreState>(s =>
    CourseSelectors.getCourse(courseId, s)
  );
  if (!course) {
    ErrorLogger.captureEvent('CALCULATE_COURSE_COMPLETION of a course that doesnt exist', 'error', {
      courseId
    });
    return;
  }

  if (course.lessonSummaries.length === 0) {
    // Do not complete course if it has no lessons
    return;
  }

  const wasCompletedBefore: boolean = yield select<LxStoreState>(
    s => !!CourseSelectors.getCourseProgress(course.id, s)?.completed
  );

  type CompletionType = ReturnType<ReturnType<typeof LessonSelectors.getCourseCompletion>>;
  const completion: CompletionType = yield select<LxStoreState>(
    LessonSelectors.getCourseCompletion(course.lessonSummaries, course.completionCriteria)
  );

  yield put(
    CourseActions.updateCourseCompleted(
      course.id,
      completion.isCompleted,
      wasCompletedBefore,
      completion.percentageCompleted,
      completion.completedLessonsIds.length,
      completion.unlockedLessonsIds.length
    )
  );
}

export function* watchCalculateCourseCompletion() {
  yield takeLatest(CourseActionTypes.CALCULATE_COURSE_COMPLETION, handleCalculateCourseCompletion);
}
