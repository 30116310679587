import type { ContentLibraryActionUnionType } from '@edapp/content-library';
import type { ErrorResponseType, RequestTypes } from '@edapp/request';
import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { CourseActionsUnionType } from '@maggie/store/courseware/courses/types';

import type { MicroCredential } from './types';

export enum MicroCredentialActionTypes {
  POLL_MICRO_CREDENTIAL = 'POLL_MICRO_CREDENTIAL',
  FETCH_MICRO_CREDENTIAL = 'FETCH_MICRO_CREDENTIAL',
  FETCH_MICRO_CREDENTIAL_SUCCESS = 'FETCH_MICRO_CREDENTIAL_SUCCESS',
  FETCH_MICRO_CREDENTIAL_FAILURE = 'FETCH_MICRO_CREDENTIAL_FAILURE',

  FETCH_EARNED_MICRO_CREDENTIALS = 'FETCH_EARNED_MICRO_CREDENTIALS',
  FETCH_EARNED_MICRO_CREDENTIALS_SUCCESS = 'FETCH_EARNED_MICRO_CREDENTIALS_SUCCESS',
  FETCH_EARNED_MICRO_CREDENTIALS_FAILURE = 'FETCH_EARNED_MICRO_CREDENTIALS_FAILURE'
}

export const MicroCredentialActions = {
  pollMicroCredential: (id: string) =>
    createAction(MicroCredentialActionTypes.POLL_MICRO_CREDENTIAL, { id }),
  fetchMicroCredential: (id: string) =>
    createAction(MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL, { id }),
  fetchEarnedMicroCredentials: (page: number) =>
    createAction(MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS, { page })
};

export type MicroCredentialActionsUnionType =
  | ActionsUnion<typeof MicroCredentialActions>
  | ContentLibraryActionUnionType
  | CourseActionsUnionType
  | ActionWithPayload<MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL_SUCCESS, MicroCredential>
  | ActionWithPayload<MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL_FAILURE, ErrorResponseType>
  | ActionWithPayload<
      MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS_SUCCESS,
      RequestTypes.PaginatedResponse<MicroCredential>
    >
  | ActionWithPayload<
      MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS_FAILURE,
      ErrorResponseType
    >;
