import { delay } from 'redux-saga';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import type { RouteParams } from '@maggie/core/router/types';
import type { LoginRouteError } from '@maggie/store/session/types';
import { SplitViewLayoutActions } from '@maggie/store/split-view-layout/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserActions } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

import { NavigationActionTypes, NavigationActions } from './actions';
import { watchFallbackDefaultRoute } from './fallback-default-route-sagas';
import { watchGoBack } from './go-back-sagas';
import { hasBillingAccess, hasRouteAccess, hasVerifiedAccess } from './handlers/access-sagas';
import { handleDidNavigateToAccountSettings } from './handlers/account-settings-sagas';
import { handleDidNavigateToAchievements } from './handlers/achievement-sagas';
import { handleDidNavigateToAssessment } from './handlers/assessment-sagas';
import { handleDidNavigateToAwardedCertificates } from './handlers/awarded-certificate-sagas';
import { handleDidNavigateToBrainBoost } from './handlers/brainboost-sagas';
import { handleDidNavigateToBriefcaseDocument } from './handlers/briefcase-document-sagas';
import { checkForRouteQueryParams } from './handlers/check-params-sagas';
import { handleDidNavigateToConference } from './handlers/conference-sagas';
import { handleDidNavigateToContentLibrary } from './handlers/content-library-sagas';
import { handleDidNavigateToContentLibrarySection } from './handlers/content-library-section-sagas';
import { handleDidNavigateToCourseCollection } from './handlers/course-collection-sagas';
import { handleDidNavigateToCourse } from './handlers/course-sagas';
import { handleDidNavigateToDiscussion } from './handlers/discussion-sagas';
import { handleDidNavigateToForgotPassword } from './handlers/forgot-password-sagas';
import { handleDidNavigateToGame } from './handlers/game-sagas';
import { handleDidNavigateGroupTraining } from './handlers/group-training-sagas';
import { handleDidNavigateToHome } from './handlers/home-sagas';
import { handleDidNavigateToHomeSection } from './handlers/home-section-sagas';
import { handleDidNavigateToLaunchLesson } from './handlers/launch-lesson-sagas';
import { handleDidNavigateToLeaderboard } from './handlers/leaderboard-sagas';
import { handleDidNavigateToLeaderboards } from './handlers/leaderboards-sagas';
import { handleDidNavigateToLessonPlay } from './handlers/lesson-play-sagas';
import { handleDidNavigateToLessonReview } from './handlers/lesson-review-sagas';
import { handleDidNavigateToLesson } from './handlers/lesson-sagas';
import { handleDidNavigateToLogin } from './handlers/login-sagas';
import { handleDidNavigateToMagicLink } from './handlers/magic-link-sagas';
import { handleDidNavigateToMicroCredentials } from './handlers/micro-credentials-sagas';
import { handleDidNavigateToObservationFeedback } from './handlers/observation-feedback-sagas';
import { handleDidNavigateToObservationResults } from './handlers/observation-results-sagas';
import { handleDidNavigateToObservation } from './handlers/observation-sagas';
import { handleDidNavigateToPerformance } from './handlers/performance-sagas';
import { handleDidNavigateToPlaylist } from './handlers/playlist-sagas';
import { handleDidNavigateToProfile } from './handlers/profile-sagas';
import { handleDidNavigateToRapidRefresh } from './handlers/rapid-refresh-sagas';
import { handleDidNavigateToRegister } from './handlers/register-sagas';
import { handleDidNavigateToResetPassword } from './handlers/reset-password-sagas';
import { handleDidNavigateToSignUp } from './handlers/sign-up-sagas';
import { handleDidNavigateToSocialLearning } from './handlers/social-learning-sagas';
import { handleDidNavigateToSSOLoginFailure } from './handlers/sso-login-failure-sagas';
import { handleDidNavigateToSubmission } from './handlers/submission-sagas';
import { handleDidNavigateToThread } from './handlers/thread-sagas';
import { authenticateWithSSO } from './query-params-handlers/authenticate-sso-sagas';
import { Redirection } from './redirection';
import type { NavigationAction } from './types';
import { getLocationPath, strictQueryParams } from './utils';

export function* handleDidNavigateRoute(
  action: NavigationAction<NavigationActionTypes.DID_NAVIGATE_ROUTE>
): any {
  const { route, params } = action.payload;

  const verifiedAccess = yield hasVerifiedAccess(route);
  if (!verifiedAccess) {
    return window.__router.navigate('userNotVerified', {});
  }

  const isRedirected = yield call(authenticateWithSSO, route, params);
  if (isRedirected) {
    return;
  }

  /**
   * Only start poll sync user after SSO is finished.
   *
   * The reason for this is to avoid any other HTTP request
   * to be triggered before the session has been validated.
   */
  yield put(UserActions.startPollSyncUser());

  yield checkForRouteQueryParams(route, params);

  const hasAccessToBilling = yield hasBillingAccess(route);
  if (!hasAccessToBilling) {
    return window.__router.navigate('home', {}); // home displays message for billing
  }
  const hasAccessToRoute = yield hasRouteAccess(route);
  if (!hasAccessToRoute) {
    Redirection.storeRedirect(getLocationPath());
    return window.__router.navigate('login', {});
  }

  switch (route) {
    case 'contentLibrary': {
      yield call(handleDidNavigateToContentLibrary);
      break;
    }

    case 'contentLibraryCourse': {
      const { courseId } = params as RouteParams<'contentLibraryCourse'>;
      yield put(NavigationActions.didNavigateToContentLibraryCourse(courseId));
      break;
    }

    case 'contentLibrarySection': {
      const { sectionId } = params as RouteParams<'contentLibrarySection'>;
      yield put(NavigationActions.didNavigateToContentLibrarySection(sectionId));
      yield call(handleDidNavigateToContentLibrarySection, sectionId);
      break;
    }

    case 'signup': {
      yield call(handleDidNavigateToSignUp);
      break;
    }

    case 'game': {
      yield call(handleDidNavigateToGame);
      break;
    }

    case 'performance': {
      yield call(handleDidNavigateToPerformance);
      break;
    }

    case 'achievements': {
      yield call(handleDidNavigateToAchievements);
      break;
    }

    case 'awardedCertificates': {
      yield call(handleDidNavigateToAwardedCertificates);
      break;
    }

    case 'brainBoost': {
      yield call(handleDidNavigateToBrainBoost);
      break;
    }

    case 'profile': {
      const user: LxStoreState['user'] = yield select<LxStoreState>(state => state.user);
      if (!user.ed.myProfileEnabled) {
        window.__router.navigate('home', {}, true);
        break;
      }

      yield call(handleDidNavigateToProfile);
      break;
    }

    case 'forgotPassword': {
      type Params = { email?: string };
      const { query } = strictQueryParams<'forgotPassword', Params>(params);
      yield call(handleDidNavigateToForgotPassword, query.email);
      break;
    }

    case 'magicLink': {
      type Params = { email: string };
      const { query } = strictQueryParams<'magicLink', Params>(params);
      yield call(handleDidNavigateToMagicLink, query.email);
      break;
    }

    case 'resetPassword': {
      type Params = { email?: string; secret?: string };
      const { query } = strictQueryParams<'resetPassword', Params>(params);
      yield call(handleDidNavigateToResetPassword, query.email, query.secret);
      break;
    }

    case 'home': {
      yield put(NavigationActions.didNavigateToHome());
      yield call(handleDidNavigateToHome);
      break;
    }

    case 'homeSection': {
      const { sectionType } = params as RouteParams<'homeSection'>;
      yield call(handleDidNavigateToHomeSection, sectionType);
      break;
    }

    case 'login': {
      type Params = { error?: LoginRouteError; page?: string };
      const { query } = strictQueryParams<'forgotPassword', Params>(params);
      yield put(NavigationActions.didNavigateToLogin(query.error, query.page));
      yield call(handleDidNavigateToLogin, query.error, query.page);
      break;
    }

    case 'lesson': {
      const { id } = params as RouteParams<'lesson'>;
      yield put(NavigationActions.didNavigateToLesson(id, undefined));
      yield call(handleDidNavigateToLesson, id, undefined);
      break;
    }

    case 'lessonPlay': {
      type Params = { slideIndex: number };
      const { id, query } = strictQueryParams<'lessonPlay', Params>(params as any);
      yield put(NavigationActions.didNavigateToLessonPlay(id, query.slideIndex));
      yield call(handleDidNavigateToLessonPlay, id, query.slideIndex);
      break;
    }

    case 'oldLessonReview': {
      const { courseId, lessonId } = params as RouteParams<'oldLessonReview'>;
      window.__router.navigate('lessonReview', { courseId, lessonId }, true);
      break;
    }

    case 'lessonReview': {
      const { courseId, lessonId } = params as RouteParams<'lessonReview'>;
      yield call(handleDidNavigateToLessonReview, courseId, lessonId);
      break;
    }

    case 'launchLesson': {
      type Params = { lesson: string };
      const { query } = strictQueryParams<'launchLesson', Params>(params);
      yield put(NavigationActions.didNavigateToLaunchLesson(query.lesson));
      yield call(handleDidNavigateToLaunchLesson, query.lesson);
      break;
    }

    case 'conference': {
      const { id } = params as RouteParams<'conference'>;
      yield put(NavigationActions.didNavigateToConference(id));
      yield call(handleDidNavigateToConference, id);
      break;
    }

    case 'oldConferenceRoute': {
      const { conferenceId } = params as RouteParams<'oldConferenceRoute'>;
      window.__router.navigate('conference', { id: conferenceId }, true);
      break;
    }

    case 'assignment': {
      const { id } = params as RouteParams<'assignment'>;
      yield put(NavigationActions.didNavigateToAssessment(id));
      yield call(handleDidNavigateToAssessment, id);
      break;
    }

    case 'submission': {
      const { assessmentId, postId } = params as RouteParams<'submission'>;
      yield put(NavigationActions.didNavigateToSubmission(postId, assessmentId));
      yield call(handleDidNavigateToSubmission, postId, assessmentId);
      break;
    }

    case 'discussion': {
      const { id } = params as RouteParams<'discussion'>;
      yield put(NavigationActions.didNavigateToDiscussion(id));
      yield call(handleDidNavigateToDiscussion, id);
      break;
    }

    case 'thread': {
      const { discussionId, postId } = params as RouteParams<'thread'>;
      yield put(NavigationActions.didNavigateToThread(postId, discussionId));
      yield call(handleDidNavigateToThread, postId, discussionId);
      break;
    }

    case 'course': {
      type Params = { playlistId?: string; collectionId?: string };
      const { id, query } = strictQueryParams<'course', Params>(params as RouteParams<'course'>);
      yield put(NavigationActions.didNavigateToCourse(id, query));
      yield call(handleDidNavigateToCourse, id);
      break;
    }

    case 'microCredentials': {
      yield call(handleDidNavigateToMicroCredentials);
      break;
    }

    // new one - after split view
    case 'briefcaseDocument': {
      const { id, documentId } = params as RouteParams<'briefcaseDocument'>;
      yield put(NavigationActions.didNavigateToBriefcaseDocument(id, documentId));
      yield call(handleDidNavigateToBriefcaseDocument, documentId);
      break;
    }

    case 'oldPlaylist': {
      const { id } = params as RouteParams<'oldPlaylist'>;
      window.__router.navigate('playlist', { id }, true);
      break;
    }

    case 'playlist': {
      const { id } = params as RouteParams<'playlist'>;
      yield put(NavigationActions.didNavigateToPlaylist(id));
      yield call(handleDidNavigateToPlaylist, id);
      break;
    }

    case 'courseCollection': {
      const { id } = params as RouteParams<'courseCollection'>;
      yield put(NavigationActions.didNavigateToCourseCollection(id));
      yield call(handleDidNavigateToCourseCollection, id);
      break;
    }

    case 'register': {
      type Params = {
        /**
         * Used to prefill input when users are invited via email
         */
        email?: string;
        /**
         * Used for individual learners when self-enroll from public website
         */
        courseId?: string;
      };
      const { code, query } = strictQueryParams<'register', Params>(
        params as RouteParams<'register'>
      );
      yield put(NavigationActions.didNavigateToRegister(code, query.email, query.courseId));
      yield call(handleDidNavigateToRegister, code, query.email, query.courseId);
      break;
    }

    case 'peerAuthoringLesson': {
      const { id, slideId } = params as RouteParams<'peerAuthoringLesson'>;
      yield put(NavigationActions.didNavigateToLesson(id, slideId));
      yield call(handleDidNavigateToLesson, id, slideId);
      break;
    }

    // This opens the social learning page
    case 'socialLearning':
    case 'socialLearningSlide': {
      const { lessonId } = params as RouteParams<'socialLearning'>;
      yield put(NavigationActions.didNavigateToSocialLearning(lessonId));
      yield call(handleDidNavigateToSocialLearning, lessonId);
      break;
    }

    case 'observation': {
      const { id } = params as RouteParams<'observation'>;
      yield put(NavigationActions.didNavigateToObservation(id));
      yield call(handleDidNavigateToObservation, id);
      break;
    }

    case 'observationFeedback': {
      const { observationId } = params as RouteParams<'observationFeedback'>;
      yield put(NavigationActions.didNavigateToObservationFeedback(observationId));
      yield call(handleDidNavigateToObservationFeedback, observationId);
      break;
    }

    case 'observationResults': {
      yield call(handleDidNavigateToObservationResults);
      break;
    }

    case 'ssoLoginFailure': {
      type Params = { error?: string };
      const { query } = strictQueryParams<'ssoLoginFailure', Params>(params);
      yield put(NavigationActions.didNavigateToSSOLoginFailure());
      yield call(handleDidNavigateToSSOLoginFailure, query.error);
      break;
    }

    case 'rapidRefresh': {
      const { id, session } = params as RouteParams<'rapidRefresh'>;
      yield put(NavigationActions.didNavigateToRapidRefresh(id, Number(session)));
      yield call(handleDidNavigateToRapidRefresh, id, Number(session));
      break;
    }

    case 'leaderboard': {
      const { id } = params as RouteParams<'leaderboard'>;
      yield call(handleDidNavigateToLeaderboard, id);
      break;
    }

    case 'leaderboards': {
      yield call(handleDidNavigateToLeaderboards);
      break;
    }

    case 'userNotVerified': {
      const user = yield select<LxStoreState>(state => state.user);
      if (!UserSelectors.isUserUnverified(user)) {
        window.__router.navigate('home', {});
      }
      break;
    }

    case 'groupTraining': {
      const { courseId, sessionId } = params as RouteParams<'groupTraining'>;
      yield call(handleDidNavigateGroupTraining, courseId, sessionId);
      break;
    }

    case 'accountSettings': {
      yield call(handleDidNavigateToAccountSettings);
      break;
    }

    default:
      break; // not handled here yet!
  }

  if (!window.__router.isSplitViewLayout(route)) {
    yield delay(500);
    yield put(SplitViewLayoutActions.clearDetail());
  } else if (window.__router.getMain().routeName === route) {
    yield put(SplitViewLayoutActions.mainNavigate(route, params));
  }
}

function* watchDidNavigateRoute() {
  yield takeLatest(NavigationActionTypes.DID_NAVIGATE_ROUTE, handleDidNavigateRoute);
}

export const navigationSagas = [
  fork(watchDidNavigateRoute),
  fork(watchFallbackDefaultRoute),
  fork(watchGoBack)
];
