interface PeerAuthoringLaunchType {
  slideId: string;
  lessonId: string;
  videoId: string;
}

// A place to store the peer authoring auto comment load properties
// Should be managed by redux store when redux work complete
export class PeerAuthoringLaunchOptions {
  static openCommentWindow: boolean;
  static slideId: string;
  static lessonId: string;
  static videoId: string;

  static setLaunchComment({ slideId, lessonId, videoId }: PeerAuthoringLaunchType) {
    this.openCommentWindow = true;
    this.slideId = slideId;
    this.lessonId = lessonId;
    this.videoId = videoId;
    console.debug('slideId', this.slideId);
    console.debug('lessonId', this.lessonId);
    console.debug('videoId', this.videoId);
  }

  static unsetAutoOpenComment() {
    this.openCommentWindow = false;
    this.slideId = '';
    this.lessonId = '';
    this.videoId = '';
  }
}
