import type { Retry } from './types';
import { DEFAULT_RETRIES, DEFAULT_INTERVAL } from './constants';

export const retry: Retry = (action, options = {}) =>
  new Promise((resolve, reject) => {
    action()
      .then(resolve)
      .catch(error => {
        if (options?.errorFilter && !options?.errorFilter(error)) {
          reject(error);
          return;
        }

        const retries = (options?.retries ?? DEFAULT_RETRIES) - 1;

        if (retries <= 0) {
          reject(error);
          return;
        }

        const interval = options?.interval ?? DEFAULT_INTERVAL;

        setTimeout(() => {
          retry(action, {
            retries,
            interval,
            errorFilter: options.errorFilter
          }).then(resolve, reject);
        }, interval);
      });
  });
