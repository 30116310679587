const REDIRECT_LOCALSTORAGE = 'redirect';

/**
 * Stores a route to redirect to, after user login
 * @param route A hash route to redirect to
 */
const storeRedirect = (route: string) => {
  let redirect = route;

  // Remove the token / businessId / companyId from the query params
  redirect = redirect.replace(/&?(token|companyId|businessId)=[^&]+/, '');

  // Need to remove the & if ?&somethingElse
  redirect = redirect.replace('?&', '?');

  window.localStorage.setItem(REDIRECT_LOCALSTORAGE, redirect);
};

/**
 * Gets the route to redirect to after user login
 */
const getRedirect = () => {
  return window.localStorage.getItem(REDIRECT_LOCALSTORAGE);
};

/**
 * Removes any stored redirect
 */
const removeRedirect = () => {
  window.localStorage.removeItem(REDIRECT_LOCALSTORAGE);
};

export const Redirection = {
  storeRedirect,
  getRedirect,
  removeRedirect
};
