import { put, race, select, take } from 'redux-saga/effects';

import { ConfigSelectors } from '@maggie/store/config/selectors';
import { LessonActionTypes, LessonActions } from '@maggie/store/courseware/lessons/actions';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToLaunchLesson(lessonId: string) {
  yield put(LessonActions.fetchLessonWithProgress(lessonId, false));
  yield race({
    success: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS),
    failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
  });

  const restrictToLessonScreen: boolean = yield select<LxStoreState>(
    ConfigSelectors.getRestrictToLessonScreen
  );
  window.__router.navigate(
    'lesson',
    { id: lessonId, query: !!restrictToLessonScreen ? { restrictToLessonScreen } : {} },
    true,
    !!restrictToLessonScreen
  );
}
