import { createAction } from '@edapp/utils';

enum LocalNotificationActionTypes {
  SCHEDULE_LESSON_NOTIFICATIONS = 'SCHEDULE_LESSON_NOTIFICATIONS',
  SCHEDULE_PRIZING_NOTIFICATIONS = 'SCHEDULE_PRIZING_NOTIFICATIONS',

  SET_LAST_LESSON_SCHEDULED = 'SET_LAST_LESSON_SCHEDULED',

  FETCH_LESSON_NOTIFICATIONS_SUCCESS = 'FETCH_LESSON_NOTIFICATIONS_SUCCESS',
  FETCH_LESSON_NOTIFICATIONS_FAILURE = 'FETCH_LESSON_NOTIFICATIONS_FAILURE'
}

const LocalNotificationActions = {
  scheduleLessonNotifications: (hour: number, minute: number) =>
    createAction(LocalNotificationActionTypes.SCHEDULE_LESSON_NOTIFICATIONS, { hour, minute }),
  schedulePrizingNotifications: (hour: number, minute: number) =>
    createAction(LocalNotificationActionTypes.SCHEDULE_PRIZING_NOTIFICATIONS, { hour, minute }),

  /**
   * We keep the a reference to last lessonId schedule
   * So, we don't reschedule unecessarily
   */
  setLastLessonScheduled: (lessonId: string) =>
    createAction(LocalNotificationActionTypes.SET_LAST_LESSON_SCHEDULED, { lessonId })
};

export { LocalNotificationActionTypes, LocalNotificationActions };
