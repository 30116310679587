// There is a type relying on the first prefix `AUTHED` | `UNAUTHED` in the `./types.ts` called
// If introducing new prefixes, make sure to update FetchAuthorizationType

enum RequestActionTypes {
  AUTHED_GET_REQUEST = 'AUTHED_GET_REQUEST',
  AUTHED_POST_REQUEST = 'AUTHED_POST_REQUEST',
  AUTHED_DELETE_REQUEST = 'AUTHED_DELETE_REQUEST',
  UNAUTHED_GET_REQUEST = 'UNAUTHED_GET_REQUEST',
  UNAUTHED_POST_REQUEST = 'UNAUTHED_POST_REQUEST',
  REQUEST_COMPLETED = 'REQUEST_COMPLETED',
  REQUEST_RESTARTED = 'REQUEST_RESTARTED',
  REQUEST_STOPPED = 'REQUEST_STOPPED',
  AUTHED_PUT_REQUEST = 'AUTHED_PUT_REQUEST',
  AUTHED_PATCH_REQUEST = 'AUTHED_PATCH_REQUEST'
}

export default RequestActionTypes;

export enum StatusCode {
  Forbidden = 403,
  NotFound = 404
}

export enum Direction {
  NotSpecified = 'not-specified',
  Asc = 'asc',
  Desc = 'desc'
}
