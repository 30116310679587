import { put, select, take } from 'redux-saga/effects';

import {
  RapidRefreshActionTypes,
  RapidRefreshActions
} from '@maggie/store/courseware/rapid-refreshes/actions';
import { RapidRefreshSelectors } from '@maggie/store/courseware/rapid-refreshes/selectors';
import type { RapidRefreshType } from '@maggie/store/courseware/rapid-refreshes/types';
import { LeaderboardsActions } from '@maggie/store/leaderboards/actions';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToRapidRefresh(id: string, session: number) {
  const rapidRefresh: RapidRefreshType | undefined = yield select<LxStoreState>(
    RapidRefreshSelectors.getRapidRefreshItem(id, session)
  );

  if (!rapidRefresh) {
    yield put(RapidRefreshActions.fetchRapidRefreshItem(id, session));
    yield take(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_COMPLETED);
  }

  yield put(RapidRefreshActions.didOpenRapidRefresh(id, session));
  yield put(LeaderboardsActions.fetchRapidRefreshLeaderboards(id));
}
