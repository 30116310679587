import { combineReducers } from 'redux';
import type { PersistConfig } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { UserActionTypes } from '@maggie/store/user/actions';
import type { UserActionsUnionType } from '@maggie/store/user/types';

import { assessmentReducer } from './assessments/reducer';
import { awardedCertificatesReducer } from './awarded-certificates/reducer';
import { collectionsReducer } from './collections/reducer';
import { conferenceReducer } from './conferences/reducer';
import { initialCoursewareState } from './constants';
import { courseLanguageReducer } from './course-language/reducer';
import type { CourseLanguageState } from './course-language/types';
import { coursesReducer } from './courses/reducer';
import { discussionsReducer } from './discussions/reducer';
import { documentsReducer } from './documents/reducer';
import { groupTrainingReducer } from './group-training/reducer';
import { lessonsReducer } from './lessons/reducer';
import type { LessonsState } from './lessons/types';
import { coursewareLoadingReducer } from './loading/reducer';
import { microCredentialsReducer } from './micro-credentials/reducer';
import { observationsReducer } from './observations/reducer';
import { playlistsReducer } from './playlists/reducer';
import { rapidRefreshesReducer } from './rapid-refreshes/reducer';
import type { CoursewareState } from './types';

const lessonsPersistConfig: PersistConfig<LessonsState> = {
  key: 'ed:lessons',
  storage,
  whitelist: ['lessonsAttempts'],
  version: 0
};

const courseLanguagePersistConfig: PersistConfig<CourseLanguageState> = {
  key: 'ed:course-language',
  storage,
  version: 0
};

const reducers = combineReducers<CoursewareState>({
  collections: collectionsReducer,
  courses: coursesReducer,
  documents: documentsReducer,
  lessons: persistReducer(lessonsPersistConfig, lessonsReducer),
  isLoading: coursewareLoadingReducer,
  playlists: playlistsReducer,
  discussions: discussionsReducer,
  assessments: assessmentReducer,
  conferences: conferenceReducer,
  microCredentials: microCredentialsReducer,
  rapidRefreshes: rapidRefreshesReducer,
  observations: observationsReducer,
  awardedCertificates: awardedCertificatesReducer,
  groupTraining: groupTrainingReducer,
  courseLanguage: persistReducer(courseLanguagePersistConfig, courseLanguageReducer)
});

export const coursewareReducer = (state: CoursewareState, action: UserActionsUnionType) => {
  if (action.type === UserActionTypes.USER_DID_LOGOUT) {
    return initialCoursewareState;
  }

  return reducers(state, action);
};
