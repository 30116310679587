import { put, race, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import type { SessionAction } from '../actions';
import { SessionActionTypes, SessionActions } from '../actions';

function* handleUserVerify(action: SessionAction<SessionActionTypes.USER_VERIFY>) {
  const { username } = action.payload;

  yield put(
    RequestActions.postUnauthed(
      Urls.USER_VERIFY,
      SessionActionTypes.USER_VERIFY_SUCCESS,
      SessionActionTypes.USER_VERIFY_FAILURE,
      undefined,
      { username }
    )
  );

  type RaceType = { success: SessionAction<SessionActionTypes.USER_VERIFY_SUCCESS> };
  const { success }: RaceType = yield race({
    success: take(SessionActionTypes.USER_VERIFY_SUCCESS),
    failure: take(SessionActionTypes.USER_VERIFY_FAILURE)
  });

  if (!!success && !!success.payload.companyId) {
    yield put(
      SessionActions.redirectToSSO(
        success.payload.companyId,
        success.payload.openSsoExternally || false
      )
    );
  }
}

export function* watchUserVerify() {
  yield takeLatest(SessionActionTypes.USER_VERIFY, handleUserVerify);
}
