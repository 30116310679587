import { createAction } from '@edapp/utils';
import type { ActionFromActionType, ActionWithPayload, ActionsUnion } from '@edapp/utils';
import type { LoginResponseType } from '@maggie/store/session/types';

export enum ResetPasswordActionTypes {
  SET_PARAMS = 'SET_PARAMS',

  RESET_LOST_PASSWORD = 'RESET_LOST_PASSWORD',
  RESET_LOST_PASSWORD_SUCCESS = 'RESET_LOST_PASSWORD_SUCCESS',
  RESET_LOST_PASSWORD_FAILURE = 'RESET_LOST_PASSWORD_FAILURE',

  RESET_LOST_PASSWORD_LOGIN_SUCCESS = 'RESET_LOST_PASSWORD_LOGIN_SUCCESS',
  RESET_LOST_PASSWORD_LOGIN_FAILURE = 'RESET_LOST_PASSWORD_LOGIN_FAILURE'
}

export const ResetPasswordActions = {
  setParams: (email: string, token: string) =>
    createAction(ResetPasswordActionTypes.SET_PARAMS, { email, token }),

  resetPassword: (email: string, token: string, newPassword: string) =>
    createAction(ResetPasswordActionTypes.RESET_LOST_PASSWORD, { email, token, newPassword }),
  resetPasswordSuccess: (email: string, password: string) =>
    createAction(ResetPasswordActionTypes.RESET_LOST_PASSWORD_SUCCESS, { email, password }),
  resetPasswordFailure: (error: { message: string; response: string }) =>
    createAction(ResetPasswordActionTypes.RESET_LOST_PASSWORD_FAILURE, { error })
};

export type ResetPasswordActionUnionType =
  | ActionsUnion<typeof ResetPasswordActions>
  | ActionWithPayload<ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_SUCCESS, LoginResponseType>
  | ActionWithPayload<
      ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_FAILURE,
      EdErrorResponseType
    >;
export type ResetPasswordAction<A extends string> = ActionFromActionType<
  ResetPasswordActionUnionType,
  A
>;
