import type { CollectionsState } from './types';

export const initialCollectionsState: CollectionsState = {
  fetchCollectionsError: '',
  fetchCollectionsErrorCode: undefined,
  fetchCollectionsLoading: false,

  currentPage: 1,
  totalCount: 0,
  totalCourses: 0,
  items: {},
  librarySortOption: 'publishDate',

  last_update: null
};
