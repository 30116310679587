import { flatten } from 'lodash-es';

import { ContentLibraryActionTypes } from '@edapp/content-library';
import { ErrorCode } from '@edapp/request';
import type { DictionaryType } from '@edapp/utils';
import { getErrorMessage } from '@maggie/store/request/errors';

import { CoursewareUtils } from '../utils';
import { CollectionsActionTypes } from './actions';
import { initialCollectionsState } from './constants';
import type { CollectionActionsUnionType, CollectionType, CollectionsState } from './types';

export const collectionsReducer = (
  state: CollectionsState = initialCollectionsState,
  action: CollectionActionsUnionType
): CollectionsState => {
  switch (action.type) {
    case CollectionsActionTypes.APPLY_LIBRARY_SORT: {
      const librarySortOption = action.payload;
      return { ...state, librarySortOption };
    }

    case CollectionsActionTypes.FETCH_COLLECTIONS: {
      const { page } = action.payload.options;
      if (typeof page !== 'number') {
        // don't touch the items if there is no page
        // we are fetching only one item...
        return {
          ...state,
          fetchCollectionsLoading: true,
          fetchCollectionsError: '',
          fetchCollectionsErrorCode: undefined
        };
      }

      return {
        ...state,
        currentPage: page,
        fetchCollectionsLoading: true,
        fetchCollectionsError: '',
        fetchCollectionsErrorCode: undefined
      };
    }
    case CollectionsActionTypes.FETCH_COLLECTIONS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      const errorCode = action.payload?.code;
      return {
        ...state,
        fetchCollectionsError: message,
        fetchCollectionsErrorCode: errorCode,
        fetchCollectionsLoading: false
      };
    }
    case CollectionsActionTypes.FETCH_COLLECTIONS_SUCCESS: {
      const { items, totalCount, totalCourses, page } = action.payload;

      const collections = items.reduce<DictionaryType<CollectionType>>((obj, collection) => {
        const collectionWDate = {
          ...collection,
          lastUpdate: new Date().toISOString()
        };

        obj[collection.id] = collectionWDate;
        return obj;
      }, {});

      return {
        ...state,
        items:
          page === 1
            ? { ...collections }
            : {
                ...state.items,
                ...collections
              },
        totalCount: typeof page !== 'number' ? state.totalCount : totalCount,
        totalCourses: typeof page !== 'number' ? state.totalCourses : totalCourses,
        last_update: new Date(),
        fetchCollectionsError: '',
        fetchCollectionsErrorCode: undefined,
        fetchCollectionsLoading: false
      };
    }

    case ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY: {
      const { courseIds } = action.payload;

      const initialState: DictionaryType<CollectionType> = {};
      const newItems = Object.keys(state.items).reduce((result, collectionKey) => {
        const collection = state.items[collectionKey];

        if (CoursewareUtils.isManualCourseCollection(collection)) {
          // remove items from course summaries
          const newCourses = collection.courses.filter(c => !courseIds.includes(c.courseId));
          result[collectionKey] = {
            ...collection,
            courses: [...newCourses]
          };
        } else {
          // remove the whole collection if system generated collection has the course
          const isCourse = collection.courses.find(c => courseIds.includes(c.courseId));
          if (!isCourse) {
            result[collectionKey] = { ...collection };
          }
        }

        return result;
      }, initialState);

      const newCollectionsCount: number = Object.keys(newItems).length;

      const newCoursesCount: number = flatten(
        Object.entries(newItems).map(([, { courses }]) => {
          if (courses.length !== 0) return courses;
        })
      ).length;

      return {
        ...state,
        totalCount: newCollectionsCount,
        totalCourses: newCoursesCount,
        items: { ...newItems }
      };
    }

    default:
      return state;
  }
};
