import type { Locale } from 'date-fns';
import { setDefaultOptions } from 'date-fns';
import { enUS } from 'date-fns/locale';
import i18n from 'i18next';

import { ErrorLogger } from '@edapp/monitoring'; // { i18n we have: locale files in date-fns }

// { i18n we have: locale files in date-fns }
const dateFnsLocalesMap: Record<string, string> = {
  ar: 'ar',
  bn: 'bn',
  da: 'da',
  de: 'de',
  en: 'en-US',
  es: 'es',
  fr: 'fr',
  fi: 'fi',
  hi: 'hi',
  id: 'id',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  nl: 'nl',
  no: 'nb', // https://github.com/date-fns/date-fns/issues/2963
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sv: 'sv',
  ta: 'ta',
  th: 'th',
  tr: 'tr',
  uk: 'uk',
  vi: 'vi',
  zh: 'zh-CN',
  'zh-tw': 'zh-TW'
};

const attemptLoadDateFns = async (locale: string): Promise<Locale | undefined> => {
  try {
    return await import(
      /* @vite-ignore */
      /* webpackChunkName: "df-[request]" */
      `date-fns/locale/${locale}/index.js`
    );
  } catch {
    return undefined;
  }
};

export const getDateFnsResource = async (locale: string) => {
  while (locale) {
    // Try importing with user locale
    const resource = await attemptLoadDateFns(locale);
    if (resource) {
      return resource;
    }

    // Try importing with mapping if user locale failed
    // e.g. user locale: 'no'    --> date-fns: 'nb'
    //                   'zh-tw' -->           'zh-TW'
    const resourceWithMapping = await attemptLoadDateFns(dateFnsLocalesMap[locale]);
    if (resourceWithMapping) {
      return resourceWithMapping;
    }

    const localeArr = locale.split('-');
    localeArr.pop();
    locale = localeArr.join('-');
  }

  return undefined;
};

/**
 * Load date-fns language file and set `react-datepicker` to use that file
 */
export const initDateFnsLocale = async (language: string = i18n.language) => {
  try {
    const dateFnsImport = await getDateFnsResource(language);
    if (!!dateFnsImport) {
      setDefaultOptions({ locale: dateFnsImport ? dateFnsImport : enUS });
    }
  } catch (err) {
    ErrorLogger.captureEvent('Failed to init date-fns locales', 'error', { err, language });
  }
};
