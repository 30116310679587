import { groupBy, uniqBy } from 'lodash';

import { ENV } from '@maggie/config/env';
import type { LxStoreState } from '@maggie/store/types';

const getRegistrationFields = (state: LxStoreState) => {
  const registrationSettings = state.register.registrationSettings;
  const fields = uniqBy(registrationSettings.fields, 'name');

  const groups = groupBy(fields, 'group');

  // to ensure compliance goes at last
  if (groups.compliance) {
    const { compliance, ...newGroups } = groups;
    return { ...newGroups, compliance };
  }

  return groups;
};

const DEFAULT_LOGO_IMAGE = `${ENV.STATIC_ASSETS_CDN_BASE_URL}/training/registration/default-logo.png`;
const getCustomLogo = (state: LxStoreState) => {
  const logo = state.register.registrationSettings.useCustomLogo
    ? state.register.registrationSettings.customLogoUrl || DEFAULT_LOGO_IMAGE
    : DEFAULT_LOGO_IMAGE;
  return logo;
};

const DEFAULT_BACKGROUND_IMAGE = `${ENV.STATIC_ASSETS_CDN_BASE_URL}/training/registration/Background-new.png`;
const getBackgroundImg = (state: LxStoreState) => {
  return state.register.registrationSettings.customBackgroundUrl || DEFAULT_BACKGROUND_IMAGE;
};

const getIsLoading = (state: LxStoreState) => state.register.loading;

const getError = (state: LxStoreState) => state.register.error;

const getApplicationId = (state: LxStoreState) => state.register.registrationSettings.applicationId;

export const RegisterSelectors = {
  getRegistrationFields,
  getCustomLogo,
  getIsLoading,
  getError,
  getBackgroundImg,
  getApplicationId
};
