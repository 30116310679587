import { call, fork, takeLatest } from 'redux-saga/effects';

import type { RouteParams } from '@maggie/core/router/types';

import { SplitViewLayoutActionTypes } from './actions';
import { handleDidMainNavigateToAssessment } from './handlers/assessment-sagas';
import { handleDidMainNavigateToCourseCollection } from './handlers/collection-sagas';
import { handleDidMainNavigateToCourse } from './handlers/course-sagas';
import { handleDidMainNavigateToDiscussion } from './handlers/discussion-sagas';
import { handleDidMainNavigateToObservation } from './handlers/observation-sagas';
import { handleDidMainNavigateToPlaylist } from './handlers/playlist-sagas';
import type { SplitViewLayoutAction } from './types';

function* handleMainNavigateSagas(
  action: SplitViewLayoutAction<SplitViewLayoutActionTypes.MAIN_NAVIGATE>
) {
  const { route, params } = action.payload;

  switch (route) {
    case 'course': {
      const { id } = params as RouteParams<'course'>;
      yield call(handleDidMainNavigateToCourse, id);
      break;
    }

    case 'assignment': {
      const { id } = params as RouteParams<'assignment'>;
      yield call(handleDidMainNavigateToAssessment, id);
      break;
    }

    case 'discussion': {
      const { id } = params as RouteParams<'discussion'>;
      yield call(handleDidMainNavigateToDiscussion, id);
      break;
    }

    case 'observation': {
      const { id } = params as RouteParams<'observation'>;
      yield call(handleDidMainNavigateToObservation, id);
      break;
    }

    case 'courseCollection': {
      const { id } = params as RouteParams<'courseCollection'>;
      yield call(handleDidMainNavigateToCourseCollection, id);
      break;
    }

    case 'playlist': {
      const { id } = params as RouteParams<'playlist'>;
      yield call(handleDidMainNavigateToPlaylist, id);
      break;
    }
  }
}

export function* watchMainNavigate() {
  yield takeLatest(SplitViewLayoutActionTypes.MAIN_NAVIGATE, handleMainNavigateSagas);
}

export const splitViewLayoutSagas = [fork(watchMainNavigate)];
