import { put } from 'redux-saga/effects';

import ConfigActions from '@maggie/store/config/actions';

import type { GlobalRouteQueryParams } from './type';

const convertBooleanStrToBoolean = (str?: string): boolean | undefined => {
  if (str !== 'true' && str !== 'false') {
    return undefined; // not a boolean
  }

  return str === 'true' ? true : false;
};

export function* checkForRestrictionScreen(query: GlobalRouteQueryParams) {
  const { restrictToCourseScreen, restrictToLessonScreen } = query;

  const bRestrictToCourseScreen = convertBooleanStrToBoolean(restrictToCourseScreen);
  const bRestrictToLessonScreen = convertBooleanStrToBoolean(restrictToLessonScreen);

  // Course Restriction Screen
  if (typeof bRestrictToCourseScreen === 'boolean') {
    yield put(ConfigActions.setRestrictToCourseScreen(bRestrictToCourseScreen));
  }

  // Lesson Restriction Screen
  if (typeof bRestrictToLessonScreen === 'boolean') {
    yield put(ConfigActions.setRestrictToLessonScreen(bRestrictToLessonScreen));
  }
}
