import { escapeRegExp, orderBy } from 'lodash';

import { AssessmentActionTypes } from '@maggie/store/courseware/assessments/actions';
import { DiscussionActionTypes } from '@maggie/store/courseware/discussions/actions';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import { UserActionTypes } from '@maggie/store/user/actions';

import { SearchActionTypes } from './actions';
import type { SearchActionUnionType, SearchState } from './types';

export const initialSearchState: SearchState = {
  isOpen: false,
  isPreviewMode: true,
  isLoadingCatalog: false,
  isFilteringCatalog: false,
  catalog: [],
  filteredItems: [],
  searchTerm: '',
  recentSearchesTerms: [],
  lastSearchItemSelected: undefined
};

export const searchReducer = (
  state: SearchState = initialSearchState,
  action: SearchActionUnionType
): SearchState => {
  switch (action.type) {
    case SearchActionTypes.SET_IS_OPEN: {
      const { isOpen } = action.payload;
      if (!isOpen) {
        return { ...initialSearchState, recentSearchesTerms: state.recentSearchesTerms };
      }

      return { ...state, isOpen };
    }

    case SearchActionTypes.SET_IS_PREVIEW_MODE: {
      const { isPreviewMode } = action.payload;
      return { ...state, isPreviewMode };
    }

    case SearchActionTypes.SET_SEARCH_TERM: {
      const { searchTerm } = action.payload;
      return { ...state, isFilteringCatalog: true, searchTerm };
    }

    case SearchActionTypes.FILTER_ITEMS: {
      const { searchTerm } = action.payload;

      const matchSearchTerm = (v: string) =>
        typeof v === 'string' && v.match(new RegExp(escapeRegExp(searchTerm), 'igm'));

      const filteredItems = state.catalog.filter(i => {
        const match = Object.values(i.metadata).find(matchSearchTerm);
        return !!match;
      });

      // we want results that match title to come back first
      const itemsOrderdByTitle = orderBy(filteredItems, i => matchSearchTerm(i.title));

      return { ...state, filteredItems: itemsOrderdByTitle, isFilteringCatalog: false };
    }

    case SearchActionTypes.ADD_RECENT_SEARCH_TERM: {
      const { searchTerm } = action.payload;
      if (!searchTerm.trim()) {
        return state; // term is empty or just a space
      }

      if (state.recentSearchesTerms.includes(searchTerm)) {
        return state; // already got the searchTerm as recent
      }

      const LIMIT_RECENT_SEARCHES = 5;
      let newRecentSearchTerms = [searchTerm, ...state.recentSearchesTerms];
      if (newRecentSearchTerms.length > LIMIT_RECENT_SEARCHES) {
        newRecentSearchTerms = newRecentSearchTerms.slice(0, 5);
      }

      return { ...state, recentSearchesTerms: newRecentSearchTerms };
    }

    case SearchActionTypes.FETCH_SEARCH_CATALOG: {
      return { ...state, isLoadingCatalog: true };
    }
    case SearchActionTypes.FETCH_SEARCH_CATALOG_SUCCESS: {
      const catalog = action.payload;
      return { ...state, catalog: [...catalog], isLoadingCatalog: false };
    }

    case SearchActionTypes.SELECT_SEARCH_ITEM: {
      const { item } = action.payload;
      return { ...state, lastSearchItemSelected: item };
    }

    // Actions in lessons should reset search state
    case LessonActionTypes.OPEN_LESSON:
    case AssessmentActionTypes.SAVE_ASSESSMENT_POST:
    case DiscussionActionTypes.SAVE_DISCUSSION_POST: {
      if (state.isOpen) {
        return { ...initialSearchState, recentSearchesTerms: state.recentSearchesTerms };
      }

      return state;
    }

    case UserActionTypes.USER_DID_LOGOUT: {
      return { ...initialSearchState };
    }

    default: {
      return state;
    }
  }
};
