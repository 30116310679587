import type { AuthorProfileActionsUnionType } from './actions';
import { AuthorProfileActionTypes as actions, AuthorProfileActions } from './actions';
import { fork, takeLatest, put, select } from 'redux-saga/effects';
import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import { ContentLibraryActionTypes } from '@edapp/content-library';
import type { ContentLibraryActionUnionType } from '@edapp/content-library';

import type { ActionFromActionType } from '@edapp/utils';
import { chunk } from 'lodash-es';

import type { AuthorProfileApiType, StoreState } from './types';
import { socialMediaFromApi, socialMediaToApi } from './utils';

// This comes from the limitation of the querystring size
const AUTHOR_PROFILE_BY_IDS_CHUNK_SIZE = 20;

function* handleFetchAuthorProfile() {
  yield put(
    RequestActions.getAuthed(
      // The first author profile back from the api is the current logged in user's author profile
      `api/application-profiles/`,
      (data: RequestTypes.PaginatedResponse<AuthorProfileApiType>) => ({
        type: actions.FETCH_AUTHOR_PROFILE_SUCCESS,
        payload: { ...data.items[0], socialMedia: socialMediaFromApi(data.items[0]?.socialMedia) }
      }),
      actions.FETCH_AUTHOR_PROFILE_FAILURE,
      false,
      { page: 1, pageSize: 1 }
    )
  );
}

type AuthorProfileAction<A extends string> = ActionFromActionType<AuthorProfileActionsUnionType, A>;
type ContentLibraryAction<A extends string> = ActionFromActionType<
  ContentLibraryActionUnionType,
  A
>;

function* handleSaveAuthorProfile(action: AuthorProfileAction<actions.SAVE_AUTHOR_PROFILE>) {
  const updatedProfile = action.payload;
  yield put(
    RequestActions.putAuthed(
      `api/application-profiles/${updatedProfile.id}`,
      actions.SAVE_AUTHOR_PROFILE_SUCCESS,
      actions.SAVE_AUTHOR_PROFILE_FAILURE,
      false,
      { ...updatedProfile, socialMedia: socialMediaToApi(updatedProfile.socialMedia) }
    )
  );
}

function* handleFetchAuthorProfiles(action: AuthorProfileAction<actions.FETCH_AUTHOR_PROFILES>) {
  const { ids } = action.payload;
  const store: StoreState = yield select();
  const localProfiles = store.authorProfile.authorProfiles;
  const filteredIds = [...new Set(ids.filter(id => id && !(id in localProfiles)))];
  if (!filteredIds.length) {
    return;
  }

  const chunks = chunk(filteredIds, AUTHOR_PROFILE_BY_IDS_CHUNK_SIZE);
  for (const chunk of chunks) {
    yield put(
      RequestActions.getAuthed(
        `api/application-profiles/search`,
        (data: RequestTypes.PaginatedResponse<AuthorProfileApiType>) => {
          return AuthorProfileActions.fetchAuthorProfilesSuccess(
            data.items.reduce<Record<string, any>>((profilesById, profile) => {
              profilesById[profile.id] = {
                ...profile,
                socialMedia: socialMediaFromApi(profile.socialMedia)
              };
              return profilesById;
            }, {})
          );
        },
        actions.FETCH_AUTHOR_PROFILES_FAILURE,
        false,
        { ids: chunk, page: 1, pageSize: AUTHOR_PROFILE_BY_IDS_CHUNK_SIZE }
      )
    );
  }
}

function* handleFetchContentLibrarySectionsSuccess(
  action: ContentLibraryAction<ContentLibraryActionTypes.FETCH_SECTIONS_SUCCESS>
) {
  const { items } = action.payload.response;
  const ids = items.reduce<string[]>(
    (acc, item) => acc.concat(item.courses.items.map(c => c.applicationProfileId)),
    []
  );
  yield put(AuthorProfileActions.fetchAuthorProfiles(ids));
}

function* handleFetchContentLibraryCoursesSuccess(
  action: ContentLibraryAction<ContentLibraryActionTypes.FETCH_COURSES_SUCCESS>
) {
  const { items } = action.payload.response;
  const ids = items.map(c => c.applicationProfileId);
  yield put(AuthorProfileActions.fetchAuthorProfiles(ids));
}

function* watchFetchAuthorProfile() {
  yield takeLatest(actions.FETCH_AUTHOR_PROFILE, handleFetchAuthorProfile);
}

function* watchSaveAuthorProfile() {
  yield takeLatest(actions.SAVE_AUTHOR_PROFILE, handleSaveAuthorProfile);
}

function* watchFetchContentLibrarySectionsSuccess() {
  yield takeLatest(
    ContentLibraryActionTypes.FETCH_SECTIONS_SUCCESS,
    handleFetchContentLibrarySectionsSuccess
  );
}

function* watchFetchContentLibraryCoursesSuccess() {
  yield takeLatest(
    ContentLibraryActionTypes.FETCH_COURSES_SUCCESS,
    handleFetchContentLibraryCoursesSuccess
  );
}

function* watchFetchAuthorProfiles() {
  yield takeLatest(actions.FETCH_AUTHOR_PROFILES, handleFetchAuthorProfiles);
}

const authorProfileSagas = [
  fork(watchFetchAuthorProfile),
  fork(watchSaveAuthorProfile),
  fork(watchFetchContentLibrarySectionsSuccess),
  fork(watchFetchContentLibraryCoursesSuccess),
  fork(watchFetchAuthorProfiles)
];

export { authorProfileSagas };
