import { select } from 'redux-saga/effects';

import { DiscussionSelectors } from '@maggie/store/courseware/discussions/selectors';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidMainNavigateToDiscussion(discussionId: string) {
  const postId: string | undefined = yield select<LxStoreState>(
    DiscussionSelectors.getPostForDiscussion(discussionId)
  );

  if (postId) {
    window.__router.navigate('thread', { discussionId, postId });
  }
}
