import { fork } from 'redux-saga/effects';

import { watchOnlineStatusChangePrepareUpNext, watchPrepareUpNext } from './up-next-sagas';
import { watchFetchForYouWidgets } from './widget-sagas';

export const homeSagas = [
  fork(watchPrepareUpNext),
  fork(watchOnlineStatusChangePrepareUpNext),
  fork(watchFetchForYouWidgets)
];
