import { put, select } from 'redux-saga/effects';

import { DiscussionActions } from '@maggie/store/courseware/discussions/actions';
import { DiscussionSelectors } from '@maggie/store/courseware/discussions/selectors';

export function* handleDidNavigateToThread(postId: string, discussionId: string): any {
  const discussion: ReturnType<typeof DiscussionSelectors.getDiscussionItem> = yield select(
    DiscussionSelectors.getDiscussionItem(discussionId)
  );

  if (!discussion) {
    yield put(DiscussionActions.fetchDiscussion(discussionId));
    yield put(DiscussionActions.fetchPosts(discussionId));
  }
  yield put(DiscussionActions.fetchPostItem(discussionId, postId));
  yield put(DiscussionActions.fetchPostComments(discussionId, postId));
}
