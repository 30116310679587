import type { ToastActions } from './actions';
import { ToastActionTypes } from './actions';
import type { ActionsUnion } from '@edapp/utils';

type ToastActionUnionType = ActionsUnion<typeof ToastActions>;

export type ToastState = {
  show: boolean;
  isOn: boolean;
  text: string;
};

export const initialToastState: ToastState = {
  show: false,
  isOn: false,
  text: ''
};

export const toastReducer = (
  state: ToastState = initialToastState,
  action: ToastActionUnionType
): ToastState => {
  switch (action.type) {
    case ToastActionTypes.SHOW_TOAST: {
      const { text, isOn } = action.payload;
      return { ...state, show: true, text, isOn };
    }

    case ToastActionTypes.HIDE_TOAST: {
      return { ...state, show: false };
    }

    default: {
      return state;
    }
  }
};
