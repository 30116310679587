const lockToPortrait = () => {
  // @ts-expect-error TODO: EDAPP-42821 lock does not exist on ScreenOrientation
  window?.screen?.orientation?.lock('portrait');
};

const unlock = () => {
  window?.screen?.orientation?.unlock();
};

export const ScreenLock = {
  lockToPortrait,
  unlock
};
