import { put, select, take, takeLatest } from 'redux-saga/effects';

import { LeaderboardsActions } from '@maggie/store/leaderboards/actions';
import { LeaderboardsSelectors } from '@maggie/store/leaderboards/selectors';
import type { LxStoreState } from '@maggie/store/types';

import { CourseActionTypes, CourseActions } from '../courses/actions';
import { CourseSelectors } from '../courses/selectors';
import type { CourseType } from '../courses/types';
import { LessonActionTypes } from './actions';
import type { LessonAction } from './types';

function* handleFetchCourseLeaderboards(
  action: LessonAction<LessonActionTypes.FETCH_COURSE_LEADERBOARD>
) {
  const { courseId } = action.payload;

  let course: CourseType | undefined = yield select<LxStoreState>(state =>
    CourseSelectors.getCourse(courseId, state)
  );

  // If course isn't available we need to fetch and wait for the result
  // Because we need the leaderboard id to show the lesson summary
  if (!course) {
    yield put(CourseActions.fetchSyncCourse(courseId));
    yield take(CourseActionTypes.FETCH_SYNC_COURSE_COMPLETED);
    course = yield select<LxStoreState>(s => CourseSelectors.getCourse(courseId, s));
  }

  if (!course) {
    return; // course sync failed? won't try to fetch leaderboard in that case
  }

  const isLeaderboardAvailable: boolean = yield select<LxStoreState>(
    LeaderboardsSelectors.isSelectedLeaderboard(courseId)
  );

  if (course.leaderboard?.isEnabled && !isLeaderboardAvailable) {
    yield put(LeaderboardsActions.fetchLeaderboard(course.leaderboard.id));
  }
}

export function* watchFetchCourseLeaderboards() {
  yield takeLatest(LessonActionTypes.FETCH_COURSE_LEADERBOARD, handleFetchCourseLeaderboards);
}
