import { isBefore, isEqual } from 'date-fns';
import * as _ from 'lodash';

import { sortCourses, splitMandatory } from '@maggie/components/course-collection/courseListUtils';
import type { MandatoryAccumulator } from '@maggie/components/course-collection/courseListUtils';
import type { LxStoreState } from '@maggie/store/types';

import { CourseSelectors } from '../courses/selectors';
import type { MandatoryDueByType } from '../courses/types';
import { CoursewareUtils } from '../utils';
import type { CollectionType, CourseSummaryType, LibrarySortOption } from './types';

const getCollectionsCourseIds = (collections: CollectionType[]): string[] => {
  const courseIds: string[] = [];

  collections.forEach(collection => {
    collection.courses.forEach(course => {
      courseIds.push(course.courseId);
    });
  });

  return courseIds;
};

const getCollectionsPrerequisitesIds = (collections: CollectionType[]): string[] => {
  let prerequisiteIds: string[] = [];

  collections.forEach(collection => {
    collection.courses.forEach(course => {
      const ids = CourseSelectors.getPrerequisitesIds(course);
      prerequisiteIds = [...prerequisiteIds, ...ids];
    });
  });

  return prerequisiteIds;
};

const getManualCollectionsAndCourses = (collections: CollectionType[]) => {
  return collections.map(collection => {
    const isManualCollection = CoursewareUtils.isManualCourseCollection(collection);
    if (isManualCollection) {
      return collection;
    } else {
      return collection.courses[0];
    }
  });
};

const getDateForLibrarySort = (c: CollectionType) => {
  // Check if true course
  // If yes -> We use publish date
  // Fallback is collection modified date
  if (!CoursewareUtils.isManualCourseCollection(c)) {
    return c.courses[0]?.publishedAt || c.modified;
  }
  // Modified date is the same as created date when
  // a course or collection is first created
  return c.modified;
};

const sortLibraryCourses = (collections: CollectionType[], sortOption: LibrarySortOption) => {
  const courses = collections.reduce((acc, collection) => {
    const coursesFromCollections = collection.courses.map<CourseSummaryType>(course => ({
      ...course,
      publishedAt: course.publishedAt || collection.modified
    }));
    return [...acc, ...coursesFromCollections];
  }, []);

  if (sortOption === 'alphabets') {
    return courses.sort((c1, c2) => c1.title.localeCompare(c2.title));
  }
  return courses.sort((c1, c2) => {
    const c1Date = Date.parse(c1.publishedAt!);
    const c2Date = Date.parse(c2.publishedAt!);
    if (isEqual(c1Date, c2Date)) {
      return 0;
    }

    return isBefore(c1Date, c2Date) ? 1 : -1;
  });
};

const sortLibraryCollections = (
  courseCollections: CollectionType[],
  sortOption: LibrarySortOption
) => {
  if (sortOption === 'alphabets') {
    const sortByAlphabets = courseCollections.sort((c1, c2) => {
      const c2Title = !CoursewareUtils.isManualCourseCollection(c2)
        ? c2.courses[0].title
        : c2.title;

      // Check if true course collection
      // If yes -> sort by collection title
      if (c1.isManual) {
        return c1.title.localeCompare(c2Title);
      }

      // Sort by course title
      if (!CoursewareUtils.isManualCourseCollection(c1)) {
        return c1.courses[0].title.localeCompare(c2Title);
      }

      // Default
      return c1.rank - c2.rank;
    });
    return sortByAlphabets;
  }

  const sortByPublishDate = courseCollections.sort((c1, c2) => {
    const c1Date = Date.parse(getDateForLibrarySort(c1));
    const c2Date = Date.parse(getDateForLibrarySort(c2));

    if (isEqual(c1Date, c2Date)) {
      return c1.rank - c2.rank;
    }

    return isBefore(c1Date, c2Date) ? 1 : -1;
  });
  return sortByPublishDate;
};

// Step 1 => Split mandatory from non-mandatory courses
// Step 2 => Sort mandatory and non-mandatory courses
const sortCourseCollections = (courseCollections: CollectionType[], state: LxStoreState) => {
  // Step 1

  const { mandatory, nonMandatory } = courseCollections.reduce<
    MandatoryAccumulator<CollectionType>
  >(
    splitMandatory(({ courses }) => {
      if (!courses.length) {
        return null;
      }

      const { courseId, planning, mandatory } = sortCourses(courses, state)[0];
      const complete = CourseSelectors.getCourseStatus(courseId, planning, state).id === 'complete';

      return { complete, mandatory };
    }),
    { mandatory: { dueDate: [], noDueDate: [] }, nonMandatory: [] }
  );

  // Step 2
  const sortedMandatoryDueDate = _.sortBy(mandatory.dueDate, [
    ({ courses }) => new Date((courses[0].mandatory as MandatoryDueByType).date)
  ]);
  const sortedMandatoryNoDueDate = _.sortBy(mandatory.noDueDate, ['rank']);
  const sortedNonMandatory = _.sortBy(nonMandatory, ['rank']);

  return [...sortedMandatoryDueDate, ...sortedMandatoryNoDueDate, ...sortedNonMandatory];
};

export const CollectionUtils = {
  getCollectionsCourseIds,
  getCollectionsPrerequisitesIds,
  getManualCollectionsAndCourses,
  sortCourseCollections,
  sortLibraryCollections,
  sortLibraryCourses
};
