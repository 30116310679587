import type { AnalyticsBrowser } from '@segment/analytics-next';

interface WrappedSegment {
  alias(...args: Parameters<AnalyticsBrowser["alias"]>): Promise<void>;
  group(...args: Parameters<AnalyticsBrowser["group"]>): Promise<void>;
  page(...args: Parameters<AnalyticsBrowser["page"]>): Promise<void>;
  screen(...args: Parameters<AnalyticsBrowser["screen"]>): Promise<void>;
  track(...args: Parameters<AnalyticsBrowser["track"]>): Promise<void>;
}

// Simply declare the analytics instance exported from the shell application
// https://github.com/SafetyCulture/frontend-reactor/blob/master/packages/sc-tracking/src/analytics-service.ts
interface Analytics {
  getSegment(): WrappedSegment;
}

type Singletons = Partial<{
  analytics: Analytics;
}>;

/**
 * Get the shared singleton across different applications
 */
const getGlobalSingleton = <K extends keyof Singletons>(name: K): Singletons[K] => {
  const g = globalThis || window;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const store: Singletons = g.__SC_SINGLETONS__ || {};
  return store[name];
}

export default getGlobalSingleton;
