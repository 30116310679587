import { createSelector } from 'reselect';

import { initialSectionState } from '@edapp/content-library';
import type { LxStoreState } from '@maggie/store/types';

const findSection = (id: string) => (state: LxStoreState) => {
  return state.contentLibrary.sections.items.find(s => s.id === id);
};

const getSection = (id: string) => (state: LxStoreState) => {
  const section = findSection(id)(state);
  if (!section) {
    return initialSectionState;
  }

  return section;
};

const findCourse = (courseId: string) =>
  createSelector(
    (state: LxStoreState) => ({
      courseItem: state.contentLibrary.courseItem,
      courseSearchResults: state.contentLibrary.courseSearchResults,
      sections: state.contentLibrary.sections
    }),
    ({ courseItem, courseSearchResults, sections }) => {
      if (courseItem?.id === courseId) {
        return courseItem;
      }

      const course = courseSearchResults.items.find(c => c.id === courseId);
      if (!!course) {
        return course;
      }

      for (const section of sections.items) {
        for (const sectionCourse of section.courses.items) {
          if (sectionCourse.id === courseId) {
            return sectionCourse;
          }
        }
      }

      return undefined;
    }
  );

const hasSections = (state: LxStoreState) => state.contentLibrary.sections.totalCount > 0;

export const ContentLibrarySelectors = {
  findSection,
  getSection,
  findCourse,
  hasSections
};
