import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import type { ActionFromActionType } from '@edapp/utils';
import { SocialLearningApi } from '@maggie/core/lessons/social_learning/social_learning_api';
import { SocialLearningService } from '@maggie/core/lessons/social_learning/social_learning_service';
import type {
  SocialLearningCommentForThomas,
  SocialLearningSlide
} from '@maggie/core/lessons/social_learning/types';
import type { LxStoreState } from '@maggie/store/types';

import { SocialLearningActionTypes, SocialLearningActions } from './actions';
import { isSocialLearningEnabled } from './selectors';
import type { SocialLearningActionsUnionType } from './types';

const __SocialLearningService = new SocialLearningService();

type SocialLearningAction<ActionType extends string> = ActionFromActionType<
  SocialLearningActionsUnionType,
  ActionType
>;

function* handleFetchSocialLearningSummary(
  action: SocialLearningAction<SocialLearningActionTypes.FETCH_SOCIAL_LEARNING_SUMMARY>
) {
  const state: LxStoreState = yield select();
  if (!isSocialLearningEnabled(state)) {
    return;
  }

  if (!action.payload.lessonId) {
    return;
  }
  try {
    const response: [
      { hasUnreadComments: boolean },
      { activeCommentsCount: number }
    ] = yield Promise.all([
      SocialLearningApi.checkIfNewComments(action.payload.lessonId),
      SocialLearningApi.getCommentCountForLesson(action.payload.lessonId)
    ]);
    yield put(
      SocialLearningActions.fetchSocialLearningSummarySuccess(
        action.payload.lessonId,
        response[0].hasUnreadComments,
        response[1].activeCommentsCount
      )
    );
  } catch (error) {
    console.error('Social Learning Error', error);
  }
}

function* handleFetchAllCommentsForALesson(
  action: SocialLearningAction<SocialLearningActionTypes.FETCH_ALL_COMMENTS_FOR_A_LESSON>
) {
  const { lessonId } = action.payload;

  try {
    const res: SocialLearningSlide[] = yield call(
      __SocialLearningService.getAllCommentsForALesson,
      lessonId
    );

    yield put(SocialLearningActions.fetchAllCommentsForALessonSuccess(lessonId, res));
  } catch (err) {
    ErrorLogger.captureEvent('Failed to load social learning', 'error', { err });
  }
}

function* handleLikeSocialLearingComment(
  action: SocialLearningAction<SocialLearningActionTypes.LIKE_SOCIAL_LEARNING_COMMENT>
) {
  const { lessonId, slideId, commentId } = action.payload;

  try {
    yield call(__SocialLearningService.addLike, { lessonId, slideId, commentId });
  } catch (err) {
    ErrorLogger.captureEvent('Failed to like social learning comment', 'error', action.payload);
  }
}

function* handleDislikeSocialLearingComment(
  action: SocialLearningAction<SocialLearningActionTypes.DISLIKE_SOCIAL_LEARNING_COMMENT>
) {
  const { lessonId, slideId, commentId } = action.payload;

  try {
    yield call(__SocialLearningService.dislike, { lessonId, slideId, commentId });
  } catch (err) {
    ErrorLogger.captureEvent('Failed to dislike social learning comment', 'error', action.payload);
  }
}

function* handleFetchCommentsForSlide(
  action: SocialLearningAction<SocialLearningActionTypes.FETCH_COMMENTS_FOR_SLIDE>
) {
  const { lessonId, slideId, page } = action.payload;

  try {
    type ResultType = {
      comments: SocialLearningCommentForThomas[];
      hasMore: boolean;
      pageNum: number;
      total: number;
    };
    const res: ResultType = yield call(__SocialLearningService.getSlideCommentsFromApp, {
      lessonId,
      slideId,
      pageNum: page
    });

    yield put(
      SocialLearningActions.fetchCommentsForSlideSuccess(
        lessonId,
        slideId,
        res.comments,
        page,
        res.total
      )
    );
  } catch (err) {
    ErrorLogger.captureEvent('Failed to fetch social learning commments', 'error', action.payload);
  }
}

function* watchFetchAllCommentsForALesson() {
  yield takeLatest(
    SocialLearningActionTypes.FETCH_ALL_COMMENTS_FOR_A_LESSON,
    handleFetchAllCommentsForALesson
  );
}

function* watchFetchSocialLearningSummary() {
  yield takeLatest(
    SocialLearningActionTypes.FETCH_SOCIAL_LEARNING_SUMMARY,
    handleFetchSocialLearningSummary
  );
}

function* watchLikeSocialLearningComment() {
  yield takeLatest(
    SocialLearningActionTypes.LIKE_SOCIAL_LEARNING_COMMENT,
    handleLikeSocialLearingComment
  );
}

function* watchDislikeSocialLearningComment() {
  yield takeLatest(
    SocialLearningActionTypes.DISLIKE_SOCIAL_LEARNING_COMMENT,
    handleDislikeSocialLearingComment
  );
}

function* watchFetchCommentsForSlide() {
  yield takeEvery(SocialLearningActionTypes.FETCH_COMMENTS_FOR_SLIDE, handleFetchCommentsForSlide);
}

export const socialLearningSagas = [
  fork(watchFetchSocialLearningSummary),
  fork(watchFetchAllCommentsForALesson),
  fork(watchLikeSocialLearningComment),
  fork(watchDislikeSocialLearningComment),
  fork(watchFetchCommentsForSlide)
];
