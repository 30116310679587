import * as React from 'react';
import type { FeatureFlags, FlagKeys } from './types';

export type Context<Flags extends FlagKeys> = {
  flags: FeatureFlags<Flags>;
  fetchFlags: (flags?: Flags[]) => Promise<void>;
  fetchFlag: (flagName: Flags) => Promise<void>;
};

export const FeatureFlagsContext = React.createContext<Context<any>>({
  flags: {},
  fetchFlags: () => Promise.resolve(),
  fetchFlag: () => Promise.resolve()
});
