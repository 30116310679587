import { put, race, take } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import type { ActionWithPayload } from '@edapp/utils';
import { PlaylistsActionTypes, PlaylistsActions } from '@maggie/store/courseware/playlists/actions';
import type { PlaylistItemType } from '@maggie/store/courseware/playlists/types';

type RacePayload = {
  success?: ActionWithPayload<
    PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS_SUCCESS,
    PlaylistItemType
  >;
  failure?: ActionWithPayload<
    PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS_FAILURE,
    PlaylistItemType
  >;
};

export function* handleDidNavigateToPlaylist(playlistId: string): any {
  yield put(PlaylistsActions.fetchPlaylistItemWithProgress(playlistId));

  const { success, failure }: RacePayload = yield race({
    success: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS_SUCCESS),
    failure: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS_FAILURE)
  });

  if (failure) {
    ErrorLogger.captureEvent('Failed to fetch playlist', 'error', { playlistId });
    return;
  }

  if (success) {
    itly.playlistOpenedByLearner({
      playlist_id: playlistId,
      playlist_type: success.payload.playlistType
    });
    yield put(PlaylistsActions.updatePlaylistNextItem(playlistId));
  }
}
