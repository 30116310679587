import { createAction } from '@edapp/utils';

import type { UnlockPayload } from '../types';

export enum DocumentActionTypes {
  UPDATE_OPEN_DOCUMENT = 'UPDATE_OPEN_DOCUMENT',
  UPDATE_DOCUMENTS_UNLOCK = 'UPDATE_DOCUMENTS_UNLOCK',
  FETCH_DOCUMENT_DOWNLOAD_URL = 'FETCH_DOCUMENT_DOWNLOAD_URL',
  FETCH_DOCUMENT_DOWNLOAD_URL_SUCCESS = 'FETCH_DOCUMENT_DOWNLOAD_URL_SUCCESS',
  FETCH_DOCUMENT_DOWNLOAD_URL_FAILURE = 'FETCH_DOCUMENT_DOWNLOAD_URL_FAILURE'
}

export const DocumentActions = {
  openDocument: (courseId: string, documentId: string) =>
    createAction(DocumentActionTypes.UPDATE_OPEN_DOCUMENT, {
      courseId,
      documentId
    }),
  updateDocumentsUnlock: (payload: UnlockPayload[]) =>
    createAction(DocumentActionTypes.UPDATE_DOCUMENTS_UNLOCK, { payload }),
  fetchDocumentDownloadUrl: (documentId: string) =>
    createAction(DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL, {
      documentId
    })
};
