import type { ActionsUnion } from '@edapp/utils';
import { UserActionTypes } from '@maggie/store/user/actions';
import type { UserActions } from '@maggie/store/user/actions';

import { LogoutActionTypes } from './actions';
import type { LogoutActions } from './actions';
import { initialLogoutState } from './constants';
import type { LogoutState } from './types';

export const logoutDialogReducer = (
  state: LogoutState = initialLogoutState,
  action: ActionsUnion<typeof LogoutActions> | ActionsUnion<typeof UserActions>
): LogoutState => {
  switch (action.type) {
    case UserActionTypes.USER_LOGOUT: {
      return { ...state, loading: true };
    }

    case UserActionTypes.USER_DID_LOGOUT: {
      return { ...state, loading: false };
    }

    case LogoutActionTypes.OPEN_LOGOUT_DIALOG: {
      return { ...state, dialogOpen: true, loading: false };
    }

    case LogoutActionTypes.CLOSE_LOGOUT_DIALOG: {
      return { ...state, dialogOpen: false, loading: false };
    }

    default:
      return state;
  }
};
