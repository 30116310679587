export enum NotificationTopics {
  SocialLearning = 'SocialLearning',
  VideoCreated = 'VideoCreated',
  VideoCommentCreated = 'VideoCommentCreated',
  DiscussionPostCommentCreated = 'DiscussionPostCommentCreated',
  AssessmentPostCommentCreated = 'AssessmentPostCommentCreated',
  RapidRefreshSessionStarted = 'RapidRefreshSessionStarted',
  BrainBoostSessionIsAvailable = 'BrainBoost',
  Course = 'Course'
}
