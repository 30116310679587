import type { AwardedCertificatesState } from './types';

export const initialAwardedCertificatesState: AwardedCertificatesState = {
  fetchAllAwardedCertificateError: '',
  fetchAllAwardedCertificateLoading: false,
  allAwardedCertificatesData: { totalCount: 0, items: [] },
  fetchAwardedCertificateDownloadUrlError: '',
  fetchAwardedCertificateDownloadUrlLoading: false
};

export const DEFAULT_PAGE_SIZE = 25;
