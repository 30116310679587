import { createSelector } from 'reselect';
import type { ContentLibraryCourseType, ContentLibraryState } from '@edapp/content-library';

type StoreState = {
  contentLibrary: ContentLibraryState;
};

const getIsLoading = (state: StoreState) => state.contentLibrary.loading;

const getError = (state: StoreState) => state.contentLibrary.error;

const getSections = (state: StoreState) => state.contentLibrary.sections;

const getSectionItems = (state: StoreState) => state.contentLibrary.sections.items;

const getSearchTerm = (state: StoreState) => state.contentLibrary.searchTerm;

const getSection = (sectionId: string) =>
  createSelector(getSections, ({ items }) => items.filter(section => section.id === sectionId));

const findCourse = (courseId: string) =>
  createSelector(
    (state: StoreState) => ({
      courseItem: state.contentLibrary.courseItem,
      courseSearchResults: state.contentLibrary.courseSearchResults,
      sections: state.contentLibrary.sections
    }),
    ({ courseItem, courseSearchResults, sections }) => {
      if (courseItem?.id === courseId) {
        return courseItem;
      }

      const course = courseSearchResults.items.find(c => c.id === courseId);
      if (!!course) {
        return course;
      }

      for (const section of sections.items) {
        for (const sectionCourse of section.courses.items) {
          if (sectionCourse.id === courseId) {
            return sectionCourse;
          }
        }
      }

      return undefined;
    }
  );

const getAllSectionsCourses = createSelector(getSectionItems, sections =>
  sections.reduce((acc: ContentLibraryCourseType[], section) => {
    if (!!section.courses && section.courses.items.length > 0) {
      return [...acc, ...section.courses.items];
    }

    return acc;
  }, [])
);

const getSectionCourses = (sectionId: string) =>
  createSelector(getSection(sectionId), section => (!!section[0] ? section[0].courses.items : []));

const getCourseSearchResults = (state: StoreState) => state.contentLibrary.courseSearchResults;

const getCourseSearchResultItems = (state: StoreState) =>
  state.contentLibrary.courseSearchResults.items;

const getAddingCoursesError = (state: StoreState) => state.contentLibrary.addingCoursesError;

const getAddingCoursesLoading = (state: StoreState) => state.contentLibrary.addingCoursesLoading;

const getUpdatedCourseIds = (state: StoreState) => state.contentLibrary.coursesWithUpdates.ids;

const getUpdatedCourses = createSelector(
  getCourseSearchResults,
  getUpdatedCourseIds,
  (courses, courseIds) => courses.items.filter(course => courseIds.includes(course.id))
);

const getUpdatedCoursesCount = (state: StoreState) =>
  state.contentLibrary.coursesWithUpdates.totalCount;

const getShowUpdateToggleCount = createSelector(
  getUpdatedCoursesCount,
  (coursesWithUpdatesCount: number) => coursesWithUpdatesCount > 0
);

export const ContentLibrarySelectors = {
  findCourse,
  getError,
  getIsLoading,
  getSection,
  getSections,
  getSectionItems,
  getAllSectionsCourses,
  getSectionCourses,
  getCourseSearchResults,
  getCourseSearchResultItems,
  getSearchTerm,
  getAddingCoursesError,
  getAddingCoursesLoading,
  getUpdatedCourseIds,
  getUpdatedCourses,
  getUpdatedCoursesCount,
  getShowUpdateToggleCount
};
