import { select } from 'redux-saga/effects';

import type { RouteName } from '@maggie/core/router/types';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

/**
 * If a user is not logged in, we will only allow the access to the following routes
 */
export const UNAUTHENTICATED_ROUTES: RouteName[] = [
  'register',
  'forgotPassword',
  'resetPassword',
  'login',
  'ssoLogin',
  'ssoLoginFailure',
  'signup',
  'magicLink'
];

/**
 * Only routes allowed to navigate to if your billing plan is expired
 */
export const BILLING_WHITELIST_ROUTES: RouteName[] = ['home', 'profile', 'login'];

/**
 * @returns `boolean`
 */
export function* hasRouteAccess(route: RouteName) {
  if (UNAUTHENTICATED_ROUTES.indexOf(route) >= 0) {
    return true;
  }

  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );
  return !!token;
}

/**
 * @returns `boolean`
 */
export function* hasBillingAccess(route: RouteName) {
  if (BILLING_WHITELIST_ROUTES.indexOf(route) >= 0) {
    return true;
  }

  const user: ReturnType<typeof UserSelectors.getUser> = yield select<LxStoreState>(
    UserSelectors.getUser
  );
  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );
  if (!token) {
    return true; // no token means they have access – but why??
  }

  return !!user.ed.hasAccess;
}

/**
 * @returns `boolean`
 */
export function* hasVerifiedAccess(route: RouteName) {
  if (route === 'userNotVerified') {
    return true;
  }
  const user: LxStoreState['user'] = yield select<LxStoreState>(state => state.user);
  return !UserSelectors.isUserUnverified(user);
}
