import { format, isAfter } from 'date-fns';
import { t } from 'i18next';

import { removeUTCOffset } from '@edapp/utils';
import type { PlanningType } from '@maggie/store/courseware/types';

import type { DoRedirectParams, LockedDatesPayload, RedirectParams } from './types';

/**
 *
 * @param dates
 * @param entity - course | lesson
 *
 * @returns null if not locked and Object if locked
 */
export function checkLockedDates(
  dates: PlanningType | undefined,
  entity: 'course' | 'lesson'
): LockedDatesPayload | null {
  const dialogTitle =
    entity === 'course'
      ? t('locked.course.title', { ns: 'learners-experience' })
      : t('locked.lesson.title', { ns: 'learners-experience' });

  const coursewareEndDate = removeUTCOffset(dates?.endDateTime);
  const localEndDate = coursewareEndDate ? new Date(coursewareEndDate) : null;

  if (!!localEndDate && isAfter(new Date(), localEndDate)) {
    const date = format(localEndDate, 'PPPP');
    const dialogText =
      entity === 'course'
        ? t('locked.course.expired', { date, ns: 'learners-experience' })
        : t('locked.lesson.expired', { date, ns: 'learners-experience' });

    return { dialogTitle, dialogText };
  }

  const coursewareStartDate = removeUTCOffset(dates?.startDateTime);
  const localStartDate = coursewareStartDate ? new Date(coursewareStartDate) : null;

  if (!!localStartDate && isAfter(localStartDate, new Date())) {
    const date = format(localStartDate, 'PPPP');
    const dialogText =
      entity === 'course'
        ? t('locked.course.premature', { date, ns: 'learners-experience' })
        : t('locked.lesson.premature', { date, ns: 'learners-experience' });

    return { dialogTitle, dialogText };
  }

  return null;
}

export function shouldRedirectToPlaylist(p: RedirectParams): p is DoRedirectParams<'playlist'> {
  return p.shouldRedirect && p.routeName === 'playlist';
}
