import { select, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { Tracker } from '@maggie/core/tracker/tracker';
import type { LxStoreState } from '@maggie/store/types';

import { LessonActionTypes } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonAction, LessonProgressType } from './types';
import type { LessonType } from './types';

function* handleUpdateLessonOpened(action: LessonAction<LessonActionTypes.UPDATE_LESSON_OPENED>) {
  const { lessonId, attemptId, courseVersionNumber } = action.payload;

  const lesson: LessonType = yield select<LxStoreState>(s =>
    LessonSelectors.getLesson(lessonId, s)
  );

  Tracker.trackEvent({
    name: 'open-lesson',
    id: lessonId,
    type: 'event',
    value: attemptId,
    courseVersionNumber
  });

  const progress: LessonProgressType | undefined = yield select<LxStoreState>(s =>
    LessonSelectors.getLessonProgress(lessonId, s)
  );
  itly.openLesson({
    id: lessonId,
    type: lesson?.lessonType || 'Lesson',
    interaction_type: progress?.completed ? 'Reattempt' : 'First attempt'
  });
}

export function* watchUpdateLessonOpened() {
  yield takeLatest(LessonActionTypes.UPDATE_LESSON_OPENED, handleUpdateLessonOpened);
}
