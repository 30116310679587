import { createAction } from '@edapp/utils';

import type { PrizeDetailsType } from './types';

enum UserActionTypes {
  USER_LOGOUT = 'USER_LOGOUT',
  USER_LOGOUT_SSO_SUCCESS = 'USER_LOGOUT_SSO_SUCCESS',
  USER_LOGOUT_SSO_FAILURE = 'USER_LOGOUT_SSO_FAILURE',
  USER_DID_LOGOUT = 'USER_DID_LOGOUT',

  UPDATE_OPENED_DATE = 'UPDATE_OPENED_DATE',
  WIN_PRIZE = 'WIN_PRIZE',
  CLAIM_PRIZE = 'CLAIM_PRIZE',
  REMIND_RATING = 'REMIND_RATING',
  DECLINED_RATING = 'DECLINED_RATING',

  START_POLL_USER_SYNC = 'START_POLL_USER_SYNC',
  STOPPED_POLL_USER_SYNC = 'STOPPED_POLL_USER_SYNC',

  FETCH_SYNC_USER = 'FETCH_SYNC_USER',
  FETCH_SYNC_USER_SUCCESS = 'FETCH_SYNC_USER_SUCCESS',
  FETCH_SYNC_USER_FAILURE = 'FETCH_SYNC_USER_FAILURE',

  SET_REGISTRATION_DATE = 'SET_REGISTRATION_DATE'
}

const UserActions = {
  /**
   * @param redirect - redirects user to `login` page after logout finishes
   * @param waitSyncInteractions  - waits interaction syncing mechanism and forces the user to logout
   */
  userLogout: (redirect: boolean, waitSyncInteractions: boolean) =>
    createAction(UserActionTypes.USER_LOGOUT, { redirect, waitSyncInteractions }),

  /**
   * The user has finished logging out and we're ready to navigate away. Clear out the state of the app.
   */
  userDidLogout: () => createAction(UserActionTypes.USER_DID_LOGOUT, {}),

  updateOpenedDateAction: () => createAction(UserActionTypes.UPDATE_OPENED_DATE),

  winPrize: (roundId: string) => createAction(UserActionTypes.WIN_PRIZE, { roundId }),

  claimPrizeAction: (details: PrizeDetailsType) =>
    createAction(UserActionTypes.CLAIM_PRIZE, { details }),

  remindRatingAction: (dateTime: number) =>
    createAction(UserActionTypes.REMIND_RATING, { dateTime }),

  declinedRatingAction: (dateTime: number) =>
    createAction(UserActionTypes.DECLINED_RATING, { dateTime }),

  startPollSyncUser: () => createAction(UserActionTypes.START_POLL_USER_SYNC),

  stoppedPollSyncUser: () => createAction(UserActionTypes.STOPPED_POLL_USER_SYNC),

  syncUser: () => createAction(UserActionTypes.FETCH_SYNC_USER),

  setRegistrationDate: (date: Date | undefined) =>
    createAction(UserActionTypes.SET_REGISTRATION_DATE, { date })
};

export { UserActions, UserActionTypes };
