import { delay } from 'redux-saga';
import { fork, put, select, takeLatest } from 'redux-saga/effects';

import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { LxStoreState } from '@maggie/store/types';

import { CourseLanguageSelectors } from '../course-language/selectors';
import type { ObservationActionsUnionType } from './actions';
import { ObservationActionTypes, ObservationActions } from './actions';
import type { ObservationType } from './types';

export type ObservationAction<ActionType extends string> = ActionFromActionType<
  ObservationActionsUnionType,
  ActionType
>;

const DEFAULT_PAGE_SIZE = 10;

// Fetch observation
function* handleFetchObservation(
  action: ObservationAction<ObservationActionTypes.FETCH_OBSERVATION>
) {
  const { observationId } = action.payload;
  const courseLanguageCode: string | undefined = yield select<LxStoreState>(s =>
    CourseLanguageSelectors.getSelectedLanguageOfCourse(s)
  );

  yield put(
    RequestActions.getAuthed<ObservationType>(
      Urls.OBSERVATION_ITEM(observationId),
      data => ObservationActions.fetchObservationSuccess(data),
      ObservationActionTypes.FETCH_OBSERVATION_FAILURE,
      undefined,
      {
        locale: courseLanguageCode
      }
    )
  );
}

// Fetch all observation submissions
function* handleFetchAllObservations(
  action: ObservationAction<ObservationActionTypes.FETCH_ALL_OBSERVATIONS>
) {
  const { options, observationFilters } = action.payload;

  if (options.searchTerm !== '') {
    yield delay(750);
  }

  yield put(
    RequestActions.postAuthed<RequestTypes.PaginatedResponse<ObservationType>>(
      Urls.OBSERVATION_RESULTS,
      data => ObservationActions.fetchAllObservationsSuccess(options.page, data),
      ObservationActionTypes.FETCH_ALL_OBSERVATIONS_FAILURE,
      undefined,
      {
        page: options.page,
        pageSize: options.pageSize || DEFAULT_PAGE_SIZE,
        searchTerm: options.searchTerm,
        includePassedObservations: observationFilters.passed,
        includeFailedObservations: observationFilters.tryAgain
      }
    )
  );
}

function* watchFetchObservation() {
  yield takeLatest(ObservationActionTypes.FETCH_OBSERVATION, handleFetchObservation);
}

function* watchFetchAllObservations() {
  yield takeLatest(ObservationActionTypes.FETCH_ALL_OBSERVATIONS, handleFetchAllObservations);
}

const observationSagas = [fork(watchFetchObservation), fork(watchFetchAllObservations)];

export { observationSagas };
