import { put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import { LessonActionTypes, LessonActions } from './actions';
import type { LessonAction, LessonAttemptType } from './types';

function* handleFetchLessonAttempt(action: LessonAction<LessonActionTypes.FETCH_LESSON_ATTEMPT>) {
  const { lessonId } = action.payload;

  yield put(
    RequestActions.getAuthed<LessonAttemptType>(
      Urls.LESSON_ATTEMPT_PROGRESS(lessonId),
      response => LessonActions.fetchLessonAttemptSuccess(lessonId, response),
      LessonActionTypes.FETCH_LESSON_ATTEMPT_FAILURE,
      undefined
    )
  );
}

function* handleFetchLessonAttemptList(
  action: LessonAction<LessonActionTypes.FETCH_LESSON_ATTEMPT_LIST>
) {
  const { lessonIds } = action.payload;
  yield put(
    RequestActions.getAuthed(
      Urls.LESSON_ATTEMPT_PROGRESS_LIST,
      LessonActionTypes.FETCH_LESSON_ATTEMPT_LIST_SUCCESS,
      LessonActionTypes.FETCH_LESSON_ATTEMPT_LIST_FAILURE,
      undefined,
      { lessonIds }
    )
  );
}

export function* watchFetchLessonAttempt() {
  yield takeLatest(LessonActionTypes.FETCH_LESSON_ATTEMPT, handleFetchLessonAttempt);
}

export function* watchFetchLessonAttemptList() {
  yield takeLatest(LessonActionTypes.FETCH_LESSON_ATTEMPT_LIST, handleFetchLessonAttemptList);
}
