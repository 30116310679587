import { param, route } from '@igorrmotta/typesafe-react-router';

/**
 * Routes with no views. Should not be called from inside the app!
 *
 * In case the user navigates straight to those urls, it gets redirected (from side effects)
 *
 * Similar to `<Redirect />` of react router.
 *
 */
export const redirectionRoutes = {
  launchLesson: route('launch-lesson'),
  /**
   *  @deprecated - redirects to `virtual-classroom/:conferenceId`
   */
  oldConferenceRoute: route('conference', param('conferenceId')),
  /**
   * @deprecated - redirects to `path/:id`
   */
  oldPlaylist: route('playlist', param('id')),
  peerAuthoringLesson: route('lesson', param('id'), 'peer-authoring', param('slideId')),
  /**
   * @deprecated - redirects to `course/:courseId/lesson/:lessonId/view`
   */
  oldLessonReview: route(
    'course',
    param('courseId'),
    'lesson',
    param('lessonId'),
    'slide',
    param('slideId'),
    'view'
  )
};

/**
 * Routes with views.
 *
 * Each key has the value of objects that can be called to:
 *  - CREATE: routes with typesafe route arguments
 *  - GET: routes templates representing possible route arguments
 *
 * Similar to `<Route />` of react router.
 */
export const routePaths = {
  ...redirectionRoutes,
  login: route('login'),
  signup: route('sign-up'),

  ssoLogin: route('sso-login'),
  ssoLoginFailure: route('sso-login-failure'),

  forgotPassword: route('forgot-password'),
  resetPassword: route('reset-password'),
  register: route('register', param('code')),
  magicLink: route('magic-link'),
  userNotVerified: route('user-not-verified'),

  home: route('home'),
  homeSection: route('home', param('sectionType')),
  search: route('search'),
  course: route('course', param('id')),
  /**
   * rebranded as Path.
   * everywhere in the code is called playlist, except from translation and url perspective
   */
  playlist: route('path', param('id')),
  courseCollection: route('course-collection', param('id')),
  lesson: route('lesson', param('id')),
  lessonPlay: route('lesson', param('id'), 'play'),
  lessonReview: route('course', param('courseId'), 'lesson', param('lessonId'), 'view'),
  assignment: route('assignment', param('id')),
  submission: route('submission', param('assessmentId'), 'post', param('postId')),
  discussion: route('discussion', param('id')),
  thread: route('discussion', param('discussionId'), 'post', param('postId')),
  rapidRefresh: route('rapid-refresh', param('id'), param('session')),
  brainBoost: route('brain-boost'),
  observation: route('practical-assessment', param('id')),
  observationFeedback: route(
    'practical-assessment',
    param('observationId'),
    'submission',
    param('submissionId')
  ),
  groupTraining: route('group-training', param('courseId'), param('sessionId')),

  /**
   * This is called conference here because it's called conference everywhere else in the code
   *  But when it comes to user perspective, the url and all brand labeling is called
   * 'virtual classroom'
   */
  conference: route('virtual-classroom', param('id')),
  socialLearning: route('social', param('lessonId')),
  socialLearningSlide: route('social', param('lessonId'), param('slideId')),
  briefcaseDocument: route('briefcase-document', param('id'), param('documentId')),

  profile: route('profile'),
  performance: route('performance'),
  achievements: route('achievements'),
  microCredentials: route('micro-credentials'),
  accountSettings: route('account-settings'),
  observationResults: route('practical-assessment-results'),
  awardedCertificates: route('awarded-certificates'),

  linkAccounts: route('link-accounts'),
  changePassword: route('change-password'),

  contentLibrary: route('content-library'),
  contentLibraryCourse: route('content-library', 'course', param('courseId')),
  contentLibrarySection: route('content-library', 'section', param('sectionId')),

  leaderboard: route('leaderboard', param('id')),
  leaderboards: route('leaderboards'),

  game: route('game'),
  pastWinners: route('past-winners'),
  upcomingPrizes: route('upcoming-prizes'),
  claim: route('claim'),
  congratulations: route('congratulations'),

  notifications: route('notifications')
};
