import { createAction } from '@edapp/utils';
import type {
  SocialLearningCommentForThomas,
  SocialLearningSlide
} from '@maggie/core/lessons/social_learning/types';

export enum SocialLearningActionTypes {
  FETCH_SOCIAL_LEARNING_SUMMARY = 'FETCH_SOCIAL_LEARNING_SUMMARY',
  FETCH_SOCIAL_LEARNING_SUMMARY_SUCCESS = 'FETCH_SOCIAL_LEARNING_SUMMARY_SUCCESS',
  FETCH_SOCIAL_LEARNING_SUMMARY_FAILURE = 'FETCH_SOCIAL_LEARNING_SUMMARY_FAILURE',

  LIKE_SOCIAL_LEARNING_COMMENT = 'LIKE_SOCIAL_LEARNING_COMMENT',
  DISLIKE_SOCIAL_LEARNING_COMMENT = 'DISLIKE_SOCIAL_LEARNING_COMMENT',

  FETCH_ALL_COMMENTS_FOR_A_LESSON = 'FETCH_ALL_COMMENTS_FOR_A_LESSON',
  FETCH_ALL_COMMENTS_FOR_A_LESSON_SUCCESS = 'FETCH_ALL_COMMENTS_FOR_A_LESSON_SUCCESS',
  FETCH_ALL_COMMENTS_FOR_A_LESSON_FAILURE = 'FETCH_ALL_COMMENTS_FOR_A_LESSON_FAILURE',

  FETCH_COMMENTS_FOR_SLIDE = 'FETCH_COMMENTS_FOR_SLIDE',
  FETCH_COMMENTS_FOR_SLIDE_SUCCESS = 'FETCH_COMMENTS_FOR_SLIDE_SUCCESS',
  FETCH_COMMENTS_FOR_SLIDE_FAILURE = 'FETCH_COMMENTS_FOR_SLIDE_FAILURE'
}

export const SocialLearningActions = {
  fetchAllCommentsForALesson: (lessonId: string) =>
    createAction(SocialLearningActionTypes.FETCH_ALL_COMMENTS_FOR_A_LESSON, { lessonId }),
  fetchAllCommentsForALessonSuccess: (lessonId: string, slides: SocialLearningSlide[]) =>
    createAction(SocialLearningActionTypes.FETCH_ALL_COMMENTS_FOR_A_LESSON_SUCCESS, {
      lessonId,
      slides
    }),

  fetchCommentsForSlide: (lessonId: string, slideId: string, page: number) =>
    createAction(SocialLearningActionTypes.FETCH_COMMENTS_FOR_SLIDE, {
      lessonId,
      slideId,
      page
    }),
  fetchCommentsForSlideSuccess: (
    lessonId: string,
    slideId: string,
    slideComments: SocialLearningCommentForThomas[],
    page: number,
    totalCount: number
  ) =>
    createAction(SocialLearningActionTypes.FETCH_COMMENTS_FOR_SLIDE_SUCCESS, {
      lessonId,
      slideId,
      slideComments,
      page,
      totalCount
    }),

  likeSocialLearningComment: (lessonId: string, slideId: string, commentId: string) =>
    createAction(SocialLearningActionTypes.LIKE_SOCIAL_LEARNING_COMMENT, {
      lessonId,
      slideId,
      commentId
    }),

  dislikeSocialLearningComment: (lessonId: string, slideId: string, commentId: string) =>
    createAction(SocialLearningActionTypes.DISLIKE_SOCIAL_LEARNING_COMMENT, {
      lessonId,
      slideId,
      commentId
    }),

  fetchSocialLearningSummary: (lessonId: string) =>
    createAction(SocialLearningActionTypes.FETCH_SOCIAL_LEARNING_SUMMARY, { lessonId }),
  fetchSocialLearningSummarySuccess: (
    lessonId: string,
    hasUnreadComments: boolean,
    activeCommentsCount: number
  ) =>
    createAction(SocialLearningActionTypes.FETCH_SOCIAL_LEARNING_SUMMARY_SUCCESS, {
      lessonId,
      hasUnreadComments,
      activeCommentsCount
    })
};
