import { createAction } from '@edapp/utils';

import type { SlideStateType } from './types';

enum SlideProgressActionTypes {
  FETCH_SLIDE_PROGRESS = 'FETCH_SLIDE_PROGRESS',
  FETCH_SLIDE_PROGRESS_SUCCESS = 'FETCH_SLIDE_PROGRESS_SUCCESS',
  FETCH_SLIDE_PROGRESS_FAILURE = 'FETCH_SLIDE_PROGRESS_FAILURE',
  UPDATE_SLIDE_STATE = 'UPDATE_SLIDE_STATE',
  CLEAR_ALL_SLIDE_PROGRESS = 'CLEAR_ALL_SLIDE_PROGRESS'
}

const SlideProgressActions = {
  fetchSlidesProgress: (lessonIds: string[]) =>
    createAction(SlideProgressActionTypes.FETCH_SLIDE_PROGRESS, { lessonIds }),
  updateSlideState: (lessonId: string, slideId: string, slideState: SlideStateType) =>
    createAction(SlideProgressActionTypes.UPDATE_SLIDE_STATE, { lessonId, slideId, slideState }),
  clearAllSlideProgress: () => createAction(SlideProgressActionTypes.CLEAR_ALL_SLIDE_PROGRESS)
};

export { SlideProgressActions, SlideProgressActionTypes };
