import type { Token, TokenParameter } from './types';

const isTokenObject = (token: TokenParameter): token is Token => token instanceof Object;

const strategies: ((token: TokenParameter, headers: Headers) => boolean)[] = [
  handleFalsy,
  handleEdToken,
  handleCampusTokenObject,
  handleCampusTokenString
];

function handleFalsy(token: TokenParameter) {
  return !token;
}

function handleEdToken(token: TokenParameter, headers: Headers) {
  if (!isTokenObject(token) || token.type !== 'AccessToken') {
    return false;
  }

  headers.set('Authorization', `Bearer ${token.value}`);
  return true;
}

function handleCampusTokenObject(token: TokenParameter, headers: Headers) {
  if (!isTokenObject(token) || token.type !== 'CampusToken') {
    return false;
  }

  headers.set('token', token.value);
  return true;
}

function handleCampusTokenString(token: TokenParameter, headers: Headers) {
  if (typeof token !== 'string') {
    return false;
  }

  headers.set('token', token);
  return true;
}

export function incorporateToken(token: TokenParameter, headers: Headers) {
  for (const strategy of strategies) {
    if (strategy(token, headers)) {
      return;
    }
  }

  const preamble = `Cannot incorporate token of JS type ${typeof token}`;
  throw new Error(preamble + (isTokenObject(token) ? ` (token type '${token.type}')` : ''));
}
