import { put, select } from 'redux-saga/effects';

import { CollectionsSelectors } from '@maggie/store/courseware/collections/selectors';
import PerformanceActions from '@maggie/store/performance/actions';
import type { PerformanceState } from '@maggie/store/performance/types';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToPerformance(): any {
  const performance: PerformanceState = yield select((state: LxStoreState) => state.performance);
  const totalCourseCount: number = yield select(CollectionsSelectors.getTotalCollections);
  if (
    performance.coursePerformances.length !== performance.totalCoursePerformances ||
    totalCourseCount > performance.totalCoursePerformances
  ) {
    yield put(PerformanceActions.resetCurrentPage());
    yield put(PerformanceActions.fetchUserPerformance());
  }
}
