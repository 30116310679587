import { delay } from 'redux-saga';
import { put, takeLatest, fork, race } from 'redux-saga/effects';
import { ContentLibraryActionTypes, ContentLibraryActions } from './actions';
import type { ContentLibraryActionUnionType } from './actions';
import type { ActionFromActionType } from '@edapp/utils';
import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { ContentLibrarySectionType, ContentLibraryCourseType } from './types';

const URLS = {
  SECTIONS: `api/content-library/sections`,
  SECTION: (id: string) => `api/content-library/section/${id}`,
  COURSES: `api/content-library/courses`,
  COURSE: (id: string) => `api/content-library/course/${id}`,
  ADD_COURSES: `api/my-courses`,
  REMOVE_COURSES: `api/my-courses`,
  FLAG_COURSE: (courseId: string) => `api/courses/${courseId}/flag`
};

type Action<A extends string> = ActionFromActionType<ContentLibraryActionUnionType, A>;

function* handleFetchSections(action: Action<ContentLibraryActionTypes.FETCH_SECTIONS>) {
  const { page, pageSize, coursesPageSize } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<ContentLibrarySectionType>>(
      URLS.SECTIONS,
      response => ContentLibraryActions.fetchSectionsSuccess(page, response),
      ContentLibraryActionTypes.FETCH_SECTIONS_FAILURE,
      undefined,
      { page, pageSize, coursesPageSize }
    )
  );
}

function* handleFetchSection(action: Action<ContentLibraryActionTypes.FETCH_SECTION>) {
  const { coursesPage, coursesPageSize, sectionId } = action.payload;

  yield put(
    RequestActions.getAuthed<ContentLibrarySectionType>(
      URLS.SECTION(sectionId),
      response => ContentLibraryActions.fetchSectionSuccess(sectionId, coursesPage, response),
      ContentLibraryActionTypes.FETCH_SECTION_FAILURE,
      undefined,
      { coursesPage, coursesPageSize }
    )
  );
}

function* handleFetchCourses(action: Action<ContentLibraryActionTypes.FETCH_COURSES>) {
  // This delay here is acting as a debounce
  yield delay(1500);
  const { page, pageSize, searchTerm, filterByTagsIds, getOnlyUpdated, courseIds } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<ContentLibraryCourseType>>(
      URLS.COURSES,
      response => ContentLibraryActions.fetchCoursesSuccess(page, response),
      ContentLibraryActionTypes.FETCH_COURSES_FAILURE,
      undefined,
      { page, pageSize, searchTerm, filterByTagsIds, getOnlyUpdated, courseIds }
    )
  );
}

function* handleFetchCourseItem(action: Action<ContentLibraryActionTypes.FETCH_COURSE_ITEM>) {
  const { courseId } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<ContentLibraryCourseType>>(
      URLS.COURSE(courseId),
      ContentLibraryActionTypes.FETCH_COURSE_ITEM_SUCCESS,
      ContentLibraryActionTypes.FETCH_COURSE_ITEM_FAILURE
    )
  );
}

function* handleAddCoursesToLibrary(
  action: Action<ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY>
) {
  const { courseIds } = action.payload;

  yield put(
    RequestActions.postAuthed(
      URLS.ADD_COURSES,
      ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_SUCCESS,
      ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_FAILURE,
      undefined,
      { courseIds }
    )
  );
}

function* handleSetSearch(action: Action<ContentLibraryActionTypes.SET_SEARCH>) {
  const { searchTerm } = action.payload;
  if (!!searchTerm) {
    yield put(ContentLibraryActions.fetchCourses(1, 25, searchTerm));
  }
}

function* handleRemoveCoursesFromLibrary(
  action: Action<ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY>
) {
  const { courseIds } = action.payload;

  yield put(
    RequestActions.deleteAuthed(
      URLS.REMOVE_COURSES,
      ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY_SUCCESS,
      ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY_FAILURE,
      undefined,
      { courseIds }
    )
  );
}

function* handleFlagCoursesFromLibrary(
  action: Action<ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY>
) {
  const { courseId, shouldRemoveFromMyLibrary } = action.payload;

  yield put(
    RequestActions.postAuthed(
      URLS.FLAG_COURSE(courseId),
      ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY_SUCCESS,
      ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY_FAILURE,
      undefined,
      {}
    )
  );

  if (!!shouldRemoveFromMyLibrary) {
    const { success, failure } = yield race({
      success: ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY_SUCCESS,
      failure: ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY_FAILURE
    });

    // we just wanna make sure the request finisihed
    if (success || failure) {
      yield put(ContentLibraryActions.removeCoursesFromLibrary([courseId]));
    }
  }
}

function* watchFetchSection() {
  yield takeLatest(ContentLibraryActionTypes.FETCH_SECTION, handleFetchSection);
}

function* watchFetchSections() {
  yield takeLatest(ContentLibraryActionTypes.FETCH_SECTIONS, handleFetchSections);
}

function* watchFetchCourses() {
  yield takeLatest(ContentLibraryActionTypes.FETCH_COURSES, handleFetchCourses);
}

function* watchFetchCourseItem() {
  yield takeLatest(ContentLibraryActionTypes.FETCH_COURSE_ITEM, handleFetchCourseItem);
}

function* watchSetSearch() {
  yield takeLatest(ContentLibraryActionTypes.SET_SEARCH, handleSetSearch);
}

function* watchAddCoursesToLibrary() {
  yield takeLatest(ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY, handleAddCoursesToLibrary);
}

function* watchRemoveCoursesFromLibrary() {
  yield takeLatest(
    ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY,
    handleRemoveCoursesFromLibrary
  );
}

function* watchFlagCourseFromLibrary() {
  yield takeLatest(
    ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY,
    handleFlagCoursesFromLibrary
  );
}

const contentLibrarySagas = [
  fork(watchFetchSections),
  fork(watchFetchSection),
  fork(watchFetchCourses),
  fork(watchFetchCourseItem),
  fork(watchAddCoursesToLibrary),
  fork(watchSetSearch),
  fork(watchRemoveCoursesFromLibrary),
  fork(watchFlagCourseFromLibrary)
];

export { contentLibrarySagas };
