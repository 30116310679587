export const breakpoints = {
  xs: 0, // em
  sm: 48, // em
  md: 64, // em
  lg: 80, // em
  xlg: 86.25, // em
  xxlg: 100 // em (same as navbar max width)
};

export type ThemeBreakpoints = keyof typeof breakpoints;
