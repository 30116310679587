import { itly } from '@edapp/analytics-tracking';
import type { Environment, LoadOptions } from '@edapp/analytics-tracking';
import { initializeBraze } from '@edapp/braze-ui';
import { ConsoleAdapter, DatadogAdapter, ErrorLogger } from '@edapp/monitoring';
import SegmentPlugin from '@itly/plugin-segment';
import { ENV } from '@maggie/config/env';
import { UrlLaunch } from '@maggie/cordova/url-launch';

export function launchMonitoringTools() {
  // Monitoring
  ErrorLogger.init(() =>
    ENV.ENABLE_DD_MONITORING
      ? new DatadogAdapter({
          applicationId: 'baa5961c-5d56-4415-aa28-3bc72fa31f9d',
          clientToken: 'pub5b389e6b8fa853724c307a88d2b59db8',
          service: 'learners-app',
          env: process.env.CONFIG_ENV || ENV.NODE_ENV,
          version: process.env.GIT_COMMIT || 'development',
          sampleRate: ENV.DD_SAMPLE_RATE,
          replaySampleRate: ENV.DD_REPLAY_SAMPLE_RATE,
          beforeSend: event => {
            // workaround for hashes to display in DD monitoring
            event.view.url = event.view.url.replace('#', '');
          }
        })
      : new ConsoleAdapter()
  );
}

export function launchBrazeTools() {
  if (!ENV.IS_BRAZE_ENABLED) return;
  try {
    initializeBraze(ENV.BRAZE_PUBLIC_KEY);
  } catch (err) {
    ErrorLogger.captureEvent('Error initializing braze', 'warning', { err });
  }
}

export function launchAnalyticTools(extraLoadOptions: Partial<LoadOptions> = {}) {
  const environment: Environment = ENV.NODE_ENV === 'production' ? 'production' : 'development';

  try {
    // Iterative.ly (amplitude and segment)
    itly.load({
      environment,
      validation: {
        disabled: false,
        trackInvalid: true,
        errorOnInvalid: true
      } as any,
      disabled: !ENV.IS_TRACKING_ANALYTICS_ENABLED,
      // Override default segment plugin including LMS Public keys
      destinations: {
        all: { disabled: true }
      },
      plugins: [new SegmentPlugin(ENV.SEGMENT_PUBLIC_KEY)],
      ...extraLoadOptions
    });
  } catch (err) {
    ErrorLogger.captureEvent('Error initializing itly', 'critical', { err });
  }
}

export async function launchBranchDeepLink(opts?: { fallback?: () => void }) {
  try {
    const redirected = await UrlLaunch.initialize();
    if (!redirected) {
      // eslint-disable-next-line no-console
      console.info('No deeplink - triggering default navigation');
      opts?.fallback?.();
    }
  } catch (err) {
    ErrorLogger.captureEvent('Error initializing deep link', 'warning', { err });
  }
}
