import { createAction } from '@edapp/utils';
import type {
  CustomAchievementsListItem,
  FetchCustomAchievementsListAction,
  FetchCustomAchievementsListSuccessAction
} from './types';

export enum CustomAchievementsActionTypes {
  FETCH_CUSTOM_ACHIEVEMENT_LIST = 'FETCH_CUSTOM_ACHIEVEMENT_LIST',
  FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS = 'FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS',
  FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE = 'FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE'
}

export const CustomAchievementsActions = {
  fetchCustomAchievementsList: (): FetchCustomAchievementsListAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST),
  fetchCustomAchievementsListSuccess: (
    data: CustomAchievementsListItem[]
  ): FetchCustomAchievementsListSuccessAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS, { data })
};
