import { delay } from 'redux-saga';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';

import { UserActionTypes, UserActions } from './actions';
import { UserSelectors } from './selectors';

const SYNC_USER_DELAY = 60 * 1000;

function* handleFetchSyncUser() {
  yield put(
    RequestActions.getAuthed(
      Urls.USER_SYNC,
      UserActionTypes.FETCH_SYNC_USER_SUCCESS,
      UserActionTypes.FETCH_SYNC_USER_FAILURE,
      undefined
    )
  );
}

function* pollSyncUser() {
  while (true) {
    // Check that we are currently logged in
    const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
      UserSelectors.getToken
    );
    if (!token) {
      // if not logged in, don't start poll yet
      continue;
    }

    yield put(UserActions.syncUser());
    yield call(delay, SYNC_USER_DELAY);
  }
}

export function* watchPollSyncUser(): any {
  yield take(UserActionTypes.START_POLL_USER_SYNC);

  // Start the poll fetch user, however if a user logout action is received then we cancel the poll.
  yield race({
    polling: call(pollSyncUser),
    stop: take(UserActionTypes.USER_DID_LOGOUT)
  });

  // Put an action here, to ensure we stopped (mostly for unit tests)
  yield put(UserActions.stoppedPollSyncUser());
}

export function* watchFetchSyncUser() {
  yield takeLatest(UserActionTypes.FETCH_SYNC_USER, handleFetchSyncUser);
}
