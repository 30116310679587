import * as _ from 'lodash';
import type { AnyAction, Store } from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import type { Middleware } from 'redux';
import { composeWithDevTools as composeWebWithDevTools } from 'redux-devtools-extension';
import type { PersistConfig } from 'redux-persist';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'remote-redux-devtools';

import { ErrorLogger } from '@edapp/monitoring';
import { isCordovaBuild } from '@maggie/app/cordova-bootstrap';
import type { LxStoreState } from '@maggie/store/types';

import { initialStoreState } from './constants';
import { migrations } from './migrations';
// defaults to localStorage for web and AsyncStorage for react-native
import rootReducers from './rootReducer';

const IS_LOCAL = ['development', 'staging'].includes(process.env.NODE_ENV);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const store: Store<LxStoreState> = {};
window.__store = store;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const persistedStore: Persistor = {};
window.__persistedStore = persistedStore;

/**
 * Acts as PersistGate: https://github.com/rt2zz/redux-persist/blob/master/src/integration/react.ts
 * Only finish function when persistStore has rehydrated redux store
 */
const persistStoreAsync = async (store: Store<LxStoreState, AnyAction>) => {
  if (process.env.NODE_ENV === 'test') {
    // easier to do this than actually mock the persist store
    // the callback and return value below make really hard to mock without too much complexity
    return Promise.resolve(persistStore(store, null));
  }

  return new Promise(res => {
    const reduxPersistor = persistStore(store, null, () => {
      res(reduxPersistor);
    });
  });
};

/**
 * This function will be called when a saga has bubbled up an error all the way to the root saga.
 *
 * This could lead to an app crash - we can handle it here and ensure it doesn't.
 */
const onSagaFatalError = (error: Error) => {
  ErrorLogger.captureEvent('saga fatal error', 'error', { error });
};

const makeStore = async (reducers = rootReducers) => {
  // ##### REDUX #####
  // # create the saga middleware
  const sagaMiddleware = createSagaMiddleware({ onError: onSagaFatalError });
  const composeEnhancer = !isCordovaBuild
    ? composeWebWithDevTools({ name: 'Web Ed Redux', maxAge: 500 })
    : // composeWithDevTools -> remote-redux-devtools
      // Has incompatible @types with react-redux
      // Instead of spending time here, I will just cast to any! Sorry!
      (composeWithDevTools({
        realtime: true,
        hostname: 'localhost',
        port: 8000,
        name: 'App Ed Redux'
      }) as any);

  const userTiming: Middleware = () => next => action => {
    if (performance.mark === undefined) return next(action);
    performance.mark(`${action.type}_start`);
    const result = next(action);
    performance.mark(`${action.type}_end`);
    performance.measure(`${action.type}`, `${action.type}_start`, `${action.type}_end`);
    return result;
  };

  const reduxStore = createStore(
    reducers,
    undefined,
    IS_LOCAL
      ? composeEnhancer(applyMiddleware(userTiming, sagaMiddleware))
      : compose(applyMiddleware(sagaMiddleware))
  );

  const reduxPersistor = await persistStoreAsync(reduxStore);

  _.assign(store, reduxStore); // this mutates the object
  _.assign(persistedStore, reduxPersistor); // this mutates the object

  return {
    store: reduxStore,
    persistStore: reduxPersistor,
    runSaga: sagaMiddleware.run
  };
};

const configureStore = async (initialState: LxStoreState = initialStoreState) => {
  try {
    const persistConfig: PersistConfig<LxStoreState> = {
      key: 'ed',

      // !STOP THERE!
      // Do you really need to have the reducer persisted to the local storage?
      // If any of the reducer schemas below change, they must go through a migration!
      // If adding new ones, make sure to add them a snapshot unit test as well.
      whitelist: [
        'user',
        'offline',
        'star',
        'navigation',
        'profile',
        'local_notification',
        'learnerDemographic',
        'search',
        'featureFlags'
      ],
      storage,
      version: 11,
      migrate: async (state: StoreWithPersistState, versionKey: number) => {
        if (!state) {
          // First time opening learners-app with redux
          // -> we need a initial state before running the migrations
          state = _.cloneDeep(initialState) as StoreWithPersistState;
        }

        const result = await createMigrate(migrations, { debug: IS_LOCAL })(state, versionKey);
        return result;
      }
    };
    const persistedReducer = persistReducer(persistConfig, rootReducers);

    return await makeStore(persistedReducer);
  } catch (err) {
    throw err;
  }
};

export { configureStore, makeStore, store, persistedStore };
