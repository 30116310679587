import { createAction } from '@edapp/utils';
import type { CourseCollectionSearchFilter, LibrarySortOption } from './types';

enum CollectionsActionTypes {
  APPLY_LIBRARY_SORT = 'APPLY_LIBRARY_SORT',

  FETCH_COLLECTIONS = 'FETCH_COLLECTIONS',
  FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS',
  FETCH_COLLECTIONS_FAILURE = 'FETCH_COLLECTIONS_FAILURE',

  FETCH_COLLECTIONS_WITH_PROGRESS = 'FETCH_COLLECTIONS_WITH_PROGRESS',
  FETCH_COLLECTIONS_WITH_PROGRESS_SUCCESS = 'FETCH_COLLECTIONS_WITH_PROGRESS_SUCCESS',
  FETCH_COLLECTIONS_WITH_PROGRESS_FAILURE = 'FETCH_COLLECTIONS_WITH_PROGRESS_FAILURE',
  FETCH_COLLECTIONS_WITH_PROGRESS_COMPLETED = 'FETCH_COLLECTIONS_WITH_PROGRESS_COMPLETED'
}

const CollectionsActions = {
  applyLibrarySort: (sortOption: LibrarySortOption) =>
    createAction(CollectionsActionTypes.APPLY_LIBRARY_SORT, sortOption),

  fetchCollections: (options: CourseCollectionSearchFilter) =>
    createAction(CollectionsActionTypes.FETCH_COLLECTIONS, { options }),

  fetchCollectionsWithProgress: (options: CourseCollectionSearchFilter) =>
    createAction(CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS, options),

  fetchCollectionsWithProgressCompleted: () =>
    createAction(CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS_COMPLETED)
};

export { CollectionsActions, CollectionsActionTypes };
