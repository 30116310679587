import { put, race, select, take } from 'redux-saga/effects';

import { PlaylistsActionTypes, PlaylistsActions } from '@maggie/store/courseware/playlists/actions';
import type { PlaylistAction, PlaylistItemType } from '@maggie/store/courseware/playlists/types';
import { ToastActions } from '@maggie/store/toast/actions';
import type { LxStoreState } from '@maggie/store/types';

type PlaylistActionResults = {
  success: PlaylistAction<PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_SUCCESS>;
  failure: PlaylistAction<PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_FAILURE>;
};

export function* handleGetPlaylist(playlistId: string | null): any {
  if (!playlistId) {
    return undefined;
  }

  let playlistItem: PlaylistItemType = yield select(
    (state: LxStoreState) => state.courseware.playlists.playlistItems[playlistId]
  );

  if (!playlistItem) {
    yield put(PlaylistsActions.fetchPlaylistItem(playlistId));
    const { success, failure }: PlaylistActionResults = yield race({
      success: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_SUCCESS),
      failure: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_FAILURE)
    });
    if (!!failure) {
      yield put(
        ToastActions.showToast(
          true,
          `Failed fetching playlist ${playlistId} of offline lesson ${failure}`
        )
      );
      return;
    }
    playlistItem = success.payload;
  }

  return playlistItem;
}
