import { combineReducers } from 'redux';

import { authorProfileReducer } from '@edapp/author-profile';
import { contentLibraryReducer } from '@edapp/content-library';
import type { LxStoreState } from '@maggie/store/types';

import { accountSettingsReducer } from './account-settings/reducer';
import { actionDialogReducer } from './action-dialog/reducer';
import { bannersReducer } from './banners/reducer';
import { brainBoostReducer } from './brain-boost/reducer';
import configReducer from './config/reducer';
import { coursewareReducer } from './courseware/reducer';
import { customAchievementsReducer } from './custom-achievements/reducer';
import { featureFlagsReducer } from './feature-flags/reducer';
import { forgotPasswordReducer } from './forgot-password/reducer';
import { gameReducer } from './game/reducer';
import { homeReducer } from './home/reducer';
import { interactionsReducer } from './interactions/reducer';
import { inviteCodeSignUpReducer } from './invite-code-sign-up/reducer';
import { leaderboardsReducer } from './leaderboards/reducer';
import { learnerDemographicReducer } from './learner-demographic/reducer';
import { lessonDialogReducer } from './lesson-dialog/reducer';
import { localNotificationReducer } from './local-notification/reducer';
import { lockedDialogReducer } from './locked-dialog/reducer';
import { logoutDialogReducer } from './logout/reducer';
import { magicLinkReducer } from './magic-link/reducer';
import { navigationReducer } from './navigation/reducer';
import { offlineReducer } from './offline/reducer';
import { performanceReducer } from './performance/reducer';
import { profileReducer } from './profile/reducer';
import { ratingDialogReducer } from './rating-dialog/reducer';
import { registerReducer } from './register/reducer';
import { resetPasswordReducer } from './reset-password/reducer';
import { searchReducer } from './search/reducer';
import { sessionReducer } from './session/reducer';
import { slideProgressReducer } from './slide-progress/reducer';
import { socialLearningReducer } from './social-learning/reducer';
import { splitViewLayoutReducer } from './split-view-layout/reducer';
import { starReducer } from './star/reducer';
import { thomasReducer } from './thomas/reducer';
import { toastReducer } from './toast/reducer';
import { userReducer } from './user/reducer';

export default combineReducers<LxStoreState>({
  courseware: coursewareReducer,
  config: configReducer,
  user: userReducer,
  customAchievements: customAchievementsReducer,
  navigation: navigationReducer,
  slideProgress: slideProgressReducer,
  star: starReducer,
  game: gameReducer,
  offline: offlineReducer,
  performance: performanceReducer,
  profile: profileReducer,
  thomas: thomasReducer,
  interactions: interactionsReducer,
  social_learning: socialLearningReducer,
  local_notification: localNotificationReducer,
  brainBoost: brainBoostReducer,
  lockedDialog: lockedDialogReducer,
  ratingDialog: ratingDialogReducer,
  actionDialog: actionDialogReducer,
  toast: toastReducer,
  banners: bannersReducer,
  inviteCodeSignUp: inviteCodeSignUpReducer,
  contentLibrary: contentLibraryReducer,
  authorProfile: authorProfileReducer,
  learnerDemographic: learnerDemographicReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  register: registerReducer,
  lessonDialog: lessonDialogReducer,
  magicLink: magicLinkReducer,
  leaderboards: leaderboardsReducer,
  search: searchReducer,
  session: sessionReducer,
  featureFlags: featureFlagsReducer,
  splitViewLayout: splitViewLayoutReducer,
  home: homeReducer,
  accountSettings: accountSettingsReducer,
  logout: logoutDialogReducer
});
