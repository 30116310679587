import { put } from 'redux-saga/effects';

import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import { SocialLearningActions } from '@maggie/store/social-learning/actions';

export function* handleDidNavigateToSocialLearning(lessonId: string) {
  yield put(LessonActions.fetchLessonWithProgress(lessonId));
  yield put(SocialLearningActions.fetchSocialLearningSummary(lessonId));
  yield put(SocialLearningActions.fetchAllCommentsForALesson(lessonId));
}
