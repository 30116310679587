import type { LeaderboardInstance } from '@maggie/containers/leaderboard/LeaderboardRequests';
import { getErrorMessage } from '@maggie/store/request/errors';

import type { LeaderboardsActionUnionType } from './actions';
import { LeaderboardsActionTypes } from './actions';
import type { LeaderboardsState } from './types';
import { getMappedLeaderboards } from './utils';

export const initialLeaderboardsState: LeaderboardsState = {
  loading: false,
  error: '',
  rapidRefresh: {},
  leaderboardsMap: {}
};

export const leaderboardsReducer = (
  state: LeaderboardsState = initialLeaderboardsState,
  action: LeaderboardsActionUnionType
): LeaderboardsState => {
  switch (action.type) {
    case LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS: {
      return { ...state, loading: true, error: '' };
    }
    case LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS_SUCCESS: {
      const { courseId, rankings } = action.payload;
      return {
        ...state,
        loading: false,
        error: '',
        rapidRefresh: {
          ...state.rapidRefresh,
          [courseId]: [...rankings]
        }
      };
    }
    case LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS_FAILURE: {
      const error = getErrorMessage(action.payload?.code);
      return { ...state, loading: false, error };
    }

    case LeaderboardsActionTypes.SET_RAPID_REFRESH_LEADERBOARD_PARTICIPANT: {
      const { courseId, participant } = action.payload;
      const ranking = [...(state.rapidRefresh[courseId] || [])];
      const newParticipant = { ...participant, totalParticipants: ranking.length };

      const iParticipant = ranking.findIndex(p => p.participantId === newParticipant.participantId);
      if (iParticipant >= 0) {
        // remove existing participant from array
        ranking.splice(iParticipant, 1);
      } // move to end so so they will be last in sort case if tied ranking
      ranking.push(newParticipant);

      // re-sort to ensure participant goes higher/lower in leaderboard
      // Note: logic of timeTaken is not considered here...
      const newRankingSortedByScore = ranking
        .sort((a, b) => b.totalScore - a.totalScore)
        .map((rank, index) => ({
          ...rank,
          rank: index + 1
        }));

      return {
        ...state,
        rapidRefresh: {
          ...state.rapidRefresh,
          [courseId]: [...newRankingSortedByScore]
        }
      };
    }

    case LeaderboardsActionTypes.FETCH_LEADERBOARD: {
      return {
        ...state,
        selectedLeaderboard: undefined,
        error: '',
        loading: true
      };
    }
    case LeaderboardsActionTypes.FETCH_LEADERBOARD_SUCCESS: {
      return {
        ...state,
        selectedLeaderboard: action.payload,
        error: '',
        loading: false
      };
    }
    case LeaderboardsActionTypes.FETCH_LEADERBOARD_FAILURE: {
      const error = action.payload?.code ? getErrorMessage(action.payload?.code) : '';
      return {
        ...state,
        error: error,
        loading: false
      };
    }
    case LeaderboardsActionTypes.FETCH_LEADERBOARDS: {
      return {
        ...state,
        error: '',
        loading: true
      };
    }
    case LeaderboardsActionTypes.FETCH_LEADERBOARDS_SUCCESS: {
      return {
        ...state,
        leaderboardsMap: getMappedLeaderboards(action.payload),
        leaderboardListLength: action.payload.length,
        error: '',
        loading: false
      };
    }
    case LeaderboardsActionTypes.FETCH_LEADERBOARDS_FAILURE: {
      const error = action.payload?.code ? getErrorMessage(action.payload?.code) : '';
      return {
        ...state,
        error: error,
        loading: false
      };
    }
    case LeaderboardsActionTypes.SET_COURSE_LEADERBOARD_RANKINGS: {
      return {
        ...state,
        selectedLeaderboard: {
          ...state.selectedLeaderboard,
          rankings: action.payload.rankings ?? []
        } as LeaderboardInstance
      };
    }

    default: {
      return state;
    }
  }
};
