import { FileManager } from './file';

export class BackgroundDownloader {
  downloader: BackgroundTransfer.BackgroundDownloader;
  downloadOperations: DownloadOperation[];

  constructor() {
    if (typeof BackgroundTransfer === 'undefined') {
      throw new Error(`BackgroundTransfer plugin is not available...`);
    }
    this.downloader = new BackgroundTransfer.BackgroundDownloader();
    this.downloadOperations = [];
  }

  cancel() {
    this.downloadOperations.forEach(operation => operation.stop());
    this.downloadOperations = [];
  }

  download = async (
    fileName: string,
    folderName: string,
    uri: string,
    onSuccess: () => void,
    onError: (err: FileError) => void,
    onProgress?: (progress: BackgroundTransfer.Progress) => void
  ) => {
    const fullFolder = `${FileManager.dataDirectory}${folderName}`;
    try {
      await FileManager.createFolder(fullFolder);

      const dirEntry = await FileManager.resolveLocalFileSystemURL(fullFolder);
      if (!dirEntry) {
        return; // cannot access local file system
      }

      // Create lesson folder
      (dirEntry as DirectoryEntry).getFile(
        fileName,
        { create: true },
        file => {
          const dl = this.downloader.createDownload(uri, file as any);
          this.downloadOperations.push(dl);
          // Start the download and persist the promise to be able to cancel the download.
          dl.startAsync().then(onSuccess, onError, onProgress);
        },
        onError
      );
    } catch (err) {
      onError(err);
    }
  };
}
