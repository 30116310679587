import { put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorCode, RequestActions } from '@edapp/request';
import type { ErrorResponseType } from '@edapp/request';
import { Urls } from '@maggie/store/constants';
import { getErrorMessage } from '@maggie/store/request/errors';

import { SessionActionTypes, SessionActions } from '../actions';
import type { SessionAction } from '../actions';
import { SessionSelectors } from '../selectors';

export const createLoginFailureAction = (
  error: ErrorResponseType
): SessionAction<SessionActionTypes.LOGIN_FAILURE> => {
  /**
   * NOTE:
   * Possible ErrorCode
   * InvalidCredentials
   * InvalidUsername
   * InvalidPassword
   * AccountLocked
   * VerificationPending
   */
  return SessionActions.loginFailure(error, 'login');
};

function* handleLogin(action: SessionAction<SessionActionTypes.LOGIN>) {
  const { password, username } = action.payload;

  const lastCheckedUsername: string | undefined = yield select(
    SessionSelectors.getLoginLastCheckedUsername
  );
  // check if it should do a user/verify again (in case user changes username after clicking next)
  if (!!lastCheckedUsername && lastCheckedUsername !== username) {
    // check again
    yield put(SessionActions.loginCheck(username));
    yield race({
      success: take(SessionActionTypes.LOGIN_CHECK_SUCCESS),
      failure: take(SessionActionTypes.LOGIN_CHECK_FAILURE)
    });
  }

  yield put(
    RequestActions.postUnauthed(
      Urls.LOGIN,
      SessionActionTypes.LOGIN_REQUEST_SUCCESS,
      createLoginFailureAction,
      undefined,
      { username, password }
    )
  );

  const {
    success
  }: {
    success: SessionAction<SessionActionTypes.LOGIN_REQUEST_SUCCESS>;
    failure: SessionAction<SessionActionTypes.LOGIN_FAILURE>;
  } = yield race({
    success: take(SessionActionTypes.LOGIN_REQUEST_SUCCESS),
    failure: take(SessionActionTypes.LOGIN_FAILURE)
  });
  if (success) {
    const { invitationStatus, tokens, app } = success.payload;

    if (invitationStatus === 'invited') {
      yield put(SessionActions.loginUserInvited(username, app));
      return;
    }
    // All good!
    yield put(SessionActions.sessionLogin(tokens, { redirect: true }));

    const { failure } = yield race({
      success: take(SessionActionTypes.SESSION_LOGIN_SUCCESS),
      failure: take(SessionActionTypes.SESSION_LOGIN_FAILURE)
    });

    if (!!failure) {
      const error: EdErrorResponseType = {
        type: 'Error',
        code: ErrorCode.NotSpecified, // error-server
        message: getErrorMessage()
      };
      yield put(SessionActions.loginFailure(error, 'login'));
      return;
    }

    itly.mainLoginSuccess();
    yield put({ type: SessionActionTypes.LOGIN_SUCCESS });
  }
}

export function* watchLogin() {
  yield takeLatest(SessionActionTypes.LOGIN, handleLogin);
}
