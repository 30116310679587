import type { DiscussionsState } from './types';

const initialDiscussionsState: DiscussionsState = {
  fetchingProgressLoading: false,
  fetchingProgressError: '',
  fetchingDiscussionErrorCode: undefined,
  progress: {},

  fetchingDiscussionLoading: false,
  fetchingDiscussionError: '',
  discussions: {},

  fetchingPostsLoading: false,
  fetchingPostsError: '',
  fetchingPostItemLoading: false,
  fetchingPostItemError: '',
  savingPostLoading: false,
  savingPostError: '',
  editingPostLoading: false,
  editingPostError: '',
  posts: {},

  fetchingPostCommentsLoading: false,
  fetchingPostCommentsError: '',
  savingPostCommentLoading: false,
  savingPostCommentError: '',
  postComments: {},

  editPostSubscriptionLoading: false,
  editPostSubscriptionError: ''
};

export { initialDiscussionsState };
