import type { ActionsUnion, ActionWithPayload } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { RequestTypes, ErrorResponseType } from '@edapp/request';
import type { ConferenceType, UpcomingConference } from './types';

export enum ConferenceActionTypes {
  FETCH_CONFERENCE = 'FETCH_CONFERENCE',
  FETCH_CONFERENCE_SUCCESS = 'FETCH_CONFERENCE_SUCCESS',
  FETCH_CONFERENCE_FAILURE = 'FETCH_CONFERENCE_FAILURE',

  FETCH_UPCOMING_CONFERENCES = 'FETCH_UPCOMING_CONFERENCES',
  FETCH_UPCOMING_CONFERENCES_SUCCESS = 'FETCH_UPCOMING_CONFERENCES_SUCCESS',
  FETCH_UPCOMING_CONFERENCES_FAILURE = 'FETCH_UPCOMING_CONFERENCES_FAILURE',
  CLEAR_UPCOMING_CONFERENCES = 'CLEAR_UPCOMING_CONFERENCES',

  MARK_CONFERENCE_COMPLETED = 'MARK_CONFERENCE_COMPLETED'
}

export const ConferenceActions = {
  fetchConference: (id: string, options?: { showLoading?: boolean }) =>
    createAction(ConferenceActionTypes.FETCH_CONFERENCE, { id, options }),
  fetchUpcomingConferences: () => createAction(ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES),
  clearUpcomingConferences: () => createAction(ConferenceActionTypes.CLEAR_UPCOMING_CONFERENCES),
  markConferenceCompleted: (id: string) =>
    createAction(ConferenceActionTypes.MARK_CONFERENCE_COMPLETED, { id })
};

export type ConferenceActionsUnionType =
  | ActionsUnion<typeof ConferenceActions>
  | ActionWithPayload<ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS, ConferenceType>
  | ActionWithPayload<ConferenceActionTypes.FETCH_CONFERENCE_FAILURE, ErrorResponseType>
  | ActionWithPayload<
      ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_SUCCESS,
      RequestTypes.PaginatedResponse<UpcomingConference>
    >;
