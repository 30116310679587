import { put, race, select, take } from 'redux-saga/effects';

import {
  DiscussionActionTypes,
  DiscussionActions
} from '@maggie/store/courseware/discussions/actions';
import type { DiscussionAction } from '@maggie/store/courseware/discussions/sagas';
import type { DiscussionPostType } from '@maggie/store/courseware/discussions/types';
import { LessonActivityActions } from '@maggie/store/courseware/lesson-activity/actions';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToDiscussion(discussionId: string): any {
  yield put(DiscussionActions.fetchDiscussionWithProgress(discussionId));

  const posts: DiscussionPostType[] = yield select<LxStoreState>(
    s => s.courseware.discussions.posts[discussionId]?.items || []
  );
  if (posts.length === 0) {
    yield put(DiscussionActions.fetchPosts(discussionId));
  }

  type RaceType = {
    success: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS_SUCCESS>;
  };
  const { success }: RaceType = yield race({
    success: take(DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS_SUCCESS),
    failure: take(DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS_FAILURE)
  });

  if (!!success) {
    const { id, courseVersionNumber } = success.payload.discussion;
    yield put(LessonActions.updateLessonOpened(id, courseVersionNumber));
    yield put(LessonActivityActions.didOpenLessonActivity(discussionId, 'Discussion'));
  }
}
