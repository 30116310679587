import type { MagicLinkState } from './types';
import type { Reducer } from 'redux';
import type { MagicLinkActions } from './actions';
import { MagicLinkActionTypes } from './actions';
import type { ActionsUnion } from '@edapp/utils';

export const initialMagicLinkState: MagicLinkState = {
  email: '',
  error: undefined,
  loading: false,
  ssoCompanyId: ''
};

export const magicLinkReducer: Reducer<MagicLinkState> = (
  state = initialMagicLinkState,
  action: ActionsUnion<typeof MagicLinkActions>
) => {
  switch (action.type) {
    case MagicLinkActionTypes.UPDATE_EMAIL: {
      return {
        ...state,
        email: action.payload.email
      };
    }
    case MagicLinkActionTypes.SEND_MAGIC_LINK: {
      const { email } = action.payload;
      return { ...state, email, loading: true, error: undefined };
    }
    case MagicLinkActionTypes.SEND_MAGIC_LINK_SUCCESS: {
      return { ...state, loading: false, error: undefined };
    }
    case MagicLinkActionTypes.SEND_MAGIC_LINK_FAILURE: {
      const error = action.payload;
      return { ...state, loading: false, error, ssoCompanyId: error?.message || '' };
    }

    default:
      return state;
  }
};
