import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import { WatchStatus } from '../types';
import { DiscussionActionTypes } from './actions';
import { initialDiscussionsState } from './constants';
import type { DiscussionActionsUnionType, DiscussionProgress, DiscussionsState } from './types';

const discussionsReducer = (
  state: DiscussionsState = initialDiscussionsState,
  action: DiscussionActionsUnionType
): DiscussionsState => {
  switch (action.type) {
    case DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS: {
      return { ...state, fetchingProgressLoading: true, fetchingProgressError: '' };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS_SUCCESS: {
      const { id, progress } = action.payload;
      return {
        ...state,
        fetchingProgressLoading: false,
        fetchingProgressError: '',
        progress: {
          ...state.progress,
          [id]: progress
        }
      };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingProgressLoading: false, fetchingProgressError: message };
    }

    case DiscussionActionTypes.FETCH_DISCUSSION: {
      return {
        ...state,
        fetchingDiscussionLoading: true,
        fetchingDiscussionError: '',
        fetchingDiscussionErrorCode: undefined
      };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_SUCCESS: {
      const discussion = action.payload;
      return {
        ...state,
        fetchingDiscussionLoading: false,
        fetchingDiscussionError: '',
        fetchingDiscussionErrorCode: undefined,
        discussions: {
          ...state.discussions,
          [discussion.id]: discussion
        }
      };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      const errorCode = action.payload?.code;
      return {
        ...state,
        fetchingDiscussionLoading: false,
        fetchingDiscussionError: message,
        fetchingDiscussionErrorCode: errorCode
      };
    }

    case DiscussionActionTypes.FETCH_DISCUSSION_POSTS: {
      return { ...state, fetchingPostsLoading: true, fetchingPostsError: '' };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_POSTS_SUCCESS: {
      const { page, discussionId, postsData } = action.payload;
      return {
        ...state,
        fetchingPostsLoading: false,
        fetchingPostsError: '',
        posts: {
          ...state.posts,
          [discussionId]: {
            ...state.posts[discussionId],
            totalCount: postsData.totalCount,
            items:
              page === 1
                ? [...postsData.items]
                : [...(state.posts[discussionId]?.items || []), ...postsData.items]
          }
        }
      };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_POSTS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingPostsLoading: false, fetchingPostsError: message };
    }

    case DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS: {
      return { ...state, fetchingPostCommentsLoading: true, fetchingPostCommentsError: '' };
    }

    case DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS_SUCCESS: {
      return {
        ...state,
        fetchingPostCommentsLoading: false,
        fetchingPostCommentsError: '',
        postComments: {
          ...state.postComments,
          ...action.payload
        }
      };
    }

    case DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingPostCommentsLoading: false, fetchingPostCommentsError: message };
    }

    case DiscussionActionTypes.FETCH_DISCUSSION_POST_COMMENTS: {
      return { ...state, fetchingPostCommentsLoading: true, fetchingPostCommentsError: '' };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_POST_COMMENTS_SUCCESS: {
      const { page, postId, postComments } = action.payload;
      return {
        ...state,
        fetchingPostCommentsLoading: false,
        fetchingPostCommentsError: '',
        postComments: {
          ...state.postComments,
          [postId]: {
            ...state.postComments[postId],
            totalCount: postComments.totalCount,
            items:
              page === 1
                ? [...postComments.items]
                : [...(state.postComments[postId]?.items || []), ...postComments.items]
          }
        }
      };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_POST_COMMENTS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingPostCommentsLoading: false, fetchingPostCommentsError: message };
    }

    case DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM: {
      return { ...state, fetchingPostItemLoading: true, fetchingPostItemError: '' };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM_SUCCESS: {
      const { discussionId, post } = action.payload;
      return {
        ...state,
        fetchingPostItemLoading: false,
        fetchingPostItemError: '',
        posts: {
          ...state.posts,
          [discussionId]: {
            totalCount: state.posts[discussionId]?.totalCount || 1,
            items: [
              // existing posts less the new one that's coming through
              ...(state.posts[discussionId]?.items || []).filter(i => i.id !== post.id),
              post
            ]
          }
        }
      };
    }
    case DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingPostItemLoading: false, fetchingPostItemError: message };
    }

    case DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION: {
      return { ...state, editPostSubscriptionLoading: true, editPostSubscriptionError: '' };
    }
    case DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION_SUCCESS: {
      const { discussionId, postId, isWatching } = action.payload;
      const watchingStatus = isWatching ? WatchStatus.WATCHING : WatchStatus.NOT_WATCHING;

      return {
        ...state,
        editPostSubscriptionLoading: false,
        editPostSubscriptionError: '',
        posts: {
          ...state.posts,
          [discussionId]: {
            ...state.posts[discussionId],
            totalCount: state.posts[discussionId]?.totalCount || 0,
            items: (state.posts[discussionId]?.items || []).map(post => {
              if (postId === post.id) {
                return { ...post, watchingStatus };
              }
              return post;
            })
          }
        }
      };
    }

    case DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, editPostSubscriptionLoading: false, editPostSubscriptionError: message };
    }

    case DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT: {
      return { ...state, savingPostCommentError: '', savingPostCommentLoading: true };
    }
    case DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT_SUCCESS: {
      const { postId, postComment } = action.payload;

      return {
        ...state,
        savingPostCommentLoading: false,
        savingPostCommentError: '',
        postComments: {
          ...state.postComments,
          [postId]: {
            ...state.postComments[postId],
            totalCount: (state.postComments[postId]?.totalCount || 0) + 1,
            items: [...(state.postComments[postId]?.items || []), postComment]
          }
        }
      };
    }
    case DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, savingPostCommentLoading: false, savingPostCommentError: message };
    }

    case DiscussionActionTypes.SAVE_DISCUSSION_POST:
    case DiscussionActionTypes.EDIT_DISCUSSION_POST: {
      return { ...state, savingPostLoading: true, savingPostCommentError: '' };
    }
    case DiscussionActionTypes.SAVE_DISCUSSION_POST_SUCCESS: {
      const { discussionId, post } = action.payload;

      const currentDiscussionProgress: DiscussionProgress = state.progress[discussionId] ?? {
        hasPosted: false,
        hasCommented: false
      };

      return {
        ...state,
        savingPostLoading: false,
        savingPostError: '',
        posts: {
          ...state.posts,
          [discussionId]: {
            ...state.posts[discussionId],
            totalCount: state.posts[discussionId]?.totalCount || 0,
            items: [post, ...(state.posts[discussionId]?.items || [])]
          }
        },
        progress: {
          ...state.progress,
          [discussionId]: {
            ...currentDiscussionProgress,
            hasPosted: true
          }
        }
      };
    }
    case DiscussionActionTypes.SAVE_DISCUSSION_POST_FAILURE:
    case DiscussionActionTypes.EDIT_DISCUSSION_POST_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, savingPostLoading: false, savingPostError: message };
    }

    case DiscussionActionTypes.EDIT_DISCUSSION_POST_SUCCESS: {
      const { discussionId, post } = action.payload;

      const currentPosts = state.posts[discussionId];

      if (!currentPosts) {
        return state;
      }

      const newPosts = currentPosts.items.map(currentPost => {
        if (currentPost.id === post.id) {
          return post;
        }

        return currentPost;
      });

      return {
        ...state,
        savingPostLoading: false,
        savingPostError: '',
        posts: {
          ...state.posts,
          [discussionId]: {
            ...state.posts[discussionId],
            totalCount: currentPosts.totalCount,
            items: newPosts
          }
        }
      };
    }

    case DiscussionActionTypes.CLEAR_DISCUSSION_POSTS: {
      const { discussionId } = action.payload;

      const newPosts = { ...state.posts };
      delete newPosts[discussionId];

      return { ...state, posts: { ...newPosts } };
    }

    default:
      return state;
  }
};

export { discussionsReducer };
