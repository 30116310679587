import * as _ from 'lodash';
import { createSelector } from 'reselect';

import type { LxStoreState } from '@maggie/store/types';

import type { DictionaryPostComments } from '../types';
import type { DiscussionPostType } from './types';

const getProgress = (discussionId: string) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.discussions.fetchingProgressLoading,
    (state: LxStoreState) => state.courseware.discussions.fetchingProgressError,
    (state: LxStoreState) => state.courseware.discussions.progress[discussionId],
    (loading, error, progress) => ({ loading, error, progress })
  );
};

const getDiscussionItem = (discussionId: string) => {
  return (state: LxStoreState) => state.courseware.discussions.discussions[discussionId];
};

const getDiscussion = (discussionId: string) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.discussions.fetchingDiscussionLoading,
    (state: LxStoreState) => state.courseware.discussions.fetchingDiscussionError,
    (state: LxStoreState) => state.courseware.discussions.discussions[discussionId],
    (loading, error, discussion) => ({ loading, error, discussion })
  );
};

const getPosts = (discussionId: string) => (state: LxStoreState) => {
  return {
    loading: state.courseware.discussions.fetchingPostsLoading,
    error: state.courseware.discussions.fetchingPostsError,
    posts: _.orderBy(
      state.courseware.discussions.posts[discussionId]?.items || [],
      p => new Date(p.timestamp),
      'desc'
    ),
    totalCount: state.courseware.discussions.posts[discussionId]?.totalCount || 0
  };
};

const getPostCommentsForManyPosts = (posts: DiscussionPostType[]) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.discussions.postComments,
    postComments => {
      return posts.reduce((res, post) => {
        const comments = postComments[post.id];

        if (!comments) {
          return res;
        }

        res[post.id] = comments;
        return res;
      }, {} as DictionaryPostComments);
    }
  );
};

const getPost = (postId: string) =>
  createSelector(
    (state: LxStoreState) => state.courseware.discussions.posts,
    posts => {
      const allPosts = Object.keys(posts).reduce((result, discussionId) => {
        const discussionPosts = posts[discussionId];
        return [...result, ...(discussionPosts?.items || [])];
      }, [] as DiscussionPostType[]);

      return allPosts.find(i => i.id === postId);
    }
  );

const getPostComments = (postId: string) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.discussions.postComments[postId]?.items || [],
    (state: LxStoreState) => state.courseware.discussions.postComments[postId]?.totalCount || 0,
    (items, totalCount) => ({ items, totalCount })
  );
};

const getFetchErrorCode = (state: LxStoreState) => {
  return state.courseware.discussions.fetchingDiscussionErrorCode;
};

const getTitle = (state: LxStoreState) => {
  const discussionId = state.navigation.discussionId;
  const discussion = DiscussionSelectors.getDiscussion(discussionId)(state).discussion;
  return discussion?.title || '';
};

const getPostForDiscussion = (discussionId: string) => (state: LxStoreState) => {
  const { posts } = getPosts(discussionId)(state);
  return state.navigation.postId || posts[0]?.id;
};

const DiscussionSelectors = {
  getProgress,
  getDiscussion,
  getDiscussionItem,
  getPosts,
  getPostCommentsForManyPosts,
  getPost,
  getPostComments,

  getFetchErrorCode,
  getTitle,
  getPostForDiscussion
};

export { DiscussionSelectors };
