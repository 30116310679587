import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';
import { UserActionTypes } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

import { LearnerDemographicActionTypes, LearnerDemographicActions } from './actions';
import type { LearnerDemographicActionUnion } from './reducer';

type Action<ActionType extends string> = ActionFromActionType<
  LearnerDemographicActionUnion,
  ActionType
>;

function* handleOptOutAnswer(action: Action<LearnerDemographicActionTypes.OPT_OUT_ANSWER>) {
  yield put(LearnerDemographicActions.close());

  const { questionId } = action.payload;

  yield put(
    RequestActions.postAuthed(
      Urls.DEMOGRAPHIC_OPT_OUT(questionId),
      () => LearnerDemographicActions.optOutSuccess(questionId),
      () => LearnerDemographicActions.optOutFailure(questionId),
      undefined,
      {}
    )
  );
}

function* handleSaveAnswer(action: Action<LearnerDemographicActionTypes.SAVE_ANSWER>) {
  yield put(LearnerDemographicActions.close());

  const { questionId, demographicAnswers } = action.payload;

  yield put(
    RequestActions.postAuthed(
      Urls.DEMOGRAPHIC_ANSWER,
      () => LearnerDemographicActions.saveAnswerSuccess(questionId),
      () => LearnerDemographicActions.saveAnswerFailure(questionId),
      undefined,
      { demographicAnswers }
    )
  );

  yield put(LearnerDemographicActions.saveAnswerSuccess(questionId));
}

function* handleFetchUnansweredQuestions() {
  const isIndividualLearner: ReturnType<typeof UserSelectors.isIndividualLearner> = yield select<
    LxStoreState
  >(s => UserSelectors.isIndividualLearner(s.user));

  const questionsLength: number = yield select<LxStoreState>(
    s => s.learnerDemographic.questions.length
  );

  if (!isIndividualLearner || questionsLength > 0) {
    return;
  }

  yield put(
    RequestActions.getAuthed(
      Urls.DEMOGRAPHIC_QUESTIONS,
      LearnerDemographicActionTypes.FETCH_UNANSWERED_QUESTIONS_SUCCESS,
      LearnerDemographicActionTypes.FETCH_UNANSWERED_QUESTIONS_FAILURE,
      undefined,
      {}
    )
  );
}

function* watchFetchUnansweredQuestions() {
  yield takeLatest(
    [
      LearnerDemographicActionTypes.FETCH_UNANSWERED_QUESTIONS,
      UserActionTypes.FETCH_SYNC_USER_SUCCESS
    ],
    handleFetchUnansweredQuestions
  );
}

function* watchSaveAnswer() {
  yield takeLatest(LearnerDemographicActionTypes.SAVE_ANSWER, handleSaveAnswer);
}

function* watchOptOutAnswer() {
  yield takeLatest(LearnerDemographicActionTypes.OPT_OUT_ANSWER, handleOptOutAnswer);
}

export const learnerDemographicSagas = [
  fork(watchFetchUnansweredQuestions),
  fork(watchSaveAnswer),
  fork(watchOptOutAnswer)
];
