import { delay } from 'redux-saga';
import { call, put, spawn, take } from 'redux-saga/effects';

import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { BannersActions } from '@maggie/store/banners/actions';
import { BrainBoostActions } from '@maggie/store/brain-boost/actions';
import { ConferenceActions } from '@maggie/store/courseware/conferences/actions';
import { RapidRefreshActions } from '@maggie/store/courseware/rapid-refreshes/actions';
import { CustomAchievementsActions } from '@maggie/store/custom-achievements/actions';
import { HomeActionTypes, HomeActions } from '@maggie/store/home/actions';

/**
 * We need to break this away so it doesn't block the main navigation saga
 *
 * The delay calls inside of it makes the `navigation/sagas` delay as well
 */
function* delayedCallsForInteractions(): any {
  // This is here because the interaction batch is too slow. Will revisit in the future.
  yield delay(5000);
  yield put(CustomAchievementsActions.fetchCustomAchievementsList());

  // Limit fetch to avoid issue where backend might not process
  // interactions quickly enough to update conference completion
  yield call(delay, 30_000);
  yield put(ConferenceActions.fetchUpcomingConferences());
}

export function* handleDidNavigateToHome(): any {
  HostedWebviewUtils.triggerEvent('homeScreenRendered');

  yield put(HomeActions.setIsLoading(true));
  //will be removed once the data is added to user sync endpoint
  yield put(BrainBoostActions.fetchBrainBoostSession());
  yield put(BannersActions.fetchBanners());
  yield put(HomeActions.fetchForYouWidgets());

  // courses/collections/playlists are fetched inside `prepareUpNext`
  yield put(HomeActions.prepareUpNext());
  yield take(HomeActionTypes.COMPLETE_UP_NEXT);

  // I can only fetch rapid refresh after course collections and its progress have been fetched and calculated
  // Otherwise, there will be a racing condition causing a bug where rapid refresh thinks it's ready and its progress + prerequisites
  // have been calculated correctly but they have not
  yield put(RapidRefreshActions.fetchRapidRefreshList());

  yield put(HomeActions.setIsLoading(false));

  yield spawn(delayedCallsForInteractions);
}
