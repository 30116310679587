import { Platform } from '@maggie/cordova/platform';
import type { LxStoreState } from '@maggie/store/types';

const getRestrictToCourseScreen = (state: LxStoreState) => state.config.restrictToCourseScreen;

const getRestrictToLessonScreen = (state: LxStoreState) => state.config.restrictToLessonScreen;

const isSlowConnection = (state: LxStoreState) => state.config.isSlowConnection;

const isOnline = (state: LxStoreState) => state.config.isOnline;

const getOrientation = (state: LxStoreState) => state.config.orientation;

const getDevicePushNotification = (state: LxStoreState) => state.config.devicePushNotificationToken;

const getPlatformClassNames = (locked: boolean) => (state: LxStoreState) => {
  const platform = window.device?.platform?.toLowerCase() || state.config.platform;

  const classNames = [];
  classNames.push(`is-${platform || 'browser'}`);
  classNames.push(Platform.isTouch() ? 'is-pointer' : 'is-mouse');
  classNames.push(locked ? 'locked' : '');

  return classNames.join(' ');
};

export const ConfigSelectors = {
  getRestrictToCourseScreen,
  getRestrictToLessonScreen,
  isSlowConnection,
  isOnline,
  getOrientation,
  getDevicePushNotification,
  getPlatformClassNames
};
