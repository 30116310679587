import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { openExternalUrl } from '@maggie/cordova/link';
import { Tracker } from '@maggie/core/tracker/tracker';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';

import { DocumentActionTypes } from './actions';
import { watchUpdateDocumentsUnlock } from './documents-unlock-sagas';
import { DocumentSelectors } from './selectors';
import type { DocumentType } from './types';
import type { DocumentDownloadLinkResponse, DocumentsActionsUnionType } from './types';

export type DocumentsAction<ActionType extends string> = ActionFromActionType<
  DocumentsActionsUnionType,
  ActionType
>;

function* handleFetchDocumentDownloadUrl({
  payload: { documentId }
}: DocumentsAction<DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL>) {
  yield put(
    RequestActions.getAuthed<DocumentDownloadLinkResponse>(
      Urls.BRIEFCASE_DOCUMENT_DOWNLOAD_URL(documentId),
      DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_SUCCESS,
      DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_FAILURE,
      undefined,
      {}
    )
  );

  type RaceType = {
    success: DocumentsAction<DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_SUCCESS>;
    failure: DocumentsAction<DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_FAILURE>;
  };
  const { success, failure }: RaceType = yield race({
    success: take(DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_SUCCESS),
    failure: take(DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_FAILURE)
  });

  if (failure) {
    ErrorLogger.captureEvent('Failed to download document', 'error', { documentId });
    return;
  }

  const document: DocumentType | undefined = yield select<LxStoreState>(state =>
    DocumentSelectors.getDocument(documentId, state)
  );

  Tracker.trackEvent({
    type: 'event',
    name: 'document-viewed',
    value: document?.title || '',
    id: documentId
  });

  itly.documentViewed({
    value: document?.title || '',
    id: documentId
  });

  const url = success.payload.url;
  //Adding timeout to open the url as safari blocks window.open() calls made inside a callback function.
  setTimeout(() => {
    openExternalUrl(url);
  }, 1);
}

export function* watchFetchDocumentDownloadUrl() {
  yield takeLatest(DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL, handleFetchDocumentDownloadUrl);
}

export const documentSagas = [
  fork(watchUpdateDocumentsUnlock),
  fork(watchFetchDocumentDownloadUrl)
];
