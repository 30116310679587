import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';

import { PerformanceActionTypes } from './actions';

function* handleFetchUserPerformance() {
  const { currentPage, pageSize } = yield select<LxStoreState>(state => state.performance);

  yield put(
    RequestActions.getAuthed(
      Urls.USER_PERFORMANCE,
      PerformanceActionTypes.FETCH_USER_PERFORMANCE_SUCCESS,
      PerformanceActionTypes.FETCH_USER_PERFORMANCE_FAILURE,
      false,
      { page: currentPage, pageSize }
    )
  );
}

function* watchFetchUserPerformance() {
  yield takeLatest(PerformanceActionTypes.FETCH_USER_PERFORMANCE, handleFetchUserPerformance);
}

export const performanceSagas = [fork(watchFetchUserPerformance)];
