import { fork, put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import { BannersActionTypes } from './actions';

function* handleFetchBanners() {
  yield put(
    RequestActions.getAuthed(
      Urls.BANNERS,
      BannersActionTypes.FETCH_BANNERS_SUCCESS,
      BannersActionTypes.FETCH_BANNERS_FAILURE,
      false,
      { includeCourseBanners: true }
    )
  );
}

function* watchHandleFetchBanners() {
  yield takeLatest(BannersActionTypes.FETCH_BANNERS, handleFetchBanners);
}

export const bannersSagas = [fork(watchHandleFetchBanners)];
