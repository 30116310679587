import { fork, put, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import type { ActionFromActionType } from '@edapp/utils';
import { openExternalUrl } from '@maggie/cordova/link';

import { AwardedCertificatesActionTypes, AwardedCertificatesRequestActions } from './actions';
import type { AwardedCertificatesActionsUnionType } from './actions';

export type AwardedCertificatesAction<ActionType extends string> = ActionFromActionType<
  AwardedCertificatesActionsUnionType,
  ActionType
>;

function* handleFetchAllAwardedCertificates({
  payload: {
    requestData: { page }
  }
}: AwardedCertificatesAction<AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES>) {
  yield put(AwardedCertificatesRequestActions.getLearnersAwardedCertificates(page));
}

function* handleFetchAwardedCertificateDownloadUrl({
  payload: { certificateId, courseId, courseTitle }
}: AwardedCertificatesAction<
  AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL
>) {
  yield put(
    AwardedCertificatesRequestActions.getLearnersAwardedCertificateDownloadUrl(
      certificateId,
      courseId,
      courseTitle
    )
  );
}

function* handleFetchAwardedCertificateDownloadUrlSuccess({
  payload: {
    courseId,
    courseTitle,
    responseData: { url }
  }
}: AwardedCertificatesAction<
  AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_SUCCESS
>) {
  openExternalUrl(url);

  itly.courseCertificateDownloaded({
    course_id: courseId,
    course_title: courseTitle,
    source: 'app'
  });
}

export function* watchFetchAllAwardedCertificates() {
  yield takeLatest(
    AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES,
    handleFetchAllAwardedCertificates
  );
}

export function* watchFetchAwardedCertificateDownloadUrl() {
  yield takeLatest(
    AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL,
    handleFetchAwardedCertificateDownloadUrl
  );
}

export function* watchFetchAwardedCertificateDownloadUrlSuccess() {
  yield takeLatest(
    AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_SUCCESS,
    handleFetchAwardedCertificateDownloadUrlSuccess
  );
}

const awardedCertificateSagas = [
  fork(watchFetchAllAwardedCertificates),
  fork(watchFetchAwardedCertificateDownloadUrl),
  fork(watchFetchAwardedCertificateDownloadUrlSuccess)
];

export { awardedCertificateSagas };
