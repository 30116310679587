import type { RapidRefreshType } from './types';
import type { CourseSummaryType } from '../collections/types';
import { CoursewareTypeNames } from '../types';
import type { DictionaryType } from '@edapp/utils';
import type { CourseProgressType } from '../courses/types';
import { uniq } from 'lodash';
import { MandatoryTypes } from '../courses/constants';
import { CoursewareStatus } from '@edapp/ed-components';

/**
 * This is a workaround because we don't wanna touch the very fragile courseware sagas.
 *
 * The logic is really messy and we're just keeping it consistent for RR.
 * We will revisit later this thing. Hope you don't need to come back to read this again.
 *
 * @param items
 */
export const convertRapidRefreshToCourseSummaries = (items: RapidRefreshType[]) => {
  return items.map<CourseSummaryType>(i => ({
    typeName: CoursewareTypeNames.COURSE_SUMMARY,
    courseId: i.courseId,
    title: i.title,
    description: i.description,
    prerequisites: i.prerequisites,
    // all the properties below are not used
    // we're keeping it here temporarily until we fixed the prerequisites nightmare
    sequentialPlaylists: [],
    brandingImage: '',
    brandingTextColor: 'black',
    thumbnail: '',
    background: '',
    colors: { background: '', text: 'white' },
    percentageRequired: 0,
    planning: {},
    displayActive: true,
    courseStatus: CoursewareStatus.PUBLISHED,
    collectionRank: 0,
    logo: '',
    logoBackgroundColor: 'white',
    libraryImage: '',
    isContentReady: false,
    isFromContentLibrary: false,
    isSponsored: false,
    isInappropriate: false,
    mandatory: {
      type: MandatoryTypes.NoDueDate,
      enabled: false
    },
    lessons: []
  }));
};

/**
 * Only get course ids of course progress that don't exist in datastore.
 * The user experience is already really shit in the home screen, we can save a bit here if
 * the progress is already available
 */
export const prepareCourseIdsToGetProgress = (
  courseProgress: DictionaryType<CourseProgressType>,
  items: RapidRefreshType[]
) => {
  const allIds = items.reduce((acc, item) => {
    const prerequisites = item.prerequisites.map(i => i.id);
    acc = [...acc, ...prerequisites, item.courseId];
    return acc;
  }, [] as string[]);

  const ids = allIds
    // filter only ids for progresses that don't exist
    .filter(id => !courseProgress[id]);

  return uniq(ids);
};
