import { put, race, select, take } from 'redux-saga/effects';

import type { DictionaryType } from '@edapp/utils';
import {
  ConferenceActionTypes,
  ConferenceActions
} from '@maggie/store/courseware/conferences/actions';
import type { ConferenceAction } from '@maggie/store/courseware/conferences/sagas';
import { ConferenceSelectors } from '@maggie/store/courseware/conferences/selectors';
import type { ConferenceType } from '@maggie/store/courseware/conferences/types';
import { LessonActivityActions } from '@maggie/store/courseware/lesson-activity/actions';
import { LessonActionTypes, LessonActions } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonProgressType } from '@maggie/store/courseware/lessons/types';

export function* handleDidNavigateToConference(conferenceId: string): any {
  yield put(LessonActions.fetchLessonsProgress([conferenceId]));

  // wait for fetch lesson progress to finish before carrying on...
  yield race({
    success: take(LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS),
    failure: take(LessonActionTypes.FETCH_LESSON_PROGRESS_FAILURE)
  });

  type LessonProgState = DictionaryType<LessonProgressType>;
  const lessonsProgress: LessonProgState = yield select(LessonSelectors.getLessonsProgress);
  const doesLessonProgressExist = !!lessonsProgress[conferenceId];

  if (doesLessonProgressExist) {
    yield put(ConferenceActions.markConferenceCompleted(conferenceId));
  }

  type ConferenceState = DictionaryType<ConferenceType | undefined>;
  const conferencesById: ConferenceState = yield select(ConferenceSelectors.getConferencesById);
  const doesConferenceExist = !!conferencesById[conferenceId];

  const fetchConferenceOptions = {
    showLoading: !doesConferenceExist
  };

  yield put(ConferenceActions.fetchConference(conferenceId, fetchConferenceOptions));

  type RaceType = { success: ConferenceAction<ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS> };
  const { success }: RaceType = yield race({
    success: take(ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS),
    failure: take(ConferenceActionTypes.FETCH_CONFERENCE_FAILURE)
  });

  if (!!success) {
    const { id, courseVersionNumber } = success.payload;
    yield put(LessonActions.updateLessonOpened(id, courseVersionNumber));
    yield put(LessonActivityActions.didOpenLessonActivity(conferenceId, 'Conference'));
  }
}
