import { createAction } from '@edapp/utils/src/actions';
import type { RegisterInviteCodeResponse, RegistrationSettings } from './types';
import type { ActionsUnion, ActionWithPayload } from '@edapp/utils';
import type { ErrorResponseType } from '@edapp/request';

export enum RegisterActionsTypes {
  CHECK_INVITE_CODE = 'CHECK_INVITE_CODE',
  CHECK_INVITE_CODE_SUCCESS = 'CHECK_INVITE_CODE_SUCCESS',
  CHECK_INVITE_CODE_FAILURE = 'CHECK_INVITE_CODE_FAILURE',

  FETCH_INVITE_CODE_SETTINGS = 'FETCH_INVITE_CODE_SETTINGS',
  FETCH_INVITE_CODE_SETTINGS_SUCCESS = 'FETCH_INVITE_CODE_SETTINGS_SUCCESS',
  FETCH_INVITE_CODE_SETTINGS_FAILURE = 'FETCH_INVITE_CODE_SETTINGS_FAILURE',

  REGISTER_WITH_INVITE_CODE = 'REGISTER_WITH_INVITE_CODE',
  REGISTER_WITH_INVITE_CODE_SUCCESS = 'REGISTER_WITH_INVITE_CODE_SUCCESS',
  REGISTER_WITH_INVITE_CODE_FAILURE = 'REGISTER_WITH_INVITE_CODE_FAILURE',

  SELF_ENROLL = 'SELF_ENROLL',
  SELF_ENROLL_COMPLETED = 'SELF_ENROLL_COMPLETED'
}

export const RegisterActions = {
  checkInviteCode: (inviteCode: string) =>
    createAction(RegisterActionsTypes.CHECK_INVITE_CODE, { inviteCode }),

  fetchInviteCodeSettings: (applicationId: string) =>
    createAction(RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS, { applicationId }),

  registerWithInviteCode: (inviteCode: string, fields: {}) =>
    createAction(RegisterActionsTypes.REGISTER_WITH_INVITE_CODE, { inviteCode, fields }),

  selfEnroll: (courseId: string) => createAction(RegisterActionsTypes.SELF_ENROLL, { courseId }),
  selfEnrollCompleted: () => createAction(RegisterActionsTypes.SELF_ENROLL_COMPLETED, {})
};

export type RegisterActionUnionType =
  | ActionsUnion<typeof RegisterActions>
  | ActionWithPayload<
      RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_SUCCESS,
      RegisterInviteCodeResponse
    >
  | ActionWithPayload<RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_FAILURE, ErrorResponseType>
  | ActionWithPayload<RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_SUCCESS, RegistrationSettings>
  | ActionWithPayload<RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_FAILURE, ErrorResponseType>
  | ActionWithPayload<RegisterActionsTypes.CHECK_INVITE_CODE_SUCCESS, RegistrationSettings>
  | ActionWithPayload<RegisterActionsTypes.CHECK_INVITE_CODE_FAILURE, ErrorResponseType>;
