import { t } from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';

import type { ActionFromActionType } from '@edapp/utils';
import { ThomasPlayerMemo } from '@maggie/core/lessons/thomas-player/lesson-player-memo';
import type { ReviewThomasPlayer } from '@maggie/core/lessons/thomas-player/review-thomas-player';

import { LessonDialogActions } from '../lesson-dialog/actions';
import type { LessonReviewUnionType } from './actions';
import { LessonReviewActionTypes } from './actions';

type Action<ActionType extends string> = ActionFromActionType<LessonReviewUnionType, ActionType>;

export function* handleReattemptLesson(action: Action<LessonReviewActionTypes.REATTEMPT_LESSON>) {
  const { id } = action.payload;

  // First: Open loading dialog ahead - to make sure we don't show white screen when we destroy existing thomas
  yield put(
    LessonDialogActions.openLessonLoadingDialog(t('loader.loading', { ns: 'learners-experience' }))
  );

  // Second: close the lesson
  if (ThomasPlayerMemo.hasPlayer(id)) {
    const player = ThomasPlayerMemo.getPlayer<ReviewThomasPlayer>(id);
    yield call(player.close);
  }

  // Third: navigate to lessonPlay
  window.__router.navigate('lessonPlay', { id });
}

export function* watchReattemptLesson() {
  yield takeLatest(LessonReviewActionTypes.REATTEMPT_LESSON, handleReattemptLesson);
}
