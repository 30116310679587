import type { AccountSettingsState } from './types';
import type { AccountSettingsActionsUnionType } from './actions';
import { AccountSettingsActionTypes } from './actions';

export const initialAccountSettingsState: AccountSettingsState = {
  switchAccountLoading: false,
  linkAccountLoading: false,
  linkAccountError: null
};

export const accountSettingsReducer = (
  state = initialAccountSettingsState,
  action: AccountSettingsActionsUnionType
): AccountSettingsState => {
  switch (action.type) {
    case AccountSettingsActionTypes.LINK_ACCOUNT: {
      return { ...state, linkAccountLoading: true, linkAccountError: null };
    }
    case AccountSettingsActionTypes.LINK_ACCOUNT_SUCCESS: {
      return { ...state, linkAccountLoading: false, linkAccountError: null };
    }
    case AccountSettingsActionTypes.LINK_ACCOUNT_FAILURE: {
      return { ...state, linkAccountLoading: false, linkAccountError: action.payload.code };
    }
    case AccountSettingsActionTypes.CLEAR_LINK_ACCOUNT_ERROR: {
      return { ...state, linkAccountError: null };
    }

    case AccountSettingsActionTypes.SWITCH_ACCOUNT: {
      return { ...state, switchAccountLoading: true };
    }
    case AccountSettingsActionTypes.SWITCH_ACCOUNT_SUCCESS: {
      return { ...state, switchAccountLoading: false };
    }
    case AccountSettingsActionTypes.SWITCH_ACCOUNT_FAILURE: {
      return { ...state, switchAccountLoading: false };
    }

    default:
      return state;
  }
};
