import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import type { AwardedCertificatesActionsUnionType } from './actions';
import { AwardedCertificatesActionTypes } from './actions';
import { initialAwardedCertificatesState } from './constants';
import type { AwardedCertificatesState } from './types';

export const awardedCertificatesReducer = (
  state: AwardedCertificatesState = initialAwardedCertificatesState,
  action: AwardedCertificatesActionsUnionType
): AwardedCertificatesState => {
  let errorMessage = '';

  switch (action.type) {
    case AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES:
      return {
        ...state,
        fetchAllAwardedCertificateLoading: true,
        fetchAllAwardedCertificateError: ''
      };

    case AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES_SUCCESS:
      const { page, responseData } = action.payload;

      return {
        ...state,
        fetchAllAwardedCertificateError: '',
        fetchAllAwardedCertificateLoading: false,
        allAwardedCertificatesData: {
          totalCount: responseData.totalCount,
          items:
            page === 1
              ? [...responseData.items]
              : [...state.allAwardedCertificatesData.items, ...responseData.items]
        }
      };

    case AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES_FAILURE:
      errorMessage = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return {
        ...state,
        fetchAllAwardedCertificateError: errorMessage,
        fetchAllAwardedCertificateLoading: false
      };

    case AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL:
      return {
        ...state,
        fetchAwardedCertificateDownloadUrlLoading: true,
        fetchAwardedCertificateDownloadUrlError: ''
      };

    case AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_SUCCESS:
      return {
        ...state,
        fetchAwardedCertificateDownloadUrlLoading: false
      };

    case AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_FAILURE:
      errorMessage = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return {
        ...state,
        fetchAwardedCertificateDownloadUrlLoading: false,
        fetchAwardedCertificateDownloadUrlError: errorMessage
      };

    default:
      return state;
  }
};
