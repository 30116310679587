import { createAction } from '@edapp/utils';

enum RatingDialogActionTypes {
  OPEN_RATING_DIALOG = 'OPEN_RATING_DIALOG',
  CLOSE_RATING_DIALOG = 'CLOSE_RATING_DIALOG',

  SET_COURSE_RATING = 'SET_COURSE_RATING',
  SET_COURSE_RATING_SUCCESS = 'SET_COURSE_RATING_SUCCESS',
  SET_COURSE_RATING_FAILURE = 'SET_COURSE_RATING_FAILURE'
}

const RatingDialogActions = {
  setCourseRating: (courseId: string, rating: number, review?: string) =>
    createAction(RatingDialogActionTypes.SET_COURSE_RATING, { courseId, rating, review }),

  openRatingDialog: (
    backgroundImage: string | undefined,
    title: string,
    rating: number | null,
    review: string | null
  ) =>
    createAction(RatingDialogActionTypes.OPEN_RATING_DIALOG, {
      backgroundImage,
      title,
      rating,
      review
    }),

  closeRatingDialog: () => createAction(RatingDialogActionTypes.CLOSE_RATING_DIALOG)
};

export { RatingDialogActions, RatingDialogActionTypes };
