import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import type { ObservationActionsUnionType } from './actions';
import { ObservationActionTypes } from './actions';
import { initialObservationsState } from './constants';
import type { ObservationsState } from './types';

const observationsReducer = (
  state: ObservationsState = initialObservationsState,
  action: ObservationActionsUnionType
): ObservationsState => {
  switch (action.type) {
    /**
     * Fetching observation from observationId
     */

    case ObservationActionTypes.FETCH_OBSERVATION: {
      return {
        ...state,
        fetchingObservationLoading: true,
        fetchingObservationError: ''
      };
    }

    case ObservationActionTypes.FETCH_OBSERVATION_SUCCESS: {
      const { observation } = action.payload;

      return {
        ...state,
        fetchingObservationLoading: false,
        fetchingObservationError: '',
        selectedObservation: observation
      };
    }

    case ObservationActionTypes.FETCH_OBSERVATION_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return {
        ...state,
        fetchingObservationLoading: false,
        fetchingObservationError: message
      };
    }

    /**
     * [Observations]
     * For fetching all the observations and children submissions
     *
     */
    case ObservationActionTypes.FETCH_ALL_OBSERVATIONS: {
      return {
        ...state,
        fetchingAllObservationSubmissionsLoading: true,
        fetchingAllObservationSubmissionsError: ''
      };
    }

    case ObservationActionTypes.FETCH_ALL_OBSERVATIONS_SUCCESS: {
      const { page, observationsData } = action.payload;
      return {
        ...state,
        fetchingAllObservationSubmissionsLoading: false,
        fetchingAllObservationSubmissionsError: '',
        completedObservationsList: {
          ...state.completedObservationsList,
          totalCount: observationsData.totalCount,
          items:
            page === 1
              ? [...observationsData.items]
              : [...state.completedObservationsList.items, ...observationsData.items]
        }
      };
    }

    case ObservationActionTypes.FETCH_ALL_OBSERVATIONS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return {
        ...state,
        fetchingAllObservationSubmissionsLoading: false,
        fetchingAllObservationSubmissionsError: message
      };
    }

    case ObservationActionTypes.SET_SELECTED_OBSERVATION: {
      const { observation } = action.payload;
      return {
        ...state,
        selectedObservation: observation
      };
    }

    default:
      return state;
  }
};

export { observationsReducer };
