import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import type { ActionFromActionType } from '@edapp/utils';
import { LockedDialogActions } from '@maggie/store/locked-dialog/actions';
import { getRedirectToHomeParams } from '@maggie/store/locked-dialog/types';
import type { DoNotRedirectParams, RedirectParams } from '@maggie/store/locked-dialog/types';
import type { LxStoreState } from '@maggie/store/types';

import { LessonActions } from '../lessons/actions';
import { LessonSelectors } from '../lessons/selectors';
import type { UnlockPayload } from '../types';
import { ActivityActionTypes, LessonActivityActions } from './actions';
import type { LessonActivityActionsUnionType } from './actions';
import { LessonActivitySelectors } from './selectors';
import type { LessonSummaryWithProgress } from './types';

type ActivityAction<A extends string> = ActionFromActionType<LessonActivityActionsUnionType, A>;

function* handleDidOpenLessonActivity(
  action: ActivityAction<ActivityActionTypes.DID_OPEN_LESSON_ACTIVITY>
) {
  const { id, type } = action.payload;

  const lessonSummary: LessonSummaryWithProgress | undefined = yield select<LxStoreState>(
    LessonActivitySelectors.findLessonSummaryWithProgress(id, type)
  );

  if (!lessonSummary) {
    ErrorLogger.captureEvent(`Opened a lesson activity that doesn't exist`, 'error', { id, type });
    return window.__router.navigate('home', {});
  }

  // Based on `courseware/actions` and `courseware/sagas`
  // Looks like back-end always return initial state wrong for `unlocked` in courseprogress + lessonprogress
  // The front end has been working around by recalculating the value once it receives back
  // We have to make sure when we deeplink, that we do the same here
  const items: UnlockPayload[] = yield select(
    LessonSelectors.getUnlockPayloadFromPrerequisites([lessonSummary])
  );
  yield put(LessonActions.updateLessonsUnlock(items));

  const isLessonAvailable: boolean = yield select<LxStoreState>(store =>
    LessonActivitySelectors.isLessonAvailableForActivity(store, id, type)
  );

  if (!isLessonAvailable) {
    itly.tapLockedLesson();

    const redirectHome = getRedirectToHomeParams();

    const doNotRedirect: DoNotRedirectParams = {
      shouldRedirect: false
    };

    let redirectParams: RedirectParams = doNotRedirect;
    let shouldOpenDialog = false;

    const routeName = window.__router.getMain().routeName;
    switch (routeName) {
      case 'course': {
        shouldOpenDialog = false;
        redirectParams = doNotRedirect;
        break;
      }

      case 'assignment': {
        shouldOpenDialog = true;
        redirectParams = window.__router.isSplitViewLayout('assignment')
          ? redirectHome
          : doNotRedirect;
        break;
      }

      case 'conference': {
        shouldOpenDialog = true;
        redirectParams = window.__router.isSplitViewLayout('conference')
          ? redirectHome
          : doNotRedirect;
        break;
      }

      case 'discussion': {
        shouldOpenDialog = true;
        redirectParams = window.__router.isSplitViewLayout('discussion')
          ? redirectHome
          : doNotRedirect;
        break;
      }

      case 'lesson': {
        shouldOpenDialog = false;
        redirectParams = doNotRedirect;
        break;
      }

      case 'lessonPlay':
      case 'lessonReview':
      default: {
        shouldOpenDialog = true;
        redirectParams = redirectHome;
        break;
      }
    }

    yield put(LockedDialogActions.openLockedLesson(shouldOpenDialog, id, type, redirectParams));
  }

  yield put(LessonActivityActions.didOpenLessonActivityCompleted());
}

export function* watchDidOpenLessonActivity() {
  yield takeLatest(ActivityActionTypes.DID_OPEN_LESSON_ACTIVITY, handleDidOpenLessonActivity);
}

export const lessonActivitySagas = [fork(watchDidOpenLessonActivity)];
