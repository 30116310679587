import * as React from 'react';

import styled, { css } from 'styled-components';

import { Box } from '@edapp/ed-components';

export const SubmissionCardWrapper: React.FC<React.PropsWithChildren<{}>> = ({
  children,
  ...rest
}) => {
  return (
    <StyledContainer bgColor="white" flex flexDirection="column" {...rest}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.sizes.borderRadius}px;
    margin-bottom: ${theme.space(2)}px;
  `
);
