import type { RouteName } from '@maggie/core/router/types';
import type { LxStoreState } from '@maggie/store/types';

import type { SplitDetailState } from './types';

const getCurrentSplitViewDetail = <T extends RouteName>(
  state: LxStoreState
): SplitDetailState<T> | undefined => {
  return state.splitViewLayout.detail as SplitDetailState<T>;
};

const getCurrentSplitViewDetailRouteName = (state: LxStoreState): RouteName | undefined => {
  return state.splitViewLayout.detail?.routeName;
};

export const SplitViewLayoutSelectors = {
  getCurrentSplitViewDetail,
  getCurrentSplitViewDetailRouteName
};
