import { differenceInCalendarDays, isAfter } from 'date-fns';
import { createSelector } from 'reselect';

import type { LxStoreState } from '@maggie/store/types';

const getBrainBoostSessionSlideCount = (state: LxStoreState) => state.brainBoost.slideCount;

const getBrainBoostSessionTime = createSelector(
  (state: LxStoreState) => state.brainBoost.slideCount,
  slideCount => (slideCount ? slideCount * 20 : 0)
);

const getBrainBoostSesssionId = (state: LxStoreState) => state.brainBoost.quiz?.sessionId;

const getBrainBoostSessionCourses = (state: LxStoreState) => state.brainBoost.courses;

const getBrainBoostIsFetching = (state: LxStoreState) => state.brainBoost.isFetching;

const getBrainBoostScore = (state: LxStoreState) => state.brainBoost.sessionResults;

const getCompletedSlides = (state: LxStoreState) => state.brainBoost.completedSlides;

const getIsBrainBoostSummaryOpen = (state: LxStoreState) =>
  state.brainBoost.isBrainBoostSummaryOpen;

const getIsBrainBoostScoreLoading = (state: LxStoreState) =>
  state.brainBoost.isLoadingBrainBoostScore;

const getBrainboostToastState = (state: LxStoreState) => state.brainBoost.toast;

const getIsBrainBoostAvailable = createSelector(
  (state: LxStoreState) => state.brainBoost.availableFrom,
  availableFrom => {
    const availableFromDate = new Date(availableFrom);

    const today = new Date();
    // converting time to UTC time to compare with availableFrom
    const todayInUTC = new Date(today.getTime() + today.getTimezoneOffset() * 60000);

    const hasCompletedBoostedSlides = availableFrom !== '0001-01-01T00:00:00.0000000';
    // nextSession is available when availableFrom is in the past
    const sessionAvailable = hasCompletedBoostedSlides && isAfter(todayInUTC, availableFromDate);

    //if session becomes available later on the day (daysToNextSession=0 but sessionAvailable=false), round up to 1 day in the UI)
    const daysToNextSession = Math.ceil(differenceInCalendarDays(availableFromDate, todayInUTC));
    const daysToNextSessionInUI =
      daysToNextSession === 0 && !sessionAvailable ? 1 : daysToNextSession;
    return {
      hasCompletedBoostedSlides,
      sessionAvailable,
      daysToNextSession: daysToNextSessionInUI
    };
  }
);

const getQuiz = (state: LxStoreState) => state.brainBoost.quiz;

export const BrainBoostSelectors = {
  getBrainBoostSessionSlideCount,
  getBrainBoostSessionTime,
  getBrainBoostSesssionId,
  getBrainBoostSessionCourses,
  getBrainBoostIsFetching,
  getBrainBoostScore,
  getCompletedSlides,
  getBrainboostToastState,
  getIsBrainBoostAvailable,
  getIsBrainBoostSummaryOpen,
  getIsBrainBoostScoreLoading,
  getQuiz
};
