import { createAction } from '@edapp/utils';

export enum ThomasActionTypes {
  CLOSE_THOMAS = 'CLOSE_THOMAS',
  DOM_IS_READY = 'DOM_IS_READY',

  SET_LAST_COMPLETED_SLIDE_INDEX = 'SET_LAST_COMPLETED_SLIDE_INDEX',
  SET_RESUME_LESSON_SLIDE_INDEX = 'SET_RESUME_LESSON_SLIDE_INDEX',

  GO_TO_SLIDE_INDEX = 'GO_TO_SLIDE_INDEX',
  SET_SLIDE_INDEX = 'SET_SLIDE_INDEX',

  DISABLE_NAVIGATION = 'DISABLE_NAVIGATION',
  ENABLE_NAVIGATION = 'ENABLE_NAVIGATION',

  SET_IS_OPEN_MENU = 'SET_IS_OPEN_MENU',
  TOGGLE_HEADER = 'TOGGLE_HEADER'
}

export const ThomasActions = {
  closeThomas: (lessonId: string) => createAction(ThomasActionTypes.CLOSE_THOMAS, { lessonId }),

  /**
   * Triggers when any transition/animation in the lessonPlay is finished
   */
  domIsReady: () => createAction(ThomasActionTypes.DOM_IS_READY, {}),

  disableNavigation: () => createAction(ThomasActionTypes.DISABLE_NAVIGATION, {}),
  enableNavigation: () => createAction(ThomasActionTypes.ENABLE_NAVIGATION, {}),

  /**
   * This one does not update redux.
   * It just triggers the navigation in thomas if indexes are different.
   */
  goToSlideIndex: (lessonId: string, index: number) =>
    createAction(ThomasActionTypes.GO_TO_SLIDE_INDEX, { lessonId, index }),
  setSlideIndex: (index: number) => createAction(ThomasActionTypes.SET_SLIDE_INDEX, { index }),
  setLastCompletedSlideIndex: (index: number) =>
    createAction(ThomasActionTypes.SET_LAST_COMPLETED_SLIDE_INDEX, { index }),

  setIsOpenMenu: (isOpen: boolean) => createAction(ThomasActionTypes.SET_IS_OPEN_MENU, { isOpen }),
  toggleHeader: (lessonId: string, mode: 'desktop' | 'mobile') =>
    createAction(ThomasActionTypes.TOGGLE_HEADER, { lessonId, mode })
};
