import { t } from 'i18next';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { LessonThomasPlayer } from '@maggie/core/lessons/thomas-player/lesson-thomas-player';
import { ActionDialogActions } from '@maggie/store/action-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';

import { LessonActionTypes, LessonActions } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonAction } from './types';
import type { LessonType } from './types';
import { LessonUtils } from './utils';

function* getLesson(lessonId: string) {
  let lesson: LessonType | undefined = yield select((state: LxStoreState) =>
    LessonSelectors.getLesson(lessonId, state)
  );

  // If lesson is not found try fetching it
  if (!lesson) {
    yield put(LessonActions.fetchLessonWithProgress(lessonId));

    const { success } = yield race({
      success: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS),
      failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
    });

    // Now we should have the lesson in our redux store
    if (success) {
      lesson = yield select((state: LxStoreState) => LessonSelectors.getLesson(lessonId, state));
    }
  }

  return lesson;
}

function* handleOpenLesson(action: LessonAction<LessonActionTypes.OPEN_LESSON>) {
  try {
    const lesson: LessonType | undefined = yield getLesson(action.payload.lessonId);
    if (!lesson) {
      ErrorLogger.captureEvent(`Could not find lesson ${action.payload.lessonId}`, 'critical', {});
      return;
    }

    const lessonPlayer = new LessonThomasPlayer(lesson, action.payload.iframeParent || 'body');

    // Open lesson in thomas
    try {
      yield call(lessonPlayer.open);
    } catch {
      window.__router.navigate('lesson', { id: lesson.id });
      const message = t('course.lesson.closed', { ns: 'learners-experience' });
      const confirm = t('course.lesson.ok', { ns: 'learners-experience' });
      yield put(ActionDialogActions.openActionDialog(undefined, message, confirm));
      yield call(lessonPlayer.close);
      return;
    }

    const slideIndex = action.payload.slideId
      ? LessonUtils.findSlideIndexBySlideId(lesson, action.payload.slideId)
      : action.payload.slideIndex;
    if (slideIndex) {
      lessonPlayer.goToPage(slideIndex);
    }

    if (action.payload.openFrom) {
      lessonPlayer.toggleHeader(action.payload.openFrom);
    }

    HostedWebviewUtils.triggerEvent('startLessonClicked');
  } catch (error) {
    ErrorLogger.captureEvent(`Could not open lesson: ${action.payload.lessonId}`, 'error', error);
    console.error(`Could not open lesson: ${action.payload.lessonId}`, error);
  }
}

export function* watchOpenLesson() {
  yield takeLatest(LessonActionTypes.OPEN_LESSON, handleOpenLesson);
}
