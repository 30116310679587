import { createAction } from '@edapp/utils';
import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';

export enum HomeActionTypes {
  SET_IS_LOADING = 'SET_IS_LOADING',

  FETCH_FOR_YOU_WIDGETS = 'FETCH_FOR_YOU_WIDGETS',
  FETCH_FOR_YOU_WIDGETS_SUCCESS = 'FETCH_FOR_YOU_WIDGETS_SUCCESS',
  FETCH_FOR_YOU_WIDGETS_FAILURE = 'FETCH_FOR_YOU_WIDGETS_FAILURE',

  PREPARE_UP_NEXT = 'PREPARE_UP_NEXT',
  COMPLETE_UP_NEXT = 'COMPLETE_UP_NEXT',

  SELECT_TAB_INDEX = 'SELECT_TAB_INDEX'
}

export const HomeActions = {
  setIsLoading: (loading: boolean) => createAction(HomeActionTypes.SET_IS_LOADING, { loading }),

  fetchForYouWidgets: () => createAction(HomeActionTypes.FETCH_FOR_YOU_WIDGETS, {}),

  prepareUpNext: () => createAction(HomeActionTypes.PREPARE_UP_NEXT, {}),
  completeUpNext: (courses: CourseSummaryType[]) =>
    createAction(HomeActionTypes.COMPLETE_UP_NEXT, { courses }),

  selectTabIndex: (tabIndex: number) => createAction(HomeActionTypes.SELECT_TAB_INDEX, { tabIndex })
};
