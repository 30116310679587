import { fork, select, takeLatest } from 'redux-saga/effects';

import type { RouteName } from '@maggie/core/router/types';
import type { LxStoreState } from '@maggie/store/types';

import { UserActionTypes } from './actions';
import { UserSelectors } from './selectors';
import type { UserAction } from './types';
import { watchUserLogout } from './user-logout-sagas';
import { watchFetchSyncUser, watchPollSyncUser } from './user-sync-sagas';

function* handleFetchUserSuccess(action: UserAction<UserActionTypes.FETCH_SYNC_USER_SUCCESS>) {
  const route: RouteName = yield select((state: LxStoreState) => state.navigation.route);
  const isUnverified = UserSelectors.isUserUnverified(action.payload);
  if (route !== 'userNotVerified' && isUnverified) {
    window.__router.navigate('userNotVerified', {});
  } else if (route === 'userNotVerified' && !isUnverified) {
    window.__router.navigate('home', {});
  }
}

function* watchUserFetchSuccess() {
  yield takeLatest(UserActionTypes.FETCH_SYNC_USER_SUCCESS, handleFetchUserSuccess);
}

export const userSagas = [
  fork(watchPollSyncUser),
  fork(watchFetchSyncUser),
  fork(watchUserLogout),
  fork(watchUserFetchSuccess)
];
