import { CustomAchievementsActionTypes } from './actions';
import type { CustomAchievementsActionsUnionType, CustomAchievementsState } from './types';

export const initialCustomAchievementsState: CustomAchievementsState = {
  customAchievementsList: [],
  isLoading: false
};

export const customAchievementsReducer = (
  state: CustomAchievementsState = initialCustomAchievementsState,
  action: CustomAchievementsActionsUnionType
): CustomAchievementsState => {
  switch (action.type) {
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        customAchievementsList: action.payload.data
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE: {
      const message = action.payload?.message;
      return {
        ...state,
        isLoading: false,
        error: message
      };
    }

    default: {
      return state;
    }
  }
};
