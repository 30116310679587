import { ContentLibraryActionTypes } from './actions';
import type { ContentLibraryActionUnionType } from './actions';
import type { ContentLibraryState, ContentLibrarySectionType } from './types';
import { initialContentLibraryState } from './constants';

const contentLibraryReducer = (
  state: ContentLibraryState = initialContentLibraryState,
  action: ContentLibraryActionUnionType
): ContentLibraryState => {
  switch (action.type) {
    case ContentLibraryActionTypes.FETCH_SECTIONS:
    case ContentLibraryActionTypes.FETCH_SECTION:
    case ContentLibraryActionTypes.FETCH_COURSES:
    case ContentLibraryActionTypes.FETCH_COURSE_ITEM: {
      return { ...state, loading: true, error: '' };
    }

    case ContentLibraryActionTypes.FETCH_SECTIONS_FAILURE:
    case ContentLibraryActionTypes.FETCH_SECTION_FAILURE:
    case ContentLibraryActionTypes.FETCH_COURSES_FAILURE:
    case ContentLibraryActionTypes.FETCH_COURSE_ITEM_FAILURE: {
      const { message } = action.payload;
      return { ...state, loading: false, error: message };
    }

    case ContentLibraryActionTypes.FETCH_SECTIONS_SUCCESS: {
      const { page, response } = action.payload;
      const { items, totalCount } = response;

      return {
        ...state,
        loading: false,
        error: '',
        sections: {
          items: page === 1 ? [...items] : [...state.sections.items, ...items],
          totalCount
        }
      };
    }

    case ContentLibraryActionTypes.FETCH_SECTION_SUCCESS: {
      const { sectionId, response, coursesPage } = action.payload;

      const initialValue = { hasFoundSection: false, sections: [] as ContentLibrarySectionType[] };
      const { hasFoundSection, sections } = state.sections.items.reduce((result, section) => {
        if (section.id !== sectionId) {
          result.sections.push(section);
          return result;
        }

        result.hasFoundSection = true;
        result.sections.push({
          ...section,
          ...response,
          courses: {
            items:
              coursesPage === 1
                ? [...response.courses.items]
                : [...section.courses.items, ...response.courses.items],
            totalCount: response.courses.totalCount
          }
        });
        return result;
      }, initialValue);

      if (!hasFoundSection) {
        sections.push({ id: sectionId, ...response });
      }

      return {
        ...state,
        loading: false,
        error: '',
        sections: {
          ...state.sections,
          items: [...sections],
          totalCount: state.sections.totalCount
        }
      };
    }

    case ContentLibraryActionTypes.FETCH_COURSES_SUCCESS: {
      const { page, response } = action.payload;
      const { items, totalCount } = response;

      return {
        ...state,
        loading: false,
        error: '',
        courseSearchResults: {
          ...state.courseSearchResults,
          items: page === 1 ? [...items] : [...state.courseSearchResults.items, ...items],
          totalCount
        }
      };
    }

    case ContentLibraryActionTypes.FETCH_COURSE_ITEM_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        loading: false,
        error: '',
        courseItem: { ...response }
      };
    }

    case ContentLibraryActionTypes.SET_SEARCH: {
      const { searchTerm } = action.payload;
      return {
        ...state,
        searchTerm
      };
    }

    case ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY: {
      return {
        ...state,
        addingCoursesLoading: true,
        addingCoursesError: ''
      };
    }
    case ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        addingCoursesLoading: false,
        addingCoursesError: message
      };
    }
    case ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_SUCCESS: {
      return {
        ...state,
        addingCoursesLoading: false,
        addingCoursesError: ''
      };
    }
    default:
      return state;
  }
};

export { contentLibraryReducer };
