/* eslint-disable no-var */
import type { LoadOptions } from './src/itly';
import { default as defaultItly } from './src/itly';

declare global {
  interface Window {
    __itly: typeof defaultItly;
    __itlyLoaded: boolean;
  }

  var __itly: typeof defaultItly;
  var __itlyLoaded: boolean;
}

export * from './src/page';
export * from './src/itly';
export { SegmentPlugin } from './src/segment-plugin/segment-plugin';

/**
 * Get the shared singleton across different applications
 */
export const itly = (() => {
  const g = globalThis || window;

  if (!g.__itly) {
    g.__itly = defaultItly;
  }

  /**
   * Override load method to ensure we only intialize itly once
   */
  const loadMethodOriginal = g.__itly.load;
  g.__itly.load = function (loadOptions?: LoadOptions) {
    if (!g.__itlyLoaded) {
      loadMethodOriginal.call(this, loadOptions);
      g.__itlyLoaded = true;
    } else {
      console.warn('itly already loaded');
    }
  };

  return g.__itly;
})();
