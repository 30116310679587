import { pxToRem } from '../common/utils';

const BASE_FONT_SIZE = 16;
const BASE_LINE_HEIGHT = 24;
const typography = {
  baseFontSize: BASE_FONT_SIZE,
  baseLineHeight: BASE_LINE_HEIGHT,
  mobileFontSize: 14,
  subtitle1: `
    font-size: ${pxToRem(BASE_FONT_SIZE, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(BASE_LINE_HEIGHT, BASE_FONT_SIZE)}rem;
    font-weight: 500;
  `,
  subtitle2: `
    font-size: ${pxToRem(14, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(20, BASE_FONT_SIZE)}rem;
    font-weight: 600;
    text-transform: uppercase;
  `,
  paragraph: `
    font-size: ${pxToRem(BASE_FONT_SIZE, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(BASE_LINE_HEIGHT, BASE_FONT_SIZE)}rem;
    font-weight: 400;
  `,
  small: `
    font-size: ${pxToRem(14, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(BASE_LINE_HEIGHT, BASE_FONT_SIZE)}rem;
    font-weight: 400;
  `,
  subtext: `
    font-size: ${pxToRem(12, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(16, BASE_FONT_SIZE)}rem;
    font-weight: 400;
  `,
  subtext2: `
    font-size: ${pxToRem(12, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(16, BASE_FONT_SIZE)}rem;
    font-weight: 700;
    letter-spacing: ${pxToRem(1, BASE_FONT_SIZE)}rem;
    text-transform: uppercase;
  `,
  preformatted: `
    font-family: Consolas, monaco, monospace;
    font-size: ${pxToRem(14, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(22, BASE_FONT_SIZE)}rem;
    font-weight: 400;
  `,
  normal: `
    font-size: ${pxToRem(BASE_FONT_SIZE, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(BASE_LINE_HEIGHT, BASE_FONT_SIZE)}rem;
    font-weight: 400;
  `,
  button: `
    font-size: ${pxToRem(BASE_FONT_SIZE, BASE_FONT_SIZE)}rem;
    line-height:  ${pxToRem(BASE_LINE_HEIGHT, BASE_FONT_SIZE)}rem;
    font-weight: 500;
  `
};

export type TypographyVariant = keyof typeof typography;
export { typography, BASE_FONT_SIZE, BASE_LINE_HEIGHT };
