import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { UserActionTypes } from '../user/actions';
import { GameActionTypes } from './actions';
import { GameSelectors } from './selectors';

function* handleFetchGameData() {
  const state: LxStoreState = yield select();
  const isPrizingUser = UserSelectors.isPrizingUser(state.user);

  if (!isPrizingUser || !GameSelectors.isGameEnabled(state)) {
    return;
  }

  yield put(
    RequestActions.getAuthed(
      Urls.GAME,
      GameActionTypes.FETCH_GAME_DATA_SUCCESS,
      GameActionTypes.FETCH_GAME_DATA_FAILURE
    )
  );
}
function* watchFetchGameData() {
  yield takeLatest(
    [GameActionTypes.FETCH_GAME_DATA, UserActionTypes.FETCH_SYNC_USER_SUCCESS],
    handleFetchGameData
  );
}

const gameSagas = [fork(watchFetchGameData)];

export { gameSagas };
