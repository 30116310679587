import * as Backbone from 'backbone';

/**
 * This is a global event manager to manage Backbone.Events.
 * It ensures the application has only one listener for each event.
 *
 * Since Thomas Bridge is not a global object anymore, it's part of a Player.
 * In some scenarios, we could end up having two players instantiated in memory at the same time.
 * That said, we want to avoid having multiple Backbone listeners for the same event.
 *
 * Example that this class avoids:
 *    -> User opens a deeplink to a Lesson.
 *    -> "new-star" event is added
 *    -> User opens a deeplink to another different Lesson.
 *    -> "new-star" event is added (but the previous one wasn't closed)
 *    -> User completes a slide and earns a star. "new-star" event is triggered
 *    -> Event is going to be triggered twice.
 */
class EventManager {
  eventManager: Backbone.Events;

  constructor() {
    this.eventManager = { ...Backbone.Events } as Backbone.Events;
  }

  listenTo(name: string, callback: Backbone.EventHandler) {
    this.eventManager.stopListening(Backbone.Events, name);
    this.eventManager.listenTo(Backbone.Events, name, callback);
  }

  listenToOnce(name: string, callback: Backbone.EventHandler) {
    this.eventManager.stopListening(Backbone.Events, name);
    this.eventManager.listenToOnce(Backbone.Events, name, callback);
  }

  stopListening(name: string) {
    this.eventManager.stopListening(Backbone.Events, name);
  }
}

export const eventManager = new EventManager();
