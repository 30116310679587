/**
 * @file Utilities for interacting with apps which are hosting the learner's
 * app web client in a webview
 */
import { UserSelectors } from '@maggie/store/user/selectors';

const WEBVIEW_EVENTS = 'EdAppEvents-';

const validEvents = [
  'startLessonRendered',
  'startLessonClicked',
  'lessonLoaded',
  'slideRendered',
  'slideCompleted',
  'lessonCompleted',
  'lessonClosed',
  'syncCompleted',
  'homeScreenRendered',
  'courseScreenRendered',
  'loginSSOSuccess',
  'loginSSOFailure',
  'unauthorized',
  'ready'
] as const;

type Events = typeof validEvents[number];

export const HostedWebviewUtils = {
  triggerBootstrapError(reason: string, path: string = location.pathname) {
    const msg = { type: 'error_bootstrap', reason, path };
    window.parent?.postMessage(JSON.stringify(msg), '*');
  },
  /**
   * This method will post messages to the window.parent
   */
  triggerLocationChange(path: string) {
    if (!UserSelectors.hasPostMessageWindowLocationEnabled(window.__store.getState())) {
      return; // not enable for this account
    }

    if (typeof path !== 'string') {
      throw new Error(`HostedWebviewUtils.triggerLocationChange: invalid path`);
    }

    const msg = { type: 'location_changed', path };
    window.parent.postMessage(JSON.stringify(msg), '*');
  },

  /**
   * This method will post messages to the window.parent
   *
   * It only accepts messages as strings.
   *
   * !Note: Do not call this method with any PI or user data.
   * If you need to post messages with payloads of data,
   * you could create a new version of this function and add a targetOrigin
   * to whatever websites are allowed to listen to those messages.
   */
  triggerEvent(eventName: Events) {
    if (typeof eventName !== 'string' || !validEvents.includes(eventName)) {
      throw new Error(`HostedWebviewUtils.triggerEvent should be only called with event names.`);
    }

    const evt = `${WEBVIEW_EVENTS}${eventName}`;
    window.parent?.postMessage(evt, '*');
  }
};
