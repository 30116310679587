const colors = {
  statusBar: '#1f232f',

  zilch: 'rgba(0, 0, 0, 0)',

  white: '#FFFFFF',

  // The greys
  darkNavbar: '#172335',
  athenGrey: '#F9F9FB',
  border: '#e2e6ea',
  loadingShadow: '#dbe3eb',
  blueishGrey: '#cdecfb',
  mediumGrey: '#bdccdb',
  darkGrey: '#b4bcc6',
  mirage: '#171E2D',
  ebonyClay: '#202737',
  riverBed: '#4B5367',

  // Intended override of ed-components/theme
  navyMuted: '#91A6BA',

  // Greens
  lightGreen: '#67db89',
  mediumGreen: '#34cf61',

  stormBlue: '#526884',
  dark20CardBlue: '#106086',
  cardBlue: '#46B4E9',
  starsDialogBlue: '#102341',

  darkGreyGradient: 'linear-gradient(0deg, #18212e 0%, #314556 100%)',
  brandedHeaderBranding: 'linear-gradient(to bottom,rgba(29,45,67,.2),rgba(29,45,67,0) 60px)',
  hasBranding: 'linear-gradient(to top right, rgba(255, 255, 255, .06), rgba(0,0,0,0))',
  reviewerCard: 'rgba(0,0,0,0.5)',

  gold: '#ffb100',

  // We can find approximate names for colors from here - http://chir.ag/projects/name-that-color/
  monza: 'rgba(208, 2, 27, 0.6)',

  loginGradient: `linear-gradient(rgba(41, 200, 255, 0.486), rgb(56, 190, 244), rgb(70, 180, 233))`
};

export default colors;
