import type { DictionaryType } from '@edapp/utils';
import type {
  AttemptInteraction,
  SlideInteraction,
  ViewInteraction
} from '@maggie/core/tracker/hippo-interaction-types';
import { TrackerInteractions } from '@maggie/core/tracker/tracker-interactions';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonType } from '@maggie/store/courseware/lessons/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { SlideScoring } from '../scoring/slide-scoring';
import type {
  PackageType,
  ThomasSlideInteraction,
  ThomasViewInteraction
} from '../thomas/thomas-interaction-types';
import { Attempt } from './attempt';
import type { AttemptSlideInteraction } from './types';

export class LessonAttempt extends Attempt {
  private lesson: LessonType;

  constructor(
    lesson: LessonType,
    id?: string,
    interactions?: DictionaryType<AttemptSlideInteraction>
  ) {
    super(lesson.configuration.slides, id, interactions);
    this.lesson = lesson;
  }

  public startAttempt = () => {
    super.startAttempt();

    // Save info to FE
    window.__store.dispatch(LessonActions.startAttemptLesson(this.lesson.id, this));
  };

  public finishAttempt = () => {
    super.finishAttempt();

    // Save info to BE
    const state = window.__store.getState();
    const minimumScore = LessonSelectors.getLessonMinimumScore(this.lesson.id)(state);
    this._successful = LessonSelectors.isScored(this.lesson)
      ? this.areRequiredPackagesCompleted() && this._score >= minimumScore
      : this.areRequiredPackagesCompleted() && this.hasSeenLastOrExitSlide();
    const interaction: AttemptInteraction = {
      type: 'attempt',
      started_timestamp: this._startedAt,
      ended_timestamp: this._endedAt,
      attempt: this._attemptId,
      score: this._score,
      success: this._successful,
      successful: this._successful,

      // lesson properties
      entity: this.lesson.id,
      courseId: this.lesson.courseId,
      courseVersionNumber: this.lesson.courseVersionNumber,
      locale: this.lesson.locale,
      lessonExternalIdentifier: this.lesson.externalId,
      needed_score: minimumScore,

      // Fuck knows what the below is - probably not used?
      // Check the database in prod and the query timesout before finds an instance of that
      counted_to_course_reset: false,
      scorm_data: '',
      // Confirm if stars info are required
      earned_stars: 0,
      needed_stars: 0,
      max_stars: 0
    };
    TrackerInteractions.trackAttemptInteraction(interaction);

    // Save info to FE
    window.__store.dispatch(LessonActions.finishAttemptLesson(this.lesson.id, this));
  };

  protected recordView = (view: ThomasViewInteraction) => {
    super.recordView(view);

    // Save info to FE
    window.__store.dispatch(
      LessonActions.attemptSlide(this.lesson.id, this._attemptId, { id: view.id })
    );

    // Save info to BE
    const state = window.__store.getState();
    const isCompleted = !!LessonSelectors.getLessonProgress(this.lesson.id, state)?.completed;
    const interaction: ViewInteraction = {
      ...view,
      name: `${view.name + 1}`,
      type: 'view',
      lesson_id: this.lesson.id,
      lesson_title: this.lesson.title,
      course_id: this.lesson.courseId,
      courseVersionNumber: this.lesson.courseVersionNumber,
      already_completed: isCompleted,
      progress: 0,
      attempt: this._attemptId
    };
    TrackerInteractions.trackViewWithDuration(interaction);
  };

  protected recordInteraction(interaction: ThomasSlideInteraction) {
    const slide = this.findSlide(interaction.id);
    if (!!slide) {
      // Calculate score before storing in base attempt class
      const weight = SlideScoring.getSlideWeight(slide);
      const weightedScore = SlideScoring.calculate(interaction, weight);
      interaction.weightedScore = weightedScore;

      // Only save to base class if interaction is in fact from a slide
      // There could be other types of interactions coming here.
      // The other types of interaction are mostly to save user state,
      // such as: progress in scorm/aicc, if user has finished watching video or not
      super.recordInteraction(interaction);

      // Save info to FE
      window.__store.dispatch(
        LessonActions.attemptSlide(this.lesson.id, this._attemptId, interaction)
      );
    }

    // Save info to BE
    const state = window.__store.getState();
    const isCompleted = !!LessonSelectors.getLessonProgress(this.lesson.id, state)?.completed;
    const userExternalId = UserSelectors.getExternalIdentifier(state.user);
    const slideInteraction: SlideInteraction & ThomasSlideInteraction & { is_lesson: boolean } = {
      ...interaction,
      type: 'slide',
      attempt: this._attemptId,
      lesson_id: this.lesson.id,
      lesson_title: this.lesson.title,
      locale: this.lesson.locale,
      course_id: this.lesson.courseId,
      courseVersionNumber: this.lesson.courseVersionNumber,
      already_completed: isCompleted,
      lesson_external_identifier: this.lesson.externalId,
      user_external_identifier: userExternalId,
      is_lesson: true
    };
    TrackerInteractions.trackSlideInteraction(slideInteraction);
  }

  protected calculateScore() {
    super.calculateScore();

    const totalWeights = this._slides.reduce(
      (total, slide) => total + SlideScoring.getSlideWeight(slide),
      0
    );
    if (totalWeights <= 0) {
      return this.hasSeenLastOrExitSlide() ? 100 : 0;
    }

    const totalEarned = this.interactions.reduce((total, interaction) => {
      // by this point all interactions should have their own score calculated already
      return total + (interaction.weightedScore || 0);
    }, 0);

    return Math.round((100.0 * totalEarned) / totalWeights);
  }

  private findSlide(slideId: string) {
    const slide = this._slides.find(s => s.id === slideId);
    if (!slide) {
      return;
    }

    return slide;
  }

  /**
   * If it does contain AICC or SCORM slides
   * which needs to be completed for the lesson to be completed
   */
  private areRequiredPackagesCompleted() {
    const packageInteractions = this.interactions.filter((i: PackageType): i is PackageType => {
      return i?.isRequiredForLessonCompletion === true;
    });

    return packageInteractions.every(interaction => interaction.isCompleted);
  }
}
