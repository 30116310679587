import type * as moment from 'moment';
import { format, isAfter, isBefore, formatDistanceToNowStrict } from 'date-fns';

export const isValidDate = (date: any): boolean =>
  (isDateInstance(date) || isDateType(date)) && isValidDateString(date);

export const isDateInstance = (date: any): boolean => date instanceof Date;

export const isDateType = (date: any): boolean =>
  Object.prototype.toString.call(date) === '[object Date]';

export const isValidDateString = (date: any): boolean =>
  !!date ? new Date(date).toString() !== 'Invalid Date' : false;

// Format Moment to UTC Date
export const formatAsUTCDate = (dateMoment: moment.Moment) =>
  dateMoment.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

// Format Date to UTC Date
export const formatDateAsUTCDate = (date: Date) => `${format(date, 'YYYY-MM-DDTHH:mm:ss.SSS')}Z`;

export const formatAsTimeZoneAgnosticDate = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ss");

// Date-Time as localised String eg: "12:00AM on April 9th, 2022"
export const formatAsLocalisedDateTime = (date: Date) =>
  format(date, "h:mmaaaaa'm' 'on' MMMM do, yyyy");

export const removeUTCOffset = (date?: string) => {
  if (date && date.substring(date.length - 1, date.length) === 'Z') {
    return date.substring(0, date.length - 1);
  }
  return date;
};

export const getDateWithinRange = (date: Date, minDate?: Date, maxDate?: Date) => {
  if (!!minDate && isBefore(date, minDate)) {
    return minDate;
  } else if (!!maxDate && isAfter(date, maxDate)) {
    return maxDate;
  } else {
    return date;
  }
};

/**
 * Show the difference between a specific date to now, in a short manner.
 * @param date e.g. 2022-08-17 10:35:00
 * @returns formatted duration, e.g. 5s, 5m, 1d, 5mo
 */
export function formatDateShort(date: Date) {
  try {
    return formatDistanceToNowStrict(date)
      .replace(/0 seconds/, '1 second') // skip 0s
      .replace(/\sseconds?/, 's')
      .replace(/\sminutes?/, 'm')
      .replace(/\shours?/, 'h')
      .replace(/\sdays?/, 'd')
      .replace(/\smonths?/, 'mo')
      .replace(/\syears?/, 'y');
  } catch {
    return '-';
  }
}

/**
 * MonthNumber starting from 1
 * @example Jan -> 1, Feb -> 2 . . . Dec -> 12
 */
export enum Months {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}

/**
 * Returns a Record of Max No. of Days in each month for Leap/Non-Leap years
 * @param isLeapYear Default is False
 * @returns Record<Months, number>
 */
export const getMaxDaysInMonth = (isLeapYear: boolean = false): Record<Months, number> =>
  !isLeapYear
    ? {
        [Months.January]: 31,
        [Months.February]: 28,
        [Months.March]: 31,
        [Months.April]: 30,
        [Months.May]: 31,
        [Months.June]: 30,
        [Months.July]: 31,
        [Months.August]: 31,
        [Months.September]: 30,
        [Months.October]: 31,
        [Months.November]: 30,
        [Months.December]: 31
      }
    : {
        [Months.January]: 31,
        [Months.February]: 29,
        [Months.March]: 31,
        [Months.April]: 30,
        [Months.May]: 31,
        [Months.June]: 30,
        [Months.July]: 31,
        [Months.August]: 31,
        [Months.September]: 30,
        [Months.October]: 31,
        [Months.November]: 30,
        [Months.December]: 31
      };

type monthOptions = 'long' | 'short';
/**
 Get the name of Month from the month number
 * @param monthNumber Numerical Index of Month starting at 1. Jan -> 1, Feb -> 2 ..
 * @param options "long" - January | "short" - Jan
 * @param locale Default: en
 * @returns Name of Month
 */
export const getMonthName = (
  monthNumber: Months,
  options: monthOptions = 'long',
  locale: string = 'en'
) =>
  new Date(new Date().getFullYear(), monthNumber - 1, 1).toLocaleString(locale, { month: options });

/**
 * Get the date formatted based on the given locale
 * @param date Date to be localized
 * @param locale Default: en-GB [dd MMM yyy]
 * @param options Intl.DateTimeFormatOptions
 * @returns Date formatted based on the given locale
 */
export const formatDateTimeToLocale = (
  date: Date,
  locale: string = 'en-GB', // dd MMM yyyy
  options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' }
) => new Intl.DateTimeFormat(locale, options).format(date);
