import type { CourseLanguageActionsUnionType } from './actions';
import { CourseLanguageActionTypes } from './actions';
import { initialCourseLanguageState } from './constants';
import type { CourseLanguageState } from './types';

const courseLanguageReducer = (
  state: CourseLanguageState = initialCourseLanguageState,
  action: CourseLanguageActionsUnionType
): CourseLanguageState => {
  switch (action.type) {
    /**
     * Set a language for a particular course, this will change the content of the course accordingly
     */
    case CourseLanguageActionTypes.SET_COURSE_LANGUAGE: {
      const { courseId, languageCode } = action.payload;
      return {
        ...state,
        courseLanguageById: {
          [courseId]: {
            ...state.courseLanguageById[courseId],
            languageCode
          }
        }
      };
    }

    default:
      return state;
  }
};

export { courseLanguageReducer };
