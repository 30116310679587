import { put, race, select, take } from 'redux-saga/effects';

import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { LessonActivityActions } from '@maggie/store/courseware/lesson-activity/actions';
import { LessonActionTypes, LessonActions } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonType } from '@maggie/store/courseware/lessons/types';
import { LessonUtils } from '@maggie/store/courseware/lessons/utils';
import { SocialLearningActions } from '@maggie/store/social-learning/actions';
import type { LxStoreState } from '@maggie/store/types';

/**
 * Deeplinks don't include different urls based on the type of the lesson
 *
 * They are all launched as `#launch-lesson`.
 * Because of that, we need to check here and redirect user to the correct
 * route based on the type of the base lesson.
 *
 * @returns `boolean` - tells if redirection has happened
 */
function* checkLessonTypeRedirection(lessonId: string) {
  const lesson: LessonType | undefined = yield select<LxStoreState>(s =>
    LessonSelectors.getLesson(lessonId, s)
  );

  if (lesson?.lessonType === 'Lesson') {
    return false; // no redirection required
  }

  LessonUtils.navigate(lessonId, lesson?.lessonType, true);
  return true;
}

export function* handleDidNavigateToLesson(lessonId: string, slideId?: string) {
  yield put(LessonActions.fetchLessonWithProgress(lessonId));
  yield put(SocialLearningActions.fetchSocialLearningSummary(lessonId));

  const { success } = yield race({
    success: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS),
    failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
  });

  const hasRedirected: boolean = yield checkLessonTypeRedirection(lessonId);
  if (!!hasRedirected) {
    return;
  }

  // Open a lesson with a slide id, used for peer authoring
  if (!!slideId) {
    yield put(LessonActions.openLesson(lessonId, 0, slideId));
  }

  if (success) {
    // This event must be triggered whenever the start lesson view has loaded
    HostedWebviewUtils.triggerEvent('startLessonRendered');
    yield put(LessonActivityActions.didOpenLessonActivity(lessonId, 'Lesson'));

    const courseId: string | undefined = yield select<LxStoreState>(
      LessonSelectors.getLessonCourseId(lessonId)
    );
    if (courseId) {
      yield put(LessonActions.fetchCourseLeaderboard(courseId));
    }
  }
}
