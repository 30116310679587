import { breakpoints } from './breakpoints';

const flexboxgrid = {
  gridSize: 12, // columns
  gutterWidth: 1.5, // rem
  outerMargin: 0, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46.5, // rem
    md: 61.5, // rem
    lg: 76.5, // rem
    xlg: 82.5 // rem
  },
  breakpoints
};

export default flexboxgrid;
