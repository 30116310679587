import { createAction } from '@edapp/utils';
import type { DemographicQuestionType, AnswersValue } from './types';

export enum LearnerDemographicActionTypes {
  OPEN = 'LEARNER_DEMOGRAPHIC_OPEN',
  CLOSE = 'LEARNER_DEMOGRAPHIC_CLOSE',

  FETCH_UNANSWERED_QUESTIONS = 'FETCH_UNANSWERED_QUESTIONS',
  FETCH_UNANSWERED_QUESTIONS_SUCCESS = 'FETCH_UNANSWERED_QUESTIONS_SUCCESS',
  FETCH_UNANSWERED_QUESTIONS_FAILURE = 'FETCH_UNANSWERED_QUESTIONS_FAILURE',

  SAVE_ANSWER = 'SAVE_ANSWER',
  SAVE_ANSWER_SUCCESS = 'SAVE_ANSWER_SUCCESS',
  SAVE_ANSWER_FAILURE = 'SAVE_ANSWER_FAILURE',

  OPT_OUT_ANSWER = 'OPT_OUT_ANSWER',
  OPT_OUT_ANSWER_SUCCESS = 'OPT_OUT_ANSWER_SUCCESS',
  OPT_OUT_ANSWER_FAILURE = 'OPT_OUT_ANSWER_FAILURE'
}

export const LearnerDemographicActions = {
  open: () => createAction(LearnerDemographicActionTypes.OPEN, {}),
  close: () => createAction(LearnerDemographicActionTypes.CLOSE, {}),

  fetchUnansweredQuestions: () =>
    createAction(LearnerDemographicActionTypes.FETCH_UNANSWERED_QUESTIONS, {}),
  fetchUnansweredQuestionsSuccess: (questions: DemographicQuestionType[]) =>
    createAction(LearnerDemographicActionTypes.FETCH_UNANSWERED_QUESTIONS_SUCCESS, questions),

  saveAnswer: (questionId: string, demographicAnswers: AnswersValue[]) =>
    createAction(LearnerDemographicActionTypes.SAVE_ANSWER, { questionId, demographicAnswers }),
  saveAnswerSuccess: (questionId: string) =>
    createAction(LearnerDemographicActionTypes.SAVE_ANSWER_SUCCESS, { questionId }),
  saveAnswerFailure: (questionId: string) =>
    createAction(LearnerDemographicActionTypes.SAVE_ANSWER_FAILURE, { questionId }),

  optOut: (questionId: string) =>
    createAction(LearnerDemographicActionTypes.OPT_OUT_ANSWER, { questionId }),
  optOutSuccess: (questionId: string) =>
    createAction(LearnerDemographicActionTypes.OPT_OUT_ANSWER_SUCCESS, { questionId }),
  optOutFailure: (questionId: string) =>
    createAction(LearnerDemographicActionTypes.OPT_OUT_ANSWER_FAILURE, { questionId })
};
