import { put, race, select, take } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { LESSON_PLAY_IFRAME_PARENT } from '@maggie/containers/lesson-play/constants';
import { AppLayoutSelectors } from '@maggie/layout/selectors';
import {
  ActivityActionTypes,
  LessonActivityActions
} from '@maggie/store/courseware/lesson-activity/actions';
import { LessonActivitySelectors } from '@maggie/store/courseware/lesson-activity/selectors';
import { LessonActionTypes, LessonActions } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonType } from '@maggie/store/courseware/lessons/types';
import { ThomasActionTypes } from '@maggie/store/thomas/actions';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToLessonPlay(lessonId: string, slideIndex: number): any {
  yield take(ThomasActionTypes.DOM_IS_READY);

  const lesson: LessonType | undefined = yield fetchLesson(lessonId);
  if (!lesson) {
    ErrorLogger.captureEvent(`Opened a lesson play that doesn't exist`, 'error', { lessonId });
    return window.__router.navigate('home', {});
  }

  // Recalculate lesson's UNLOCKED status + show locked dialog
  yield put(LessonActivityActions.didOpenLessonActivity(lessonId, 'Lesson'));
  yield take(ActivityActionTypes.DID_OPEN_LESSON_ACTIVITY_COMPLETED);

  const isLessonAvailable: boolean = yield select<LxStoreState>(store =>
    LessonActivitySelectors.isLessonAvailableForActivity(store, lessonId, 'Lesson')
  );

  // If the lesson is locked, don't open Thomas. LessonLocked Dialog will appear and redirect user to home screen
  if (!isLessonAvailable) {
    return;
  }

  const openingFrom = AppLayoutSelectors.getIsMobile(undefined, 'lessonPlay')
    ? 'mobile'
    : 'desktop';

  // if there is no slideIndex specified to be opened, always open at the first slide
  const sIndex = slideIndex !== undefined ? slideIndex : 0;

  yield put(
    LessonActions.openLesson(
      lessonId,
      sIndex,
      undefined,
      `#${LESSON_PLAY_IFRAME_PARENT}`,
      openingFrom
    )
  );
}

export function* fetchLesson(lessonId: string) {
  let lesson: LessonType | undefined = yield select((state: LxStoreState) =>
    LessonSelectors.getLesson(lessonId, state)
  );

  if (!lesson) {
    yield put(LessonActions.fetchLessonWithProgress(lessonId));

    yield race({
      success: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS),
      failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
    });

    lesson = yield select((state: LxStoreState) => LessonSelectors.getLesson(lessonId, state));
  }

  return lesson;
}
