import { t } from 'i18next';
import { put, race, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { SessionActions } from '@maggie/store/session/actions';
import { ToastActions } from '@maggie/store/toast/actions';
import { UserActionTypes, UserActions } from '@maggie/store/user/actions';

import type { AccountSettingsActionsUnionType } from '../actions';
import { AccountSettingsActionTypes } from '../actions';

type Action<ActionType extends string> = ActionFromActionType<
  AccountSettingsActionsUnionType,
  ActionType
>;

type SwitchAccountRaceType = {
  success: Action<AccountSettingsActionTypes.SWITCH_ACCOUNT_SUCCESS>;
  failure: Action<AccountSettingsActionTypes.SWITCH_ACCOUNT_FAILURE>;
};

export function* handleSwitchAccount(
  action: Action<AccountSettingsActionTypes.SWITCH_ACCOUNT>
): any {
  const { userId } = action.payload;

  yield put(
    RequestActions.postAuthed(
      Urls.SWITCH_ACCOUNT,
      AccountSettingsActionTypes.SWITCH_ACCOUNT_SUCCESS,
      AccountSettingsActionTypes.SWITCH_ACCOUNT_FAILURE,
      undefined,
      { otherUserId: userId }
    )
  );

  const { failure, success }: SwitchAccountRaceType = yield race({
    success: take(AccountSettingsActionTypes.SWITCH_ACCOUNT_SUCCESS),
    failure: take(AccountSettingsActionTypes.SWITCH_ACCOUNT_FAILURE)
  });

  if (failure) {
    yield put(
      ToastActions.showToast(true, t('dialog.error.message', { ns: 'learners-experience' }))
    );
    return;
  }

  yield put(UserActions.userLogout(false, false));
  yield take(UserActionTypes.USER_DID_LOGOUT);

  const { tokens } = success.payload;
  yield put(SessionActions.sessionLogin(tokens, { redirect: false }));
}

export function* watchSwitchAccountSagas() {
  yield takeLatest(AccountSettingsActionTypes.SWITCH_ACCOUNT, handleSwitchAccount);
}
