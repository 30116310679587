import { createAction } from '@edapp/utils';
import type { Attempt } from '@maggie/core/lessons/attempt/attempt';
import type { AttemptSlideInteraction } from '@maggie/core/lessons/attempt/types';

import type { CourseType } from '../courses/types';
import type { UnlockPayload } from '../types';
import type { LessonAttemptType } from './types';

enum LessonActionTypes {
  FETCH_LESSON = 'FETCH_LESSON',
  FETCH_LESSON_SUCCESS = 'FETCH_LESSON_SUCCESS',
  FETCH_LESSON_FAILURE = 'FETCH_LESSON_FAILURE',

  FETCH_LESSON_WITH_PROGRESS = 'FETCH_LESSON_WITH_PROGRESS',
  FETCH_LESSON_WITH_PROGRESS_SUCCESS = 'FETCH_LESSON_WITH_PROGRESS_SUCCESS',
  FETCH_LESSON_WITH_PROGRESS_FAILURE = 'FETCH_LESSON_WITH_PROGRESS_FAILURE',

  FETCH_LESSON_ATTEMPT = 'FETCH_LESSON_ATTEMPT',
  FETCH_LESSON_ATTEMPT_SUCCESS = 'FETCH_LESSON_ATTEMPT_SUCCESS',
  FETCH_LESSON_ATTEMPT_FAILURE = 'FETCH_LESSON_ATTEMPT_FAILURE',

  FETCH_LESSON_ATTEMPT_LIST = 'FETCH_LESSON_ATTEMPT_LIST',
  FETCH_LESSON_ATTEMPT_LIST_SUCCESS = 'FETCH_LESSON_ATTEMPT_LIST_SUCCESS',
  FETCH_LESSON_ATTEMPT_LIST_FAILURE = 'FETCH_LESSON_ATTEMPT_LIST_FAILURE',

  FETCH_LESSON_PROGRESS = 'FETCH_LESSON_PROGRESS',
  FETCH_LESSON_PROGRESS_SUCCESS = 'FETCH_LESSON_PROGRESS_SUCCESS',
  FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS = 'FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS',
  FETCH_LESSON_PROGRESS_FAILURE = 'FETCH_LESSON_PROGRESS_FAILURE',
  FETCH_LESSON_PROGRESS_PREREQUISITES_FAILURE = 'FETCH_LESSON_PROGRESS_PREREQUISITES_FAILURE',

  FETCH_LESSON_BREADCRUMB = 'FETCH_LESSON_BREADCRUMB',
  FETCH_LESSON_BREADCRUMB_SUCCESS = 'FETCH_LESSON_BREADCRUMB_SUCCESS',
  FETCH_LESSON_BREADCRUMB_FAILURE = 'FETCH_LESSON_BREADCRUMB_FAILURE',

  UPDATE_LESSON_OPENED = 'UPDATE_LESSON_OPENED',
  UPDATE_LESSON_COMPLETED = 'UPDATE_LESSON_COMPLETED',
  UPDATE_LESSON_FAILED = 'UPDATE_LESSON_FAILED',
  UPDATE_LESSONS_UNLOCK = 'UPDATE_LESSONS_UNLOCK',

  UPDATE_LESSON_ATTEMPT_FAILURE = 'UPDATE_LESSON_ATTEMPT_FAILURE',

  RESET_LESSONS_PROGRESS = 'RESET_LESSONS_PROGRESS',

  SET_LEADERBOARD_SUMMARY_DIALOG_OPEN = 'SET_LEADERBOARD_SUMMARY_DIALOG_OPEN',

  FETCH_LESSONS_ACCESS = 'FETCH_LESSONS_ACCESS',
  FETCH_LESSONS_ACCESS_SUCCESS = 'FETCH_LESSONS_ACCESS_SUCCESS',
  FETCH_LESSONS_ACCESS_FAILURE = 'FETCH_LESSONS_ACCESS_FAILURE',

  CHECK_LESSONS_PREREQUISITES = 'CHECK_LESSONS_PREREQUISITES',

  FINISHED_THOMAS_LESSON = 'FINISHED_THOMAS_LESSON',

  FETCH_COURSE_LEADERBOARD = 'FETCH_COURSE_LEADERBOARD',

  OPEN_LESSON_SUMMARY_DIALOG = 'OPEN_LESSON_SUMMARY_DIALOG',
  CLOSE_LESSON_SUMMARY_DIALOG = 'CLOSE_LESSON_SUMMARY_DIALOG',

  START_ATTEMPT_LESSON = 'START_ATTEMPT_LESSON',
  FINISH_ATTEMPT_LESSON = 'FINISH_ATTEMPT_LESSON',
  ATTEMPT_SLIDE = 'ATTEMPT_SLIDE',

  OPEN_LESSON = 'OPEN_LESSON',
  CLOSE_LESSON = 'CLOSE_LESSON'
}

const LessonActions = {
  fetchLesson: (lessonId: string) => createAction(LessonActionTypes.FETCH_LESSON, { lessonId }),
  fetchLessonWithProgress: (lessonId: string, withLoading: boolean = true) =>
    createAction(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS, { lessonId, withLoading }),
  // We are including the boolean isPrerequisites because we want the network call to send
  // different actions when it's success
  fetchLessonsProgress: (lessonIds: string[], isPrerequisites?: boolean) =>
    createAction(LessonActionTypes.FETCH_LESSON_PROGRESS, {
      ids: lessonIds,
      isPrerequisites
    }),

  fetchLessonAttempt: (lessonId: string) =>
    createAction(LessonActionTypes.FETCH_LESSON_ATTEMPT, { lessonId }),

  fetchLessonAttemptList: (lessonIds: string[]) =>
    createAction(LessonActionTypes.FETCH_LESSON_ATTEMPT_LIST, { lessonIds }),

  fetchLessonAttemptSuccess: (lessonId: string, response: LessonAttemptType) =>
    createAction(LessonActionTypes.FETCH_LESSON_ATTEMPT_SUCCESS, { lessonId, ...response }),

  /*
   * Used to update `lessonProgress` datastore.
   * It also raises interaction based on this action.
   */
  updateLessonOpened: (lessonId: string, courseVersionNumber: number, attemptId?: string) =>
    createAction(LessonActionTypes.UPDATE_LESSON_OPENED, {
      lessonId,
      courseVersionNumber,
      attemptId
    }),

  updateLessonCompleted: (lessonId: string, score: number) =>
    createAction(LessonActionTypes.UPDATE_LESSON_COMPLETED, { lessonId, score }),
  updateLessonFailed: (lessonId: string, score: number) =>
    createAction(LessonActionTypes.UPDATE_LESSON_FAILED, { lessonId, score }),
  updateLessonsUnlock: (items: UnlockPayload[]) =>
    createAction(LessonActionTypes.UPDATE_LESSONS_UNLOCK, { items }),

  // For Old Deeplinks
  fetchLessonBreadcrumb: (lessonId: string) =>
    createAction(LessonActionTypes.FETCH_LESSON_BREADCRUMB, {
      id: lessonId
    }),
  updateLessonAttemptFailure: (lessonId: string, score: number) =>
    createAction(LessonActionTypes.UPDATE_LESSON_ATTEMPT_FAILURE, { id: lessonId, score }),
  resetLessonsProgress: (lessonIds: string[]) =>
    createAction(LessonActionTypes.RESET_LESSONS_PROGRESS, { lessonIds }),
  fetchLessonsAccess: (lessonIds: string[]) =>
    createAction(LessonActionTypes.FETCH_LESSONS_ACCESS, { lessonIds }),

  setLeaderboardSummaryDialogIsOpen: (open: boolean) =>
    createAction(LessonActionTypes.SET_LEADERBOARD_SUMMARY_DIALOG_OPEN, { open }),

  checkLessonsPrerequisites: (course: CourseType) =>
    createAction(LessonActionTypes.CHECK_LESSONS_PREREQUISITES, { course }),

  finishedThomasLesson: (lessonId: string) =>
    createAction(LessonActionTypes.FINISHED_THOMAS_LESSON, { lessonId }),

  fetchCourseLeaderboard: (courseId: string) =>
    createAction(LessonActionTypes.FETCH_COURSE_LEADERBOARD, { courseId }),

  openLessonSummaryDialog: () => createAction(LessonActionTypes.OPEN_LESSON_SUMMARY_DIALOG),
  closeLessonSummaryDialog: (lessonId: string, isAttemptSuccessful: boolean) =>
    createAction(LessonActionTypes.CLOSE_LESSON_SUMMARY_DIALOG, { lessonId, isAttemptSuccessful }),

  startAttemptLesson: (lessonId: string, attempt: Attempt) =>
    createAction(LessonActionTypes.START_ATTEMPT_LESSON, { lessonId, attempt }),
  finishAttemptLesson: (lessonId: string, attempt: Attempt) =>
    createAction(LessonActionTypes.FINISH_ATTEMPT_LESSON, { lessonId, attempt }),

  attemptSlide: (lessonId: string, attemptId: string, attemptSlide: AttemptSlideInteraction) =>
    createAction(LessonActionTypes.ATTEMPT_SLIDE, { lessonId, attemptId, attemptSlide }),

  openLesson: (
    lessonId: string,
    slideIndex: number,
    slideId?: string,
    iframeParent?: string,
    openFrom?: 'desktop' | 'mobile'
  ) =>
    createAction(LessonActionTypes.OPEN_LESSON, {
      lessonId,
      slideIndex,
      slideId,
      iframeParent,
      openFrom
    }),
  closeLesson: (lessonId: string, isAttemptSuccessful: boolean) =>
    createAction(LessonActionTypes.CLOSE_LESSON, { lessonId, isAttemptSuccessful })
};

export { LessonActions, LessonActionTypes };
