import type { PlaylistsState } from './types';

export const initialPlaylistsState: PlaylistsState = {
  fetchPlaylistsError: '',
  fetchPlaylistsLoading: false,
  playlists: [],
  totalCount: 0,
  playlistProgressesByCourseId: {},

  fetchPlaylistItemError: '',
  fetchPlaylistItemErrorCode: undefined,
  fetchPlaylistItemLoading: false,
  playlistItems: {}
};
