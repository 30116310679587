import { delay } from 'redux-saga';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import {
  AssessmentActionTypes,
  AssessmentActions
} from '@maggie/store/courseware/assessments/actions';
import {
  ConferenceActionTypes,
  ConferenceActions
} from '@maggie/store/courseware/conferences/actions';
import { CourseActionTypes, CourseActions } from '@maggie/store/courseware/courses/actions';
import {
  DiscussionActionTypes,
  DiscussionActions
} from '@maggie/store/courseware/discussions/actions';
import { LessonActionTypes, LessonActions } from '@maggie/store/courseware/lessons/actions';
import {
  ObservationActionTypes,
  ObservationActions
} from '@maggie/store/courseware/observations/actions';

import { LessonSelectors } from '../courseware/lessons/selectors';
import type { LessonType } from '../courseware/lessons/types';
import { Flags } from '../feature-flags/constants';
import { FeatureFlagsSelectors } from '../feature-flags/selectors';
import type { LxStoreState } from '../types';
import { SearchActionTypes, SearchActions } from './actions';
import type { SearchActionUnionType } from './types';

type SearchAction<ActionType extends string> = ActionFromActionType<
  SearchActionUnionType,
  ActionType
>;

function* handleFetchSearchCatalog() {
  yield put(
    RequestActions.getAuthed(
      Urls.SEARCH_CATALOG,
      SearchActionTypes.FETCH_SEARCH_CATALOG_SUCCESS,
      SearchActionTypes.FETCH_SEARCH_CATALOG_FAILURE
    )
  );
}

function* handleSetSearchTerm(action: SearchAction<SearchActionTypes.SET_SEARCH_TERM>) {
  const { searchTerm } = action.payload;
  yield delay(750);
  yield put(SearchActions.filterItems(searchTerm));
}

export function* handleSelectSearchItem(
  action: SearchAction<SearchActionTypes.SELECT_SEARCH_ITEM>
): any {
  const { item } = action.payload;
  yield put(SearchActions.addRecentSearchTerm(item.title));

  switch (item.type) {
    case 'Course': {
      window.__router.navigate('course', { id: item.id });
      return;
    }

    case 'CourseCollection': {
      window.__router.navigate('courseCollection', { id: item.id });
      return;
    }

    case 'Lesson': {
      yield put(LessonActions.fetchLessonWithProgress(item.id, false));
      yield race({
        success: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS),
        failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
      });

      const canBeReviewed: boolean = yield select<LxStoreState>(
        LessonSelectors.getCanLessonBeReviewed(item.id)
      );
      const isReviewEnabled = yield select<LxStoreState>(s =>
        FeatureFlagsSelectors.isFlagEnabled(Flags.reviewLesson, s)
      );
      if (!canBeReviewed || !isReviewEnabled) {
        window.__router.navigate('lesson', { id: item.id });
        return;
      }

      const lesson: LessonType | undefined = yield select<LxStoreState>(s =>
        LessonSelectors.getLesson(item.id, s)
      );
      if (!lesson) {
        // probably won't happen as we're awaiting above for the succes of fetching lesson
        // but in case it happens, we just redirect user to the pre-lesson screen
        window.__router.navigate('lesson', { id: item.id });
        return;
      }

      window.__router.navigate('lessonReview', { courseId: lesson.courseId, lessonId: lesson.id });
      return;
    }

    case 'Conference': {
      yield put(ConferenceActions.fetchConference(item.id, { showLoading: false }));
      yield race({
        success: take(ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS),
        failure: take(ConferenceActionTypes.FETCH_CONFERENCE_FAILURE)
      });
      window.__router.navigate('conference', { id: item.id });
      return;
    }

    case 'Discussion': {
      yield put(DiscussionActions.fetchDiscussionWithProgress(item.id));
      yield race({
        success: take(DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS_SUCCESS),
        failure: take(DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS_FAILURE)
      });
      window.__router.navigate('discussion', { id: item.id });
      return;
    }

    case 'Assignment': {
      yield put(AssessmentActions.fetchAssessmentWithProgress(item.id));
      yield race({
        success: take(AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS_SUCCESS),
        failure: take(AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS_FAILURE)
      });
      window.__router.navigate('assignment', { id: item.id });
      return;
    }

    case 'Playlist': {
      window.__router.navigate('playlist', { id: item.id });
      return;
    }

    case 'Observation': {
      yield put(ObservationActions.fetchObservation(item.id));
      yield race({
        success: take(ObservationActionTypes.FETCH_OBSERVATION_SUCCESS),
        failure: take(ObservationActionTypes.FETCH_OBSERVATION_FAILURE)
      });
      window.__router.navigate('observation', { id: item.id });
      return;
    }

    case 'BriefcaseDocument': {
      const courseId = item.metadata?.['courseId'] as string;
      if (!courseId) {
        ErrorLogger.captureEvent(
          'Searched for a briefcase document without course id in metadata',
          'warning',
          {}
        );
        return; // throw?
      }

      yield put(CourseActions.fetchSyncCourse(courseId));
      yield race({
        success: take(CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS),
        failure: take(CourseActionTypes.FETCH_SYNC_COURSE_FAILURE)
      });
      window.__router.navigate('briefcaseDocument', { id: courseId, documentId: item.id });
      return;
    }

    default: {
      console.error('unknown type of search entity' + item.type);
      return;
    }
  }
}

function* watchFetchSearchCatalog() {
  yield takeLatest(SearchActionTypes.FETCH_SEARCH_CATALOG, handleFetchSearchCatalog);
}

function* watchSetSearchTerm() {
  yield takeLatest(SearchActionTypes.SET_SEARCH_TERM, handleSetSearchTerm);
}

function* watchSelectSearchItem() {
  yield takeLatest(SearchActionTypes.SELECT_SEARCH_ITEM, handleSelectSearchItem);
}

export const searchSagas = [
  fork(watchFetchSearchCatalog),
  fork(watchSetSearchTerm),
  fork(watchSelectSearchItem)
];
