import { put, race, select, take } from 'redux-saga/effects';

import {
  AssessmentActionTypes,
  AssessmentActions
} from '@maggie/store/courseware/assessments/actions';
import type { AssessmentAction } from '@maggie/store/courseware/assessments/sagas';
import type { AssessmentPostType } from '@maggie/store/courseware/assessments/types';
import { LessonActivityActions } from '@maggie/store/courseware/lesson-activity/actions';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToAssessment(assessmentId: string): any {
  yield put(AssessmentActions.fetchAssessmentWithProgress(assessmentId));

  const posts: AssessmentPostType[] = yield select<LxStoreState>(
    s => s.courseware.assessments.posts[assessmentId]?.items || []
  );
  if (posts.length === 0) {
    yield put(AssessmentActions.fetchPosts(assessmentId));
  }

  type RaceType = {
    success: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS_SUCCESS>;
  };
  const { success }: RaceType = yield race({
    success: take(AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS_SUCCESS),
    failure: take(AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS_FAILURE)
  });

  if (!!success) {
    const { id, courseVersionNumber } = success.payload.assessment;
    yield put(LessonActions.updateLessonOpened(id, courseVersionNumber));
    yield put(LessonActivityActions.didOpenLessonActivity(assessmentId, 'Assessment'));
  }
}
