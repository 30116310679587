import { UserActionTypes } from '@maggie/store/user/actions';
import type { UserActionsUnionType } from '@maggie/store/user/types';

import type { ProfileActionsUnionType } from './actions';
import { ProfileActionTypes } from './actions';
import type { ProfileState } from './types';

export const initialProfileState: ProfileState = {
  downloadOverData: false,
  accounts: []
};

export const profileReducer = (
  state = initialProfileState,
  action: ProfileActionsUnionType | UserActionsUnionType
): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.UPDATE_DOWNLOADOVERDATA: {
      const { value } = action.payload;
      return { ...state, downloadOverData: value };
    }

    case UserActionTypes.FETCH_SYNC_USER_SUCCESS: {
      const { accounts } = action.payload;
      return { ...state, accounts };
    }

    default:
      return state;
  }
};
