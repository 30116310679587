import { BrainBoostActionTypes } from './actions';
import type { BrainBoostState, BrainBoostActionsUnionType } from './types';

export const initialBrainBoostState: BrainBoostState = {
  quiz: null,
  isFetching: false,
  sessionResults: {
    score: 0,
    completedSlideCount: 0,
    slidesAvailable: 0
  },
  availableFrom: '',
  slideCount: 0,
  courses: null,
  completedSlides: [],
  isBrainBoostSummaryOpen: false,
  isLoadingBrainBoostScore: false,
  toast: { streak: 0, show: false, maxStreak: 0 }
};

export const brainBoostReducer = (
  state: BrainBoostState = initialBrainBoostState,
  action: BrainBoostActionsUnionType
) => {
  switch (action.type) {
    case BrainBoostActionTypes.FETCH_BRAIN_BOOST_SESSION:
      return {
        ...state,
        isFetching: true
      };

    case BrainBoostActionTypes.FETCH_BRAIN_BOOST_SESSION_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case BrainBoostActionTypes.FETCH_BRAIN_BOOST_SLIDES:
      return {
        ...state,
        isFetching: true
      };
    case BrainBoostActionTypes.FETCH_BRAIN_BOOST_SLIDES_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case BrainBoostActionTypes.FETCH_BRAIN_BOOST_SESSION_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        slideCount: payload.slideCount,
        courses: payload.courses,
        availableFrom: payload.availableFrom,
        isFetching: false
      };
    }

    case BrainBoostActionTypes.FETCH_BRAIN_BOOST_SLIDES_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        quiz: { ...payload },
        isFetching: false
      };
    }
    case BrainBoostActionTypes.BRAIN_BOOST_SLIDE_COMPLETE:
      return {
        ...state,
        completedSlides: [...(state.completedSlides ?? []), action.payload.slide]
      };
    case BrainBoostActionTypes.CLEAR_COMPLETED_SLIDES:
      return {
        ...state,
        completedSlides: []
      };

    case BrainBoostActionTypes.LOADING_BRAIN_BOOST_SCORE:
      return {
        ...state,
        isBrainBoostSummaryOpen: true,
        isLoadingBrainBoostScore: true,
        toast: { ...state.toast, show: false }
      };

    case BrainBoostActionTypes.SCORE_BRAIN_BOOST:
      return {
        ...state,
        sessionResults: {
          score: action.payload.score,
          completedSlideCount: action.payload.totalSlides,
          slidesAvailable: state.slideCount ?? 0
        },
        isLoadingBrainBoostScore: false
      };

    case BrainBoostActionTypes.CLOSE_BRAINBOOST_SUMMARY:
      return {
        ...state,
        isBrainBoostSummaryOpen: false
      };

    case BrainBoostActionTypes.SHOW_BRAINBOOST_TOAST: {
      const { isCorrect } = action.payload;
      const streak = isCorrect ? state.toast.streak + 1 : 0;
      const maxStreak = state.toast.maxStreak > streak ? state.toast.maxStreak : streak;
      return { ...state, toast: { ...state.toast, show: true, streak, maxStreak } };
    }

    case BrainBoostActionTypes.HIDE_BRAINBOOST_TOAST: {
      return { ...state, toast: { ...state.toast, show: false } };
    }
    case BrainBoostActionTypes.CLEAR_BRAINBOOST_STREAKS:
      return {
        ...state,
        toast: { ...state.toast, show: false, streak: 0, maxStreak: 0 }
      };

    default:
      return state;
  }
};
