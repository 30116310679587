import type { Reducer } from 'redux';

import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import type { ResetPasswordActionUnionType } from './actions';
import { ResetPasswordActionTypes } from './actions';
import { initialResetPasswordState } from './constants';
import type { ResetPasswordState } from './types';

export const resetPasswordReducer: Reducer<ResetPasswordState> = (
  state = initialResetPasswordState,
  action: ResetPasswordActionUnionType
) => {
  switch (action.type) {
    case ResetPasswordActionTypes.SET_PARAMS: {
      const { email, token } = action.payload;
      return { ...state, email, token };
    }

    case ResetPasswordActionTypes.RESET_LOST_PASSWORD: {
      const { email, token } = action.payload;
      return { ...state, email, token, loading: true, error: '' };
    }
    case ResetPasswordActionTypes.RESET_LOST_PASSWORD_SUCCESS: {
      return { ...state, loading: true, error: '' };
    }
    case ResetPasswordActionTypes.RESET_LOST_PASSWORD_FAILURE: {
      const { error } = action.payload;
      let errorMessage: string;
      if (error?.response === 'No user found') {
        errorMessage = getErrorMessage(ErrorCode.ForgotPasswordErrorCredentials);
      } else {
        errorMessage = error.message;
      }
      return { ...state, loading: false, error: errorMessage };
    }
    case ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_SUCCESS: {
      return { ...state, loading: false, error: '' };
    }
    case ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_FAILURE: {
      const error = action.payload?.message ?? '';
      return { ...state, loading: false, error };
    }

    default:
      return state;
  }
};
