import { put, select } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { SessionActions } from '@maggie/store/session/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { Redirection } from '../redirection';

type HandleAuthenticateWithSSOType = {
  token?: string;
  magicLink?: boolean;
  companyId?: string;
};
export function* handleAuthenticateWithSSO({
  token,
  magicLink,
  companyId
}: HandleAuthenticateWithSSOType = {}): any {
  const isLoggedIn: boolean = yield select<LxStoreState>(s => !!UserSelectors.getToken(s));

  const hasRedirect = Redirection.getRedirect();
  if (isLoggedIn && !hasRedirect && !token && !magicLink && !companyId) {
    window.__router.navigate('home', {}, true);
    return; // already logged in
  }

  if (companyId) {
    yield put(SessionActions.ssoCheck(companyId));
    return; // check for companyId
  }

  if (magicLink) {
    itly.magicLinkClicked();
  }

  if (!!token) {
    yield put(SessionActions.loginWithSSO(token));
    return; // auto sso using the token from the url
  }
}
