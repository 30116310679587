import { RequestActions } from '@edapp/request';
import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';

import { DEFAULT_PAGE_SIZE } from './constants';
import type {
  AwardedCertificatesRequest,
  AwardedCertificatesResponse,
  UserCourseCertificateDownloadLinkResponse
} from './types';

export enum AwardedCertificatesActionTypes {
  FETCH_ALL_AWARDED_CERTIFICATES = 'FETCH_ALL_AWARDED_CERTIFICATES',
  FETCH_ALL_AWARDED_CERTIFICATES_SUCCESS = 'FETCH_ALL_AWARDED_CERTIFICATES_SUCCESS',
  FETCH_ALL_AWARDED_CERTIFICATES_FAILURE = 'FETCH_ALL_AWARDED_CERTIFICATES_FAILURE',
  FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL = 'FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL',
  FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_SUCCESS = 'FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_SUCCESS',
  FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_FAILURE = 'FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_FAILURE'
}

export const AwardedCertificatesActions = {
  fetchAllAwardedCertificates: (requestData: AwardedCertificatesRequest) =>
    createAction(AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES, {
      requestData
    }),
  fetchAllAwardedCertificatesSuccess: (page: number, responseData: AwardedCertificatesResponse) =>
    createAction(AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES_SUCCESS, {
      page,
      responseData
    }),
  fetchAwardedCertificateDownloadUrl: (
    certificateId: string,
    courseId: string,
    courseTitle: string
  ) =>
    createAction(AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL, {
      certificateId,
      courseId,
      courseTitle
    }),
  fetchAwardedCertificateDownloadUrlSuccess: (
    courseId: string,
    courseTitle: string,
    responseData: UserCourseCertificateDownloadLinkResponse
  ) =>
    createAction(AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_SUCCESS, {
      courseId,
      courseTitle,
      responseData
    })
};

export type AwardedCertificatesActionsUnionType =
  | ActionsUnion<typeof AwardedCertificatesActions>
  | ActionWithPayload<
      AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES_FAILURE,
      EdErrorResponseType
    >
  | ActionWithPayload<
      AwardedCertificatesActionTypes.FETCH_AWARDED_CERTIFICATE_DOWNLOAD_URL_FAILURE,
      EdErrorResponseType
    >;

export const AwardedCertificatesRequestActions = {
  getLearnersAwardedCertificates: (page: number) =>
    RequestActions.getAuthed<AwardedCertificatesResponse>(
      Urls.LEARNERS_AWARDED_CERTIFICATES,
      data => AwardedCertificatesActions.fetchAllAwardedCertificatesSuccess(page, data),
      AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES_FAILURE,
      undefined,
      {
        page,
        pageSize: DEFAULT_PAGE_SIZE
      }
    ),

  getLearnersAwardedCertificateDownloadUrl: (
    certificateId: string,
    courseId: string,
    courseTitle: string
  ) =>
    RequestActions.getAuthed<UserCourseCertificateDownloadLinkResponse>(
      Urls.LEARNERS_AWARDED_CERTIFICATE_DOWNLOAD_URL(certificateId),
      data =>
        AwardedCertificatesActions.fetchAwardedCertificateDownloadUrlSuccess(
          courseId,
          courseTitle,
          data
        ),
      AwardedCertificatesActionTypes.FETCH_ALL_AWARDED_CERTIFICATES_FAILURE,
      undefined,
      {}
    )
};

export type AwardedCertificatesRequestActionsUnionType = ActionsUnion<
  typeof AwardedCertificatesRequestActions
>;
