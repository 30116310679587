import type { CoursesState } from './types';

export enum MandatoryTypes {
  NotSpecified = 'not-specified',
  DueBy = 'due-by',
  NoDueDate = 'no-due-date'
}

export const initialCoursesState: CoursesState = {
  courses: {},
  coursesProgress: {},
  last_update: new Date(),
  courseSyncErrorCode: undefined,
  courseSyncLoading: false
};
