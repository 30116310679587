import * as Cookies from 'js-cookie';

import { Platform } from '@maggie/cordova/platform';

const setCookies = (id: string) => {
  if (Platform.isWeb()) {
    Cookies.set('user', id);
  }
};

export const UserUtils = {
  setCookies
};
