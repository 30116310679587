import { GroupTrainingActionTypes } from './actions';
import type { GroupTrainingActionsUnionType, GroupTrainingState } from './types';

export const initialGroupTrainingState: GroupTrainingState = {
  isLoading: false,
  registered: false
};

export const groupTrainingReducer = (
  state: GroupTrainingState = initialGroupTrainingState,
  action: GroupTrainingActionsUnionType
) => {
  switch (action.type) {
    case GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        registered: false
      };

    case GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        registered: true
      };

    case GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
