import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import { CoursewareUtils } from '@maggie/store/courseware/utils';
import type { LxStoreState } from '@maggie/store/types';

export const getSocialLearningForLesson = (state: LxStoreState, lessonId: string) => {
  return state.social_learning.lessons[lessonId];
};

export const isSocialLearningEnabled = (state: LxStoreState) => {
  return state.user && state.user.ed && state.user.ed.socialLearning;
};

/**
 * The default summary object for the LessonView
 */
const DefaultSocialLearningLessonSummary = {
  display: false,
  activeCommentsCount: 0,
  hasUnreadComments: false
};

/**
 * Gets the social learning summary for the lesson view.
 *
 * @param {StoreState} state
 * @param {string} [lessonId]
 * @returns {typeof DefaultSocialLearningLessonSummary}
 */
export const getSocialLearningForLessonView = (
  state: LxStoreState,
  lessonId?: string
): typeof DefaultSocialLearningLessonSummary => {
  const { config } = state;
  if (!lessonId) {
    return DefaultSocialLearningLessonSummary;
  }

  const summary = getSocialLearningForLesson(state, lessonId);
  if (!summary) {
    return DefaultSocialLearningLessonSummary;
  }
  return {
    display: config.isOnline && isSocialLearningEnabled(state) && summary.activeCommentsCount > 0,
    ...summary
  };
};

const getAllCommentsForALesson = (lessonId: string) => (s: LxStoreState) => {
  return s.social_learning.commentsByLesson[lessonId] || [];
};

const isLoadingSlideComments = (slideId: string) => (state: LxStoreState) => {
  return state.social_learning.isLoadingSlideComments[slideId];
};

const getTitle = (state: LxStoreState) => {
  const lessonId = state.navigation.lessonId;
  const lesson = LessonSelectors.getLesson(lessonId, state);
  return lesson?.title || '';
};

const getTextColor = (state: LxStoreState) => {
  const lessonId = state.navigation.lessonId;
  const lesson = LessonSelectors.getLesson(lessonId, state);
  return CoursewareUtils.getLessonStyleObject(lesson).text;
};

const getBackground = (state: LxStoreState) => {
  const lessonId = state.navigation.lessonId;
  const lesson = LessonSelectors.getLesson(lessonId, state);
  return lesson?.configuration.styleConfiguration.background;
};

const getBackgroundColor = (state: LxStoreState) => {
  const lessonId = state.navigation.lessonId;
  const lesson = LessonSelectors.getLesson(lessonId, state);
  return lesson?.configuration.styleConfiguration.colors?.background;
};

export const SocialLearningSelectors = {
  getSocialLearningForLesson,
  isSocialLearningEnabled,
  getSocialLearningForLessonView,
  getAllCommentsForALesson,
  isLoadingSlideComments,
  getTitle,
  getTextColor,
  getBackground,
  getBackgroundColor
};
