import { ObservationStatus } from '@edapp/observations-ui';

import type {
  ObservationFilters,
  ObservationType,
  ObservationsState,
  SubmissionType
} from './types';

export const DEFAULT_OBSERVATION_FILTERS: ObservationFilters = {
  passed: true,
  tryAgain: true
};

export const initialSubmissionObject: SubmissionType = {
  id: '',
  observationId: '',
  dateCreated: '',
  dateCompleted: undefined,
  createdByName: '',
  completedByName: undefined,
  status: ObservationStatus.Unattempted,
  feedback: undefined,
  resultsUrl: undefined
};

export const initialObservationObject: ObservationType = {
  id: '',
  courseId: '',
  courseVersionNumber: 1,
  name: '',
  description: '',
  courseTitle: '',
  submissions: [],
  prerequisitesWithTitle: [],
  planning: {}
};

export const initialObservationsState: ObservationsState = {
  selectedObservation: initialObservationObject,
  completedObservationsList: { totalCount: 0, items: [] },
  fetchingObservationLoading: false,
  fetchingObservationError: '',
  fetchingAllObservationSubmissionsLoading: false,
  fetchingAllObservationSubmissionsError: ''
};
