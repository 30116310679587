import type { AssessmentsState } from './types';

const initialAssessmentState: AssessmentsState = {
  fetchingAssessmentError: '',
  fetchingAssessmentLoading: false,
  fetchingAssessmentErrorCode: undefined,
  assessments: {},

  fetchingPostItemError: '',
  fetchingPostItemLoading: false,
  fetchingPostsError: '',
  fetchingPostsLoading: false,
  savingPostError: '',
  savingPostLoading: false,
  posts: {},

  fetchingPostCommentsError: '',
  fetchingPostCommentsLoading: false,
  savingPostCommentLoading: false,
  savingPostCommentError: '',
  postComments: {},

  fetchingProgressError: '',
  fetchingProgressLoading: true,
  progress: {},

  fetchingFeedbackLoading: false,
  fetchingFeedbackError: '',
  feedback: {},

  editPostSubscriptionLoading: false,
  editPostSubscriptionError: ''
};

export { initialAssessmentState };
