import { put, select } from 'redux-saga/effects';

import { DocumentSelectors } from '@maggie/store/courseware/documents/selectors';
import type { DocumentWithProgressAndStatusType } from '@maggie/store/courseware/documents/types';
import { LockedDialogActions } from '@maggie/store/locked-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToBriefcaseDocument(documentId: string) {
  const document: DocumentWithProgressAndStatusType | undefined = yield select<LxStoreState>(s =>
    DocumentSelectors.getCourseDocumentWithProgress(documentId, s)
  );

  if (!!document && !!document.progress && !document.progress.unlocked) {
    yield put(LockedDialogActions.openLockedBriefcase(documentId));
  }
}
