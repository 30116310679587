import * as _ from 'lodash';

type UserDataType = {
  id: string;
  name: string;
  lesson_id?: string;
  nb_stars_earned?: number;
  correct?: boolean;
  tooSlow?: boolean;
  game?: unknown;
  configuration?: {
    nb_stars?: number;
  };
};

const InteractionModel = {
  getStarConfigurationFromInteractionData: (ud: UserDataType) => {
    if (!ud) {
      return {};
    }

    const total =
      _.get(ud, 'configuration.nb_stars') === 0
        ? 0
        : _.get(ud, 'configuration.nb_stars') || _.get(ud, 'configuration.weighting') || 0;
    const earned = ud.nb_stars_earned ? ud.nb_stars_earned : ud.correct ? total : 0;
    const lessonId = ud.lesson_id;
    const slideId = ud.id;
    const slideName = ud.name;

    return { total, earned, lessonId, slideId, slideName };
  },
  shouldBeRewarded: (userData: UserDataType) => {
    // Slide interactive challenge
    if (userData.correct) {
      return (
        InteractionModel.hasReward(userData) &&
        userData.correct &&
        !userData.tooSlow &&
        userData.lesson_id
      );

      // Game
    } else if (userData.game) {
      return (
        typeof userData.nb_stars_earned === 'number' &&
        userData.nb_stars_earned >= 1 &&
        userData.lesson_id
      );

      // Anything else should not be rewarded
    } else {
      return false;
    }
  },
  hasReward: (userData: UserDataType) => {
    const weighting = _.get(userData, 'configuration.weighting');
    const nbStars = _.get(userData, 'configuration.nb_stars');
    return (
      (typeof nbStars === 'number' && nbStars > 0) ||
      (typeof weighting === 'number' && weighting > 0 && nbStars !== 0)
    );
  }
};

export { InteractionModel };
