import { put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import { RegisterActionsTypes } from './actions';
import type { RegisterAction } from './types';

function* handleCheckInviteCode(
  action: RegisterAction<RegisterActionsTypes.CHECK_INVITE_CODE>
): any {
  const { inviteCode } = action.payload;
  const decodedInviteCode = decodeURIComponent(inviteCode);

  yield put(
    RequestActions.postUnauthed(
      Urls.INVITE_CHECK,
      RegisterActionsTypes.CHECK_INVITE_CODE_SUCCESS,
      RegisterActionsTypes.CHECK_INVITE_CODE_FAILURE,
      undefined,
      { code: decodedInviteCode, locale: window.__l10n.language }
    )
  );
}

export function* watchCheckInviteCode() {
  yield takeLatest(RegisterActionsTypes.CHECK_INVITE_CODE, handleCheckInviteCode);
}
