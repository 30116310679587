import { queryParamAccessor } from '@edapp/utils';

import type { FileName } from './filenames';
import { initI18N } from './i18n';

const CONTENT_LANG_QUERY_PARAM = 'content_lang';
const CONTENT_LANG_LOCAL_STORAGE = 'ed:content_lang';

// simple validation check - nothing too sophisticated to validate the actual locale code
const validateLangQueryParam = (value: string) => value.length < 10;

export const initMFELocalization = async (lang: string, ns: FileName[]) => {
  // Initializes the window variable to be used when consuming content
  // It will be sent in the headers of all requests to hippo as `locale` - see `maggie/request/sagas`.
  const contentLang = queryParamAccessor(
    CONTENT_LANG_QUERY_PARAM,
    CONTENT_LANG_LOCAL_STORAGE,
    validateLangQueryParam
  );
  window.__contentLang = contentLang;

  // Initializes the resource strings in the correct language
  const i18n = await initI18N({
    lng: lang,
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupQuerystring: 'lang'
    },
    supportedLngs: false,
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    ns
  });
  window.__l10n = i18n;
};
