import { itly } from '@edapp/analytics-tracking';

import { PeerAuthoringLaunchOptions } from '../../core/lessons/peer_authoring/peer_authoring_launch';
import { NotificationTopics } from './constants';
import type { HandleNotification } from './types';

export const handleNotification: HandleNotification = ({ title = '', message, additionalData }) => {
  // If the app is currently open ignore notification
  if (additionalData.foreground) {
    return;
  }

  switch (additionalData.topic) {
    case NotificationTopics.SocialLearning: {
      itly.notificationOpened({
        topic: additionalData.topic,
        body: message,
        title,
        url: 'socialLearningSlide'
      });

      return window.__router.navigate('socialLearningSlide', {
        lessonId: additionalData.lesson_id,
        slideId: additionalData.slide_id
      });
    }

    case NotificationTopics.VideoCreated: {
      itly.notificationOpened({
        topic: additionalData.topic,
        body: message,
        title,
        url: 'peerAuthoringLesson'
      });

      return window.__router.navigate('peerAuthoringLesson', {
        id: additionalData.lesson_id,
        slideId: additionalData.slide_id
      });
    }

    case NotificationTopics.VideoCommentCreated: {
      itly.notificationOpened({
        topic: additionalData.topic,
        body: message,
        title,
        url: 'peerAuthoringLesson'
      });

      PeerAuthoringLaunchOptions.setLaunchComment({
        lessonId: additionalData.lesson_id,
        slideId: additionalData.slide_id,
        videoId: additionalData.video_id
      });

      return window.__router.navigate('peerAuthoringLesson', {
        id: additionalData.lesson_id,
        slideId: additionalData.slide_id
      });
    }

    case NotificationTopics.DiscussionPostCommentCreated: {
      itly.notificationOpened({
        topic: additionalData.topic,
        body: message,
        title,
        url: 'thread'
      });

      return window.__router.navigate('thread', {
        discussionId: additionalData.discussion_id,
        postId: additionalData.post_id
      });
    }

    case NotificationTopics.AssessmentPostCommentCreated: {
      itly.notificationOpened({
        topic: additionalData.topic,
        body: message,
        title,
        url: 'submission'
      });

      return window.__router.navigate('submission', {
        assessmentId: additionalData.assessment_id,
        postId: additionalData.post_id
      });
    }

    case NotificationTopics.RapidRefreshSessionStarted: {
      itly.notificationOpened({
        topic: additionalData.topic,
        body: message,
        title,
        url: 'rapidRefresh'
      });

      return window.__router.navigate('rapidRefresh', {
        id: additionalData.course_id,
        session: additionalData.session
      });
    }

    case NotificationTopics.BrainBoostSessionIsAvailable: {
      itly.notificationOpened({
        topic: additionalData.topic,
        type: additionalData.type,
        body: message,
        title,
        url: 'brainBoost'
      });

      return window.__router.navigate('brainBoost', {});
    }

    case NotificationTopics.Course: {
      itly.notificationOpened({
        topic: 'CourseReminder',
        body: message,
        title,
        url: 'course'
      });

      return window.__router.navigate('course', { id: additionalData.course_id });
    }
  }
};
