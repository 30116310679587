import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { featureFlagsRoute } from '@edapp/feature-flags';
import { RequestActions } from '@edapp/request';
import { SessionActionTypes } from '@maggie/store/session/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { FeatureFlagsActionTypes } from './actions';

function* handleFetchFeatureFlags() {
  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );

  const action = !!token ? RequestActions.getAuthed : RequestActions.getUnauthed;

  yield put(
    action(
      featureFlagsRoute,
      FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_SUCCESS,
      FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_FAILURE,
      undefined,
      {},
      undefined,
      true,
      false
    )
  );
}

export function* watchFetchFeatureFlags() {
  yield takeLatest(FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS, handleFetchFeatureFlags);
}

export function* watchLoginSuccess() {
  yield takeLatest(SessionActionTypes.SESSION_LOGIN_SUCCESS, handleFetchFeatureFlags);
}

export const featureFlagsSagas = [fork(watchFetchFeatureFlags), fork(watchLoginSuccess)];
