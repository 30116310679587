import extend from 'lodash-es/extend';

import { ErrorLogger } from '@edapp/monitoring';
import { appVersion } from '@maggie/config/appVersion';
import type { ThomasSlideInteraction } from '@maggie/core/lessons/thomas/thomas-interaction-types';
import { SlideProgressActions } from '@maggie/store/slide-progress/actions';
import { bitState } from '@maggie/utils/bit_state';
import { createUUID } from '@maggie/utils/uuid';
import * as Backbone from 'backbone';

import type {
  AttemptInteraction,
  Interaction,
  QuizPlayInteraction,
  ReviewLessonInteraction,
  ReviewSlideInteraction,
  SlideInteraction,
  SlideState,
  SlideStateInteraction,
  ViewInteraction,
  VisitInteraction
} from './hippo-interaction-types';
import { InteractionModel } from './interaction';
import { InteractionApi } from './interaction-api';

const trackInteraction = (data: Interaction) => {
  const timestamp = !data.timestamp ? new Date().getTime() : data.timestamp;
  const interactionData: Interaction = {
    ...data,
    timestamp,
    ClientId: !data.ClientId ? createUUID() : data.ClientId
  };

  setTimeout(async () => {
    try {
      await InteractionApi.enqueueInteraction(interactionData);
    } catch (err) {
      ErrorLogger.captureEvent('trackInteraction - Failed to enqueue interaction', 'error', {
        err,
        interactionData
      });
    }
  }, 1);

  return interactionData.ClientId;
};

const trackReviewSlideInteraction = (data: ReviewSlideInteraction) => {
  return trackInteraction(data);
};

const trackReviewLessonInteraction = (data: ReviewLessonInteraction) => {
  return trackInteraction(data);
};

const trackSlideInteraction = (data: ThomasSlideInteraction & SlideInteraction) => {
  if (data.isLessonUserState) {
    trackSlideStates(data);
    return;
  }

  const { hasReward, getStarConfigurationFromInteractionData, shouldBeRewarded } = InteractionModel;
  if (hasReward(data)) {
    // Add earned stars, total stars to interaction data
    data.starsRewarded = getStarConfigurationFromInteractionData(data);
    if (shouldBeRewarded(data)) {
      Backbone.Events.trigger('star', data);
    }
  }

  return trackInteraction(data);
};

const trackSlideStates = (data: ThomasSlideInteraction) => {
  const result: SlideState[] = [];
  const object = data.state || {};
  const { lesson_id, course_id } = data;

  Object.keys(object).forEach(slideId => {
    const state = object[slideId];
    window.__store.dispatch(SlideProgressActions.updateSlideState(lesson_id, slideId, state));
    result.push({ slideId, state, lessonId: lesson_id, courseId: course_id });
  });

  const slideStateInteraction: SlideStateInteraction = {
    newStates: result,
    type: 'slide-state'
  };
  return trackInteraction(slideStateInteraction);
};

const trackVisitInteraction = (
  data?: VisitInteraction,
  user?: { id: string; name: string; email: string },
  deviceToken?: string,
  locale?: string
) => {
  if (data == null) {
    data = { type: 'visit' };
  }

  if (!!user?.id && !!user?.name) {
    let timezone: string | null;

    try {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      timezone = null;
    }

    trackInteraction(
      extend(data, {
        type: 'visit',
        userId: user.id,
        userAgent: window.navigator.userAgent,
        userTimezoneOffset: new Date().getTimezoneOffset(),
        appVersion,
        deviceToken,
        timezone,
        locale: locale || 'en'
      })
    );
  }
};

const trackAttemptInteraction = (data?: AttemptInteraction) => {
  if (data == null) {
    data = { type: 'attempt' };
  }
  const inter = extend(data, { type: 'attempt' });
  return trackInteraction(inter);
};

const trackQuizPlayInteraction = (data?: QuizPlayInteraction) => {
  if (data == null) {
    data = { type: 'quiz-play' };
  }
  const inter = extend(data, { type: 'quiz-play' });
  return trackInteraction(inter);
};

let idTrackedView = 0;
const trackedViews: Record<string, { id: string, name: string, end: () => void }> = {};
const tracking = bitState({
  init: true,
  set_true: 'start',
  set_false: 'stop'
});

const trackViewInteraction = (data: ViewInteraction) => {
  if (!tracking.is(true) || !data.name) {
    // not ready?
    return;
  }

  const id = ++idTrackedView;
  const start = Date.now();

  const trackedView = {
    id: id.toString(),
    name: data.name,
    end() {
      const duration = Date.now() - start;

      if (duration < 0) {
        ErrorLogger.captureEvent('Duration lower than zero', 'error', {
          ...data,
          type: 'view interaction',
          duration,
          start,
          now: Date.now()
        });
      }

      trackInteraction(extend(data, { type: 'view' }, { duration, timestamp: start }));
      return removeFromTrackedViews(this);
    }
  };
  trackedViews[trackedView.id] = trackedView;
  return trackedView;
};

const trackViewWithDuration = (data: ViewInteraction) => {
  trackInteraction(extend(data, { type: 'view' }));
};

const removeFromTrackedViews = (trackedView: ReturnType<typeof trackViewInteraction> | null) => {
  if (!trackedView) {
    // already removed?
    return;
  }

  delete trackedViews[trackedView.id.toString()];
  return (trackedView = null);
};

const pause = () => {
  tracking.stop();

  Object.keys(trackedViews).forEach(id => {
    const view = trackedViews[id];
    if (!!view && !!view.end && typeof view.end === 'function') {
      view.end();
    }
  });
};

const resume = () => {
  tracking.start();
};

export const TrackerInteractions = {
  trackInteraction,
  trackAttemptInteraction,
  trackQuizPlayInteraction,
  trackSlideInteraction,
  trackViewInteraction,
  trackViewWithDuration,
  trackVisitInteraction,
  trackReviewSlideInteraction,
  trackReviewLessonInteraction,
  pause,
  resume
};
