import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import type { StarState } from './types';

const STAR_BALANCE_UPDATE_LEEWAY = 15000;

function getDisplayedStarBalance(state: StarState): number {
  const { clientBalance, clientTimestamp, syncedBalance, syncedTimestamp } = state;

  if (clientTimestamp == null) {
    return syncedBalance;
  }

  // Since star balance updates are asynchronous and take some time
  // for the server to process, add a 15 second leeway. This helps
  // only for scenarios where we fetch the star balance right before
  // or after updating it on the client (e.g. daily reward)
  if (syncedTimestamp == null || clientTimestamp > syncedTimestamp - STAR_BALANCE_UPDATE_LEEWAY) {
    return clientBalance;
  }

  return syncedBalance;
}

/**
 * Is stars enabled based on the user and config.
 * @param state The store state
 */
const isStarsEnabled = (state: LxStoreState) =>
  !state.config.restrictToCourseScreen &&
  !state.config.restrictToLessonScreen &&
  UserSelectors.hasStarsEnabled(state.user);

const getNumberOfDailyRewardStars = (state: LxStoreState) =>
  state.star.numberOfStarsFromDailyLoginReward;

export const StarSelectors = {
  getDisplayedStarBalance,
  isStarsEnabled,
  getNumberOfDailyRewardStars
};
