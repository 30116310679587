import { put, race, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import type { SessionAction } from './actions';
import { SessionActionTypes, SessionActions } from './actions';

function* handleSSOCheck(action: SessionAction<SessionActionTypes.SSO_CHECK>) {
  const { businessId } = action.payload;

  yield put(
    RequestActions.postUnauthed(
      Urls.SSO_CHECK,
      SessionActionTypes.SSO_CHECK_SUCCESS,
      SessionActionTypes.SSO_CHECK_FAILURE,
      undefined,
      { code: businessId }
    )
  );

  type RaceType = { success: SessionAction<SessionActionTypes.SSO_CHECK_SUCCESS> };
  const { success }: RaceType = yield race({
    success: take(SessionActionTypes.SSO_CHECK_SUCCESS),
    failure: take(SessionActionTypes.SSO_CHECK_FAILURE)
  });

  if (!!success) {
    yield put(SessionActions.redirectToSSO(businessId, success.payload.openSsoExternally));
  } else {
    window.__router.navigate('ssoLogin', {});
  }
}

export function* watchCheckSSO() {
  yield takeLatest(SessionActionTypes.SSO_CHECK, handleSSOCheck);
}
