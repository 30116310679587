import { put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ContentLibraryActionTypes, ContentLibraryActions } from '@edapp/content-library';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { RegisterActions, RegisterActionsTypes } from './actions';
import type { RegisterAction } from './types';

function* handleSelfEnroll(action: RegisterAction<RegisterActionsTypes.SELF_ENROLL>) {
  const { courseId } = action.payload;

  const isIndividualLearner: boolean = yield select<LxStoreState>(s =>
    UserSelectors.isIndividualLearner(s.user)
  );

  if (isIndividualLearner) {
    // self-enroll course to individual learner!?
    yield put(ContentLibraryActions.addCoursesToLibrary([courseId]));
    yield race({
      success: take(ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_SUCCESS),
      failure: take(ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_FAILURE)
    });
  } else {
    // TODO
    // self-enroll course to org learner?!
  }

  yield put(RegisterActions.selfEnrollCompleted());
}

export function* watchSelfEnroll() {
  yield takeLatest(RegisterActionsTypes.SELF_ENROLL, handleSelfEnroll);
}
