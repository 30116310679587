import { t } from 'i18next';

import type { DictionaryType } from '@edapp/utils';
import { ENV } from '@maggie/config/env';
import { checkDeviceOnline } from '@maggie/cordova/network_utils';
import { OfflineAssets } from '@maggie/core/offlineAssets';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';
import { prettyList } from '@maggie/utils/written';

import type { CourseSummaryType } from '../collections/types';
import type { DocumentType } from '../documents/types';
import {
  makeGetMicroCredential,
  makeGetMicroCredentialIdFromCourseId
} from '../micro-credentials/selectors';
import { PlaylistProgressSelectors } from '../playlists/playlist-progress-selectors';
import type { CompletionCriteria, PlanningType, UnlockPayload } from '../types';
import { CoursewareUtils, DEFAULT_BRANDING_TEXT_COLOR } from '../utils';
import type {
  CourseAvailabilityResult,
  CourseProgressType,
  CourseStatusType,
  CourseType,
  LessonSummaryType
} from './types';

const getCourse = (id: string, state: LxStoreState): CourseType | undefined => {
  return checkDeviceOnline()
    ? state.courseware.courses.courses[id]
    : OfflineAssets.normalizeUrls(state.offline.courseware.courses.courses[id]);
};

const getCourses = (state: LxStoreState): DictionaryType<CourseType> => {
  return checkDeviceOnline()
    ? state.courseware.courses.courses
    : OfflineAssets.normalizeUrlsInDictionary(state.offline.courseware.courses.courses);
};

const getCourseProgress = (id: string, state: LxStoreState): CourseProgressType | undefined => {
  return checkDeviceOnline()
    ? state.courseware.courses.coursesProgress[id]
    : state.offline.courseware.courses.coursesProgress[id];
};

const getCoursesProgress = (state: LxStoreState): DictionaryType<CourseProgressType> => {
  return checkDeviceOnline()
    ? state.courseware.courses.coursesProgress
    : state.offline.courseware.courses.coursesProgress;
};

const getCoursePercentageCompleted = (id: string, state: LxStoreState): number => {
  return getCourseProgress(id, state)?.percentageCompleted ?? 0;
};

/**
 * This checks global course availability
 */
const isCourseAvailable = (
  id: string,
  planningStart: string | undefined,
  planningEnd: string | undefined,
  state: LxStoreState,
  options?: { isLockedByDefault?: boolean }
): CourseAvailabilityResult => {
  const progress = getCourseProgress(id, state);
  const datesUnlocked = CoursewareUtils.isCourseInAvailableDates(planningStart, planningEnd);
  const lockedByPlaylistsResult = PlaylistProgressSelectors.isCourseLockedByPlaylists(id)(state);

  // If we haven't received progress yet, consider course as unlocked
  const unlocked = progress ? progress.unlocked : !options?.isLockedByDefault;

  /**
   * Reviewers are not to have access to learners app in the future.
   * We want to display the original status of course in the new home.
   */
  return {
    isAvailable: unlocked && datesUnlocked && lockedByPlaylistsResult.isAvailable,
    lockedInPlaylists: lockedByPlaylistsResult.lockedInPlaylists
  };
};

const isCourseAvailableOffline = (id: string) => (state: LxStoreState) => {
  const course = getCourse(id, state);
  if (!course) return;

  const { lessonSummaries: lessons } = course;
  return lessons.some(({ lessonId }) => !!state.offline.status[lessonId]);
};

// be careful on calling this with useSelector, it always returns a new object which will trigger render
const getCourseStatus = (
  id: string,
  p: PlanningType | undefined,
  s: LxStoreState
): CourseStatusType => {
  const progress = getCourseProgress(id, s);

  const courseAvailabilityStatus = CourseSelectors.isCourseAvailable(
    id,
    p?.startDateTime,
    p?.endDateTime,
    s
  );
  if (!courseAvailabilityStatus.isAvailable) {
    return {
      id: 'locked',
      text: t('course.status.locked', { ns: 'learners-experience' }),
      lockedByPlaylists: courseAvailabilityStatus.lockedInPlaylists
    };
  }

  const isNew =
    !progress?.completed && progress?.lessonsCompleted === 0 && progress?.lessonsOpened === 0;

  if (!progress) {
    return {
      id: 'active',
      text: t('course.status.active', { ns: 'learners-experience' })
    };
  }

  if (isNew) {
    return {
      id: 'new',
      text: t('course.completions.new', { ns: 'learners-experience' })
    };
  }

  if (progress.completed) {
    return {
      id: 'complete',
      text: t('course.completions.completed', { ns: 'learners-experience' })
    };
  }

  return {
    id: 'in-progress',
    text: t('course.completions.in-progress', {
      completed: progress.lessonsCompleted,
      total: progress.lessonsTotal,
      ns: 'learners-experience'
    })
  };
};

const getCourseSyncErrorCode = (state: LxStoreState) => {
  return state.courseware.courses.courseSyncErrorCode;
};

const getCourseSyncLoading = (state: LxStoreState) => {
  return state.courseware.courses.courseSyncLoading;
};

const getTitle = (state: LxStoreState) => {
  const courseId = state.navigation.course.id;
  const course = CourseSelectors.getCourse(courseId, state);
  return course?.title || '';
};

const getTextColor = (state: LxStoreState) => {
  const courseId = state.navigation.course.id;
  const course = CourseSelectors.getCourse(courseId, state);
  const courseStyle = !!course ? CoursewareUtils.getBrandingStyleObject(course) : undefined;
  return courseStyle?.text || DEFAULT_BRANDING_TEXT_COLOR;
};

const getCompletionCriteria = (
  courseId: string,
  state: LxStoreState
): (CompletionCriteria & { list: string }) | undefined => {
  const course = getCourse(courseId, state);
  if (!course) {
    return undefined;
  }
  const {
    lessonIds = [],
    percentage = 100,
    openToComplete = false,
    milestone = 'percentage',
    certificateEnabled = false
  } = course.completionCriteria;
  let list = '';
  if (course.prerequisites.length) {
    // This is the line before magpie
    // const titles = course.prerequisites.map(pre => pre.title);
    const titles = course.prerequisites.map(pre => pre);
    list = prettyList(titles, 3, { wrap: 'strong', amp: '&' });
  }
  return { lessonIds, percentage, openToComplete, milestone, certificateEnabled, list };
};

const getPrerequisitesIds = (course: CourseSummaryType) => {
  return (
    course.prerequisites?.reduce((preIds, pre) => {
      preIds.push(pre.id);
      return preIds;
    }, [] as string[]) ?? []
  );
};

const getMandatoryLessonIds = (courseId: string, state: LxStoreState) =>
  CourseSelectors.getCourse(courseId, state)?.completionCriteria.lessonIds;

const isLessonMandatory = (courseId: string, lessonId: string, state: LxStoreState) => {
  const mandatoryLessonIds = getMandatoryLessonIds(courseId, state);
  return !!mandatoryLessonIds?.find(id => id === lessonId);
};

const getBackground = (state: LxStoreState) => {
  const courseId = state.navigation.course.id;
  const course = CourseSelectors.getCourse(courseId, state);
  return course?.brandingImage ? ENV.imageUrl(course.brandingImage) : undefined;
};

const getSupportedLanguages = (state: LxStoreState, courseId: string) => {
  const course = CourseSelectors.getCourse(courseId, state);
  return course?.supportedLanguages || [];
};

const getLastOpenedForCourse =
  (courseId: string) =>
  (state: LxStoreState): LessonSummaryType | DocumentType | undefined => {
    const course = CourseSelectors.getCourse(courseId, state);
    const lessonSummaries = course?.lessonSummaries || [];
    const documents = Object.values(state.courseware.documents.documents).filter(
      d => d.courseId === courseId
    );

    const searchItemId = state.search.lastSearchItemSelected?.id;
    if (!!searchItemId) {
      const searchedLesson = lessonSummaries.find(l => l.lessonId === searchItemId);
      if (searchedLesson) {
        return searchedLesson;
      }

      const document = state.courseware.documents.documents[searchItemId];
      if (!!document) {
        return document;
      }
    }

    const lastOpenedLessonId =
      state.navigation.lessonId ||
      state.navigation.observationId ||
      state.navigation.assessmentId ||
      state.navigation.discussionId ||
      state.navigation.conferenceId;

    const lastOpenedDocumentId = state.navigation.documentId;
    const lastDocumentOpened = documents.find(d => d.id === lastOpenedDocumentId);

    const lastLessonOpened = lessonSummaries.find(l => l.lessonId === lastOpenedLessonId);

    if (!!lastLessonOpened) {
      return lastLessonOpened;
    }

    if (!!lastDocumentOpened) {
      return lastDocumentOpened;
    }

    return lessonSummaries[0];
  };

const getCourseCertificateurl = (courseId: string) => (state: LxStoreState) =>
  state.courseware.courses.courses[courseId]?.certificateUrl;
const getCourseCertificateShareUrl = (courseId: string) => (state: LxStoreState) =>
  state.courseware.courses.courses[courseId]?.certificateShareUrl;
const getCourseCertificateLogo = (courseId: string) => (state: LxStoreState) =>
  state.courseware.courses.courses[courseId]?.certificateLogo;

const getMicroCredential = (courseId: string) => (state: LxStoreState) => {
  const isIndividualLearner = UserSelectors.isIndividualLearner(state.user);

  const getMicroCredentialId = makeGetMicroCredentialIdFromCourseId(courseId);
  const microCredentialId = getMicroCredentialId(state);

  const getMicroCredential = makeGetMicroCredential(microCredentialId);
  const microCredential = getMicroCredential(state);

  return {
    isIndividualLearner,
    microCredential
  };
};

const getAccessToRating = (courseId: string) => (state: LxStoreState) => {
  const hasAccessToRatings = UserSelectors.isIndividualLearner(state.user);
  const lessonsCompleted =
    CourseSelectors.getCourseProgress(courseId, state)?.lessonsCompleted || 0;

  return {
    hasAccessToRatings,
    lessonsCompleted
  };
};

type Direction = 'up' | 'down';
const getLessonNavigationDirection = (
  courseId?: string,
  fromLessonId?: string,
  toLessonId?: string
) => {
  return (state: LxStoreState): Direction => {
    if (!courseId) {
      return 'up';
    }

    const course = getCourse(courseId, state);
    if (!course) {
      return 'up';
    }

    const fromLessonIndex = course.lessonSummaries.findIndex(l => l.lessonId === fromLessonId);
    const toLessonIndex = course.lessonSummaries.findIndex(l => l.lessonId === toLessonId);

    if (toLessonIndex > fromLessonIndex) {
      return 'down';
    } else {
      return 'up';
    }
  };
};

const getUnlockPayloadFromPrerequisitesForCourseSummaries =
  (items: CourseSummaryType[], coursesProgress?: DictionaryType<CourseProgressType>) =>
  (state: LxStoreState) => {
    const progress = coursesProgress || getCoursesProgress(state);
    return items.map<UnlockPayload>(item => ({
      id: item.courseId,
      title: item.title,
      unlocked: item.prerequisites?.every(pre => !!progress[pre.id]?.completed) ?? true,
      courseVersionNumber: item.versionNumber
    }));
  };

const getUnlockPayloadFromPrerequisitesForCourses =
  (items: CourseType[], coursesProgress?: DictionaryType<CourseProgressType>) =>
  (state: LxStoreState) => {
    const progress = coursesProgress || getCoursesProgress(state);
    return items.map<UnlockPayload>(item => ({
      id: item.id,
      title: item.title,
      unlocked: item.prerequisites.every(pre => progress[pre]?.completed)
    }));
  };

/**
 * This checks course availability based on the current playlist progress.
 */
const getCourseStatusInPlaylist = (
  id: string,
  playlistId: string,
  p: PlanningType | undefined,
  s: LxStoreState
): CourseStatusType => {
  const courseAvailableInPlaylist = isCourseAvailableInPlaylist(
    id,
    playlistId,
    p?.startDateTime,
    p?.endDateTime,
    s
  );

  if (!courseAvailableInPlaylist.isAvailable) {
    return {
      id: 'locked',
      text: t('course.status.locked', { ns: 'learners-experience' })
    };
  }

  return CourseSelectors.getCourseStatus(id, p, s);
};

/**
 * This checks course availability based on the current playlist progress.
 *
 * !Note: If no playlistId, it will compute general course availability, not scoped to the Playlist
 */

const isCourseAvailableInPlaylist = (
  id: string,
  playlistId: string | undefined,
  planningStart: string | undefined,
  planningEnd: string | undefined,
  state: LxStoreState,
  options?: { isLockedByDefault?: boolean }
): CourseAvailabilityResult => {
  const isCourseAvailableInCourseware = isCourseAvailable(
    id,
    planningStart,
    planningEnd,
    state,
    options
  );

  if (!playlistId) {
    return isCourseAvailableInCourseware;
  }

  const isLockedInPlaylist = PlaylistProgressSelectors.isCourseLockedInPlaylist(
    id,
    playlistId
  )(state);

  return {
    isAvailable: !isLockedInPlaylist && isCourseAvailableInCourseware.isAvailable,
    lockedInPlaylists: isLockedInPlaylist ? [playlistId] : []
  };
};

const getCourseDetailPlaceholder = (state: LxStoreState, Placeholder: React.ComponentType) => {
  const courseId = state.navigation.course.id;
  const course = CourseSelectors.getCourse(courseId, state);
  const isLoading = state.courseware.courses.courseSyncLoading;
  return (course?.lessonSummaries.length || 0) > 0 || isLoading ? null : Placeholder;
};

export const CourseSelectors = {
  getCourse,
  getCourses,
  getCourseProgress,
  getCoursesProgress,
  getCoursePercentageCompleted,
  isCourseAvailable,
  isCourseAvailableOffline,
  getCourseStatus,
  getCourseSyncErrorCode,
  getCourseSyncLoading,
  getTitle,
  getTextColor,
  getBackground,
  getSupportedLanguages,
  getCompletionCriteria,
  getPrerequisitesIds,
  getMandatoryLessonIds,
  isLessonMandatory,
  getLastOpenedForCourse,
  getCourseCertificateurl,
  getCourseCertificateShareUrl,
  getCourseCertificateLogo,
  getMicroCredential,
  getAccessToRating,
  getLessonNavigationDirection,
  getUnlockPayloadFromPrerequisitesForCourseSummaries,
  getUnlockPayloadFromPrerequisitesForCourses,
  getCourseStatusInPlaylist,
  isCourseAvailableInPlaylist,
  getCourseDetailPlaceholder
};
