import type { HomeState } from './types';
import { HomeScreenWidget } from './types';

/**
 * Initialised criteriaOrder, forYouWidgets
 * for when open App in offline mode
 */
export const initialHomeState: HomeState = {
  isLoading: false,
  tabIndex: 0,
  forYouWidgets: [
    HomeScreenWidget.CoursesInProgressSection,
    HomeScreenWidget.PlaylistsSection,
    HomeScreenWidget.CourseCollectionsSection,
    HomeScreenWidget.UpNextSection,
    HomeScreenWidget.CoursesCompletedSection
  ],
  upNext: []
};
