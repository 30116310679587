type Source = 'library' | 'camera' | 'album';
type Type = 'video' | 'image' | 'all';

export type GetMediaFromSourceOptions = {
  /**
   * Set the source of the media. Defined in navigator.camera.PictureSourceType.
   * @type {('library' | 'camera' | 'album')}
   */
  source: Source;
  /**
   * Filters the type of media in the picker.
   * @type {('video' | 'image' | 'all')}
   */
  type: Type;
};

/**
 * A wrapper for the cordova camera plugin to retreive images and videos.
 *
 * @export
 * @class MediaHelper
 */
export class MediaHelper {
  /**
   * Gets an image or video from the device and returns the url of the file.
   * @static
   * @param {GetMediaFromSourceOptions} options
   * @returns {Promise<string>}
   * @memberof MediaHelper
   */
  static async getMediaFromSource(options: GetMediaFromSourceOptions): Promise<string | undefined> {
    if (!navigator.camera) {
      console.warn('You must be running on device to access camera.');
      return;
    }

    const uri = await new Promise<string>((resolve, reject) => {
      navigator.camera.getPicture(resolve, reject, {
        destinationType: Camera.DestinationType.FILE_URI,
        sourceType: this.getSourceTypeMap(options.source),
        mediaType: this.getTypeMap(options.type),
        correctOrientation: true
      });
    });

    // Workaround for Android not returning correct URIs
    return uri.startsWith('file://') ? uri : 'file://' + uri;
  }
  private static getSourceTypeMap(source: Source) {
    return {
      library: Camera.PictureSourceType.PHOTOLIBRARY,
      camera: Camera.PictureSourceType.CAMERA,
      album: Camera.PictureSourceType.SAVEDPHOTOALBUM
    }[source];
  }
  private static getTypeMap(type: Type) {
    return {
      video: Camera.MediaType.VIDEO,
      image: Camera.MediaType.PICTURE,
      all: Camera.MediaType.ALLMEDIA
    }[type];
  }
}
