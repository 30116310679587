import { loadEnv } from '@edapp/maggie';

export const loadMaggieEnvironment = () => {
  window.__ENV__ = {
    IS_FAST_FORWARD_LOCAL_NOTIFICATIONS: false,
    FOR_CHINA: 'false',
    NODE_ENV: process.env.NODE_ENV,
    CDN: process.env.CLOUDINARY_CDN,
    THOMAS: getRegionalUrl(),
    THOMAS_PLAYER: '/training/engine/player.html',
    EMILY_API: '',
    HIPPO_API: process.env.HIPPO_URL,
    IS_TRACKING_ANALYTICS_ENABLED: true,
    IS_BRAZE_ENABLED: false,
    ENABLE_DD_MONITORING: true,
    PUBLIC_BRANCH_KEY: process.env.BRANCH_KEY,
    KNOCK_PUBLIC_KEY: '',
    BRAZE_PUBLIC_KEY: process.env.BRAZE_API_KEY,
    SEGMENT_PUBLIC_KEY: '',
    STATIC_ASSETS_CDN_BASE_URL: `${process.env.STATIC_ASSETS_CDN_BASE_URL}`
  };

  loadEnv();
};

const getRegionalUrl = () => {
  return "https://" + window.location.hostname;
};
