import * as _ from 'lodash';
import { createSelector } from 'reselect';

import type { LxStoreState } from '@maggie/store/types';

import type { AssessmentPostType } from './types';

const getProgress = (assessmentId: string) => {
  return (state: LxStoreState) => state.courseware.assessments.progress[assessmentId];
};

const getAssessmentItem = (assessmentId: string) => {
  return (state: LxStoreState) => state.courseware.assessments.assessments[assessmentId];
};

const getAssessment = (assessmentId: string) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.assessments.fetchingAssessmentLoading,
    (state: LxStoreState) => state.courseware.assessments.fetchingAssessmentError,
    (state: LxStoreState) => state.courseware.assessments.assessments[assessmentId],
    (loading, error, assessment) => ({ loading, error, assessment })
  );
};

const getPosts = (assessmentId: string) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.assessments.posts[assessmentId]?.items || [],
    (state: LxStoreState) => state.courseware.assessments.fetchingPostsLoading,
    (state: LxStoreState) => state.courseware.assessments.fetchingPostsError,
    (state: LxStoreState) => state.courseware.assessments.posts[assessmentId]?.totalCount || 0,
    (posts, loading, error, totalCount) => ({
      // latest post at the top
      posts: _.orderBy(posts, p => new Date(p.timestamp), 'desc'),
      loading,
      error,
      totalCount
    })
  );
};

const getPost = (postId: string) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.assessments.posts,
    posts => {
      const allPosts = Object.keys(posts).reduce((result, assessmentId) => {
        const assessmentPosts = posts[assessmentId];
        return [...result, ...(assessmentPosts?.items || [])];
      }, [] as AssessmentPostType[]);

      return allPosts.find(i => i.id === postId);
    }
  );
};

const getPostComments = (postId: string) => {
  return createSelector(
    (state: LxStoreState) => state.courseware.assessments.postComments[postId]?.items || [],
    (state: LxStoreState) => state.courseware.assessments.postComments[postId]?.totalCount || 0,
    (items, totalCount) => ({ items, totalCount })
  );
};

const getFeedback = (postId: string) =>
  createSelector(
    (state: LxStoreState) => state.courseware.assessments.fetchingFeedbackLoading,
    (state: LxStoreState) => state.courseware.assessments.fetchingFeedbackError,
    (state: LxStoreState) => state.courseware.assessments.feedback[postId],
    (loading, error, feedback) => ({ loading, error, feedback })
  );

const getFetchErrorCode = (state: LxStoreState) => {
  return state.courseware.assessments.fetchingAssessmentErrorCode;
};

const getTitle = (state: LxStoreState) => {
  const assessmentId = state.navigation.assessmentId;
  const assessment = AssessmentSelectors.getAssessment(assessmentId)(state).assessment;
  return assessment?.title || '';
};

const getPostForAssignment = (assessmentId: string) => (state: LxStoreState) => {
  const { posts } = getPosts(assessmentId)(state);
  return state.navigation.postId || posts[0]?.id;
};

export const AssessmentSelectors = {
  getProgress,
  getAssessment,
  getAssessmentItem,
  getPosts,
  getPost,
  getPostComments,
  getFeedback,
  getFetchErrorCode,
  getTitle,
  getPostForAssignment
};
