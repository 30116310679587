import { select, takeLatest } from 'redux-saga/effects';

import { Tracker } from '@maggie/core/tracker/tracker';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { SCUserId } from '@maggie/store/navigation/sc-user-id';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { ProfileActionTypes } from './actions';

function* handleSetLanguage() {
  const user: ReturnType<typeof UserSelectors.getUser> = yield select<LxStoreState>(
    UserSelectors.getUser
  );
  const deviceToken: string = yield select<LxStoreState>(ConfigSelectors.getDevicePushNotification);
  const scUserId = SCUserId.get();
  Tracker.trackVisit({ type: 'visit' }, user, deviceToken, window.__l10n.language, scUserId);
}

export function* watchSetLanguage() {
  yield takeLatest(ProfileActionTypes.SET_LANGUAGE, handleSetLanguage);
}
