import { cloneDeep } from 'lodash';

import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';
import type { SlideType } from '@maggie/store/brain-boost/types';
import type { ConfigurationType } from '@maggie/store/courseware/lessons/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { BrainboostAttempt } from '../attempt/brainboost-attempt';
import { ThomasPlayer } from './thomas-player';
import type { ConfigurationForThomas, ContextForThomas } from './thomas-player-interface';

export class BrainboostThomasPlayer extends ThomasPlayer {
  private attempt: BrainboostAttempt;

  constructor(
    id: string,
    slides: SlideType[],
    configuration: ConfigurationType,
    thomasVersion?: string
  ) {
    super(id, configuration, thomasVersion);
    this.attempt = new BrainboostAttempt(id, slides);
  }

  /**
   * Returns the lesson configuration object with some extra properties for thomas
   */
  private getLessonConfigurationForThomas = (config: ConfigurationType): ConfigurationForThomas => {
    const configuration = cloneDeep(config);

    // disable social learning in case backend forgot!?
    for (const slide of configuration.slides) {
      if (slide?.data?.socialLearning?.enabled) {
        slide.data.socialLearning.enabled = false;
      }
    }

    return {
      ...configuration,
      config: {
        ...configuration.styleConfiguration,
        isQuiz: true
      }
    };
  };

  protected getContextForThomas = (): ContextForThomas => {
    const configuration = this.thomasAssets.getConfiguration();
    return {
      slidedeck: {
        id: this.id,
        userId: UserSelectors.getId(window.__store.getState()),
        stars: {},
        enableStars: false,
        alreadyCompleted: false,
        disableAnswerFeedback: false,
        isScored: true,
        platform: Platform.get(),
        config: this.getLessonConfigurationForThomas(configuration),
        aiccURL: ENV.apiUrl('/aicc')
      },
      uploadConfiguration: {}
    };
  };

  /**
   * @throws error if it does not successfully open
   */
  public open = async () => {
    await super.open();

    this.attempt.startAttempt();
    this.attempt.startRecording(this.id);
  };

  public close = async () => {
    await super.close();

    this.attempt.finishAttempt();
    this.attempt.stopRecording(this.id);
  };
}
