import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

const canPlay = (state: LxStoreState) => {
  return state.config.isOnline && !!state.user.ed.lottery.playerId;
};

/**
 * Disabled when restricted to course or lessons screens or Individual Learner.
 * @param state The store state
 */
const isGameEnabled = (state: LxStoreState) =>
  !state.config.restrictToCourseScreen &&
  !state.config.restrictToLessonScreen &&
  UserSelectors.hasStarBarEnabled(state.user);

export const GameSelectors = {
  canPlay,
  isGameEnabled
};
