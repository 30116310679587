import * as i18n from 'i18next';
import type { BackgroundColor } from '../status-label/types';

// Courseware Statuses
export enum CoursewareStatus {
  NOT_SPECIFIED = 'NotSpecified',
  DRAFT = 'Draft',
  IN_REVIEW = 'InReview',
  REVIEWED = 'Reviewed',
  SCHEDULED = 'Scheduled',
  PUBLISHED = 'Published',
  ARCHIVED = 'Archived'
}

export const coursewareStatusLabelColor: Record<CoursewareStatus, BackgroundColor> = {
  [CoursewareStatus.PUBLISHED]: 'blue',
  [CoursewareStatus.DRAFT]: 'navy',
  [CoursewareStatus.SCHEDULED]: 'orange',
  [CoursewareStatus.ARCHIVED]: 'greyActive',
  [CoursewareStatus.IN_REVIEW]: 'greyActive',
  [CoursewareStatus.REVIEWED]: 'greyActive',
  [CoursewareStatus.NOT_SPECIFIED]: 'greyActive'
};

export const coursewareStatusLabelText: Record<CoursewareStatus, string> = {
  [CoursewareStatus.PUBLISHED]: 'courseware-status.label.published',
  [CoursewareStatus.DRAFT]: 'courseware-status.label.draft',
  [CoursewareStatus.SCHEDULED]: 'courseware-status.label.scheduled',
  [CoursewareStatus.ARCHIVED]: 'courseware-status.label.archived',
  [CoursewareStatus.IN_REVIEW]: 'courseware-status.label.in-review',
  [CoursewareStatus.REVIEWED]: 'courseware-status.label.reviewed',
  [CoursewareStatus.NOT_SPECIFIED]: 'courseware-status.label.not-specified'
};

export const ScCoursewareStatusVariantMap: Record<
  CoursewareStatus,
  { variant: 'default' | 'warning' | 'negative' | 'positive' | 'information'; text: string }
> = {
  [CoursewareStatus.NOT_SPECIFIED]: {
    variant: 'default',
    text: 'courseware-status.label.not-specified'
  },
  [CoursewareStatus.DRAFT]: {
    variant: 'default',
    text: 'courseware-status.label.draft'
  },
  [CoursewareStatus.IN_REVIEW]: {
    variant: 'information',
    text: 'courseware-status.label.in-review'
  },
  [CoursewareStatus.REVIEWED]: {
    variant: 'information',
    text: 'courseware-status.label.reviewed'
  },
  [CoursewareStatus.SCHEDULED]: {
    variant: 'warning',
    text: 'courseware-status.label.scheduled'
  },
  [CoursewareStatus.PUBLISHED]: {
    variant: 'positive',
    text: 'courseware-status.label.published'
  },
  [CoursewareStatus.ARCHIVED]: {
    variant: 'negative',
    text: 'courseware-status.label.archived'
  }
};

export const getCoursewareStatusLabelText = (status: CoursewareStatus): string => {
  return i18n.t(coursewareStatusLabelText[status], { ns: 'ed-components' });
};
