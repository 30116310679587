import type { DictionaryType } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { PlaylistItemType } from '@maggie/store/courseware/playlists/types';

import type { CollectionType } from '../courseware/collections/types';
import type { CourseProgressType, CourseType } from '../courseware/courses/types';
import type { LessonProgressType, LessonType } from '../courseware/lessons/types';

enum OfflineActionTypes {
  DOWNLOAD_LESSON_OFFLINE = 'DOWNLOAD_LESSON_OFFLINE',
  DOWNLOAD_LESSON_OFFLINE_PROGRESS = 'DOWNLOAD_LESSON_OFFLINE_PROGRESS',
  DOWNLOAD_LESSON_OFFLINE_CANCEL_CURRENT = 'DOWNLOAD_LESSON_OFFLINE_CANCEL_CURRENT',

  SAVE_DATA_OFFLINE = 'SAVE_DATA_OFFLINE',
  ALL_DOWNLOADS_COMPLETED = 'ALL_DOWNLOADS_COMPLETED',

  DELETE_LESSON_OFFLINE = 'DELETE_LESSON_OFFLINE',
  DELETE_LESSON_OFFLINE_SUCCESS = 'DELETE_LESSON_OFFLINE_SUCCESS',
  DELETE_LESSON_OFFLINE_FAILURE = 'DELETE_LESSON_OFFLINE_FAILURE',

  DELETE_COURSE_OFFLINE_SUCCESS = 'DELETE_COURSE_OFFLINE_SUCCESS',
  DELETE_COURSE_OFFLINE_FAILURE = 'DELETE_COURSE_OFFLINE_FAILURE',
  DELETE_COLLECTION_OFFLINE_SUCCESS = 'DELETE_COLLECTION_OFFLINE_SUCCESS',
  DELETE_COLLECTION_OFFLINE_FAILURE = 'DELETE_COLLECTION_OFFLINE_FAILURE',
  DELETE_PLAYLIST_OFFLINE_SUCCESS = 'DELETE_PLAYLSIT_OFFLINE_SUCCESS',
  DELETE_PLAYLIST_OFFLINE_FAILURE = 'DELETE_PLAYLIST_OFFLINE_FAILURE',

  PURGE_LESSONS_OFFLINE = 'PURGE_LESSONS_OFFLINE',
  PURGE_LESSONS_OFFLINE_SUCCESS = 'PURGE_LESSONS_OFFLINE_SUCCESS',
  PURGE_LESSONS_OFFLINE_FAILURE = 'PURGE_LESSONS_OFFLINE_FAILURE'
}

const OfflineActions = {
  downloadLessonOffline: (lessonId: string, courseId?: string) =>
    createAction(OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE, { lessonId, courseId }),
  downloadLessonOfflineProgress: (lessonId: string, progress: number) =>
    createAction(OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE_PROGRESS, { lessonId, progress }),
  saveDataOffline: (
    lesson: LessonType,
    lessonProgresses: DictionaryType<LessonProgressType>,
    course: CourseType,
    courseProgress: CourseProgressType,
    collection: CollectionType,
    playlist: PlaylistItemType | undefined
  ) =>
    createAction(OfflineActionTypes.SAVE_DATA_OFFLINE, {
      lesson,
      lessonProgresses,
      collection,
      course,
      courseProgress,
      playlist
    }),
  allDownloadsCompleted: () => createAction(OfflineActionTypes.ALL_DOWNLOADS_COMPLETED),
  deleteLessonOffline: (lessonId: string) =>
    createAction(OfflineActionTypes.DELETE_LESSON_OFFLINE, { lessonId }),
  deleteLessonOfflineSuccess: (lessonId: string) =>
    createAction(OfflineActionTypes.DELETE_LESSON_OFFLINE_SUCCESS, { lessonId }),
  deleteCourseOfflineSuccess: (courseId: string) =>
    createAction(OfflineActionTypes.DELETE_COURSE_OFFLINE_SUCCESS, { courseId }),
  deleteCollectionOfflineSuccess: (collectionId: string) =>
    createAction(OfflineActionTypes.DELETE_COLLECTION_OFFLINE_SUCCESS, { collectionId }),
  deletePlaylistOfflineSuccess: (playlistId: string) =>
    createAction(OfflineActionTypes.DELETE_PLAYLIST_OFFLINE_SUCCESS, { playlistId }),
  purgeLessonsOffline: (purgeDownloadsInProgress: boolean) =>
    createAction(OfflineActionTypes.PURGE_LESSONS_OFFLINE, { purgeDownloadsInProgress })
};

export { OfflineActions, OfflineActionTypes };
