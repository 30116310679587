import { Platform } from './platform';

export interface Ionic {
  WebView: {
    convertFileSrc: (url: string) => string;
  };
}

/**
 * A blob that has similar properties to a File that are mutable,
 * which is used to get around cordova overriding the File object
 * @interface MutableFile
 * @extends {Blob}
 */
interface MutableFile extends Blob {
  name: string;
  lastModified: number;
}

export class FileManager {
  /**
   * Returns a file like object.
   * Cordova overrides the global File class object which results in file uploads breaking.
   *
   * @static
   * @param {string} url The full path to the file
   * @returns {File}
   * @memberof FileManager
   */
  static async getFileWithURL(url: string) {
    const fileEntry = await new Promise<Entry | FileEntry>((resolve, reject) => {
      window.resolveLocalFileSystemURL(url, resolve, reject);
    });

    const file = await new Promise<File>((resolve, reject) => {
      if ('file' in fileEntry) {
        fileEntry.file(resolve, reject);
      } else {
        reject(new Error('URL is not a file'));
      }
    });
    const platform = Platform.get();

    if (platform === 'iOS' || platform === 'Android') {
      // The following is a workaround for iOS & android not returning a proper file object
      // this is because cordova is overriding the original 'File' class so we have to fake it
      return new Promise<File | MutableFile>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (!reader.error && reader.result) {
            const blob = new Blob([reader.result as ArrayBuffer], {
              type: file.type
            }) as MutableFile;
            blob.name = file.name;
            blob.lastModified = file.lastModified;
            resolve(blob);
          } else {
            reject(reader.error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    } else {
      throw new Error(`Method 'getFileWithURL' is not supported on this platform.`);
    }
  }

  /**
   * Returns a boolean if a file exists.
   * Cordova overrides the global File class object which results in file uploads breaking.
   *
   * @static
   * @param {string} url The full path to the file
   * @returns {File}
   * @memberof FileManager
   */
  static async fileExists(url: string) {
    return new Promise<boolean>(resolve => {
      window.resolveLocalFileSystemURL(
        url,
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  /**
   * Gets a file from a direcotory entry.
   *
   * @static
   * @param {string} url A path relative to the entry
   * @param {DirectoryEntry} entry
   * @param {(Flags | undefined)} options
   * @returns {Promise<FileEntry>}
   * @memberof FileManager
   */
  static async getFileFromDirectoryEntry(
    url: string,
    entry: DirectoryEntry,
    options: Flags | undefined
  ): Promise<FileEntry> {
    return new Promise((resolve, reject) => {
      entry.getFile(url, options, resolve, reject);
    });
  }

  static async createFolder(folder: string): Promise<DirectoryEntry> {
    const folders = folder.split('/');
    const path = folders.slice(0, -1).join('/');
    const currentfolder = folders[folders.length - 1];
    return new Promise<DirectoryEntry>((resolve, reject) => {
      window.resolveLocalFileSystemURL(
        path,
        (root: DirectoryEntry) => {
          root.getDirectory(currentfolder, { create: true, exclusive: false }, resolve, reject);
        },
        reject
      );
    });
  }

  /**
   * Lists the contents of a directory as an array of entries.
   *
   * @static
   * @param {string} url An absolute path
   * @returns {Promise<Entry[]>}
   * @memberof FileManager
   */
  static async listDirectory(url: string): Promise<Entry[]> {
    const dirEntry = await this.resolveLocalFileSystemURL(url);
    if (dirEntry && 'createReader' in dirEntry) {
      const dirReader = dirEntry.createReader();
      return new Promise((resolve, reject) => {
        dirReader.readEntries(resolve, reject);
      });
    } else {
      return [];
    }
  }

  /**
   * Creates a file writer from a directory entry.
   *
   * @static
   * @param {FileEntry} entry
   * @returns {Promise<FileWriter>}
   * @memberof FileManager
   */
  static async createWriter(entry: FileEntry): Promise<FileWriter> {
    return new Promise((resolve, reject) => {
      entry.createWriter(resolve, reject);
    });
  }

  /**
   * Read a file from the application data directory as a string.
   *
   * @static
   * @param {string} url A path relative to the data directory.
   * @returns {string | null}
   * @memberof FileManager
   */
  static async readFileAsString(url: string) {
    const entry = await FileManager.resolveLocalFileSystemURL(FileManager.dataDirectory + url);
    if (entry && entry.isFile && 'file' in entry) {
      let file: File;
      try {
        file = await new Promise<File>((resolve, reject) => entry.file(resolve, reject));
      } catch (e) {
        console.error(`Failed to read file, '${FileManager.dataDirectory + url}`, e);
        return null;
      }

      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsText(file);
      });
    } else {
      return null;
    }
  }

  /**
   * Writes to a file in the application data directory.
   *
   * @static
   * @param {string} url A path relative to the data directory.
   * @param {(Blob | string)} data
   * @returns {Promise<void>}
   * @memberof FileManager
   */
  static async writeFile(url: string, data: Blob | string): Promise<void> {
    // Get the directory entry
    const dirEntry = await FileManager.resolveLocalFileSystemURL(FileManager.dataDirectory);
    if (dirEntry && 'getFile' in dirEntry) {
      // Get the file entry
      const entry = await FileManager.getFileFromDirectoryEntry(url, dirEntry, {
        create: true,
        exclusive: false
      });
      const writer = await FileManager.createWriter(entry);
      return new Promise((resolve, reject) => {
        writer.onwrite = () => resolve();
        writer.onerror = () => reject(writer.error);
        // Convert the string to a Blob before writing
        if (typeof data === 'string') {
          writer.write(new Blob([data], { type: 'text/plain' }));
        } else {
          writer.write(data);
        }
      });
    }
    return;
  }

  /**
   * Convert a url to a FileEntry or DirectoryEntry
   *
   * @static
   * @param {string} url The file or directory path
   * @returns A Promise resolving in a FileEntry or DirectoryEntry
   * @memberof FileManager
   */
  static async resolveLocalFileSystemURL(url: string) {
    return new Promise<Entry | FileEntry | DirectoryEntry | undefined>((resolve, reject) => {
      if (!window.resolveLocalFileSystemURL) {
        return resolve(undefined);
      }
      window.resolveLocalFileSystemURL(url, resolve, reject);
    });
  }

  static ionicNormalizeURL(url: string) {
    return Platform.get() === 'iOS' && window.Ionic && window.Ionic.WebView
      ? window.Ionic.WebView.convertFileSrc(url)
      : url;
  }

  /**
   * Removes a folder and all files
   *
   * @static
   * @param {string} path The directory path
   * @returns A Promise resolving if the delete was successful
   * @memberof FileManager
   */
  static async deleteFolder(path: string) {
    const entry = await this.resolveLocalFileSystemURL(path);
    return new Promise<void>((resolve, reject) => {
      if (entry && entry.isDirectory && 'removeRecursively' in entry) {
        (entry as DirectoryEntry).removeRecursively(resolve, reject);
      } else {
        reject(new Error('URL is not a directory'));
      }
    });
  }

  /**
   * Returns the data directory if available or an empty string.
   * The 'dataDirectory' is a persistent and private data storage within the application's sandbox using internal memory.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberof FileManager
   */
  static get dataDirectory(): string {
    return !!window.cordova ? window.cordova.file.dataDirectory : '';
  }

  /**
   * Returns the application directory if available or an empty string.
   * The 'applicationDirectory' is the read-only directory where the application is installed.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberof FileManager
   */
  static get applicationDirectory(): string {
    return !!window.cordova ? window.cordova.file.applicationDirectory : '';
  }
  /**
   * Returns the www folder inside the application directory if available or an empty string.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberof FileManager
   */
  static get wwwDirectory(): string {
    return !!window.cordova ? window.cordova.file.applicationDirectory + 'www/' : '';
  }
}
