import type { RelativeToken, Token } from '@edapp/request';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import { GameActionTypes } from '@maggie/store/game/actions';
import type { GameActionsUnionType } from '@maggie/store/game/types';
import { ProfileActionTypes } from '@maggie/store/profile/actions';
import type { ProfileActionsUnionType } from '@maggie/store/profile/actions';
import type { SessionActionsUnionType } from '@maggie/store/session/actions';
import { SessionActionTypes } from '@maggie/store/session/actions';

import { UserActionTypes } from './actions';
import type { SyncPropertiesToIgnore, UserActionsUnionType, UserState } from './types';

// This is set from the BE.
const initialSplashScreen = {
  enabled: false,
  background: '',
  logo: '',
  textColor: 'white' as const
};

export const initialUserState: UserState = {
  ed: {
    compliance: {
      prizing: {
        enabled: false,
        text: ''
      }
    },
    lottery: {
      email: '',
      firstName: '',
      id: '',
      lastName: '',
      lastRoundWon: '',
      nbTokens: 0,
      playerId: ''
    },
    content: {
      lessonOnWeekends: false,
      nbLessonsPerDay: 0,
      showCourseCollectionSection: true,
      showUpNextSection: true
    },
    notifications: {
      enabled: false,
      content: '',
      prizing: ''
    },
    enableSpinToWin: false,
    isDemoUser: false,
    // Data previously not in Login
    leaderboardEnabled: false,
    offlineEnabled: false,
    avatarUploadEnabled: false,
    enableStars: false,
    quizEnabled: false,
    socialLearning: false,
    socialAttribution: false,
    lessonCompletedDates: [],
    enableStarBar: false,
    enableMindfulLearning: false,
    myPerformanceEnabled: false,
    myProfileEnabled: false,
    newNavbarEnabled: false,
    hasAccess: true,
    hasAccessReason: null,
    deeplinksEnabled: true,
    notificationsEnabled: false,
    postMessageWindowLocationEnabled: false,
    appUpdatePromptEnabled: false
  },
  invited: false,
  invitationStatus: 'verified',
  hasRatedApp: false,
  accounts: [],
  declinedRating: undefined,
  remindRating: undefined,
  roles: [],
  id: '',
  account: '',
  applicationId: '',
  applicationName: '',
  bids: {},
  tokens: [],
  dateLastOpened: '',
  email: '',
  externalIdentifier: '',
  firstName: '',
  hippoSyncEnabled: false,
  lastName: '',
  name: '',
  ssoDetails: '',
  ssoToken: '',
  ssoTokenExpiry: '',
  status: '',
  lessonCompletedDates: [],
  splashScreen: initialSplashScreen,
  registrationDate: undefined,
  demographicQuestionFrequency: 1,
  recommendedAppVersion: '',
  soundDisabled: false,
  ssoLogoutUrl: '',
  hasLoggedInWithSSO: false,
  customPrivacyPolicyUrl: null,
  locale: null,
  avatarUrl: '',
  provisioningType: null
};

const skew = (token: RelativeToken): Token => ({
  type: token.type,
  value: token.value,
  expiresAt: new Date(Date.now() + token.expiresInSeconds * 1000).toISOString()
});

type NoOverlap<TPatient, TUnwanted> = {
  [key in keyof TPatient]: key extends keyof TUnwanted ? never : TPatient[key];
};

export const userReducer = (
  state: UserState = initialUserState,
  action:
    | UserActionsUnionType
    | GameActionsUnionType
    | SessionActionsUnionType
    | ProfileActionsUnionType
): UserState => {
  switch (action.type) {
    case SessionActionTypes.SESSION_LOGIN: {
      const tokens = action.payload.tokens.map(skew);
      return {
        ...state,
        tokens
      };
    }

    case UserActionTypes.FETCH_SYNC_USER_SUCCESS: {
      const { campusToken, campusTokenExpiry, ...rest } = action.payload;
      const boundedRest: NoOverlap<typeof rest, SyncPropertiesToIgnore> = rest;
      return { ...state, ...boundedRest };
    }

    case ProfileActionTypes.UPDATE_AVATAR:
      return { ...state, ...action.payload };

    case UserActionTypes.USER_DID_LOGOUT:
      return { ...initialUserState };

    case UserActionTypes.UPDATE_OPENED_DATE:
      return {
        ...state,
        dateLastOpened: new Date().toString()
      };

    case UserActionTypes.WIN_PRIZE:
      const lastRoundWon = action.payload.roundId;
      return {
        ...state,
        ed: {
          ...state.ed,
          lottery: {
            ...state.ed.lottery,
            lastRoundWon
          }
        }
      };

    case UserActionTypes.CLAIM_PRIZE:
      const prizeDetails = action.payload.details;
      return {
        ...state,
        ed: {
          ...state.ed,
          lottery: {
            ...state.ed.lottery,
            lastRoundWon: '',
            firstName: prizeDetails.firstName,
            lastName: prizeDetails.lastName,
            email: prizeDetails.email
          }
        }
      };

    case UserActionTypes.REMIND_RATING:
      return {
        ...state,
        remindRating: action.payload.dateTime
      };

    case UserActionTypes.DECLINED_RATING:
      return {
        ...state,
        declinedRating: action.payload.dateTime
      };

    case GameActionTypes.BID_ON_AUCTION: {
      const bids: UserState['bids'] = state.bids ? { ...state.bids } : {};
      const { auctionId, stars } = action.payload;

      // @ts-expect-error TODO: EDAPP-42821 not sure how this is allowed if bids = UserState['bids']
      bids[auctionId] = {
        created: Date.now(),
        stars
      };

      return {
        ...state,
        bids
      };
    }

    case LessonActionTypes.UPDATE_LESSON_COMPLETED:
      const newCompletedDates = state.ed.lessonCompletedDates.concat(new Date());

      return {
        ...state,
        ed: {
          ...state.ed,
          lessonCompletedDates: newCompletedDates
        }
      };

    case UserActionTypes.SET_REGISTRATION_DATE: {
      return {
        ...state,
        registrationDate: action.payload.date?.toString()
      };
    }

    case SessionActionTypes.LOGIN_WITH_SSO_SUCCESS: {
      return { ...state, hasLoggedInWithSSO: true };
    }

    case ProfileActionTypes.SET_LANGUAGE: {
      const { locale } = action.payload;
      return { ...state, locale };
    }

    default:
      return state;
  }
};
