import { appRoutes } from '@maggie/app/constants/app-routes';
import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';
import { ToolbarBehaviour } from '@maggie/core/router/types';
import type { RouteName, RouteParams } from '@maggie/core/router/types';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { UNAUTHENTICATED_ROUTES } from '@maggie/store/navigation/handlers/access-sagas';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';
import type { ThemeType } from '@maggie/theme/theme';

import { splitViewLayoutSizes } from './view/route-split-view-layout/split-view-main/constants';

const getTitle = (routeName?: RouteName, routeParams?: RouteParams<RouteName>) => (
  state: LxStoreState
) => {
  if (!routeName) {
    return '';
  }

  return appRoutes[routeName].viewLayout?.getTitle?.(state, routeParams) || '';
};

const getSplitViewTitle = (routeName?: RouteName) => (state: LxStoreState) => {
  if (!routeName) {
    return '';
  }

  return appRoutes[routeName].splitViewLayout?.main?.getTitle?.(state) || '';
};

const getViewHeadType = (routeName?: RouteName) => (state: LxStoreState) => {
  if (!routeName) {
    return 'fixed';
  }

  return appRoutes[routeName].viewLayout?.getViewHeadType?.(state) || 'fixed';
};

const getIsLocked = (routeName?: RouteName) => () => {
  if (!routeName) {
    return false;
  }

  return appRoutes[routeName].locked;
};

const getSplitViewToolbarBehaviour = (routeName?: RouteName) => {
  return (state: LxStoreState): ToolbarBehaviour => {
    if (!routeName) {
      return ToolbarBehaviour.fixed;
    }

    return (
      appRoutes[routeName].splitViewLayout?.main?.getToolbarBehaviour?.(state) ||
      ToolbarBehaviour.fixed
    );
  };
};

const getSplitViewToolbarTextColor = (theme: ThemeType, routeName?: RouteName) => (
  state: LxStoreState
) => {
  if (!routeName) {
    return theme.colors.white;
  }

  return (
    appRoutes[routeName].splitViewLayout?.main?.getToolbarTextColor?.(state) || theme.colors.white
  );
};

const getSplitViewToolbarBackground = (routeName?: RouteName) => (state: LxStoreState) => {
  if (!routeName) {
    return '';
  }

  const background =
    appRoutes[routeName].splitViewLayout?.main?.getToolbarBackground?.(state) || '';

  // Temporary fix: Force all img to start with Cloudianary URL so old course branding img loads
  if (!!background) return ENV.imageUrl(background);
  return background;
};

const getHasBackButton = (routeName?: RouteName) => (state: LxStoreState) => {
  const restrictToCourseScreen = ConfigSelectors.getRestrictToCourseScreen(state);
  const restrictToLessonScreen = ConfigSelectors.getRestrictToLessonScreen(state);

  if (routeName === 'lesson' && restrictToLessonScreen) {
    return false;
  }

  if (routeName === 'course' && restrictToCourseScreen) {
    return false;
  }

  return !!routeName && appRoutes[routeName].stackIndex > 0;
};

const getLayoutBreakpoint = (routeName?: RouteName): number => {
  if (routeName && appRoutes[routeName].splitViewLayout) {
    const size = appRoutes[routeName].splitViewLayout?.main?.size || 'md';
    return splitViewLayoutSizes[size].breakpoint;
  }

  return -1;
};

const getIsMobile = (screenWidth = window.innerWidth, routeName?: RouteName) => {
  const breakpoint = getLayoutBreakpoint(routeName);
  if (breakpoint >= 0) {
    return screenWidth <= breakpoint;
  }

  return Platform.isMobile(screenWidth);
};

/**
 * We use this to avoid rendering if user is not authenticated and route requires
 * In the side effects it will redirect to login - this is to avoid flashing
 */
const shouldRenderRoute = (routeName?: RouteName) => (state: LxStoreState) => {
  const userToken = UserSelectors.getToken(state);
  const unauthedRoute = routeName && UNAUTHENTICATED_ROUTES.includes(routeName);

  return unauthedRoute || !!userToken;
};

export const AppLayoutSelectors = {
  getTitle,
  getSplitViewTitle,
  getViewHeadType,
  getIsLocked,
  getSplitViewToolbarBehaviour,
  getSplitViewToolbarTextColor,
  getSplitViewToolbarBackground,
  getHasBackButton,
  shouldRenderRoute,
  getLayoutBreakpoint,
  getIsMobile
};
