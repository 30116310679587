import { put, race, take } from 'redux-saga/effects';

import { LessonActivityActions } from '@maggie/store/courseware/lesson-activity/actions';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import {
  ObservationActionTypes,
  ObservationActions
} from '@maggie/store/courseware/observations/actions';
import type { ObservationAction } from '@maggie/store/courseware/observations/sagas';

export function* handleDidNavigateToObservation(observationId: string) {
  yield put(ObservationActions.fetchObservation(observationId));

  type RaceType = { success: ObservationAction<ObservationActionTypes.FETCH_OBSERVATION_SUCCESS> };
  const { success }: RaceType = yield race({
    success: take(ObservationActionTypes.FETCH_OBSERVATION_SUCCESS),
    failure: take(ObservationActionTypes.FETCH_OBSERVATION_FAILURE)
  });

  if (!!success) {
    const { courseVersionNumber } = success.payload.observation;
    yield put(LessonActions.updateLessonOpened(observationId, courseVersionNumber));
    yield put(LessonActivityActions.didOpenLessonActivity(observationId, 'Observation'));
  }
}
