import type { MicroCredentialsState } from './types';

export const initialMicroCredentialsState: MicroCredentialsState = {
  byId: {},
  byCourseId: {},
  polling: {},
  earnedList: {
    isLoading: false,
    error: '',
    totalCount: 0
  }
};
