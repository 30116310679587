import { mapValues, uniqBy } from 'lodash';

import { ErrorLogger } from '@edapp/monitoring';
import type { DictionaryType } from '@edapp/utils';
import { BackgroundDownloader } from '@maggie/cordova/backgroundDownloader';
import { FileManager } from '@maggie/cordova/file';
import type { CollectionType } from '@maggie/store/courseware/collections/types';
import type { CourseType } from '@maggie/store/courseware/courses/types';
import type { LessonType } from '@maggie/store/courseware/lessons/types';
import type { PlaylistItemType } from '@maggie/store/courseware/playlists/types';
import { deepMap } from '@maggie/utils/deepMap';

import { Asset, isAsset, isDefaultAsset } from './lessons/asset';
import { optimizeCloudinaryImageURL } from './optimize';

type EntityType = CourseType | LessonType | CollectionType | PlaylistItemType;

export class OfflineAssets {
  lesson: LessonType;
  course: CourseType;
  collection: CollectionType;
  playlist: PlaylistItemType | undefined;
  downloader: BackgroundDownloader;
  dictAsset: { [path: string]: string };
  assetProgress: { [path: string]: number };
  isCancelled: boolean;

  constructor(
    lesson: LessonType,
    course: CourseType,
    collection: CollectionType,
    playlist: PlaylistItemType | undefined
  ) {
    this.lesson = lesson;
    this.course = course;
    this.collection = collection;
    this.playlist = playlist;
    // Only create a downloader if not in SC Webview
    if (!window.__scWebview.platform) {
      this.downloader = new BackgroundDownloader();
    }
    this.dictAsset = {};
    this.assetProgress = {};
    this.isCancelled = false;
  }

  private static normalizePath(path: string): string {
    if (isDefaultAsset(path) || !isAsset(path)) {
      return path;
    }

    if (window.__scWebview.platform) {
      // SC Webview
      return window.__scWebview.normalizePath(path);
    } else {
      // Cordova
      return FileManager.ionicNormalizeURL(`${FileManager.dataDirectory}${path}`);
    }
  }

  /**
   * Takes a course, lesson or collection and fixes any offline assets with
   * relative paths to have the full path and if iOS an ionic path so that it is
   * compatible with the ionic webview.
   *
   * @static
   * @param {(CourseType | LessonType | CollectionType | PlaylistItemType)} entity
   * @returns
   * @memberof OfflineAssets
   */
  public static normalizeUrls<T extends EntityType>(entity: T): T {
    return deepMap(entity, this.normalizePath);
  }

  /**
   * same as `normalizeUrls` but for a dictionary of entities
   */
  public static normalizeUrlsInDictionary<T extends EntityType>(
    entityDictionary: DictionaryType<T>
  ) {
    // Map across each entity in a dictionary, and fix any relative file URLs
    return mapValues(entityDictionary, value => this.normalizeUrls(value));
  }

  // Based on https://github.com/hughsk/flat
  private deepFlatten = (target: unknown): { [k: string]: any } => {
    const output: Record<string, any> = {};
    const delimiter = '.';
    const step = (object: any, prev?: {}, currentDepth?: number) => {
      const currentD = currentDepth || 1;
      Object.keys(object).forEach(key => {
        const value = object[key];
        const isarray = Array.isArray(value);
        const type = Object.prototype.toString.call(value);
        const isobject = type === '[object Object]' || type === '[object Array]';
        const newKey = prev ? prev + delimiter + key : key;
        if (!isarray && isobject && Object.keys(value).length) {
          return step(value, newKey, currentD + 1);
        } else if (isarray) {
          const newObj = value.reduce((obj: Record<number, any>, item: any, index: number) => {
            obj[index] = item;
            return obj;
          }, {});
          return step(newObj, newKey, currentD + 1);
        }
        output[newKey] = value;
      });
    };
    step(target);
    return output;
  };

  private getAssets = (): Asset[] => {
    // Get all lesson assets
    const flattenConf = this.deepFlatten(this.lesson.configuration);
    const lessonAssets = Object.values(flattenConf)
      .filter(p => isAsset(p) && !isDefaultAsset(p))
      .map((p: string) => new Asset(p, 'lessons', this.lesson.id));

    // Get all course assets
    const flattenedStyleConf = this.deepFlatten(this.course.styleConfiguration);
    const courseAssets = [
      this.course.brandingImage,
      this.course.thumbnail,
      ...Object.values(flattenedStyleConf)
    ]
      .filter(isAsset)
      .map(p => new Asset(p, 'courses', this.course.id));

    // Get all collection assets
    const flattenedCollectionCourses = this.deepFlatten(this.collection.courses);
    const collectionAssets = [
      this.collection.brandingImage,
      this.collection.thumbnail,
      ...Object.values(flattenedCollectionCourses)
    ]
      .filter(isAsset)
      .map(p => new Asset(p, 'collections', this.collection.id));

    // Get all playlist assets
    let playlistAssets: Asset[] = [];
    if (this.playlist) {
      const flattenedPlaylistCourses = this.deepFlatten(this.playlist.courses);
      playlistAssets = [this.playlist.coverImage, ...Object.values(flattenedPlaylistCourses)]
        .filter(isAsset)
        .map(p => new Asset(p, 'playlists', this.playlist!.id));
    }

    // Remove duplicate assets
    return uniqBy(
      lessonAssets.concat(courseAssets, collectionAssets, playlistAssets),
      a => a.remoteURL
    );
  };

  private createSCDownload = async (asset: Asset): Promise<boolean> => {
    const { fileName, remoteURL, folderPath } = asset;

    return new Promise<boolean>(async resolve => {
      try {
        const result = await window.__scWebview.downloadAsset(
          folderPath,
          optimizeCloudinaryImageURL(remoteURL)
        );
        this.dictAsset[remoteURL] = result;
        resolve(true);
      } catch (err) {
        ErrorLogger.captureEvent('Failed downloading asset', 'error', {
          err,
          fileName,
          remoteURL,
          folderPath
        });
        resolve(false);
      }
    });
  };

  private createCordovaDownload = async (
    asset: Asset,
    onProgress: () => void
  ): Promise<boolean> => {
    const { fileName, remoteURL, folderPath } = asset;

    return new Promise<boolean>(async resolve => {
      const onAssetSuccess = () => {
        this.dictAsset[remoteURL] = asset.filePath;
        resolve(true);
      };

      const onAssetError = (err: FileError) => {
        ErrorLogger.captureEvent('Failed downloading asset', 'error', {
          err,
          fileName,
          remoteURL,
          folderPath
        });
        resolve(false);
      };

      const onAssetProgress = (progress: BackgroundTransfer.Progress) => {
        this.assetProgress[remoteURL] = progress.bytesReceived / progress.totalBytesToReceive;
        onProgress();
      };

      // Cordova Download
      this.downloader.download(
        fileName,
        folderPath,
        optimizeCloudinaryImageURL(remoteURL),
        onAssetSuccess,
        onAssetError,
        onAssetProgress
      );
    });
  };

  private createDownload = async (asset: Asset, onProgress: () => void): Promise<boolean> => {
    if (!!window.__scWebview.platform) {
      // SC Webview Download
      return this.createSCDownload(asset);
    } else {
      // Cordova
      return this.createCordovaDownload(asset, onProgress);
    }
  };

  public downloadAssets = async (onProgress: (percentage: number) => void) => {
    const onAssetProgress = () => {
      const totalPercentage =
        Object.values(this.assetProgress).reduce((a, b) => a + b) / assets.length;
      onProgress(totalPercentage);
    };

    // Get all the remote assets for lesson, course and collection
    const assets = this.getAssets();
    // For each asset create a background download promise
    const dls: Promise<boolean>[] = [];
    for (const asset of assets) {
      dls.push(this.createDownload(asset, onAssetProgress));
    }

    return Promise.all(dls);
  };

  public cancel() {
    if (this.isCancelled) {
      return;
    }
    this.downloader.cancel();
    this.isCancelled = true;
  }

  public getOfflineLessonConfiguration = () => {
    const da = { ...this.dictAsset };
    // Map through the lesson configuration and replace assets paths with relative file URLs
    const configuration = deepMap(this.lesson.configuration, (p: string) =>
      isAsset(p) && !isDefaultAsset(p)
        ? da[new Asset(p, 'lessons', this.lesson.id).remoteURL]
        : isAsset(p)
        ? new Asset(p, 'lessons', this.lesson.id).remoteURL
        : p
    );
    return { ...this.lesson, configuration };
  };

  public getOfflineCourseConfiguration = () => {
    const da = { ...this.dictAsset };
    // Map through the course style configuration and replace assets paths with relative file URLs
    const styleConfiguration = !!this.course.styleConfiguration
      ? deepMap(this.course.styleConfiguration, (p: string) =>
          isAsset(p) ? da[new Asset(p, 'courses', this.course.id).remoteURL] : p
        )
      : this.course.styleConfiguration;
    return {
      ...this.course,
      brandingImage: da[new Asset(this.course.brandingImage, 'courses', this.course.id).remoteURL],
      thumbnail: da[new Asset(this.course.thumbnail, 'courses', this.course.id).remoteURL],
      styleConfiguration
    };
  };

  public getOfflineCollectionConfiguration = () => {
    const da = { ...this.dictAsset };
    // Map through the collection course summaries and replace asset paths with relative file URLs
    const courses = this.collection.courses.map(c => {
      return deepMap(c, (p: string) =>
        isAsset(p) ? da[new Asset(p, 'collections', this.collection.id).remoteURL] : p
      );
    });
    return {
      ...this.collection,
      brandingImage:
        da[new Asset(this.collection.brandingImage, 'collections', this.collection.id).remoteURL],
      thumbnail:
        da[new Asset(this.collection.thumbnail, 'collections', this.collection.id).remoteURL],
      courses
    };
  };

  public getOfflinePlaylistConfiguration = () => {
    if (!this.playlist) {
      return undefined;
    }

    const da = { ...this.dictAsset };
    const courses = this.playlist.courses.map(c => {
      return deepMap(c, (p: string) =>
        isAsset(p) ? da[new Asset(p, 'playlists', this.playlist!.id).remoteURL] : p
      );
    });
    return {
      ...this.playlist,
      coverImage: da[new Asset(this.playlist.coverImage, 'playlists', this.playlist.id).remoteURL],
      courses
    };
  };
}
