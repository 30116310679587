import { fork, put, race, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType, ActionsUnion } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { SessionActions } from '@maggie/store/session/actions';

import type { ResetPasswordAction } from './actions';
import { ResetPasswordActionTypes, ResetPasswordActions } from './actions';

type Action<ActionType extends string> = ActionFromActionType<
  ActionsUnion<typeof ResetPasswordActions>,
  ActionType
>;

function* handleResetPassword(action: Action<ResetPasswordActionTypes.RESET_LOST_PASSWORD>) {
  const { email, token, newPassword } = action.payload;
  const resetBody = {
    email,
    token,
    password: newPassword
  };
  try {
    yield put(
      RequestActions.postUnauthed<any, any>(
        Urls.RESET_PASSWORD,
        () => ({
          type: ResetPasswordActionTypes.RESET_LOST_PASSWORD_SUCCESS,
          payload: { email, password: newPassword }
        }),
        err =>
          ResetPasswordActions.resetPasswordFailure(err as { message: string; response: string }),
        undefined,
        resetBody
      )
    );
  } catch (err) {
    yield put(ResetPasswordActions.resetPasswordFailure(err));
  }
}

function* handleResetPasswordSuccess(
  action: Action<ResetPasswordActionTypes.RESET_LOST_PASSWORD_SUCCESS>
): any {
  const { email: username, password } = action.payload;
  yield put(
    RequestActions.postUnauthed(
      Urls.LOGIN,
      ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_SUCCESS,
      ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_FAILURE,
      undefined,
      { username, password }
    )
  );

  const {
    success
  }: {
    success: ResetPasswordAction<ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_SUCCESS>;
  } = yield race({
    success: take(ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_SUCCESS),
    failure: take(ResetPasswordActionTypes.RESET_LOST_PASSWORD_LOGIN_FAILURE)
  });

  if (success) {
    // Clear the query params from the URL
    window.history.replaceState(null, '', `${window.location.href.split('?')[0]}#reset-password`);
    yield put(SessionActions.sessionLogin(success.payload.tokens, { redirect: true }));
  }
}

function* watchResetPassword() {
  yield takeLatest(ResetPasswordActionTypes.RESET_LOST_PASSWORD, handleResetPassword);
}

function* watchResetPasswordSuccess() {
  yield takeLatest(
    ResetPasswordActionTypes.RESET_LOST_PASSWORD_SUCCESS,
    handleResetPasswordSuccess
  );
}

const resetPasswordSagas = [fork(watchResetPassword), fork(watchResetPasswordSuccess)];

export { resetPasswordSagas, handleResetPassword, handleResetPasswordSuccess };
