import { put, race, take, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { ErrorCode, RequestActions } from '@edapp/request';
import { checkOnline } from '@maggie/cordova/network_utils';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { Urls } from '@maggie/store/constants';
import { Redirection } from '@maggie/store/navigation/redirection';
import { UserActionTypes, UserActions } from '@maggie/store/user/actions';

import type { SessionAction } from './actions';
import { SessionActionTypes, SessionActions } from './actions';

type SsoRaceType = {
  ssoSuccess: SessionAction<SessionActionTypes.LOGIN_WITH_SSO_SUCCESS>;
  ssoFailure: SessionAction<SessionActionTypes.LOGIN_WITH_SSO_FAILURE>;
};

function* failLoginWithSSO(ssoToken: string, error: EdErrorResponseType) {
  ErrorLogger.captureEvent('Failed sso login', 'warning', { ssoToken });

  window.__router.navigate('ssoLoginFailure', { query: { error: error?.message } });

  Redirection.removeRedirect();
  HostedWebviewUtils.triggerEvent('loginSSOFailure');
}

function* handleLoginWithSSO(action: SessionAction<SessionActionTypes.LOGIN_WITH_SSO>) {
  const { ssoToken } = action.payload;

  if (!checkOnline()) {
    const action: SessionAction<SessionActionTypes.LOGIN_WITH_SSO_FAILURE> = {
      type: SessionActionTypes.LOGIN_WITH_SSO_FAILURE,
      payload: { type: 'Error', code: ErrorCode.InvalidSsoToken, message: '' }
    };
    yield put(action);
    yield failLoginWithSSO(ssoToken, action.payload);
    return;
  }

  yield put(UserActions.userLogout(false, false));
  yield take(UserActionTypes.USER_DID_LOGOUT); // wait for logout to finish

  yield put(
    RequestActions.postUnauthed(
      Urls.LOGIN_SSO,
      SessionActionTypes.LOGIN_WITH_SSO_SUCCESS,
      SessionActionTypes.LOGIN_WITH_SSO_FAILURE,
      undefined,
      { sso_token: ssoToken }
    )
  );

  const { ssoSuccess, ssoFailure }: SsoRaceType = yield race({
    ssoSuccess: take(SessionActionTypes.LOGIN_WITH_SSO_SUCCESS),
    ssoFailure: take(SessionActionTypes.LOGIN_WITH_SSO_FAILURE)
  });

  if (ssoFailure) {
    yield failLoginWithSSO(ssoToken, ssoFailure.payload);
    return;
  }

  const { tokens } = ssoSuccess.payload;
  yield put(SessionActions.sessionLogin(tokens, { redirect: true }));
  const { failure } = yield race({
    success: take(SessionActionTypes.SESSION_LOGIN_SUCCESS),
    failure: take(SessionActionTypes.SESSION_LOGIN_FAILURE)
  });

  if (failure) {
    yield failLoginWithSSO(ssoToken, failure.payload);
    return;
  }

  HostedWebviewUtils.triggerEvent('loginSSOSuccess');
}

export function* watchLoginWithSSO() {
  yield takeLatest(SessionActionTypes.LOGIN_WITH_SSO, handleLoginWithSSO);
}
