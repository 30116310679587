import { createAction } from '@edapp/utils';
import type { LoadingDialogStateType } from './types';

enum LessonDialogActionTypes {
  OPEN_LESSON_LOADING_DIALOG = 'OPEN_LESSON_LOADING_DIALOG',
  CLOSE_LESSON_LOADING_DIALOG = 'CLOSE_LESSON_LOADING_DIALOG',
  SET_LESSON_LOADING_DIALOG_STATE = 'SET_LESSON_LOADING_DIALOG_STATE'
}

const LessonDialogActions = {
  openLessonLoadingDialog: (title: string) =>
    createAction(LessonDialogActionTypes.OPEN_LESSON_LOADING_DIALOG, { title }),
  closeLessonLoadingDialog: () => createAction(LessonDialogActionTypes.CLOSE_LESSON_LOADING_DIALOG),
  setLessonLoadingDialogState: (state: LoadingDialogStateType) =>
    createAction(LessonDialogActionTypes.SET_LESSON_LOADING_DIALOG_STATE, { state })
};

export { LessonDialogActions, LessonDialogActionTypes };
