import { keyBy } from 'lodash';

import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import { CourseActionTypes } from '../courses/actions';
import type { CourseActionsUnionType } from '../courses/types';
import { DocumentActionTypes } from './actions';
import { initialDocumentsState } from './constants';
import type { DocumentsActionsUnionType, DocumentsState } from './types';

export const documentsReducer = (
  state: DocumentsState = initialDocumentsState,
  action: DocumentsActionsUnionType | CourseActionsUnionType
): DocumentsState => {
  switch (action.type) {
    case CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS: {
      const { briefcaseDocumentsInfo, briefcaseDocumentsProgress } = action.payload;

      const documentsProgress = briefcaseDocumentsInfo.map(({ id }) => {
        const progress = briefcaseDocumentsProgress?.find(doc => doc.briefcaseDocumentId === id);
        if (progress) {
          return progress;
        }

        // create empty progress
        return {
          briefcaseDocumentId: id,
          unlocked: false,
          unlockedDate: null,
          opened: false,
          openedDate: null
        };
      });

      return {
        ...state,
        documents: keyBy(briefcaseDocumentsInfo, 'id'),
        documentsProgress: keyBy(documentsProgress, 'briefcaseDocumentId')
      };
    }

    case DocumentActionTypes.UPDATE_OPEN_DOCUMENT: {
      const documentProgressUpdated = {
        ...state.documentsProgress[action.payload.documentId],
        opened: true,
        openedDate: new Date()
      };

      return {
        ...state,
        documentsProgress: {
          ...state.documentsProgress,
          [action.payload.documentId]: documentProgressUpdated
        }
      };
    }

    case DocumentActionTypes.UPDATE_DOCUMENTS_UNLOCK: {
      const { payload } = action.payload;

      let updatedProgress = {};

      for (const item of payload) {
        updatedProgress = {
          ...updatedProgress,
          [item.id]: {
            ...state.documentsProgress[item.id],
            unlocked: item.unlocked,
            unlockedDate: new Date()
          }
        };
      }

      return {
        ...state,
        documentsProgress: {
          ...state.documentsProgress,
          ...updatedProgress
        }
      };
    }

    case DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL:
      return {
        ...state,
        fetchDocumentDownloadUrlLoading: true,
        fetchDocumentDownloadUrlError: ''
      };

    case DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_SUCCESS:
      return {
        ...state,
        fetchDocumentDownloadUrlLoading: false
      };

    case DocumentActionTypes.FETCH_DOCUMENT_DOWNLOAD_URL_FAILURE:
      const errorMessage = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return {
        ...state,
        fetchDocumentDownloadUrlLoading: false,
        fetchDocumentDownloadUrlError: errorMessage
      };

    default:
      return state;
  }
};
