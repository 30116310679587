import type { Reducer } from 'redux';

import type { ActionsUnion } from '@edapp/utils';
import type { RouteName } from '@maggie/core/router/types';

import type { SplitViewLayoutActions } from './actions';
import { SplitViewLayoutActionTypes } from './actions';
import { initialSplitViewLayoutState } from './constants';
import type { SplitViewLayoutState } from './types';

export const splitViewLayoutReducer: Reducer<SplitViewLayoutState<RouteName>> = (
  state = initialSplitViewLayoutState,
  action: ActionsUnion<typeof SplitViewLayoutActions>
) => {
  switch (action.type) {
    case SplitViewLayoutActionTypes.SET_DETAIL: {
      const { routeName, params } = action.payload;
      return { ...state, detail: { routeName, params } };
    }

    case SplitViewLayoutActionTypes.CLEAR_DETAIL: {
      return { ...state, detail: undefined };
    }

    default: {
      return state;
    }
  }
};
