import * as React from 'react';

import { cloneDeep, merge } from 'lodash-es';
import { ThemeProvider } from 'styled-components';

// eslint-disable-next-line no-restricted-imports
import { ConfigProvider, light, dark, defaultConfig } from '@safetyculture/sc-web-ui';
import type { SchemeType, ThemeCommonType } from '@edapp/themes';
// eslint-disable-next-line no-restricted-imports
import type { Config, Localization, Theme } from '@safetyculture/sc-web-ui';

type ScEdappUIConfigAdapterProps<T> = React.PropsWithChildren<{
  /**
   * Tokens from EdApp theme are needed to override some aspects
   * of the SafetyCulture theme, like the primary colour
   */
  edappTheme: T;
  /**
   * The localization object contains strings used by some SafetyCulture
   * components.
   * Please initialise this object with the i18n library that the
   * app uses. If the app uses i18next, you can use the helper
   * `localisationWithI18next`.
   *
   * @default The default localization object only supports English
   */
  localization?: Localization;
  isUxp: boolean;
  schemeType?: SchemeType;
}>;

export const ScEdappUIConfigAdapter = <T extends ThemeCommonType>({
  children,
  edappTheme,
  localization = defaultConfig.localization,
  isUxp,
  schemeType = 'ed-light'
}: ScEdappUIConfigAdapterProps<T>) => {
  const { colors } = edappTheme;
  const baseTheme = schemeType === 'ed-dark' || schemeType === 'sc-dark' ? dark : light;

  const uiConfig: Config = React.useMemo(
    () => ({
      theme: isUxp
        ? (baseTheme as Theme)
        : merge(cloneDeep(baseTheme), {
            colors: {
              accent: {
                bg: {
                  default: colors.blue,
                  hover: colors.blueHover,
                  pressed: colors.blueActive,
                  weaker: colors.lightBlue,
                  weakerHover: colors.lightBlue,
                  weakerPressed: colors.lightBlue,
                  weakest: colors.lightBlue
                },
                text: {
                  default: colors.textBlue,
                  onWeaker: colors.textBlue
                },
                border: {
                  default: colors.blueActive
                }
              },
              focus: {
                default: colors.blueActive
              }
            }
          } as Theme),
      localization,
      history: undefined
    }),
    [isUxp, colors, baseTheme, localization]
  );

  return (
    <ThemeProvider theme={{ ...(edappTheme as any), _ui: uiConfig.theme as Theme }}>
      <ConfigProvider config={uiConfig}>{children}</ConfigProvider>
    </ThemeProvider>
  );
};
