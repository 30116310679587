import { fork } from 'redux-saga/effects';
import { watchSessionLogin } from './session-login-sagas';
import { watchLoginWithSSO } from './login-sso-sagas';
import { watchRedirectToSSO } from './redirect-to-sso-sagas';
import { watchCheckSSO } from './check-sso-sagas';
import { watchLogin } from './sagas/login-sagas';
import { watchUserVerify } from './sagas/user-verify-sagas';
import { watchLoginCheck } from './sagas/login-check-sagas';
import { watchLoginUserInvited } from './sagas/login-user-invited-sagas';
import { watchForgotPassword } from './sagas/forgot-password-sagas';
import { watchMagicLink } from './sagas/magic-link-sagas';
import { watchLoginFailure } from './sagas/login-failure-sagas';

export const sessionSagas = [
  fork(watchLoginWithSSO),
  fork(watchSessionLogin),
  fork(watchRedirectToSSO),
  fork(watchCheckSSO),
  fork(watchLogin),
  fork(watchLoginFailure),
  fork(watchUserVerify),
  fork(watchLoginCheck),
  fork(watchLoginUserInvited),
  fork(watchForgotPassword),
  fork(watchMagicLink)
];
