import { addDays } from 'date-fns';

import { CourseActionTypes } from '@maggie/store/courseware/courses/actions';
import {
  courseProgressCompleteUpdated,
  courseProgressOpenUpdated,
  courseProgressUnlockUpdated,
  courseRemainingStarsUpdated
} from '@maggie/store/courseware/courses/reducerUtils';
import type { CourseActionsUnionType } from '@maggie/store/courseware/courses/types';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import {
  lessonProgressAttemptFailureUpdated,
  lessonProgressCompleteUpdated,
  lessonProgressEarnedStarsUpdated,
  lessonProgressOpenUpdated,
  lessonProgressUnlockUpdated,
  lessonsProgressReset
} from '@maggie/store/courseware/lessons/reducerUtils';
import type { LessonActionsUnionType } from '@maggie/store/courseware/lessons/types';
import type { PlaylistsActionsUnionType } from '@maggie/store/courseware/playlists/types';
import { StarActionTypes } from '@maggie/store/star/actions';
import type { StarActionUnionType } from '@maggie/store/star/types';

import { OfflineActionTypes } from './actions';
import type { OfflineActionsUnionType, OfflineState } from './types';

const NB_DAYS_EXPIRY = 45;

const initialOfflineState: OfflineState = {
  downloading: false,
  status: {},
  courseware: {
    lessons: {
      lessons: {},
      lessonsProgress: {}
    },
    courses: {
      courses: {},
      coursesProgress: {}
    },
    collections: {},
    playlists: {}
  }
};

const offlineReducer = (
  state = initialOfflineState,
  action:
    | OfflineActionsUnionType
    | LessonActionsUnionType
    | CourseActionsUnionType
    | PlaylistsActionsUnionType
    | StarActionUnionType
): OfflineState => {
  switch (action.type) {
    // Create empty lesson & lessonProgress waiting for data
    case OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE:
      return {
        ...state,
        downloading: true
      };

    case OfflineActionTypes.DOWNLOAD_LESSON_OFFLINE_PROGRESS: {
      const { lessonId, progress } = action.payload;
      return {
        ...state,
        status: {
          ...state.status,
          [lessonId]: {
            ...state.status[lessonId],
            progress
          }
        }
      };
    }

    case OfflineActionTypes.DELETE_LESSON_OFFLINE_SUCCESS: {
      const { lessonId } = action.payload;
      const { [lessonId]: lessonStatus, ...status } = state.status;
      const { [lessonId]: lesson, ...lessons } = state.courseware.lessons.lessons;
      const {
        [lessonId]: lessonProgress,
        ...lessonsProgress
      } = state.courseware.lessons.lessonsProgress;

      const downloading =
        Object.values(status).filter(stat => !stat.progress || stat.progress < 100).length > 0;

      return {
        ...state,
        status,
        downloading,
        courseware: {
          ...state.courseware,
          lessons: {
            lessons,
            lessonsProgress
          }
        }
      };
    }

    case OfflineActionTypes.DELETE_COURSE_OFFLINE_SUCCESS: {
      const { courseId } = action.payload;
      const { [courseId]: course, ...courses } = state.courseware.courses.courses;
      const {
        [courseId]: courseProgress,
        ...coursesProgress
      } = state.courseware.courses.coursesProgress;

      return {
        ...state,
        courseware: {
          ...state.courseware,
          courses: {
            courses,
            coursesProgress
          }
        }
      };
    }

    case OfflineActionTypes.DELETE_COLLECTION_OFFLINE_SUCCESS: {
      const { collectionId } = action.payload;
      const { [collectionId]: collection, ...collections } = state.courseware.collections;

      return {
        ...state,
        courseware: {
          ...state.courseware,
          collections
        }
      };
    }

    case OfflineActionTypes.DELETE_PLAYLIST_OFFLINE_SUCCESS: {
      const { playlistId } = action.payload;
      const { [playlistId]: playlist, ...playlists } = state.courseware.playlists;
      return {
        ...state,
        courseware: {
          ...state.courseware,
          playlists
        }
      };
    }

    case OfflineActionTypes.SAVE_DATA_OFFLINE: {
      const {
        lesson,
        lessonProgresses,
        course,
        courseProgress,
        collection,
        playlist
      } = action.payload;
      const newState = {
        status: {
          ...state.status,
          [lesson.id]: {
            expiryDate: addDays(new Date(), NB_DAYS_EXPIRY).toISOString(),
            progress: 100
          }
        },
        courseware: {
          ...state.courseware,
          lessons: {
            ...state.courseware.lessons,
            lessons: {
              ...state.courseware.lessons.lessons,
              [lesson.id]: lesson
            },
            lessonsProgress: {
              ...state.courseware.lessons.lessonsProgress,
              ...lessonProgresses
            }
          },
          courses: {
            ...state.courseware.courses,
            courses: {
              ...state.courseware.courses.courses,
              [course.id]: course
            },
            coursesProgress: {
              ...state.courseware.courses.coursesProgress,
              [courseProgress.courseId]: courseProgress
            }
          },
          collections: {
            ...state.courseware.collections,
            [collection.id]: collection
          }
        }
      };

      if (!!playlist) {
        newState.courseware.playlists[playlist.id] = playlist;
      }

      return { ...state, ...newState };
    }

    case OfflineActionTypes.ALL_DOWNLOADS_COMPLETED: {
      return {
        ...state,
        downloading: false
      };
    }

    case LessonActionTypes.UPDATE_LESSON_COMPLETED: {
      const { lessonId, score } = action.payload;
      const progress = state.courseware.lessons.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        courseware: {
          ...state.courseware,
          lessons: {
            ...state.courseware.lessons,
            lessonsProgress: {
              ...state.courseware.lessons.lessonsProgress,
              ...lessonProgressCompleteUpdated(progress, lessonId, score)
            }
          }
        }
      };
    }

    case LessonActionTypes.UPDATE_LESSON_OPENED: {
      const { lessonId } = action.payload;
      const progress = state.courseware.lessons.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        courseware: {
          ...state.courseware,
          lessons: {
            ...state.courseware.lessons,
            lessonsProgress: {
              ...state.courseware.lessons.lessonsProgress,
              ...lessonProgressOpenUpdated(progress, lessonId)
            }
          }
        }
      };
    }

    case LessonActionTypes.RESET_LESSONS_PROGRESS: {
      const { lessonIds } = action.payload;
      const lessonsProgress = state.courseware.lessons.lessonsProgress;
      return {
        ...state,
        courseware: {
          ...state.courseware,
          lessons: {
            ...state.courseware.lessons,
            lessonsProgress: {
              ...lessonsProgress,
              ...lessonsProgressReset(lessonsProgress, lessonIds)
            }
          }
        }
      };
    }

    case LessonActionTypes.UPDATE_LESSON_ATTEMPT_FAILURE: {
      const { id: lessonId, score } = action.payload;
      const progress = state.courseware.lessons.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        courseware: {
          ...state.courseware,
          lessons: {
            ...state.courseware.lessons,
            lessonsProgress: {
              ...state.courseware.lessons.lessonsProgress,
              ...lessonProgressAttemptFailureUpdated(progress, lessonId, score)
            }
          }
        }
      };
    }

    case LessonActionTypes.UPDATE_LESSONS_UNLOCK: {
      const { items } = action.payload;
      const lessonsProgress = state.courseware.lessons.lessonsProgress;

      return {
        ...state,
        courseware: {
          ...state.courseware,
          lessons: {
            ...state.courseware.lessons,
            lessonsProgress: {
              ...state.courseware.lessons.lessonsProgress,
              ...lessonProgressUnlockUpdated(lessonsProgress, items)
            }
          }
        }
      };
    }

    case CourseActionTypes.UPDATE_COURSE_OPENED: {
      const { courseId } = action.payload;
      const progress = state.courseware.courses.coursesProgress[courseId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        courseware: {
          ...state.courseware,
          courses: {
            ...state.courseware.courses,
            coursesProgress: {
              ...state.courseware.courses.coursesProgress,
              ...courseProgressOpenUpdated(progress, courseId)
            }
          }
        }
      };
    }

    case CourseActionTypes.UPDATE_COURSE_COMPLETED: {
      const {
        courseId,
        completed,
        percentageCompleted,
        lessonsCompleted,
        lessonsUnlocked
      } = action.payload;
      const progress = state.courseware.courses.coursesProgress[courseId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        courseware: {
          ...state.courseware,
          courses: {
            ...state.courseware.courses,
            coursesProgress: {
              ...state.courseware.courses.coursesProgress,
              ...courseProgressCompleteUpdated(
                progress,
                courseId,
                completed,
                percentageCompleted,
                lessonsCompleted,
                lessonsUnlocked
              )
            }
          }
        }
      };
    }

    case CourseActionTypes.UPDATE_COURSES_UNLOCK: {
      const { items } = action.payload;
      const coursesProgress = state.courseware.courses.coursesProgress;

      return {
        ...state,
        courseware: {
          ...state.courseware,
          courses: {
            ...state.courseware.courses,
            coursesProgress: {
              ...state.courseware.courses.coursesProgress,
              ...courseProgressUnlockUpdated(coursesProgress, items)
            }
          }
        }
      };
    }

    case StarActionTypes.REWARD_STARS_FROM_SLIDE: {
      const { courseId, lessonId, stars } = action.payload;
      const progress = state.courseware.lessons.lessonsProgress[lessonId];

      if (progress == null) {
        return state;
      }

      const course = state.courseware.courses.courses[courseId];

      return {
        ...state,
        courseware: {
          ...state.courseware,
          lessons: {
            ...state.courseware.lessons,
            lessonsProgress: {
              ...state.courseware.lessons.lessonsProgress,
              ...lessonProgressEarnedStarsUpdated(progress, lessonId, stars)
            }
          },
          courses: {
            ...state.courseware.courses,
            courses: {
              ...state.courseware.courses.courses,
              ...courseRemainingStarsUpdated(course, lessonId, stars)
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export { offlineReducer, initialOfflineState };
