import type { LxStoreState } from '@maggie/store/types';
import type { SlideProgressType } from './types';

const getForLesson = (id: string) => (state: LxStoreState) => state.slideProgress[id];

/**
 * Used this to get slides progress of a lesson used by Thomas.
 * For example: SCORM needs this to resume the previous activity after users exit the lesson.
 */
const getSlideProgressForThomas = (lessonId: string) => (state: LxStoreState) => {
  const slidesProgress = getForLesson(lessonId)(state)?.items ?? {};
  const userStateForThomas = Object.keys(slidesProgress).reduce<Record<string, SlideProgressType>>((acc, slideId) => {
    const slideProgress = slidesProgress[slideId];
    acc[slideId] = slideProgress.contentEngineState;
    return acc;
  }, {});
  return userStateForThomas;
};

export const SlideProgressSelectors = {
  getForLesson,
  getSlideProgressForThomas
};
