export function canUseDOM(): boolean {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
}

export function isUserAgentMobile(userAgent?: string): boolean | undefined {
  if (!userAgent) {
    return undefined;
  }

  return !!userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);
}

export enum CloudfrontDeviceHeader {
  MOBILE = 'cloudfront-is-mobile-viewer',
  TABLET = 'cloudfront-is-tablet-viewer',
  DESKTOP = 'cloudfront-is-desktop-viewer'
}

export function includesMobileHeaders(headers: {
  [header: string]: string | string[] | undefined;
}) {
  if (
    [headers[CloudfrontDeviceHeader.MOBILE], headers[CloudfrontDeviceHeader.TABLET]].includes(
      'true'
    )
  ) {
    return true;
  }

  const userAgentHeader = headers['user-agent'];

  if (typeof userAgentHeader === 'string') {
    return isUserAgentMobile(userAgentHeader)!;
  }

  return false;
}

/**
 * If needed this function can also return what IE version
 */
export function isIE(): boolean {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    // return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    return true;
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    // const rv = ua.indexOf('rv:');
    // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    return true;
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    // return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    return true;
  }

  // other browser
  return false;
}

/**
 * Covers both ipad/tablet and mobile phones.
 */
export const isMobileDevice = () => {
  if (!navigator) return false;

  // iPad 6th gen ++
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
    return true;
  }

  return !!isUserAgentMobile(navigator.userAgent);
};
