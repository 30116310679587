import { put } from 'redux-saga/effects';

import { ObservationActions } from '@maggie/store/courseware/observations/actions';
import {
  DEFAULT_OBSERVATION_FILTERS,
  initialObservationObject
} from '@maggie/store/courseware/observations/constants';

export function* handleDidNavigateToObservationResults() {
  yield put(
    ObservationActions.fetchAllObservationSubmissions({ page: 1 }, DEFAULT_OBSERVATION_FILTERS)
  );
  yield put(ObservationActions.setSelectedObservation(initialObservationObject));
}
