import { colors } from './colors';
import { space } from './space';
import { typography } from './typography';
import flexboxgrid from './flexboxgrid';
import { breakpoints } from './breakpoints';

const theme = {
  colors,
  space,
  typography,
  flexboxgrid,
  breakpoints,

  fontSizes: [12, 14, 16, 20, 28, 34],
  fontFamily: "'Noto Sans','San Francisco','Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif",
  fontWeights: [400, 600, 700],
  lineHeights: [14, 16, 20, 22, 32, 40],

  iconSizes: {
    xs: 12,
    sm: 16,
    md: 20,
    lg: 24,
    xl: 32
  },
  buttonPaddings: {
    xs: { x: 8, y: 4 },
    sm: { x: 12, y: 4 },
    md: { x: 16, y: 8 },
    lg: { x: 24, y: 12 },
    xl: { x: 32, y: 16 }
  },
  sizes: {
    borderRadius: 4,
    pillMargin: 2
  },
  transition: {
    slow: '250ms ease-in-out',
    fast: '150ms linear'
  },
  shadows: {
    shallow: '0 2px 8px -2px rgba(0,0,0,0.16)',
    middle: '0 2px 12px -2px rgba(0,0,0,0.32)',
    deep: '0 2px 16px -2px rgba(0,0,0,0.64)'
  },
  dropShadows: {
    shallow: 'drop-shadow(rgba(0,0,0,0.16) 0 2px 8px)',
    middle: 'drop-shadow(rgba(0,0,0,0.32) 0 2px 12px)',
    deep: 'drop-shadow(rgba(0,0,0,0.64) 0 2px 16px)'
  },
  transitionAnimation: {
    bounce: {
      sm: '0.65, 0.26, 0.56, 1.23',
      md: '0.8, 0.5, 0, 1.8',
      lg: '1, 1.5, 0, 2.2'
    }
  },
  lineClamp: (numberOfLines: number) => `
    @supports (-webkit-line-clamp: ${numberOfLines}) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: ${numberOfLines};
      -webkit-box-orient: vertical;
    }
  `,
  navBarHeight: 48
};

export type ThemeType = typeof theme;
export { theme };
