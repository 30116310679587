import type { ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';

enum LessonReviewActionTypes {
  OPEN_LESSON_REVIEW = 'OPEN_LESSON_REVIEW',
  CLOSE_LESSON_REVIEW = 'CLOSE_LESSON_REVIEW',

  REATTEMPT_LESSON = 'REATTEMPT_LESSON'
}

const LessonReviewActions = {
  openLessonReview: (courseId: string, lessonId: string) =>
    createAction(LessonReviewActionTypes.OPEN_LESSON_REVIEW, { courseId, lessonId }),
  closeLessonReview: (id: string) =>
    createAction(LessonReviewActionTypes.CLOSE_LESSON_REVIEW, { id }),
  reattemptLesson: (id: string) => createAction(LessonReviewActionTypes.REATTEMPT_LESSON, { id })
};

type LessonReviewUnionType = ActionsUnion<typeof LessonReviewActions>;

export { LessonReviewActions, LessonReviewActionTypes, LessonReviewUnionType };
