import * as React from 'react';

import type { Props } from './types';

export const SomethingWentWrongSvg: React.FC<Props> = ({ width = 180, height }) => (
  <svg viewBox="0 0 916 903" width={width} height={height} focusable="false">
    <path
      d="M521.872 648.569H459.548H456.907H394.583L369.582 751.488H456.907H459.548H546.696L521.872 648.569Z"
      fill="#6559FF"
    />
    <path
      opacity="0.65"
      d="M387.364 678.176L525.921 666.016L521.872 648.569H394.582L387.364 678.176Z"
      fill="#4740C8"
    />
    <path
      d="M752.331 654.385H163.947C131.025 654.385 104.264 627.598 104.264 594.643V200.416C104.264 167.461 131.025 140.674 163.947 140.674H752.331C785.254 140.674 812.015 167.461 812.015 200.416V594.643C812.015 627.598 785.43 654.385 752.331 654.385Z"
      fill="#6559FF"
    />
    <path
      opacity="0.65"
      d="M458.139 617.906C472.433 617.906 484.02 606.308 484.02 592.001C484.02 577.693 472.433 566.095 458.139 566.095C443.846 566.095 432.259 577.693 432.259 592.001C432.259 606.308 443.846 617.906 458.139 617.906Z"
      fill="#4740C8"
    />
    <path
      d="M751.627 168.872H165.532C148.102 168.872 133.842 183.147 133.842 200.593V511.111C133.842 521.332 142.116 529.791 152.504 529.791H764.655C774.867 529.791 783.317 521.509 783.317 511.111V200.593C783.317 182.97 769.057 168.872 751.627 168.872Z"
      fill="white"
    />
    <path
      opacity="0.65"
      d="M372.575 738.976H543.703L541.238 728.931L372.575 738.976Z"
      fill="#4740C8"
    />
    <path
      d="M568.527 738.976H340.533C328.385 738.976 318.35 747.611 316.061 759.066C315.885 761.709 317.822 764 320.639 764H320.815H588.422H588.598C591.239 764 593.351 761.709 593.175 759.066C590.711 747.611 580.675 738.976 568.527 738.976Z"
      fill="#6559FF"
    />
    <circle
      cx="458.565"
      cy="348.813"
      r="94.9125"
      fill="#FFDBE1"
      stroke="#FF8F96"
      strokeWidth="21.2227"
    />
    <rect x="448.543" y="289.271" width="21.2227" height="84.8907" rx="10.6113" fill="#FF8F96" />
    <rect x="448.543" y="388.311" width="21.2227" height="21.2227" rx="10.6113" fill="#FF8F96" />
  </svg>
);
