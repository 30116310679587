import type { Effect } from 'redux-saga';
import { delay } from 'redux-saga';
import { all, call, put, race, select, spawn, take } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { RouteParams } from '@igorrmotta/typesafe-react-router';
import { routePaths } from '@maggie/core/router/routes';
import type { RouteName } from '@maggie/core/router/types';
import { Urls } from '@maggie/store/constants';
import { NavigationActions } from '@maggie/store/navigation/actions';
import { SCUserId } from '@maggie/store/navigation/sc-user-id';
import type { SessionAction } from '@maggie/store/session/actions';
import { SessionActionTypes, SessionActions } from '@maggie/store/session/actions';
import { redirectAfterSuccess } from '@maggie/store/session/session-login-sagas';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { handleAuthenticateWithSSO } from '../handlers/sso-login-sagas';
import { Redirection } from '../redirection';
import { strictQueryParams } from '../utils';
import type { GlobalRouteQueryParams } from './type';

/**
 * dispatch sso redirection handler
 * @param effects race effect to stop redirection progress
 */

export function* raceSSOAuthenticationProgress(effects: Record<string, Effect>) {
  yield put(SessionActions.ssoAuthenticationInProgress());

  yield race(effects);

  // ensure redirection happened before loading finish
  yield delay(300);
  yield put(SessionActions.ssoAuthenticationFinished());
}

function* isActiveScTrainingSession(paramScUserId?: string) {
  if (!paramScUserId) {
    return false;
  }

  try {
    const scUserId = SCUserId.get();
    const edappUserId: string = yield select<LxStoreState>(UserSelectors.getId);

    if (!scUserId || scUserId !== paramScUserId) {
      SCUserId.set(paramScUserId);
      return false;
    }

    // check if session is still active when param user id is the same with previous.
    yield put(
      RequestActions.getAuthed(
        Urls.ME,
        SessionActionTypes.SESSION_ME_SUCCESS,
        SessionActionTypes.SESSION_ME_FAILURE
      )
    );

    const {
      success
    }: { success: SessionAction<SessionActionTypes.SESSION_ME_SUCCESS> } = yield race({
      success: take(SessionActionTypes.SESSION_ME_SUCCESS),
      failure: take(SessionActionTypes.SESSION_ME_FAILURE)
    });

    if (success && success.payload.userId === edappUserId) {
      return true;
    }

    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

/**
 * @returns `boolean` - tells if there is a redirection
 */
export function* authenticateWithSSO(route: RouteName, params: RouteParams<any>): any {
  const { query } = strictQueryParams<any, GlobalRouteQueryParams>(params);

  if (!query) {
    return false;
  }

  // sso via token
  if (query.token) {
    yield put(SessionActions.ssoAuthenticationInProgress());

    // if other query params are accepted - make sure to replace them inside `storeRedirect`
    // otherwise it will cause infite loop :)
    if (route !== 'ssoLogin') {
      const path = routePaths[route].create(params as any);
      Redirection.storeRedirect(path);
    }

    // check if there is active sc training session
    const hasActiveSession: boolean = yield call(isActiveScTrainingSession, query.sc_user_id);
    if (hasActiveSession) {
      yield put(SessionActions.ssoAuthenticationFinished());
      yield call(redirectAfterSuccess);
      return true;
    }

    /**
     * stop redirection progress, if and only if:
     * - success -> login_with_sso_success AND session_login_success has been dispatched
     * - or, failure -> login_with_sso_failure OR session_login_failure has been dispatched
     */
    yield spawn(raceSSOAuthenticationProgress, {
      success: all([
        take(SessionActionTypes.LOGIN_WITH_SSO_SUCCESS),
        take(SessionActionTypes.SESSION_LOGIN_SUCCESS)
      ]),
      failure: take([
        SessionActionTypes.LOGIN_WITH_SSO_FAILURE,
        SessionActionTypes.SESSION_LOGIN_FAILURE
      ])
    });

    yield put(NavigationActions.didNavigateToSSOLogin(query.token, query.magicLink));
    // NOTE: redirection is handled in login-sso-sagas
    yield call(handleAuthenticateWithSSO, {
      token: query.token,
      magicLink: query.magicLink
    });

    return true;
  }

  // sso via company / business identity provider.
  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );
  const companyId = query.businessId || query.companyId;
  if ((!token || query.forceSSO) && companyId) {
    // if other query params are accepted - make sure to replace them inside `storeRedirect`
    // otherwise it will cause infite loop :)

    if (route !== 'ssoLogin') {
      const path = routePaths[route].create(params as any);
      Redirection.storeRedirect(path);
    }

    /**
     * stop redirection progress, if and only if:
     * - success -> sso_check_success has been dispatched
     * - or, failure -> sso_check_failure has been dispatched
     *
     */
    yield spawn(raceSSOAuthenticationProgress, {
      success: all([
        take(SessionActionTypes.SSO_CHECK_SUCCESS),
        take(SessionActionTypes.REDIRECT_TO_SSO)
      ]),
      failure: take(SessionActionTypes.SSO_CHECK_FAILURE)
    });

    yield put(NavigationActions.didNavigateToSSOLogin(undefined, undefined, companyId));
    // NOTE: redirection is handled in sso-check-sagas and login-sso-sagas
    yield call(handleAuthenticateWithSSO, { companyId });

    return true;
  }

  return false;
}
