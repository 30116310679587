import { createAction } from '@edapp/utils';
import type { LessonActivityType } from '@maggie/store/courseware/lessons/types';

import type { RedirectParams } from './types';

export enum LockedDialogActionTypes {
  OPEN_LOCKED_DIALOG = 'OPEN_LOCKED_DIALOG',
  CLOSE_LOCKED_DIALOG = 'CLOSE_LOCKED_DIALOG',

  OPEN_LOCKED_LESSON = 'OPEN_LOCKED_LESSON',
  OPEN_LOCKED_COURSE = 'OPEN_LOCKED_COURSE',
  OPEN_LOCKED_RAPID_REFRESH = 'OPEN_LOCKED_RAPID_REFRESH',
  OPEN_LOCKED_BRIEFCASE = 'OPEN_LOCKED_BRIEFCASE'
}

export const LockedDialogActions = {
  openLockedDialog: (
    isOpen: boolean,
    title: string,
    contentText: string,
    redirectParams: RedirectParams = { shouldRedirect: false },
    acceptText?: string
  ) =>
    createAction(LockedDialogActionTypes.OPEN_LOCKED_DIALOG, {
      isOpen,
      title,
      contentText,
      redirectParams,
      acceptText
    }),
  closeLockedDialog: () => createAction(LockedDialogActionTypes.CLOSE_LOCKED_DIALOG),

  /**
   * @param {string} lockedLessonId
   * @param {boolean} redirectParams - tells if acknowledging locked lesson redirects to specified route
   */
  openLockedLesson: (
    shouldOpenDialog: boolean,
    lockedLessonId: string,
    lessonType: LessonActivityType,
    redirectParams: RedirectParams
  ) =>
    createAction(LockedDialogActionTypes.OPEN_LOCKED_LESSON, {
      shouldOpenDialog,
      lockedLessonId,
      lessonType,
      redirectParams
    }),

  openLockedRapidRefresh: (courseId: string, session: number) =>
    createAction(LockedDialogActionTypes.OPEN_LOCKED_RAPID_REFRESH, { courseId, session }),

  /**
   * @param {boolean} shouldOpenDialog - display dialog or just set the locked overlay info
   * @param {string} lockedCourseId
   * @param {boolean} redirectParams - tells if acknowledging locked lesson redirects to `home`
   */
  openLockedCourse: (
    shouldOpenDialog: boolean,
    lockedCourseId: string,
    redirectParams: RedirectParams
  ) =>
    createAction(LockedDialogActionTypes.OPEN_LOCKED_COURSE, {
      shouldOpenDialog,
      lockedCourseId,
      redirectParams
    }),
  openLockedBriefcase: (lockedBriefcaseId: string) =>
    createAction(LockedDialogActionTypes.OPEN_LOCKED_BRIEFCASE, { lockedBriefcaseId })
};
