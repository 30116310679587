export const globalZIndex = {
  ACHIEVEMENTS: 1039,
  CONFERENCE: 1038,
  APP_UPDATE: 99999,
  PRIVACY_DIALOG: 99999999,
  APP_LAUNCH: 9999999,
  BRAIN_BOOST_TOAST: 11,
  BRAIN_BOOST_SUMMARY: 12,
  HOME_TABS: 99999,
  LESSON_LOADING_DIALOG: 999999,
  INDIVIDUAL_LEARNER_CARD_MENU: 2,
  ABOVE_THE_SKY: 999999999999,
  POST_HEADER: 10000,
  VIEW_ONLY_BANNER: 11
};
