import * as React from 'react';
import { rgba } from '@edapp/themes';
import { format } from 'date-fns';
import styled, { css } from 'styled-components';
import { EdTypography, Box, ChevronDownIcon, ChevronUpIcon, StatusLabel } from '@edapp/ed-components';
import { getDateFormat, getStatusColor } from '../utils';
import type { ObservationStatus } from '../constants';

type Props = {
  name: string;
  observationName: string;
  courseName: string;
  assessor?: string;
  status?: ObservationStatus;
  dateCreated?: string;
  expandable?: boolean;
  timeZone?: string;
};

export const FormHeader: React.FC<Props> = ({
  name,
  observationName,
  courseName,
  assessor,
  status,
  dateCreated,
  expandable = true,
  timeZone,
  ...rest
}) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const statusObject = React.useMemo(() => {
    if (status) {
      return getStatusColor(status);
    }
  }, [status]);
  const today = format(new Date(), 'do MMM, yyyy');
  const onToggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  return (
    <FullWidthBox px="md" bgColor="white" flex justifyContent="center" expandable={expandable}>
      <HeaderContent bgColor="white" onClick={expandable ? onToggleShowDetail : undefined}>
        <Box py="sm" textAlign="center" flex alignItems="center" {...rest}>
          <EdTypography variant="subtitle1">{name}</EdTypography>
          {expandable && (
            <Box ml="xs">
              {!showDetail ? (
                <ChevronDownIcon color="text" size="xs" />
              ) : (
                <ChevronUpIcon color="text" size="xs" />
              )}
            </Box>
          )}
        </Box>
        {(showDetail || !expandable) && (
          <Box pb="sm">
            <Box pb={0.5} flex justifyContent="space-between">
              <Box>
                <EdTypography pr={1} color="textMuted">
                  Practical assessment
                </EdTypography>
              </Box>
              <Box>
                <TextContent lineClamp={1}>{observationName}</TextContent>
              </Box>
            </Box>
            <Box pb={0.5} flex justifyContent="space-between">
              <Box>
                <EdTypography pr={1} color="textMuted">
                  Course
                </EdTypography>
              </Box>
              <Box>
                <TextContent lineClamp={1}>{courseName}</TextContent>
              </Box>
            </Box>
            <Box pb={0.5} flex justifyContent="space-between">
              <Box>
                <EdTypography pr={1} color="textMuted">
                  Start date
                </EdTypography>
              </Box>
              <Box>
                <TextContent lineClamp={1}>
                  {dateCreated ? getDateFormat(dateCreated, timeZone) : today}
                </TextContent>
              </Box>
            </Box>
            {!!assessor && (
              <Box pb={0.5} flex justifyContent="space-between">
                <Box>
                  <EdTypography pr={1} color="textMuted">
                    Assessor
                  </EdTypography>
                </Box>
                <Box>
                  <TextContent lineClamp={1}>{assessor}</TextContent>
                </Box>
              </Box>
            )}
            {!!statusObject && (
              <Box pb={0.5} flex justifyContent="space-between">
                <Box>
                  <EdTypography pr={1} color="textMuted">
                    Status
                  </EdTypography>
                </Box>
                <Box>
                  <StatusLabel
                    background={statusObject.color}
                    text={statusObject.color}
                    label={statusObject.text}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </HeaderContent>
    </FullWidthBox>
  );
};

const FullWidthBox = styled(Box)<{ expandable: boolean }>(
  ({ expandable, theme }) => css`
    width: 100%;
    min-width: 250px;
    z-index: 100;
    ${expandable &&
    `cursor: pointer;
    box-shadow: 0px 8px 16px 0px ${rgba(theme.colors.fixedBlack, 0.04)};`}
  `
);

const HeaderContent = styled(Box)`
  width: 100%;
  max-width: ${({ theme }) => theme.flexboxgrid.breakpoints.lg}rem;
`;

const TextContent = styled(EdTypography)`
  word-break: break-all;
`;
