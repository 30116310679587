import { fork, put, race, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import type { ActionFromActionType } from '@edapp/utils';
import { RegisterActions, RegisterActionsTypes } from '@maggie/store/register/actions';
import { SessionActionTypes, SessionActions } from '@maggie/store/session/actions';
import type { SessionAction } from '@maggie/store/session/actions';

import { InviteCodeSignUpActionTypes, InviteCodeSignupActions } from './action';
import type { InviteCodeSignUpUnionType } from './action';

type Action<A extends string> = ActionFromActionType<InviteCodeSignUpUnionType, A>;

type UserVerifySuccess = SessionAction<SessionActionTypes.USER_VERIFY_SUCCESS>;
type UserVerifyFailure = SessionAction<SessionActionTypes.USER_VERIFY_FAILURE>;

function* handleInviteCodeSignUp(
  action: Action<InviteCodeSignUpActionTypes.VERIFY_INVITE_CODE_SIGN_UP>
) {
  const { email, inviteCode } = action.payload;

  // Guard, if email or inviteCode is blank or null or undefined
  if (!email || !inviteCode) {
    return;
  }
  yield put(SessionActions.userVerify(email));

  const {
    success,
    failure
  }: { success: UserVerifySuccess; failure: UserVerifyFailure } = yield race({
    success: take(SessionActionTypes.USER_VERIFY_SUCCESS),
    failure: take(SessionActionTypes.USER_VERIFY_FAILURE)
  });

  if (failure) {
    // see reducer for failure
    return;
  }
  const { companyId, exists } = success.payload;

  /**
   * if companyId exists, don't do anything, will be redirecter
   * see: react-workspaces/apps/maggie/src/store/session/sagas/user-verify-sagas.ts
   */
  if (companyId) {
    itly.failedSignUpSso();
    return;
  }

  // if email exist, don't do anything, let user fix it
  if (exists) {
    yield put(InviteCodeSignupActions.verifyEmailExists());
    itly.failedSignUpEmailExists();
    return;
  }

  yield put(RegisterActions.checkInviteCode(inviteCode));

  // see reducer for failure
  const { success: checkInviteCodeSuccess } = yield race({
    success: take(RegisterActionsTypes.CHECK_INVITE_CODE_SUCCESS),
    failure: take(RegisterActionsTypes.CHECK_INVITE_CODE_FAILURE)
  });

  if (checkInviteCodeSuccess) {
    const code = encodeURIComponent(inviteCode);
    itly.enterInviteCodeOnCreateAccount({ code, email });

    yield put(InviteCodeSignupActions.reset());
    window.__router.navigate('register', { code, query: { email } });
  }
}

function* watchInviteCodeSignUp() {
  yield takeLatest(InviteCodeSignUpActionTypes.VERIFY_INVITE_CODE_SIGN_UP, handleInviteCodeSignUp);
}

const inviteCodeSignUpSagas = [fork(watchInviteCodeSignUp)];

export { inviteCodeSignUpSagas, watchInviteCodeSignUp };
