import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { CourseActionTypes, CourseActions } from '../courses/actions';
import { CourseSelectors } from '../courses/selectors';
import type { UnlockPayload } from '../types';
import { PlaylistsActionTypes, PlaylistsActions } from './actions';
import { watchUpdatePlaylistNextItem, watchUpdatePlaylistNextItemSuccess } from './next-item-sagas';
import type {
  FetchPlaylistsResponse,
  PlaylistAction,
  PlaylistItemType,
  PlaylistsActionsUnionType
} from './types';
import { PlaylistUtils } from './utils';

type Action<ActionType extends string> = ActionFromActionType<
  PlaylistsActionsUnionType,
  ActionType
>;

type FetchPlaylistsResponseType = {
  success: Action<PlaylistsActionTypes.FETCH_PLAYLISTS_SUCCESS>;
  failure: Action<PlaylistsActionTypes.FETCH_PLAYLISTS_FAILURE>;
};

type FetchPlaylistItemResponseType = {
  success: Action<PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_SUCCESS>;
  failure: Action<PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_FAILURE>;
};

function* handleFetchPlaylists() {
  const user: LxStoreState['user'] = yield select<LxStoreState>(s => s.user);

  yield put(
    RequestActions.getAuthed<FetchPlaylistsResponse>(
      Urls.PLAYLISTS,
      PlaylistsActionTypes.FETCH_PLAYLISTS_SUCCESS,
      PlaylistsActionTypes.FETCH_PLAYLISTS_FAILURE,
      undefined,
      {
        includeDrafts: UserSelectors.isReviewer(user),
        includeCourses: true,
        includeSequentialPlaylists: true
      }
    )
  );

  const { success }: FetchPlaylistsResponseType = yield race({
    success: take(PlaylistsActionTypes.FETCH_PLAYLISTS_SUCCESS),
    failure: take(PlaylistsActionTypes.FETCH_PLAYLISTS_FAILURE)
  });

  if (success) {
    const playlistIds = success.payload.items.map(p => p.id);
    if (playlistIds && playlistIds.length > 0) {
      yield put(PlaylistsActions.fetchPlaylistProgresses(playlistIds));
      yield race({
        success: take(PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES_SUCCESS),
        failure: take(PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES_FAILURE)
      });
    }
  }

  yield put(PlaylistsActions.fetchPlaylistsCompleted());
}

function* handleFetchPlaylistItem(
  action: PlaylistAction<PlaylistsActionTypes.FETCH_PLAYLIST_ITEM>
) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed(
      Urls.PLAYLIST_ITEM(id),
      PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_SUCCESS,
      PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_FAILURE
    )
  );

  const { success }: FetchPlaylistItemResponseType = yield race({
    success: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_SUCCESS),
    failure: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_FAILURE)
  });

  if (success) {
    const playlistId = success.payload.id;
    if (playlistId) {
      yield put(PlaylistsActions.fetchPlaylistProgresses([playlistId]));
      yield race({
        success: take(PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES_SUCCESS),
        failure: take(PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES_FAILURE)
      });
    }
  }
}

function* handleFetchPlaylistProgresses(
  action: PlaylistAction<PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES>
) {
  const { ids } = action.payload;

  yield put(
    RequestActions.postAuthed(
      Urls.PLAYLIST_PROGRESSES,
      PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES_SUCCESS,
      PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES_FAILURE,
      undefined,
      { ids }
    )
  );
}

function* handleFetchPlaylistWithProgress(
  action: PlaylistAction<PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS>
) {
  yield put(PlaylistsActions.fetchPlaylistItem(action.payload.id));

  const { playlistAction, failure } = yield race({
    playlistAction: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_SUCCESS),
    failure: take(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_FAILURE)
  });

  if (failure) {
    yield put({
      type: PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS_FAILURE,
      payload: failure as EdErrorResponseType
    });
    return;
  }

  const playlist = playlistAction.payload as PlaylistItemType;

  // Get Progress
  const courseIds = playlist.courses.map(c => c.courseId);
  const prereqIds = PlaylistUtils.getPlaylistPrerequisitesIds(playlist);

  if (courseIds.length > 0 || prereqIds.length > 0) {
    yield put(CourseActions.fetchCoursesProgress([...courseIds, ...prereqIds]));

    const { failureProgress } = yield race({
      coursesProgress: take(CourseActionTypes.FETCH_COURSES_PROGRESS_SUCCESS),
      failureProgress: take(CourseActionTypes.FETCH_COURSES_PROGRESS_FAILURE)
    });

    if (failureProgress) {
      yield put({
        type: PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS_FAILURE,
        payload: failureProgress.payload as EdErrorResponseType
      });
      return;
    }
  }

  // Check prereq
  const items: UnlockPayload[] = yield select(
    CourseSelectors.getUnlockPayloadFromPrerequisitesForCourseSummaries(playlist.courses)
  );
  yield put(CourseActions.updateCoursesUnlock(items));

  yield put({
    type: PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS_SUCCESS,
    payload: playlist
  });
}

function* watchFetchPlaylists() {
  yield takeLatest(PlaylistsActionTypes.FETCH_PLAYLISTS, handleFetchPlaylists);
}

function* watchFetchPlaylistItem() {
  yield takeLatest(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM, handleFetchPlaylistItem);
}

function* watchFetchPlaylistItemWithProgress() {
  yield takeLatest(
    PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS,
    handleFetchPlaylistWithProgress
  );
}

function* watchFetchPlaylistProgresses() {
  yield takeLatest(PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES, handleFetchPlaylistProgresses);
}

const playlistsSagas = [
  fork(watchFetchPlaylists),
  fork(watchFetchPlaylistItem),
  fork(watchFetchPlaylistItemWithProgress),
  fork(watchFetchPlaylistProgresses),
  fork(watchUpdatePlaylistNextItem),
  fork(watchUpdatePlaylistNextItemSuccess)
];

export { playlistsSagas };
