/**
 * The custom CSS styling that gets applied to a Spaced Repetition Lesson (Brain Boost)
 */
export const SpacedRepetitionCSS = `\
/* Reused Styles  */ \
.block-dk { \
background: rgba(255, 251, 251, 0.2); \
} \
\
.btn.btn-solid { \
background: #00092f; \
color: #00adff; \
border: 2px solid #00adff; \
} \
\
.btn.btn-solid.__active { \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
background: #00092f; \
border: 2px solid #00adff; \
} \
\
.btn:before { \
color: #00adff !important; \
} \
\
.draggy.active .btn { \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
background: #00092f; \
} \
\
/* Slide Specific Styles */ \
.slide-dial-distribution .dial-ring { \
border: 4em solid rgba(251, 245, 245, 0.42); \
} \
\
.slide-dial .dial-ring { \
border: 4em solid rgba(251, 245, 245, 0.42); \
} \
\
.slide-expandable-list .item.active .item-button { \
background: #00adff2b; \
} \
\
.slide-game-true-or-false .game-statement { \
background: #00adff2b; \
color: #00adff; \
border: 2px solid #00adff; \
} \
\
.slide.slide-connect { \
background: radial-gradient(rgba(0, 167, 249, 0.41), transparent 82%) no-repeat; \
background-size: 100%; \
} \
\
.slide-expandable-list .item-button:after { \
color: #00adff; \
} \
\
.slide-expandable-list .item.active .item-button:after { \
color: #00adff; \
} \
\
.slide-circle-the-answer.state-complete .circle-box.active.incorrect .circle-box-text { \
color: #00adff; \
} \
\
.slide-circle-the-answer.state-complete .circle-box.active.correct .circle-box-text { \
color: #00adff; \
} \
\
.slide-categorise .btn.btn-solid { \
background: #00092f; \
color: #00adff; \
border: 2px solid #00adff; \
} \
\
.slide-missing-word .draggy.slide-up.is-positioned { \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
background: #00092f; \
} \
\
.slide-missing-word .draggy { \
background: #00092f; \
color: #00adff; \
border: 2px solid #00adff; \
} \
\
.slide-connect .connect-box { \
background: transparent; \
color: #00adff; \
border: 2px solid #00adff; \
} \
\
.slide-connect .connect-box.active { \
background: transparent; \
color: #00adff; \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
border: 2px solid #00adff; \
} \
\
.slide-connect .connect-box.complete { \
background: #00adff33; \
color: #00adff; \
border: 2px solid #00adff; \
} \
\
.slide-circle-the-answer .circle-box.active .circle-box-text { \
background: transparent; \
color: #00adff; \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
border: 2px solid #00adff; \
} \
\
.slide-slider-survey .slider-knob { \
background: black; \
color: #00adff; \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
border: 2px solid #00adff; \
} \
\
.slide-game-memory .btn.btn-solid { \
color: #333; \
border: 1px solid rgba(0,0,0,0.2); \
background-color: #fafafa; \
} \
\
#slide-answer-continue.btn.btn-solid { \
color: #333; \
border: 1px solid rgba(0,0,0,0.2); \
background-color: #fafafa; \
} \
\
.slide-carousel.state-complete .carousel-item.active.carousel-item-correct .carousel-btn { \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
} \
\
.selectable.active .btn { \
box-shadow: rgb(0, 225, 245) 1px 0px 20px 1px; \
background: #00092f; \
} \
\
.slide-comparison .slider-item-0 .multi-content-text { \
background: linear-gradient(to right, rgba(253, 251, 251, 0.12), rgba(0,0,0,0.06)); \
} \
\
.slide-comparison .slider-item-1 .multi-content-text { \
background: linear-gradient(to left, rgba(253, 251, 251, 0.12), rgba(0,0,0,0.06)); \
} \
\
.slide-pie-chart .pie-ring { \
stroke: #fbfcfd70; \
} \
\
.slide-ratio .ratio-bar { \
background-color: #00adff; \
} \
\
.slide-ratio .ratio-bar-container { \
background-color: #00adff57; \
border-color: #00adff57; \
} \
\
.slide-strikeout .word.active { \
background:#00adff2b; \
color: #00adff; \
border: 2px solid #00adff; \
} \
\
.slide-slider .slider-bar, .slide-slider-survey .slider-bar { \
background: #f2f5f773; \
}\
`;
