import { createAction } from '@edapp/utils';

enum GameActionTypes {
  FETCH_GAME_DATA = 'FETCH_GAME_DATA',
  FETCH_GAME_DATA_SUCCESS = 'FETCH_GAME_DATA_SUCCESS',
  FETCH_GAME_DATA_FAILURE = 'FETCH_GAME_DATA_FAILURE',
  BID_ON_AUCTION = 'BID_ON_AUCTION',
  PRIZE_CLAIMED = 'PRIZE_CLAIMED'
}

const GameActions = {
  fetchGameData: () => createAction(GameActionTypes.FETCH_GAME_DATA),
  bidOnAuction: ({ auctionId, stars }: { auctionId: string; stars: number }) =>
    createAction(GameActionTypes.BID_ON_AUCTION, { auctionId, stars }),
  removeClaimedPrize: ({ roundId }: { roundId: string }) =>
    createAction(GameActionTypes.PRIZE_CLAIMED, { roundId })
};

export { GameActionTypes, GameActions };
