import { select } from 'redux-saga/effects';

import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { LessonSummaryType } from '@maggie/store/courseware/courses/types';
import type { DocumentType } from '@maggie/store/courseware/documents/types';
import { LessonUtils } from '@maggie/store/courseware/lessons/utils';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidMainNavigateToCourse(courseId: string) {
  // select last opened lesson/document or first lesson
  const firstItem: LessonSummaryType | DocumentType | undefined = yield select<LxStoreState>(
    CourseSelectors.getLastOpenedForCourse(courseId)
  );
  if (!!firstItem) {
    firstItem.typeName === 'BriefcaseDocument'
      ? window.__router.navigate('briefcaseDocument', { id: courseId, documentId: firstItem.id })
      : LessonUtils.navigate(firstItem.lessonId, firstItem.lessonType);
  }
}
