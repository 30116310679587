import { put, select } from 'redux-saga/effects';

import ConfigActions from '@maggie/store/config/actions';
import type { LoginRouteError } from '@maggie/store/session/types';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

export function* handleDidNavigateToLogin(error?: LoginRouteError): any {
  if (!!error) {
    yield put(ConfigActions.setRestrictToCourseScreen(false));
    yield put(ConfigActions.setRestrictToLessonScreen(false));
    return;
  }

  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );
  if (!!token) {
    window.__router.navigate('home', {});
    return;
  }
}
