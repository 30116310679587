import { put, race, take, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import type { SessionAction } from '../actions';
import { SessionActionTypes, SessionActions } from '../actions';

function* handleLoginCheck(action: SessionAction<SessionActionTypes.LOGIN_CHECK>) {
  const { username } = action.payload;

  yield put(SessionActions.userVerify(username));

  const { success, failure } = yield race({
    success: take(SessionActionTypes.USER_VERIFY_SUCCESS),
    failure: take(SessionActionTypes.USER_VERIFY_FAILURE)
  });

  if (!!success) {
    if (!success.payload.companyId) {
      yield put(SessionActions.setLoginFormStep('show-password'));
    }
    yield put(SessionActions.loginCheckSuccess());
  } else {
    ErrorLogger.captureEvent('Unable to verify users', 'warning', failure);
    const error: EdErrorResponseType = {
      type: 'Error',
      code: ErrorCode.NotSpecified, // error-server
      message: getErrorMessage()
    };
    yield put(SessionActions.loginCheckFailure(error));
  }
}

export function* watchLoginCheck() {
  yield takeLatest(SessionActionTypes.LOGIN_CHECK, handleLoginCheck);
}
