import type { ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { LessonActivityType } from '@maggie/store/courseware/lessons/types';

enum ActivityActionTypes {
  DID_OPEN_LESSON_ACTIVITY = 'DID_OPEN_LESSON_ACTIVITY',
  DID_OPEN_LESSON_ACTIVITY_COMPLETED = 'DID_OPEN_LESSON_ACTIVITY_COMPLETED'
}

const LessonActivityActions = {
  /**
   * @param {string} id
   * @param {LessonActivityType} type
   */
  didOpenLessonActivity: (id: string, type: LessonActivityType) =>
    createAction(ActivityActionTypes.DID_OPEN_LESSON_ACTIVITY, { id, type }),

  didOpenLessonActivityCompleted: () =>
    createAction(ActivityActionTypes.DID_OPEN_LESSON_ACTIVITY_COMPLETED)
};

type LessonActivityActionsUnionType = ActionsUnion<typeof LessonActivityActions>;

export { ActivityActionTypes, LessonActivityActions, LessonActivityActionsUnionType };
