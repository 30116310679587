import { select, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { Tracker } from '@maggie/core/tracker/tracker';
import type { LxStoreState } from '@maggie/store/types';

import { LessonActionTypes } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonAction } from './types';

function* handleUpdateLessonsUnlock(action: LessonAction<LessonActionTypes.UPDATE_LESSONS_UNLOCK>) {
  const { items } = action.payload;

  const storeState: LxStoreState = yield select(s => s);

  items.forEach(({ id, title, unlocked }) => {
    const progress = LessonSelectors.getLessonProgress(id, storeState);
    const lesson = LessonSelectors.getLesson(id, storeState);

    // only call interaction if unlocked happened for the first time
    if (!!unlocked && progress?.unlocked !== unlocked) {
      Tracker.trackEvent({
        type: 'event',
        name: 'lesson-unlocked',
        value: title,
        id,
        courseVersionNumber: lesson?.courseVersionNumber
      });
      itly.lessonUnlocked({
        value: title,
        id
      });
    }
  });
}

export function* watchUpdateLessonsUnlock() {
  yield takeLatest(LessonActionTypes.UPDATE_LESSONS_UNLOCK, handleUpdateLessonsUnlock);
}
