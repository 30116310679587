import type { Action } from 'redux';

import type { ErrorResponseType, RelativeToken } from '@edapp/request';
import type { ActionFromActionType, ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';

import type {
  LoginFormStepType,
  LoginResponseType,
  LoginSsoResponse,
  MeResponseType,
  SessionLoginOptions,
  SsoCheckResponse,
  UserVerifyResponse
} from './types';

export enum SessionActionTypes {
  // Generic
  SESSION_LOGIN = 'SESSION_LOGIN',
  SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS',
  SESSION_LOGIN_FAILURE = 'SESSION_LOGIN_FAILURE',

  SESSION_ME_SUCCESS = 'SESSION_ME_SUCCESS',
  SESSION_ME_FAILURE = 'SESSION_ME_FAILURE',

  USER_VERIFY = 'USER_VERIFY',
  USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS',
  USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE',

  // SSO Login
  REDIRECT_TO_SSO = 'REDIRECT_TO_SSO',
  SSO_AUTHENTICATION_IN_PROGRESS = 'SSO_AUTHENTICATION_IN_PROGRESS',
  SSO_AUTHENTICATION_FINISHED = 'SSO_AUTHENTICATION_FINISHED',

  LOGIN_WITH_SSO = 'LOGIN_WITH_SSO',
  LOGIN_WITH_SSO_SUCCESS = 'LOGIN_WITH_SSO_SUCCESS',
  LOGIN_WITH_SSO_FAILURE = 'LOGIN_WITH_SSO_FAILURE',

  SSO_CHECK = 'SSO_CHECK',
  SSO_CHECK_SUCCESS = 'SSO_CHECK_SUCCESS',
  SSO_CHECK_FAILURE = 'SSO_CHECK_FAILURE',

  SSO_RESET = 'SSO_RESET',
  // Login
  LOGIN = 'LOGIN',
  LOGIN_USER_INVITED = 'LOGIN_USER_INVITED',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGIN_CHECK = 'LOGIN_CHECK',
  LOGIN_CHECK_SUCCESS = 'LOGIN_CHECK_SUCCESS',
  LOGIN_CHECK_FAILURE = 'LOGIN_CHECK_FAILURE',

  CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR',

  MAGIC_LINK = 'MAGIC_LINK',
  MAGIC_LINK_SUCCESS = 'MAGIC_LINK_SUCCESS',
  MAGIC_LINK_FAILURE = 'MAGIC_LINK_FAILURE',

  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE',

  SET_LOGIN_FORM_STEP = 'SET_LOGIN_FORM_STEP',

  SET_LOGIN_SLIDER_PAGE_INDEX = 'SET_LOGIN_SLIDER_PAGE_INDEX'
}

export const SessionActions = {
  sessionLogin: (tokens: RelativeToken[], options: SessionLoginOptions) =>
    createAction(SessionActionTypes.SESSION_LOGIN, { tokens, options }),
  sessionLoginSuccess: () => createAction(SessionActionTypes.SESSION_LOGIN_SUCCESS),
  sessionLoginFailure: () => createAction(SessionActionTypes.SESSION_LOGIN_FAILURE),

  /**
   * Checks for username auto detection, user exists, etc...
   */
  userVerify: (username: string) => createAction(SessionActionTypes.USER_VERIFY, { username }),

  /**
   * redirects user to external sso page - microsoft, miniorange, etc
   */
  redirectToSSO: (code: string, openSsoExternally: boolean) =>
    createAction(SessionActionTypes.REDIRECT_TO_SSO, { code, openSsoExternally }),

  ssoAuthenticationInProgress: () =>
    createAction(SessionActionTypes.SSO_AUTHENTICATION_IN_PROGRESS),
  ssoAuthenticationFinished: () => createAction(SessionActionTypes.SSO_AUTHENTICATION_FINISHED),
  /**
   * default with no redirection after session login
   */
  loginWithSSO: (ssoToken: string) => createAction(SessionActionTypes.LOGIN_WITH_SSO, { ssoToken }),

  /**
   * Check for businessId to see if there is an identity provider to auth with
   */
  ssoCheck: (businessId: string) => createAction(SessionActionTypes.SSO_CHECK, { businessId }),
  ssoReset: () => createAction(SessionActionTypes.SSO_RESET),

  /**
   * Authenticate user with edapp
   */
  login: (username: string, password: string) =>
    createAction(SessionActionTypes.LOGIN, { username, password }),
  loginUserInvited: (username: string, app: string) =>
    createAction(SessionActionTypes.LOGIN_USER_INVITED, { username, app }),
  loginFailure: (error: EdErrorResponseType, from: string) =>
    createAction(SessionActionTypes.LOGIN_FAILURE, { error, from }),

  /**
   * Login check before calling actual login
   */
  loginCheck: (username: string) => createAction(SessionActionTypes.LOGIN_CHECK, { username }),
  loginCheckSuccess: () => createAction(SessionActionTypes.LOGIN_CHECK_SUCCESS, {}),
  loginCheckFailure: (error: EdErrorResponseType) =>
    createAction(SessionActionTypes.LOGIN_CHECK_FAILURE, { error }),

  magicLink: (username: string) => createAction(SessionActionTypes.MAGIC_LINK, { username }),
  magicLinkSuccess: () => createAction(SessionActionTypes.MAGIC_LINK_SUCCESS, {}),
  magicLinkFailure: () => createAction(SessionActionTypes.MAGIC_LINK_FAILURE, {}),

  forgotPassword: (username: string) =>
    createAction(SessionActionTypes.FORGOT_PASSWORD, { username }),
  forgotPasswordSuccess: () => createAction(SessionActionTypes.FORGOT_PASSWORD_SUCCESS, {}),
  forgotPasswordFailure: () => createAction(SessionActionTypes.FORGOT_PASSWORD_FAILURE, {}),

  setLoginFormStep: (formStep: LoginFormStepType) =>
    createAction(SessionActionTypes.SET_LOGIN_FORM_STEP, { formStep })
};

export type SessionActionsUnionType =
  | ActionsUnion<typeof SessionActions>
  | ActionWithPayload<SessionActionTypes.USER_VERIFY_SUCCESS, UserVerifyResponse>
  | ActionWithPayload<SessionActionTypes.USER_VERIFY_FAILURE, EdErrorResponseType>
  | ActionWithPayload<SessionActionTypes.SSO_CHECK_SUCCESS, SsoCheckResponse>
  | ActionWithPayload<SessionActionTypes.SSO_CHECK_FAILURE, ErrorResponseType>
  | ActionWithPayload<SessionActionTypes.LOGIN_WITH_SSO_SUCCESS, LoginSsoResponse>
  | ActionWithPayload<SessionActionTypes.LOGIN_WITH_SSO_FAILURE, EdErrorResponseType>
  | ActionWithPayload<SessionActionTypes.LOGIN_REQUEST_SUCCESS, LoginResponseType>
  | ActionWithPayload<SessionActionTypes.SESSION_ME_SUCCESS, MeResponseType>
  | Action<SessionActionTypes.LOGIN_SUCCESS>;

export type SessionAction<A extends string> = ActionFromActionType<SessionActionsUnionType, A>;
