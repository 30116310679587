import type { LxStoreState } from '@maggie/store/types';

const getUpcomingLessonNotifications = (state: LxStoreState) => {
  return state.local_notification.upcomingLessonNotifications;
};

const getLastLessonIdScheduled = (state: LxStoreState) => {
  return state.local_notification.lastLessonIdScheduled;
};

export const LocalNotificationsSelectors = {
  getUpcomingLessonNotifications,
  getLastLessonIdScheduled
};
