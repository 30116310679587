import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';
import type { ConfigurationType } from '@maggie/store/courseware/lessons/types';
import { deepMap } from '@maggie/utils/deepMap';

import { Asset, isAsset, isScorm } from '../asset';

export class ThomasAssets {
  private lessonId: string;
  private configuration: ConfigurationType;
  private thomasVersion?: string;
  private dictAssets: { [key: string]: Asset };

  constructor(lessonId: string, configuration: ConfigurationType, thomasVersion?: string) {
    // By this time, both CourseBranding & CustomCSS for the lesson is already
    // applied on the server side (e.g. Emily).
    this.lessonId = lessonId;
    this.configuration = configuration;
    this.thomasVersion = thomasVersion;
    this.loadAssets();
  }

  // #region Private Methods

  private loadAssets() {
    // Dictionary where the key is the original asset path in the lesson
    // Value is the asset
    this.dictAssets = {};
    for (const a of Array.from(this.getAssetPaths())) {
      this.dictAssets[a] = new Asset(a, 'lessons', this.lessonId);
    }
  }

  private getAssetPaths() {
    const assets: string[] = [];
    deepMap(this.configuration, (p: any) => {
      if (isAsset(p)) {
        return assets.push(p);
      }
      return false;
    });
    return assets;
  }

  private hasIframeContent() {
    let iframeContent = false;
    deepMap(this.configuration, (p: any) => {
      if (p === 'youtube-video-embed') {
        return (iframeContent = true);
      }

      if (isScorm(p)) {
        return (iframeContent = true);
      }
      return false;
    });
    return iframeContent;
  }

  // #endregion

  // #region Public Methods

  public getUrl(): string {
    const thomasWeb = ENV.thomasWeb();

    if (this.hasIframeContent()) {
      // content requires to be online, load thomas from the web
      return thomasWeb;
    }

    if (Platform.isCordova()) {
      // load thomas from file system
      return ENV.thomasCordovaApp();
    }

    if (!!window.__scWebview.platform) {
      // load thomas from scwebview
      return ENV.thomasSCWebview();
    }

    return thomasWeb;
  }

  public getConfiguration(): ConfigurationType {
    // If thomas is online, we need the assets to be online too
    const da = this.dictAssets;
    const configuration = deepMap(this.configuration, (p: any) => {
      if (isAsset(p)) {
        return da[p].remoteURL;
      } else {
        return p;
      }
    });
    return configuration;
  }

  // #endregion
}
