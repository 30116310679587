import { delay } from 'redux-saga';
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Tracker } from '@maggie/core/tracker/tracker';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';
import { UserActionTypes } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

import { StarActionTypes } from './actions';
import { StarSelectors } from './selectors';
import type { StarActionUnionType } from './types';

type StarAction<ActionType extends string> = ActionFromActionType<StarActionUnionType, ActionType>;

const SYNC_STARS_DELAY = 30 * 1000;

function* watchFetchStars() {
  yield takeLatest(
    [UserActionTypes.FETCH_SYNC_USER_SUCCESS, StarActionTypes.FETCH_STAR_BALANCE],
    handleFetchStars
  );
}

function* handleFetchStars() {
  const areStarsEnabled: ReturnType<typeof StarSelectors.isStarsEnabled> = yield select(
    StarSelectors.isStarsEnabled
  );
  if (!areStarsEnabled) return;

  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );
  if (!token) return;

  yield put(
    RequestActions.getAuthed(
      Urls.STARS,
      StarActionTypes.SET_STAR_BALANCE,
      StarActionTypes.FETCH_STAR_BALANCE_FAILURE
    )
  );
}

function* watchChangeStars() {
  yield takeLatest(
    [StarActionTypes.REWARD_STARS_FROM_SLIDE, StarActionTypes.SPEND_STARS],
    handleClientChangeStars
  );
}

// whenever the client optimistically updates
// the star balance, we later sync balance with server
function* handleClientChangeStars() {
  yield call(delay, SYNC_STARS_DELAY);
  yield handleFetchStars();
}

function* handleNewlyEarnedSlideStars(action: StarAction<StarActionTypes.REWARD_STARS_FROM_SLIDE>) {
  // This event is needed to tell thomas about what
  // stars have been earned so it can show the little popup
  yield call(() => {
    (window as any).Backbone.Events.trigger('new-star', {
      earned: action.payload.stars,
      score: action.payload.starsScored,
      total: action.payload.totalStars
    });

    // enqueue 'new-star' interaction used by analytics
    Tracker.trackEvent({
      type: 'event',
      name: 'new-star',
      value: action.payload.stars + ''
    });
  });
}

function* watchFetchDailyReward() {
  while (true) {
    yield take(UserActionTypes.FETCH_SYNC_USER_SUCCESS);
    yield take(StarActionTypes.SET_STAR_BALANCE);

    const lastFetchTimestamp: number | undefined = yield select(
      (state: LxStoreState) => state.star.dailyRewardTimestamp
    );

    // First time fetching, or last successful fetch was at least a day ago
    if (lastFetchTimestamp == null || Date.now() - lastFetchTimestamp >= 86_400_000) {
      yield fork(handleFetchDailyReward);
    }
  }
}

function* handleFetchDailyReward() {
  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );
  if (!token) return;

  yield put(
    RequestActions.postAuthed(
      `${Urls.STARS}/check-daily-reward`,
      StarActionTypes.FETCH_DAILY_REWARD_SUCCESS,
      StarActionTypes.FETCH_DAILY_REWARD_FAILURE
    )
  );
}

function* watchNewlyEarnedSlideStars() {
  yield takeLatest(StarActionTypes.REWARD_STARS_FROM_SLIDE, handleNewlyEarnedSlideStars);
}

const starSagas = [
  fork(watchFetchStars),
  fork(watchChangeStars),
  fork(watchFetchDailyReward),
  fork(watchNewlyEarnedSlideStars)
];

export { starSagas };
