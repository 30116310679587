import { createAction } from '@edapp/utils';

export enum FeatureFlagsActionTypes {
  FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS',
  FETCH_FEATURE_FLAGS_SUCCESS = 'FETCH_FEATURE_FLAGS_SUCCESS',
  FETCH_FEATURE_FLAGS_FAILURE = 'FETCH_FEATURE_FLAGS_FAILURE'
}

export const FeatureFlagsActions = {
  fetchFeatureFlags: () => createAction(FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS, {})
};
