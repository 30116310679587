import type { LxStoreState } from '@maggie/store/types';

// SSO Login
const getSSOCheckLoading = (state: LxStoreState) => state.session.ssoCheckLoading;
const getSSOCheckError = (state: LxStoreState) => state.session.ssoCheckError;
const getSSOLoginLoading = (state: LxStoreState) => state.session.ssoLoginLoading;
const getSSOIsMagicLink = (state: LxStoreState) => state.session.ssoIsMagicLink;
const getSSOAuthenticationProgress = (state: LxStoreState) =>
  state.session.ssoAuthenticationInProgress;

// Login
const getLoginFormStep = (state: LxStoreState) => state.session.loginFormStep;
const getLoginRouteError = (state: LxStoreState) => state.session.loginRouteError;
const getLoginLoading = (state: LxStoreState) => state.session.loginLoading;
const getLoginError = (state: LxStoreState) => state.session.loginError;
const getLoginLastCheckedUsername = (state: LxStoreState) => state.session.loginLastCheckedUsername;

export const SessionSelectors = {
  getSSOCheckLoading,
  getSSOCheckError,
  getSSOLoginLoading,
  getSSOIsMagicLink,
  getSSOAuthenticationProgress,
  getLoginLoading,
  getLoginError,
  getLoginFormStep,
  getLoginRouteError,
  getLoginLastCheckedUsername
};
