import type { ILocalNotification } from 'cordova-plugin-local-notification';
import { add, set } from 'date-fns';
import { select, takeLatest } from 'redux-saga/effects';

import { ENV } from '@maggie/config/env';
import { config } from '@maggie/cordova/notifier/config';
import { Notifier } from '@maggie/cordova/notifier/notifier';
import type { PrizeType } from '@maggie/store/game/prizes/types';
import { getNotification } from '@maggie/store/game/prizes/utils';
import type { LxStoreState } from '@maggie/store/types';

import { LocalNotificationActionTypes } from '../actions';
import type { TypeMap } from '../types';

const getNotifTime = (day: number, hours: number, minutes: number) => {
  let date = new Date();

  // Set to specific hour and minute of config
  if (!ENV.IS_FAST_FORWARD_LOCAL_NOTIFICATIONS) {
    date = set(date, {
      hours: hours,
      minutes: minutes
    });
    return add(date, {
      days: day
    });
  }

  return add(date, {
    minutes: day
  });
};

function* handleSchedulePrizingLocalNotifications(
  action: TypeMap<LocalNotificationActionTypes.SCHEDULE_PRIZING_NOTIFICATIONS>
) {
  const { hour, minute } = action.payload;

  const hasPrizesAvailable: PrizeType[] = yield select((s: LxStoreState) => s.game.prizes);
  if (!hasPrizesAvailable?.length) {
    // No prizes available
    return;
  }
  let index = 0;
  for (const day of config.prizing.days) {
    const notif = makePrizingNotification(day, hour, minute)(index);
    Notifier.schedule(notif);
    index++;
  }
}

export function* watchSchedulePrizingNotifications() {
  yield takeLatest(
    LocalNotificationActionTypes.SCHEDULE_PRIZING_NOTIFICATIONS,
    handleSchedulePrizingLocalNotifications
  );
}

const makePrizingNotification = (day: number, hour: number, minute: number) => (
  unit: number
): ILocalNotification => {
  const nextDate = getNotifTime(day, hour, minute);
  return {
    id: uniquePrizingNotificationId(unit),
    trigger: {
      at: nextDate
    },
    ...getNotification({ nextTime: nextDate }),
    data: { type: 'prizing' }
  };
};

/**
 * Generates a deterministic id for local notification plugin.
 * This is so that we can cancel only prizing notifications.
 */
const uniquePrizingNotificationId = (day: number) => +(`100000000` + day);
