import { theme as themeCommon } from '../common/theme';
import { mixins } from './mixins';
import { typography } from './typography';

const theme = {
  ...themeCommon,
  mixins,
  typography: {
    ...themeCommon.typography,
    ...typography
  }
};

export type ThemeType = typeof theme;
export { theme };
