import { put, select } from 'redux-saga/effects';

import type { ContentLibrarySectionType } from '@edapp/content-library';
import { ContentLibraryActions, SECTION_COURSES_PAGE_SIZE } from '@edapp/content-library';
import { ContentLibrarySelectors } from '@maggie/store/content-library/selectors';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToContentLibrarySection(sectionId: string): any {
  // Check if section already exists
  // so it doesn't reset the store state when coming back to same section
  const section: ContentLibrarySectionType = yield select<LxStoreState>(
    ContentLibrarySelectors.findSection(sectionId)
  );

  if (!section) {
    yield put(ContentLibraryActions.fetchSection(sectionId, 1, SECTION_COURSES_PAGE_SIZE));
  }
}
