import { t } from 'i18next';
import { all, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { CourseActions } from '@maggie/store/courseware/courses/actions';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { CourseType } from '@maggie/store/courseware/courses/types';
import { ToastActions } from '@maggie/store/toast/actions';
import type { LxStoreState } from '@maggie/store/types';

import { CourseLanguageSelectors } from '../course-language/selectors';
import { LessonActionTypes, LessonActions } from '../lessons/actions';
import { WatchStatus } from '../types';
import type { AssessmentActionsUnionType } from './actions';
import { AssessmentActionTypes, AssessmentActions } from './actions';
import { AssessmentSelectors } from './selectors';
import type {
  AssessmentFeedback,
  AssessmentPostCommentType,
  AssessmentPostType,
  AssessmentProgress,
  AssessmentType
} from './types';

export type AssessmentAction<A extends string> = ActionFromActionType<
  AssessmentActionsUnionType,
  A
>;

function* handleFetchAssessmentProgress(
  action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS>
) {
  const { assessmentId } = action.payload;

  yield put(
    RequestActions.getAuthed<AssessmentProgress>(
      Urls.ASSESSMENT_PROGRESS(assessmentId),
      data => AssessmentActions.fetchAssessmentProgressSuccess(assessmentId, data),
      AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS_FAILURE
    )
  );
}

function* handleFetchAssessmentWithProgress(
  action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS>
): any {
  const { id } = action.payload;

  yield all([
    put(AssessmentActions.fetchAssessment(id)),
    put(AssessmentActions.fetchAssessmentProgress(id)),
    put(LessonActions.fetchLessonsProgress([id]))
  ]);

  type SagaResultType = {
    success: {
      assessment: {
        payload: AssessmentType;
      };
    };
    failure: {};
  };

  const { success, failure }: SagaResultType = yield race({
    success: all({
      assessment: take(AssessmentActionTypes.FETCH_ASSESSMENT_SUCCESS),
      progress: take(AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS_SUCCESS),
      lessonProgress: take(LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS)
    }),
    failure: race({
      assessment: take(AssessmentActionTypes.FETCH_ASSESSMENT_FAILURE),
      progress: take(AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS_FAILURE),
      lessonProgress: take(LessonActionTypes.FETCH_LESSON_PROGRESS_FAILURE)
    })
  });

  if (!!failure) {
    return yield put(AssessmentActions.fetchAssessmentWithProgressFailure());
  }

  const { payload: assessment } = success.assessment;

  yield put(LessonActions.fetchLessonsProgress(assessment.prerequisites, true));

  const { type: completedActionType } = yield take([
    LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS,
    LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_FAILURE
  ]);

  if (completedActionType === LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS) {
    yield put(AssessmentActions.fetchAssessmentWithProgressSuccess(assessment));
  } else {
    yield put(AssessmentActions.fetchAssessmentWithProgressFailure());
  }
}

function* handleEditPostSubscription(
  action: AssessmentAction<AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION>
) {
  const { assessmentId, postId, isWatching } = action.payload;

  yield put(
    RequestActions.patchAuthed(
      Urls.ASSESSMENT_POST_SUBSCRIPTIONS(postId),
      () => AssessmentActions.editPostSubscriptionSuccess(assessmentId, postId, isWatching),
      AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION_FAILURE,
      undefined,
      { isWatching }
    )
  );

  const { success, failure } = yield race({
    success: take(AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION_SUCCESS),
    failure: take(AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION_FAILURE)
  });

  if (failure) {
    yield put(
      ToastActions.showToast(
        false,
        t('common.postWatching.notifications.error', { ns: 'learners-experience' })
      )
    );
  }

  if (success) {
    if (isWatching) {
      yield put(
        ToastActions.showToast(
          true,
          t('common.postWatching.notifications.enabled', { ns: 'learners-experience' })
        )
      );
    } else {
      yield put(
        ToastActions.showToast(
          false,
          t('common.postWatching.notifications.disabled', { ns: 'learners-experience' })
        )
      );
    }
  }
}

function* handleFetchAssessment(action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT>) {
  const { id } = action.payload;
  const courseLanguageCode: string | undefined = yield select<LxStoreState>(s =>
    CourseLanguageSelectors.getSelectedLanguageOfCourse(s)
  );

  yield put(
    RequestActions.getAuthed(
      Urls.ASSESSMENT_ITEM(id),
      AssessmentActionTypes.FETCH_ASSESSMENT_SUCCESS,
      AssessmentActionTypes.FETCH_ASSESSMENT_FAILURE,
      undefined,
      { locale: courseLanguageCode }
    )
  );
}

function* handleFetchAssessmentSuccess(
  action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_SUCCESS>
) {
  const { courseId } = action.payload;
  const course: CourseType | undefined = yield select<LxStoreState>(state =>
    CourseSelectors.getCourse(courseId, state)
  );

  if (!course) {
    yield put(CourseActions.fetchSyncCourse(courseId));
  }
}

function* handleFetchPosts(action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_POSTS>) {
  const { page, assessmentId } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<AssessmentPostType>>(
      Urls.APP_ASSESSMENT_POSTS(assessmentId),
      data => AssessmentActions.fetchPostsSuccess(assessmentId, page, data),
      AssessmentActionTypes.FETCH_ASSESSMENT_POSTS_FAILURE,
      undefined,
      { pageSize: 20, page }
    )
  );
}

function* handleFetchPostComments(
  action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS>
) {
  const { assessmentId, postId, page, created } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<AssessmentPostCommentType>>(
      Urls.ASSESSMENT_POST_COMMENTS(assessmentId, postId),
      data => AssessmentActions.fetchPostCommentsSuccess(postId, page, data),
      AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS_FAILURE,
      undefined,
      { pageSize: 20, page, created }
    )
  );
}

function* handleFetchFeedback(
  action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK>
) {
  const { assessmentId, postId } = action.payload;

  yield put(
    RequestActions.getAuthed<AssessmentFeedback>(
      Urls.ASSESSMENT_POST_FEEDBACK(assessmentId, postId),
      data => AssessmentActions.fetchFeedbackSuccess(postId, data),
      AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK_FAILURE
    )
  );
}

function* handleSaveComment(
  action: AssessmentAction<AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT>
) {
  const { assessmentId, postId, commentText } = action.payload;

  yield put(
    RequestActions.postAuthed<AssessmentPostCommentType>(
      Urls.ASSESSMENT_POST_COMMENTS(assessmentId, postId),
      data => AssessmentActions.saveCommentSuccess(postId, data),
      AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT_FAILURE,
      undefined,
      { content: commentText }
    )
  );

  const { success } = yield race({
    success: take(AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT_SUCCESS),
    failure: take(AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT_FAILURE)
  });

  // only optimistically subscribe to watching if no post subscription exists yet -
  // this will set isWatching on the post to true
  if (success) {
    const commentedPost: AssessmentPostType | undefined = yield select(
      AssessmentSelectors.getPost(postId)
    );

    if (commentedPost?.watchingStatus === WatchStatus.NOT_SPECIFIED) {
      yield put(AssessmentActions.editPostSubscriptionSuccess(assessmentId, postId, true));
    }
  }
}

function* handleSavePost(action: AssessmentAction<AssessmentActionTypes.SAVE_ASSESSMENT_POST>) {
  const { assessmentId, textContent, media } = action.payload;

  yield put(
    RequestActions.postAuthed<AssessmentPostType>(
      Urls.ASSESSMENT_POSTS(assessmentId),
      createdPost => AssessmentActions.savePostSuccess(assessmentId, createdPost),
      AssessmentActionTypes.SAVE_ASSESSMENT_POST_FAILURE,
      undefined,
      { content: textContent, media }
    )
  );
}

function* handleSavePostSuccess(
  action: AssessmentAction<AssessmentActionTypes.SAVE_ASSESSMENT_POST_SUCCESS>
) {
  const { assessmentId, post } = action.payload;

  const isSplitView = window.__router.isSplitViewLayout('assignment');
  const isAssignmentInMain = window.__router.getMain().routeName === 'assignment';
  if (isSplitView && isAssignmentInMain) {
    window.__router.navigate('submission', { assessmentId, postId: post.id });
  }
}

function* handleFetchPostItem(
  action: AssessmentAction<AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM>
) {
  const { assessmentId, postId } = action.payload;

  yield put(
    RequestActions.getAuthed<AssessmentPostType>(
      Urls.APP_ASSESSMENT_POST_ITEM(assessmentId, postId),
      data => AssessmentActions.fetchPostItemSuccess(assessmentId, data),
      AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM_FAILURE
    )
  );
}

function* handleEditPost(action: AssessmentAction<AssessmentActionTypes.EDIT_ASSESSMENT_POST>) {
  const { assessmentId, postId, textContent, media } = action.payload;

  yield put(
    RequestActions.patchAuthed<AssessmentPostType>(
      Urls.ASSESSMENT_POST_ITEM(assessmentId, postId),
      editedPost => AssessmentActions.editPostSuccess(assessmentId, editedPost),
      AssessmentActionTypes.EDIT_ASSESSMENT_POST_FAILURE,
      undefined,
      { content: textContent, media }
    )
  );
}

function* watchFetchAssessmentProgress() {
  yield takeLatest(AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS, handleFetchAssessmentProgress);
}

function* watchFetchAssessment() {
  yield takeLatest(AssessmentActionTypes.FETCH_ASSESSMENT, handleFetchAssessment);
}

function* watchFetchAssessmentSuccess() {
  yield takeLatest(AssessmentActionTypes.FETCH_ASSESSMENT_SUCCESS, handleFetchAssessmentSuccess);
}

function* watchFetchPosts() {
  yield takeLatest(AssessmentActionTypes.FETCH_ASSESSMENT_POSTS, handleFetchPosts);
}

function* watchFetchPostComments() {
  yield takeLatest(AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS, handleFetchPostComments);
}

function* watchFetchPostItem() {
  yield takeLatest(AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM, handleFetchPostItem);
}

function* watchFetchFeedback() {
  yield takeLatest(AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK, handleFetchFeedback);
}

function* watchSaveComment() {
  yield takeLatest(AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT, handleSaveComment);
}

function* watchSavePost() {
  yield takeLatest(AssessmentActionTypes.SAVE_ASSESSMENT_POST, handleSavePost);
}

function* watchSavePostSuccess() {
  yield takeLatest(AssessmentActionTypes.SAVE_ASSESSMENT_POST_SUCCESS, handleSavePostSuccess);
}

function* watchEditPost() {
  yield takeLatest(AssessmentActionTypes.EDIT_ASSESSMENT_POST, handleEditPost);
}

function* watchEditPostSubscription() {
  yield takeLatest(
    AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION,
    handleEditPostSubscription
  );
}

function* watchFetchAssessmentWithProgress() {
  yield takeLatest(
    AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS,
    handleFetchAssessmentWithProgress
  );
}

const assessmentSagas = [
  fork(watchFetchAssessmentProgress),
  fork(watchFetchAssessment),
  fork(watchFetchAssessmentSuccess),
  fork(watchFetchPosts),
  fork(watchFetchPostComments),
  fork(watchFetchPostItem),
  fork(watchFetchFeedback),
  fork(watchSavePost),
  fork(watchSavePostSuccess),
  fork(watchSaveComment),
  fork(watchEditPost),
  fork(watchEditPostSubscription),
  fork(watchFetchAssessmentWithProgress)
];

export { assessmentSagas };
