import type { PrizeType } from './types';
import { t } from 'i18next';
import { generateTitle } from '@edapp/translations/src/i18n';
import { GamePrizesSelectors } from './selectors';
import type { WinnerRoundType } from '../winners/types';
const written = require('written');

const getPrizes = () => {
  const { game } = window.__store.getState();
  if (game) {
    return game.prizes;
  }
  return [];
};

const getNumericValue = (prize: PrizeType) => {
  const val = prize.value || 0;
  const match = `${val}`.match(/\d+(\.\d*)*$/);
  return match ? match[0] : '0';
};

const getFormattedValue = (prize: PrizeType) => {
  const value = +getNumericValue(prize);
  return written.prettyPrice(value, priceFormat(value));
};

const priceFormat = (value: number) => {
  return {
    decimals: value % 1 === 0 ? 0 : 2,
    currency: `<sup>${t('currency', {
      ns: 'learners-experience'
    })}</sup>`,
    wrap: value % 1 !== 0 ? 'sup' : undefined
  };
};

const getNotificationMessage = (notificationTime: Date) => {
  const prizes = getPrizes();
  // we want the first prize that has not ended, or the last prize
  const prize = prizes.find(p => !p.end || notificationTime < new Date(p.end)) || prizes.pop();
  return t('notifications.prize.message', {
    prize: prize && prize.name,
    ns: 'learners-experience'
  });
};

const getWinnerRoundValue = (round: WinnerRoundType) => {
  return round && round.prize.fields && round.prize.fields.value && round.prize.fields.value.value
    ? +round.prize.fields.value.value
    : 0;
};

type NotificationType = {
  title: string;
  text: string;
  at?: Date;
};

const getNotificationTitle = () => {
  return generateTitle('notifications.prize.title');
};

export const getNotification = ({ nextTime }: { nextTime: Date }): NotificationType | null => {
  if (getPrizes().length === 0) {
    return null;
  }
  const notif: NotificationType = {
    title: getNotificationTitle(),
    text: getNotificationMessage(nextTime)
  };
  return notif;
};

const PrizeUtils = {
  ...GamePrizesSelectors,
  getNotification,
  getNumericValue,
  getFormattedValue,
  priceFormat,
  getWinnerRoundValue
};

export { PrizeUtils, NotificationType };
