import type { Reducer } from 'redux';

import type { LessonReviewUnionType } from '../lesson-review/actions';
import { LessonReviewActionTypes } from '../lesson-review/actions';
import { LessonDialogActionTypes } from './actions';
import { initialLessonDialogState } from './constants';
import type { LessonDialogActionUnionType, LessonDialogState } from './types';

export const lessonDialogReducer: Reducer<LessonDialogState> = (
  state = initialLessonDialogState,
  action: LessonDialogActionUnionType | LessonReviewUnionType
) => {
  switch (action.type) {
    case LessonDialogActionTypes.OPEN_LESSON_LOADING_DIALOG: {
      const { title } = action.payload;
      return {
        ...state,
        loadingDialogOpenTimestamp: Date.now(),
        loadingDialogIsOpen: true,
        loadingDialogTitle: title,
        loadingDialogState: 'loading'
      };
    }

    case LessonReviewActionTypes.CLOSE_LESSON_REVIEW:
    case LessonDialogActionTypes.CLOSE_LESSON_LOADING_DIALOG: {
      return {
        ...state,
        loadingDialogIsOpen: false
      };
    }

    case LessonDialogActionTypes.SET_LESSON_LOADING_DIALOG_STATE: {
      return { ...state, loadingDialogState: action.payload.state };
    }

    default: {
      return state;
    }
  }
};
