import type { LxStoreState } from '@maggie/store/types';

import { AssessmentSelectors } from '../assessments/selectors';
import { ConferenceSelectors } from '../conferences/selectors';
import { CourseSelectors } from '../courses/selectors';
import { DiscussionSelectors } from '../discussions/selectors';
import { LessonSelectors } from '../lessons/selectors';
import type { LessonActivityType } from '../lessons/types';
import { ObservationSelectors } from '../observations/selectors';
import { CoursewareUtils } from '../utils';
import { LessonSummaryMapper } from './mappers';
import type { LessonSummaryWithProgress } from './types';

/**
 * Finds a lesson summary (as a common interface) for all lesson activity types
 *
 * @param id - id of the lesson activity
 * @param type - type discriminator - used to look up data store
 */
const findLessonSummaryWithProgress = (id: string, type: LessonActivityType) => {
  return (state: LxStoreState): LessonSummaryWithProgress | undefined => {
    const progress = LessonSelectors.getLessonProgress(id, state);

    const course = CourseSelectors.getCourse(state.navigation.course.id, state);
    if (!!course) {
      const summary = course.lessonSummaries.find(l => l.lessonId === id);
      if (!!summary) {
        return { ...summary, progress };
      }
    }

    switch (type) {
      case 'Lesson': {
        const lesson = LessonSelectors.getLesson(id, state);
        if (!!lesson) {
          const summary = LessonSummaryMapper.map({ type: 'Lesson', activity: lesson });
          return { ...summary, progress };
        }
        break;
      }

      case 'Discussion': {
        const discussion = DiscussionSelectors.getDiscussion(id)(state).discussion;
        if (!!discussion) {
          const summary = LessonSummaryMapper.map({ type: 'Discussion', activity: discussion });
          return { ...summary, progress };
        }
        break;
      }

      case 'Conference': {
        const conference = ConferenceSelectors.getConferencesById(state)[id];
        if (!!conference) {
          const summary = LessonSummaryMapper.map({ type: 'Conference', activity: conference });
          return { ...summary, progress };
        }
        break;
      }

      case 'Assessment': {
        const assessment = AssessmentSelectors.getAssessment(id)(state).assessment;
        if (!!assessment) {
          const summary = LessonSummaryMapper.map({ type: 'Assessment', activity: assessment });
          return { ...summary, progress };
        }
        break;
      }

      case 'Observation': {
        const observation = ObservationSelectors.getObservation(id)(state);
        if (!!observation) {
          const summary = LessonSummaryMapper.map({ type: 'Observation', activity: observation });
          return { ...summary, progress };
        }
        break;
      }

      default: {
        throw Error(`Unknown lesson activity type: ` + type);
      }
    }

    return undefined;
  };
};

const isLessonAvailableForActivity = (
  state: LxStoreState,
  lessonId: string,
  type: LessonActivityType
) => {
  const lessonSummary = findLessonSummaryWithProgress(lessonId, type)(state);
  const isAvailable = LessonSelectors.isLessonAvailable(lessonSummary, state);
  const isAvailableForPlatform = CoursewareUtils.isAvailableForPlatform(lessonSummary);

  return isAvailable && isAvailableForPlatform;
};

export const LessonActivitySelectors = {
  findLessonSummaryWithProgress,
  isLessonAvailableForActivity
};
