import type { Store } from 'redux';

import type { LxStoreState } from '@maggie/store/types';

import ConfigActions from './actions';

export const addOnlineConfigListener = (store: Store<LxStoreState>) => {
  window.addEventListener('offline', () => {
    const wasOnline = store.getState().config.isOnline;
    store.dispatch(ConfigActions.setOnline(navigator.onLine, wasOnline));
  });

  window.addEventListener('online', () => {
    const wasOnline = store.getState().config.isOnline;
    store.dispatch(ConfigActions.setOnline(navigator.onLine, wasOnline));
  });
};
