import { t } from 'i18next';
import { call, put, select, take } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { LESSON_REVIEW_IFRAME_PARENT } from '@maggie/components/lesson-review/thomas-wrapper/constants';
import { ReviewThomasPlayer } from '@maggie/core/lessons/thomas-player/review-thomas-player';
import { ActionDialogActions } from '@maggie/store/action-dialog/actions';
import {
  ActivityActionTypes,
  LessonActivityActions
} from '@maggie/store/courseware/lesson-activity/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonType } from '@maggie/store/courseware/lessons/types';
import { ThomasActionTypes } from '@maggie/store/thomas/actions';
import type { LxStoreState } from '@maggie/store/types';

import { fetchLesson } from './lesson-play-sagas';

export function* handleDidNavigateToLessonReview(courseId: string, lessonId: string): any {
  yield take(ThomasActionTypes.DOM_IS_READY);

  const lesson: LessonType | undefined = yield fetchLesson(lessonId);
  if (!lesson) {
    ErrorLogger.captureEvent(`Opened a lesson in review that doesn't exist`, 'error', {
      courseId,
      lessonId
    });
    return; // do not navigate anywhere - let UI handle errors
  }

  // Recalculate lesson's UNLOCKED status + show locked dialog
  yield put(LessonActivityActions.didOpenLessonActivity(lessonId, 'Lesson'));
  yield take(ActivityActionTypes.DID_OPEN_LESSON_ACTIVITY_COMPLETED);

  // users cannot review lessons that are in progress or not completed
  const canBeReviewed: boolean = yield select<LxStoreState>(
    LessonSelectors.getCanLessonBeReviewed(lessonId)
  );
  if (!canBeReviewed) {
    return window.__router.navigate('course', { id: courseId }, true);
  }

  const reviewPlayer = new ReviewThomasPlayer(lesson, `#${LESSON_REVIEW_IFRAME_PARENT(lessonId)}`);

  try {
    yield call(reviewPlayer.open);
  } catch (err) {
    ErrorLogger.captureEvent(`Failed to open lesson in review`, 'error', { err });
    const message = t('course.lesson.closed', { ns: 'learners-experience' });
    const confirm = t('course.lesson.ok', { ns: 'learners-experience' });
    yield put(ActionDialogActions.openActionDialog(undefined, message, confirm));
    yield call(reviewPlayer.close);
    window.__router.navigate('course', { id: courseId }, true);
    return;
  }
}
