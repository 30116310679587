import { put, select, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { CollectionsSelectors } from '@maggie/store/courseware/collections/selectors';
import type { CollectionType } from '@maggie/store/courseware/collections/types';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { CourseType } from '@maggie/store/courseware/courses/types';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonType } from '@maggie/store/courseware/lessons/types';
import { NavigationActions } from '@maggie/store/navigation/actions';
import type { NavigationState } from '@maggie/store/navigation/types';
import { getLocationPath } from '@maggie/store/navigation/utils';
import { ThomasSelectors } from '@maggie/store/thomas/selectors';
import type { LxStoreState } from '@maggie/store/types';

import { ConfigActionTypes } from './actions';
import type { ConfigActionsMap } from './types';

function* handleOnlineConfigChange(action: ConfigActionsMap<ConfigActionTypes.SET_ONLINE>) {
  const { online, wasOnline } = action.payload;
  const isThomasOpen: boolean = yield select(ThomasSelectors.getIsOpen);
  const isSummaryDialogOpen: boolean = yield select(LessonSelectors.getIsSummaryDialogOpen);

  if ((online && wasOnline) || isThomasOpen || isSummaryDialogOpen) {
    // online - no need to handle!
    return;
  }

  if (online && !wasOnline) {
    // coming back online - trigger side effects only
    const path = getLocationPath();
    const match = window.__router.findMatch(path);
    if (!match) {
      const extra = { online, wasOnline, path, match };
      ErrorLogger.captureEvent('Could not find match', 'error', extra);
    } else {
      yield put(NavigationActions.didNavigateRoute(match.routeName, match.params));
    }
    return;
  }

  const navigation: NavigationState = yield select<LxStoreState>(s => s.navigation);
  switch (navigation.route) {
    case 'courseCollection': {
      const collection: CollectionType | undefined = yield select<LxStoreState>(s =>
        CollectionsSelectors.getCollection(navigation.collectionId, s)
      );
      if (!collection) {
        window.__router.navigate('home', {});
      }
      break;
    }

    case 'course': {
      const course: CourseType | undefined = yield select<LxStoreState>(s =>
        CourseSelectors.getCourse(navigation.course.id, s)
      );
      if (!course) {
        window.__router.navigate('home', {});
      }
      break;
    }

    case 'lesson': {
      const lesson: LessonType = yield select<LxStoreState>(s =>
        LessonSelectors.getLesson(navigation.lessonId, s)
      );
      if (!lesson) {
        window.__router.navigate('home', {});
      }
      break;
    }

    case 'game': {
      window.__router.navigate('home', {});
      break;
    }
  }
}

export function* watchOnlineConfigChange() {
  yield takeLatest(ConfigActionTypes.SET_ONLINE, handleOnlineConfigChange);
}
