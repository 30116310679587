// "I honestly think we don't need this" - Tim W.
// This can probably get removed once we get rid of downloadable_collection
// This is not being used in many places and the logic is confusing as hell!

type BitStateObj = {
  init?: boolean;
  set_true?: string;
  set_false?: string;
  check?: string;
};

export const bitState = ({ init, set_true, set_false, check }: BitStateObj = {}): any => {
  let val = init != null ? init : true;
  const methods: Record<string, any> = {};
  methods[check || 'is'] = (c: boolean) => val === c;
  methods[set_true || 'yes'] = () => (val = true);
  methods[set_false || 'no'] = () => (val = false);
  return methods;
};
