type Options = {
  more?: string;
  amp?: string;
  wrap?: string;
  quantify?: boolean;
};

export const prettyList = (arr: string[], max: number, opts: Options = {}) => {
  const len = arr.length;
  if (len === 0) {
    return '';
  }

  let wrappedArray = [...arr];
  const amp = opts.amp || 'and';
  const more = opts.more || 'more';

  if (max < len) {
    const diff = len - max;
    wrappedArray = wrappedArray.slice(0, max);
    wrappedArray = wrappedArray.concat(diff + ' ' + more);
  }

  if (!!opts.wrap) {
    wrappedArray = wrappedArray.map(s => wrapInTag(s, opts.wrap || ''));
  }

  return wrappedArray
    .slice(0, -1)
    .join(', ')
    .concat(wrappedArray.length === 1 ? '' : ' ' + amp + ' ', wrappedArray.slice(-1).join(''));
};

export const wrapInTag = (str: string, tag: string) => {
  if (tag == null) {
    tag = 'span';
  }
  return '<' + tag + '>' + str + '</' + tag + '>';
};
