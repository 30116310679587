import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { checkOnline } from '@maggie/cordova/network_utils';
import { Tracker } from '@maggie/core/tracker/tracker';
import { BackboneActionTypes } from '@maggie/store/backbone/actions';
import { SCUserId } from '@maggie/store/navigation/sc-user-id';
import { SplitViewLayoutActions } from '@maggie/store/split-view-layout/actions';
import type { LxStoreState } from '@maggie/store/types';

import ConfigActions, { ConfigActionTypes } from './actions';
import { watchOnlineConfigChange } from './online-sagas';

function* handleApplicationResume() {
  // Reset offline on resume
  const online = checkOnline();
  const wasOnline: boolean = yield select<LxStoreState>(s => s.config.isOnline);
  yield put(ConfigActions.setOnline(online, wasOnline));

  // Track visit on resume
  const deviceToken: string = yield select<LxStoreState>(
    state => state.config.devicePushNotificationToken
  );
  const scUserId = SCUserId.get();
  const userData: LxStoreState['user'] = yield select<LxStoreState>(state => state.user);
  Tracker.trackVisit({ type: 'visit' }, userData, deviceToken, window.__l10n.language, scUserId);
}

/**
 * When the orientation changes, we need to retrigger the navigation to main
 * so it can select the item on the detail accordingly
 */
function* handleOrientationChange() {
  const { params, routeName } = window.__router.getMain();
  if (!window.__router.isSplitViewLayout(routeName)) {
    return;
  }

  yield put(SplitViewLayoutActions.mainNavigate(routeName, params));
}

function* watchApplicationResume() {
  yield takeLatest(BackboneActionTypes.APPLICATION_RESUME, handleApplicationResume);
}

function* watchOrientationChange() {
  yield takeLatest(ConfigActionTypes.SET_ORIENTATION, handleOrientationChange);
}

export const configSagas = [
  fork(watchApplicationResume),
  fork(watchOnlineConfigChange),
  fork(watchOrientationChange)
];
