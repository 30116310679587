import type { StarState } from './types';

export const initialStarState: StarState = {
  clientBalance: 0,
  clientTimestamp: undefined,
  numberOfStarsFromDailyLoginReward: 0,
  dailyRewardTimestamp: undefined,
  syncedBalance: 0,
  syncedTimestamp: undefined
};
