import { put, take } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import {
  CollectionsActionTypes,
  CollectionsActions
} from '@maggie/store/courseware/collections/actions';

export function* handleDidNavigateToCourseCollection(courseCollectionId: string) {
  yield put(CollectionsActions.fetchCollectionsWithProgress({ ids: [courseCollectionId] }));
  // wait before finish the handler
  yield take(CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS_COMPLETED);

  itly.courseCollectionOpened({ course_collection_id: courseCollectionId });
}
