import type { LxStoreState } from '@maggie/store/types';

import type { CourseAvailabilityResult } from '../courses/types';

/**
 * This is to check the locked status of a course in relation to all playlists.
 */
const isCourseLockedByPlaylists = (courseId: string) => (
  state: LxStoreState
): CourseAvailabilityResult => {
  const playlistProgresses = state.courseware.playlists.playlistProgressesByCourseId[courseId];
  if (!playlistProgresses) {
    // By default, if course is not in any playlists - return isAvailable=true
    return {
      isAvailable: true,
      lockedInPlaylists: []
    };
  }

  let locked = false;
  const lockedInPlaylists = [];
  for (const playlistId in playlistProgresses) {
    const isLocked = playlistProgresses[playlistId] || false;

    if (!isLocked) {
      // If course is not locked at least in one playlist - course generaly is not locked. isAvailable=true
      return {
        isAvailable: true,
        lockedInPlaylists: []
      };
    } else {
      locked = true;
      lockedInPlaylists.push(playlistId);
    }
  }

  return {
    isAvailable: !locked,
    lockedInPlaylists
  };
};

/**
 * This is to check the locked status of a course in relation to the playlist.
 *
 * If user is looking in the context of a playlist, the global status does not matter.
 * We care about the status of the course in that one specific instance of the playlist
 */
const isCourseLockedInPlaylist = (courseId: string, playlistId: string) => (
  state: LxStoreState
): boolean => {
  return state.courseware.playlists.playlistProgressesByCourseId[courseId]?.[playlistId] || false;
};

export const PlaylistProgressSelectors = {
  isCourseLockedByPlaylists,
  isCourseLockedInPlaylist
};
