import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { checkOnline } from '@maggie/cordova/network_utils';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { InteractionApi } from '@maggie/core/tracker/interaction-api';
import type { LxStoreState } from '@maggie/store/types';

import { InteractionActionTypes, InteractionActions } from './actions';

function* handleSendAttemptAndEmptyQueue() {
  // when offline dont try to send interactions
  if (!checkOnline()) {
    yield put(InteractionActions.sendAttemptAndEmptyQueueSuccess());
    return;
  }

  try {
    yield call(InteractionApi.waitForQueueEmpty, { timeout: 30000, minimum: 1000 });
  } catch (err) {
    ErrorLogger.captureEvent('Failed to empty queue', 'error', {
      err,
      isSlowConnection: window.__store.getState().config.isSlowConnection,
      isOnline: window.__store.getState().config.isOnline
    });
    yield put(InteractionActions.sendAttemptAndEmptyQueueFailure());
    return;
  }

  HostedWebviewUtils.triggerEvent('syncCompleted');
  yield put(InteractionActions.sendAttemptAndEmptyQueueSuccess());
}

function* handleSendAttemptAndEmptyQueueFailure() {
  /**
   * For third parties apps loading the lesson in a webview we notify
   * them after `maxRetries` to close there webview
   */
  const state: LxStoreState = yield select();
  const { retries, maxRetries } = state.interactions;
  if (retries > maxRetries) {
    ErrorLogger.captureEvent('Failed to sync attempt! Tried 3 times', 'critical', {
      isSlowConnection: window.__store.getState().config.isSlowConnection,
      isOnline: window.__store.getState().config.isOnline
    });

    // After 3 retries force success to let the learner move on
    yield put(InteractionActions.sendAttemptAndEmptyQueueSuccess());
  }
}

function* watchSendAttemptAndEmptyQueue() {
  yield takeLatest(
    InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE,
    handleSendAttemptAndEmptyQueue
  );
}

export function* watchSendAttemptAndEmptyQueueFailure() {
  yield takeLatest(
    InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE_FAILURE,
    handleSendAttemptAndEmptyQueueFailure
  );
}

export const interactionsSagas = [
  fork(watchSendAttemptAndEmptyQueue),
  fork(watchSendAttemptAndEmptyQueueFailure)
];
