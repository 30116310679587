import { put, select, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { Tracker } from '@maggie/core/tracker/tracker';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { MicroCredentialActions } from '../micro-credentials/actions';
import { MicrocredentialsSelectors } from '../micro-credentials/selectors';
import type { MicroCredential } from '../micro-credentials/types';
import { CourseActionTypes } from './actions';
import type { CourseAction } from './sagas';
import { CourseSelectors } from './selectors';
import type { CourseType } from './types';

function* handleUpdateCourseCompleted(
  action: CourseAction<CourseActionTypes.UPDATE_COURSE_COMPLETED>
) {
  const { courseId, completed, wasCompletedBefore, lessonsCompleted } = action.payload;
  const course: CourseType | undefined = yield select<LxStoreState>(s =>
    CourseSelectors.getCourse(courseId, s)
  );

  if (!course) {
    ErrorLogger.captureEvent('UPDATE_COURSE_COMPLETED of a course that doesnt exist', 'error', {
      courseId
    });
    return;
  }

  if (completed && !wasCompletedBefore) {
    Tracker.trackEvent({
      type: 'event',
      name: 'course-completed',
      value: course.title,
      locale: course.locale,
      id: courseId,
      courseVersionNumber: course.versionNumber,
      lessonsCompleted
    });

    itly.courseCompleted({ id: courseId, value: course.title, lessonsCompleted });

    const isIndividualLearner: boolean = yield select<LxStoreState>(s =>
      UserSelectors.isIndividualLearner(s.user)
    );

    // If this course has an active micro-credential, we start polling the server
    // until it gets earned in the backend
    const microCredentialId: string | undefined = yield select<LxStoreState>(
      MicrocredentialsSelectors.getByCourseId(courseId)
    );

    if (isIndividualLearner && microCredentialId) {
      const microCredential: MicroCredential | undefined = yield select<LxStoreState>(
        MicrocredentialsSelectors.getById(microCredentialId)
      );

      if (microCredential?.active) {
        yield put(MicroCredentialActions.pollMicroCredential(microCredentialId));
      }
    }
  }
}

export function* watchUpdateCourseCompleted() {
  yield takeLatest(CourseActionTypes.UPDATE_COURSE_COMPLETED, handleUpdateCourseCompleted);
}
