import { chunk } from 'lodash';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { PaginatedResponse } from '@edapp/request/src/types';
import type { ActionFromActionType, ActionWithPayload } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { LockedDialogActions } from '@maggie/store/locked-dialog/actions';
import {
  getRedirectToHomeParams,
  getRedirectToPlaylistParams
} from '@maggie/store/locked-dialog/types';
import type { DoNotRedirectParams, RedirectParams } from '@maggie/store/locked-dialog/types';
import { NavigationSelectors } from '@maggie/store/navigation/selectors';
import type { LxStoreState } from '@maggie/store/types';

import type { CourseSummaryType } from '../collections/types';
import { ConferenceActionTypes } from '../conferences/actions';
import type { UpcomingConference } from '../conferences/types';
import { CourseSummariesSelectors } from '../course-summaries/selectors';
import { CourseActionTypes, CourseActions } from './actions';
import { watchCalculateCourseCompletion } from './calculate-course-completion-sagas';
import { watchFetchSyncCourse } from './course-sync-sagas';
import { watchFetchCourseLessons } from './fetch-course-lessons-sagas';
import { CourseSelectors } from './selectors';
import type {
  CourseActionsUnionType,
  CourseAvailabilityResult,
  CourseCertificateType,
  CourseProgressType
} from './types';
import type { CourseType } from './types';
import { watchUpdateCourseCompleted } from './update-course-completed-sagas';
import { watchUpdateCourseOpened } from './update-course-opened-sagas';
import { watchUpdateCoursesUnlocked } from './update-course-unlocked-sagas';

type BatchProgressRaceType = {
  success: CourseAction<CourseActionTypes.FETCH_COURSES_PROGRESS_BATCH_SUCCESS>;
  failure: CourseAction<CourseActionTypes.FETCH_COURSES_PROGRESS_BATCH_FAILURE>;
};

const DEFAULT_PAGE_SIZE = 100;

export type CourseAction<ActionType extends string> = ActionFromActionType<
  CourseActionsUnionType,
  ActionType
>;

// COURSES PROGRESS
function* handleFetchCoursesProgress(
  action: CourseAction<CourseActionTypes.FETCH_COURSES_PROGRESS>
) {
  // splitting down the payload in chunks of 100
  const idChunks = chunk(action.payload.ids, DEFAULT_PAGE_SIZE);

  const courseProgressFetchRequests = idChunks.map(courseIds =>
    RequestActions.getAuthed(
      Urls.COURSE_PROGRESS,
      CourseActionTypes.FETCH_COURSES_PROGRESS_BATCH_SUCCESS,
      CourseActionTypes.FETCH_COURSES_PROGRESS_BATCH_FAILURE,
      undefined,
      {
        courseIds
      }
    )
  );

  // send and wait for all requests before finishing FETCH_COURSES_PROGRESS
  const finalResult: PaginatedResponse<CourseProgressType> = { totalCount: 0, items: [] };
  for (const fetchRequest of courseProgressFetchRequests) {
    // send request
    yield put(fetchRequest);

    // wait request
    const result: BatchProgressRaceType = yield race({
      success: take(CourseActionTypes.FETCH_COURSES_PROGRESS_BATCH_SUCCESS),
      failure: take(CourseActionTypes.FETCH_COURSES_PROGRESS_BATCH_FAILURE)
    });

    // if fails, fail all!
    if (result.failure) {
      yield put({
        type: CourseActionTypes.FETCH_COURSES_PROGRESS_FAILURE,
        payload: result.failure.payload
      });

      return;
    }

    // if success - concat on final result
    finalResult.items = [...finalResult.items, ...result.success.payload.items];
    // Yikes! Backend returns totalCount as the number of items returned in that request
    finalResult.totalCount += result.success.payload.totalCount;
  }

  yield put({
    type: CourseActionTypes.FETCH_COURSES_PROGRESS_SUCCESS,
    payload: finalResult
  });
}

function* handleDidOpenCourse(action: CourseAction<CourseActionTypes.DID_OPEN_COURSE>) {
  const { courseId } = action.payload;

  const allCourseSummaries: CourseSummaryType[] = yield select<LxStoreState>(
    CourseSummariesSelectors.getAllCourseSummaries
  );

  const matchingCourseSummary = allCourseSummaries.find(c => c.courseId === courseId);

  const matchingCourse: CourseType | undefined = yield select<LxStoreState>(state =>
    CourseSelectors.getCourse(courseId, state)
  );

  if (!matchingCourseSummary && !matchingCourse) {
    // possible scenario: user doesn't have access to course and trying to deeplink?
    return;
  }

  const planningStartDate =
    matchingCourseSummary?.planning.startDateTime ?? matchingCourse?.planning.start;
  const planningEndDate =
    matchingCourseSummary?.planning.endDateTime ?? matchingCourse?.planning.end;

  const playlistId: string | undefined = yield select<LxStoreState>(
    NavigationSelectors.getPlaylistIdFromCourse
  );

  const availability: CourseAvailabilityResult = yield select<LxStoreState>(s =>
    CourseSelectors.isCourseAvailableInPlaylist(
      courseId,
      playlistId,
      planningStartDate,
      planningEndDate,
      s
    )
  );

  const isLockedByPlaylist = !availability.isAvailable && availability.lockedInPlaylists.length > 0;

  let shouldOpenDialog = false;

  const doNotRedirect: DoNotRedirectParams = {
    shouldRedirect: false
  };

  let redirect: RedirectParams = doNotRedirect;

  const routeName = window.__router.getMain().routeName;
  switch (routeName) {
    case 'playlist':
    case 'courseCollection': // clicked on a locked course from the list of courses
      shouldOpenDialog = false;

      redirect = doNotRedirect;
      break;

    case 'home': // clicked on a locked course in homescreen
      shouldOpenDialog = true;
      redirect = isLockedByPlaylist
        ? getRedirectToPlaylistParams(availability.lockedInPlaylists[0])
        : doNotRedirect;
      break;

    case 'course': // deeplink into a locked course
    default:
      shouldOpenDialog = true;
      redirect = isLockedByPlaylist
        ? getRedirectToPlaylistParams(availability.lockedInPlaylists[0])
        : getRedirectToHomeParams();
      break;
  }

  if (!availability.isAvailable) {
    const isCourseFromSeqPlaylist = matchingCourseSummary
      ? matchingCourseSummary.sequentialPlaylists?.length > 0
      : false;
    itly.tapLockedCourse({ is_course_seq_playlist: isCourseFromSeqPlaylist });
    yield put(LockedDialogActions.openLockedCourse(shouldOpenDialog, courseId, redirect));
  } else {
    yield put(
      CourseActions.updateCourseOpened(
        courseId,
        matchingCourseSummary?.versionNumber ?? matchingCourse?.versionNumber ?? 1
      )
    );
  }
}

function* handleFetchUpcomingConferencesSuccess(
  action: ActionWithPayload<
    ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_SUCCESS,
    RequestTypes.PaginatedResponse<UpcomingConference>
  >
) {
  const { items: upcomingConferences } = action.payload;
  const firstCourseId = upcomingConferences[0]?.courseId;

  if (firstCourseId) {
    yield put(CourseActions.fetchSyncCourse(firstCourseId));
  }
}

function* handleFetchCourseCertificate(
  action: CourseAction<CourseActionTypes.FETCH_COURSE_CERTIFICATE>
) {
  const user: LxStoreState['user'] = yield select<LxStoreState>(s => s.user);

  yield put(
    RequestActions.getAuthed<CourseCertificateType>(
      Urls.COURSE_CERTIFICATE(action.payload.courseId, user.id),
      data => CourseActions.fetchCourseCertificateSuccess(action.payload.courseId, data),
      CourseActionTypes.FETCH_COURSE_CERTIFICATE_FAILURE
    )
  );
}

function* watchFetchCoursesProgress() {
  yield takeLatest(CourseActionTypes.FETCH_COURSES_PROGRESS, handleFetchCoursesProgress);
}

function* watchFetchUpcomingConferencesSuccess() {
  yield takeLatest(
    ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_SUCCESS,
    handleFetchUpcomingConferencesSuccess
  );
}

export function* watchDidOpenCourse() {
  yield takeLatest(CourseActionTypes.DID_OPEN_COURSE, handleDidOpenCourse);
}

function* watchFetchCourseCertificate() {
  yield takeLatest(CourseActionTypes.FETCH_COURSE_CERTIFICATE, handleFetchCourseCertificate);
}

const courseSagas = [
  fork(watchFetchCoursesProgress),
  fork(watchFetchSyncCourse),
  fork(watchDidOpenCourse),
  fork(watchFetchUpcomingConferencesSuccess),
  fork(watchUpdateCourseOpened),
  fork(watchUpdateCoursesUnlocked),
  fork(watchFetchCourseCertificate),
  fork(watchFetchCourseLessons),
  fork(watchUpdateCourseCompleted),
  fork(watchCalculateCourseCompletion)
];

export { courseSagas };
