import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { appVersion } from '@maggie/config/appVersion';
import { getConnectionType } from '@maggie/cordova/network_utils';
import type { UserState } from '@maggie/store/user/types';

import type {
  EventInteraction,
  EventInteractionName,
  ViewInteraction,
  VisitInteraction
} from './hippo-interaction-types';
import { TrackerInteractions } from './tracker-interactions';

const trackEvent = (data?: EventInteraction | EventInteractionName) => {
  if (!data) {
    return;
  }

  if (typeof data === 'string') {
    data = { name: data, type: 'event' };
  }

  return TrackerInteractions.trackInteraction({ ...data, type: 'event' });
};

const trackVisit = (
  data?: VisitInteraction,
  user?: UserState,
  deviceToken?: string,
  locale?: string,
  sc_user_id?: string | null
) => {
  if (!user) {
    ErrorLogger.captureEvent('Visit with no user', 'critical', { data, user, deviceToken });
  }

  TrackerInteractions.trackVisitInteraction(data, user, deviceToken, locale);

  if (!!user && !!user.id) {
    itly.identify(user.id, {
      email: user.email,
      application_id: user.applicationId,
      application_name: user.applicationName,
      roles: user.roles,
      appVersion,
      sc_user_id: sc_user_id || undefined,
      language: locale || 'en'
    });
    itly.group(user.applicationId, {
      amplitudeType: 'Application',
      email: user.email,
      application_id: user.applicationId,
      application_name: user.applicationName
    });
    itly.appOpen();
  }
};

const trackView = (data: ViewInteraction) => {
  const trackedView = TrackerInteractions.trackViewInteraction(data);
  //analytics
  if (!!data.name) {
    itly.page('app', data.name, {
      appVersion,
      network: getConnectionType()
    });
  }
  return trackedView;
};

const Tracker = {
  trackView,
  trackEvent,
  trackVisit
};

export { Tracker };
