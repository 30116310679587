import type { GameActionsUnionType } from '../types';
import { GameActionTypes } from '../actions';

export const initialGameLoadingState: boolean = true;

const gameLoadingReducer = (
  state: boolean = initialGameLoadingState,
  action: GameActionsUnionType
) => {
  switch (action.type) {
    case GameActionTypes.FETCH_GAME_DATA:
      return true;
    case GameActionTypes.FETCH_GAME_DATA_SUCCESS:
    case GameActionTypes.FETCH_GAME_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export { gameLoadingReducer };
