import type { LockedDialogState } from './types';
import type { Reducer } from 'redux';
import { initialLockedDialogState } from './constants';
import type { LockedDialogActions } from './actions';
import { LockedDialogActionTypes } from './actions';
import type { ActionsUnion } from '@edapp/utils';

export const lockedDialogReducer: Reducer<LockedDialogState> = (
  state = initialLockedDialogState,
  action: ActionsUnion<typeof LockedDialogActions>
) => {
  switch (action.type) {
    case LockedDialogActionTypes.OPEN_LOCKED_DIALOG: {
      const { isOpen, title, contentText, redirectParams, acceptText } = action.payload;
      return {
        ...state,
        isOpen,
        title,
        contentText,
        redirectParams,
        acceptText
      };
    }

    case LockedDialogActionTypes.CLOSE_LOCKED_DIALOG: {
      // clear whole state on close dialog so when its reopened it will not show previous state
      return { ...initialLockedDialogState };
    }

    default:
      return state;
  }
};
