import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { brainBoostBackground } from '@maggie/components/brainboost/constants';
import { SpacedRepetitionCSS } from '@maggie/core/lessons/quiz_styles';
import { BrainboostThomasPlayer } from '@maggie/core/lessons/thomas-player/brainboost-thomas-player';
import { InteractionApi } from '@maggie/core/tracker/interaction-api';
import { Urls } from '@maggie/store/constants';
import type { ConfigurationType } from '@maggie/store/courseware/lessons/types';
import type { LxStoreState } from '@maggie/store/types';

import { BrainBoostActionTypes, BrainBoostActions } from './actions';
import { BrainBoostSelectors } from './selectors';
import type {
  BrainBoostActionsUnionType,
  BrainBoostSession,
  BrainBoostSlides,
  CompletedSlide
} from './types';

type BrainBoostAction<ActionType extends string> = ActionFromActionType<
  BrainBoostActionsUnionType,
  ActionType
>;

function* handleFetchBrainBoostSession() {
  const skipSlides: CompletedSlide[] = yield select(BrainBoostSelectors.getCompletedSlides);
  yield put(
    RequestActions.postAuthed<BrainBoostSession>(
      Urls.BRAIN_BOOST_SESSION,
      BrainBoostActionTypes.FETCH_BRAIN_BOOST_SESSION_SUCCESS,
      BrainBoostActionTypes.FETCH_BRAIN_BOOST_SESSION_FAILURE,
      false,
      skipSlides ? { skipSlides } : {}
    )
  );
}

function* handleFetchBrainBoostSlides() {
  const skipSlides: CompletedSlide[] = yield select(BrainBoostSelectors.getCompletedSlides);
  yield put(
    RequestActions.postAuthed<BrainBoostSlides>(
      Urls.BRAIN_BOOST_SLIDES,
      BrainBoostActionTypes.FETCH_BRAIN_BOOST_SLIDES_SUCCESS,
      BrainBoostActionTypes.FETCH_BRAIN_BOOST_SLIDES_FAILURE,
      false,
      skipSlides ? { skipSlides } : {}
    )
  );
}

function* handleBackbonebrainBoostScore(
  action: BrainBoostAction<BrainBoostActionTypes.SCORE_QUIZ_PLAY>
) {
  yield put(BrainBoostActions.loadingBrainBoostScore());

  //waiting for slide interactions to be processed before session is scored
  try {
    yield InteractionApi.waitForQueueEmpty({ timeout: 5000, minimum: 1000 });
  } catch (err) {
    ErrorLogger.captureEvent('Failed to empty queue in brainboost', 'error', { err });
  }

  yield put(
    BrainBoostActions.scoreBrainBoost({
      score: action.payload.score,
      totalSlides: action.payload.totalSlides
    })
  );
}

function* handleLaunchBrainBoost() {
  const quiz: BrainBoostSlides | null = yield select<LxStoreState>(BrainBoostSelectors.getQuiz);
  if (!quiz) {
    ErrorLogger.captureEvent('launched a brainboost that does not exist?', 'error', {});
    return;
  }

  const configuration: ConfigurationType = {
    index: 0,
    isAudio: true,
    isDebug: false,
    isOnline: true,
    lessonPreviewConfigurationHeight: 0,
    lessonPreviewConfigurationWidth: 0,
    narration: {},
    scale: 1,
    slides: quiz.slides,
    state: '',
    duration: 30,
    styleConfiguration: {
      direction: 'ltr',
      indicator: true,
      disableInteractionSummary: false,
      disableLessonScore: false,
      hasScormExitButton: false,
      language: 'en',
      logo: '',
      minimumScore: 0,
      pagination: false,
      preventLogoutOnExit: false,
      soundDisabled: false,
      pageNumbers: true,
      hasAnswerFeedback: true,
      enableCustomCSS: true,
      customCSS: SpacedRepetitionCSS,
      background: brainBoostBackground,
      colors: {
        text: 'white'
      },
      preferences: {
        useParentBackgroundImage: false,
        useParentColors: false,
        useParentCustomCss: false,
        useParentLogo: false
      }
    },
    title: ''
  };

  const brainboostPlayer = new BrainboostThomasPlayer(
    quiz.sessionId,
    quiz.slides,
    configuration,
    quiz.thomasVersion
  );
  try {
    yield call(brainboostPlayer.open);
  } catch {
    window.__router.navigate('brainBoost', {});
  }
}

function* handleScoreBrainBoostSession() {
  yield put(BrainBoostActions.fetchBrainBoostSession());
}

function* watchBrainboostBackboneScore() {
  yield takeLatest(BrainBoostActionTypes.SCORE_QUIZ_PLAY, handleBackbonebrainBoostScore);
}

function* watchFetchBrainBoostSession() {
  yield takeLatest(BrainBoostActionTypes.FETCH_BRAIN_BOOST_SESSION, handleFetchBrainBoostSession);
}

function* watchFetchBrainBoostSlides() {
  yield takeLatest(BrainBoostActionTypes.FETCH_BRAIN_BOOST_SLIDES, handleFetchBrainBoostSlides);
}

function* watchLaunchBrainBoost() {
  yield takeLatest(BrainBoostActionTypes.LAUNCH_BRAIN_BOOST, handleLaunchBrainBoost);
}

function* watchBrainBoostCompleted() {
  yield takeLatest(BrainBoostActionTypes.SCORE_BRAIN_BOOST, handleScoreBrainBoostSession);
}

export const brainBoostSagas = [
  fork(watchLaunchBrainBoost),
  fork(watchFetchBrainBoostSlides),
  fork(watchFetchBrainBoostSession),
  fork(watchBrainBoostCompleted),
  fork(watchBrainboostBackboneScore)
];
