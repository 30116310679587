import type { RequestTypes } from '@edapp/request';
import type { ActionsUnion, ActionWithPayload } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { ContentLibrarySectionType, ContentLibraryCourseType, ErrorType } from './types';

enum ContentLibraryActionTypes {
  FETCH_SECTIONS = 'CONTENT_LIBRARY_FETCH_SECTIONS',
  FETCH_SECTIONS_SUCCESS = 'CONTENT_LIBRARY_FETCH_SECTIONS_SUCCESS',
  FETCH_SECTIONS_FAILURE = 'CONTENT_LIBRARY_FETCH_SECTIONS_FAILURE',

  FETCH_SECTION = 'CONTENT_LIBRARY_FETCH_SECTION',
  FETCH_SECTION_SUCCESS = 'CONTENT_LIBRARY_FETCH_SECTION_SUCCESS',
  FETCH_SECTION_FAILURE = 'CONTENT_LIBRARY_FETCH_SECTION_FAILURE',

  FETCH_COURSES = 'CONTENT_LIBRARY_FETCH_COURSES',
  FETCH_COURSES_SUCCESS = 'CONTENT_LIBRARY_FETCH_COURSES_SUCCESS',
  FETCH_COURSES_FAILURE = 'CONTENT_LIBRARY_FETCH_COURSES_FAILURE',

  FETCH_COURSE_ITEM = 'CONTENT_LIBRARY_FETCH_COURSE_ITEM',
  FETCH_COURSE_ITEM_SUCCESS = 'CONTENT_LIBRARY_FETCH_COURSE_ITEM_SUCCESS',
  FETCH_COURSE_ITEM_FAILURE = 'CONTENT_LIBRARY_FETCH_COURSE_ITEM_FAILURE',

  ADD_COURSES_TO_LIBRARY = 'CONTENT_LIBRARY_ADD_COURSES_TO_LIBRARY',
  ADD_COURSES_TO_LIBRARY_SUCCESS = 'CONTENT_LIBRARY_ADD_COURSES_TO_LIBRARY_SUCCESS',
  ADD_COURSES_TO_LIBRARY_FAILURE = 'CONTENT_LIBRARY_ADD_COURSES_TO_LIBRARY_FAILURE',

  REMOVE_COURSES_FROM_LIBRARY = 'CONTENT_LIBRARY_REMOVE_COURSES_FROM_LIBRARY',
  REMOVE_COURSES_FROM_LIBRARY_SUCCESS = 'CONTENT_LIBRARY_REMOVE_COURSES_FROM_LIBRARY_SUCCESS',
  REMOVE_COURSES_FROM_LIBRARY_FAILURE = 'CONTENT_LIBRARY_REMOVE_COURSES_FROM_LIBRARY_FAILURE',

  FLAG_COURSE_FROM_CONTENT_LIBRARY = 'CONTENT_LIBRARY_FLAG_COURSE_FROM_CONTENT_LIBRARY',
  FLAG_COURSE_FROM_CONTENT_LIBRARY_SUCCESS = 'CONTENT_LIBRARY_FLAG_COURSE_FROM_CONTENT_LIBRARY_SUCCESS',
  FLAG_COURSE_FROM_CONTENT_LIBRARY_FAILURE = 'CONTENT_LIBRARY_FLAG_COURSE_FROM_CONTENT_LIBRARY_FAILURE',

  SET_SEARCH = 'CONTENT_LIBRARY_SET_SEARCH'
}

const ContentLibraryActions = {
  fetchSections: (page: number, pageSize: number, coursesPageSize: number) =>
    createAction(ContentLibraryActionTypes.FETCH_SECTIONS, { page, pageSize, coursesPageSize }),
  fetchSectionsSuccess: (
    page: number,
    response: RequestTypes.PaginatedResponse<ContentLibrarySectionType>
  ) => createAction(ContentLibraryActionTypes.FETCH_SECTIONS_SUCCESS, { page, response }),

  fetchSection: (sectionId: string, coursesPage: number, coursesPageSize: number) =>
    createAction(ContentLibraryActionTypes.FETCH_SECTION, {
      sectionId,
      coursesPage,
      coursesPageSize
    }),
  fetchSectionSuccess: (
    sectionId: string,
    coursesPage: number,
    response: Omit<ContentLibrarySectionType, 'id'>
  ) =>
    createAction(ContentLibraryActionTypes.FETCH_SECTION_SUCCESS, {
      sectionId,
      coursesPage,
      response
    }),

  fetchCourses: (
    page: number,
    pageSize: number,
    searchTerm?: string,
    filterByTagsIds?: string[],
    getOnlyUpdated?: boolean,
    courseIds?: string[]
  ) =>
    createAction(ContentLibraryActionTypes.FETCH_COURSES, {
      page,
      pageSize,
      searchTerm,
      filterByTagsIds,
      getOnlyUpdated,
      courseIds
    }),
  fetchCoursesSuccess: (
    page: number,
    response: RequestTypes.PaginatedResponse<ContentLibraryCourseType>
  ) => createAction(ContentLibraryActionTypes.FETCH_COURSES_SUCCESS, { page, response }),

  fetchCourseItem: (courseId: string) =>
    createAction(ContentLibraryActionTypes.FETCH_COURSE_ITEM, { courseId }),

  addCoursesToLibrary: (courseIds: string[]) =>
    createAction(ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY, { courseIds }),

  removeCoursesFromLibrary: (courseIds: string[]) =>
    createAction(ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY, { courseIds }),

  flagCourseFromLibrary: (courseId: string, shouldRemoveFromMyLibrary: boolean) =>
    createAction(ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY, {
      courseId,
      shouldRemoveFromMyLibrary
    }),

  setSearch: (searchTerm: string) =>
    createAction(ContentLibraryActionTypes.SET_SEARCH, { searchTerm })
};

type ContentLibraryActionUnionType =
  | ActionsUnion<typeof ContentLibraryActions>
  | ActionWithPayload<ContentLibraryActionTypes.FETCH_SECTIONS_FAILURE, ErrorType>
  | ActionWithPayload<ContentLibraryActionTypes.FETCH_SECTION_FAILURE, ErrorType>
  | ActionWithPayload<ContentLibraryActionTypes.FETCH_COURSES_FAILURE, ErrorType>
  | ActionWithPayload<ContentLibraryActionTypes.FETCH_COURSE_ITEM_FAILURE, ErrorType>
  | ActionWithPayload<ContentLibraryActionTypes.FETCH_COURSE_ITEM_SUCCESS, ContentLibraryCourseType>
  | ActionWithPayload<ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_FAILURE, ErrorType>
  | ActionWithPayload<ContentLibraryActionTypes.ADD_COURSES_TO_LIBRARY_SUCCESS, {}>
  | ActionWithPayload<ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY_SUCCESS, {}>
  | ActionWithPayload<ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY_FAILURE, ErrorType>
  | ActionWithPayload<ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY_FAILURE, ErrorType>
  | ActionWithPayload<ContentLibraryActionTypes.FLAG_COURSE_FROM_CONTENT_LIBRARY_SUCCESS, {}>;

export { ContentLibraryActions, ContentLibraryActionTypes };
export type { ContentLibraryActionUnionType };
