import { fork, put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import { CustomAchievementsActionTypes, CustomAchievementsActions } from './actions';
import type { CustomAchievementsListItem } from './types';

function* handleFetchCustomAchievementList() {
  yield put(
    RequestActions.getAuthed<CustomAchievementsListItem[]>(
      Urls.CUSTOM_ACHIEVEMENTS,
      CustomAchievementsActions.fetchCustomAchievementsListSuccess,
      CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE
    )
  );
}

function* watchFetchCustomAchievementList() {
  yield takeLatest(
    CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST,
    handleFetchCustomAchievementList
  );
}

export const customAchievementsSagas = [fork(watchFetchCustomAchievementList)];
