import { combineReducers } from 'redux';
import { lotteryReducer } from './lottery/reducer';
import { prizesReducer } from './prizes/reducer';
import { winnersReducer } from './winners/reducer';
import { playsReducer } from './plays/reducer';
import { gameLoadingReducer } from './loading/reducer';
import type { GamesState } from './types';

const gameReducer = combineReducers<GamesState>({
  lottery: lotteryReducer,
  prizes: prizesReducer,
  winners: winnersReducer,
  plays: playsReducer,
  loading: gameLoadingReducer
});

export { gameReducer };
