import type { LessonsState } from './types';

export const initialLessonsState: LessonsState = {
  lessons: {},
  lessonsProgress: {},
  lessonsAttempts: {},
  fetchLessonErrorCode: undefined,
  lastLessonsScore: {},
  leaderboardSummaryDialogIsOpen: false,
  summaryDialogOpen: false,
  lastAttempt: null
};
