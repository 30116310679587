import * as React from 'react';

/**
 * This hook saves the scroll position of a div. It persists in the local storage,
 * so no matter if the ref is destroyed or not the value will be restored when wished.
 */
export const usePersistedScroll = (key: string, ref: React.RefObject<HTMLDivElement>) => {
  const handleScroll = (e: UIEvent) => {
    setScrollPosition(key, (e.currentTarget as HTMLDivElement).scrollTop);
  };

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener('scroll', handleScroll);

    return () => {
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, [ref.current]);

  const stayScrolled = React.useCallback(() => {
    if (!ref.current) {
      return;
    }

    const top = getScrollPosition(key);
    ref.current.scrollTop = top;
  }, [key]);

  return { stayScrolled };
};

const PREFIX = `ed-persisted-scroll`;
const STORAGE_KEY = (k: string) => `${PREFIX}-${k}`;

const setScrollPosition = (k: string, value: number) => {
  window.localStorage.setItem(STORAGE_KEY(k), value + '');
};

const getScrollPosition = (k: string) => {
  return +(window.localStorage.getItem(STORAGE_KEY(k)) || 0);
};

/**
 * Used to clear persisted scroll positions when user logs out
 */
export const clearScrollPositions = () => {
  Object.keys(window.localStorage).forEach(key => {
    if (key.match(PREFIX)) {
      window.localStorage.setItem(key, '0');
    }
  });
};
