import { formatInTimeZone } from 'date-fns-tz';
import type { BackgroundColor } from '@edapp/ed-components/src/common/status-label/types';
import { ObservationStatus } from './constants';
import { format } from 'date-fns';

export const getStatusColor = (
  status: ObservationStatus
): {
  text: string;
  color: BackgroundColor;
} => {
  switch (status) {
    case ObservationStatus.Passed:
      return {
        text: 'Passed',
        color: 'green'
      };
    case ObservationStatus.Failed:
      return {
        text: 'Try Again',
        color: 'orange'
      };
    case ObservationStatus.InProgress:
      return {
        text: 'In Progress',
        color: 'blue'
      };
    case ObservationStatus.Unattempted:
    default:
      return {
        text: 'Unattempted',
        color: 'black'
      };
  }
};

export const getDateFormat = (date: string, timeZone?: string): string => {
  const timeFormat = 'do MMM, yyyy';
  return !!timeZone
    ? formatInTimeZone(new Date(date), timeZone, timeFormat)
    : format(new Date(date), timeFormat);
};

export const isNewObservation = (id: string) => id === 'new';
