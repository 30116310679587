import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { UserSelectors } from '@maggie/store/user/selectors';

import { GroupTrainingActionTypes } from './actions';
import type { GroupTrainingActionsUnionType } from './types';

type GroupTrainingActions<ActionType extends string> = ActionFromActionType<
  GroupTrainingActionsUnionType,
  ActionType
>;

function* handleRegisterGroupTrainingSession(
  action: GroupTrainingActions<GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION>
) {
  const { sessionId } = action.payload;
  const appUserId: ReturnType<typeof UserSelectors.getId> = yield select(UserSelectors.getId);
  yield put(
    RequestActions.postAuthed(
      Urls.GROUP_TRAINING_SESSION(sessionId),
      GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION_SUCCESS,
      GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION_FAILURE,
      true,
      { appUserId }
    )
  );
}

function* watchRegisterGroupTrainingSession() {
  yield takeLatest(
    GroupTrainingActionTypes.REGISTER_GROUP_TRAINING_SESSION,
    handleRegisterGroupTrainingSession
  );
}

export const groupTrainingSagas = [fork(watchRegisterGroupTrainingSession)];
