import { createAction } from '@edapp/utils';

export enum PerformanceActionTypes {
  FETCH_USER_PERFORMANCE = 'FETCH_USER_PERFORMANCE',
  FETCH_USER_PERFORMANCE_SUCCESS = 'FETCH_USER_PERFORMANCE_SUCCESS',
  FETCH_USER_PERFORMANCE_FAILURE = 'FETCH_USER_PERFORMANCE_FAILURE',
  RESET_CURRENT_PAGE = 'RESET_CURRENT_PAGE'
}

const PerformanceActions = {
  fetchUserPerformance: () => createAction(PerformanceActionTypes.FETCH_USER_PERFORMANCE),
  resetCurrentPage: () => createAction(PerformanceActionTypes.RESET_CURRENT_PAGE)
};

export default PerformanceActions;
