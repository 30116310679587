import type { Store } from 'redux';

import ConfigActions from '@maggie/store/config/actions';
import type { LxStoreState } from '@maggie/store/types';

const SLOW_LIMIT_MS = 7000;

export class NetworkMonitor {
  private networkLatencyMs: number[];
  private store: Store<LxStoreState>;

  public constructor(store: Store<LxStoreState>) {
    this.store = store;
    this.networkLatencyMs = [];
  }

  public addNetworkLatency = (timeMs: number) => {
    /**
     * Keep only ten items - for easier/faster calculation of average.
     */
    if (this.networkLatencyMs.length >= 10) {
      this.networkLatencyMs = this.networkLatencyMs.slice(1);
    }

    this.networkLatencyMs.push(timeMs);

    const isSlowConnection = this.isSlowConnection();
    if (isSlowConnection !== this.store.getState().config.isSlowConnection) {
      this.store.dispatch(ConfigActions.setIsSlowConnection(isSlowConnection));
    }
  };

  private isSlowConnection = () => {
    const totalTimeMs = this.networkLatencyMs.reduce((acc, item) => acc + item, 0);
    return totalTimeMs / this.networkLatencyMs.length >= SLOW_LIMIT_MS;
  };
}
