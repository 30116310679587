import { fork, put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType, ActionsUnion } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';

import type { MagicLinkActions } from './actions';
import { MagicLinkActionTypes } from './actions';

type Action<ActionType extends string> = ActionFromActionType<
  ActionsUnion<typeof MagicLinkActions>,
  ActionType
>;

function* handleSendMagicLink(action: Action<MagicLinkActionTypes.SEND_MAGIC_LINK>) {
  const { email } = action.payload;
  yield put(
    RequestActions.postUnauthed(
      Urls.MAGIC_LINK,
      MagicLinkActionTypes.SEND_MAGIC_LINK_SUCCESS,
      MagicLinkActionTypes.SEND_MAGIC_LINK_FAILURE,
      undefined,
      { email },
      {},
      undefined,
      false
    )
  );
}

function* watchSendMagicLink() {
  yield takeLatest(MagicLinkActionTypes.SEND_MAGIC_LINK, handleSendMagicLink);
}

const magicLinkSagas = [fork(watchSendMagicLink)];

export { magicLinkSagas };
