import { createAction } from '@edapp/utils';

export enum ConfigActionTypes {
  SET_PLATFORM = 'SET_PLATFORM',
  SET_RESTRICT_TO_COURSE_SCREEN = 'SET_RESTRICT_TO_COURSE_SCREEN',
  SET_RESTRICT_TO_LESSON_SCREEN = 'SET_RESTRICT_TO_LESSON_SCREEN',
  SET_MOBILE_PUSH_TOKEN = 'SET_MOBILE_PUSH_TOKEN',
  SET_ONLINE = 'SET_ONLINE',
  SET_IS_SLOW_CONNECTION = 'SET_IS_SLOW_CONNECTION',
  SET_ORIENTATION = 'SET_ORIENTATION'
}

const ConfigActions = {
  setPlatform: (platform: 'ios' | 'android' | 'browser') =>
    createAction(ConfigActionTypes.SET_PLATFORM, { platform }),

  setRestrictToCourseScreen: (restricted: boolean) =>
    createAction(ConfigActionTypes.SET_RESTRICT_TO_COURSE_SCREEN, { restricted }),

  setRestrictToLessonScreen: (restricted: boolean) =>
    createAction(ConfigActionTypes.SET_RESTRICT_TO_LESSON_SCREEN, { restricted }),

  setMobilePushToken: (token: string) =>
    createAction(ConfigActionTypes.SET_MOBILE_PUSH_TOKEN, { token }),

  /**
   * `wasOnline` - the previous state of redux store before the online status updates
   */
  setOnline: (online: boolean, wasOnline: boolean) =>
    createAction(ConfigActionTypes.SET_ONLINE, { online, wasOnline }),

  setIsSlowConnection: (isSlowConnection: boolean) =>
    createAction(ConfigActionTypes.SET_IS_SLOW_CONNECTION, { isSlowConnection }),

  setOrientation: (orientation: OrientationType) =>
    createAction(ConfigActionTypes.SET_ORIENTATION, { orientation })
};

export default ConfigActions;
