import type { RatingDialogState } from './types';
import type { Reducer } from 'redux';
import { initialRatingDialogState } from './constants';
import type { RatingDialogActions } from './actions';
import { RatingDialogActionTypes } from './actions';
import type { ActionsUnion } from '@edapp/utils';

export const ratingDialogReducer: Reducer<RatingDialogState> = (
  state = initialRatingDialogState,
  action: ActionsUnion<typeof RatingDialogActions>
) => {
  switch (action.type) {
    case RatingDialogActionTypes.OPEN_RATING_DIALOG: {
      const { backgroundImage, title, rating, review } = action.payload;

      return {
        ...state,
        isOpen: true,
        backgroundImage,
        title,
        rating,
        review
      };
    }

    case RatingDialogActionTypes.CLOSE_RATING_DIALOG: {
      return { ...initialRatingDialogState, isOpen: false };
    }

    default:
      return state;
  }
};
