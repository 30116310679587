import { canUseDOM } from '@edapp/utils';

import { LOCAL_STORAGE_FEATURE_FLAGS_KEY } from './constants';
import type { FeatureFlags, FeatureFlagsResponse, FlagKeys } from './types';

export function getLocalStorageFlags<Flags extends FlagKeys>(): FeatureFlags<Flags> | undefined {
  if (!canUseDOM()) {
    return undefined;
  }

  try {
    const localStorageValue = localStorage.getItem(LOCAL_STORAGE_FEATURE_FLAGS_KEY);

    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    }
  } catch (err) {
    console.error(err);
  }
}

export function setLocalStorageFlags<Flags extends FlagKeys>(flags: FeatureFlags<Flags>): void {
  if (!canUseDOM()) {
    return;
  }

  const localStorageValue = JSON.stringify(flags);

  try {
    localStorage.setItem(LOCAL_STORAGE_FEATURE_FLAGS_KEY, localStorageValue);
  } catch (error) {
    console.error('Failed to store feature flags', { error });
    localStorage.removeItem(LOCAL_STORAGE_FEATURE_FLAGS_KEY);
  }
}

export function addExpiry(timestamp: number, seconds: number = 60) {
  return timestamp + seconds * 1000;
}

export function mergeFeatureFlags<Flags extends FlagKeys>(
  previous: FeatureFlags<Flags>,
  next: FeatureFlagsResponse<Flags>,
  addExpirySeconds?: number
): FeatureFlags<Flags> {
  return next.reduce<FeatureFlags<Flags>>(
    (acc, { key, value, variationValue }) => ({
      ...acc,
      [key]: {
        enabled: value,
        expiry: addExpiry(Date.now(), addExpirySeconds),
        variationValue: variationValue || undefined
      }
    }),
    previous
  );
}
