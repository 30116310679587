import { ContentLibraryActionTypes } from '@edapp/content-library';
import type { DictionaryType } from '@edapp/utils';
import { RatingDialogActionTypes } from '@maggie/store/rating-dialog/actions';
import type { RatingDialogActionUnionType } from '@maggie/store/rating-dialog/types';
import { StarActionTypes } from '@maggie/store/star/actions';
import type { StarActionUnionType } from '@maggie/store/star/types';

import { CourseActionTypes } from './actions';
import { initialCoursesState } from './constants';
import {
  courseProgressCompleteUpdated,
  courseProgressOpenUpdated,
  courseProgressUnlockUpdated,
  courseRemainingStarsUpdated,
  courseUserRatingUpdated,
  setCourseHasBeenPromptedForRating,
  spreadCourseProgressUpdates
} from './reducerUtils';
import type { CourseActionsUnionType, CourseProgressType, CourseType, CoursesState } from './types';

export const coursesReducer = (
  state: CoursesState = initialCoursesState,
  action: CourseActionsUnionType | StarActionUnionType | RatingDialogActionUnionType
): CoursesState => {
  switch (action.type) {
    case CourseActionTypes.FETCH_SYNC_COURSE: {
      return { ...state, courseSyncLoading: true, courseSyncErrorCode: undefined };
    }

    case CourseActionTypes.FETCH_SYNC_COURSE_COMPLETED: {
      return { ...state, courseSyncLoading: false };
    }

    case CourseActionTypes.FETCH_SYNC_COURSE_FAILURE: {
      const errorCode = action.payload?.code;
      return { ...state, courseSyncErrorCode: errorCode };
    }

    case CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS: {
      const { courseInfo, courseProgress } = action.payload;
      const currentProgress = state.coursesProgress[courseProgress.courseId];
      const course = {
        ...courseInfo,
        lastUpdate: new Date().toISOString(),
        leaderboard: action.payload.leaderboard
      };
      return {
        ...state,
        courses: {
          ...state.courses,
          [course.id]: {
            ...state.courses[course.id],
            ...course
          }
        },
        coursesProgress: {
          ...state.coursesProgress,
          [courseProgress.courseId]: {
            ...courseProgress,
            ...spreadCourseProgressUpdates(currentProgress, courseProgress)
          }
        },
        courseSyncErrorCode: undefined,
        last_update: new Date()
      };
    }

    case CourseActionTypes.FETCH_COURSES_PROGRESS_SUCCESS: {
      const initialState: DictionaryType<CourseProgressType> = {};
      const coursesWithProgressFormatted = action.payload.items.reduce((obj, progress) => {
        const currentProgress = state.coursesProgress[progress.courseId];

        obj[progress.courseId] = {
          ...progress,
          ...spreadCourseProgressUpdates(currentProgress, progress)
        };
        return obj;
      }, initialState);

      return {
        ...state,
        coursesProgress: { ...state.coursesProgress, ...coursesWithProgressFormatted },
        last_update: new Date()
      };
    }

    case CourseActionTypes.UPDATE_COURSE_OPENED: {
      const { courseId } = action.payload;
      const progress = state.coursesProgress[courseId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        coursesProgress: {
          ...state.coursesProgress,
          ...courseProgressOpenUpdated(progress, courseId)
        }
      };
    }

    case CourseActionTypes.UPDATE_COURSE_COMPLETED: {
      const {
        courseId,
        completed,
        percentageCompleted,
        lessonsCompleted,
        lessonsUnlocked
      } = action.payload;
      const progress = state.coursesProgress[courseId];

      if (progress == null) {
        return state;
      }

      return {
        ...state,
        coursesProgress: {
          ...state.coursesProgress,
          ...courseProgressCompleteUpdated(
            progress,
            courseId,
            completed,
            percentageCompleted,
            lessonsCompleted,
            lessonsUnlocked
          )
        }
      };
    }

    case CourseActionTypes.UPDATE_COURSES_UNLOCK: {
      const { items } = action.payload;
      const coursesProgress = state.coursesProgress;

      return {
        ...state,
        coursesProgress: {
          ...state.coursesProgress,
          ...courseProgressUnlockUpdated(coursesProgress, items)
        }
      };
    }

    case StarActionTypes.REWARD_STARS_FROM_SLIDE: {
      const { stars, courseId, lessonId } = action.payload;
      const course = state.courses[courseId];

      if (course == null) {
        return state;
      }

      return {
        ...state,
        courses: {
          ...state.courses,
          ...courseRemainingStarsUpdated(course, lessonId, stars)
        }
      };
    }

    case RatingDialogActionTypes.SET_COURSE_RATING: {
      const { courseId, rating, review } = action.payload;
      const course = state.courses[courseId];

      if (course == null) {
        return state;
      }

      return {
        ...state,
        courses: {
          ...state.courses,
          ...courseUserRatingUpdated(course, rating, review)
        }
      };
    }

    case ContentLibraryActionTypes.REMOVE_COURSES_FROM_LIBRARY: {
      const { courseIds } = action.payload;

      const initialState: DictionaryType<CourseType> = {};
      const newCourses = Object.keys(state.courses).reduce((result, courseKey) => {
        const course = state.courses[courseKey];

        if (courseIds.includes(course.id)) {
          return result;
        }

        result[courseKey] = { ...course };
        return result;
      }, initialState);

      return { ...state, courses: { ...newCourses } };
    }

    case CourseActionTypes.HAS_BEEN_PROMPT_RATING: {
      const { courseId } = action.payload;
      const course = state.courses[courseId];

      if (course == null) {
        return state;
      }

      return {
        ...state,
        courses: {
          ...state.courses,
          ...setCourseHasBeenPromptedForRating(course)
        }
      };
    }

    case CourseActionTypes.FETCH_COURSE_CERTIFICATE_SUCCESS: {
      const { courseId, url, shareUrl, certificateLogo } = action.payload;
      const course = state.courses[courseId];

      if (course == null) {
        return state;
      }

      return {
        ...state,
        courses: {
          ...state.courses,
          [course.id]: {
            ...course,
            certificateUrl: url,
            certificateShareUrl: shareUrl,
            certificateLogo
          }
        }
      };
    }

    default:
      return state;
  }
};
