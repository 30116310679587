import { intersection } from 'lodash';
import { all, put, select } from 'redux-saga/effects';

import type { DictionaryType } from '@edapp/utils';
import { FileManager } from '@maggie/cordova/file';
import type { AssetEntityType } from '@maggie/core/lessons/asset';
import { Asset } from '@maggie/core/lessons/asset';

import type { CollectionType } from '../courseware/collections/types';
import type { CourseType } from '../courseware/courses/types';
import type { LessonType } from '../courseware/lessons/types';
import type { PlaylistItemType } from '../courseware/playlists/types';
import { OfflineActions } from './actions';
import { OfflineSelectors } from './selectors';

const deleteFolder = (entityType: AssetEntityType, entityId: string) => {
  if (!window.__scWebview.platform) {
    // CORDOVA
    return FileManager.deleteFolder(
      `${FileManager.dataDirectory}downloads/${entityType}/${entityId}`
    );
  } else {
    // SC WEBVIEW
    const asset = new Asset(null, entityType, entityId);
    return window.__scWebview.deleteGroupOfAssets(asset.folderPath);
  }
};

/**
 * This saga will look up all dangling courses, collections and playlists.
 * - If a course has no offline lessons, it will be removed.
 * - If a collection has no offline courses, it will be removed.
 * - If a playlist has no offline courses, it will be removed.
 */
export function* handleCleanOfflineFiles(): any {
  const lessons: DictionaryType<LessonType> = yield select(OfflineSelectors.getLessons);
  const courses: DictionaryType<CourseType> = yield select(OfflineSelectors.getCourses);
  const collections: DictionaryType<CollectionType> = yield select(OfflineSelectors.getCollections);
  const playlists: DictionaryType<PlaylistItemType> = yield select(OfflineSelectors.getPlaylists);

  // Get all course ids for lessons
  const lessonCourseIds = Object.values(lessons).map(l => l.courseId);

  // Find courses without offline lessons
  const removeCourses = Object.keys(courses).filter(id => !lessonCourseIds.includes(id));

  // Find collections without no offline courses
  const removeCollections = Object.values(collections)
    .filter(collection => {
      const collectionCourses = collection.courses.map(c => c.courseId);
      return intersection(collectionCourses, lessonCourseIds).length === 0;
    })
    .map(collection => collection.id);

  // Find playlists without no offline courses
  const removePlaylists = Object.values(playlists)
    .filter(playlist => {
      const playlistCourses = playlist.courses.map(c => c.courseId);
      return intersection(playlistCourses, lessonCourseIds).length === 0;
    })
    .map(playlist => playlist.id);

  // Delete Courses
  try {
    yield Promise.all(removeCourses.map(id => deleteFolder('courses', id)));
    yield all(removeCourses.map(id => put(OfflineActions.deleteCourseOfflineSuccess(id))));
  } catch (e) {
    console.warn('Error cleaning courses', e);
  }

  // Delete Collections
  try {
    yield Promise.all(removeCollections.map(id => deleteFolder('collections', id)));
    yield all(removeCollections.map(id => put(OfflineActions.deleteCollectionOfflineSuccess(id))));
  } catch (e) {
    console.warn('Error cleaning collections', e);
  }

  // Delete Playlists
  try {
    yield Promise.all(removePlaylists.map(id => deleteFolder('playlists', id)));
    yield all(removePlaylists.map(id => put(OfflineActions.deletePlaylistOfflineSuccess(id))));
  } catch (e) {
    console.warn('Error cleaning playlists', e);
  }
}
