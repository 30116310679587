import { addMinutes } from 'date-fns';
import { t } from 'i18next';

import { generateTitle } from '@edapp/translations/src/i18n';
import type { SocialLearningSlide } from '@maggie/core/lessons/social_learning/types';
import type { SlideType } from '@maggie/store/courseware/lessons/types';
import { getLocationPath, parseQueryString } from '@maggie/store/navigation/utils';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import type { LessonSummaryType } from '../courses/types';
import { LessonActions } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonActivityType, LessonProgressType, LessonType } from './types';

const getTotalStarsForSlide = (lesson: LessonType, slideId: string): number => {
  for (const slide of lesson.configuration.slides) {
    if (slide.id === slideId) {
      return slide.data.challenge_configuration.nb_stars == null
        ? slide.data.challenge_configuration.weighting
        : slide.data.challenge_configuration.nb_stars;
    }
  }
  return 0;
};

const getSlide = (lessonId: string, slideId: string, state: LxStoreState): SlideType | null => {
  const lesson = LessonSelectors.getLesson(lessonId, state);

  if (lesson == null) {
    return null;
  }

  const matchingSlide = lesson.configuration.slides.find(slide => slide.id === slideId);
  return matchingSlide || null;
};

const findSlideIndexBySlideId = (lesson: LessonType, slideId: string) => {
  try {
    const slides = lesson.configuration.slides;
    let index = 0;
    for (const slide of slides) {
      if (slide.id === slideId) {
        return index;
      }
      ++index;
    }
    throw new Error(`cannot find the slide ${slideId} id in of the lesson`);
  } catch (err) {
    console.error('error', err);
    return 0;
  }
};

const getSlidesFromSocialLearningSlides = (
  lesson: LessonType,
  socialLearningSlides: SocialLearningSlide[]
) => {
  const slides = lesson.configuration.slides;
  return socialLearningSlides.reduce((acc, slSlide) => {
    const slide = slides.find(s => s.id === slSlide.id);
    if (!!slide) {
      acc.push(slide);
    }
    return acc;
  }, [] as SlideType[]);
};

const resetLessonsProgress = (ids: string[]) => {
  window.__store.dispatch(LessonActions.resetLessonsProgress(ids));
};

const getUnlockDetails = (
  lesson?: LessonSummaryType | LessonType,
  progress?: LessonProgressType
) => {
  if (!lesson || !progress) {
    return undefined;
  }

  const lockIntervalMin = lesson.features.attemptRestrictionApiResource.lockIntervalMin || 0;
  const unlockDate = progress.lastFailedAttemptDate
    ? addMinutes(new Date(progress.lastFailedAttemptDate), lockIntervalMin)
    : null;
  return {
    enabled: lockIntervalMin && lockIntervalMin !== 0,
    duration: lockIntervalMin,
    unlockDate,
    timestamp: unlockDate ? unlockDate.getTime() : null
  };
};

// Thomas + SCORM
const isComplete = (lessonId: string) => {
  const lessonProgress = LessonSelectors.getLessonProgress(lessonId, window.__store.getState());
  return !!(lessonProgress && lessonProgress.completed);
};

/**
 * @deprecated use `LessonSelectors.hasStarsEnabled` instead
 */
const hasStarsEnabled = (lesson?: LessonType) => {
  if (!lesson) {
    return false;
  }

  return (
    UserSelectors.hasStarsEnabled(window.__store.getState().user) &&
    lesson &&
    lesson.features &&
    lesson.features.areStarsEnabled
  );
};

// // Update other lesson which have the current lesson as prerequisites
// const refreshLessonsPrerequisiteUnlock = (lesson: LessonType, state: LxStoreState) => {
//   const currentCourse = CourseSelectors.getCourse(lesson.courseId, state);
//   if (!currentCourse) {
//     return;
//   }

//   const otherLessons = currentCourse.lessonSummaries.filter(less => less.lessonId !== lesson.id);
//   const lessonSummariesToUnlock = Object.values(otherLessons).reduce((filtered, less) => {
//     // lesson has current as prerequisites
//     const isPrerequisite = less.prerequisites.find(pre => pre.id === lesson.id);
//     if (isPrerequisite) {
//       const otherLessonsPrerequisitesIds = less.prerequisites.filter(pre => pre.id !== lesson.id);
//       const allPrerequisitesCompleted = otherLessonsPrerequisitesIds.reduce((allCompleted, pre) => {
//         const lessonProgress = LessonSelectors.getLessonProgress(pre.id, state);
//         return allCompleted && !!(lessonProgress && lessonProgress.completed);
//       }, true);

//       if (allPrerequisitesCompleted) {
//         filtered.push(less);
//       }
//     }
//     return filtered;
//   }, [] as LessonSummaryType[]);

//   const items = LessonSelectors.getUnlockPayloadFromPrerequisites(otherLessons)(state);
//   window.__store.dispatch(LessonActions.updateLessonsUnlock(items));
// };

// Total Stars
const computeEarnedStars = (lesson: LessonType) => {
  const lessonProgress = LessonSelectors.getLessonProgress(lesson.id, window.__store.getState());

  return !!lessonProgress ? lessonProgress.earnedStars : 0;
};

const getNotificationTitle = () => {
  return generateTitle('notifications.lesson.title');
};

const getNotificationMessage = (title?: string) => {
  return t('notifications.lesson.message', {
    lesson: title ? title : '',
    ns: 'learners-experience'
  });
};

const navigate = (id: string, type?: LessonActivityType, replace?: boolean) => {
  // make sure to preserve query/search params from URL
  const path = getLocationPath();
  const qs = path.split('?')[1];
  const query = parseQueryString(qs) || {};

  switch (type) {
    case 'Assessment':
      return window.__router.navigate('assignment', { id, query }, replace);

    case 'Discussion':
      return window.__router.navigate('discussion', { id, query }, replace);

    case 'Conference':
      return window.__router.navigate('conference', { id, query }, replace);

    case 'Observation':
      return window.__router.navigate('observation', { id, query }, replace);

    case 'Lesson':
    default:
      return window.__router.navigate('lesson', { id, query }, replace);
  }
};

const LessonUtils = {
  getTotalStarsForSlide,
  getSlide,
  findSlideIndexBySlideId,
  getSlidesFromSocialLearningSlides,
  resetLessonsProgress,
  getUnlockDetails,
  isComplete,
  hasStarsEnabled,
  computeEarnedStars,
  getNotificationTitle,
  getNotificationMessage,
  navigate
};

export { LessonUtils };
