import { fork, put, select, takeLatest } from 'redux-saga/effects';

import type { RankingParticipant } from '@edapp/leaderboards';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import type {
  LeaderboardInstance,
  LeaderboardInstanceListItem
} from '@maggie/containers/leaderboard/LeaderboardRequests';
import { Urls } from '@maggie/store/constants';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonAction } from '@maggie/store/courseware/lessons/types';
import type { LxStoreState } from '@maggie/store/types';

import type { LeaderboardsActionUnionType } from './actions';
import { LeaderboardsActionTypes, LeaderboardsActions } from './actions';
import type { RapidRefreshLeaderboardType } from './types';

type LeaderboardActions<ActionType extends string> = ActionFromActionType<
  LeaderboardsActionUnionType,
  ActionType
>;

function* handleFetchRapidRefreshLeaderboards(
  action: LeaderboardActions<LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS>
) {
  const { courseId } = action.payload;

  yield put(
    RequestActions.getAuthed<RapidRefreshLeaderboardType>(
      Urls.RAPID_REFRESH_LEADERBOARD(courseId),
      data => LeaderboardsActions.fetchRapidRefreshLeaderboardsSuccess(courseId, data),
      LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS_FAILURE
    )
  );
}

function* handleFetchLeaderboards() {
  yield put(
    RequestActions.getAuthed<LeaderboardInstanceListItem[]>(
      Urls.LEADERBOARDS_V2,
      LeaderboardsActionTypes.FETCH_LEADERBOARDS_SUCCESS,
      LeaderboardsActionTypes.FETCH_LEADERBOARDS_FAILURE
    )
  );
}

function* handleFetchLeaderboard(
  action: LeaderboardActions<LeaderboardsActionTypes.FETCH_LEADERBOARD>
) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed<LeaderboardInstance>(
      Urls.LEADERBOARD(id),
      LeaderboardsActionTypes.FETCH_LEADERBOARD_SUCCESS,
      LeaderboardsActionTypes.FETCH_LEADERBOARD_FAILURE
    )
  );
}

function* handleUpdatedCourseLeaderboardRankings(
  action: LessonAction<LessonActionTypes.UPDATE_LESSON_COMPLETED>
) {
  const { lessonId } = action.payload;
  const newRankings: RankingParticipant[] | undefined = yield select((s: LxStoreState) =>
    LessonSelectors.getNewScoreRankings(lessonId, s)
  );

  yield put(LeaderboardsActions.setCourseLeaderboardRankings(newRankings));
}

function* watchFetchRapidRefreshLeaderboardsSagas() {
  yield takeLatest(
    LeaderboardsActionTypes.FETCH_RAPID_REFRESH_LEADERBOARDS,
    handleFetchRapidRefreshLeaderboards
  );
}

function* watchFetchLeaderboardsSagas() {
  yield takeLatest(LeaderboardsActionTypes.FETCH_LEADERBOARDS, handleFetchLeaderboards);
}

function* watchFetchLeaderboardSagas() {
  yield takeLatest(LeaderboardsActionTypes.FETCH_LEADERBOARD, handleFetchLeaderboard);
}

// When a lesson is finished we need to update the leaderboard rankings if the course has a leaderboard
// This is because if the users keeps completing lessons their old scores won't be reflected otherwise,
// and the BE is too slow at calculating so we can't trust their results
function* watchUpdateLessonCompletedSagas() {
  yield takeLatest(
    LessonActionTypes.UPDATE_LESSON_COMPLETED,
    handleUpdatedCourseLeaderboardRankings
  );
}

export const leaderboardsSagas = [
  fork(watchFetchRapidRefreshLeaderboardsSagas),
  fork(watchFetchLeaderboardsSagas),
  fork(watchFetchLeaderboardSagas),
  fork(watchUpdateLessonCompletedSagas)
];
