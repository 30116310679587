import { put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import type { LessonActivityType } from '../lessons/types';
import { CourseActionTypes } from './actions';
import type { CourseAction } from './sagas';

export type CourseLessonsPayload = Array<{
  courseId: string;
  lessonId: string;
  lessonType: LessonActivityType;
  title: string;
  description: string;
  isPublished: boolean;
  slides: Array<{ id: string; title: string }>;
  archived: boolean;
}>;

function* handleFetchCourseLessons(action: CourseAction<CourseActionTypes.FETCH_COURSE_LESSONS>) {
  const { courseId } = action.payload;

  yield put(
    RequestActions.getAuthed<CourseLessonsPayload>(
      Urls.COURSE_LESSONS(courseId),
      CourseActionTypes.FETCH_COURSE_LESSONS_SUCCESS,
      CourseActionTypes.FETCH_COURSE_LESSONS_FAILURE
    )
  );
}

export function* watchFetchCourseLessons() {
  yield takeLatest(CourseActionTypes.FETCH_COURSE_LESSONS, handleFetchCourseLessons);
}
