const getTapticPlugin = (): TapticEngine | undefined => window.TapticEngine && window.TapticEngine;

const notification = (type: 'success' | 'error' | 'warning') => {
  const tapticEngine = getTapticPlugin();
  if (!tapticEngine) {
    return;
  }

  tapticEngine.notification({ type });
};

export const Taptic = {
  notification
};
