import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import type { SharedTestingProps } from '../../types';
import { Box } from '../box';
import type { PaddingsProps } from '@edapp/themes/src/common/utils';

type Props = {
  className?: string;
  size?: number;
  color?: string;
} & SharedTestingProps &
  PaddingsProps;

const DEFAULT_TIMEOUT = 300;
export const LOADING_TEST_ID = 'loading';

const bounceKeyFrames = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const bounce = (timeout = DEFAULT_TIMEOUT) => css`
  animation: ${bounceKeyFrames} ${timeout}ms infinite ease-out;
`;

const Container = styled(Box).attrs<Props>(({ testId = LOADING_TEST_ID }) => ({
  'data-testid': testId
}))`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bounce = styled.div<{ size: number; color?: string }>(
  ({ theme, size, color }) => css`
    background: ${color || theme.colors.blue};
    width: ${size}px;
    height: ${size}px;
    border-radius: 100%;
    display: inline-block;
    ${bounce(1400)};
  `
);

const BounceOne = styled(Bounce)`
  animation-delay: -0.32s;
`;

const BounceTwo = styled(Bounce)`
  animation-delay: -0.16s;
`;

export const Loading: React.FC<Props> = ({ size = 40, color, ...rest }) => {
  const bounceSize = size / 3;

  return (
    <Container {...rest}>
      <BounceOne size={bounceSize} color={color} />
      <BounceTwo size={bounceSize} color={color} />
      <Bounce size={bounceSize} color={color} />
    </Container>
  );
};
