import { put, select } from 'redux-saga/effects';

import { AssessmentActions } from '@maggie/store/courseware/assessments/actions';
import { AssessmentSelectors } from '@maggie/store/courseware/assessments/selectors';

export function* handleDidNavigateToSubmission(postId: string, assessmentId: string): any {
  const assessment: ReturnType<typeof AssessmentSelectors.getAssessmentItem> = yield select(
    AssessmentSelectors.getAssessmentItem(assessmentId)
  );

  if (!assessment) {
    yield put(AssessmentActions.fetchAssessment(assessmentId));
    yield put(AssessmentActions.fetchPosts(assessmentId));
  }
  yield put(AssessmentActions.fetchFeedback(assessmentId, postId));
  yield put(AssessmentActions.fetchPostItem(assessmentId, postId));
  yield put(AssessmentActions.fetchPostComments(assessmentId, postId));
}
