import type { ForgotPasswordState } from './types';
import type { Reducer } from 'redux';
import { initialForgotPasswordState } from './constants';
import type { ForgotPasswordActions } from './actions';
import { ForgotPasswordActionTypes } from './actions';
import type { ActionsUnion } from '@edapp/utils';

export const forgotPasswordReducer: Reducer<ForgotPasswordState> = (
  state = initialForgotPasswordState,
  action: ActionsUnion<typeof ForgotPasswordActions>
) => {
  switch (action.type) {
    case ForgotPasswordActionTypes.SET_EMAIL: {
      const { email } = action.payload;
      return { ...state, email };
    }

    case ForgotPasswordActionTypes.RESET_PASSWORD: {
      const { email } = action.payload;
      return { ...state, email, loading: true, error: undefined };
    }
    case ForgotPasswordActionTypes.RESET_PASSWORD_SUCCESS: {
      return { ...state, loading: false, error: undefined };
    }
    case ForgotPasswordActionTypes.RESET_PASSWORD_FAILURE: {
      const error = action.payload;
      return { ...state, loading: false, error, ssoCompanyId: error?.message || '' };
    }

    default:
      return state;
  }
};
