import type { LeaderboardInstanceListItem } from '@maggie/containers/leaderboard/LeaderboardRequests';

import type { LeaderboardTypeMap } from './types';

export const getMappedLeaderboards = (leaderboards: LeaderboardInstanceListItem[] | undefined) => {
  if (!leaderboards || !leaderboards.length) {
    return {};
  }

  const leaderboardMap: Partial<LeaderboardTypeMap> = {};

  leaderboards.forEach(leaderboard => {
    leaderboardMap[leaderboard.type] = [...(leaderboardMap[leaderboard.type] ?? []), leaderboard];
  });
  return leaderboardMap;
};
