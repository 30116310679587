import styled from 'styled-components';

import { getSpaceValue } from '../../utils';
import type { Props } from './types';
import { appendMargins, appendPaddings } from './utils';

export const BOX_TEST_ID = 'block';

/**
 * Box component enabling an easy spacing
 */
export const Box = styled.div.attrs<Props>(({ testId = BOX_TEST_ID }) => ({
  'data-testid': testId
}))<Props>(props => {
  let css = '';

  // Add layout styles
  let display = props.flex ? 'flex' : 'block';

  if (props.isInline) {
    display = 'inline-' + display;
  }

  if (props.boxSizing) {
    css += `box-sizing: ${props.boxSizing};`;
  }

  css += `display: ${display};`;

  if (props.flex) {
    if (typeof props.flex === 'string') {
      css += `flex: ${props.flex};`;
    }

    if (props.flexFlow != null) {
      css += `flex-flow: ${props.flexFlow};`;
    }

    if (props.flexDirection != null) {
      css += `flex-direction: ${props.flexDirection};`;
    }

    if (props.flexWrap != null) {
      css += `flex-wrap: ${props.flexWrap};`;
    }

    if (props.alignSelf != null) {
      css += `align-self: ${props.alignSelf};`;
    }

    if (props.justifySelf != null) {
      css += `justify-self: ${props.justifySelf};`;
    }

    if (props.alignItems != null) {
      css += `align-items: ${props.alignItems};`;
    }

    if (props.justifyContent != null) {
      css += `justify-content: ${props.justifyContent};`;
    }
  }

  if (props.flexGrow != null) {
    css += `flex-grow: ${props.flexGrow};`;
  }

  if (props.flexShrink != null) {
    css += `flex-shrink: ${props.flexShrink};`;
  }

  if (props.flexBasis != null) {
    css += `flex-basis: ${props.flexBasis};`;
  }

  // Add paddings and margins for individual sides
  css += `
    ${appendPaddings(props)}
    ${appendMargins(props)}
  `;

  // Add text alignment
  if (props.textAlign != null) {
    css += `text-align: ${props.textAlign};`;
  }

  if (props.boxShadow) {
    css += `box-shadow: ${props.theme.shadows[props.boxShadow]};`;
  }

  if (props.bgColor) {
    css += `background-color: ${props.theme.colors[props.bgColor]};`;
  }

  if (props.rounded) {
    css += `border-radius: ${props.theme.sizes.borderRadius}px;`;
  }

  if (props.border) {
    css += `border: 1px solid ${props.theme.colors.greyHover};`;
  }

  if (props.gap) {
    css += `gap: ${getSpaceValue(props.gap)}px;`;
  }
  if (props.rowGap) {
    css += `row-gap: ${getSpaceValue(props.rowGap)}px;`;
  }
  if (props.columnGap) {
    css += `column-gap: ${getSpaceValue(props.columnGap)}px;`;
  }

  return css;
});
