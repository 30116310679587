import type { LockedDialogState } from './types';

export const initialLockedDialogState: LockedDialogState = {
  isOpen: false,
  title: '',
  contentText: '',
  redirectParams: {
    shouldRedirect: false
  }
};
