import { call, put, race, select, takeLatest } from 'redux-saga/effects';

import { ConfigSelectors } from '@maggie/store/config/selectors';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { CourseActionTypes, CourseActions } from '../courses/actions';
import { CourseSelectors } from '../courses/selectors';
import type { CourseType, LessonSummaryType } from '../courses/types';
import { LessonActionTypes, LessonActions } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonAction } from './types';
import { LessonUtils } from './utils';

function* navigateToLesson(lessonId: string, isAttemptSuccessful: boolean) {
  const nextLesson: null | LessonSummaryType = yield select<LxStoreState>(s =>
    isAttemptSuccessful
      ? LessonSelectors.getNextAccessibleLessonFromCurrentLessonId(lessonId, s)
      : null
  );

  if (nextLesson) {
    LessonUtils.navigate(nextLesson.lessonId, nextLesson.lessonType, true);
  } else {
    window.__router.navigate('lesson', { id: lessonId }, true);
  }
}

function* handleCloseLessonSummaryDialog(
  action: LessonAction<LessonActionTypes.CLOSE_LESSON_SUMMARY_DIALOG>
) {
  const { lessonId, isAttemptSuccessful } = action.payload;

  /**
   * on offline no need to sync / show leaderboard
   * neither finishedThomasLesson -> rating & learners demo
   **/
  const isOnline: boolean = yield select(ConfigSelectors.isOnline);
  if (!isOnline) {
    yield call(navigateToLesson, lessonId, isAttemptSuccessful);
    return;
  }

  const courseId: string | undefined = yield select<LxStoreState>(
    LessonSelectors.getLessonCourseId(lessonId)
  );

  if (!!courseId) {
    let course: CourseType | undefined = yield select<LxStoreState>(s =>
      CourseSelectors.getCourse(courseId, s)
    );

    // If course isn't available we need to fetch and wait for the result
    // Because we need the leaderboard id to show the lesson summary
    if (!course) {
      yield put(CourseActions.fetchSyncCourse(courseId));
      const { success } = yield race({
        success: CourseActionTypes.FETCH_SYNC_COURSE_COMPLETED,
        failure: CourseActionTypes.FETCH_SYNC_COURSE_FAILURE
      });
      course = success
        ? yield select<LxStoreState>(s => CourseSelectors.getCourse(courseId, s))
        : course;
    }

    const isLeaderboardsEnabled: boolean = yield select<LxStoreState>(s =>
      UserSelectors.hasLeaderboardEnabled(s.user)
    );
    if (isLeaderboardsEnabled && course?.leaderboard?.isEnabled) {
      yield put(LessonActions.setLeaderboardSummaryDialogIsOpen(true));
    }
  }

  yield put(LessonActions.finishedThomasLesson(lessonId));
  yield call(navigateToLesson, lessonId, isAttemptSuccessful);
}

export function* watchCloseLessonSummary() {
  yield takeLatest(LessonActionTypes.CLOSE_LESSON_SUMMARY_DIALOG, handleCloseLessonSummaryDialog);
}
