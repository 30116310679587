import type { FeatureFlagsState } from './types';

export enum Flags {
  /*
   * awardedCertificates - enable certificate feature, display 'certificates' in my profile section
   */
  awardedCertificates = 'enable-learners-profile-awarded-certificates-v2',

  /**
   * To allow review lessons
   */
  reviewLesson = 'lx-review-lesson2',

  /**
   * To allow review multiple lessons in the navbar
   */
  reviewMultipleLessons = 'lx-review-multiple-lessons'
}

export const initialFeatureFlagsState: FeatureFlagsState = {
  flags: {},
  fetched: false,
  error: ''
};
