import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';

export const optimizeCloudinaryImageURL = (imageUrl: string): string => {
  const transformation = Platform.isMobile()
    ? ENV.CLOUDINARY_IMAGE_MOBILE_TRANSFORMATION
    : ENV.CLOUDINARY_IMAGE_DESKTOP_TRANSFORMATION;
  const cloudinaryImageFolderUrl = ENV.CLOUDINARY_IMAGE_FOLDER;

  return imageUrl.replace(
    cloudinaryImageFolderUrl,
    cloudinaryImageFolderUrl + transformation + '/'
  );
};
