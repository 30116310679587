import { put, race, take } from 'redux-saga/effects';

import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { CourseActionTypes, CourseActions } from '@maggie/store/courseware/courses/actions';
import type { CourseSyncType } from '@maggie/store/courseware/courses/types';
import { PlaylistsActionTypes, PlaylistsActions } from '@maggie/store/courseware/playlists/actions';

/**
 * This is required due to the poor design of linked translations.
 *
 * The problem is that when a user deeplinks into an english course,
 * the english course might have an italian translation. If the requester
 * has their device set to italian, the URL Id will point to the english version
 * but endpoints will respond with the italian one.
 *
 * This redirects user to the course that's been returned by endpoints.
 *
 * !Note:
 * ! in splitview layout, it can conflict since there could be two pages requesting at the same time
 * ! so the workaround has been disabled
 */
function checkForLinkedTranslations(routeCourseId: string, course: CourseSyncType) {
  const courseId = course?.courseInfo.id;
  if (typeof courseId === 'string' && courseId !== routeCourseId) {
    window.__router.navigate('course', { id: courseId }, true);
    return true;
  }

  return false;
}

export function* handleDidNavigateToCourse(courseId: string): any {
  HostedWebviewUtils.triggerEvent('courseScreenRendered');

  yield put(CourseActions.fetchSyncCourse(courseId));

  const { success } = yield race({
    success: take(CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS),
    failure: take(CourseActionTypes.FETCH_SYNC_COURSE_FAILURE)
  });

  /**
   * if the user deeplinks into a course, check if the courseId provided is an original course and redirect to the correct translation course.
   * if the route is not course, then it is coursecollection or playlist, hence opened course is selected from correct translated list.
   */
  const isCourseDeeplink = window.__router.getMain().routeName === 'course';
  if (isCourseDeeplink) {
    const redirected = checkForLinkedTranslations(courseId, success?.payload);
    if (redirected) {
      return;
    }
  }

  // wait for course sync to complete
  yield take(CourseActionTypes.FETCH_SYNC_COURSE_COMPLETED);

  // fetch playlists before opening course to determine if the course is locked by playlists
  yield put(PlaylistsActions.fetchPlaylists());
  yield take(PlaylistsActionTypes.FETCH_PLAYLISTS_COMPLETED);

  yield put(CourseActions.didOpenCourse(courseId));
}
