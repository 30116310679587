import * as React from 'react';

import type { DefaultTheme, ThemeProps } from 'styled-components';
import styled, { css, useTheme } from 'styled-components';

import { margins, rgba } from '@edapp/themes';

import { Loading } from '../../common/loading/Loading';
import { EdTypography } from '../typography/EdTypography';
import type { ButtonProps, LoadingColors, LoadingSizes } from './types';

export const BUTTON_TEST_ID = 'button';

const LOADING_SIZE_MAP: LoadingSizes = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 48,
  xl: 56.5
};

export const LOADING_COLOR_MAP: LoadingColors = {
  primary: 'fixedWhite',
  light: 'blue',
  tertiary: 'blue',
  pale: 'fixedWhite',
  'pale-dark': 'blue',
  transparent: 'blue',
  danger: 'white',
  warning: 'white',
  selected: 'blue',
  dark: 'white',
  grey: 'blue'
};

export const buttonStyle = <T extends keyof JSX.IntrinsicElements = 'button'>({
  theme,
  variant,
  size = 'md',
  isFullWidth,
  isLoading
}: ButtonProps<T> & ThemeProps<DefaultTheme>) => css`
  padding: ${theme.buttonPaddings[size].y}px ${theme.buttonPaddings[size].x}px;
  border-radius: ${theme.sizes.borderRadius * (size === 'xs' ? 1.5 : 2)}px;
  width: ${isFullWidth ? '100%' : 'auto'};
  box-shadow: none;
  border: none;
  user-select: none;
  font-family: inherit;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  ${size !== 'xs' ? theme.typography.button : theme.typography.subtext};

  :hover,
  :focus,
  :active {
    outline: none;
  }

  :focus-visible {
    outline: auto 2px Highlight; //Firefox support
    outline: -webkit-focus-ring-color auto 2px;
  }

  :disabled {
    cursor: not-allowed;
    color: ${theme.colors.text};
    ${!isLoading &&
    `
    opacity: 0.3;
    background-color: ${variant !== 'pale-dark' && theme.colors.grey};
    `}
  }

  ${() => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: ${theme.colors.blue};
          color: ${theme.colors.fixedWhite};

          ${EdTypography} {
            color: ${theme.colors.fixedWhite};
          }

          :not([disabled]) {
            &:hover,
            &:focus {
              color: ${theme.colors.fixedWhite};
              background-color: ${theme.colors.blueHover};
            }

            &:active {
              color: ${theme.colors.fixedWhite};
              background-color: ${theme.colors.blueActive};
            }
          }
        `;

      case 'transparent':
        return css`
          background: transparent;
          color: ${({ theme }) => theme.colors.text};

          ${EdTypography} {
            color: ${theme.colors.text};
          }

          :not([disabled]) {
            :hover,
            :focus {
              background-color: ${theme.colors.lightGrey};
            }
          }
        `;

      case 'pale':
        return css`
          background-color: transparent;
          border: 1px solid ${theme.colors.fixedWhite};
          color: ${theme.colors.fixedWhite};

          ${EdTypography} {
            color: ${theme.colors.fixedWhite};
          }

          :not([disabled]) {
            &:hover,
            &:focus {
              color: ${theme.colors.fixedWhite};
              background-color: ${rgba(theme.colors.fixedWhite, 0.25)};
            }
          }
        `;

      case 'pale-dark':
        return css`
          color: ${theme.colors.black};
          border: 1px solid ${theme.colors.black};
          background-color: transparent;

          :not([disabled]) {
            &:hover,
            &:focus {
              color: ${theme.colors.navyHover};
              background-color: ${rgba(theme.colors.white, 0.25)};
            }
          }
        `;

      case 'danger':
        return css`
          background-color: ${theme.colors.red};
          color: ${theme.colors.white};
        `;

      case 'warning':
        return css`
          background-color: ${theme.colors.orange};
          color: ${theme.colors.white};

          ${EdTypography} {
            color: ${theme.colors.white};
          }
        `;

      case 'selected':
        return css`
          background-color: ${rgba(theme.colors.blue, 0.1)};
          color: ${theme.colors.blue};
          border: 1px solid ${theme.colors.blue};

          ${EdTypography} {
            color: ${theme.colors.blue};
          }

          :not([disabled]) {
            &:hover,
            &:focus {
              background-color: ${rgba(theme.colors.blue, 0.2)};
            }

            &:active {
              background-color: ${rgba(theme.colors.blue, 0.3)};
            }
          }
        `;

      case 'dark': // used for website only
        return css`
          background-color: ${theme.colors.navy};
          color: ${theme.colors.white};

          ${EdTypography} {
            color: ${theme.colors.white};
          }

          :not([disabled]) {
            &:hover,
            &:focus,
            &:active {
              color: ${theme.colors.white};
              background-color: ${theme.colors.navyHover};
            }
          }
        `;
      case 'grey': // used for Icon button (without text) https://www.figma.com/file/ri3WV9N4p7UXLamemrjYvs/EdApp-Design-System?node-id=2898-21668&t=vMWP5H7jtIFM5IWN-0
        return css`
          background-color: ${theme.colors.lightGrey};
          color: ${theme.colors.text};

          ${EdTypography} {
            color: ${theme.colors.text};
          }

          :not([disabled]) {
            &:hover,
            &:focus,
            &:active {
              background-color: ${rgba(theme.colors.white, 0.75)};
            }
          }
        `;
      case 'tertiary':
        return css`
          background-color: transparent;
          color: ${theme.colors.blue};

          ${EdTypography} {
            color: ${theme.colors.blue};
          }

          :not([disabled]) {
            &:hover,
            &:focus,
            &:active {
              background-color: ${theme.colors.lightBlue};
              color: ${theme.colors.blue};
            }
          }

          :disabled {
            ${!isLoading &&
            `
              color: ${theme.colors.textMuted};
              `}
          }
        `;
      case 'light':
      default:
        return css`
          background-color: ${theme.colors.white};
          color: ${theme.colors.blue};
          border: 1px solid ${theme.colors.greyHover};

          ${EdTypography} {
            color: ${theme.colors.blue};
          }

          :not([disabled]) {
            &:hover,
            &:focus,
            &:active {
              background-color: ${theme.colors.lightBlue};
              color: ${theme.colors.blue};
            }
          }

          :disabled {
            ${!isLoading &&
            `
              color: ${theme.colors.textMuted};
              `}
          }
        `;
    }
  }}
`;

const InternalButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isLoading, children, size = 'md', ...props }, ref) => {
    const theme = useTheme();
    if (props.dangerouslySetInnerHTML) {
      // The outside caller should be responsible for sanitizing the string...
      return <StyledButton isLoading={isLoading} ref={ref} {...props} />;
    }

    return (
      <StyledButton isLoading={isLoading} ref={ref} {...props}>
        {children}
        {isLoading && (
          <StyledLoading
            color={props?.variant ? theme.colors[LOADING_COLOR_MAP[props.variant]] : undefined}
            size={LOADING_SIZE_MAP[size]}
          />
        )}
      </StyledButton>
    );
  }
);

const StyledButton = styled.button.attrs<ButtonProps>(
  ({ testId = BUTTON_TEST_ID, disabled, isDisabled, isLoading }) => ({
    'data-testid': testId,
    disabled: disabled || isDisabled || isLoading
  })
)<ButtonProps>(
  ({ isLoading }) => css`
    ${isLoading
      ? css`
          transition: none !important;
          color: transparent !important;
          // exclude loading
          & > :not(:last-child) {
            visibility: hidden;
          }
        `
      : ''}
    position: relative;
  `
);

const StyledLoading = styled(Loading)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Button = styled(InternalButton)<ButtonProps>`
  ${buttonStyle}
  ${margins}
`;
