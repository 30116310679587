import { delay } from 'redux-saga';
import { call, cancel, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';
import { UserActionTypes } from '@maggie/store/user/actions';

import type { MicroCredentialActionsUnionType } from './actions';
import { MicroCredentialActionTypes, MicroCredentialActions } from './actions';
import type { MicroCredential } from './types';

type MicroCredentialAction<A extends string> = ActionFromActionType<
  MicroCredentialActionsUnionType,
  A
>;

function* handleFetchMicroCredential(
  action: MicroCredentialAction<MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL>
) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed<MicroCredential>(
      Urls.MICRO_CREDENTIAL(id),
      MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL_SUCCESS,
      MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL_FAILURE
    )
  );
}

function* handleFetchEarnedMicroCredentials(
  action: MicroCredentialAction<MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS>
) {
  const { page } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<MicroCredential>>(
      Urls.EARNED_MICRO_CREDENTIALS,
      MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS_SUCCESS,
      MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS_FAILURE,
      undefined,
      { page }
    )
  );
}

function* handlePollMicroCredential(
  action: MicroCredentialAction<MicroCredentialActionTypes.POLL_MICRO_CREDENTIAL>
): any {
  const { id } = action.payload;

  const pollingTask = yield fork(pollMicroCredential, id);

  yield take(UserActionTypes.USER_DID_LOGOUT);

  yield cancel(pollingTask);
}

function* pollMicroCredential(id: string) {
  try {
    while (true) {
      yield put(MicroCredentialActions.fetchMicroCredential(id));

      yield take(
        (action: MicroCredentialActionsUnionType) =>
          action.type === MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL_SUCCESS ||
          action.type === MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL_FAILURE
      );

      const isMicroCredentialEarned: boolean | undefined = yield select(
        (state: LxStoreState) => !!state.courseware.microCredentials.byId[id]?.earned
      );

      if (isMicroCredentialEarned) {
        break;
      }

      yield call(delay, 2_000);
    }
  } finally {
  }
}

function* watchFetchMicroCredential() {
  yield takeLatest(MicroCredentialActionTypes.FETCH_MICRO_CREDENTIAL, handleFetchMicroCredential);
}

function* watchFetchEarnedMicroCredentials() {
  yield takeLatest(
    MicroCredentialActionTypes.FETCH_EARNED_MICRO_CREDENTIALS,
    handleFetchEarnedMicroCredentials
  );
}

function* watchPollMicroCredential() {
  yield takeLatest(MicroCredentialActionTypes.POLL_MICRO_CREDENTIAL, handlePollMicroCredential);
}

export const microCredentialSagas = [
  fork(watchFetchMicroCredential),
  fork(watchFetchEarnedMicroCredentials),
  fork(watchPollMicroCredential)
];
