import { fork, put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType, ActionsUnion } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';

import { ForgotPasswordActionTypes, ForgotPasswordActions } from './actions';

type Action<ActionType extends string> = ActionFromActionType<
  ActionsUnion<typeof ForgotPasswordActions>,
  ActionType
>;

function* handleResetPassword(action: Action<ForgotPasswordActionTypes.RESET_PASSWORD>) {
  const { email } = action.payload;

  yield put(
    RequestActions.postUnauthed(
      Urls.RESET_PASSWORD_V2,
      ForgotPasswordActions.resetPasswordSuccess,
      ForgotPasswordActions.resetPasswordFailure,
      undefined,
      {
        email,
        source: 'WEB'
      },
      undefined,
      true,
      false
    )
  );
}

export function* watchResetPassword() {
  yield takeLatest(ForgotPasswordActionTypes.RESET_PASSWORD, handleResetPassword);
}

const forgotPasswordSagas = [fork(watchResetPassword)];

export { forgotPasswordSagas };
