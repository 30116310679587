import { UserActionTypes } from '@maggie/store/user/actions';

import { LocalNotificationActionTypes } from './actions';
import type { LocalNotificationState, LocalNotificationsUnionType } from './types';

export const initialLocalNotificationState: LocalNotificationState = {
  lastLessonIdScheduled: '',
  upcomingLessonNotifications: []
};

export const localNotificationReducer = (
  state: LocalNotificationState = initialLocalNotificationState,
  action: LocalNotificationsUnionType
): LocalNotificationState => {
  switch (action.type) {
    case LocalNotificationActionTypes.SET_LAST_LESSON_SCHEDULED: {
      const { lessonId } = action.payload;
      return { ...state, lastLessonIdScheduled: lessonId };
    }

    case LocalNotificationActionTypes.FETCH_LESSON_NOTIFICATIONS_SUCCESS: {
      return { ...state, upcomingLessonNotifications: [...action.payload] };
    }

    case UserActionTypes.USER_DID_LOGOUT: {
      return { ...initialLocalNotificationState };
    }

    default:
      return state;
  }
};
