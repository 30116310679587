export type PlatformType = 'Android' | 'iOS' | 'browser-device' | 'browser-desktop';

export const Platform = {
  isTouch: () => {
    return 'ontouchstart' in window;
  },

  isWeb: () => {
    return typeof LocalFileSystem === 'undefined' || LocalFileSystem === null;
  },

  isCordova: () => {
    return typeof LocalFileSystem !== 'undefined' && LocalFileSystem !== null;
  },

  /**
   * @deprecated - use `AppMobileContext` instead
   */
  isMobile: (width = window.innerWidth) => {
    return width < 744;
  },

  /**
   * Returns the device's operating system name if available otherwise returns the device kind
   * @returns {PlatformType} A value such as 'Android', 'iOS', 'browser-device', 'browser-desktop'
   */
  get: (): PlatformType => {
    if (!!window.device && window.device.platform) {
      return window.device.platform as PlatformType;
    }

    if (/iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent)) {
      return 'browser-device';
    } else {
      return 'browser-desktop';
    }
  }
};
