import { all } from 'redux-saga/effects';

import { authorProfileSagas } from '@edapp/author-profile';
import { contentLibrarySagas } from '@edapp/content-library';

import { accountSettingsSagas } from './account-settings/sagas';
import { bannersSagas } from './banners/sagas';
import { brainBoostSagas } from './brain-boost/sagas';
import { configSagas } from './config/sagas';
import { assessmentSagas } from './courseware/assessments/sagas';
import { awardedCertificateSagas } from './courseware/awarded-certificates/sagas';
import { collectionSagas } from './courseware/collections/sagas';
import { conferenceSagas } from './courseware/conferences/sagas';
import { courseSagas } from './courseware/courses/sagas';
import { discussionsSagas } from './courseware/discussions/sagas';
import { documentSagas } from './courseware/documents/sagas';
import { groupTrainingSagas } from './courseware/group-training/sagas';
import { lessonActivitySagas } from './courseware/lesson-activity/sagas';
import { lessonsSagas } from './courseware/lessons/sagas';
import { loadingSagas } from './courseware/loading/sagas';
import { microCredentialSagas } from './courseware/micro-credentials/sagas';
import { observationSagas } from './courseware/observations/sagas';
import { playlistsSagas } from './courseware/playlists/sagas';
import { rapidRefreshesSagas } from './courseware/rapid-refreshes/sagas';
import { customAchievementsSagas } from './custom-achievements/sagas';
import { featureFlagsSagas } from './feature-flags/sagas';
import { forgotPasswordSagas } from './forgot-password/sagas';
import { gameSagas } from './game/sagas';
import { homeSagas } from './home/sagas';
import { interactionsSagas } from './interactions/sagas';
import { inviteCodeSignUpSagas } from './invite-code-sign-up/sagas';
import { leaderboardsSagas } from './leaderboards/sagas';
import { learnerDemographicSagas } from './learner-demographic/sagas';
import { lessonReviewSagas } from './lesson-review/sagas';
import { localNotificationSagas } from './local-notification/sagas';
import { lockedDialogSagas } from './locked-dialog/sagas';
import { magicLinkSagas } from './magic-link/sagas';
import { navigationSagas } from './navigation/sagas';
import { offlineSagas } from './offline/sagas';
import { performanceSagas } from './performance/sagas';
import { profileSagas } from './profile/sagas';
import { ratingDialogSagas } from './rating-dialog/sagas';
import { registerSagas } from './register/sagas';
import { requestSagas } from './request/sagas';
import { resetPasswordSagas } from './reset-password/sagas';
import { searchSagas } from './search/sagas';
import { sessionSagas } from './session/sagas';
import { slideProgressSagas } from './slide-progress/sagas';
import { socialLearningSagas } from './social-learning/sagas';
import { splitViewLayoutSagas } from './split-view-layout/sagas';
import { starSagas } from './star/sagas';
import { thomasSagas } from './thomas/sagas';
import { userSagas } from './user/sagas';

export default function* rootSaga() {
  yield all([
    ...configSagas,
    ...navigationSagas,
    ...requestSagas,
    ...userSagas,
    ...collectionSagas,
    ...playlistsSagas,
    ...courseSagas,
    ...lessonsSagas,
    ...rapidRefreshesSagas,
    ...loadingSagas,
    ...customAchievementsSagas,
    ...starSagas,
    ...slideProgressSagas,
    ...gameSagas,
    ...offlineSagas,
    ...performanceSagas,
    ...thomasSagas,
    ...interactionsSagas,
    ...socialLearningSagas,
    ...localNotificationSagas,
    ...brainBoostSagas,
    ...lockedDialogSagas,
    ...ratingDialogSagas,
    ...discussionsSagas,
    ...bannersSagas,
    ...assessmentSagas,
    ...lessonActivitySagas,
    ...inviteCodeSignUpSagas,
    ...contentLibrarySagas,
    ...authorProfileSagas,
    ...learnerDemographicSagas,
    ...conferenceSagas,
    ...forgotPasswordSagas,
    ...resetPasswordSagas,
    ...microCredentialSagas,
    ...sessionSagas,
    ...registerSagas,
    ...magicLinkSagas,
    ...leaderboardsSagas,
    ...searchSagas,
    ...featureFlagsSagas,
    ...observationSagas,
    ...awardedCertificateSagas,
    ...homeSagas,
    ...splitViewLayoutSagas,
    ...groupTrainingSagas,
    ...profileSagas,
    ...accountSettingsSagas,
    ...documentSagas,
    ...lessonReviewSagas
  ]);
}
