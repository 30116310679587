import { put, select } from 'redux-saga/effects';

import { MicroCredentialActions } from '@maggie/store/courseware/micro-credentials/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

export function* handleDidNavigateToMicroCredentials(): any {
  const isIndividualLearner: ReturnType<typeof UserSelectors.isIndividualLearner> = yield select(
    (state: LxStoreState) => UserSelectors.isIndividualLearner(state.user)
  );

  if (!isIndividualLearner) {
    return window.__router.navigate('home', {});
  }

  yield put(MicroCredentialActions.fetchEarnedMicroCredentials(1));
}
