import { takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import type { ActionFromActionType } from '@edapp/utils';
import { Tracker } from '@maggie/core/tracker/tracker';

import { DocumentActionTypes } from './actions';
import type { DocumentsActionsUnionType } from './types';

export type Action<ActionType extends string> = ActionFromActionType<
  DocumentsActionsUnionType,
  ActionType
>;

function* handleUpdateDocumentsUnlock(action: Action<DocumentActionTypes.UPDATE_DOCUMENTS_UNLOCK>) {
  const { payload } = action.payload;

  for (const item of payload) {
    if (item.unlocked) {
      Tracker.trackEvent({
        type: 'event',
        name: 'document-unlocked',
        id: item.id,
        value: item.title || ''
      });
      itly.documentUnlocked({ id: item.id });
    }
  }
}

export function* watchUpdateDocumentsUnlock() {
  yield takeLatest(DocumentActionTypes.UPDATE_DOCUMENTS_UNLOCK, handleUpdateDocumentsUnlock);
}
