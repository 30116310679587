import * as React from 'react';

import type { StyledComponentPropsWithRef } from 'styled-components';
import styled, { css } from 'styled-components';

import type { PathProps, Props } from '../types';

export const ICON_TEST_ID = 'icon';

const IconPath = styled.path.attrs<PathProps>(props => ({
  fill: props.theme.colors[props.color || 'text']
}))<PathProps>(
  props => css`
    ${props.stroke && `stroke: ${props.theme.colors[props.stroke]};`}
    ${props.strokeWidth != null &&
    `stroke-width: ${props.strokeWidth};`}
      transition: fill 150ms ease-in-out;
  `
);

const Component = React.forwardRef<SVGSVGElement, Props>(
  ({ testId = ICON_TEST_ID, d, color, stroke, strokeWidth, fillRule, className, ...rest }, ref) => (
    <svg ref={ref} data-testid={testId} className={className} viewBox="0 0 24 24" {...rest}>
      {rest.children}
      <IconPath d={d} color={color} stroke={stroke} strokeWidth={strokeWidth} fillRule={fillRule} />
    </svg>
  )
);

export const BaseIcon = styled(Component)
  .attrs<Props>(({ size = 'sm', height, width, theme }) => ({
    height: height ?? theme.iconSizes[size],
    width: width ?? theme.iconSizes[size]
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      (prop as string) === 'testId' || defaultValidatorFn(prop)
  })<Props>(
  ({ theme, color }) => css`
    fill: ${color ? theme.colors[color] || color : 'currentColor'};
    display: inline-flex;
    flex-shrink: 0;
  `
);

// Shared interface for all icon components
export type IconComponent = React.ComponentType<StyledComponentPropsWithRef<typeof BaseIcon>>;
