import { createSelector } from 'reselect';

import type { LxStoreState } from '@maggie/store/types';

const getByCourseId = (courseId: string) => (state: LxStoreState) =>
  state.courseware.microCredentials.byCourseId[courseId];

const getById = (id: string) => (state: LxStoreState) => state.courseware.microCredentials.byId[id];

const getMicroCredentialsIdsByCourseId = (state: LxStoreState) =>
  state.courseware.microCredentials.byCourseId;

export const makeGetMicroCredentialIdFromCourseId = (courseId: string) =>
  createSelector(
    getMicroCredentialsIdsByCourseId,
    () => courseId,
    (microCredentialIdsByCourseId, courseId) => microCredentialIdsByCourseId[courseId]
  );

const getMicroCredentialsById = (state: LxStoreState) => state.courseware.microCredentials.byId;

export const makeGetMicroCredential = (id?: string) =>
  createSelector(
    getMicroCredentialsById,
    () => id,
    (microCredentialsById, id) => (id ? microCredentialsById[id] : undefined)
  );

const getMicroCredentialsPolling = (state: LxStoreState) =>
  state.courseware.microCredentials.polling;

export const makeGetIsMicroCredentialPolling = (id: string) =>
  createSelector(
    getMicroCredentialsPolling,
    () => id,
    (microCredentialsPolling, id) => !!microCredentialsPolling[id]
  );

export const MicrocredentialsSelectors = {
  getByCourseId,
  getById
};
