import type { StarState, StarActionUnionType } from './types';
import { StarActionTypes } from './actions';
import { StarSelectors } from './selectors';
import { initialStarState } from './constants';

export const starReducer = (
  state: StarState = initialStarState,
  action: StarActionUnionType
): StarState => {
  const displayedBalance = StarSelectors.getDisplayedStarBalance(state);

  switch (action.type) {
    case StarActionTypes.SET_STAR_BALANCE:
      return {
        ...state,
        // Don't set the clientBalance from here, this action
        // is to update the server balance
        syncedBalance: action.payload.balance,
        syncedTimestamp: Date.now()
      };

    case StarActionTypes.REWARD_STARS_FROM_SLIDE:
      return {
        ...state,
        clientBalance: displayedBalance + action.payload.stars,
        clientTimestamp: Date.now()
      };

    case StarActionTypes.FETCH_DAILY_REWARD_SUCCESS: {
      const { awardedStars } = action.payload;
      const now = Date.now();

      return {
        ...state,
        numberOfStarsFromDailyLoginReward: awardedStars,
        dailyRewardTimestamp: now,
        clientBalance: displayedBalance + awardedStars,
        clientTimestamp: now
      };
    }

    case StarActionTypes.CLOSE_DAILY_REWARD: {
      return {
        ...state,
        numberOfStarsFromDailyLoginReward: 0
      };
    }

    case StarActionTypes.SPEND_STARS:
      return {
        ...state,
        clientBalance: displayedBalance - action.payload.stars,
        clientTimestamp: Date.now()
      };

    default:
      return state;
  }
};
