import { put, race, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { MagicLinkActions } from '@maggie/store/magic-link/actions';
import { getIsEmail } from '@maggie/utils/isEmail';

import { SessionActionTypes, SessionActions } from '../actions';
import type { SessionAction } from '../actions';

function* handleMagicLink(action: SessionAction<SessionActionTypes.MAGIC_LINK>) {
  const { username } = action.payload;

  if (!getIsEmail(username)) {
    // UI should prevent displaying magic login - but in any case
    yield put(SessionActions.magicLinkFailure());
    return;
  }

  yield put(SessionActions.userVerify(username));

  type RaceType = { success: SessionAction<SessionActionTypes.USER_VERIFY_SUCCESS> };
  const { success }: RaceType = yield race({
    success: take(SessionActionTypes.USER_VERIFY_SUCCESS),
    failure: take(SessionActionTypes.USER_VERIFY_FAILURE)
  });

  if (!!success && success.payload.exists) {
    itly.magicLinkSent();
    if (!!username) {
      yield put(MagicLinkActions.sendMagicLink(username));
    }
    window.__router.navigate('magicLink', { query: { email: username } });
    yield put(SessionActions.magicLinkSuccess());
  } else {
    yield put(SessionActions.magicLinkFailure());
  }
}

export function* watchMagicLink() {
  yield takeLatest(SessionActionTypes.MAGIC_LINK, handleMagicLink);
}
