import type {
  DiscussionPostType,
  DiscussionPostCommentType,
  DiscussionProgress,
  DiscussionType
} from './types';
import { createAction } from '@edapp/utils';
import type { RequestTypes } from '@edapp/request';
import type { Media } from '../types';

enum DiscussionActionTypes {
  FETCH_DISCUSSION_PROGRESS = 'FETCH_DISCUSSION_PROGRESS',
  FETCH_DISCUSSION_PROGRESS_SUCCESS = 'FETCH_DISCUSSION_PROGRESS_SUCCESS',
  FETCH_DISCUSSION_PROGRESS_FAILURE = 'FETCH_DISCUSSION_PROGRESS_FAILURE',

  FETCH_DISCUSSION = 'FETCH_DISCUSSION',
  FETCH_DISCUSSION_SUCCESS = 'FETCH_DISCUSSION_SUCCESS',
  FETCH_DISCUSSION_FAILURE = 'FETCH_DISCUSSION_FAILURE',

  FETCH_DISCUSSION_WITH_PROGRESS = 'FETCH_DISCUSSION_WITH_PROGRESS',
  FETCH_DISCUSSION_WITH_PROGRESS_SUCCESS = 'FETCH_DISCUSSION_WITH_PROGRESS_SUCCESS',
  FETCH_DISCUSSION_WITH_PROGRESS_FAILURE = 'FETCH_DISCUSSION_WITH_PROGRESS_FAILURE',

  FETCH_DISCUSSION_POSTS = 'FETCH_DISCUSSION_POSTS',
  FETCH_DISCUSSION_POSTS_SUCCESS = 'FETCH_DISCUSSION_POSTS_SUCCESS',
  FETCH_DISCUSSION_POSTS_FAILURE = 'FETCH_DISCUSSION_POSTS_FAILURE',

  FETCH_DISCUSSION_MANY_POSTS_COMMENTS = 'FETCH_DISCUSSION_MANY_POSTS_COMMENTS',
  FETCH_DISCUSSION_MANY_POSTS_COMMENTS_SUCCESS = 'FETCH_DISCUSSION_MANY_POSTS_COMMENTS_SUCCESS',
  FETCH_DISCUSSION_MANY_POSTS_COMMENTS_FAILURE = 'FETCH_DISCUSSION_MANY_POSTS_COMMENTS_FAILURE',

  FETCH_DISCUSSION_POST_COMMENTS = 'FETCH_DISCUSSION_POST_COMMENTS',
  FETCH_DISCUSSION_POST_COMMENTS_SUCCESS = 'FETCH_DISCUSSION_POST_COMMENTS_SUCCESS',
  FETCH_DISCUSSION_POST_COMMENTS_FAILURE = 'FETCH_DISCUSSION_POST_COMMENTS_FAILURE',

  FETCH_DISCUSSION_POST_ITEM = 'FETCH_DISCUSSION_POST_ITEM',
  FETCH_DISCUSSION_POST_ITEM_SUCCESS = 'FETCH_DISCUSSION_POST_ITEM_SUCCESS',
  FETCH_DISCUSSION_POST_ITEM_FAILURE = 'FETCH_DISCUSSION_POST_ITEM_FAILURE',

  EDIT_DISCUSSION_POST_SUBSCRIPTION = 'EDIT_DISCUSSION_POST_SUBSCRIPTION',
  EDIT_DISCUSSION_POST_SUBSCRIPTION_SUCCESS = 'EDIT_DISCUSSION_POST_SUBSCRIPTION_SUCCESS',
  EDIT_DISCUSSION_POST_SUBSCRIPTION_FAILURE = 'EDIT_DISCUSSION_POST_SUBSCRIPTION_FAILURE',

  SAVE_DISCUSSION_POST_COMMENT = 'SAVE_DISCUSSION_POST_COMMENT',
  SAVE_DISCUSSION_POST_COMMENT_SUCCESS = 'SAVE_DISCUSSION_POST_COMMENT_SUCCESS',
  SAVE_DISCUSSION_POST_COMMENT_FAILURE = 'SAVE_DISCUSSION_POST_COMMENT_FAILURE',

  SAVE_DISCUSSION_POST = 'SAVE_DISCUSSION_POST',
  SAVE_DISCUSSION_POST_SUCCESS = 'SAVE_DISCUSSION_POST_SUCCESS',
  SAVE_DISCUSSION_POST_FAILURE = 'SAVE_DISCUSSION_POST_FAILURE',

  EDIT_DISCUSSION_POST = 'EDIT_DISCUSSION_POST',
  EDIT_DISCUSSION_POST_SUCCESS = 'EDIT_DISCUSSION_POST_SUCCESS',
  EDIT_DISCUSSION_POST_FAILURE = 'EDIT_DISCUSSION_POST_FAILURE',

  CLEAR_DISCUSSION_POSTS = 'CLEAR_DISCUSSION_POSTS'
}

const DiscussionActions = {
  fetchProgress: (id: string) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS, { id }),
  fetchProgressSuccess: (id: string, progress: DiscussionProgress) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS_SUCCESS, { id, progress }),

  fetchDiscussion: (id: string) => createAction(DiscussionActionTypes.FETCH_DISCUSSION, { id }),

  fetchDiscussionWithProgress: (id: string) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS, { id }),
  fetchDiscussionWithProgressSuccess: (discussion: DiscussionType) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS_SUCCESS, { discussion }),
  fetchDiscussionWithProgressFailure: () =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS_FAILURE),

  fetchPosts: (discussionId: string, page = 1) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_POSTS, { discussionId, page }),
  fetchPostsSuccess: (
    page: number,
    discussionId: string,
    postsData: RequestTypes.PaginatedResponse<DiscussionPostType>
  ) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_POSTS_SUCCESS, {
      page,
      discussionId,
      postsData
    }),

  fetchMultiplePostsComments: (discussionId: string, postIds: string[]) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS, {
      discussionId,
      postIds
    }),

  fetchPostComments: (discussionId: string, postId: string, page = 1, created = 'asc') =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_POST_COMMENTS, {
      discussionId,
      postId,
      page,
      created
    }),
  fetchPostCommentsSuccess: (
    postId: string,
    page: number,
    postComments: RequestTypes.PaginatedResponse<DiscussionPostCommentType>
  ) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_POST_COMMENTS_SUCCESS, {
      postId,
      page,
      postComments
    }),

  fetchPostItem: (discussionId: string, postId: string) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM, { discussionId, postId }),
  fetchPostItemSuccess: (discussionId: string, post: DiscussionPostType) =>
    createAction(DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM_SUCCESS, { discussionId, post }),

  editPostSubscription: (discussionId: string, postId: string, isWatching: boolean) =>
    createAction(DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION, {
      discussionId,
      postId,
      isWatching
    }),
  editPostSubscriptionSuccess: (discussionId: string, postId: string, isWatching: boolean) =>
    createAction(DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION_SUCCESS, {
      discussionId,
      postId,
      isWatching
    }),

  saveComment: (discussionId: string, postId: string, commentText: string) =>
    createAction(DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT, {
      discussionId,
      postId,
      commentText
    }),
  saveCommentSuccess: (postId: string, postComment: DiscussionPostCommentType) =>
    createAction(DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT_SUCCESS, {
      postId,
      postComment
    }),

  savePost: (discussionId: string, textContent: string, media: Media[]) =>
    createAction(DiscussionActionTypes.SAVE_DISCUSSION_POST, { discussionId, textContent, media }),
  savePostSuccess: (discussionId: string, post: DiscussionPostType) =>
    createAction(DiscussionActionTypes.SAVE_DISCUSSION_POST_SUCCESS, { discussionId, post }),

  editPost: (discussionId: string, postId: string, textContent: string, media: Media[]) =>
    createAction(DiscussionActionTypes.EDIT_DISCUSSION_POST, {
      discussionId,
      postId,
      textContent,
      media
    }),
  editPostSuccess: (discussionId: string, post: DiscussionPostType) =>
    createAction(DiscussionActionTypes.EDIT_DISCUSSION_POST_SUCCESS, { discussionId, post }),

  clearDiscussionPosts: (discussionId: string) =>
    createAction(DiscussionActionTypes.CLEAR_DISCUSSION_POSTS, { discussionId })
};

export { DiscussionActionTypes, DiscussionActions };
