import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import type { PaginatedResponse } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { DictionaryType } from '@edapp/utils';
import { RapidRefreshThomasPlayer } from '@maggie/core/lessons/thomas-player/rapid-refresh-thomas-player';
import { Urls } from '@maggie/store/constants';
import { LockedDialogActions } from '@maggie/store/locked-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';

import { CourseActionTypes, CourseActions } from '../courses/actions';
import { CourseSelectors } from '../courses/selectors';
import type { CourseProgressType } from '../courses/types';
import type { UnlockPayload } from '../types';
import { RapidRefreshActionTypes, RapidRefreshActions } from './actions';
import { watchCompleteRapidRefresh } from './complete-rapid-refresh-sagas';
import { RapidRefreshSelectors } from './selectors';
import type { RapidRefreshAction, RapidRefreshType } from './types';
import { convertRapidRefreshToCourseSummaries, prepareCourseIdsToGetProgress } from './utils';
import { RAPID_REFRESH_PLAY_IFRAME_PARENT } from '@maggie/containers/rapid-refresh/constants';

function* fetchCourseProgressAndCheckPrerequisites(items: RapidRefreshType[]) {
  const courseProgress: DictionaryType<CourseProgressType> = yield select(
    CourseSelectors.getCoursesProgress
  );

  const courseIds: string[] = prepareCourseIdsToGetProgress(courseProgress, items);
  if (courseIds.length > 0) {
    yield put(CourseActions.fetchCoursesProgress(courseIds));
    yield race({
      progressSuccess: take(CourseActionTypes.FETCH_COURSES_PROGRESS_SUCCESS),
      progressFailure: take(CourseActionTypes.FETCH_COURSES_PROGRESS_FAILURE)
    });
  }

  const summaries = convertRapidRefreshToCourseSummaries(items);
  const payload: UnlockPayload[] = yield select(
    CourseSelectors.getUnlockPayloadFromPrerequisitesForCourseSummaries(summaries)
  );
  yield put(CourseActions.updateCoursesUnlock(payload));
}

function* handleFetchRapidRefreshList(
  action: RapidRefreshAction<RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST_SUCCESS>
) {
  const { page } = action.payload;
  yield put(
    RequestActions.getAuthed<PaginatedResponse<RapidRefreshType>>(
      Urls.RAPID_REFRESH_LIST,
      data => RapidRefreshActions.fetchRapidRefreshListSuccess(page, data),
      RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST_FAILURE,
      undefined,
      { pageSize: 25, page }
    )
  );

  type RaceType = {
    failure: any;
    success: RapidRefreshAction<RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST_SUCCESS>;
  };
  const { success, failure }: RaceType = yield race({
    success: take(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST_SUCCESS),
    failure: take(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST_FAILURE)
  });

  if (!!failure) {
    return; // failure handled in ui
  }

  if (success) {
    const { rapidRefreshData } = success.payload;
    yield fetchCourseProgressAndCheckPrerequisites(rapidRefreshData.items);
  }
}

function* handleFetchRapidRefreshItem(
  action: RapidRefreshAction<RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM>
) {
  const { courseId, session } = action.payload;

  yield put(
    RequestActions.getAuthed(
      Urls.RAPID_REFRESH_ITEM(courseId, session),
      RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_SUCCESS,
      RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_FAILURE
    )
  );

  type RaceType = {
    failure: any;
    success: RapidRefreshAction<RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_SUCCESS>;
  };
  const { success, failure }: RaceType = yield race({
    success: take(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_SUCCESS),
    failure: take(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_FAILURE)
  });

  if (!!failure || !success.payload) {
    return; // failure handled in ui
  }

  yield fetchCourseProgressAndCheckPrerequisites([success.payload]);

  yield put(RapidRefreshActions.fetchRapidRefreshItemCompleted());
}

function* handleLaunchRapidRefresh(
  action: RapidRefreshAction<RapidRefreshActionTypes.LAUNCH_RAPID_REFRESH>
) {
  const { courseId, session } = action.payload;

  const rapidRefresh: RapidRefreshType = yield select<LxStoreState>(
    RapidRefreshSelectors.getRapidRefreshItem(courseId, session)
  );

  const rapidRefreshPlayer = new RapidRefreshThomasPlayer(
    rapidRefresh.courseId,
    rapidRefresh.currentSession,
    rapidRefresh.session,
    rapidRefresh.thomasVersion,
    `#${RAPID_REFRESH_PLAY_IFRAME_PARENT}`
  );
  try {
    yield call(rapidRefreshPlayer.open);
  } catch {
    window.__router.navigate('rapidRefresh', {
      id: rapidRefresh.courseId,
      session: rapidRefresh.currentSession.toString()
    });
  }
}

function* handleDidOpenRapidRefresh(
  action: RapidRefreshAction<RapidRefreshActionTypes.DID_OPEN_RAPID_REFRESH>
) {
  const { courseId, session } = action.payload;

  const isLocked: boolean = yield select<LxStoreState>(
    RapidRefreshSelectors.isRapidRefreshLocked(courseId)
  );

  if (isLocked) {
    itly.tapLockedRapidRefresh();
    yield put(LockedDialogActions.openLockedRapidRefresh(courseId, session));
  }
}

function* watchLaunchRapidRefresh() {
  yield takeLatest(RapidRefreshActionTypes.LAUNCH_RAPID_REFRESH, handleLaunchRapidRefresh);
}

function* watchFetchRapidRefreshList() {
  yield takeLatest(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST, handleFetchRapidRefreshList);
}

function* watchFetchRapidRefreshItem() {
  yield takeLatest(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM, handleFetchRapidRefreshItem);
}

function* watchDidOpenRapidRefresh() {
  yield takeLatest(RapidRefreshActionTypes.DID_OPEN_RAPID_REFRESH, handleDidOpenRapidRefresh);
}

export const rapidRefreshesSagas = [
  fork(watchFetchRapidRefreshList),
  fork(watchFetchRapidRefreshItem),
  fork(watchLaunchRapidRefresh),
  fork(watchCompleteRapidRefresh),
  fork(watchDidOpenRapidRefresh)
];
