import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';

import type { HomeActionTypes, HomeActions } from './actions';

export enum HomeScreenWidget {
  RapidRefreshSection = 'RapidRefreshSection',
  PlaylistsSection = 'PlaylistsSection',
  CourseCollectionsSection = 'CourseCollectionsSection',
  CoursesAndCourseCollectionsSection = 'CoursesAndCourseCollectionsSection',
  BrainBoostCard = 'BrainBoostCard',
  CoursesInProgressSection = 'CoursesInProgressSection',
  CoursesCompletedSection = 'CoursesCompletedSection',
  UpNextSection = 'UpNextSection',
  Banners = 'Banners'
}

export type HomeActionsUnionType =
  | ActionsUnion<typeof HomeActions>
  | ActionWithPayload<HomeActionTypes.FETCH_FOR_YOU_WIDGETS_SUCCESS, HomeScreenWidget[]>;

export type HomeState = {
  /**
   * Tells if network calls are still happening in background.
   * Only network calls that affect the layout of home screen.
   */
  isLoading: boolean;
  /*
   * The tab that's selected in home screen
   */
  tabIndex: number;
  /**
   * The order of widgets in "For You" tab
   */
  forYouWidgets: HomeScreenWidget[];
  /**
   * The calculated data for upNext
   */
  upNext: CourseSummaryType[];
};
