import { NavigationActionTypes } from './actions';
import type { NavigationState, NavigationActionsUnionType } from './types';

const initialNavigationState: NavigationState = {
  route: 'home',
  collectionId: '',
  courseId: '',
  course: {
    id: '',
    from: {}
  },
  lessonId: '',
  slideId: '',
  rapidRefreshSessionId: { rapidRefreshId: '', session: 0 },
  assessmentId: '',
  discussionId: '',
  conferenceId: '',
  documentId: '',
  playlistId: '',
  observationId: '',
  contentLibraryCourseId: '',
  contentLibrarySectionId: '',
  postId: '',
  inviteCode: '',
  registerEmail: '',
  companyId: '',
  selfEnrollCourseId: ''
};

const navigationReducer = (
  state: NavigationState = initialNavigationState,
  action: NavigationActionsUnionType
): NavigationState => {
  switch (action.type) {
    case NavigationActionTypes.DID_NAVIGATE_ROUTE: {
      return {
        ...state,
        route: action.payload.route
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_HOME: {
      return {
        ...state,
        course: {
          id: '',
          from: {}
        }
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_PLAYLIST: {
      return { ...state, playlistId: action.payload.playlistId };
    }

    case NavigationActionTypes.DID_NAVIGATE_COURSE_COLLECTION: {
      return { ...state, collectionId: action.payload.courseCollectionId };
    }

    case NavigationActionTypes.DID_NAVIGATE_COURSE: {
      const { courseId, from } = action.payload;
      return {
        ...state,
        courseId: action.payload.courseId,
        course: {
          id: courseId,
          from
        }
      };
    }

    case NavigationActionTypes.SET_COURSE_ID: {
      const { courseId, from } = action.payload;
      return {
        ...state,
        courseId: courseId,
        course: {
          id: courseId,
          from
        }
      };
    }

    case NavigationActionTypes.SET_LESSON_ID:
    case NavigationActionTypes.DID_NAVIGATE_LAUNCH_LESSON:
    case NavigationActionTypes.DID_NAVIGATE_SOCIAL_LEARNING:
    case NavigationActionTypes.DID_NAVIGATE_LESSON:
    case NavigationActionTypes.DID_NAVIGATE_LESSON_PLAY: {
      return {
        ...state,
        lessonId: action.payload.lessonId,
        conferenceId: '',
        assessmentId: '',
        discussionId: '',
        observationId: '',
        documentId: ''
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_SOCIAL_LEARNING_SLIDE: {
      const { lessonId, slideId } = action.payload;
      return {
        ...state,
        lessonId,
        slideId,
        conferenceId: '',
        assessmentId: '',
        discussionId: '',
        observationId: '',
        documentId: ''
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_ASSESSMENT: {
      const { assessmentId } = action.payload;
      return {
        ...state,
        conferenceId: '',
        discussionId: '',
        lessonId: '',
        observationId: '',
        documentId: '',
        assessmentId: assessmentId,
        postId: state.assessmentId !== assessmentId ? '' : state.postId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_DISCUSSION: {
      const { discussionId } = action.payload;
      return {
        ...state,
        lessonId: '',
        conferenceId: '',
        assessmentId: '',
        observationId: '',
        documentId: '',
        discussionId,
        postId: state.discussionId !== discussionId ? '' : state.postId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_THREAD: {
      return {
        ...state,
        conferenceId: '',
        assessmentId: '',
        lessonId: '',
        observationId: '',
        documentId: '',
        discussionId: action.payload.discussionId,
        postId: action.payload.postId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_SUBMISSION: {
      return {
        ...state,
        conferenceId: '',
        lessonId: '',
        discussionId: '',
        observationId: '',
        documentId: '',
        assessmentId: action.payload.assessmentId,
        postId: action.payload.postId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_CONFERENCE: {
      return {
        ...state,
        lessonId: '',
        assessmentId: '',
        discussionId: '',
        observationId: '',
        documentId: '',
        conferenceId: action.payload.conferenceId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_CONTENT_LIBRARY_SECTION: {
      return {
        ...state,
        contentLibrarySectionId: action.payload.sectionId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_CONTENT_LIBRARY_COURSE: {
      return {
        ...state,
        contentLibraryCourseId: action.payload.courseId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_BRIEFCASE: {
      const { courseId } = action.payload;
      return {
        ...state,
        courseId,
        course: {
          ...state.course,
          id: courseId
        }
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_BRIEFCASE_DOCUMENT: {
      return {
        ...state,
        conferenceId: '',
        lessonId: '',
        assessmentId: '',
        discussionId: '',
        observationId: '',
        documentId: action.payload.documentId
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_REGISTER: {
      const { code, email, courseId } = action.payload;
      return { ...state, registerEmail: email, inviteCode: code, selfEnrollCourseId: courseId };
    }

    case NavigationActionTypes.DID_NAVIGATE_RAPID_REFRESH: {
      return {
        ...state,
        rapidRefreshSessionId: {
          rapidRefreshId: action.payload.id,
          session: action.payload.session
        }
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_SSO_LOGIN: {
      const { companyId } = action.payload;
      return { ...state, companyId: companyId || '' };
    }

    case NavigationActionTypes.DID_NAVIGATE_OBSERVATION:
    case NavigationActionTypes.DID_NAVIGATE_OBSERVATION_FEEDBACK: {
      return {
        ...state,
        lessonId: '',
        assessmentId: '',
        discussionId: '',
        conferenceId: '',
        documentId: '',
        observationId: action.payload.observationId
      };
    }

    default:
      return state;
  }
};

export { navigationReducer, initialNavigationState };
