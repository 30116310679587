import type { ErrorResponseType, ValidationErrorResponseType } from '@edapp/request';

export type ErrorType = ErrorResponseType | ValidationErrorResponseType;

export enum SocialMediaEnum {
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube'
}

export type SocialMediaType = {
  [SocialMediaEnum.LINKEDIN]: string;
  [SocialMediaEnum.FACEBOOK]: string;
  [SocialMediaEnum.TWITTER]: string;
  [SocialMediaEnum.INSTAGRAM]: string;
  [SocialMediaEnum.YOUTUBE]: string;
};

export type SocialMediaApiType = {
  url: string | undefined;
  type: SocialMediaEnum;
};

export type AuthorProfileBaseType = {
  id: string;
  companyName: string;
  website: string;
  industry: string;
  location: string;
  overview: string;
  extendedBio: string;
  logo: string;
  logoBackgroundColor: SponsoredLogoBackgroundColor;
  isSponsored: boolean;
};
export type SponsoredLogoBackgroundColor = 'navy' | 'white';
export type AuthorProfileType = AuthorProfileBaseType & { socialMedia: SocialMediaType };
export type AuthorProfileApiType = AuthorProfileBaseType & { socialMedia: SocialMediaApiType[] };

export type AuthorProfileState = {
  profile: AuthorProfileType;
  fetchAuthorProfileLoading: boolean;
  fetchAuthorProfileError: string;
  mutateAuthorProfileLoading: boolean;
  mutateAuthorProfileError: string;
  authorProfiles: { [authorProfileId: string]: AuthorProfileType | undefined };
};

export type StoreState = {
  authorProfile: AuthorProfileState;
};
