import { takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ENV } from '@maggie/config/env';
import { openExternalUrl } from '@maggie/cordova/link';
import { Platform } from '@maggie/cordova/platform';
import { routePaths } from '@maggie/core/router/routes';

import { SessionActionTypes } from './actions';
import type { SessionAction } from './actions';

function* handleRedirectToSSO(action: SessionAction<SessionActionTypes.REDIRECT_TO_SSO>) {
  const { code, openSsoExternally } = action.payload;
  itly.ssoRedirectSuccess({ company: code });

  if (Platform.isWeb()) {
    window.location.href = ENV.ssoRedirectUrl(code);
  } else {
    if (openSsoExternally) {
      return openExternalUrl(ENV.ssoRedirectUrl(code));
    }

    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-532
    const iab = window.open(
      ENV.ssoRedirectUrl(code),
      '_blank',
      'clearcache=yes,clearsessioncache=yes'
    );

    // firefox doesn't automatically open AppLinks
    // we open the sso page inside the app with IAB and listen to route changes
    // See: ED-14475
    iab?.addEventListener('loadstart', (event: InAppBrowserEvent) => {
      const ssoLoginHashRoute = routePaths.ssoLogin.template();
      if (event.url.indexOf(ssoLoginHashRoute) >= 0) {
        const url = new URL(event.url);
        window.__router.trigger(url.pathname + url.search);
        iab.close();
      }
    });
  }
}

export function* watchRedirectToSSO() {
  yield takeLatest(SessionActionTypes.REDIRECT_TO_SSO, handleRedirectToSSO);
}
