const validEvents = ['ready', 'unauthorized'] as const;
type Events = typeof validEvents[number];

const WEBVIEW_EVENTS = 'EdAppEvents-';

export const HostedWebviewUtils = {
  triggerShareLink(link: string) {
    if (typeof link !== 'string') {
      throw new Error(`HostedWebviewUtils.triggerShareLink: invalid link`);
    }
    const msg = { type: 'share_link', link };
    window.parent?.postMessage(JSON.stringify(msg), '*');
  },

  triggerShareQRCode(link: string) {
    if (typeof link !== 'string') {
      throw new Error(`HostedWebviewUtils.triggerShareQRCode: invalid link`);
    }
    const msg = { type: 'share_qr_code', link };
    window.parent?.postMessage(JSON.stringify(msg), '*');
  },

  triggerOpenAICreateMenu() {
    const msg = { type: 'open_ai_create_menu' };
    window.parent?.postMessage(JSON.stringify(msg), '*');
  },

  triggerClosePage(path: string, refresh: boolean = false) {
    if (typeof path !== 'string') {
      throw new Error(`HostedWebviewUtils.triggerClosePage: invalid path`);
    }

    const msg = { type: 'close_page', path, refresh };
    window.parent?.postMessage(JSON.stringify(msg), '*');
  },

  triggerOpenPage(path: string) {
    if (typeof path !== 'string') {
      throw new Error(`HostedWebviewUtils.triggerOpenPage: invalid path`);
    }

    const msg = { type: 'open_page', path };
    window.parent?.postMessage(JSON.stringify(msg), '*');
  },

  triggerBootstrapError(reason: string, path: string = location.pathname) {
    const msg = { type: 'error_bootstrap', reason, path };
    window.parent?.postMessage(JSON.stringify(msg), '*');
  },

  /**
   * This method will post messages to the window.parent
   *
   * It only accepts messages as strings.
   *
   * !Note: Do not call this method with any PI or user data.
   * If you need to post messages with payloads of data,
   * you could create a new version of this function and add a targetOrigin
   * to whatever websites are allowed to listen to those messages.
   */
  triggerEvent(eventName: Events) {
    const evt = `${WEBVIEW_EVENTS}${eventName}`;
    window.parent?.postMessage(evt, '*');
  },
};
