import { select, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { Tracker } from '@maggie/core/tracker/tracker';
import type { LxStoreState } from '@maggie/store/types';

import { CourseActionTypes } from './actions';
import type { CourseAction } from './sagas';
import { CourseSelectors } from './selectors';

function* handleUpdateCoursesUnlocked(
  action: CourseAction<CourseActionTypes.UPDATE_COURSES_UNLOCK>
) {
  const { items } = action.payload;
  const storeState: LxStoreState = yield select(s => s);

  items.forEach(({ id, title, unlocked, courseVersionNumber }) => {
    const progress = CourseSelectors.getCourseProgress(id, storeState);

    // only call interaction if unlocked happened for the first time
    if (!!unlocked && progress?.unlocked !== unlocked) {
      Tracker.trackEvent({
        type: 'event',
        name: 'course-unlocked',
        value: title,
        courseVersionNumber: courseVersionNumber,
        id
      });
      itly.courseUnlocked({
        id,
        value: title
      });
    }
  });
}

export function* watchUpdateCoursesUnlocked() {
  yield takeLatest(CourseActionTypes.UPDATE_COURSES_UNLOCK, handleUpdateCoursesUnlocked);
}
