import type { Token } from '@edapp/request';
import type { GamesState } from '@maggie/store/game/types';
import type { LxStoreState } from '@maggie/store/types';

import type { UserRole, UserState } from './types';

// === === === === === === ===
// === === Content === === ===
// === === === === === === ===

const shouldShowUpNextSection = (user: UserState) => !!user.ed.content.showUpNextSection;
const shouldShowCourseCollectionSection = (user: UserState) =>
  !!user.ed.content.showCourseCollectionSection;

// === === === === === === ===
// === === Features === === ===
// === === === === === === ===

const hasStarsEnabled = (user: UserState) => !!user.ed.enableStars;
const hasStarBarEnabled = (user: UserState) => !!user.ed.enableStarBar;
const hasMindfulLearningEnabled = (user: UserState) => !!user.ed.enableMindfulLearning;
const hasDeeplinkEnabled = (user: UserState) => !!user.ed.deeplinksEnabled;
const hasQuizEnabled = (user: UserState) => !!user.ed.quizEnabled;
const hasOfflineEnabled = (user: UserState) => !!user.ed.offlineEnabled;
const hasAvatarUploadEnabled = (user: UserState) => !!user.ed.avatarUploadEnabled;
const hasLeaderboard = (user: UserState) => !!user.ed.leaderboardEnabled;
const hasSocialLearningEnabled = (user: UserState) => !!user.ed.socialLearning;
const hasLeaderboardEnabled = (user: UserState) => !!user.ed.leaderboardEnabled;
const hasMyProfileEnabled = (state: LxStoreState) => state.user.ed.myProfileEnabled;
const hasMyPerformanceEnabled = (state: LxStoreState) => state.user.ed.myPerformanceEnabled;
const hasNotificationsEnabled = (state: LxStoreState) => state.user.ed.notificationsEnabled;
const hasNewNavbarEnabled = (state: LxStoreState) => state.user.ed.newNavbarEnabled;
const hasLocalNotificationsEnabled = (user: UserState) =>
  user.ed.notifications && user.ed.notifications.enabled;
const hasPostMessageWindowLocationEnabled = (state: LxStoreState) =>
  state.user.ed.postMessageWindowLocationEnabled;
const hasAppPromptUpdateEnabled = (state: LxStoreState) => state.user.ed.appUpdatePromptEnabled;
const hasPrizingNotificationsEnabled = (user: UserState) =>
  user.ed.notifications && user.ed.notifications.enabled && user.ed.notifications.prizing;
const getLessonNotificationTime = (user: UserState) =>
  user.ed.notifications && user.ed.notifications.content;
const getExternalIdentifier = (user: UserState) => user.externalIdentifier || '';
const canPlayLottery = (user: UserState, game: GamesState) =>
  !!user.ed.enableSpinToWin &&
  !!user.ed.enableStars &&
  !!user.ed.lottery.playerId &&
  !!game.lottery.enableSpinToWin;

const shouldShowStarBalance = (state: LxStoreState) =>
  !state.config.restrictToCourseScreen &&
  !state.config.restrictToLessonScreen &&
  state.user.ed.enableStars &&
  !state.search.isOpen;

const isGameAvailable = (s: LxStoreState) =>
  s.config.isOnline &&
  hasStarsEnabled(s.user) &&
  canPlayLottery(s.user, s.game) &&
  isPrizingUser(s.user);

// === === === === === === ===
// === === Permissions === ===
// === === === === === === ===

const hasPermission = (user: UserState, permission: UserRole) => user.roles.includes(permission);
const isDemo = (user: UserState) => user.name === 'demo' || user.name === 'demo-app-store';

const isEdAdmin = (user: UserState) => !isDemo(user) && hasPermission(user, 'ed-admin');

const isAccountAdmin = (user: UserState) => !isDemo(user) && hasPermission(user, 'account-admin');
const isAccountOwner = (user: UserState) => !isDemo(user) && hasPermission(user, 'account-owner');

const isContentAuthor = (user: UserState) => !isDemo(user) && hasPermission(user, 'content-author');

const isContentManager = (user: UserState) => hasPermission(user, 'content-manager');
const isReviewer = (user: UserState) => hasPermission(user, 'reviewer');
const isPrizingUser = (user: UserState) => hasPermission(user, 'prizing-user');
const isIndividualLearner = (user: UserState) => hasPermission(user, 'individual-learner');

const isUserUnverified = (u: UserState) => {
  return u.invitationStatus && u.invitationStatus !== 'verified' && !u.invited;
};

// === === === === === === ===
// === === Getters === ===
// === === === === === === ===

const getToken = (state: LxStoreState, campusOnly?: boolean): Token | undefined => {
  if (!!window.__disableEdAppTokens) {
    // This will never be consumed - see `packages/request/utils` -> `httpFetch`
    // we only "fake" a value here so all the application use cases will still work
    // IN SC, you can only access training if user is already logged in.
    // In EdApp Standalone, we used to have to run through logic in some views to redirect user to login page.
    // That isn't needed anymore!
    return {
      value: 'invalid-ignored-token',
      type: 'AccessToken',
      expiresAt: '1970-01-01T00:00:00Z'
    };
  }

  // By constructing this map, the function traverses the array only once.
  const map: Partial<{ [key in Token['type']]: Token }> = {};
  state.user.tokens.forEach(t => (map[t.type] = t));

  if (campusOnly) return map['CampusToken'];

  const edToken = map['AccessToken'],
    bufferZoneMilliseconds = 60000;
  if (edToken && new Date(edToken.expiresAt).getTime() > Date.now() - bufferZoneMilliseconds)
    return edToken;

  // As refreshing hasn't been implemented yet, we fall back to CampusToken when AccessToken has expired.
  return map['CampusToken'];
};
const getUser = (state: LxStoreState) => state.user;
const getRecommendedAppVersion = (state: LxStoreState) => state.user.recommendedAppVersion;
const hasAccess = (state: LxStoreState) => !!state.user.ed.hasAccess;
const getSSOToken = (state: LxStoreState) => state.user.ssoToken;
const getId = (state: LxStoreState) => state.user.id;
const getLocale = (state: LxStoreState) => state.user.locale;
const getApplicationId = (state: LxStoreState) => state.user.applicationId;
const getInvitationStatus = (state: LxStoreState) => state.user.invitationStatus;
const getEmail = (state: LxStoreState) => state.user.email;
const getSsoLogoutUrl = (state: LxStoreState) => state.user.ssoLogoutUrl;
const getSocialAttribution = (state: LxStoreState) => state.user.ed.socialAttribution;
const getUserFriendlyName = (user: UserState) => {
  if (user.firstName || user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  return user.name || user.email;
};
const hasLoggedInWithSSO = (state: LxStoreState) => state.user.hasLoggedInWithSSO;
const getFirstName = (state: LxStoreState) => state.user.firstName;
const getLastName = (state: LxStoreState) => state.user.lastName;
const getName = (state: LxStoreState) => state.user.name;
const getSoundDisabled = (state: LxStoreState) => state.user.soundDisabled;
const getCustomPrivacyPolicyUrl = (state: LxStoreState) => state.user.customPrivacyPolicyUrl;
const getAvatarUrl = (state: LxStoreState) => state.user.avatarUrl;
const getProvisioningType = (state: LxStoreState) => state.user.provisioningType;

export const UserSelectors = {
  getToken,
  getSSOToken,
  getApplicationId,
  getId,
  getLocale,
  hasStarsEnabled,
  hasStarBarEnabled,
  hasQuizEnabled,
  hasOfflineEnabled,
  hasAvatarUploadEnabled,
  hasLeaderboard,
  hasSocialLearningEnabled,
  hasLeaderboardEnabled,
  hasMyProfileEnabled,
  hasMyPerformanceEnabled,
  hasNewNavbarEnabled,
  hasNotificationsEnabled,
  hasLocalNotificationsEnabled,
  hasPostMessageWindowLocationEnabled,
  hasAppPromptUpdateEnabled,
  hasPrizingNotificationsEnabled,
  shouldShowStarBalance,
  isGameAvailable,
  getLessonNotificationTime,
  hasAccess,
  getExternalIdentifier,
  canPlayLottery,
  isDemo,
  isEdAdmin,
  isContentManager,
  isReviewer,
  isPrizingUser,
  isIndividualLearner,
  isUserUnverified,
  isAccountOwner,
  isAccountAdmin,
  isContentAuthor,
  getUser,
  getInvitationStatus,
  getEmail,
  getSsoLogoutUrl,
  getSocialAttribution,
  hasLoggedInWithSSO,
  getFirstName,
  getLastName,
  getName,
  getSoundDisabled,
  getUserFriendlyName,
  getRecommendedAppVersion,
  hasMindfulLearningEnabled,
  getCustomPrivacyPolicyUrl,
  hasDeeplinkEnabled,
  shouldShowUpNextSection,
  shouldShowCourseCollectionSection,
  getAvatarUrl,
  getProvisioningType
};
