import type * as React from 'react';
import type { ThemeSpace } from '@edapp/themes';
import { themeCommon, THEME_SPACE } from '@edapp/themes';

export function mergeRefs<T>(refsToMerge: Array<React.Ref<T> | null | undefined>) {
  return (element: T | null) => {
    refsToMerge
      .filter(refToMerge => refToMerge != null)
      .forEach(refToMerge => {
        if (typeof refToMerge === 'function') {
          refToMerge(element);
        } else {
          (refToMerge as React.MutableRefObject<T | null>).current = element;
        }
      });
  };
}

export function kFormatter(numberOfRatings: number): string {
  if (!numberOfRatings) return '0';

  return Math.abs(numberOfRatings) > 999
    ? parseFloat(Math.abs(numberOfRatings / 1000).toFixed(1)).toString() + 'k'
    : Math.abs(numberOfRatings).toString();
}

export function getSpaceValue(space?: ThemeSpace): number {
  if (typeof space === 'number') {
    return themeCommon.space(space);
  }

  if (typeof space === 'string' && THEME_SPACE[space]) {
    return THEME_SPACE[space];
  }

  return 0;
}
