import type { ContentLibrarySectionType, ContentLibraryState } from './types';

export const CL_LESSON_PREVIEW_QUERY_KEY = 'cl-lesson-preview';
export const CL_LESSON_INTERACTIVE_QUERY_KEY = 'cl-lesson-interactive-preview';
export const CL_COURSE_PREVIEW_QUERY_KEY = 'cl-course-preview';

export const RECOMMENDED_SECTION_ID = 'recommended-for-you';
export const SECTIONS_PAGE_SIZE = 5;
export const SECTION_COURSES_PAGE_SIZE = 25;

export const CONTENT_LIBRARY_APPLICATION_ID = '5d09a88df063169ad971cebe';

export const initialSectionState: ContentLibrarySectionType = {
  id: '',
  name: '',
  courses: {
    items: [],
    totalCount: 0
  }
};

export const initialContentLibraryState: ContentLibraryState = {
  loading: false,
  error: '',
  sections: {
    items: [],
    totalCount: 0
  },
  searchTerm: '',
  courseSearchResults: {
    items: [],
    totalCount: 0
  },
  courseItem: undefined,
  addingCoursesLoading: false,
  addingCoursesError: '',
  coursesWithUpdates: {
    ids: [],
    totalCount: 0
  }
};
