import { Platform } from '@maggie/cordova/platform';
import theme from '@maggie/theme/theme';

// temporary related to https://github.com/apache/cordova-plugin-statusbar/issues/256
const isAndroid12Above = () =>
  Platform.get() === 'Android' && ['12', '13'].includes(window.device.version);

/**
 * Device Status Bar Manager
 * - show/hide status bar
 * - change text color and background of the status bar
 *   - background colour can only be set when overlay status is false
 */
export const StatusBarManager = {
  /**
   * Set status bar to have light text.
   * Recommended for dark background.
   */
  setLightContent: (
    backgroundColor: keyof typeof theme['colors'] | (string & {}) = theme.colors.statusBar
  ) => {
    /**
     * https://github.com/apache/cordova-plugin-statusbar/issues/256
     * does not work on android 12/13
     */
    if (!isAndroid12Above()) {
      window.StatusBar?.styleLightContent();
      window.StatusBar?.backgroundColorByHexString?.(backgroundColor);
    }
  },
  /**
   * Set status bar to have dark text.
   * Recommended for light background.
   * */
  setDefault: (
    backgroundColor: keyof typeof theme['colors'] | (string & {}) = theme.colors.white
  ) => {
    /**
     * https://github.com/apache/cordova-plugin-statusbar/issues/256
     * does not work on android 12/13
     */
    if (!isAndroid12Above()) {
      window.StatusBar?.styleDefault();
      window.StatusBar?.backgroundColorByHexString?.(backgroundColor);
    }
  },

  show: () => {
    window.StatusBar?.show();
  },
  hide: () => {
    window.StatusBar?.hide();
  }
};
