import { put } from 'redux-saga/effects';

import { BannersActions } from '@maggie/store/banners/actions';
import { BrainBoostActions } from '@maggie/store/brain-boost/actions';
import { CollectionsActions } from '@maggie/store/courseware/collections/actions';
import { PlaylistsActions } from '@maggie/store/courseware/playlists/actions';
import { RapidRefreshActions } from '@maggie/store/courseware/rapid-refreshes/actions';
import { HomeActions } from '@maggie/store/home/actions';
import { HomeScreenWidget } from '@maggie/store/home/types';

export function* handleDidNavigateToHomeSection(sectionType: string) {
  switch (sectionType) {
    case HomeScreenWidget.UpNextSection:
      yield put(HomeActions.prepareUpNext());
      break;

    case HomeScreenWidget.PlaylistsSection:
      yield put(PlaylistsActions.fetchPlaylists());
      break;

    case HomeScreenWidget.Banners:
      yield put(BannersActions.fetchBanners());
      break;

    case HomeScreenWidget.RapidRefreshSection:
      yield put(RapidRefreshActions.fetchRapidRefreshList());
      break;

    case HomeScreenWidget.BrainBoostCard:
      yield put(BrainBoostActions.fetchBrainBoostSession());
      break;

    case HomeScreenWidget.CoursesInProgressSection:
    case HomeScreenWidget.CourseCollectionsSection:
      yield put(CollectionsActions.fetchCollectionsWithProgress({ page: 1, pageSize: 999 }));

    case HomeScreenWidget.CoursesCompletedSection:
      yield put(CollectionsActions.fetchCollectionsWithProgress({ page: 1, pageSize: 999 }));
      yield put(PlaylistsActions.fetchPlaylists());
      break;

    default:
      window.__router.navigate('home', {});
      break;
  }
}
