import type { ActionFromActionType, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';

export enum ProfileActionTypes {
  UPDATE_DOWNLOADOVERDATA = 'UPDATE_DOWNLOADOVERDATA',

  SET_LANGUAGE = 'SET_LANGUAGE',

  DELETE_INDIVIDUAL_LEARNER = 'DELETE_INDIVIDUAL_LEARNER',
  DELETE_INDIVIDUAL_LEARNER_SUCCESS = 'DELETE_INDIVIDUAL_LEARNER_SUCCESS',
  DELETE_INDIVIDUAL_LEARNER_FAILURE = 'DELETE_INDIVIDUAL_LEARNER_FAILURE',

  UPDATE_AVATAR = 'UPDATE_AVATAR',
  UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS',
  UPDATE_AVATAR_FAILURE = 'UPDATE_AVATAR_FAILURE'
}

export const ProfileActions = {
  updateDownloadOverData: (value: boolean) =>
    createAction(ProfileActionTypes.UPDATE_DOWNLOADOVERDATA, { value }),

  setLanguage: (locale: string | null) => createAction(ProfileActionTypes.SET_LANGUAGE, { locale }),

  deleteIndividualLearner: () => createAction(ProfileActionTypes.DELETE_INDIVIDUAL_LEARNER, {}),

  updateAvatar: (avatarUrl: string) => createAction(ProfileActionTypes.UPDATE_AVATAR, { avatarUrl })
};

export type ProfileActionsUnionType = ActionsUnion<typeof ProfileActions>;
export type ProfileAction<A extends string> = ActionFromActionType<ProfileActionsUnionType, A>;
