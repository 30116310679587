import { CoursewareUtils } from '../utils';
import type { CourseProgressType, CourseType, LessonSummaryType } from './types';
import type { DictionaryType } from '@edapp/utils';
import type { UnlockPayload } from '../types';

// We always spread the new progress that's coming from the network
// But, if client has completed a lesson and progress has been updated in the client side
// And hangfire jobs haven't finished being proccessed yet... we need to keep the data
// that's stored in the client side
const spreadCourseProgressUpdates = (current: CourseProgressType, server: CourseProgressType) => {
  const unlockClientAhead = CoursewareUtils.isClientAhead(current, server, 'unlockedDate');
  const completionClientAhead = CoursewareUtils.isClientAhead(current, server, 'completedDate');
  const openedClientAhead = CoursewareUtils.isClientAhead(current, server, 'openedDate');

  return {
    ...server,
    opened: openedClientAhead ? current.opened : server.opened,
    openedDate: openedClientAhead ? current.openedDate : server.openedDate,
    unlocked: unlockClientAhead ? current.unlocked : server.unlocked,
    unlockedDate: unlockClientAhead ? current.unlockedDate : server.unlockedDate,
    lessonsUnlocked: unlockClientAhead ? current.lessonsUnlocked : server.lessonsUnlocked,
    completed: completionClientAhead ? current.completed : server.completed,
    completedDate: completionClientAhead ? current.completedDate : server.completedDate,
    lessonsCompleted: completionClientAhead ? current.lessonsCompleted : server.lessonsCompleted,
    score: completionClientAhead ? current.score : server.score,
    percentageCompleted: completionClientAhead
      ? current.percentageCompleted
      : server.percentageCompleted
  };
};

const courseProgressOpenUpdated = (progress: CourseProgressType, courseId: string) => ({
  [courseId]: {
    ...progress,
    opened: true,
    openedDate: new Date().toISOString()
  }
});

const courseProgressCompleteUpdated = (
  progress: CourseProgressType,
  courseId: string,
  completed: boolean,
  percentageCompleted: number,
  lessonsCompleted: number,
  lessonsUnlocked: number
) => ({
  [courseId]: {
    ...progress,
    completed: !!completed,
    completedDate: !!completed ? new Date().toISOString() : null,
    percentageCompleted,
    lessonsCompleted,
    lessonsUnlocked
  }
});

const courseProgressUnlockUpdated = (
  coursesProgress: DictionaryType<CourseProgressType>,
  items: UnlockPayload[]
) => {
  const updatedProgress: Record<string, CourseProgressType> = {};

  for (const item of items) {
    const progress = coursesProgress[item.id];

    if (progress == null || item.unlocked === progress.unlocked) {
      // nothing to update
      continue;
    }

    updatedProgress[item.id] = {
      ...progress,
      unlocked: item.unlocked,
      // @ts-expect-error TODO: EDAPP-42821 this should be string (?)
      unlockedDate: new Date()
    };
  }

  return updatedProgress;
};

const courseRemainingStarsUpdated = (course: CourseType, lessonId: string, stars: number) => {
  const newLessonSummaries = course.lessonSummaries.map(
    (lessonSummary): LessonSummaryType => {
      if (lessonSummary.lessonId !== lessonId) {
        return lessonSummary;
      }
      let newRemainingStars = lessonSummary.remainingStars - stars;
      if (newRemainingStars < 0) {
        newRemainingStars = 0;
      }
      return {
        ...lessonSummary,
        remainingStars: newRemainingStars
      };
    }
  );
  return {
    [course.id]: {
      ...course,
      lessonSummaries: newLessonSummaries
    }
  };
};

const courseUserRatingUpdated = (course: CourseType, userRating: number, userReview?: string) => {
  // Updates the value of the user rating
  return {
    [course.id]: {
      ...course,
      userRating,
      userReview
    }
  };
};

const setCourseHasBeenPromptedForRating = (course: CourseType) => {
  // Updates the value of the user rating
  return {
    [course.id]: {
      ...course,
      hasBeenPromptRating: true
    }
  };
};

export {
  spreadCourseProgressUpdates,
  courseProgressOpenUpdated,
  courseProgressCompleteUpdated,
  courseProgressUnlockUpdated,
  courseRemainingStarsUpdated,
  courseUserRatingUpdated,
  setCourseHasBeenPromptedForRating
};
