import type { RouteParams } from '@igorrmotta/typesafe-react-router';
import type { RouteName } from '@maggie/core/router/types';

import { checkForPlatform } from '../query-params-handlers/platform-sagas';
import { checkForRestrictionScreen } from '../query-params-handlers/screen-restriction-sagas';
import type { GlobalRouteQueryParams } from '../query-params-handlers/type';
import { strictQueryParams } from '../utils';

/**
 * It checks for arguments that come from the URL.
 *
 * These arguments should be taken into account for every route.
 */
export function* checkForRouteQueryParams(route: RouteName, params?: RouteParams<any>): any {
  if (!params) {
    return false;
  }

  const { query } = strictQueryParams<any, GlobalRouteQueryParams>(params);

  yield checkForRestrictionScreen(query);

  yield checkForPlatform(query);
}
