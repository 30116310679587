import type { IAdapter, SeverityType, UserData } from './adapters';

export class ErrorLogger {
  private static adapter?: IAdapter = undefined;

  static init = (adapterFactory: () => IAdapter) => {
    if (this.adapter) {
      return;
    }

    this.adapter = adapterFactory();
  };

  static reset = () => {
    this.adapter = undefined;
  };

  static setUserContext = <TUserData extends UserData = {}>(data: TUserData) => {
    if (!this.adapter) {
      return;
    }

    this.adapter.setUserContext(data);
  };

  static clearUserContext = () => {
    if (!this.adapter) {
      return;
    }

    this.adapter.clearUserContext();
  };

  static captureException = <TError extends Error>(
    error: TError,
    context?: Record<string, unknown>
  ) => {
    if (!this.adapter) {
      return;
    }

    this.adapter.captureException<TError>(error, context);
  };

  static captureEvent = <TData extends Record<string, unknown> = {}>(
    message: string,
    level: SeverityType,
    extra: TData
  ) => {
    if (!this.adapter) {
      return;
    }

    this.adapter.captureEvent<TData>(message, level, extra);
  };
}
