import { select } from 'redux-saga/effects';

import { CollectionsSelectors } from '@maggie/store/courseware/collections/selectors';
import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidMainNavigateToCourseCollection(courseCollectionId: string) {
  const defaultSelectedCourse: CourseSummaryType | undefined = yield select<LxStoreState>(
    CollectionsSelectors.getCourseForCollection(courseCollectionId)
  );

  if (!!defaultSelectedCourse) {
    window.__router.navigate(
      'course',
      { id: defaultSelectedCourse.courseId, query: { collectionId: courseCollectionId } },
      true
    );
  }
}
