import { ErrorCode } from '@edapp/request';
import type { RegisterActionUnionType } from '@maggie/store/register/actions';
import { RegisterActionsTypes } from '@maggie/store/register/actions';
import type { SessionActionsUnionType } from '@maggie/store/session/actions';
import { SessionActionTypes } from '@maggie/store/session/actions';

import type { InviteCodeSignUpUnionType } from './action';
import { InviteCodeSignUpActionTypes } from './action';
import type { InviteCodeSignUpState } from './types';

export const inviteCodeSignUpInitialState: InviteCodeSignUpState = {
  loading: false
};

export const inviteCodeSignUpReducer = (
  state: InviteCodeSignUpState = inviteCodeSignUpInitialState,
  action: InviteCodeSignUpUnionType | SessionActionsUnionType | RegisterActionUnionType
) => {
  switch (action.type) {
    case SessionActionTypes.USER_VERIFY: {
      return {
        ...state,
        loading: true
      };
    }

    case SessionActionTypes.USER_VERIFY_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case SessionActionTypes.USER_VERIFY_FAILURE: {
      const error = action.payload?.code || ErrorCode.NullResponse;
      return {
        ...state,
        loading: false,
        error
      };
    }

    case RegisterActionsTypes.CHECK_INVITE_CODE_SUCCESS: {
      return {
        ...state,
        error: undefined
      };
    }

    case RegisterActionsTypes.CHECK_INVITE_CODE_FAILURE: {
      return { ...state, loading: false, error: action.payload.code };
    }

    case InviteCodeSignUpActionTypes.VERIFY_EMAIL_EXISTS: {
      return {
        ...state,
        loading: false,
        error: ErrorCode.EmailAlreadyRegistered
      };
    }

    case InviteCodeSignUpActionTypes.VERIFY_INVITE_CODE_SIGN_UP_RESET: {
      return {
        ...state,
        error: undefined,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
