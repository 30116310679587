import { put, select, take, takeLatest } from 'redux-saga/effects';

import { ConfigActionTypes } from '@maggie/store/config/actions';
import type { ConfigActionsMap } from '@maggie/store/config/types';
import {
  CollectionsActionTypes,
  CollectionsActions
} from '@maggie/store/courseware/collections/actions';
import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';
import { PlaylistsActionTypes, PlaylistsActions } from '@maggie/store/courseware/playlists/actions';
import { PlaylistsSelectors } from '@maggie/store/courseware/playlists/selectors';
import type { PlaylistSummaryType } from '@maggie/store/courseware/playlists/types';
import { UpNext } from '@maggie/store/home/up-next';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { HomeActionTypes, HomeActions } from './actions';
import { HomeSelectors } from './selectors';

function* handlePrepareUpNext() {
  // 1. fetch all data
  yield put(CollectionsActions.fetchCollectionsWithProgress({ page: 1, pageSize: 999 }));
  yield take(CollectionsActionTypes.FETCH_COLLECTIONS_WITH_PROGRESS_COMPLETED);

  yield put(PlaylistsActions.fetchPlaylists());
  yield take(PlaylistsActionTypes.FETCH_PLAYLISTS_COMPLETED);

  const shouldShowUpNextSection: boolean = yield select<LxStoreState>(s =>
    UserSelectors.shouldShowUpNextSection(s.user)
  );
  if (!shouldShowUpNextSection) {
    yield put(HomeActions.completeUpNext([]));
    return;
  }

  // 2. select data
  const playlists: PlaylistSummaryType[] = yield select(PlaylistsSelectors.getIncompletePlaylists);
  const courses: CourseSummaryType[] = yield select(HomeSelectors.getAllIncompleteCourseSummaries);
  const coursesAvailability: Record<string, boolean> = yield select(
    HomeSelectors.getCoursesAvailability(courses)
  );

  // 3. apply up next
  const upNext = new UpNext(playlists, courses, coursesAvailability);
  yield put(HomeActions.completeUpNext(upNext.apply()));
}

function* handleOnlineStatusChangePrepareUpNext(
  action: ConfigActionsMap<ConfigActionTypes.SET_ONLINE>
) {
  const { online, wasOnline } = action.payload;
  const userToken: ReturnType<typeof UserSelectors.getToken> = yield select(UserSelectors.getToken);
  if (online !== wasOnline && !!userToken) {
    yield put(HomeActions.prepareUpNext());
  }
}

export function* watchPrepareUpNext() {
  yield takeLatest(HomeActionTypes.PREPARE_UP_NEXT, handlePrepareUpNext);
}

export function* watchOnlineStatusChangePrepareUpNext() {
  yield takeLatest(ConfigActionTypes.SET_ONLINE, handleOnlineStatusChangePrepareUpNext);
}
