import type { AssessmentType } from '../assessments/types';
import type { ConferenceType } from '../conferences/types';
import type { LessonSummaryType } from '../courses/types';
import type { DiscussionType } from '../discussions/types';
import type { LessonType } from '../lessons/types';
import type { ObservationType } from '../observations/types';
import { CoursewareTypeNames } from '../types';

// Ideally, this discriminator should be coming for every "type of lesson" (labeled as activity here)
// that comes from the back end, but it doesn't return do that =(
interface IDiscussionActityType {
  type: 'Discussion';
  activity: DiscussionType;
}

interface IAssessmentActivityType {
  type: 'Assessment';
  activity: AssessmentType;
}

interface IConferenceActivityType {
  type: 'Conference';
  activity: ConferenceType;
}

interface ILessonActivityType {
  type: 'Lesson';
  activity: LessonType;
}

interface IObservationActivityType {
  type: 'Observation';
  activity: ObservationType;
}

type ActivityType =
  | IDiscussionActityType
  | IAssessmentActivityType
  | IConferenceActivityType
  | IObservationActivityType
  | ILessonActivityType;

export class LessonSummaryMapper {
  /**
   * Ideally, the backend should be consistent and return the same type for everytime we ask for the entity 'Discussion'
   *
   * `GET `api/discussion/{id}` returns different data than `GET api/course/{id}/sync`
   */
  private static mapDiscussionToLessonSummary(discussion: DiscussionType): LessonSummaryType {
    return {
      typeName: CoursewareTypeNames.LESSON_SUMMARY,
      // this property is not exposed from `api/discussion/{id}`
      // but we know they return discussion
      lessonType: 'Discussion',
      lessonId: discussion.id,
      title: discussion.title,
      description: discussion.description,
      // this property is not exposed from `api/discussion/{id}`
      // no slides in discussions?
      numberOfSlides: 0,
      duration: 0,
      platformsAvailable: discussion.platformsAvailable,
      // this property is not exposed from `api/discussion/{id}`
      // But we know it's zero score?
      minimumScore: 0,
      // this property is not exposed from `api/discussion/{id}`
      // But we know it's false?
      hideScore: false,
      prerequisites: discussion.prerequisitesWithTitle,
      planning: discussion.planning,
      // this property is not exposed from `api/discussion/{id}`
      // but we know it's zero?
      totalStars: 0,
      // this property is not exposed from `api/discussion/{id}`
      // but we know it's zero?
      remainingStars: 0,
      moduleId: discussion.moduleId,
      // this property is not exposed from `api/discussion/{id}`
      // but we know it they don't exist for a discussion type of lesson
      features: {
        areStarsEnabled: false,
        attemptRestrictionApiResource: {
          lockIntervalMin: 0,
          resetCourseOnNumberAttempts: 0,
          shouldLockOnCompletion: false
        }
      },
      // this property is not exposed from `api/discussion/{id}`
      // but we know if the endpoint returns the data is because it's published
      displayActive: true,
      // this property is not exposed from `api/discussion/{id}`
      // but we know discussions are not supported offline
      downloadable: false,
      // this property is not exposed from `api/discussion/{id}`
      // but we know discussions don't have an archive size
      archiveSize: 0,
      // this property is not exposed from `api/discussion/{id}`
      // but we know discussions don't have stars
      showStarProgressScore: false,
      shouldHideContentType: false,
      shouldUseCourseBranding: false,
      slides: [],
      // The progress is injected by selectors - not always there...
      progress: undefined
    };
  }

  /**
   * Ideally, the backend should be consistent and return the same type for everytime we ask for the entity 'Assessment'
   *
   * `GET api/assessments/{id}` returns different data than `GET api/course/{id}/sync`
   */
  private static mapAssessmentToLessonSummary(assessment: AssessmentType): LessonSummaryType {
    return {
      typeName: CoursewareTypeNames.LESSON_SUMMARY,
      // this property is not exposed from `api/assessments/{id}`
      // but we know they return discussion
      lessonType: 'Assessment',
      lessonId: assessment.id,
      title: assessment.title,
      description: assessment.description,
      // this property is not exposed from `api/assessments/{id}`
      // no slides in assessment?
      numberOfSlides: 0,
      // this property is not exposed from `api/assessments/{id}`
      // no slides in assessment?
      duration: 0,
      platformsAvailable: assessment.platformsAvailable,
      // this property is not exposed from `api/assessments/{id}`
      // But we know it's zero score?
      minimumScore: 0,
      // this property is not exposed from `api/assessments/{id}`
      // But we know it's false?
      hideScore: false,
      prerequisites: assessment.prerequisitesWithTitle,
      planning: assessment.planning,
      // this property is not exposed from `api/assessments/{id}`
      // but we know it's zero?
      totalStars: 0,
      // this property is not exposed from `api/assessments/{id}`
      // but we know it's zero?
      remainingStars: 0,
      moduleId: assessment.moduleId,
      // this property is not exposed from `api/assessments/{id}`
      // but we know it they don't exist for a assessment type of lesson
      features: {
        areStarsEnabled: false,
        attemptRestrictionApiResource: {
          lockIntervalMin: 0,
          resetCourseOnNumberAttempts: 0,
          shouldLockOnCompletion: false
        }
      },
      // this property is not exposed from `api/assessments/{id}`
      // but we know if the endpoint returns the data is because it's published
      displayActive: true,
      // this property is not exposed from `api/assessments/{id}`
      // but we know assessment are not supported offline
      downloadable: false,
      // this property is not exposed from `api/assessments/{id}`
      // but we know assessment don't have an archive size
      archiveSize: 0,
      // this property is not exposed from `api/assessments/{id}`
      // but we know assessment don't have stars
      showStarProgressScore: false,
      shouldHideContentType: false,
      shouldUseCourseBranding: false,
      slides: [],
      // The progress is injected by selectors - not always there...
      progress: undefined
    };
  }

  /**
   * Ideally, the backend should be consistent and return the same type for everytime we ask for the entity 'Conference'
   *
   * `GET api/conferences/{id}` returns different data than `GET api/course/{id}/sync`
   */
  private static mapConferenceToLessonSummary(conference: ConferenceType): LessonSummaryType {
    return {
      typeName: CoursewareTypeNames.LESSON_SUMMARY,
      lessonType: 'Conference',
      lessonId: conference.id,
      title: conference.title,
      description: conference.description || '',
      displayActive: conference.isPublished,
      planning: {
        startDateTime: conference.startDate || undefined,
        endDateTime: conference.endDate || undefined
      },

      // this property is not exposed from `api/conferences/{id}`
      // no slides in conferences?
      numberOfSlides: 0,
      // this property is not exposed from `api/conferences/{id}`
      // no slides in conferences?
      duration: 0,
      // this property is not exposed from `api/conferences/{id}`
      // no access rules in conferences?
      platformsAvailable: [],
      // this property is not exposed from `api/conferences/{id}`
      // no access rules in conferences?
      minimumScore: 0,
      // this property is not exposed from `api/conferences/{id}`
      // no score in conferences?
      hideScore: false,
      // this property is not exposed from `api/conferences/{id}`
      // no prereqs in conferences?
      prerequisites: [],
      // this property is not exposed from `api/conferences/{id}`
      // no stars in conferences?
      totalStars: 0,
      // this property is not exposed from `api/conferences/{id}`
      // no stars in conferences?
      remainingStars: 0,
      // this property is not exposed from `api/conferences/{id}`
      // conferences don't work with modules?
      moduleId: '',
      // this property is not exposed from `api/conferences/{id}`
      features: {
        areStarsEnabled: false,
        attemptRestrictionApiResource: {
          lockIntervalMin: 0,
          resetCourseOnNumberAttempts: 0,
          shouldLockOnCompletion: false
        }
      },
      // this property is not exposed from `api/conferences/{id}`
      // but we know conferences are not supported offline
      downloadable: false,
      // this property is not exposed from `api/conferences/{id}`
      // but we know conferences don't have an archive size
      archiveSize: 0,
      // this property is not exposed from `api/conferences/{id}`
      // but we know conferences don't have stars
      showStarProgressScore: false,
      shouldHideContentType: false,
      shouldUseCourseBranding: false,
      slides: [],
      // The progress is injected by selectors - not always there...
      progress: undefined
    };
  }

  /**
   * Ideally, the backend should be consistent and return the same type for everytime we ask for the entity 'Lesson"
   *
   * `GET api/lesson/{id}/sync` returns different data than `GET api/course/{id}/sync`
   */
  private static mapLessonToLessonSummary(lesson: LessonType): LessonSummaryType {
    return {
      typeName: CoursewareTypeNames.LESSON_SUMMARY,
      lessonType: lesson.lessonType,
      lessonId: lesson.id,
      title: lesson.title,
      description: lesson.description,
      numberOfSlides: lesson.configuration.slides.length,
      duration: lesson.configuration.duration,
      platformsAvailable: lesson.platformsAvailable,
      minimumScore: lesson.configuration.styleConfiguration.minimumScore,
      hideScore: lesson.shouldHideScore,
      prerequisites: lesson.prerequisitesWithTitle,
      planning: lesson.planning,
      totalStars: lesson.totalStars,
      remainingStars: 0,
      moduleId: lesson.moduleId || '',
      features: lesson.features,
      displayActive: lesson.isDisplayActive,
      downloadable: lesson.configuration.isOnline,
      archiveSize: lesson.archiveSize,
      showStarProgressScore: lesson.shouldShowStarProgressScore,
      shouldHideContentType: lesson.shouldHideContentType,
      shouldUseCourseBranding: lesson.shouldUseCourseBranding,
      slides: [],
      // The progress is injected by selectors - not always there...
      progress: undefined
    };
  }

  /**
   * Same as above!
   */
  private static mapObservationToLessonSummary(observation: ObservationType): LessonSummaryType {
    return {
      typeName: CoursewareTypeNames.LESSON_SUMMARY,
      lessonType: 'Observation',
      lessonId: observation.id,
      title: observation.name,
      description: observation.description,

      // TO fix deeplinks into a locked observation
      prerequisites: observation.prerequisitesWithTitle,
      planning: observation.planning,

      // the items below are not exposed from the endpoin
      numberOfSlides: 0,
      duration: 0,
      platformsAvailable: [],
      minimumScore: 0,
      hideScore: false,
      totalStars: 0,
      remainingStars: 0,
      moduleId: '',
      features: {
        areStarsEnabled: false,
        attemptRestrictionApiResource: {
          lockIntervalMin: 0,
          resetCourseOnNumberAttempts: 0,
          shouldLockOnCompletion: false
        }
      },
      displayActive: true,
      downloadable: false,
      archiveSize: 0,
      showStarProgressScore: false,
      shouldHideContentType: false,
      shouldUseCourseBranding: false,
      slides: [],
      // The progress is injected by selectors - not always there...
      progress: undefined
    };
  }

  public static map(activity: ActivityType): LessonSummaryType {
    switch (activity.type) {
      case 'Assessment':
        return this.mapAssessmentToLessonSummary(activity.activity);

      case 'Conference':
        return this.mapConferenceToLessonSummary(activity.activity);

      case 'Discussion':
        return this.mapDiscussionToLessonSummary(activity.activity);

      case 'Lesson':
        return this.mapLessonToLessonSummary(activity.activity);

      case 'Observation':
        return this.mapObservationToLessonSummary(activity.activity);

      default:
        throw Error(`Unknown activity type: ` + activity['type']);
    }
  }
}
