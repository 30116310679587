import { createAction } from '@edapp/utils';
import type { SearchItem } from './types';

export enum SearchActionTypes {
  SET_IS_OPEN = 'SET_IS_OPEN',
  SET_IS_PREVIEW_MODE = 'SET_IS_PREVIEW_MODE',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  ADD_RECENT_SEARCH_TERM = 'ADD_RECENT_SEARCH_TERM',
  SELECT_SEARCH_ITEM = 'SELECT_SEARCH_ITEM',

  FILTER_ITEMS = 'FILTER_ITEMS',

  FETCH_SEARCH_CATALOG = 'FETCH_SEARCH_CATALOG',
  FETCH_SEARCH_CATALOG_SUCCESS = 'FETCH_SEARCH_CATALOG_SUCCESS',
  FETCH_SEARCH_CATALOG_FAILURE = 'FETCH_SEARCH_CATALOG_FAILURE'
}

export const SearchActions = {
  setIsOpen: (isOpen: boolean) => createAction(SearchActionTypes.SET_IS_OPEN, { isOpen }),
  setIsPreviewMode: (isPreviewMode: boolean) =>
    createAction(SearchActionTypes.SET_IS_PREVIEW_MODE, { isPreviewMode }),
  setSearchTerm: (searchTerm: string) =>
    createAction(SearchActionTypes.SET_SEARCH_TERM, { searchTerm }),
  addRecentSearchTerm: (searchTerm: string) =>
    createAction(SearchActionTypes.ADD_RECENT_SEARCH_TERM, { searchTerm }),
  selectSearchItem: (item: SearchItem) =>
    createAction(SearchActionTypes.SELECT_SEARCH_ITEM, { item }),

  filterItems: (searchTerm: string) => createAction(SearchActionTypes.FILTER_ITEMS, { searchTerm }),

  fetchSearchCatalog: () => createAction(SearchActionTypes.FETCH_SEARCH_CATALOG)
};
