import { select } from 'redux-saga/effects';

import { AssessmentSelectors } from '@maggie/store/courseware/assessments/selectors';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidMainNavigateToAssessment(assessmentId: string) {
  const postId: string | undefined = yield select<LxStoreState>(
    AssessmentSelectors.getPostForAssignment(assessmentId)
  );

  if (postId) {
    window.__router.navigate('submission', { assessmentId, postId: postId });
  }
}
