import type { PaddingProps, MarginProps } from './types';

import { getSpaceValue } from '../../utils';

export function appendPaddings(props: PaddingProps): string {
  let css = '';

  if (props.p != null) {
    css += `padding: ${getSpaceValue(props.p)}px;`;
  }

  if (props.px != null) {
    const value = getSpaceValue(props.px);
    css += `
      padding-left: ${value}px;
      padding-right: ${value}px;
    `;
  }

  if (props.py != null) {
    const value = getSpaceValue(props.py);
    css += `
      padding-top: ${value}px;
      padding-bottom: ${value}px;
    `;
  }

  if (props.pt) {
    css += `padding-top: ${getSpaceValue(props.pt)}px;`;
  }

  if (props.pr) {
    css += `padding-right: ${getSpaceValue(props.pr)}px;`;
  }

  if (props.pb) {
    css += `padding-bottom: ${getSpaceValue(props.pb)}px;`;
  }

  if (props.pl) {
    css += `padding-left: ${getSpaceValue(props.pl)}px;`;
  }

  return css;
}

export function appendMargins(props: MarginProps): string {
  let css = '';

  if (props.m != null) {
    css += `margin: ${getSpaceValue(props.m)}px;`;
  }

  if (props.mx != null) {
    const value = getSpaceValue(props.mx);
    css += `
        margin-left: ${value}px;
        margin-right: ${value}px;
      `;
  }

  if (props.my != null) {
    const value = getSpaceValue(props.my);
    css += `
        margin-top: ${value}px;
        margin-bottom: ${value}px;
      `;
  }

  if (props.mt) {
    css += `margin-top: ${getSpaceValue(props.mt)}px;`;
  }

  if (props.mr) {
    css += `margin-right: ${getSpaceValue(props.mr)}px;`;
  }

  if (props.mb) {
    css += `margin-bottom: ${getSpaceValue(props.mb)}px;`;
  }

  if (props.ml) {
    css += `margin-left: ${getSpaceValue(props.ml)}px;`;
  }

  return css;
}
