import { eventManager } from '../event-manager';
import { SocialLearningService } from '../social_learning/social_learning_service';
import type {
  SocialLearningCommentAddEvent,
  SocialLearningCommentEvent,
  SocialLearningEvent
} from '../social_learning/types';
import { slidedeckPrefix } from './utils';

export class ThomasSocialLearningBridge {
  /**
   * The service to manage network requests
   */
  private socialLearningService: SocialLearningService;

  constructor() {
    this.socialLearningService = new SocialLearningService();
  }

  public registerSocialLearningEvents(id: string) {
    if (!SocialLearningService.isSocialLearningEnabled()) {
      return;
    }

    const prefix = slidedeckPrefix(id);
    const service = this.socialLearningService;

    eventManager.listenTo(`${prefix}:social-view-clicked`, function (
      this: ThomasSocialLearningBridge,
      { slideId }: SocialLearningEvent
    ) {
      service.postProgress(id, slideId);
    });

    eventManager.listenTo(`${prefix}:add-comment`, function (
      this: ThomasSocialLearningBridge,
      commentData: SocialLearningCommentAddEvent
    ) {
      const { slideId } = commentData;
      service.postComment(id, slideId, { text: commentData.message });
    });

    eventManager.listenTo(`${prefix}:like-comment`, function (
      this: ThomasSocialLearningBridge,
      { slideId, commentId }: SocialLearningCommentEvent
    ) {
      service.addLike({ lessonId: id, slideId, commentId });
    });

    eventManager.listenTo(`${prefix}:unlike-comment`, function (
      this: ThomasSocialLearningBridge,
      { slideId, commentId }: SocialLearningCommentEvent
    ) {
      service.dislike({ lessonId: id, slideId, commentId });
    });

    eventManager.listenTo(`${prefix}:delete-comment`, function (
      this: ThomasSocialLearningBridge,
      { slideId, commentId }: SocialLearningCommentEvent
    ) {
      service.deleteComment({ lessonId: id, slideId, commentId });
    });

    eventManager.listenTo(`${prefix}:flag-comment`, function (
      this: ThomasSocialLearningBridge,
      { slideId, commentId }: SocialLearningCommentEvent
    ) {
      service.addFlag({ lessonId: id, slideId, commentId });
    });

    eventManager.listenTo(`${prefix}:more-comment`, function (
      this: ThomasSocialLearningBridge,
      { slideId }: SocialLearningEvent
    ) {
      service.getSlideCommentsNextPage({ lessonId: id, slideId });
    });

    eventManager.listenTo(`${prefix}:scroll-to-top`, () => window.scroll(0, 0));

    eventManager.listenTo(`${prefix}:slide-start`, function (
      this: ThomasSocialLearningBridge,
      info: SocialLearningCommentEvent
    ) {
      service.clearState();
      service.getCommentsForSlide(id, info.slideId);
    });
  }

  public destroySocialLearningListeners(id: string) {
    if (!SocialLearningService.isSocialLearningEnabled()) {
      return;
    }

    const prefix = slidedeckPrefix(id);
    eventManager.stopListening(`${prefix}:social-view-clicked`);
    eventManager.stopListening(`${prefix}:add-comment`);
    eventManager.stopListening(`${prefix}:add-comment`);
    eventManager.stopListening(`${prefix}:like-comment`);
    eventManager.stopListening(`${prefix}:delete-comment`);
    eventManager.stopListening(`${prefix}:scroll-to-top`);
    eventManager.stopListening(`${prefix}:flag-comment`);
    eventManager.stopListening(`${prefix}:more-comment`);
    eventManager.stopListening(`${prefix}:slide-start`);
  }
}
