import type { RouteName, RouteParams } from '@maggie/core/router/types';

export type LockedDialogState = {
  isOpen: boolean;
  title: string;
  contentText: string;
  redirectParams: RedirectParams;
  acceptText?: string;
};

export type DoNotRedirectParams = {
  shouldRedirect: false;
};

export type DoRedirectParams<R extends RouteName> = {
  shouldRedirect: true;
  routeName: R;
  params: RouteParams<R>;
  redirectHomeFromBackdrop?: boolean;
};

export type RedirectParams = DoRedirectParams<RouteName> | DoNotRedirectParams;

export type LockedDatesPayload = {
  dialogTitle: string;
  dialogText: string;
};

export function getRedirectToHomeParams(): DoRedirectParams<'home'> {
  return {
    shouldRedirect: true,
    routeName: 'home',
    params: {}
  };
}

export function getRedirectToPlaylistParams(playlistId: string): DoRedirectParams<'playlist'> {
  return {
    shouldRedirect: true,
    routeName: 'playlist',
    params: {
      id: playlistId
    },
    redirectHomeFromBackdrop: true
  };
}
