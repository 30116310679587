import * as _ from 'lodash';

import type { LxStoreState } from '@maggie/store/types';

import { CollectionsSelectors } from '../collections/selectors';
import type { CourseSummaryType } from '../collections/types';

const getAllCourseSummaries = (state: LxStoreState) => {
  const courseSummaries: CourseSummaryType[] = [];

  // Include all courseSummaries from all collections
  const courseCollections = CollectionsSelectors.getCollections(state);
  Object.keys(courseCollections).forEach(collectionId => {
    const courseCollection = courseCollections[collectionId];
    courseCollection.courses.forEach(courseSummary => {
      courseSummaries.push(courseSummary);
    });
  });

  // include all courseSummaries from all fetched playlist items
  Object.keys(state.courseware.playlists.playlistItems).forEach(playlistId => {
    const playlist = state.courseware.playlists.playlistItems[playlistId];
    playlist.courses.forEach(courseSummary => {
      courseSummaries.push(courseSummary);
    });
  });

  // Filter possible duplicates
  return _.uniqBy(courseSummaries, c => c.courseId);
};

const CourseSummariesSelectors = {
  getAllCourseSummaries
};
export { CourseSummariesSelectors };
