import { Howl } from 'howler';
import { get } from 'lodash';

import { ErrorLogger } from '@edapp/monitoring';
import { UserSelectors } from '@maggie/store/user/selectors';

import { FileManager } from './file';
import { Platform } from './platform';

export type SoundName =
  | 'correct.mp3'
  | 'image-flip-bg.mp3'
  | 'image-flip-lose.mp3'
  | 'image-flip-step-1.mp3'
  | 'image-flip-step-2.mp3'
  | 'image-flip-step3.mp3'
  | 'image-flip-win.mp3'
  | 'incorrect.mp3'
  | 'spin-fail.mp3'
  | 'spin-stop.mp3'
  | 'spin-win.mp3'
  | 'spinning.mp3';

export class Sound {
  private static preloadedSounds: Partial<Record<SoundName, boolean>>;
  private static howlerSounds: Partial<Record<SoundName, Howl>>;

  public static async initialize() {
    this.preloadedSounds = {};
    this.howlerSounds = {};

    /**
     * iOS - use cordova-native-plugin
     * Android & Web - use Howler
     */
    if (Platform.get() === 'iOS') {
      return Sound.preloadAllNative();
    }
  }

  private static getAudioPlugin = (): NativeAudio | undefined =>
    Platform.get() === 'iOS' ? window.plugins?.NativeAudio : undefined;

  // #region - iOS preload
  private static preloadNative = async (nativeSoundFile: SoundName): Promise<void> => {
    return new Promise((resolve, reject) => {
      const success = () => {
        Sound.preloadedSounds[nativeSoundFile] = true;
        resolve();
      };

      const error: ErrorCallback = err => {
        if (err.toString && err.toString().match(/already exists/)) {
          Sound.preloadedSounds[nativeSoundFile] = true;
        } else {
          ErrorLogger.captureEvent('Failed to load sound', 'error', {
            err,
            soundFile: nativeSoundFile
          });
        }
        reject();
      };

      const plugin = Sound.getAudioPlugin();
      if (plugin) {
        plugin.preloadComplex(nativeSoundFile, nativeSoundFile, 1, 1, 0, success, error);
      }
    });
  };

  private static preloadAllNative = async () => {
    try {
      const files = await FileManager.listDirectory(FileManager.wwwDirectory);
      const soundFiles = files.filter(f => get(f, 'name', '').match(/.mp3$/));
      const ids = soundFiles.map(file => file.name);
      await Promise.all(ids.map(Sound.preloadNative));
    } catch (err) {
      // ignore error!
      // fail silently to not block UX from intializing app when something goes wrong in sounds
    }
  };
  // #endregion

  // #region Howler setup
  /**
   * Remove Howler sounds when they have stopped or finished playing
   */
  private static removeHowlerSound = (soundFile: SoundName) => {
    delete Sound.howlerSounds[soundFile];
  };

  private static playHowlerSound = (soundFile: SoundName, loop: boolean) => {
    try {
      const sound = new Howl({
        src: [soundFile],
        loop,
        onloaderror: (_, err) => {
          ErrorLogger.captureEvent('Failed to load howler sound', 'error', {
            err,
            soundFile
          });
        },
        ...(!loop && {
          onend: () => {
            Sound.howlerSounds[soundFile];
          }
        })
      });

      Sound.howlerSounds[soundFile] = sound;
      sound.play();
    } catch (err) {
      ErrorLogger.captureEvent('Failed to play howler sound', 'error', {
        err,
        soundFile: soundFile,
        loop
      });
    }
  };
  // #endregion

  public static play = async (soundFile: SoundName, loop: boolean = false) => {
    if (UserSelectors.getSoundDisabled(window.__store.getState())) {
      return;
    }

    return new Promise<void>(async (resolve, reject) => {
      const plugin = Sound.getAudioPlugin();
      if (!plugin) {
        Sound.playHowlerSound(soundFile, loop);
        return resolve();
      }

      if (!Sound.preloadedSounds[soundFile]) {
        await Sound.preloadNative(soundFile);
        ErrorLogger.captureEvent('Sound not preloaded', 'warning', { soundFile });
      }

      const play = loop ? plugin.loop : plugin.play;
      play(soundFile, resolve, reject);
    });
  };

  public static stop = (soundFile: SoundName) => {
    return new Promise<void>(async (resolve, reject) => {
      const plugin = Sound.getAudioPlugin();
      if (!plugin) {
        const sound = Sound.howlerSounds[soundFile];

        if (sound) {
          sound.stop();
          Sound.removeHowlerSound(soundFile);
        }
        return resolve();
      }

      if (!Sound.preloadedSounds[soundFile]) {
        await Sound.preloadNative(soundFile);
        ErrorLogger.captureEvent('Sound not preloaded', 'warning', { soundFile: soundFile });
      }

      plugin.stop(soundFile, resolve, reject);
    });
  };
}
