import { cloneDeep, pick } from 'lodash';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';
import type { ConfigurationType, LessonType } from '@maggie/store/courseware/lessons/types';
import { FeatureFlagsSelectors } from '@maggie/store/feature-flags/selectors';
import { LessonReviewActions } from '@maggie/store/lesson-review/actions';
import { SlideProgressSelectors } from '@maggie/store/slide-progress/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';
import { deepMap } from '@maggie/utils/deepMap';

import { Asset, isAsset } from '../asset';
import { ReviewAttempt } from '../attempt/review-attempt';
import { eventManager } from '../event-manager';
import { slidedeckPrefix } from '../thomas/utils';
import { ThomasPlayer } from './thomas-player';
import type { ConfigurationForThomas, ContextForThomas } from './thomas-player-interface';

export class ReviewThomasPlayer extends ThomasPlayer {
  /**
   * !Note: DO NOT USE `lesson.configuration` directly.
   *
   * Instead, use `this.thomasAssets.getConfiguration()`
   */
  private readonly lesson: Readonly<LessonType>;

  private readonly attempt: ReviewAttempt;

  constructor(lesson: LessonType, iFrameParent: string) {
    super(lesson.id, lesson.configuration, lesson.engine.thomasVersion, iFrameParent);
    this.lesson = lesson;
    this.attempt = new ReviewAttempt(lesson);
  }

  /**
   * Returns the lesson configuration object.
   */
  private getLessonConfigurationForThomas = (config: ConfigurationType): ConfigurationForThomas => {
    let configuration = cloneDeep(config);

    // Disable social learning
    for (const slide of configuration.slides) {
      if (slide?.data?.socialLearning?.enabled) {
        slide.data.socialLearning.enabled = false;
      }
    }

    configuration = deepMap(configuration, (value: any) =>
      isAsset(value) ? new Asset(value, 'lessons', this.lesson.id).remoteURL : value
    );

    return {
      ...configuration,
      // @ts-expect-error TODO: EDAPP-42821 Property 'scormEncodedData' is missing in type 'SlideProgressType' but required
      userState: SlideProgressSelectors.getSlideProgressForThomas(this.lesson.id)(
        window.__store.getState()
      ),
      config: {
        ...configuration.styleConfiguration,
        contextAllowsFreeNavigation: false
      },
      title: configuration.title ? configuration.title : this.lesson.title
    };
  };

  protected getContextForThomas = (): ContextForThomas => {
    const state = window.__store.getState();
    const configuration = this.thomasAssets.getConfiguration();

    return {
      slidedeck: {
        id: this.lesson.id,
        userId: UserSelectors.getId(state),
        email: UserSelectors.getEmail(state),
        firstName: UserSelectors.getFirstName(state),
        lastName: UserSelectors.getLastName(state),
        name: UserSelectors.getName(state),
        stars: {},
        disableAnswerFeedback: !configuration?.styleConfiguration?.hasAnswerFeedback,
        enableStars: false,
        alreadyCompleted: this.lesson.progress && this.lesson.progress.completed,
        isScored: false,
        platform: Platform.get(),
        attempt: '',
        config: this.getLessonConfigurationForThomas(configuration),
        aiccURL: ENV.apiUrl('/aicc')
      },
      uploadConfiguration: {},
      downloadConfiguration: {},
      featureFlags: pick(FeatureFlagsSelectors.getFlagsEnabled(state), [])
    };
  };

  public open = async () => {
    window.__store.dispatch(
      LessonReviewActions.openLessonReview(this.lesson.id, this.lesson.courseId)
    );

    this.attempt.startAttempt();
    this.attempt.startRecording(this.lesson.id);

    await super.open();

    // In review mode - make sure to unlisten to exit event.
    // We don't want the player to close itself.
    const prefix = slidedeckPrefix(this.id);
    eventManager.stopListening(`${prefix}:exit`);

    itly.openLesson({
      id: this.lesson.id,
      type: 'Lesson',
      interaction_type: 'View'
    });

    this.thomasBridge.toggleHeader('desktop');
  };

  public close = async () => {
    await super.close();

    this.attempt.finishAttempt();
    this.attempt.stopRecording(this.lesson.id);

    itly.lessonClosed({
      id: this.lesson.id,
      attempt_id: this.attempt.attemptId,
      has_seen_last_slide: this.attempt.hasSeenLastOrExitSlide(),
      time_spent: this.attempt.endedAt - this.attempt.startedAt,
      interaction_type: 'View'
    });

    window.__store.dispatch(LessonReviewActions.closeLessonReview(this.lesson.id));
  };

  public goToPage = (pageIndex: number) => {
    if (pageIndex >= this.lesson.configuration.slides.length) {
      ErrorLogger.captureEvent('Overflow! navigation to slide (lesson review)', 'error', {
        pageIndex,
        slideCount: this.lesson.configuration.slides.length
      });
      return; // overflow! do nothing
    }

    this.thomasBridge.goToPage(pageIndex);
  };
}
