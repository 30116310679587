import { addDays, addMinutes, set } from 'date-fns';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { RequestActions } from '@edapp/request';
import type { DictionaryType } from '@edapp/utils';
import { ENV } from '@maggie/config/env';
import { Notifier } from '@maggie/cordova/notifier/notifier';
import { Platform } from '@maggie/cordova/platform';
import { Urls } from '@maggie/store/constants';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { CourseProgressType } from '@maggie/store/courseware/courses/types';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type { LessonProgressType } from '@maggie/store/courseware/lessons/types';
import { LessonUtils } from '@maggie/store/courseware/lessons/utils';
import { CoursewareUtils } from '@maggie/store/courseware/utils';
import type { LxStoreState } from '@maggie/store/types';

import { LocalNotificationActionTypes, LocalNotificationActions } from '../actions';
import { DEBUG_LOCAL_NOTIFICATIONS, LESSON_NOTIFICATION_SCHEDULE } from '../constants';
import { LocalNotificationsSelectors } from '../selectors';
import type { LessonNotificationData, TypeMap } from '../types';

export function* handleScheduleLessonLocalNotifications(
  action: TypeMap<LocalNotificationActionTypes.SCHEDULE_LESSON_NOTIFICATIONS>
): any {
  const { hour, minute } = action.payload;

  yield put(
    RequestActions.getAuthed(
      Urls.LESSON_NOTIFICATIONS,
      LocalNotificationActionTypes.FETCH_LESSON_NOTIFICATIONS_SUCCESS,
      LocalNotificationActionTypes.FETCH_LESSON_NOTIFICATIONS_FAILURE,
      undefined
    )
  );

  yield race({
    success: take(LocalNotificationActionTypes.FETCH_LESSON_NOTIFICATIONS_SUCCESS),
    failure: take(LocalNotificationActionTypes.FETCH_LESSON_NOTIFICATIONS_FAILURE)
  });

  const upcomingNotifications: LessonNotificationData[] = yield select<LxStoreState>(
    LocalNotificationsSelectors.getUpcomingLessonNotifications
  );
  const lessonsProgress: DictionaryType<LessonProgressType> = yield select<LxStoreState>(
    LessonSelectors.getLessonsProgress
  );
  const coursesProgress: DictionaryType<CourseProgressType> = yield select<LxStoreState>(
    CourseSelectors.getCoursesProgress
  );
  const nextLessonNotification = findNextAvailableLesson(
    upcomingNotifications,
    lessonsProgress,
    coursesProgress
  );

  const currentLessonScheduled: string = yield select<LxStoreState>(
    LocalNotificationsSelectors.getLastLessonIdScheduled
  );

  if (currentLessonScheduled === nextLessonNotification?.lessonId) {
    // If the current notification is the same leave it
    return;
  }

  // Cancel the current notifications even if there is no new notification candidate
  const ids = LESSON_NOTIFICATION_SCHEDULE.map(uniqueLessonNotificationId);
  try {
    yield call(Notifier.cancel, ids);
  } catch (err) {
    ErrorLogger.captureException(err);
  }

  if (!nextLessonNotification) {
    // No notification candidate found - nothing to schedule
    return;
  }

  // Schedule the local lesson notifications
  const newLessonNotifications = LESSON_NOTIFICATION_SCHEDULE.map(
    makeLessonNotification(hour, minute, nextLessonNotification)
  );

  Notifier.schedule(newLessonNotifications);

  // Store the lesson id of the next notification
  yield put(LocalNotificationActions.setLastLessonScheduled(nextLessonNotification.lessonId));
}

export function* watchScheduleLessonNotifications() {
  yield takeLatest(
    LocalNotificationActionTypes.SCHEDULE_LESSON_NOTIFICATIONS,
    handleScheduleLessonLocalNotifications
  );
}

/**
 * Finds the next available lesson to set a notification.
 */
export const findNextAvailableLesson = (
  lessonNotifications: LessonNotificationData[],
  lessonsProgress: DictionaryType<LessonProgressType>,
  coursesProgress: DictionaryType<CourseProgressType>
): LessonNotificationData | undefined => {
  const completedLessonIds = Object.keys(lessonsProgress).filter(
    id => lessonsProgress[id].completed
  );
  const completedCourseIds = Object.keys(coursesProgress).filter(
    id => coursesProgress[id].completed
  );

  // Filter out locally completed lessons and courses from list
  const upcomingLessons = lessonNotifications
    .filter(n => completedCourseIds.indexOf(n.courseId) < 0)
    .filter(n => completedLessonIds.indexOf(n.lessonId) < 0);

  return upcomingLessons.find(ln => {
    if (!CoursewareUtils.isLessonInAvailableDates(ln.lessonStartDate, ln.lessonEndDate)) {
      return false;
    }

    if (!CoursewareUtils.isCourseInAvailableDates(ln.courseStartDate, ln.courseEndDate)) {
      return false;
    }

    if (!isAvailableForPlatform(ln)) {
      return false;
    }

    // Check if all lesson prerequisits are complete
    if (ln.lessonPrerequisites.length) {
      const found = upcomingLessons.find(n => ln.lessonPrerequisites.includes(n.lessonId));
      if (found) {
        return false;
      }
    }

    // Check if all course prerequisits are complete
    if (ln.coursePrerequisites.length) {
      const found = upcomingLessons.find(n => ln.coursePrerequisites.includes(n.courseId));
      if (found) {
        return false;
      }
    }

    // Passes all checks
    return true;
  });
};

const isAvailableForPlatform = (notification: LessonNotificationData) => {
  const platform = Platform.get();

  if (platform === 'Android' && notification.availableOnAndroid) {
    return true;
  } else if (platform === 'iOS' && notification.availableOnIos) {
    return true;
  } else {
    return false || DEBUG_LOCAL_NOTIFICATIONS;
  }
};

export const makeLessonNotification = (
  hour: number,
  minute: number,
  data: LessonNotificationData
) => (unit: number) => {
  const at = ENV.IS_FAST_FORWARD_LOCAL_NOTIFICATIONS
    ? addMinutes(set(new Date(), { seconds: 0, milliseconds: 0 }), unit)
    : addDays(set(new Date(), { hours: hour, minutes: minute, seconds: 0, milliseconds: 0 }), unit);

  return {
    id: uniqueLessonNotificationId(unit),
    at,
    title: LessonUtils.getNotificationTitle(),
    text: LessonUtils.getNotificationMessage(data.lessonTitle),
    data: {
      type: 'content',
      lessonId: data.lessonId
    }
  };
};

/**
 * Generates a deterministic id for local notification plugin.
 * This is so that we can cancel only lesson notifications.
 *
 * PRIME - is a Magic identifier number =D
 *
 * @param {number} day The day of the notification
 */
export const uniqueLessonNotificationId = (day: number, PRIME = 7) => PRIME * day;
