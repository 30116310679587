import type { RankingParticipant } from '@edapp/leaderboards';
import type { LxStoreState } from '@maggie/store/types';

const findParticipantFromRapidRefreshRanking = (courseId: string, userId: string) => {
  return (state: LxStoreState): RankingParticipant | undefined => {
    const ranking = state.leaderboards.rapidRefresh[courseId] || [];
    return ranking.find(p => p.participantId === userId);
  };
};

const getRapidRefreshRanking = (courseId: string) => {
  return (state: LxStoreState) => {
    return state.leaderboards.rapidRefresh[courseId];
  };
};

const isSelectedLeaderboard = (courseId: string) => {
  return (state: LxStoreState) => {
    return state.leaderboards.selectedLeaderboard?.courseId === courseId;
  };
};

const getTitle = (state: LxStoreState) => {
  const leaderboard = state.leaderboards.selectedLeaderboard;
  return leaderboard?.leaderBoardName || '';
};

export const LeaderboardsSelectors = {
  findParticipantFromRapidRefreshRanking,
  getRapidRefreshRanking,
  isSelectedLeaderboard,
  getTitle
};
