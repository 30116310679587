import { checkOnline } from '@maggie/cordova/network_utils';

import type { ConfigState } from './types';

export const initialConfigState: ConfigState = {
  restrictToCourseScreen: false,
  restrictToLessonScreen: false,
  platform: undefined,
  impersonatedUserId: '',
  devicePushNotificationToken: '',
  isOnline: checkOnline(),
  isSlowConnection: false,
  orientation: screen.orientation?.type || ''
};
