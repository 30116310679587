import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import type { LessonActionsUnionType } from '@maggie/store/courseware/lessons/types';
import type { UserActionsUnionType } from '@maggie/store/user/types';
import { RapidRefreshActionTypes } from '../courseware/rapid-refreshes/actions';
import type { RapidRefreshActionsUnionType } from '../courseware/rapid-refreshes/types';

import type { LessonReviewUnionType } from '../lesson-review/actions';
import { LessonReviewActionTypes } from '../lesson-review/actions';
import { ThomasActionTypes } from './actions';
import type { ThomasActionUnionType, ThomasState } from './types';

export const initialThomasState: ThomasState = {
  isOpen: false,
  lastCompletedSlideIndex: -1,
  slideIndex: 0,
  isMenuOpen: false,
  isNavigationEnabled: true
};

export const thomasReducer = (
  state: ThomasState = initialThomasState,
  action:
    | ThomasActionUnionType
    | UserActionsUnionType
    | LessonActionsUnionType
    | LessonReviewUnionType
    | RapidRefreshActionsUnionType
): ThomasState => {
  switch (action.type) {
    case LessonActionTypes.OPEN_LESSON:
    case LessonReviewActionTypes.OPEN_LESSON_REVIEW:
    case RapidRefreshActionTypes.OPEN_RAPID_REFRESH_THOMAS: {
      return { ...state, isOpen: true };
    }

    case LessonActionTypes.CLOSE_LESSON:
    case RapidRefreshActionTypes.CLOSE_RAPID_REFRESH_THOMAS: {
      return { ...state, isOpen: false };
    }

    case LessonReviewActionTypes.CLOSE_LESSON_REVIEW: {
      return { ...state, isOpen: false, slideIndex: 0, lastCompletedSlideIndex: -1 };
    }

    case LessonActionTypes.FINISHED_THOMAS_LESSON: {
      return { ...state, slideIndex: 0, lastCompletedSlideIndex: -1 };
    }

    case ThomasActionTypes.SET_SLIDE_INDEX: {
      return { ...state, slideIndex: action.payload.index };
    }

    case ThomasActionTypes.SET_IS_OPEN_MENU: {
      const { isOpen } = action.payload;
      return { ...state, isMenuOpen: isOpen };
    }

    case ThomasActionTypes.DISABLE_NAVIGATION: {
      return { ...state, isNavigationEnabled: false };
    }

    case ThomasActionTypes.ENABLE_NAVIGATION: {
      return { ...state, isNavigationEnabled: true };
    }

    case ThomasActionTypes.SET_LAST_COMPLETED_SLIDE_INDEX: {
      const { index } = action.payload;
      return { ...state, lastCompletedSlideIndex: index };
    }

    default:
      return state;
  }
};
