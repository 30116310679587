import { fork, put, race, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { UserActions } from '@maggie/store/user/actions';

import { ProfileActionTypes } from './actions';
import type { ProfileActionsUnionType } from './actions';
import { watchSetLanguage } from './set-language-sagas';

type ProfileActions<ActionType extends string> = ActionFromActionType<
  ProfileActionsUnionType,
  ActionType
>;

function* handleDeleteIndividualLearner() {
  yield put(
    RequestActions.deleteAuthed(
      Urls.USERS,
      ProfileActionTypes.DELETE_INDIVIDUAL_LEARNER_SUCCESS,
      ProfileActionTypes.DELETE_INDIVIDUAL_LEARNER_FAILURE
    )
  );

  const { success } = yield race({
    success: take(ProfileActionTypes.DELETE_INDIVIDUAL_LEARNER_SUCCESS),
    failure: take(ProfileActionTypes.DELETE_INDIVIDUAL_LEARNER_FAILURE)
  });

  if (!!success) {
    itly.deleteIndividualLearner();
    yield put(UserActions.userLogout(true, false));
  }
}

function* watchDeleteIndividualLearner() {
  yield takeLatest(ProfileActionTypes.DELETE_INDIVIDUAL_LEARNER, handleDeleteIndividualLearner);
}

function* handleUpdateAvatar(action: ProfileActions<ProfileActionTypes.UPDATE_AVATAR>) {
  const { avatarUrl } = action.payload;

  yield put(
    RequestActions.patchAuthed(
      Urls.UPDATE_AVATAR,
      ProfileActionTypes.UPDATE_AVATAR_SUCCESS,
      ProfileActionTypes.UPDATE_AVATAR_FAILURE,
      undefined,
      {
        avatarUrl
      }
    )
  );
}

function* watchUpdateAvatar() {
  yield takeLatest(ProfileActionTypes.UPDATE_AVATAR, handleUpdateAvatar);
}

export const profileSagas = [
  fork(watchDeleteIndividualLearner),
  fork(watchSetLanguage),
  fork(watchUpdateAvatar)
];
