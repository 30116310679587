import { createAction } from '@edapp/utils';

export enum BannersActionTypes {
  FETCH_BANNERS = 'FETCH_BANNERS',
  FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS',
  FETCH_BANNERS_FAILURE = 'FETCH_BANNERS_FAILURE'
}

export const BannersActions = {
  fetchBanners: () => createAction(BannersActionTypes.FETCH_BANNERS)
};
