import { RequestUtils } from '@edapp/request';
import { ENV } from '@maggie/config/env';
import { Urls } from '@maggie/store/constants';
import { UserSelectors } from '@maggie/store/user/selectors';
import { createUUID } from '@maggie/utils/uuid';

import type {
  SocialLearningCheckNewCommentsResponse,
  SocialLearningCommentInteraction,
  SocialLearningCommentPost,
  SocialLearningCommentsForSlideResponse,
  SocialLearningLessonCommentSummary,
  SocialLearningLessonResponse
} from './types';

export class SocialLearningApi {
  static getToken() {
    return UserSelectors.getToken(window.__store.getState());
  }

  static getCommentsForLessonSlide(
    lessonId: string,
    slideId: string,
    pageNum: number = 1,
    pageSize: number
  ): Promise<SocialLearningCommentsForSlideResponse> {
    return RequestUtils.httpFetch(
      'GET',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}/slides/${slideId}/comments?page=${pageNum}&pageSize=${pageSize}`,
      this.getToken()
    );
  }

  static postComment(lessonId: string, slideId: string, commentData: SocialLearningCommentPost) {
    commentData.commentId = createUUID();
    return RequestUtils.httpFetch(
      'POST',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}/slides/${slideId}/comments`,
      this.getToken(),
      commentData
    );
  }

  static addLike({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    return RequestUtils.httpFetch(
      'POST',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}/slides/${slideId}/comments/${commentId}/likes`,
      this.getToken()
    );
  }

  static disLike({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    return RequestUtils.httpFetch(
      'DELETE',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}/slides/${slideId}/comments/${commentId}/likes`,
      this.getToken()
    );
  }

  static addFlag({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    return RequestUtils.httpFetch(
      'POST',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}/slides/${slideId}/comments/${commentId}/flags`,
      this.getToken()
    );
  }

  static deleteComment({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    return RequestUtils.httpFetch(
      'DELETE',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}/slides/${slideId}/comments/${commentId}`,
      this.getToken()
    );
  }

  static getCommentCountForLesson(lessonId: string): Promise<SocialLearningLessonResponse> {
    return RequestUtils.httpFetch(
      'GET',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}`,
      this.getToken()
    );
  }

  static async getLessonCommentSummary(
    lessonId: string
  ): Promise<SocialLearningLessonCommentSummary> {
    return RequestUtils.httpFetch(
      'GET',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/lessons/${lessonId}`,
      this.getToken()
    );
  }

  static async checkIfNewComments(
    lessonId: string
  ): Promise<SocialLearningCheckNewCommentsResponse> {
    return RequestUtils.httpFetch(
      'GET',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/userprogress/lessons/${lessonId}`,
      this.getToken()
    );
  }

  static async postProgress(lessonId: string, slideId: string) {
    return RequestUtils.httpFetch(
      'POST',
      `${ENV.HIPPO_API}/${Urls.SOCIAL_LEARNING}/userprogress/lessons/${lessonId}/slides/${slideId}`,
      this.getToken()
    );
  }
}
