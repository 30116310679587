import { createAction } from '@edapp/utils';

enum InteractionActionTypes {
  SEND_ATTEMPT_AND_EMPTY_QUEUE = 'SEND_ATTEMPT_AND_EMPTY_QUEUE',
  SEND_ATTEMPT_AND_EMPTY_QUEUE_SUCCESS = 'SEND_ATTEMPT_AND_EMPTY_QUEUE_SUCCESS',
  SEND_ATTEMPT_AND_EMPTY_QUEUE_FAILURE = 'SEND_ATTEMPT_AND_EMPTY_QUEUE_FAILURE'
}

const InteractionActions = {
  sendAttemptAndEmptyQueue: () => createAction(InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE),
  sendAttemptAndEmptyQueueSuccess: () =>
    createAction(InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE_SUCCESS),
  sendAttemptAndEmptyQueueFailure: () =>
    createAction(InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE_FAILURE)
};

export { InteractionActionTypes, InteractionActions };
