import { put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import { InteractionActionTypes, InteractionActions } from '@maggie/store/interactions/actions';
import { NavigationActions } from '@maggie/store/navigation/actions';
import { SocialLearningActions } from '@maggie/store/social-learning/actions';
import type { LxStoreState } from '@maggie/store/types';

import type { CourseType, LessonSummaryType } from '../courses/types';
import type { UnlockPayload } from '../types';
import { LessonSelectors } from './selectors';
import type { LessonAction } from './types';

/**
 * It calculates the prerequisites of the sibling lessons inside of the same course.
 *
 * We need to calculate here to ensure that next lesson functionality works as expected.
 */
function* calculateLessonPrerequisites(lessonId: string) {
  const course: CourseType | undefined = yield select(LessonSelectors.getLessonCourse(lessonId));
  if (!course) {
    ErrorLogger.captureEvent(
      'close-lesson-sagas -> calculate prereqs -> cant find course',
      'error',
      { lessonId }
    );
    return;
  }

  const items: UnlockPayload[] = yield select(
    LessonSelectors.getUnlockPayloadFromPrerequisites(course.lessonSummaries)
  );
  yield put(LessonActions.updateLessonsUnlock(items));
}

function* handleCloseLesson(action: LessonAction<LessonActionTypes.CLOSE_LESSON>) {
  const { lessonId, isAttemptSuccessful } = action.payload;

  yield put(LessonActions.openLessonSummaryDialog());

  // Force clean and send interactions in IndexedDb if there are any left.
  yield put(InteractionActions.sendAttemptAndEmptyQueue());
  // Wait for Success cannot use race as we fail fail success with the retry button
  yield take(InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE_SUCCESS);

  // Calculate prereqs to enable next lesson button
  yield calculateLessonPrerequisites(lessonId);

  // If there is a next lesson to go to, let's prefetch it
  const nextLesson: null | LessonSummaryType = isAttemptSuccessful
    ? yield select<LxStoreState>(s =>
        LessonSelectors.getNextAccessibleLessonFromCurrentLessonId(lessonId, s)
      )
    : null;
  if (nextLesson) {
    yield put(NavigationActions.setLessonId(nextLesson.lessonId));
    yield put(LessonActions.fetchLessonWithProgress(nextLesson.lessonId));
    yield race({
      success: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS),
      failure: take(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE)
    });
  }

  // Dismiss saving/summary dialog
  const hasInteractionSummary: boolean = yield select(
    LessonSelectors.hasInteractionSummary(lessonId)
  );
  const isScoreDisabled: boolean = yield select(LessonSelectors.isScoreDisabled(lessonId));

  if (!hasInteractionSummary && isScoreDisabled) {
    yield put(LessonActions.closeLessonSummaryDialog(lessonId, isAttemptSuccessful));
  }

  // This event must be triggered whenever the lesson view becomes visible to the user
  HostedWebviewUtils.triggerEvent('lessonClosed');

  // Refresh social learning on the lesson page
  yield put(SocialLearningActions.fetchSocialLearningSummary(lessonId));
}

export function* watchCloseLesson() {
  yield takeLatest(LessonActionTypes.CLOSE_LESSON, handleCloseLesson);
}
