import RequestActionTypes from './constants';
import type { Action, RequestPayloadType } from './types';

const sendRequest = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  type: RequestActionTypes,
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => ({
  type,
  payload: {
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  }
});

export const getAuthed = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean = false,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => {
  return sendRequest(
    RequestActionTypes.AUTHED_GET_REQUEST,
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  );
};

export const postAuthed = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean = false,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => {
  return sendRequest(
    RequestActionTypes.AUTHED_POST_REQUEST,
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  );
};

export const deleteAuthed = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean = false,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => {
  return sendRequest(
    RequestActionTypes.AUTHED_DELETE_REQUEST,
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  );
};

export const getUnauthed = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean = false,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => {
  return sendRequest(
    RequestActionTypes.UNAUTHED_GET_REQUEST,
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  );
};

export const postUnauthed = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean = false,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => {
  return sendRequest(
    RequestActionTypes.UNAUTHED_POST_REQUEST,
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  );
};

export const putAuthed = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean = false,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => {
  return sendRequest(
    RequestActionTypes.AUTHED_PUT_REQUEST,
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  );
};

export const patchAuthed = <
  SuccessPayload = {},
  SuccessActionPayload = {},
  FailurePayload = {} | undefined,
  FailureActionPayload = {}
>(
  uri: string,
  successAction: string | ((data: SuccessPayload) => Action<SuccessActionPayload>),
  failureAction: string | ((error: FailurePayload) => Action<FailureActionPayload>),
  includeCredentialsCookie: boolean = false,
  data?: {},
  headers?: HeadersInit,
  isFormData?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  overrideErrorMessage?: boolean,
  /**
   * @see `RequestPayloadType`
   */
  url?: string
): Action<RequestPayloadType> => {
  return sendRequest(
    RequestActionTypes.AUTHED_PATCH_REQUEST,
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    data,
    headers,
    isFormData,
    overrideErrorMessage,
    url
  );
};
