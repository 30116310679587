import { ErrorCode } from '@edapp/request';
import { NavigationActionTypes } from '@maggie/store/navigation/actions';
import type { NavigationActionsUnionType } from '@maggie/store/navigation/types';

import type { SessionActionsUnionType } from './actions';
import { SessionActionTypes } from './actions';
import type { SessionState } from './types';

export const initialSessionState: SessionState = {
  ssoCheckLoading: false,
  ssoCheckError: undefined,

  ssoLoginLoading: false,
  ssoIsMagicLink: false,
  ssoAuthenticationInProgress: false,

  loginError: undefined,
  loginLoading: false,
  loginFormStep: 'initial',
  loginRouteError: undefined,
  loginLastCheckedUsername: undefined
};

export const sessionReducer = (
  state: SessionState = initialSessionState,
  action: SessionActionsUnionType | NavigationActionsUnionType
): SessionState => {
  switch (action.type) {
    case SessionActionTypes.SSO_CHECK: {
      return { ...state, ssoCheckError: undefined, ssoCheckLoading: true };
    }
    case SessionActionTypes.SSO_CHECK_FAILURE: {
      return { ...state, ssoCheckError: action.payload.message, ssoCheckLoading: false };
    }
    case SessionActionTypes.SSO_CHECK_SUCCESS: {
      return { ...state, ssoCheckError: undefined, ssoCheckLoading: false };
    }

    case SessionActionTypes.LOGIN_WITH_SSO: {
      return { ...state, ssoLoginLoading: true };
    }

    case NavigationActionTypes.DID_NAVIGATE_SSO_LOGIN_FAILURE:
    case SessionActionTypes.LOGIN_WITH_SSO_FAILURE: {
      return { ...state, ssoLoginLoading: false };
    }
    case SessionActionTypes.LOGIN_WITH_SSO_SUCCESS: {
      return { ...state, ssoLoginLoading: false };
    }

    case SessionActionTypes.REDIRECT_TO_SSO: {
      return { ...state, ssoCheckLoading: false, ssoLoginLoading: false };
    }

    case SessionActionTypes.SSO_AUTHENTICATION_IN_PROGRESS: {
      return { ...state, ssoAuthenticationInProgress: true };
    }

    case SessionActionTypes.SSO_AUTHENTICATION_FINISHED: {
      return { ...state, ssoAuthenticationInProgress: false };
    }

    case NavigationActionTypes.DID_NAVIGATE_SSO_LOGIN: {
      const { magicLink } = action.payload;
      return {
        ...state,
        ssoIsMagicLink: !!magicLink
      };
    }

    case SessionActionTypes.SSO_RESET: {
      return {
        ...state,
        ssoCheckError: undefined,
        ssoCheckLoading: false,
        ssoLoginLoading: false
      };
    }

    case NavigationActionTypes.DID_NAVIGATE_LOGIN: {
      const { error } = action.payload;
      return { ...state, loginRouteError: error };
    }

    case SessionActionTypes.LOGIN: {
      return { ...state, loginLoading: true, loginError: undefined };
    }
    case SessionActionTypes.LOGIN_SUCCESS: {
      return { ...state, loginLoading: false, loginError: undefined };
    }
    case SessionActionTypes.LOGIN_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loginLoading: false,
        loginError: error?.code
      };
    }

    case SessionActionTypes.LOGIN_CHECK:
    case SessionActionTypes.FORGOT_PASSWORD:
    case SessionActionTypes.MAGIC_LINK: {
      const { username } = action.payload;
      return {
        ...state,
        loginLoading: true,
        loginError: undefined,
        loginLastCheckedUsername: username
      };
    }
    case SessionActionTypes.LOGIN_CHECK_SUCCESS: {
      return { ...state, loginLoading: false, loginError: undefined };
    }
    case SessionActionTypes.LOGIN_CHECK_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loginLoading: false,
        loginError: error?.code
      };
    }

    case SessionActionTypes.SET_LOGIN_FORM_STEP: {
      const { formStep } = action.payload;
      return {
        ...state,
        loginFormStep: formStep,
        loginError: undefined,
        loginLoading: false
      };
    }

    case SessionActionTypes.FORGOT_PASSWORD_SUCCESS:
    case SessionActionTypes.MAGIC_LINK_SUCCESS: {
      return {
        ...state,
        loginFormStep: 'initial',
        loginLoading: false,
        loginError: undefined
      };
    }
    case SessionActionTypes.FORGOT_PASSWORD_FAILURE:
    case SessionActionTypes.MAGIC_LINK_FAILURE: {
      return {
        ...state,
        loginLoading: false,
        loginError: ErrorCode.InvalidUsername
      };
    }

    default: {
      return state;
    }
  }
};
