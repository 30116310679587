import type { PrizesState } from './types';
import type { GameActionsUnionType } from '../types';
import { GameActionTypes } from '../actions';

const initialPrizesState: PrizesState = [];

const prizesReducer = (
  state: PrizesState = initialPrizesState,
  action: GameActionsUnionType
): PrizesState => {
  switch (action.type) {
    case GameActionTypes.FETCH_GAME_DATA_SUCCESS:
      return [...action.payload.prizes];

    default:
      return state;
  }
};

export { initialPrizesState, prizesReducer };
