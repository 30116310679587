import { put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';
import { SessionActionTypes, SessionActions } from '@maggie/store/session/actions';
import { redirectAfterSuccess } from '@maggie/store/session/session-login-sagas';
import type { LxStoreState } from '@maggie/store/types';

import { RegisterActions, RegisterActionsTypes } from './actions';
import { RegisterSelectors } from './selectors';
import type { RegisterAction, RegisterParams } from './types';

type RaceType = {
  success: RegisterAction<RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_SUCCESS>;
  failure: RegisterAction<RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_FAILURE>;
};

function* handleRegisterWithInviteCode(
  action: RegisterAction<RegisterActionsTypes.REGISTER_WITH_INVITE_CODE>
): any {
  const { inviteCode, fields } = action.payload;
  const decodedInviteCode = decodeURIComponent(inviteCode);
  const appId: string = yield select<LxStoreState>(RegisterSelectors.getApplicationId);

  yield put(
    RequestActions.postUnauthed(
      Urls.INVITE_REGISTER,
      RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_SUCCESS,
      RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_FAILURE,
      undefined,
      { appId, codeUsed: decodedInviteCode, fields } as RegisterParams
    )
  );

  const { success, failure }: RaceType = yield race({
    success: take(RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_SUCCESS),
    failure: take(RegisterActionsTypes.REGISTER_WITH_INVITE_CODE_FAILURE)
  });

  if (failure) {
    itly.registerViaInviteCodeFailure({
      code: inviteCode,
      error_code: failure.payload?.code || ''
    });
    return;
  }

  const { tokens, email } = success.payload;
  itly.registerViaInviteCode({ code: inviteCode, email });
  yield put(SessionActions.sessionLogin(tokens, { redirect: false }));
  const { sessionFailure } = yield race({
    sessionSuccess: take(SessionActionTypes.SESSION_LOGIN_SUCCESS),
    sessionFailure: take(SessionActionTypes.SESSION_LOGIN_FAILURE)
  });
  if (sessionFailure) {
    ErrorLogger.captureEvent('Registered user but failed session login', 'error', {
      tokenTypes: tokens.map(t => t.type), // Don't log security-critical information such as token values.
      email,
      inviteCode
    });
    itly.failedRegistration();
    return window.__router.navigate('login', { query: { error: 'credentials' } });
  }

  const courseId: string = yield select<LxStoreState>(s => s.navigation.selfEnrollCourseId);
  if (!!courseId) {
    yield put(RegisterActions.selfEnroll(courseId));
    yield take(RegisterActionsTypes.SELF_ENROLL_COMPLETED);
  }

  yield redirectAfterSuccess();
}

export function* watchRegisterWithInviteCode() {
  yield takeLatest(RegisterActionsTypes.REGISTER_WITH_INVITE_CODE, handleRegisterWithInviteCode);
}
