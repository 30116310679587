import { ErrorCode } from '@edapp/request';
import { t } from 'i18next';

/**
 * @deprecated
 *
 * !Note: if you're adding here, make sure you add to
 * `ErrorMessage` -> apps/maggie/src/components/common/error-message/ErrorMessage.tsx
 */
export const getErrorMessage = (errorCode?: ErrorCode): string => {
  switch (errorCode) {
    case ErrorCode.EmailDomainAlreadyInUse:
      return t('server-error.email-domain-already-in-use', { ns: 'learners-experience' });

    case ErrorCode.EmailIsNotPersonal:
      return t('server-error.email-is-not-personal', { ns: 'learners-experience' });

    case ErrorCode.InvalidCredentials:
      return t('link-account.error.credentials', { ns: 'learners-experience' });

    case ErrorCode.AccountLocked:
      return t('login.error-account-locked', { ns: 'learners-experience' });

    case ErrorCode.InvalidUsername:
      return t('login.invalid-email-or-username', { ns: 'learners-experience' });

    case ErrorCode.InvalidPassword:
      return t('server-error.invalid-password', { ns: 'learners-experience' });

    case ErrorCode.EmailVerificationPending:
      return t('login.verification-pending', { ns: 'learners-experience' });

    case ErrorCode.InvalidPasswordStrength:
      return t('server-error.invalid-password-stregth', { ns: 'learners-experience' });

    case ErrorCode.InvalidEmailAddress:
      return t('server-error.invalid-email-address', { ns: 'learners-experience' });

    case ErrorCode.UsernameNotUnique:
      return t('server-error.username-not-unique', { ns: 'learners-experience' });

    case ErrorCode.NullResponse:
      return t('network-error.null-response', { ns: 'learners-experience' });

    case ErrorCode.ForgotPasswordErrorCredentials:
      return t('forgot-password.error.credentials', { ns: 'learners-experience' });

    case ErrorCode.ForgotPasswordServerError:
      return t('forgot-password.error.server', { ns: 'learners-experience' });

    case ErrorCode.EmailAlreadyRegistered: {
      return t('register.error.already-registered-different-account', {
        ns: 'learners-experience'
      });
    }

    case ErrorCode.EmailExistsForSameAccount: {
      return t('register.error.already-registered-same-account', { ns: 'learners-experience' });
    }

    case ErrorCode.MagicLinkIsNotAllowed:
    case ErrorCode.ResetPasswordIsNotAllowed: {
      return t('login.sso-error', { ns: 'learners-experience' });
    }

    case ErrorCode.InvitedUserIsNotVerified: {
      return t('login.unverified-error', { ns: 'learners-experience' });
    }

    case ErrorCode.ProfileFieldIsNotValid: {
      return t('register.error.invalid-fields', { ns: 'learners-experience' });
    }

    case ErrorCode.BusinessIdNotFound: {
      return t('sso-login.error.wrong-bss-id', { ns: 'learners-experience' });
    }

    case ErrorCode.InvalidSsoToken: {
      return t('sso-login.error.server', { ns: 'learners-experience' });
    }

    default:
    case ErrorCode.NotSpecified:
      return t('login.server-error', { ns: 'learners-experience' });
  }
};
