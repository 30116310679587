import type { InitOptions } from 'i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { initDateFnsLocale } from './date-fns';

export const initI18N = async (options: InitOptions) => {
  await i18n
    .use(
      resourcesToBackend((language, namespace, callback) => {
        import(
          /* @vite-ignore */
          /* webpackChunkName: "i18n-[request]" */
          `@edapp/translations/locales/${language}/${namespace}.json`
        )
          .then(resources => {
            callback(null, resources);
          })
          .catch(error => {
            callback(error, null);
          });
      })
    )
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'en',
      supportedLngs: ['en'],
      nonExplicitSupportedLngs: true,
      returnNull: false,
      ...options
    });

  /**
   * Add a custom text formatter to capitalize i18n string variables
   * https://www.i18next.com/translation-function/formatting#adding-custom-format-function
   *
   * Example: {{entity}} will be “entityvalue” and {{entity, uppercase}} will be “Entityvalue”
   */
  i18n.services.formatter?.add('capitalize', value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  });

  await initDateFnsLocale(i18n.language);

  return i18n;
};

/**
 * This is to avoid double initialization of i18next when multiple
 * components are trying to initialize it at the same time.
 */
export const initI18NOnce = async (options: InitOptions) => {
  if (window.i18nReady || window.i18nLoading) {
    return i18n;
  }

  window.i18nLoading = true;
  await initI18N(options);

  window.i18nReady = true;
  window.i18nLoading = false;

  return i18n;
};

export const changeLanguage = async (lang: string | null) => {
  if (!lang) {
    return;
  }

  await initDateFnsLocale(i18n.language);
  return i18n.changeLanguage(lang);
};

export const generateTitle = (trans: string) => {
  const titles = i18n.t(trans, { returnObjects: true }) as Record<string, string>;
  const index = Math.round(Math.random() * (Object.keys(titles).length - 1));

  return titles[index];
};
