import type { SchemeType } from '../types';
import type { ThemeSetting } from './types';

export const getBrowserPreferredTheme = () => {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'sc-dark'
    : 'sc-light';
};

export const convertScThemeToEdScheme = (scTheme: ThemeSetting): SchemeType =>
  scTheme === 'system' ? getBrowserPreferredTheme() : `sc-${scTheme}`;
