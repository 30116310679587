import { select } from 'redux-saga/effects';

import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';
import { PlaylistsSelectors } from '@maggie/store/courseware/playlists/selectors';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidMainNavigateToPlaylist(playlistId: string) {
  const defaultSelectedCourse: CourseSummaryType | undefined = yield select<LxStoreState>(
    PlaylistsSelectors.getCourseForPlaylist(playlistId)
  );

  if (!!defaultSelectedCourse) {
    window.__router.navigate(
      'course',
      { id: defaultSelectedCourse.courseId, query: { playlistId } },
      true
    );
  }
}
