import { createAction } from '@edapp/utils';

enum BackboneActionTypes {
  APPLICATION_RESUME = 'APPLICATION_RESUME'
}

const BackboneActions = {
  applicationResume: () => createAction(BackboneActionTypes.APPLICATION_RESUME)
};

export { BackboneActions, BackboneActionTypes };
