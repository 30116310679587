import { call, fork, select, takeLatest } from 'redux-saga/effects';

import type { ActionFromActionType } from '@edapp/utils';
import { ThomasPlayerMemo } from '@maggie/core/lessons/thomas-player/lesson-player-memo';

import { ThomasActionTypes } from './actions';
import { ThomasSelectors } from './selectors';
import type { ThomasActionUnionType } from './types';

type ThomasAction<ActionType extends string> = ActionFromActionType<
  ThomasActionUnionType,
  ActionType
>;

function* handleThomasGoToSlideIndex(action: ThomasAction<ThomasActionTypes.GO_TO_SLIDE_INDEX>) {
  const { lessonId, index } = action.payload;
  const currentIndex: number = yield select(ThomasSelectors.getSlideIndex);
  if (index !== currentIndex) {
    if (ThomasPlayerMemo.hasPlayer(lessonId)) {
      const player = ThomasPlayerMemo.getPlayer(lessonId);
      player.goToPage(index);
    }
  }
}

function* handleCloseThomas(action: ThomasAction<ThomasActionTypes.CLOSE_THOMAS>) {
  if (ThomasPlayerMemo.hasPlayer(action.payload.lessonId)) {
    const player = ThomasPlayerMemo.getPlayer(action.payload.lessonId);
    yield call(player.close);
  }
}

function* handleToggleHeader(action: ThomasAction<ThomasActionTypes.TOGGLE_HEADER>) {
  if (ThomasPlayerMemo.hasPlayer(action.payload.lessonId)) {
    const player = ThomasPlayerMemo.getPlayer(action.payload.lessonId);
    player.toggleHeader(action.payload.mode);
  }
}

function* watchThomasGoToSlideIndex() {
  yield takeLatest(ThomasActionTypes.GO_TO_SLIDE_INDEX, handleThomasGoToSlideIndex);
}

function* watchCloseThomas() {
  yield takeLatest(ThomasActionTypes.CLOSE_THOMAS, handleCloseThomas);
}

function* watchToggleHeader() {
  yield takeLatest(ThomasActionTypes.TOGGLE_HEADER, handleToggleHeader);
}

export const thomasSagas = [
  fork(watchThomasGoToSlideIndex),
  fork(watchCloseThomas),
  fork(watchToggleHeader)
];
