import type { Reducer } from 'redux';
import type { InteractionsActionUnionType, InteractionsState } from './types';

import { InteractionActionTypes } from './actions';
import { initialInteractionsState } from './constants';

export const interactionsReducer: Reducer<InteractionsState> = (
  state = initialInteractionsState,
  action: InteractionsActionUnionType
) => {
  switch (action.type) {
    case InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE: {
      return {
        ...state,
        saving: true,
        error: false,
        retries: state.retries + 1
      };
    }

    case InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE_SUCCESS: {
      return {
        ...state,
        saving: false,
        error: false,
        success: true,
        retries: 0
      };
    }

    case InteractionActionTypes.SEND_ATTEMPT_AND_EMPTY_QUEUE_FAILURE: {
      return {
        ...state,
        saving: false,
        error: true
      };
    }

    default: {
      return state;
    }
  }
};
