import { put, select } from 'redux-saga/effects';

import {
  ContentLibraryActions,
  SECTIONS_PAGE_SIZE,
  SECTION_COURSES_PAGE_SIZE
} from '@edapp/content-library';
import { ContentLibrarySelectors } from '@maggie/store/content-library/selectors';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidNavigateToContentLibrary(): any {
  // Check if has already fetched sections
  // so it doesn't reset the store when coming back from other pages
  const hasSections: boolean = yield select<LxStoreState>(ContentLibrarySelectors.hasSections);

  if (!hasSections) {
    yield put(
      ContentLibraryActions.fetchSections(1, SECTIONS_PAGE_SIZE, SECTION_COURSES_PAGE_SIZE)
    );
  }
}
