import type { WinnersState } from './types';
import type { GameActionsUnionType } from '../types';
import { GameActionTypes } from '../actions';

const initialWinnersState: WinnersState = [];

const winnersReducer = (
  state: WinnersState = initialWinnersState,
  action: GameActionsUnionType
): WinnersState => {
  switch (action.type) {
    case GameActionTypes.FETCH_GAME_DATA_SUCCESS:
      return [...action.payload.winners];

    default:
      return state;
  }
};

export { initialWinnersState, winnersReducer };
