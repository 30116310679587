import { formatRelative } from 'date-fns';
import { t } from 'i18next';
import { put, select, takeLatest } from 'redux-saga/effects';

import type { ActionFromActionType, ActionsUnion } from '@edapp/utils';
import { LessonActivitySelectors } from '@maggie/store/courseware/lesson-activity/selectors';
import type { LessonSummaryWithProgress } from '@maggie/store/courseware/lesson-activity/types';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import { LessonUtils } from '@maggie/store/courseware/lessons/utils';
import { CoursewareUtils } from '@maggie/store/courseware/utils';
import type { LxStoreState } from '@maggie/store/types';

import { LockedDialogActionTypes, LockedDialogActions } from './actions';
import { checkLockedDates } from './utils';

const written = require('written');

type LockedDialogAction<ActionType extends string> = ActionFromActionType<
  ActionsUnion<typeof LockedDialogActions>,
  ActionType
>;

export function* handleOpenLessonLocked(
  action: LockedDialogAction<LockedDialogActionTypes.OPEN_LOCKED_LESSON>
): any {
  const dialogTitle = t('locked.lesson.title', { ns: 'learners-experience' });
  const {
    shouldOpenDialog,
    lockedLessonId,
    redirectParams: redirectionParams,
    lessonType
  } = action.payload;

  const lessonSummary: LessonSummaryWithProgress | undefined = yield select<LxStoreState>(
    LessonActivitySelectors.findLessonSummaryWithProgress(lockedLessonId, lessonType)
  );

  const state: LxStoreState = yield select(s => s);

  const lockedByDates = checkLockedDates(lessonSummary?.planning, 'lesson');
  if (!!lockedByDates) {
    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        lockedByDates.dialogTitle,
        lockedByDates.dialogText,
        redirectionParams
      )
    );
  }

  const lockedToday = LessonSelectors.isLockedToday(lessonSummary?.progress, state);
  if (!!lockedToday) {
    let dialogText = '';
    switch (lockedToday) {
      case 'weekend':
        dialogText = t('locked.weekend', { ns: 'learners-experience' });
        break;
      case 'limit':
        dialogText = t('locked.limit', { ns: 'learners-experience' });
        break;
      default:
        // It will never reach here...
        dialogText = t('locked.default', { ns: 'learners-experience' });
        break;
    }

    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        dialogTitle,
        dialogText,
        redirectionParams
      )
    );
  }

  const prerequisites = LessonSelectors.getLessonPrerequisites(lessonSummary)(state);
  if (prerequisites.length > 0) {
    const writtenOpts = {
      more: `more lesson`,
      wrap: 'strong',
      quantify: true,
      written: true
    };

    const dialogText = t('locked.lesson.prerequisites', {
      prerequisites: written.prettyList(prerequisites, 3, writtenOpts),
      interpolation: { escapeValue: false }, // allows html from prettyList
      ns: 'learners-experience'
    });

    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        dialogTitle,
        dialogText,
        redirectionParams
      )
    );
  }

  const isAvailableForPlatform = CoursewareUtils.isAvailableForPlatform(lessonSummary);
  if (!isAvailableForPlatform) {
    const dialogText = t('locked.lesson.platform', {
      platforms: lessonSummary?.platformsAvailable || [],
      ns: 'learners-experience'
    });

    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        dialogTitle,
        dialogText,
        redirectionParams
      )
    );
  }

  const lockedFromCompletion = LessonSelectors.isLockedFromCompletion(
    lessonSummary,
    lessonSummary?.progress
  );
  if (lockedFromCompletion) {
    const dialogText = t('locked.attempt.completion', { ns: 'learners-experience' });

    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        dialogTitle,
        dialogText,
        redirectionParams
      )
    );
  }

  const lockedFromLastAttempt = LessonSelectors.isLockedFromLastAttempt(
    lessonSummary,
    lessonSummary?.progress
  );
  const unlockDetails = LessonUtils.getUnlockDetails(lessonSummary, lessonSummary?.progress);
  if (!!lockedFromLastAttempt && !!unlockDetails && !!unlockDetails.unlockDate) {
    const label0 = t('locked.attempt.duration', {
      count: unlockDetails.duration,
      ns: 'learners-experience'
    });
    const label1 = t('locked.attempt.date', {
      unlocked: formatRelative(unlockDetails.unlockDate, new Date()),
      ns: 'learners-experience'
    });
    const dialogText = `${label0} ${label1}`;

    return yield put(
      LockedDialogActions.openLockedDialog(
        shouldOpenDialog,
        dialogTitle,
        dialogText,
        redirectionParams
      )
    );
  }

  const dialogText = t('locked.default', { ns: 'learners-experience' });
  return yield put(
    LockedDialogActions.openLockedDialog(
      shouldOpenDialog,
      dialogTitle,
      dialogText,
      redirectionParams
    )
  );
}

export function* watchOpenLockedLesson() {
  yield takeLatest(LockedDialogActionTypes.OPEN_LOCKED_LESSON, handleOpenLessonLocked);
}
