import { createSelector } from 'reselect';

import type { LxStoreState } from '@maggie/store/types';

const getConferencesById = (state: LxStoreState) => state.courseware.conferences.byId;

const getIsConferenceLoading = (state: LxStoreState) => state.courseware.conferences.isLoading;

const getConferenceLoadingError = (state: LxStoreState) =>
  state.courseware.conferences.loadingError;

const getConferenceLoadingErrorCode = (state: LxStoreState) =>
  state.courseware.conferences.loadingErrorCode;

const makeGetConference = (id: string) =>
  createSelector(
    getConferencesById,
    () => id,
    (conferencesById, id) => conferencesById[id]
  );

const getTitle = (state: LxStoreState) => {
  const conferenceId = state.navigation.conferenceId;
  const conference = getConferencesById(state)[conferenceId];
  return conference?.title || '';
};

export const ConferenceSelectors = {
  getConferencesById,
  getIsConferenceLoading,
  getConferenceLoadingError,
  getConferenceLoadingErrorCode,
  makeGetConference,
  getTitle
};
