import type { ArgumentTypes, TypeOfClassMethod } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { NewAppRouter } from '@maggie/core/router/router';
import type { RouteName, RouteParams } from '@maggie/core/router/types';

export enum SplitViewLayoutActionTypes {
  SET_DETAIL = 'SET_DETAIL',
  CLEAR_DETAIL = 'CLEAR_DETAIL',

  MAIN_NAVIGATE = 'MAIN_NAVIGATE'
}

export const SplitViewLayoutActions = {
  setDetail: (routeName: RouteName, params: RouteParams<RouteName>) =>
    createAction(SplitViewLayoutActionTypes.SET_DETAIL, { routeName, params }),

  clearDetail: () => createAction(SplitViewLayoutActionTypes.CLEAR_DETAIL, {}),

  mainNavigate: (...args: ArgumentTypes<TypeOfClassMethod<NewAppRouter, 'navigate'>>) =>
    createAction(SplitViewLayoutActionTypes.MAIN_NAVIGATE, { route: args[0], params: args[1] })
};
