import type { RequestTypes } from '@edapp/request';
import type { ActionsUnion, ActionWithPayload } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { ObservationType, ObservationFilters, ObservationOptions } from './types';

// To be updated when enpoints finalise
export enum ObservationActionTypes {
  FETCH_OBSERVATION = 'FETCH_OBSERVATION',
  FETCH_OBSERVATION_SUCCESS = 'FETCH_OBSERVATION_SUCCESS',
  FETCH_OBSERVATION_FAILURE = 'FETCH_OBSERVATION_FAILURE',

  FETCH_ALL_OBSERVATIONS = 'FETCH_ALL_OBSERVATIONS',
  FETCH_ALL_OBSERVATIONS_SUCCESS = 'FETCH_ALL_OBSERVATIONS_SUCCESS',
  FETCH_ALL_OBSERVATIONS_FAILURE = 'FETCH_ALL_OBSERVATIONS_FAILURE',

  SET_SELECTED_OBSERVATION = 'SET_SELECTED_OBSERVATION'
}

export const ObservationActions = {
  fetchObservation: (observationId: string) =>
    createAction(ObservationActionTypes.FETCH_OBSERVATION, { observationId }),
  fetchObservationSuccess: (observation: ObservationType) =>
    createAction(ObservationActionTypes.FETCH_OBSERVATION_SUCCESS, {
      observation
    }),

  fetchAllObservationSubmissions: (
    options: ObservationOptions,
    observationFilters: ObservationFilters
  ) =>
    createAction(ObservationActionTypes.FETCH_ALL_OBSERVATIONS, {
      options,
      observationFilters
    }),
  fetchAllObservationsSuccess: (
    page: number,
    observationsData: RequestTypes.PaginatedResponse<ObservationType>
  ) =>
    createAction(ObservationActionTypes.FETCH_ALL_OBSERVATIONS_SUCCESS, {
      page,
      observationsData
    }),

  setSelectedObservation: (observation: ObservationType) =>
    createAction(ObservationActionTypes.SET_SELECTED_OBSERVATION, {
      observation
    })
};

export type ObservationActionsUnionType =
  | ActionsUnion<typeof ObservationActions>
  | ActionWithPayload<ObservationActionTypes.FETCH_OBSERVATION_FAILURE, EdErrorResponseType>
  | ActionWithPayload<ObservationActionTypes.FETCH_ALL_OBSERVATIONS_FAILURE, EdErrorResponseType>;
