/**
 * Analytics 2.0(segment-next) plugin for Itly
 *
 * Instead of loading the actual segment, it will use the existing one created by the shell application
 *
 * https://github.com/amplitude/itly-sdk/blob/master/packages/plugin-segment/lib/index.ts
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 */
import type { SegmentPageOptions, SegmentTrackOptions } from '@itly/plugin-segment';
import { RequestLoggerPlugin } from '@itly/sdk';
import type { Event, PluginLoadOptions, Properties, ResponseLogger } from '@itly/sdk';

import getGlobalSingleton from './get-global-singleton';

const FORMAT_NAME_REG = /[\s-]+/g;
const formatTrackingName = (name: string) =>
  `safetyculture.${name.replace(FORMAT_NAME_REG, '_').toLowerCase()}`;

export class SegmentPlugin extends RequestLoggerPlugin {
  constructor() {
    super('segment');
  }

  private invoke(method: string, ...args: any[]) {
    const analytics = getGlobalSingleton('analytics');
    // Ignore the segment call when it's not initialized
    if (!analytics) {
      return;
    }

    const segment = analytics.getSegment();
    if (segment) {
      segment[method as keyof typeof segment](...args);
    }
  }

  private wrapCallback(responseLogger: ResponseLogger, callback?: (...args: any[]) => void) {
    return (...args: any[]) => {
      responseLogger.success('done' + args);
      if (callback) {
        callback(...args);
      }
    };
  }

  public load(options: PluginLoadOptions) {
    super.load(options);
  }

  public loadSegment() {
    // Not quite sure if this function is a real public method,
    // keep it for now to have a consistent interface with the original Segment plugin
  }

  public identify() {
    // Do nothing as segment.identify is already called inside the shell
  }

  public track(
    userId: string | undefined,
    { name, properties }: Event,
    options?: SegmentTrackOptions
  ) {
    const { segmentOptions, callback } = options || {};
    const responseLogger = this.logger.logRequest(
      'track',
      userId + ', ' + name + ', ' + JSON.stringify(properties)
    );
    const customProperties = {
      ...properties,
      product_feature: 'training'
    };

    this.invoke(
      'track',
      formatTrackingName(name),
      customProperties,
      segmentOptions,
      this.wrapCallback(responseLogger, callback)
    );
  }

  public page(
    userId?: string,
    category?: string,
    name?: string,
    properties?: Properties,
    options?: SegmentPageOptions
  ) {
    const { segmentOptions, callback } = options || {};
    const responseLogger = this.logger.logRequest(
      'page',
      userId + ', ' + category + ', ' + name + ', ' + JSON.stringify(properties)
    );
    this.invoke(
      'page',
      category,
      name,
      properties,
      segmentOptions,
      this.wrapCallback(responseLogger, callback)
    );
  }

  public alias() {
    // To implement when it's necessary
  }

  public group() {
    // To implement when it's necessary
  }

  public reset() {
    // To implement when it's necessary
  }
}
