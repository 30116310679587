/**
 * Creates an expression string that pins data into the 'window.__' object
 * @param {Object} data An object containing values to be inserted into the 'window.__' object
 * @param {string} prefix Don't use this property unless you know what you are doing
 * @returns {string} Javascript code as a string
 */
export const dataToGlobal = (data: { [k: string]: any }, prefix = '__') => {
  return [`window.${prefix} = {};`]
    .concat(
      Object.keys(data).map((k: string) => {
        const v = data[k];
        return `window.${prefix}.${k} = ${safeStringify(v)};`;
      })
    )
    .join('\n');
};

export const slidedeckPrefix = (id: string) => `slidedeck:${id}`;

export const safeStringify = (s: string) => {
  return (
    JSON.stringify(s)
      // Remove illegal tokens
      .replace(/[\u200B-\u200C\uFEFF\u200E\u200F\u2028\u2029]/g, '')
  );
};
