import type { LxStoreState } from '../types';

const getDownloading = (state: LxStoreState) => state.offline.downloading;

const getLessons = (state: LxStoreState) => state.offline.courseware.lessons.lessons;

const getCourses = (state: LxStoreState) => state.offline.courseware.courses.courses;

const getCollections = (state: LxStoreState) => state.offline.courseware.collections;

const getPlaylists = (state: LxStoreState) => state.offline.courseware.playlists;

const getStatus = (lessonId: string) => (state: LxStoreState) => state.offline.status[lessonId];

export const OfflineSelectors = {
  getDownloading,
  getLessons,
  getCourses,
  getCollections,
  getPlaylists,
  getStatus
};
