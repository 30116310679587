import { createAction } from '@edapp/utils';

export enum ToastActionTypes {
  SHOW_TOAST = 'SHOW_TOAST',
  HIDE_TOAST = 'HIDE_TOAST'
}

export const ToastActions = {
  showToast: (isOn: boolean, text: string) =>
    createAction(ToastActionTypes.SHOW_TOAST, { isOn, text }),

  hideToast: () => createAction(ToastActionTypes.HIDE_TOAST)
};
