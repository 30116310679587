import type { ErrorResponseType, RelativeToken } from '@edapp/request';
import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { AccountType } from '@maggie/store/user/types';

export enum AccountSettingsActionTypes {
  LINK_ACCOUNT = 'LINK_ACCOUNT',
  LINK_ACCOUNT_SUCCESS = 'LINK_ACCOUNT_SUCCESS',
  LINK_ACCOUNT_FAILURE = 'LINK_ACCOUNT_FAILURE',
  CLEAR_LINK_ACCOUNT_ERROR = 'CLEAR_LINK_ACCOUNT_ERROR',

  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',
  SWITCH_ACCOUNT_SUCCESS = 'SWITCH_ACCOUNT_SUCCESS',
  SWITCH_ACCOUNT_FAILURE = 'SWITCH_ACCOUNT_FAILURE'
}

export const AccountSettingsActions = {
  linkAccount: (login: string, password: string) =>
    createAction(AccountSettingsActionTypes.LINK_ACCOUNT, { login, password }),

  clearLinkAccountError: () => createAction(AccountSettingsActionTypes.CLEAR_LINK_ACCOUNT_ERROR),

  switchAccount: (userId: string) =>
    createAction(AccountSettingsActionTypes.SWITCH_ACCOUNT, { userId })
};

export type AccountSettingsActionsUnionType =
  | ActionsUnion<typeof AccountSettingsActions>
  | ActionWithPayload<AccountSettingsActionTypes.LINK_ACCOUNT_SUCCESS, AccountType[]>
  | ActionWithPayload<AccountSettingsActionTypes.LINK_ACCOUNT_FAILURE, ErrorResponseType>
  | ActionWithPayload<
      AccountSettingsActionTypes.SWITCH_ACCOUNT_SUCCESS,
      { tokens: RelativeToken[] }
    >
  | ActionWithPayload<AccountSettingsActionTypes.SWITCH_ACCOUNT_FAILURE, ErrorResponseType>;
