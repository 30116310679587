import * as _ from 'lodash';
import type { Reducer } from 'redux';

import type { ActionsUnion } from '@edapp/utils';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import type { LessonActionsUnionType } from '@maggie/store/courseware/lessons/types';

import type { LearnerDemographicActions } from './actions';
import { LearnerDemographicActionTypes } from './actions';
import { initialLearnerDemographicState } from './constants';
import type { LearnerDemographicState } from './types';

export type LearnerDemographicActionUnion = ActionsUnion<typeof LearnerDemographicActions>;

export const learnerDemographicReducer: Reducer<LearnerDemographicState> = (
  state = initialLearnerDemographicState,
  action: LearnerDemographicActionUnion | LessonActionsUnionType
) => {
  switch (action.type) {
    case LearnerDemographicActionTypes.OPEN: {
      return { ...state, isOpen: true };
    }
    case LearnerDemographicActionTypes.CLOSE: {
      return { ...state, isOpen: false };
    }

    case LessonActionTypes.CLOSE_LESSON: {
      return {
        ...state,
        numberOfLessonsClosed: state.numberOfLessonsClosed + 1
      };
    }

    case LearnerDemographicActionTypes.FETCH_UNANSWERED_QUESTIONS_SUCCESS: {
      const questions = action.payload;
      const sortedQuestions = _.sortBy(questions, 'rank');
      return {
        ...state,
        questions: [...sortedQuestions]
      };
    }

    case LearnerDemographicActionTypes.OPT_OUT_ANSWER_SUCCESS:
    case LearnerDemographicActionTypes.OPT_OUT_ANSWER_FAILURE:
    case LearnerDemographicActionTypes.SAVE_ANSWER_SUCCESS:
    case LearnerDemographicActionTypes.SAVE_ANSWER_FAILURE: {
      const { questionId } = action.payload;

      const newQuestions = state.questions.filter(q => q.id !== questionId);
      return {
        ...state,
        questions: [...newQuestions]
      };
    }

    default:
      return state;
  }
};
