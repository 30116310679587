import { appRoutes } from '@maggie/app/constants/app-routes';
import type { RouteName } from '@maggie/core/router/types';

const hasChild = (routeName: RouteName): boolean => {
  return !!Object.values(appRoutes).find(appRoute =>
    (appRoute.splitViewLayout?.parents || []).includes(routeName)
  );
};

export const SplitViewLayoutUtils = {
  hasChild
};
