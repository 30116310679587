import { put, race, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';

import type { AccountSettingsActionsUnionType } from '../actions';
import { AccountSettingsActionTypes } from '../actions';

type Action<ActionType extends string> = ActionFromActionType<
  AccountSettingsActionsUnionType,
  ActionType
>;

type LinkAccountRaceType = {
  success: Action<AccountSettingsActionTypes.LINK_ACCOUNT_SUCCESS>;
  failure: Action<AccountSettingsActionTypes.LINK_ACCOUNT_FAILURE>;
};

export function* handleLinkAccount(action: Action<AccountSettingsActionTypes.LINK_ACCOUNT>): any {
  const { login, password } = action.payload;

  yield put(
    RequestActions.postAuthed(
      Urls.LINK_ACCOUNT,
      AccountSettingsActionTypes.LINK_ACCOUNT_SUCCESS,
      AccountSettingsActionTypes.LINK_ACCOUNT_FAILURE,
      undefined,
      { login, password }
    )
  );

  const { success }: LinkAccountRaceType = yield race({
    success: take(AccountSettingsActionTypes.LINK_ACCOUNT_SUCCESS),
    failure: take(AccountSettingsActionTypes.LINK_ACCOUNT_FAILURE)
  });

  if (success) {
    // Navigate to #account-settings from #link-accounts
    window.__router.navigate('accountSettings', {});
  }
}

export function* watchLinkAccountSagas() {
  yield takeLatest(AccountSettingsActionTypes.LINK_ACCOUNT, handleLinkAccount);
}
