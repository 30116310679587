import type { SupportedLanguage } from '@edapp/hippo-client';
import type { ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';

import './constants';

export enum CourseLanguageActionTypes {
  SET_COURSE_LANGUAGE = 'SET_COURSE_LANGUAGE'
}

export const CourseLanguageActions = {
  setCourseLanguage: (courseId: string, languageCode: SupportedLanguage['code']) =>
    createAction(CourseLanguageActionTypes.SET_COURSE_LANGUAGE, {
      courseId,
      languageCode
    })
};

export type CourseLanguageActionsUnionType = ActionsUnion<typeof CourseLanguageActions>;
