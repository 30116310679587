import { put, takeLatest } from 'redux-saga/effects';

import { getIsEmail } from '@maggie/utils/isEmail';

import type { SessionAction } from '../actions';
import { SessionActionTypes, SessionActions } from '../actions';

function* handleForgotPassword(action: SessionAction<SessionActionTypes.FORGOT_PASSWORD>) {
  const { username } = action.payload;

  if (!getIsEmail(username)) {
    // UI should prevent displaying forgot password - but in any case
    yield put(SessionActions.forgotPasswordFailure());
    return;
  }

  window.__router.navigate('forgotPassword', { query: { email: username } });
  yield put(SessionActions.forgotPasswordSuccess());
}

export function* watchForgotPassword() {
  yield takeLatest(SessionActionTypes.FORGOT_PASSWORD, handleForgotPassword);
}
