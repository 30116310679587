const BASE_SPACE = 8;

type Multiplier = number;

export function space(multiplier: Multiplier = 1) {
  return BASE_SPACE * multiplier;
}

export const THEME_SPACE = {
  xs: space(),
  sm: space(2),
  md: space(3),
  lg: space(4),
  xl: space(7.5)
};

export type ThemeSpace = keyof typeof THEME_SPACE | Multiplier;

export function getSpaceValue(themeSpace?: ThemeSpace): number {
  switch (typeof themeSpace) {
    case 'number':
      return space(themeSpace);
    case 'string': {
      if (THEME_SPACE[themeSpace]) {
        return THEME_SPACE[themeSpace];
      }
    }
    default:
      return 0;
  }
}
