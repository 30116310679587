import type { SocialLearningState, SocialLearningActionsUnionType } from './types';
import { SocialLearningActionTypes } from './actions';

export const initialSocialLearningState: SocialLearningState = {
  lessons: {},
  commentsByLesson: {},
  isLoadingSlideComments: {}
};

export const socialLearningReducer = (
  state: SocialLearningState = initialSocialLearningState,
  action: SocialLearningActionsUnionType
): SocialLearningState => {
  switch (action.type) {
    case SocialLearningActionTypes.FETCH_SOCIAL_LEARNING_SUMMARY_SUCCESS: {
      const { lessonId, ...data } = action.payload;
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.lessonId]: data
        }
      };
    }

    case SocialLearningActionTypes.FETCH_ALL_COMMENTS_FOR_A_LESSON_SUCCESS: {
      const { lessonId, slides } = action.payload;
      return {
        ...state,
        commentsByLesson: {
          ...state.commentsByLesson,
          [lessonId]: slides
        }
      };
    }

    case SocialLearningActionTypes.LIKE_SOCIAL_LEARNING_COMMENT: {
      const { commentId, lessonId, slideId } = action.payload;

      const newComentsByLesson = (state.commentsByLesson[lessonId] || []).map(slideComment => {
        if (slideComment.id !== slideId) {
          return slideComment; // not the one
        }

        return {
          ...slideComment,
          comments: slideComment.comments.map(c => {
            if (c.id !== commentId) {
              return c; // not the one
            }

            return { ...c, isLikedByLearner: true, likes: c.likes + 1 };
          })
        };
      });

      return {
        ...state,
        commentsByLesson: {
          ...state.commentsByLesson,
          [lessonId]: [...newComentsByLesson]
        }
      };
    }

    case SocialLearningActionTypes.DISLIKE_SOCIAL_LEARNING_COMMENT: {
      const { commentId, lessonId, slideId } = action.payload;

      const newComentsByLesson = (state.commentsByLesson[lessonId] || []).map(slideComment => {
        if (slideComment.id !== slideId) {
          return slideComment; // not the one
        }

        return {
          ...slideComment,
          comments: slideComment.comments.map(c => {
            if (c.id !== commentId) {
              return c; // not the one
            }

            return { ...c, isLikedByLearner: false, likes: c.likes - 1 };
          })
        };
      });

      return {
        ...state,
        commentsByLesson: {
          ...state.commentsByLesson,
          [lessonId]: [...newComentsByLesson]
        }
      };
    }

    case SocialLearningActionTypes.FETCH_COMMENTS_FOR_SLIDE: {
      const { slideId } = action.payload;
      return {
        ...state,
        isLoadingSlideComments: {
          ...state.isLoadingSlideComments,
          [slideId]: true
        }
      };
    }

    case SocialLearningActionTypes.FETCH_COMMENTS_FOR_SLIDE_SUCCESS: {
      const { lessonId, slideId, slideComments, page, totalCount } = action.payload;

      const newComentsByLesson = (state.commentsByLesson[lessonId] || []).map(slideComment => {
        if (slideComment.id !== slideId) {
          return slideComment; // not the one
        }

        return {
          ...slideComment,
          totalCount,
          currentPage: page,
          comments: page === 1 ? [...slideComments] : [...slideComment.comments, ...slideComments]
        };
      });

      return {
        ...state,
        isLoadingSlideComments: {
          ...state.isLoadingSlideComments,
          [slideId]: false
        },
        commentsByLesson: {
          ...state.commentsByLesson,
          [lessonId]: [...newComentsByLesson]
        }
      };
    }

    default:
      return state;
  }
};
