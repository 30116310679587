import { Box } from '../box';
import styled, { css } from 'styled-components';
import { rgba } from '@edapp/themes';
import type { ContainerProps } from './types';

export const Container = styled(Box)<ContainerProps>(
  ({ theme, backgroundColor, opacity = 0.1, onClick }) => css`
    // Custom padding and border for tiny label
    padding: ${theme.space(0.75)}px;
    padding-top: ${theme.space(0.25)}px;
    padding-bottom: ${theme.space(0.25)}px;
    border-radius: ${theme.sizes.borderRadius}px;
    box-sizing: border-box;
    height: 24px;
    display: inline-block;
    background-color: ${rgba(theme.colors[backgroundColor], opacity)};
    white-space: nowrap;

    ${!!onClick &&
    css`
      cursor: pointer;
    `}
  `
);
