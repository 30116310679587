import { captureException, captureMessage } from '@sentry/react';
import type { SeverityLevel } from '@sentry/react';

import type { IAdapter, SeverityType } from '../types';

const severityLevels: SeverityLevel[] = ['fatal', 'error', 'warning', 'log', 'info', 'debug'];

type SentryAdapterOptions = {
  app: string;
};

/**
 * A Sentry adapter that is supposed to be used inside MFE
 */
class SentryAdapter implements IAdapter {
  // The app name displayed in Sentry
  private app: string;

  constructor({ app }: SentryAdapterOptions) {
    this.app = app;
  }

  setUserContext() {
    // It's not required in MFE application
    // as the shell app will identify the user
  }

  clearUserContext() {
    // Do nothing as Sentry do not support it
  }

  captureException<T extends Error, P extends {}>(error: T, extra?: P) {
    captureException(error, { extra: { ...extra, app: this.app } });
  }

  captureEvent<T extends {}>(message: string, level:SeverityType, extra: T) {
    // Convert the level to something Sentry supports
    const severityLevel = severityLevels.find((item) => item === level as SeverityLevel);
    captureMessage(message, { extra: { ...extra, app: this.app }, level: severityLevel });
  }
}

export default SentryAdapter;
