import type { LxStoreState } from '@maggie/store/types';

const getSlideIndex = (state: LxStoreState) => state.thomas.slideIndex;

const getLastCompletedSlideIndex = (state: LxStoreState) => state.thomas.lastCompletedSlideIndex;

const getIsMenuOpen = (state: LxStoreState) => state.thomas.isMenuOpen;

const getIsOpen = (state: LxStoreState) => state.thomas.isOpen;

const getIsNavigationEnabled = (state: LxStoreState) => state.thomas.isNavigationEnabled;

export const ThomasSelectors = {
  getSlideIndex,
  getLastCompletedSlideIndex,
  getIsMenuOpen,
  getIsOpen,
  getIsNavigationEnabled
};
