import { put } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { CourseActions } from '@maggie/store/courseware/courses/actions';
import { GroupTrainingActions } from '@maggie/store/courseware/group-training/actions';

export function* handleDidNavigateGroupTraining(courseId: string, sessionId: string) {
  yield put(CourseActions.fetchSyncCourse(courseId));
  yield put(GroupTrainingActions.registerGroupTrainingSession(sessionId));
  itly.groupTrainingLearnerSelfRegister({ course_id: courseId, session_id: sessionId });
}
