/**
 * The default lesson notification time, used only as a fallback.
 *
 * Note: If `IS_FAST_FORWARD_LOCAL_NOTIFICATIONS` is true, this variable is ignored.
 */
export const DEFAULT_NOTIFICATION_TIME = '9:00';

/**
 * The lesson notification schedule
 *
 * Note: The time unit may vary depending on `IS_FAST_FORWARD_LOCAL_NOTIFICATIONS`.
 */
export const LESSON_NOTIFICATION_SCHEDULE = [1, 3, 7, 13];

/**
 * The brain boost notification schedule
 *
 * Note: The time unit may vary depending on `IS_FAST_FORWARD_LOCAL_NOTIFICATIONS`.
 */
export const BRAIN_BOOST_NOTIFICATION_SCHEDULE = [1, 2, 4, 6, 10, 12];

export const DEBUG_LOCAL_NOTIFICATIONS = false;
