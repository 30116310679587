import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { l10n } from '@maggie/core/l10n';
import { Tracker } from '@maggie/core/tracker/tracker';
import { FeatureFlagsActionTypes, FeatureFlagsActions } from '@maggie/store/feature-flags/actions';
import { Redirection } from '@maggie/store/navigation/redirection';
import { SCUserId } from '@maggie/store/navigation/sc-user-id';
import type { LxStoreState } from '@maggie/store/types';
import { UserActionTypes, UserActions } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';
import type { UserAction } from '@maggie/store/user/types';
import { UserUtils } from '@maggie/store/user/utils';

import type { SessionAction } from './actions';
import { SessionActionTypes, SessionActions } from './actions';

type UserSyncResponse = UserAction<UserActionTypes.FETCH_SYNC_USER_SUCCESS>;

/**
 * We're gonna retry sync for five times. If no success - we will stop trying...
 */
function* fetchUserSyncAndRetry() {
  let userSyncResponse = undefined;
  let userSyncTries = 0;

  while (!userSyncResponse && userSyncTries < 5) {
    userSyncTries++;
    yield put(UserActions.syncUser());

    const { success, failure }: { success: UserSyncResponse; failure: {} } = yield race({
      success: take(UserActionTypes.FETCH_SYNC_USER_SUCCESS),
      failure: take(UserActionTypes.FETCH_SYNC_USER_FAILURE)
    });

    if (failure) {
      continue;
    }

    userSyncResponse = success;
  }

  return userSyncResponse;
}

export function* redirectAfterSuccess() {
  const redirect = Redirection.getRedirect();
  if (!redirect) {
    window.__router.navigate('home', {}, true);
  } else {
    window.__router.trigger(redirect);
    Redirection.removeRedirect();
  }
}

function* handleSessionLogin(action: SessionAction<SessionActionTypes.SESSION_LOGIN>): any {
  const { tokens, options } = action.payload;

  if (!window.__disableEdAppTokens) {
    // If tokens are disabled (FOR SC Training) we can assume here the all the things below will work.
    // No need to capture anything
    if (tokens.every(t => t.type !== 'CampusToken')) {
      ErrorLogger.captureEvent(
        'Token array missing campus token but this app version still needs one',
        'critical',
        { tokenTypes: tokens.map(t => t.type) } // Don't log security-critical information such as token values.
      );
      yield put(SessionActions.sessionLoginFailure());
      return;
    }
  }

  const userId = yield select(UserSelectors.getId);

  const response: UserSyncResponse | undefined = yield fetchUserSyncAndRetry();

  if (!response) {
    // error? redirect? to where? failed to authenticate with the token
    ErrorLogger.captureEvent('Session Login Failure: 5 tries', 'critical', { options });
    yield put(SessionActions.sessionLoginFailure());
    return;
  }

  const user = response.payload;
  if (userId !== user.id) {
    // different user - refetch feature flags
    yield put(FeatureFlagsActions.fetchFeatureFlags());
    yield race({
      success: take(FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_SUCCESS),
      failure: take(FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_FAILURE)
    });
  }

  const deviceToken: string = yield select<LxStoreState>(s => s.config.devicePushNotificationToken);

  // re-initialize i18n in case user had a different language saved in BE
  if (!options.skipLocaleCheck && user.locale) {
    yield call(l10n.change, user.locale);
  }

  // start session context
  ErrorLogger.setUserContext({
    applicationId: user.applicationId,
    name: user.name,
    email: user.email,
    id: user.id,
    provisioningType: user.provisioningType,
    scUserId: SCUserId.get()
  });
  UserUtils.setCookies(user.id);
  const scUserId = SCUserId.get();
  Tracker.trackVisit({ type: 'visit' }, user, deviceToken, window.__l10n.language, scUserId);
  Tracker.trackEvent({
    type: 'event',
    name: 'successful-login'
  });
  itly.successfulLogin();

  // redirect
  if (!!options.redirect) {
    yield redirectAfterSuccess();
  }

  yield put(SessionActions.sessionLoginSuccess());
}

export function* watchSessionLogin() {
  yield takeLatest(SessionActionTypes.SESSION_LOGIN, handleSessionLogin);
}
