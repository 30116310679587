import * as Cookies from 'js-cookie';
import type { Action } from 'redux';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import * as Backbone from 'backbone';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { RequestActions } from '@edapp/request';
import type { ActionWithPayload } from '@edapp/utils';
import { ENV } from '@maggie/config/env';
import { Link } from '@maggie/cordova/link';
import { Notifier } from '@maggie/cordova/notifier/notifier';
import { Platform } from '@maggie/cordova/platform';
import { i18n } from '@maggie/core/i18n';
import { l10n } from '@maggie/core/l10n';
import { InteractionApi } from '@maggie/core/tracker/interaction-api';
import { Tracker } from '@maggie/core/tracker/tracker';
import { clearScrollPositions } from '@maggie/hooks//usePersistedScroll';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { Urls } from '@maggie/store/constants';
import { LogoutActions } from '@maggie/store/logout/actions';
import { ProfileActions } from '@maggie/store/profile/actions';
import { LOGGED_OUT_ELSEWHERE } from '@maggie/store/session/local-storage-listener';

import { SlideProgressActions } from '../slide-progress/actions';
import { UserActionTypes, UserActions } from './actions';
import { UserSelectors } from './selectors';
import type { ProvisioningType, UserAction } from './types';

type SsoLogoutRaceType = {
  success?: ActionWithPayload<UserActionTypes.USER_LOGOUT_SSO_SUCCESS, { value: string }>;
  failure?: Action<UserActionTypes.USER_LOGOUT_SSO_FAILURE>;
};

const redirectToLogin = () => window.__router.navigate('login', {});

function* handleUserLogout(action: UserAction<UserActionTypes.USER_LOGOUT>) {
  console.warn('USER_LOGOUT', action.payload);

  // Send 'logout' interaction event
  const deviceToken: string = yield select(ConfigSelectors.getDevicePushNotification);
  const interactionId = Tracker.trackEvent({
    name: 'logout',
    type: 'event',
    value: deviceToken || ''
  });

  // Don't show logout dialog for UXP users
  const provisioningType: ProvisioningType | null = yield select(UserSelectors.getProvisioningType);
  if (action.payload.waitSyncInteractions && provisioningType !== 'external') {
    try {
      // wait for interactions to finish being sent to hippo
      yield call(InteractionApi.waitForQueueEmpty, { timeout: 10000, minimum: 1000 });
    } catch (err) {
      ErrorLogger.captureEvent('Failed to wait for interactions at logout', 'error', { err });
      yield put(LogoutActions.openLogoutDialog());
      // If failed, remove interaction element from queue
      yield call(InteractionApi.removeInteraction, interactionId);
      return;
    }
  }

  // broadcast backbone 'logout' event - not used?
  Backbone.Events.trigger('logout');

  // remove user cook - not used?
  Cookies.remove('user');

  // send tracking event
  itly.logout();
  // reset itly instance
  itly.reset();

  // clear Error Monitoring user sesison
  ErrorLogger.clearUserContext();

  // Cleans persisted scroll positions
  clearScrollPositions();

  // reset language to OS/browser one when logout
  const chosenLanguage: string | null = yield select(UserSelectors.getLocale);
  const deviceLanguage = navigator.language;
  if (!i18n.isSameLocale(chosenLanguage || '', deviceLanguage)) {
    yield put(ProfileActions.setLanguage(null));
    yield call(l10n.change, deviceLanguage);
  }

  // Clean up persisted local storage from redux datastore
  yield call(window.__persistedStore?.purge);

  /**
   * I believe this is for cordova android.
   * To clear history preventing user to navigate back.
   */
  (window.navigator as any)?.app?.clearHistory?.();

  /**
   * This sets a property in the local storage for other
   * opened tabs to know there has been a logout.
   *
   * It avoids tabs being opened for different users.
   */
  if (Platform.isWeb()) {
    window.localStorage.setItem(LOGGED_OUT_ELSEWHERE, Date.now().toString());
  }

  if (Platform.isCordova()) {
    // Cleans up notifications scheduled in the device
    Notifier.cancelAll();
  }

  // Clears slide progress so it isn't transferred between users
  yield put(SlideProgressActions.clearAllSlideProgress());

  // Make sure to capture the information below before `userDidLogout` happens.
  // `userDidLogout` action will clear that from redux store.
  const ssoLogoutUrl: string = yield select(UserSelectors.getSsoLogoutUrl);
  const hasLoggedInWithSSO: boolean = yield select(UserSelectors.hasLoggedInWithSSO);

  if (ssoLogoutUrl && hasLoggedInWithSSO) {
    yield* handleSsoLogout();
    return;
  }

  yield put(UserActions.userDidLogout());

  if (action.payload.redirect) {
    // Redirect back to login.
    redirectToLogin();
  }
}

// Redirect to the identity provider's logout page (via Emily). Also clear state (userDidLogout()).
function* handleSsoLogout() {
  yield put(
    RequestActions.postAuthed(
      Urls.SINGLE_USE_TOKEN,
      UserActionTypes.USER_LOGOUT_SSO_SUCCESS,
      UserActionTypes.USER_LOGOUT_SSO_FAILURE,
      undefined,
      { purpose: 'SsoLogout' }
    )
  );

  const { success }: SsoLogoutRaceType = yield race({
    success: take(UserActionTypes.USER_LOGOUT_SSO_SUCCESS),
    failure: take(UserActionTypes.USER_LOGOUT_SSO_FAILURE)
  });

  const singleUseToken = success?.payload.value;

  if (!singleUseToken) {
    // The user is only available before `userDidLogout` below.
    const { id: userId, applicationId }: ReturnType<typeof UserSelectors.getUser> = yield select(
      UserSelectors.getUser
    );
    ErrorLogger.captureEvent('Could not get single-use token for SSO logout', 'error', {
      userId,
      applicationId
    });
  }

  yield put(UserActions.userDidLogout());

  redirectToLogin();

  if (singleUseToken) {
    Link.openExternalUrlIAB(`${ENV.EMILY_API}/trigger-idp-logout?token=${singleUseToken}`);
  }
}

export function* watchUserLogout() {
  yield takeLatest(UserActionTypes.USER_LOGOUT, handleUserLogout);
}
