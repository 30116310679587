import { put } from 'redux-saga/effects';

import ConfigActions from '@maggie/store/config/actions';

import type { GlobalRouteQueryParams } from './type';

export function* checkForPlatform(query: GlobalRouteQueryParams) {
  switch (query.platform) {
    case 'ios':
    case 'android':
    case 'browser':
      yield put(ConfigActions.setPlatform(query.platform));

    default:
      break;
  }
}
