import { fork } from 'redux-saga/effects';
import { watchRegisterWithInviteCode } from './register-invite-code-sagas';
import { watchCheckInviteCode } from './check-invite-code-sagas';
import { watchSelfEnroll } from './self-enroll-sagas';
import { watchFetchInviteCodeSettings } from './fetch-invite-settings-sagas';

export const registerSagas = [
  fork(watchRegisterWithInviteCode),
  fork(watchCheckInviteCode),
  fork(watchSelfEnroll),
  fork(watchFetchInviteCodeSettings)
];
