import { put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import { HomeActionTypes } from './actions';

function* handleFetchForYouWidgets() {
  yield put(
    RequestActions.getAuthed(
      Urls.HOME_SCREEN_FOR_YOU_WIDGETS,
      HomeActionTypes.FETCH_FOR_YOU_WIDGETS_SUCCESS,
      HomeActionTypes.FETCH_FOR_YOU_WIDGETS_FAILURE
    )
  );
}

export function* watchFetchForYouWidgets() {
  yield takeLatest(HomeActionTypes.FETCH_FOR_YOU_WIDGETS, handleFetchForYouWidgets);
}
