/**
 * The FeatureFlags component provides a React context for fetching and retrieving
 * feature flags.
 *
 * On mount, flag values are initialized using local storage when available and falling
 * back to offline values if provided.
 *
 * Async callbacks for fetching flags can be provided. Whenever flags are fetched,
 * they are merged into the initial flag values.
 */

import * as React from 'react';
import { FeatureFlagsContext } from './FeatureFlagsContext';
import { getLocalStorageFlags, setLocalStorageFlags, mergeFeatureFlags } from './utils';
import type { Props, FeatureFlags, FlagKeys } from './types';

export function FeatureFlagsProvider<Flags extends FlagKeys>({
  flags: controlledFlags,
  useLocalStorage = true,
  getFeatureFlags,
  children
}: React.PropsWithChildren<Props<Flags>>) {
  const [flags, setFlags] = React.useState<FeatureFlags<Flags>>({
    ...(useLocalStorage ? getLocalStorageFlags() : undefined),
    ...controlledFlags
  });

  const handleFetchFlags = React.useCallback(
    async (flags?: Flags[]) => {
      const fetchedFlagValues = await getFeatureFlags(flags);
      setFlags(previousFlags => mergeFeatureFlags(previousFlags, fetchedFlagValues));
    },
    [getFeatureFlags]
  );

  const handleFetchFlag = React.useCallback(
    async (flagName: Flags) => {
      const fetchedFlagValue = await getFeatureFlags([flagName]);
      setFlags(previousFlags => mergeFeatureFlags(previousFlags, fetchedFlagValue));
    },
    [getFeatureFlags]
  );

  React.useEffect(() => {
    setFlags(previousFlags => ({ ...previousFlags, ...controlledFlags }));
  }, [controlledFlags]);

  React.useEffect(() => {
    setLocalStorageFlags(flags);
  }, [flags]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        fetchFlags: handleFetchFlags,
        fetchFlag: handleFetchFlag,
        flags
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}
