import type { ConferenceActionsUnionType } from './actions';
import { ConferenceActionTypes } from './actions';
import { initialConferencesState } from './constants';
import type { ConferencesState } from './types';

export const conferenceReducer = (
  state: ConferencesState = initialConferencesState,
  action: ConferenceActionsUnionType
): ConferencesState => {
  switch (action.type) {
    case ConferenceActionTypes.FETCH_CONFERENCE: {
      const { options } = action.payload;

      const newState: ConferencesState = {
        ...state,
        loadingError: '',
        loadingErrorCode: undefined
      };

      if (options?.showLoading) {
        newState.isLoading = true;
      }

      return newState;
    }

    case ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS: {
      const { payload: conference } = action;

      return {
        ...state,
        byId: {
          ...state.byId,
          [conference.id]: conference
        },
        isLoading: false,
        loadingError: '',
        loadingErrorCode: undefined
      };
    }

    case ConferenceActionTypes.FETCH_CONFERENCE_FAILURE: {
      const { payload: error } = action;

      return {
        ...state,
        isLoading: false,
        loadingError: error?.message || '',
        loadingErrorCode: error?.code
      };
    }

    case ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_SUCCESS: {
      const { payload: upcomingConferences } = action;

      return {
        ...state,
        upcoming: upcomingConferences
      };
    }

    case ConferenceActionTypes.CLEAR_UPCOMING_CONFERENCES: {
      return {
        ...state,
        upcoming: {
          items: [],
          totalCount: 0
        }
      };
    }

    default:
      return state;
  }
};
