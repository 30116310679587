import type { LoadingActionsUnionType } from './types';
import { LoadingActionTypes } from './actions';
import { initialCoursewareLoadingState } from './constants';

export const coursewareLoadingReducer = (
  state: boolean = initialCoursewareLoadingState,
  action: LoadingActionsUnionType
) => {
  switch (action.type) {
    case LoadingActionTypes.START_COURSEWARE_LOADING:
      return true;
    case LoadingActionTypes.STOP_COURSEWARE_LOADING:
      return false;
    default:
      return state;
  }
};
