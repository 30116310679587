import { createAction } from '@edapp/utils';

export enum ActionDialogActionTypes {
  OPEN_ACTION_DIALOG = 'OPEN_ACTION_DIALOG',
  CLOSE_ACTION_DIALOG = 'CLOSE_ACTION_DIALOG'
}

export const ActionDialogActions = {
  openActionDialog: (
    title?: string,
    message?: string,
    confirmText?: string,
    onConfirm?: () => void
  ) =>
    createAction(ActionDialogActionTypes.OPEN_ACTION_DIALOG, {
      title,
      message,
      confirmText,
      onConfirm
    }),
  closeActionDialog: () => createAction(ActionDialogActionTypes.CLOSE_ACTION_DIALOG)
};
