const primaryBlue = '#00BAFF';
const colors = {
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'transparent',

  background: '#FFFFFF',

  brand: '#223042',

  navyMuted: '#8E96A1',
  navy: '#00122D',
  navyHover: '#001A41',
  navyBorder: '#2C3C4E',

  lightBlue: '#F2FCFF',
  blue: primaryBlue,
  blueHover: '#39A7E5',
  blueActive: '#3499D6',

  // Greys
  lightGrey: '#E9EDF6',
  grey: '#DBE0EB',
  greyHover: '#BFC6D4',
  greyActive: '#828EA0',
  darkGrey: '#505F79',

  // Text
  textMuted: '#545F70',
  text: '#1F2533',
  textOrange: '#BD5800',
  textBlue: '#0D75B5',
  // Reds
  lightRed: '#FFF0F1',
  red: '#C62F35',

  // Yellows
  orange: '#D97502',
  lightOrange: '#FFFAE5',

  // Green
  green: '#17966C',
  lightGreen: '#E8FCF5',

  // Gradients
  navyCardGradientStart: '#2F475F',
  blueCardGradientEnd: '#0070AF',
  blueGradientHoverEnd: '#005788',
  // To use aiGradient as a background you must use background, not background-color
  aiGradient: 'linear-gradient(239.98deg, #ad00ff 4.16%, #599bff 90.62%)',

  // Purple
  purple: '#675DF4',

  // Social media
  facebook: '#4267b2',
  twitter: '#1da1f2',
  linkedIn: '#2867b2',

  // SC colors
  scBlack: '#1F2533',
  scPurple: '#6559FF',
  scGrey: '#E9EEF6',
  scOverlay: '#070B12',
  scBlue: '#0e8ad8',

  // TODO: https://safetyculture.atlassian.net/browse/TRAINING-559
  fixedWhite: '#FFFFFF',
  fixedBlack: '#000000'
};

const darkColors = {
  white: '#242432', // Surface Default BG
  black: '#EFEFF6',
  background: '#15161E', // Default BG

  navy: '#2E2E3E',
  navyHover: '#404054',
  brand: '#475a73',

  navyMuted: '#8E96A1',
  navyBorder: '#d4d8db',
  athenGrey: '#5A5A6D',

  lightBlue: '#203347',

  // Greys
  lightGrey: '#15161E',
  grey: '#404054',
  greyHover: '#5A5A6D',
  greyActive: '#404054',
  darkGrey: '#404054',

  // Text
  textMuted: '#8C8CA1',
  text: '#EFEFF6',
  textOrange: '#F4962A',
  textBlue: '#8AE6FF',
  // Reds
  lightRed: '#3A030E',
  red: '#d2414b',

  // Yellows
  lightOrange: '#331A00',
  orange: '#E47F0C',

  // Green
  lightGreen: '#002E1F',
  green: '#15AC7A',

  // Purple
  purple: '#675DF4'
};

type ColorsType = typeof colors;
export type ColorsKeys = keyof ColorsType;
export { colors, darkColors };
