import { createAction } from '@edapp/utils';

export enum MagicLinkActionTypes {
  UPDATE_EMAIL = 'UPDATE_EMAIL',
  SEND_MAGIC_LINK = 'SEND_MAGIC_LINK',
  SEND_MAGIC_LINK_SUCCESS = 'SEND_MAGIC_LINK_SUCCESS',
  SEND_MAGIC_LINK_FAILURE = 'SEND_MAGIC_LINK_FAILURE'
}

export const MagicLinkActions = {
  updateEmail: (email: string) => createAction(MagicLinkActionTypes.UPDATE_EMAIL, { email }),
  sendMagicLink: (email: string) => createAction(MagicLinkActionTypes.SEND_MAGIC_LINK, { email }),
  sendMagicLinksSuccess: () => createAction(MagicLinkActionTypes.SEND_MAGIC_LINK_SUCCESS, {}),
  sendMagicLinksFailure: (error: EdErrorResponseType) =>
    createAction(MagicLinkActionTypes.SEND_MAGIC_LINK_FAILURE, error)
};
