import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type {
  AssessmentPostType,
  AssessmentPostCommentType,
  AssessmentType,
  AssessmentProgress,
  AssessmentFeedback
} from './types';
import type { RequestTypes } from '@edapp/request';
import type { Media } from '../types';

enum AssessmentActionTypes {
  FETCH_ASSESSMENT = 'FETCH_ASSESSMENT',
  FETCH_ASSESSMENT_SUCCESS = 'FETCH_ASSESSMENT_SUCCESS',
  FETCH_ASSESSMENT_FAILURE = 'FETCH_ASSESSMENT_FAILURE',

  FETCH_ASSESSMENT_PROGRESS = 'FETCH_ASSESSMENT_PROGRESS',
  FETCH_ASSESSMENT_PROGRESS_SUCCESS = 'FETCH_ASSESSMENT_PROGRESS_SUCCESS',
  FETCH_ASSESSMENT_PROGRESS_FAILURE = 'FETCH_ASSESSMENT_PROGRESS_FAILURE',

  FETCH_ASSESSMENT_WITH_PROGRESS = 'FETCH_ASSESSMENT_WITH_PROGRESS',
  FETCH_ASSESSMENT_WITH_PROGRESS_SUCCESS = 'FETCH_ASSESSMENT_WITH_PROGRESS_SUCCESS',
  FETCH_ASSESSMENT_WITH_PROGRESS_FAILURE = 'FETCH_ASSESSMENT_WITH_PROGRESS_FAILURE',

  FETCH_ASSESSMENT_POSTS = 'FETCH_ASSESSMENT_POSTS',
  FETCH_ASSESSMENT_POSTS_SUCCESS = 'FETCH_ASSESSMENT_POSTS_SUCCESS',
  FETCH_ASSESSMENT_POSTS_FAILURE = 'FETCH_ASSESSMENT_POSTS_FAILURE',

  FETCH_ASSESSMENT_POST_COMMENTS = 'FETCH_ASSESSMENT_POST_COMMENTS',
  FETCH_ASSESSMENT_POST_COMMENTS_SUCCESS = 'FETCH_ASSESSMENT_POST_COMMENTS_SUCCESS',
  FETCH_ASSESSMENT_POST_COMMENTS_FAILURE = 'FETCH_ASSESSMENT_POST_COMMENTS_FAILURE',

  FETCH_ASSESSMENT_POST_ITEM = 'FETCH_ASSESSMENT_POST_ITEM',
  FETCH_ASSESSMENT_POST_ITEM_SUCCESS = 'FETCH_ASSESSMENT_POST_ITEM_SUCCESS',
  FETCH_ASSESSMENT_POST_ITEM_FAILURE = 'FETCH_ASSESSMENT_POST_ITEM_FAILURE',

  EDIT_ASSESSMENT_POST_SUBSCRIPTION = 'EDIT_ASSESSMENT_POST_SUBSCRIPTION',
  EDIT_ASSESSMENT_POST_SUBSCRIPTION_SUCCESS = 'EDIT_ASSESSMENT_POST_SUBSCRIPTION_SUCCESS',
  EDIT_ASSESSMENT_POST_SUBSCRIPTION_FAILURE = 'EDIT_ASSESSMENT_POST_SUBSCRIPTION_FAILURE',

  EDIT_ASSESSMENT_POST = 'EDIT_ASSESSMENT_POST',
  EDIT_ASSESSMENT_POST_SUCCESS = 'EDIT_ASSESSMENT_POST_SUCCESS',
  EDIT_ASSESSMENT_POST_FAILURE = 'EDIT_ASSESSMENT_POST_FAILURE',

  SAVE_ASSESSMENT_POST_COMMENT = 'SAVE_ASSESSMENT_POST_COMMENT',
  SAVE_ASSESSMENT_POST_COMMENT_SUCCESS = 'SAVE_ASSESSMENT_POST_COMMENT_SUCCESS',
  SAVE_ASSESSMENT_POST_COMMENT_FAILURE = 'SAVE_ASSESSMENT_POST_COMMENT_FAILURE',

  SAVE_ASSESSMENT_POST = 'SAVE_ASSESSMENT_POST',
  SAVE_ASSESSMENT_POST_SUCCESS = 'SAVE_ASSESSMENT_POST_SUCCESS',
  SAVE_ASSESSMENT_POST_FAILURE = 'SAVE_ASSESSMENT_POST_FAILURE',

  FETCH_ASSESSMENT_POST_FEEDBACK = 'FETCH_ASSESSMENT_POST_FEEDBACK',
  FETCH_ASSESSMENT_POST_FEEDBACK_SUCCESS = 'FETCH_ASSESSMENT_POST_FEEDBACK_SUCCESS',
  FETCH_ASSESSMENT_POST_FEEDBACK_FAILURE = 'FETCH_ASSESSMENT_POST_FEEDBACK_FAILURE',

  CLEAR_ASSESSMENT_POSTS = 'CLEAR_ASSESSMENT_POSTS'
}

const AssessmentActions = {
  fetchAssessment: (id: string) => createAction(AssessmentActionTypes.FETCH_ASSESSMENT, { id }),

  fetchAssessmentWithProgress: (id: string) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS, { id }),
  fetchAssessmentWithProgressSuccess: (assessment: AssessmentType) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS_SUCCESS, { assessment }),
  fetchAssessmentWithProgressFailure: () =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_WITH_PROGRESS_FAILURE),

  fetchPosts: (assessmentId: string, page = 1) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POSTS, { assessmentId, page }),
  fetchPostsSuccess: (
    assessmentId: string,
    page: number,
    postsData: RequestTypes.PaginatedResponse<AssessmentPostType>
  ) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POSTS_SUCCESS, {
      page,
      assessmentId,
      postsData
    }),

  fetchPostComments: (assessmentId: string, postId: string, page = 1, created = 'asc') =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS, {
      assessmentId,
      postId,
      page,
      created
    }),
  fetchPostCommentsSuccess: (
    postId: string,
    page: number,
    postComments: RequestTypes.PaginatedResponse<AssessmentPostCommentType>
  ) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS_SUCCESS, {
      postId,
      page,
      postComments
    }),

  fetchPostItem: (assessmentId: string, postId: string) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM, { assessmentId, postId }),
  fetchPostItemSuccess: (assessmentId: string, post: AssessmentPostType) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM_SUCCESS, { assessmentId, post }),

  fetchAssessmentProgress: (assessmentId: string) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS, { assessmentId }),
  fetchAssessmentProgressSuccess: (assessmentId: string, progress: AssessmentProgress) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS_SUCCESS, {
      assessmentId,
      progress
    }),

  fetchFeedback: (assessmentId: string, postId: string) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK, { assessmentId, postId }),
  fetchFeedbackSuccess: (postId: string, feedback: AssessmentFeedback) =>
    createAction(AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK_SUCCESS, {
      postId,
      feedback
    }),

  saveComment: (assessmentId: string, postId: string, commentText: string) =>
    createAction(AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT, {
      assessmentId,
      postId,
      commentText
    }),
  saveCommentSuccess: (postId: string, postComment: AssessmentPostCommentType) =>
    createAction(AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT_SUCCESS, {
      postId,
      postComment
    }),

  editPostSubscription: (assessmentId: string, postId: string, isWatching: boolean) =>
    createAction(AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION, {
      assessmentId,
      postId,
      isWatching
    }),
  editPostSubscriptionSuccess: (assessmentId: string, postId: string, isWatching: boolean) =>
    createAction(AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION_SUCCESS, {
      assessmentId,
      postId,
      isWatching
    }),

  savePost: (assessmentId: string, textContent: string, media: Media[]) =>
    createAction(AssessmentActionTypes.SAVE_ASSESSMENT_POST, { assessmentId, textContent, media }),
  savePostSuccess: (assessmentId: string, post: AssessmentPostType) =>
    createAction(AssessmentActionTypes.SAVE_ASSESSMENT_POST_SUCCESS, { assessmentId, post }),

  editPost: (assessmentId: string, postId: string, textContent: string, media: Media[]) =>
    createAction(AssessmentActionTypes.EDIT_ASSESSMENT_POST, {
      assessmentId,
      postId,
      textContent,
      media
    }),
  editPostSuccess: (assessmentId: string, post: AssessmentPostType) =>
    createAction(AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUCCESS, { assessmentId, post }),

  clearAssessmentPosts: (assessmentId: string) =>
    createAction(AssessmentActionTypes.CLEAR_ASSESSMENT_POSTS, { assessmentId })
};

type AssessmentActionsUnionType =
  | ActionsUnion<typeof AssessmentActions>
  | ActionWithPayload<AssessmentActionTypes.FETCH_ASSESSMENT_SUCCESS, AssessmentType>
  | ActionWithPayload<AssessmentActionTypes.FETCH_ASSESSMENT_FAILURE, EdErrorResponseType>
  | ActionWithPayload<AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS_FAILURE, EdErrorResponseType>
  | ActionWithPayload<AssessmentActionTypes.FETCH_ASSESSMENT_POSTS_FAILURE, EdErrorResponseType>
  | ActionWithPayload<
      AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS_FAILURE,
      EdErrorResponseType
    >
  | ActionWithPayload<AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM_FAILURE, EdErrorResponseType>
  | ActionWithPayload<
      AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK_FAILURE,
      EdErrorResponseType
    >
  | ActionWithPayload<AssessmentActionTypes.EDIT_ASSESSMENT_POST_FAILURE, EdErrorResponseType>
  | ActionWithPayload<
      AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT_FAILURE,
      EdErrorResponseType
    >
  | ActionWithPayload<AssessmentActionTypes.SAVE_ASSESSMENT_POST_FAILURE, EdErrorResponseType>
  | ActionWithPayload<
      AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION_FAILURE,
      EdErrorResponseType
    >;

export { AssessmentActionTypes, AssessmentActions, AssessmentActionsUnionType };
