import type { PlaysState } from './types';
import type { GameActionsUnionType } from '../types';
import { GameActionTypes } from '../actions';

const initialPlaysState: PlaysState = [];

const playsReducer = (
  state: PlaysState = initialPlaysState,
  action: GameActionsUnionType
): PlaysState => {
  switch (action.type) {
    case GameActionTypes.FETCH_GAME_DATA_SUCCESS:
      return action.payload.plays.sort((playA, playB) => {
        const playADate = new Date(playA.created);
        const playBDate = new Date(playB.created);
        if (playADate < playBDate) {
          return -1;
        }
        if (playADate > playBDate) {
          return 1;
        }
        return 0;
      });
    case GameActionTypes.PRIZE_CLAIMED:
      return state.filter(play => play.id !== action.payload.roundId);

    default:
      return state;
  }
};

export { initialPlaysState, playsReducer };
