import { select } from 'redux-saga/effects';

import { ObservationSelectors } from '@maggie/store/courseware/observations/selectors';
import type { LxStoreState } from '@maggie/store/types';

export function* handleDidMainNavigateToObservation(observationId: string) {
  const submissionId: string | undefined = yield select<LxStoreState>(
    ObservationSelectors.getSubmissionIdforObservation(observationId)
  );

  if (submissionId) {
    window.__router.navigate('observationFeedback', { observationId, submissionId });
  }
}
