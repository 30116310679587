import { itly } from '@edapp/analytics-tracking';
import type { ViewInteraction } from '@maggie/core/tracker/hippo-interaction-types';
import { Tracker } from '@maggie/core/tracker/tracker';
import { TrackerInteractions } from '@maggie/core/tracker/tracker-interactions';
import type { SlideType } from '@maggie/store/courseware/lessons/types';
import { RapidRefreshActions } from '@maggie/store/courseware/rapid-refreshes/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

import type {
  ThomasSlideInteraction,
  ThomasViewInteraction
} from '../thomas/thomas-interaction-types';
import { Attempt } from './attempt';

export class RapidRefreshAttempt extends Attempt {
  private id: string;
  private session: number;

  constructor(id: string, session: number, slides: SlideType[]) {
    super(slides);
    this.id = id;
    this.session = session;
  }

  protected recordView = (view: ThomasViewInteraction) => {
    super.recordView(view);

    const interaction: ViewInteraction = {
      ...view,
      type: 'view',
      lesson_title: 'Rapid Refresh',
      already_completed: false,
      attempt: this._attemptId,
      quiz_id: this.id
    };
    TrackerInteractions.trackViewWithDuration(interaction);
  };

  protected recordInteraction(slide: ThomasSlideInteraction) {
    super.recordInteraction(slide);

    // Save info
    const externalIdentifier = UserSelectors.getExternalIdentifier(window.__store.getState().user);
    TrackerInteractions.trackSlideInteraction({
      ...slide,
      type: 'slide',
      lesson_id: this.id,
      course_id: this.id,
      attempt: this._attemptId,
      user_external_identifier: externalIdentifier
    });
  }

  public finishAttempt(): void {
    super.finishAttempt();

    Tracker.trackEvent({
      type: 'event',
      name: 'rapid-refresh-session-completed',
      lessonsCompleted: 0,
      id: this._attemptId,
      value: `${this.session}`
    });

    itly.rapidRefreshSessionCompleted({
      lessonsCompleted: 0,
      id: this._attemptId,
      value: `${this.session}`
    });

    window.__store.dispatch(RapidRefreshActions.completeRapidRefresh(this.id, this.interactions));
  }
}
