import type { ActionsUnion, ActionWithPayload, Action } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { AuthorProfileType, ErrorType } from './types';

export enum AuthorProfileActionTypes {
  FETCH_AUTHOR_PROFILE = 'FETCH_AUTHOR_PROFILE',
  FETCH_AUTHOR_PROFILE_SUCCESS = 'FETCH_AUTHOR_PROFILE_SUCCESS',
  FETCH_AUTHOR_PROFILE_FAILURE = 'FETCH_AUTHOR_PROFILE_FAILURE',
  SAVE_AUTHOR_PROFILE = 'SAVE_AUTHOR_PROFILE',
  SAVE_AUTHOR_PROFILE_SUCCESS = 'SAVE_AUTHOR_PROFILE_SUCCESS',
  SAVE_AUTHOR_PROFILE_FAILURE = 'SAVE_AUTHOR_PROFILE_FAILURE',
  FETCH_AUTHOR_PROFILES = 'FETCH_AUTHOR_PROFILES',
  FETCH_AUTHOR_PROFILES_SUCCESS = 'FETCH_AUTHOR_PROFILES_SUCCESS',
  FETCH_AUTHOR_PROFILES_FAILURE = 'FETCH_AUTHOR_PROFILES_FAILURE'
}

export type SaveAuthorProfile = {
  data: AuthorProfileType;
  oldData: AuthorProfileType;
};

export type SaveAuthorProfileFailure = {
  authorProfileStateBeforeSave: AuthorProfileType;
  error: ErrorType;
};

export type FetchCoursesAuthorProfileSuccess = {
  [id: string]: AuthorProfileType | undefined;
};

export type FetchAuthorProfilesSuccess = {
  [id: string]: AuthorProfileType | undefined;
};

export const AuthorProfileActions = {
  fetchAuthorProfile: () => createAction(AuthorProfileActionTypes.FETCH_AUTHOR_PROFILE),
  fetchAuthorProfileSuccess: (payload: AuthorProfileType) =>
    createAction(AuthorProfileActionTypes.FETCH_AUTHOR_PROFILE_SUCCESS, payload),
  fetchAuthorProfileFailure: (payload: ErrorType) =>
    createAction(AuthorProfileActionTypes.FETCH_AUTHOR_PROFILE_FAILURE, payload),

  saveAuthorProfile: (payload: AuthorProfileType) =>
    createAction(AuthorProfileActionTypes.SAVE_AUTHOR_PROFILE, payload),
  saveAuthorProfileSuccess: (payload: SaveAuthorProfile) =>
    createAction(AuthorProfileActionTypes.SAVE_AUTHOR_PROFILE_SUCCESS, payload),
  saveAuthorProfileFailure: (payload: SaveAuthorProfileFailure) =>
    createAction(AuthorProfileActionTypes.SAVE_AUTHOR_PROFILE_FAILURE, payload),

  fetchAuthorProfiles: (ids: string[]) =>
    createAction(AuthorProfileActionTypes.FETCH_AUTHOR_PROFILES, { ids }),
  fetchAuthorProfilesSuccess: (authorProfiles: FetchAuthorProfilesSuccess) =>
    createAction(AuthorProfileActionTypes.FETCH_AUTHOR_PROFILES_SUCCESS, {
      authorProfiles
    })
};

export type AuthorProfileActionsUnionType =
  | ActionsUnion<typeof AuthorProfileActions>
  | Action<AuthorProfileActionTypes.FETCH_AUTHOR_PROFILE>
  | ActionWithPayload<AuthorProfileActionTypes.FETCH_AUTHOR_PROFILE_SUCCESS, AuthorProfileType>
  | ActionWithPayload<AuthorProfileActionTypes.FETCH_AUTHOR_PROFILE_FAILURE, ErrorType>
  | ActionWithPayload<AuthorProfileActionTypes.SAVE_AUTHOR_PROFILE, AuthorProfileType>
  | ActionWithPayload<AuthorProfileActionTypes.SAVE_AUTHOR_PROFILE_SUCCESS, SaveAuthorProfile>
  | ActionWithPayload<
      AuthorProfileActionTypes.SAVE_AUTHOR_PROFILE_FAILURE,
      SaveAuthorProfileFailure
    >
  | ActionWithPayload<AuthorProfileActionTypes.FETCH_AUTHOR_PROFILES_FAILURE, ErrorType>;
