import { RequestUtils } from '@edapp/request';
import type { Action, ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import { ENV } from '@maggie/config/env';
import { FileManager } from '@maggie/cordova/file';
import type { GetMediaFromSourceOptions } from '@maggie/cordova/media';
import { MediaHelper } from '@maggie/cordova/media';
import { uploadDocument } from '@maggie/hooks//use-media-upload/api';
import type { UploadMediaRequest } from '@maggie/hooks//use-media-upload/types';
import { Urls } from '@maggie/store/constants';
import { MediaType } from '@maggie/store/courseware/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { eventManager } from '../event-manager';
import type { IFrameBridge } from '../thomas/iframe-bridge';
import { slidedeckPrefix } from '../thomas/utils';
import { PeerAuthoringLaunchOptions } from './peer_authoring_launch';

const DEBUG = false;

type PostResponse = { id: string };

type GetCommentOptions = {
  lessonId: string;
  slideId: string;
  contentId: string;
};

type Comment = {
  id: string;
  userName: string;
  userId: string;
  comment: string;
  createdAt: string;
};

type GetCommentResponse = {
  totalCount: number;
  items: Comment[];
};

type PostCommentOptions = {
  lessonId: string;
  slideId: string;
  contentId: string;
  comment: string;
};

type PostCommentResponse = PostResponse;

type GetMediaResponse = {
  totalCount: number;
  items: PeerAuthoringMedia[];
};

type PeerAuthoringMedia = {
  id: string;
  title: string;
  description: string;
  url: string;
  slideId: string;
  lessonId: string;
  userId: string;
  userName: string;
  createdAt: string;
  commentCount: number;
};

type PostMediaBody = { title: string; url: string; description: string };

type PostMediaResponse = PostResponse;

type OpenCommentPayload = {
  openComment: boolean;
  lessonId?: string;
  slideId?: string;
  videoId?: string;
};

export class PeerAuthoringAPI {
  static getUserToken() {
    return UserSelectors.getToken(window.__store.getState());
  }

  static async getMedia(lessonId: string, slideId: string) {
    const url = `${ENV.HIPPO_API}/${Urls.PEER_AUTHORING}/${lessonId}/${slideId}/media?pageSize=1000`;

    return RequestUtils.httpFetch<GetMediaResponse, undefined>(
      'GET',
      url,
      this.getUserToken(),
      undefined
    );
  }

  static async postMedia(options: PeerAuthoringParams & PostMediaBody) {
    const { lessonId, slideId, ...body } = options;
    const endpoint = `${ENV.HIPPO_API}/${Urls.PEER_AUTHORING}/${lessonId}/${slideId}/media`;
    return RequestUtils.httpFetch<PostMediaResponse, typeof body>(
      'POST',
      endpoint,
      this.getUserToken(),
      body
    );
  }

  static async upload(options: UploadMediaRequest) {
    return uploadDocument(this.getUserToken(), options);
  }

  static async getComments(options: GetCommentOptions) {
    const { lessonId, slideId, contentId } = options;
    const url = `${ENV.HIPPO_API}/${Urls.PEER_AUTHORING}/${lessonId}/${slideId}/media/${contentId}/comments?pageSize=1000`;
    return RequestUtils.httpFetch<GetCommentResponse, undefined>(
      'GET',
      url,
      this.getUserToken(),
      undefined
    );
  }

  static async postComment(options: PostCommentOptions) {
    const { lessonId, slideId, contentId, ...body } = options;
    const endpoint = `${ENV.HIPPO_API}/${Urls.PEER_AUTHORING}/${lessonId}/${slideId}/media/${contentId}/comments`;
    return RequestUtils.httpFetch<PostCommentResponse, typeof body>(
      'POST',
      endpoint,
      this.getUserToken(),
      body
    );
  }
}

// Types
type PeerAuthoringParams = {
  lessonId: string;
  slideId: string;
};

type GetMediaForSlideAction = ActionWithPayload<
  typeof GET_MEDIA_FOR_SLIDE,
  PeerAuthoringParams & { page: number }
>;

type UploadMediaFromSourceAction = ActionWithPayload<
  typeof UPLOAD_MEDIA_FROM_SOURCE,
  PeerAuthoringParams & GetMediaFromSourceOptions
>;

type PostMediaForSlideAction = ActionWithPayload<
  typeof UPLOAD_MEDIA_FROM_SOURCE,
  PeerAuthoringParams & PeerAuthoringMedia
>;

type PeerAuthoringSocial = {
  slideId: string;
  contentId: string;
};

type CommentLoaded = ReturnType<
  typeof PeerAuthoringBridge.prototype.mapCommentToSocialLearningComment
>;

// type MoreCommentAction = ActionWithPayload<typeof MORE_COMMENT, PeerAuthoringSocial>;
type AddCommentAction = ActionWithPayload<
  typeof ADD_COMMENT,
  PeerAuthoringSocial & { message: string }
>;
// type LikeCommentAction = ActionWithPayload<
//   typeof LIKE_COMMENT,
//   PeerAuthoringSocial & { commentId: string }
// >;
// type UnlikeCommentAction = ActionWithPayload<
//   typeof UNLIKE_COMMENT,
//   PeerAuthoringSocial & { commentId: string }
// >;
// type FlagCommentAction = ActionWithPayload<
//   typeof FLAG_COMMENT,
//   PeerAuthoringSocial & { commentId: string }
// >;
// type DeleteCommentAction = ActionWithPayload<
//   typeof DELETE_COMMENT,
//   PeerAuthoringSocial & { commentId: string }
// >;

// Constants

export const UPLOAD_MEDIA_FROM_SOURCE = 'UPLOAD_MEDIA_FROM_SOURCE';
const UPLOAD_MEDIA_FROM_SOURCE_SUCCESS = 'UPLOAD_MEDIA_FROM_SOURCE_SUCCESS';
const UPLOAD_MEDIA_FROM_SOURCE_FAILURE = 'UPLOAD_MEDIA_FROM_SOURCE_FAILURE';
const UPLOAD_MEDIA_FROM_SOURCE_PROGRESS = 'UPLOAD_MEDIA_FROM_SOURCE_PROGRESS';
const UPLOAD_MEDIA_FROM_SOURCE_PREVIEW = 'UPLOAD_MEDIA_FROM_SOURCE_PREVIEW';

export const POST_MEDIA_FOR_SLIDE = 'POST_MEDIA_FOR_SLIDE';
const POST_MEDIA_FOR_SLIDE_SUCCESS = 'POST_MEDIA_FOR_SLIDE_SUCCESS';
const POST_MEDIA_FOR_SLIDE_FAILURE = 'POST_MEDIA_FOR_SLIDE_FAILURE';

export const GET_MEDIA_FOR_SLIDE = 'GET_MEDIA_FOR_SLIDE';
const GET_MEDIA_FOR_SLIDE_SUCCESS = 'GET_MEDIA_FOR_SLIDE_SUCCESS';
const GET_MEDIA_FOR_SLIDE_FAILURE = 'GET_MEDIA_FOR_SLIDE_FAILURE';
export const UNSET_AUTO_OPEN_COMMENT = 'UNSET_AUTO_OPEN_COMMENT';

// Closely imitate social learning events
/**
 * An incoming action from the lesson engine to load comments and load more when a user reaches the bottom.
 */
export const MORE_COMMENT = 'more-comment-pa';
/**
 * An outgoing action to the lesson engine with the payload of comments for a media item.
 */
const COMMENT_LOADED = 'subscribe:event:comment-loaded';
/**
 * An incomming action to add a comment for a media item.
 */
export const ADD_COMMENT = 'add-comment-pa';
// const LIKE_COMMENT = 'like-comment-pa';
// const UNLIKE_COMMENT = 'unlike-comment-pa';
// const FLAG_COMMENT = 'flag-comment-pa';
// const DELETE_COMMENT = 'delete-comment-pa';
// const SCROLL_TO_TOP = 'scroll-to-top-pa';

// Action Creators
export const PeerAuthoringActions = {
  uploadMediaFromSourceProgress: (percent: number | undefined) =>
    createAction(UPLOAD_MEDIA_FROM_SOURCE_PROGRESS, { percent }),
  getMediaForSlideSuccess: (payload: GetMediaResponse & OpenCommentPayload) =>
    createAction(GET_MEDIA_FOR_SLIDE_SUCCESS, payload),
  getMediaForSlideFailure: (payload: GetMediaResponse) =>
    createAction(GET_MEDIA_FOR_SLIDE_FAILURE, payload),
  postMediaForSlideSuccess: (payload: Partial<PeerAuthoringMedia>) =>
    createAction(POST_MEDIA_FOR_SLIDE_SUCCESS, payload),
  postMediaForSlideFailure: (payload: any) => createAction(POST_MEDIA_FOR_SLIDE_FAILURE, payload),
  uploadMediaFromSourcePreview: (uri: string) =>
    createAction(UPLOAD_MEDIA_FROM_SOURCE_PREVIEW, { uri }),
  uploadMediaFromSourceSuccess: (uri: string) =>
    createAction(UPLOAD_MEDIA_FROM_SOURCE_SUCCESS, { uri }),
  uploadMediaFromSourceFailure: (message: string) =>
    createAction(UPLOAD_MEDIA_FROM_SOURCE_FAILURE, { message }),
  commentLoaded: (payload: CommentLoaded) => createAction(COMMENT_LOADED, payload),
  commentAdded: (payload: AddCommentAction) => createAction('COMMENT_ADDED', payload)
};

export type PeerAuthoringActionsUnion = ActionsUnion<typeof PeerAuthoringActions>;

export class PeerAuthoringBridge {
  lessonWindow: IFrameBridge;
  lessonId: string;

  getCurrentUserId(): string {
    return UserSelectors.getId(window.__store.getState());
  }

  /**
   * Sets the lesson window and lesson Id which is used to dispatch events to.
   * @param {IFrameWindowBridge} lessonWindow
   * @param {string} lessonId
   * @memberof PeerAuthoringBridge
   */
  activate(lessonWindow: IFrameBridge, lessonId: string) {
    this.lessonWindow = lessonWindow;
    this.lessonId = lessonId;

    // Subscribe to peer authoring events
    this.subscribe(UPLOAD_MEDIA_FROM_SOURCE, this.handleUploadMediaFromSource);
    this.subscribe(GET_MEDIA_FOR_SLIDE, this.handleGetMediaForSlide);
    this.subscribe(POST_MEDIA_FOR_SLIDE, this.handlePostMediaForSlide);
    this.subscribe(MORE_COMMENT, this.handleMoreComment);
    this.subscribe(ADD_COMMENT, this.handleAddComment);
    this.subscribe(UNSET_AUTO_OPEN_COMMENT, this.handleUnsetAutoOpenComment);
  }

  /**
   * Deactivates the peer authoring bridge. This should be used when the lesson content has exited.
   * @memberof PeerAuthoringBridge
   */
  deactivate() {
    // Stop listening to all events
    eventManager.stopListening(UPLOAD_MEDIA_FROM_SOURCE);
    eventManager.stopListening(GET_MEDIA_FOR_SLIDE);
    eventManager.stopListening(POST_MEDIA_FOR_SLIDE);
    eventManager.stopListening(MORE_COMMENT);
    eventManager.stopListening(ADD_COMMENT);
    eventManager.stopListening(UNSET_AUTO_OPEN_COMMENT);
  }

  /**
   * A redux like way of dispatching an action into the thomas event environment.
   * @param {Action<any>} action
   * @memberof PeerAuthoringBridge
   */
  dispatch(action: Action<any> | ActionWithPayload<any, any>) {
    const code =
      'payload' in action && typeof action.payload !== 'undefined'
        ? `Backbone.Events.trigger('${action.type}', ${JSON.stringify(action.payload)});`
        : `Backbone.Events.trigger('${action.type}');`;
    if (DEBUG) {
      console.debug('Dispatch Action:', action.type);
    }
    this.lessonWindow.executeScript(code);
  }
  /**
   * A redux like way for subscribing to event actions on the Backbone.Events channel.
   * @param {string} event
   * @param {((action: Action<any> | ActionWithPayload<any, any>) => void)} handler
   * @memberof PeerAuthoringBridge
   */
  subscribe(event: string, handler: (action: Action<any> | ActionWithPayload<any, any>) => void) {
    // We need to prefix our listening events to the format thomas outputs
    const prefixedEvent = `${slidedeckPrefix(this.lessonId)}:${event}`;
    eventManager.listenTo(prefixedEvent, (payload: any) =>
      // When calling the handler mimick the action type
      handler({ type: prefixedEvent, payload })
    );
  }

  handleUploadMediaFromSource = async (action: UploadMediaFromSourceAction) => {
    try {
      if (!action.payload) {
        throw new Error("Invalid payload for action 'UploadMediaFromSourceAction'");
      }
      const options = {
        source: action.payload.source || 'library',
        type: action.payload.type || 'video'
      };
      // Get a media type from a source
      const localUri = await MediaHelper.getMediaFromSource(options);
      if (!localUri) {
        throw new Error('No uri returned from getMediaFromSource');
      }
      const file = await FileManager.getFileWithURL(localUri);

      // Send the preview of the file
      this.dispatch(PeerAuthoringActions.uploadMediaFromSourcePreview(localUri));

      // Start uploading
      const externalUri = await PeerAuthoringAPI.upload({
        file: file as File,
        mediaType: MediaType.VIDEO,
        fileName: localUri.substring(localUri.lastIndexOf('/') + 1)
      });

      this.dispatch(PeerAuthoringActions.uploadMediaFromSourceSuccess(externalUri.url));
    } catch (e) {
      console.error('Error in handleUploadMediaFromSource', e);
      this.dispatch(PeerAuthoringActions.uploadMediaFromSourceFailure(e.message));
    }
  };

  handleGetMediaForSlide = async (action: GetMediaForSlideAction) => {
    if (action.payload) {
      const { lessonId, slideId } = action.payload;
      try {
        const mediaForSlide = await PeerAuthoringAPI.getMedia(lessonId, slideId);
        if (
          PeerAuthoringLaunchOptions.openCommentWindow &&
          slideId === PeerAuthoringLaunchOptions.slideId &&
          lessonId === PeerAuthoringLaunchOptions.lessonId
        ) {
          this.dispatch(
            PeerAuthoringActions.getMediaForSlideSuccess({
              ...mediaForSlide,
              openComment: true,
              lessonId: PeerAuthoringLaunchOptions.lessonId,
              slideId: PeerAuthoringLaunchOptions.slideId,
              videoId: PeerAuthoringLaunchOptions.videoId
            })
          );
        } else {
          this.dispatch(
            PeerAuthoringActions.getMediaForSlideSuccess({ ...mediaForSlide, openComment: false })
          );
        }
      } catch (e) {
        this.dispatch(PeerAuthoringActions.getMediaForSlideFailure(e));
      }
    }
  };

  handlePostMediaForSlide = async (action: PostMediaForSlideAction) => {
    try {
      const { id } = await PeerAuthoringAPI.postMedia(action.payload);
      this.dispatch(PeerAuthoringActions.postMediaForSlideSuccess({ ...action.payload, id }));
    } catch (e) {
      this.dispatch(PeerAuthoringActions.postMediaForSlideFailure(e));
    }
  };
  /**
   * The action handler to load comments for a media item.
   * @memberof PeerAuthoringBridge
   */
  handleMoreComment = async (action: ActionWithPayload<any, PeerAuthoringSocial>) => {
    const result = await PeerAuthoringAPI.getComments({
      ...action.payload,
      lessonId: this.lessonId
    });
    const comments = this.mapCommentToSocialLearningComment(result);
    this.dispatch(PeerAuthoringActions.commentLoaded(comments));
  };

  handleAddComment = async (action: AddCommentAction) => {
    const { message: comment, ...payload } = action.payload;
    await PeerAuthoringAPI.postComment({
      ...payload,
      lessonId: this.lessonId,
      comment
    });
    // Dispatch an action to Thomas to get it increment the comment count on the video
    this.dispatch(PeerAuthoringActions.commentAdded(action));
    // We just get all comments again
    await this.handleMoreComment(action);
  };

  handleUnsetAutoOpenComment = () => {
    PeerAuthoringLaunchOptions.unsetAutoOpenComment();
  };

  /**
   * Maps comments received from the Peer Authoring endpoints to the social learning format in the lesson engine.
   * @memberof PeerAuthoringBridge
   */
  mapCommentToSocialLearningComment = (response: GetCommentResponse) => {
    return {
      hasMore: false,
      total: response.totalCount,
      comments: response.items.map(item => ({
        id: item.id,
        message: item.comment,
        author: item.userName,
        likes: 0,
        timestamp: item.createdAt,
        isFromCurrentLearner: item.userId === this.getCurrentUserId(),
        isLikedByLearner: false,
        isFlaggedByLearner: false
      }))
    };
  };
}
