import * as React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import type { Props } from './types';

export const QRCode = React.forwardRef<HTMLCanvasElement, Props>(({ value, ...props }, ref) => {
  /**
   * Below ref callback logic is required due to the underlying component not forwarding refs properly.
   */
  const handleSetRef = React.useCallback<React.RefCallback<HTMLDivElement>>(
    element => {
      if (!ref || !element?.firstElementChild) {
        return;
      }

      if (typeof ref === 'function') {
        ref(element.firstElementChild as HTMLCanvasElement);
      } else {
        ref.current = element.firstElementChild as HTMLCanvasElement;
      }
    },
    [ref]
  );

  return (
    <div ref={handleSetRef}>
      <QRCodeCanvas {...props} value={value} />
    </div>
  );
});
