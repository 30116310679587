import { race, select } from 'redux-saga-test-plan/matchers';
import { put, take } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { generateTitle } from '@edapp/translations/src/i18n';
import { validators } from '@edapp/utils';
import { RegisterActions, RegisterActionsTypes } from '@maggie/store/register/actions';
import type { RegisterAction } from '@maggie/store/register/types';
import { ToastActions } from '@maggie/store/toast/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserActionTypes, UserActions } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

type CheckInviteCodeRaceType = {
  success: RegisterAction<RegisterActionsTypes.CHECK_INVITE_CODE_SUCCESS>;
  failure: RegisterAction<RegisterActionsTypes.CHECK_INVITE_CODE_FAILURE>;
};

export function* handleDidNavigateToRegister(code: string, email?: string, courseId?: string): any {
  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );

  if (!!token && !!courseId) {
    yield put(RegisterActions.selfEnroll(courseId));
    yield take(RegisterActionsTypes.SELF_ENROLL_COMPLETED);
  }

  if (code !== 'invited') {
    yield put(RegisterActions.checkInviteCode(code));
    const { success, failure }: CheckInviteCodeRaceType = yield race({
      success: take(RegisterActionsTypes.CHECK_INVITE_CODE_SUCCESS),
      failure: take(RegisterActionsTypes.CHECK_INVITE_CODE_FAILURE)
    });

    if (!!failure) {
      yield put(ToastActions.showToast(true, generateTitle('dialog.error.titles')));
      return window.__router.navigate('login', {});
    }

    const { applicationId } = success.payload;
    const userApplicationId: string = yield select(UserSelectors.getApplicationId);
    if (applicationId === userApplicationId) {
      // user is already signed in, with same account as the invite code
      return window.__router.navigate('home', {});
    }

    if (!!token) {
      // user is logged in with a different account than one from invite code
      yield put(UserActions.userLogout(false, false));
      yield take(UserActionTypes.USER_DID_LOGOUT);
      return;
    }
  }

  if (!!email) {
    if (validators.email(email)) {
      ErrorLogger.captureEvent('Register without email', 'critical', { email, code });
      itly.registerWithoutEmail({ code, email });
    }
  }
}
