import type { Action, ActionWithPayload } from '@edapp/utils';
import { createAction } from '@edapp/utils/src/actions';
import type { InviteCodeSignUpPayload } from './types';

enum InviteCodeSignUpActionTypes {
  VERIFY_INVITE_CODE_SIGN_UP = 'VERIFY_INVITE_CODE_SIGN_UP',
  VERIFY_EMAIL_EXISTS = 'VERIFY_EMAIL_EXISTS',
  VERIFY_INVITE_CODE_SIGN_UP_RESET = 'VERIFY_INVITE_CODE_SIGN_UP_RESET'
}

const InviteCodeSignupActions = {
  verifyInviteCodeSignUp: (email: string, inviteCode: string) =>
    createAction(InviteCodeSignUpActionTypes.VERIFY_INVITE_CODE_SIGN_UP, {
      email,
      inviteCode
    }),
  verifyEmailExists: () => createAction(InviteCodeSignUpActionTypes.VERIFY_EMAIL_EXISTS),
  reset: () => createAction(InviteCodeSignUpActionTypes.VERIFY_INVITE_CODE_SIGN_UP_RESET)
};

type InviteCodeSignUpUnionType =
  | ActionWithPayload<
      InviteCodeSignUpActionTypes.VERIFY_INVITE_CODE_SIGN_UP,
      InviteCodeSignUpPayload
    >
  | Action<InviteCodeSignUpActionTypes.VERIFY_EMAIL_EXISTS>
  | Action<InviteCodeSignUpActionTypes.VERIFY_INVITE_CODE_SIGN_UP_RESET>;

export { InviteCodeSignUpActionTypes, InviteCodeSignUpUnionType, InviteCodeSignupActions };
