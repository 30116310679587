import { select } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

export function* handleDidNavigateToSSOLoginFailure(error: string): any {
  const token: ReturnType<typeof UserSelectors.getToken> = yield select<LxStoreState>(
    UserSelectors.getToken
  );
  if (!!token) {
    return window.__router.navigate('home', {});
  }

  ErrorLogger.captureEvent('SSO Login Failure', 'error', { error });
}
