import type { ConferencesState } from './types';

export const initialConferencesState: Readonly<ConferencesState> = {
  byId: {},
  upcoming: {
    items: [],
    totalCount: 0
  },
  isLoading: false,
  loadingError: '',
  loadingErrorCode: undefined
};
