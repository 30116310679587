import { select, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { Tracker } from '@maggie/core/tracker/tracker';
import type { LxStoreState } from '@maggie/store/types';

import { LessonActionTypes } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonAction } from './types';
import type { LessonType } from './types';

function* handleUpdateLessonCompleted(
  action: LessonAction<LessonActionTypes.UPDATE_LESSON_COMPLETED>
) {
  const { lessonId, score } = action.payload;

  const lesson: LessonType = yield select<LxStoreState>(s =>
    LessonSelectors.getLesson(lessonId, s)
  );
  if (!lesson) {
    ErrorLogger.captureEvent('UPDATE_LESSON_COMPLETED: lesson doesnt exist?', 'warning', {
      lessonId
    });
    return;
  }

  Tracker.trackEvent({
    name: 'lesson-completed',
    value: lesson.title,
    id: lesson.id,
    courseVersionNumber: lesson.courseVersionNumber,
    locale: lesson.locale,
    score,
    type: 'event'
  });
  itly.lessonCompleted({
    value: lesson.title,
    id: lesson.id,
    score
  });
}

export function* watchUpdateLessonCompleted() {
  yield takeLatest(LessonActionTypes.UPDATE_LESSON_COMPLETED, handleUpdateLessonCompleted);
}
