import { createAction } from '@edapp/utils';

export enum StarActionTypes {
  FETCH_STAR_BALANCE = 'FETCH_STAR_BALANCE',
  SET_STAR_BALANCE = 'SET_STAR_BALANCE',
  FETCH_STAR_BALANCE_FAILURE = 'FETCH_STAR_BALANCE_FAILURE',
  REWARD_STARS_FROM_SLIDE = 'REWARD_STARS_FROM_SLIDE',
  FETCH_DAILY_REWARD = 'FETCH_DAILY_REWARD',
  FETCH_DAILY_REWARD_SUCCESS = 'FETCH_DAILY_REWARD_SUCCESS',
  FETCH_DAILY_REWARD_FAILURE = 'FETCH_DAILY_REWARD_FAILURE',
  CLOSE_DAILY_REWARD = 'CLOSE_DAILY_REWARD',
  SPEND_STARS = 'SPEND_STARS'
}

export const StarActions = {
  fetchStarBalance: () => createAction(StarActionTypes.FETCH_STAR_BALANCE),
  setStarBalance: (balance: number) => createAction(StarActionTypes.SET_STAR_BALANCE, { balance }),
  rewardStarsFromSlide: (
    courseId: string,
    lessonId: string,
    slideId: string,
    starsToReward: number,
    starsScored: number,
    totalStars: number
  ) =>
    createAction(StarActionTypes.REWARD_STARS_FROM_SLIDE, {
      stars: starsToReward,
      courseId,
      lessonId,
      slideId,
      starsScored,
      totalStars
    }),
  closeDailyReward: () => createAction(StarActionTypes.CLOSE_DAILY_REWARD),
  spendStars: (stars: number) => createAction(StarActionTypes.SPEND_STARS, { stars })
};
