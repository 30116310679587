import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import { RapidRefreshActionTypes } from './actions';
import { initialRapidRefreshesState } from './constants';
import type { RapidRefreshActionsUnionType, RapidRefreshesState } from './types';

export const rapidRefreshesReducer = (
  state: RapidRefreshesState = initialRapidRefreshesState,
  action: RapidRefreshActionsUnionType
): RapidRefreshesState => {
  switch (action.type) {
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST: {
      return { ...state, loading: true, errorCode: undefined, error: '' };
    }
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST_SUCCESS: {
      const { rapidRefreshData, page } = action.payload;
      return {
        ...state,
        loading: false,
        error: '',
        errorCode: undefined,
        totalCount: rapidRefreshData.totalCount,
        items:
          page === 1
            ? [...rapidRefreshData.items]
            : [...(state.items || []), ...rapidRefreshData.items]
      };
    }
    case RapidRefreshActionTypes.RESET_RAPID_REFRESH_ITEMS:
      return {
        ...state,
        totalCount: 0,
        items: []
      };
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST_FAILURE: {
      const message = getErrorMessage(ErrorCode.NullResponse);
      return { ...state, loading: false, errorCode: ErrorCode.NullResponse, error: message };
    }

    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM: {
      return { ...state, loading: true, error: '', errorCode: undefined };
    }
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_SUCCESS: {
      const item = action.payload;
      return {
        ...state,
        loading: false,
        error: '',
        errorCode: undefined,
        items: [item],
        totalCount: 1
      };
    }
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_FAILURE: {
      const message = getErrorMessage(ErrorCode.NullResponse);
      return { ...state, loading: false, error: message, errorCode: action.payload?.code };
    }

    case RapidRefreshActionTypes.SET_RAPID_REFRESH_SUMMARY_DIALOG_OPEN: {
      const { open } = action.payload;
      return {
        ...state,
        summaryDialogOpen: open,
        currentScore: open ? state.currentScore : initialRapidRefreshesState.currentScore,
        currentInteractions: open
          ? state.currentInteractions
          : initialRapidRefreshesState.currentInteractions
      };
    }

    case RapidRefreshActionTypes.COMPLETE_RAPID_REFRESH: {
      const { interactions } = action.payload;

      return {
        ...state,
        currentInteractions: [...interactions]
      };
    }

    case RapidRefreshActionTypes.UPDATE_RAPID_REFRESH_SCORE: {
      const { score } = action.payload;

      return {
        ...state,
        currentScore: score
      };
    }

    case RapidRefreshActionTypes.DID_COMPLETE_RAPID_REFRESH: {
      const { courseId, session } = action.payload;
      // remove completed rapid refresh from data store since user cannot complete again
      const newItems = state.items.filter(
        i => i.courseId !== courseId || i.currentSession !== session
      );
      return { ...state, items: [...newItems] };
    }

    default:
      return state;
  }
};
