const scUserLocalStorageKey = 'sc_user_id';

const set = (id: string) => window.localStorage.setItem(scUserLocalStorageKey, id);

const get = () => window.localStorage.getItem(scUserLocalStorageKey);

export const SCUserId = {
  get,
  set
};
