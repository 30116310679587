import type { LxStoreState } from '@maggie/store/types';

import { CourseSelectors } from '../courses/selectors';
import type { PlaylistItemType, PlaylistSummaryType } from './types';

const getPlaylistCourseIds = (playlist: PlaylistItemType) => playlist.courses.map(c => c.courseId);

const getPlaylistPrerequisitesIds = (playlist: PlaylistItemType) => {
  let prerequisiteIds: string[] = [];
  playlist.courses.forEach(c => {
    const ids = CourseSelectors.getPrerequisitesIds(c) ?? [];
    prerequisiteIds = [...prerequisiteIds, ...ids];
  });
  return prerequisiteIds;
};

/**
 * Get playlist completion date based on most recent course completion date
 */
const getPlaylistCompletionDate = (
  completedPlaylist: PlaylistSummaryType,
  state: LxStoreState
): Date | null => {
  let latestCourseCompletedDate: Date | null = null;

  completedPlaylist.courseIds.forEach(courseId => {
    const courseProgress = CourseSelectors.getCourseProgress(courseId, state);
    const completedDate = courseProgress?.completedDate;
    const isCourseCompleted = courseProgress?.completed;

    const courseCompletedDate = completedDate ? new Date(completedDate) : null;

    if (
      !latestCourseCompletedDate ||
      (courseCompletedDate && courseCompletedDate > latestCourseCompletedDate && isCourseCompleted)
    ) {
      latestCourseCompletedDate = courseCompletedDate;
    }
  });

  return latestCourseCompletedDate;
};

const mapPlaylistsToPlaylistSummaries = (playlists: PlaylistItemType[]): PlaylistSummaryType[] => {
  return playlists.map(p => ({
    id: p.id,
    title: p.title,
    description: p.description,
    isPublished: p.isPublished,
    courseCount: p.courseCount,
    coverImage: p.coverImage,
    coverImageTextColor: p.coverImageTextColor,
    isCompleted: p.isCompleted,
    courseIds: p.courses.map(course => course.courseId),
    lastActivity: '',
    lexoRank: p.lexoRank,
    playlistType: p.playlistType
  }));
};

export const PlaylistUtils = {
  getPlaylistCourseIds,
  getPlaylistPrerequisitesIds,
  getPlaylistCompletionDate,
  mapPlaylistsToPlaylistSummaries
};
