import type { LotteryType } from './types';
import type { GameActionsUnionType } from '../types';
import { GameActionTypes } from '../actions';

const initialLotteryState: LotteryType = {
  enableSpinToWin: false,
  gameType: '',
  starBar: {
    id: '',
    active: false,
    description: '',
    gameType: '',
    gameId: null,
    image: '',
    isDefault: false,
    name: ''
  }
};

const lotteryReducer = (
  state: LotteryType = initialLotteryState,
  action: GameActionsUnionType
): LotteryType => {
  switch (action.type) {
    case GameActionTypes.FETCH_GAME_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload.lottery
      };

    default:
      return state;
  }
};

export { lotteryReducer, initialLotteryState };
