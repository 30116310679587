import type { AuthorProfileActionsUnionType } from './actions';
import { AuthorProfileActionTypes as actions } from './actions';

import type { AuthorProfileState } from './types';

export const initialAuthorProfileState: AuthorProfileState = {
  profile: {
    id: '',
    companyName: '',
    website: '',
    industry: '',
    location: '',
    overview: '',
    extendedBio: '',
    logo: '',
    logoBackgroundColor: 'navy',
    isSponsored: false,
    socialMedia: {
      linkedin: '',
      facebook: '',
      instagram: '',
      youtube: '',
      twitter: ''
    }
  },
  fetchAuthorProfileLoading: true,
  fetchAuthorProfileError: '',
  mutateAuthorProfileLoading: false,
  mutateAuthorProfileError: '',
  authorProfiles: {}
};

export const authorProfileReducer = (
  state: AuthorProfileState = initialAuthorProfileState,
  action: AuthorProfileActionsUnionType
) => {
  switch (action.type) {
    case actions.FETCH_AUTHOR_PROFILE: {
      return { ...state, fetchAuthorProfileLoading: true, fetchAuthorProfileError: '' };
    }
    case actions.FETCH_AUTHOR_PROFILE_SUCCESS: {
      const authorProfile = action.payload;
      return {
        ...state,
        fetchAuthorProfileLoading: false,
        fetchAuthorProfileError: '',
        profile: { ...authorProfile }
      };
    }
    case actions.FETCH_AUTHOR_PROFILE_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        fetchAuthorProfileLoading: false,
        fetchAuthorProfileError: message
      };
    }

    case actions.FETCH_AUTHOR_PROFILES: {
      return { ...state, fetchAuthorProfileLoading: true, fetchAuthorProfileError: '' };
    }

    case actions.FETCH_AUTHOR_PROFILES_SUCCESS: {
      return {
        ...state,
        fetchAuthorProfileLoading: false,
        fetchAuthorProfileError: '',
        authorProfiles: {
          ...state.authorProfiles,
          ...action.payload.authorProfiles
        }
      };
    }

    case actions.FETCH_AUTHOR_PROFILES_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        fetchAuthorProfileLoading: false,
        fetchAuthorProfileError: message
      };
    }

    case actions.SAVE_AUTHOR_PROFILE: {
      const data = action.payload;

      const currentProfile = state.profile;

      if (!currentProfile) {
        return state;
      }

      return {
        ...state,
        mutateAuthorProfileLoading: true,
        mutateAuthorProfileError: '',
        profile: { currentProfile, ...data }
      };
    }
    case actions.SAVE_AUTHOR_PROFILE_SUCCESS: {
      return { ...state, mutateAuthorProfileLoading: false, mutateAuthorProfileError: '' };
    }
    case actions.SAVE_AUTHOR_PROFILE_FAILURE: {
      const { error, authorProfileStateBeforeSave } = action.payload;

      const currentProfile = state.profile;

      if (!currentProfile) {
        return state;
      }

      return {
        ...state,
        mutateAuthorProfileLoading: false,
        mutateAuthorProfileError: error.message,
        profile: { ...currentProfile, ...authorProfileStateBeforeSave }
      };
    }

    default:
      return state;
  }
};
