import { t } from 'i18next';

import { ErrorLogger } from '@edapp/monitoring';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { ActionDialogActions } from '@maggie/store/action-dialog/actions';
import type { ConfigurationType } from '@maggie/store/courseware/lessons/types';
import { LessonDialogActions } from '@maggie/store/lesson-dialog/actions';
import { LOGGED_OUT_ELSEWHERE } from '@maggie/store/session/local-storage-listener';

import { eventManager } from '../event-manager';
import { ThomasBridge } from '../thomas/thomas-bridge';
import { slidedeckPrefix } from '../thomas/utils';
import { ThomasPlayerMemo } from './lesson-player-memo';
import { ThomasAssets } from './thomas-assets';
import type { ContextForThomas, IThomasPlayer } from './thomas-player-interface';

export abstract class ThomasPlayer implements IThomasPlayer {
  protected id: string;
  protected thomasBridge: ThomasBridge;
  protected thomasAssets: ThomasAssets;

  constructor(
    id: string,
    configuration: ConfigurationType,
    thomasVersion?: string,
    iFrameParent?: string
  ) {
    this.id = id;
    this.thomasBridge = new ThomasBridge(iFrameParent || 'body');
    this.thomasAssets = new ThomasAssets(id, configuration, thomasVersion);
  }

  private initializePlayer = async () => {
    const prefix = slidedeckPrefix(this.id);

    // completion events
    eventManager.listenTo(`${prefix}:completed`, this.completed);

    // unloaded events (iframe closing without us asking for it)
    eventManager.listenTo(`${prefix}:lesson-unload`, this.onLessonUnload);

    // exit events
    // Events that will force player to close itself
    eventManager.listenTo(`${prefix}:exit`, this.close);
    eventManager.listenTo(LOGGED_OUT_ELSEWHERE, this.close);
    eventManager.listenTo('account-switch', this.close);

    const context = this.getContextForThomas();
    const url = this.thomasAssets.getUrl();
    await this.thomasBridge.openThomas(context, url);
  };

  private destroyPlayer = async () => {
    await this.thomasBridge.closeThomas(this.id);
    const prefix = slidedeckPrefix(this.id);

    // completion events
    eventManager.stopListening(`${prefix}:completed`);

    // unloaded events (iframe closing without us asking for it)
    eventManager.stopListening(`${prefix}:lesson-unload`);

    // exit events
    // Events that will force player to close itself
    eventManager.stopListening(LOGGED_OUT_ELSEWHERE);
    eventManager.stopListening('account-switch');
    eventManager.stopListening(`${prefix}:exit`);
  };

  /* istanbul ignore next */
  protected getContextForThomas = (): ContextForThomas => {
    // Should be implemented in the derived class
    throw new Error('Method not implemented.');
  };

  /**
   * In this case, completed doesn't mean succesfully.
   *
   * It means the user reached the end of the lesson.
   */
  protected completed() {
    HostedWebviewUtils.triggerEvent('lessonCompleted');
  }

  /**
   * Triggered on page unload / refresh lesson while in thomas
   * After lesson-unload -> lesson-exit get triggered
   */
  protected onLessonUnload = async () => {
    const message = t('course.lesson.closed', { ns: 'learners-experience' });
    const confirm = t('course.lesson.ok', { ns: 'learners-experience' });
    window.__store.dispatch(ActionDialogActions.openActionDialog(undefined, message, confirm));
    await this.close();
  };

  public async open() {
    window.__store.dispatch(
      LessonDialogActions.openLessonLoadingDialog(
        t('loader.loading', { ns: 'learners-experience' })
      )
    );

    // memoize player
    ThomasPlayerMemo.setPlayer(this.id, this);

    try {
      await this.initializePlayer();
    } catch (e) {
      ErrorLogger.captureEvent('Failed to initialize player', 'fatal', { e });

      await this.destroyPlayer();
      throw e;
    } finally {
      window.__store.dispatch(LessonDialogActions.closeLessonLoadingDialog());
    }
  }

  public async close() {
    await this.destroyPlayer();

    // delete the memoized player to ensure next time the bridge iframe.contentWindow is reloaded
    if (ThomasPlayerMemo.hasPlayer(this.id)) {
      ThomasPlayerMemo.deletePlayer(this.id);
    }
  }

  public goToPage(pageIndex: number) {
    this.thomasBridge.goToPage(pageIndex);
  }

  public toggleHeader(mode: 'desktop' | 'mobile') {
    this.thomasBridge.toggleHeader(mode);
  }
}
