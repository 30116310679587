import { getUnixTime } from 'date-fns';
import * as _ from 'lodash';

import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';
import { MandatoryTypes } from '@maggie/store/courseware/courses/constants';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { MandatoryDueByType, MandatoryType } from '@maggie/store/courseware/courses/types';
import type { LxStoreState } from '@maggie/store/types';

export type MandatoryAccumulator<Entity> = {
  mandatory: { dueDate: Entity[]; noDueDate: Entity[] };
  nonMandatory: Entity[];
};

export const splitMandatory = <Entity>(
  extractData: (entity: Entity) => { complete: boolean; mandatory: MandatoryType } | null
) => (acc: MandatoryAccumulator<Entity>, entity: Entity): MandatoryAccumulator<Entity> => {
  const data = extractData(entity);

  if (!!data?.mandatory.enabled && !data?.complete) {
    if (data.mandatory.type === MandatoryTypes.DueBy) {
      acc.mandatory.dueDate.push(entity);
    } else {
      acc.mandatory.noDueDate.push(entity);
    }
  } else {
    acc.nonMandatory.push(entity);
  }

  return acc;
};

// Step 1 => Split mandatory from non-mandatory courses
// Step 2 => Sort mandatory and non-mandatory courses
export const sortCourses = (courses: CourseSummaryType[], state: LxStoreState) => {
  // Step 1
  const { mandatory, nonMandatory } = courses.reduce<MandatoryAccumulator<CourseSummaryType>>(
    splitMandatory(({ courseId, planning, mandatory }) => ({
      complete: CourseSelectors.getCourseStatus(courseId, planning, state).id === 'complete',
      mandatory
    })),
    { mandatory: { dueDate: [], noDueDate: [] }, nonMandatory: [] }
  );

  // Step 2
  const sortedMandatoryDueDate = _.sortBy(mandatory.dueDate, [
    ({ mandatory }) => getUnixTime(new Date((mandatory as MandatoryDueByType).date))
  ]);
  const sortedMandatoryNoDueDate = _.sortBy(mandatory.noDueDate, ['collectionRank ']);
  const sortedNonMandatory = _.sortBy(nonMandatory, ['collectionRank']);

  return [...sortedMandatoryDueDate, ...sortedMandatoryNoDueDate, ...sortedNonMandatory];
};
