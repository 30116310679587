import { CourseActionTypes } from '@maggie/store/courseware/courses/actions';
import type { CourseActionsUnionType } from '@maggie/store/courseware/courses/types';

import { BannersActionTypes } from './actions';
import type { BannersActionsUnionType, BannersState } from './types';

export const initialBannersState: BannersState = {
  items: [],
  isLoading: false
};

export const bannersReducer = (
  state: BannersState = initialBannersState,
  action: BannersActionsUnionType | CourseActionsUnionType
) => {
  switch (action.type) {
    case BannersActionTypes.FETCH_BANNERS:
      return {
        ...state,
        isLoading: true
      };

    case BannersActionTypes.FETCH_BANNERS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isLoading: false
      };

    case BannersActionTypes.FETCH_BANNERS_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case CourseActionTypes.UPDATE_COURSE_COMPLETED:
      const { courseId, completed } = action.payload;
      if (!completed) {
        return state;
      }

      return {
        ...state,
        items: state.items.filter(b => b.courseId !== courseId)
      };
    default:
      return state;
  }
};
