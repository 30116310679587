// @returns false if the value is not valid
type ValidateFnType = (value: string) => boolean;

/**
 * This function is used to get a value from the query params or from the local storage.
 *
 * If a value is provided from a query string, it will be persisted.
 */
export const queryParamAccessor = <T extends string | undefined>(
  queryParam: string,
  localStorageKey: string,
  validateFn?: ValidateFnType
): T | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, searchFromHash] = window.location.hash?.split('?') || [];
  const queryString = window.location.search || (searchFromHash ?? `?${searchFromHash}`);
  const urlParams = new URLSearchParams(queryString);
  const value = urlParams.get(queryParam);

  if (validateFn && value && !validateFn(value)) {
    return undefined; // not valid
  }

  if (value) {
    window.localStorage.setItem(localStorageKey, value);
    return value as T;
  }

  return window.localStorage.getItem(localStorageKey) as T;
};
