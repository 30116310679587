export const isDateInstance = (date: any): boolean => date instanceof Date;

export const isDateType = (date: any): boolean =>
  Object.prototype.toString.call(date) === '[object Date]';

export const isValidDateString = (date: any): boolean =>
  !!date ? date.toString() !== 'Invalid Date' : false;

export const addDaysImmutably = (date: Date, addend: number) => {
  const result = new Date(date);
  result.setDate(date.getDate() + addend);
  return result;
};
