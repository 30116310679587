// Reduce validator functions left to right and show the first error message
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const specialCharacter = regex(
  /[~`!@#$%^&*()-+={[}\]|\\:;"'<,>.?/_\d]/,
  'One number or special character'
);
const lowercase = regex(/[a-z]/, 'One lowercase letter');
const uppercase = regex(/[A-Z]/, 'One uppercase letter');

function composeValidators(
  ...validators: Array<(value: string, allValues?: object) => string | undefined>
) {
  // Fall back empty string as <input> could pass undefined when backspace value
  return (value: string = '', allValues?: object) =>
    validators.reduce(
      (error: string | undefined, validator) => error ?? validator(value, allValues),
      undefined
    );
}

// Check for presence of value
function required(value?: string) {
  return value?.trim() ? undefined : 'This field is required';
}

function notEmpty(message: string = 'Please fill out this field.') {
  return (value: string) => (value?.trim() ? undefined : message);
}

function minLength(minLength: number) {
  return (value: string) =>
    value.length >= minLength ? undefined : `${minLength} characters minimum`;
}

function maxLength(maxLength: number) {
  return (value: string) =>
    value.length <= maxLength ? undefined : `${maxLength} characters maximum`;
}

// Accepts an an RegExp object and provides a way to display a custom message if check fails
function regex(regex: RegExp, message?: string) {
  return (value: string) =>
    regex.test(value) ? undefined : message ?? 'Please enter a valid value.';
}

function email(value: string) {
  return EMAIL_REGEX.test(value) ? undefined : 'Please enter a valid email address.';
}

// Check for string equality against a different form field
function match(fieldName: string, message?: string) {
  return (value: string, allValues?: Record<string,unknown>) =>
    value === allValues?.[fieldName] ? undefined : message ?? 'Values must match.';
}

function postCodeAU(value: string) {
  if (/^\d{4}$/.test(value)) {
    return undefined;
  } else {
    return 'Please, enter a valid 4-digit AU Zip Code.';
  }
}

function postCodeUS(value: string) {
  if (/^\d{5}$/.test(value)) {
    return undefined;
  } else {
    return 'Please, enter a valid 5-digit US Zip Code.';
  }
}

/**
 * A validator does not allow . or / characters for the email subject,
 * and the length is less than 320 characters.
 */
function isValidEmailSubject(value?: string) {
  return value?.includes('.') || value?.includes('/') || (value && value.length > 320)
    ? 'Please enter an email subject without special characters.'
    : undefined;
}

const password = composeValidators(
  minLength(8),
  maxLength(50),
  specialCharacter,
  lowercase,
  uppercase
);

const validators = {
  required,
  notEmpty,
  minLength,
  maxLength,
  regex,
  email,
  match,
  password,
  specialCharacter,
  lowercase,
  uppercase,
  postCodeAU,
  postCodeUS,
  isValidEmailSubject
};

export { composeValidators, validators };
