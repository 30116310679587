import { fork, put, race, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';

import { RatingDialogActionTypes, RatingDialogActions } from './actions';
import type { RatingDialogActionUnionType } from './types';

type RatingDialogAction<ActionType extends string> = ActionFromActionType<
  RatingDialogActionUnionType,
  ActionType
>;

function* handleSetCourseRating(
  action: RatingDialogAction<RatingDialogActionTypes.SET_COURSE_RATING>
) {
  const { courseId, rating, review } = action.payload;

  yield put(
    RequestActions.postAuthed(
      Urls.COURSE_RATING(courseId),
      RatingDialogActionTypes.SET_COURSE_RATING_SUCCESS,
      RatingDialogActionTypes.SET_COURSE_RATING_FAILURE,
      undefined,
      {
        rating,
        review
      }
    )
  );

  const {
    success,
    failure
  }: {
    success: RatingDialogActionTypes.SET_COURSE_RATING_SUCCESS;
    failure: RatingDialogActionTypes.SET_COURSE_RATING_FAILURE;
  } = yield race({
    success: take(RatingDialogActionTypes.SET_COURSE_RATING_SUCCESS),
    failure: take(RatingDialogActionTypes.SET_COURSE_RATING_FAILURE)
  });

  if (failure) {
    // handle error?
    yield put(RatingDialogActions.closeRatingDialog());
  }

  if (success) {
    yield put(RatingDialogActions.closeRatingDialog());
  }
}

function* watchSetCourseRating() {
  yield takeLatest(RatingDialogActionTypes.SET_COURSE_RATING, handleSetCourseRating);
}

export const ratingDialogSagas = [fork(watchSetCourseRating)];
