import colors from '@maggie/theme/colors';

export const config = {
  prizing: {
    id: '1',
    days: [1, 4, 9, 16]
  },

  content: {
    id: '2',
    days: [1, 3, 7, 13]
  },

  android: {
    icon: 'res://icon.png',
    smallIcon: 'res://icon.png',
    led: colors.cardBlue.replace(/#/, '')
  },

  push: {
    android: {
      senderID: '937801883023',
      sound: true,
      vibrate: true,
      iconColor: colors.cardBlue
    },
    ios: {
      badge: true,
      alert: true,
      sound: true,
      clearBadge: true
    }
  },

  registerAttempts: 3
};
