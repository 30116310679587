import { put, select, takeLatest } from 'redux-saga/effects';

import { ErrorLogger } from '@edapp/monitoring';
import type { LxStoreState } from '@maggie/store/types';

import { CourseActions } from '../courses/actions';
import { LessonActionTypes, LessonActions } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonAction } from './types';

function* handleAttemptLesson(action: LessonAction<LessonActionTypes.FINISH_ATTEMPT_LESSON>) {
  const { lessonId, attempt } = action.payload;

  if (attempt.successful) {
    yield put(LessonActions.updateLessonCompleted(lessonId, attempt.score));
  } else {
    yield put(LessonActions.updateLessonFailed(lessonId, attempt.score));
  }

  // Calculate course
  const courseId: string | undefined = yield select<LxStoreState>(
    s => LessonSelectors.getLesson(lessonId, s)?.courseId
  );

  if (!courseId) {
    ErrorLogger.captureEvent(
      'ATTEMPT_LESSON: Could not find course for the completed lesson. Skipping course calculation',
      'error',
      {
        lessonId,
        courseId
      }
    );
  } else {
    yield put(CourseActions.calculateCourseCompletion(courseId));
  }
}

export function* watchAttemptLesson() {
  yield takeLatest(LessonActionTypes.FINISH_ATTEMPT_LESSON, handleAttemptLesson);
}
