import { css } from 'styled-components';
import type { PropertiesHyphen } from 'csstype';
import type { ThemeSpace } from '../../space';
import { getSpaceValue } from '../../space';
import type {
  MarginProp,
  MarginXProp,
  MarginYProp,
  MarginTopProp,
  MarginRightProp,
  MarginBottomProp,
  MarginLeftProp,
  PaddingProp,
  PaddingXProp,
  PaddingYProp,
  PaddingTopProp,
  PaddingRightProp,
  PaddingBottomProp,
  PaddingLeftProp,
  MarginsProps,
  PaddingsProps,
  SpaceProps
} from './types';

type Rule = keyof PropertiesHyphen;

function getStyle(rule: Rule, style: string) {
  return `${rule}: ${style};`;
}

function applySpace(rule: Rule | Rule[], space?: ThemeSpace) {
  if (space === undefined) {
    return undefined;
  }

  const style = `${getSpaceValue(space)}px`;
  return Array.isArray(rule)
    ? rule.reduce<string>((acc, cur) => `${acc} ${getStyle(cur, style)}`, '')
    : getStyle(rule, style);
}

export const margin = css<MarginProp>(({ m }) => applySpace('margin', m));
export const marginX = css<MarginXProp>(({ mx }) =>
  applySpace(['margin-left', 'margin-right'], mx)
);
export const marginY = css<MarginYProp>(({ my }) =>
  applySpace(['margin-top', 'margin-bottom'], my)
);
export const marginTop = css<MarginTopProp>(({ mt }) => applySpace('margin-top', mt));
export const marginRight = css<MarginRightProp>(({ mr }) => applySpace('margin-right', mr));
export const marginBottom = css<MarginBottomProp>(({ mb }) => applySpace('margin-bottom', mb));
export const marginLeft = css<MarginLeftProp>(({ ml }) => applySpace('margin-left', ml));

export const margins = css<MarginsProps>`
  ${margin}
  ${marginX}
  ${marginY}
  ${marginTop}
  ${marginRight}
  ${marginBottom}
  ${marginLeft}
`;

export const padding = css<PaddingProp>(({ p }) => applySpace('padding', p));
export const paddingX = css<PaddingXProp>(({ px }) =>
  applySpace(['padding-left', 'padding-right'], px)
);
export const paddingY = css<PaddingYProp>(({ py }) =>
  applySpace(['padding-top', 'padding-bottom'], py)
);
export const paddingTop = css<PaddingTopProp>(({ pt }) => applySpace('padding-top', pt));
export const paddingRight = css<PaddingRightProp>(({ pr }) => applySpace('padding-right', pr));
export const paddingBottom = css<PaddingBottomProp>(({ pb }) => applySpace('padding-bottom', pb));
export const paddingLeft = css<PaddingLeftProp>(({ pl }) => applySpace('padding-left', pl));

export const paddings = css<PaddingsProps>`
  ${padding}
  ${paddingX}
  ${paddingY}
  ${paddingTop}
  ${paddingRight}
  ${paddingBottom}
  ${paddingLeft}
`;

export const space = css<SpaceProps>`
  ${margins}
  ${paddings}
`;
