import type { LxStoreState } from '@maggie/store/types';

import type { Flags } from './constants';

const getFlag = (flag: Flags, state: LxStoreState = window.__store.getState()) =>
  state.featureFlags.flags[flag];

const getFlags = (state: LxStoreState) => state.featureFlags.flags;

const getFlagsEnabled = (state: LxStoreState) =>
  Object.entries(state.featureFlags.flags).reduce(
    (acc, [key, val]) => ({ ...acc, [key]: val.enabled }),
    {}
  );

const isFlagEnabled = (flag: Flags, state: LxStoreState = window.__store.getState()) =>
  getFlag(flag, state)?.enabled;

/**
 * This returns the state if feature flags have always been retrieved.
 * We just need to know if there is any state in the datastore.
 * After it's retrieved once, it's likely that this will always return true.
 */
const getIsFetched = (state: LxStoreState) => state.featureFlags.fetched || !state.config.isOnline;

export const FeatureFlagsSelectors = {
  getFlag,
  getFlags,
  getIsFetched,
  getFlagsEnabled,
  isFlagEnabled
};
