import { t } from 'i18next';
import { all, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import type { RequestTypes } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { CourseActions } from '@maggie/store/courseware/courses/actions';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { CourseType } from '@maggie/store/courseware/courses/types';
import { ToastActions } from '@maggie/store/toast/actions';
import type { LxStoreState } from '@maggie/store/types';

import { CourseLanguageSelectors } from '../course-language/selectors';
import { LessonActionTypes, LessonActions } from '../lessons/actions';
import type { DictionaryPostComments } from '../types';
import { WatchStatus } from '../types';
import { DiscussionActionTypes, DiscussionActions } from './actions';
import { DiscussionSelectors } from './selectors';
import type {
  DiscussionActionsUnionType,
  DiscussionPostCommentType,
  DiscussionPostType,
  DiscussionProgress,
  DiscussionType
} from './types';

export type DiscussionAction<ActionType extends string> = ActionFromActionType<
  DiscussionActionsUnionType,
  ActionType
>;

function* handleFetchProgress(
  action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS>
) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed<DiscussionProgress>(
      Urls.DISCUSSION_PROGRESS(id),
      data => DiscussionActions.fetchProgressSuccess(id, data),
      DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS_FAILURE
    )
  );
}

function* handleFetchDiscussionWithProgress(
  action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS>
): any {
  const { id } = action.payload;

  yield all([
    put(DiscussionActions.fetchDiscussion(id)),
    put(DiscussionActions.fetchProgress(id)),
    put(LessonActions.fetchLessonsProgress([id]))
  ]);

  type SagaResultType = {
    success: {
      discussion: {
        payload: DiscussionType;
      };
    };
    failure: {};
  };

  const { success, failure }: SagaResultType = yield race({
    success: all({
      discussion: take(DiscussionActionTypes.FETCH_DISCUSSION_SUCCESS),
      progress: take(DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS_SUCCESS),
      lessonProgress: take(LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS)
    }),
    failure: race({
      discussion: take(DiscussionActionTypes.FETCH_DISCUSSION_FAILURE),
      progress: take(DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS_FAILURE),
      lessonProgress: take(LessonActionTypes.FETCH_LESSON_PROGRESS_FAILURE)
    })
  });

  if (!!failure) {
    return yield put(DiscussionActions.fetchDiscussionWithProgressFailure());
  }

  const { payload: discussion } = success.discussion;

  yield put(LessonActions.fetchLessonsProgress(discussion.prerequisites, true));

  const { type: completedActionType } = yield take([
    LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS,
    LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_FAILURE
  ]);

  if (completedActionType === LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS) {
    yield put(DiscussionActions.fetchDiscussionWithProgressSuccess(discussion));
  } else {
    yield put(DiscussionActions.fetchDiscussionWithProgressFailure());
  }
}

function* handleFetchDiscussion(action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION>) {
  const { id } = action.payload;
  const courseLanguageCode: string | undefined = yield select<LxStoreState>(s =>
    CourseLanguageSelectors.getSelectedLanguageOfCourse(s)
  );

  yield put(
    RequestActions.getAuthed(
      Urls.DISCUSSION_ITEM(id),
      DiscussionActionTypes.FETCH_DISCUSSION_SUCCESS,
      DiscussionActionTypes.FETCH_DISCUSSION_FAILURE,
      undefined,
      { locale: courseLanguageCode }
    )
  );
}

function* handleFetchDiscussionSuccess(
  action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_SUCCESS>
) {
  const { courseId } = action.payload;
  const parentCourse: CourseType | undefined = yield select<LxStoreState>(state =>
    CourseSelectors.getCourse(courseId, state)
  );

  if (!parentCourse) {
    yield put(CourseActions.fetchSyncCourse(courseId));
  }
}

function* handleFetchPosts(action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_POSTS>) {
  const { discussionId, page } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<DiscussionPostType>>(
      Urls.APP_DISCUSSION_POSTS(discussionId),
      data => DiscussionActions.fetchPostsSuccess(page, discussionId, data),
      DiscussionActionTypes.FETCH_DISCUSSION_POSTS_FAILURE,
      undefined,
      {
        page,
        pageSize: 20
      }
    )
  );
}

function* handleFetchPostsSuccess(
  action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_POSTS_SUCCESS>
) {
  const { discussionId, postsData } = action.payload;
  const postIds = postsData.items.map(post => post.id);

  yield put(DiscussionActions.fetchMultiplePostsComments(discussionId, postIds));
}

function* handleFetchManyPostsComments(
  action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS>
) {
  const { discussionId, postIds } = action.payload;

  yield put(
    RequestActions.getAuthed<DictionaryPostComments>(
      Urls.DISCUSSION_POSTS_COMMENTS(discussionId),
      DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS_SUCCESS,
      DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS_FAILURE,
      undefined,
      { page: 1, pageSize: 20, postIds }
    )
  );
}

function* handleFetchPostComments(
  action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_POST_COMMENTS>
) {
  const { discussionId, postId, page, created } = action.payload;

  yield put(
    RequestActions.getAuthed<RequestTypes.PaginatedResponse<DiscussionPostCommentType>>(
      Urls.DISCUSSION_POST_COMMENTS(discussionId, postId),
      data => DiscussionActions.fetchPostCommentsSuccess(postId, page, data),
      DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT_FAILURE,
      undefined,
      { pageSize: 20, page, created }
    )
  );
}

function* handleFetchPostItem(
  action: DiscussionAction<DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM>
) {
  const { discussionId, postId } = action.payload;

  yield put(
    RequestActions.getAuthed<DiscussionPostType>(
      Urls.APP_DISCUSSION_POST_ITEM(discussionId, postId),
      data => DiscussionActions.fetchPostItemSuccess(discussionId, data),
      DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM_FAILURE,
      undefined
    )
  );
}

function* handleEditPostSubscription(
  action: DiscussionAction<DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION>
) {
  const { discussionId, postId, isWatching } = action.payload;

  yield put(
    RequestActions.patchAuthed(
      Urls.DISCUSSIONS_POST_SUBSCRIPTIONS(postId),
      () => DiscussionActions.editPostSubscriptionSuccess(discussionId, postId, isWatching),
      DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION_FAILURE,
      undefined,
      { isWatching }
    )
  );

  const { success, failure } = yield race({
    success: take(DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION_SUCCESS),
    failure: take(DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION_FAILURE)
  });

  if (failure) {
    yield put(
      ToastActions.showToast(
        false,
        t('common.postWatching.notifications.error', { ns: 'learners-experience' })
      )
    );
  }

  if (success) {
    if (isWatching) {
      yield put(
        ToastActions.showToast(
          true,
          t('common.postWatching.notifications.enabled', { ns: 'learners-experience' })
        )
      );
    } else {
      yield put(
        ToastActions.showToast(
          false,
          t('common.postWatching.notifications.disabled', { ns: 'learners-experience' })
        )
      );
    }
  }
}

function* handleSaveComment(
  action: DiscussionAction<DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT>
) {
  const { discussionId, postId, commentText } = action.payload;

  yield put(
    RequestActions.postAuthed<DiscussionPostCommentType>(
      Urls.DISCUSSION_POST_COMMENTS(discussionId, postId),
      data => DiscussionActions.saveCommentSuccess(postId, data),
      DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT_FAILURE,
      undefined,
      { content: commentText }
    )
  );

  const { success } = yield race({
    success: take(DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT_SUCCESS),
    failure: take(DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT_FAILURE)
  });

  // only optimistically subscribe to watching if no post subscription exists yet -
  // this will set isWatching on the post to true
  if (success) {
    const commentedPost: DiscussionPostType | undefined = yield select(
      DiscussionSelectors.getPost(postId)
    );

    if (commentedPost?.watchingStatus === WatchStatus.NOT_SPECIFIED) {
      yield put(DiscussionActions.editPostSubscriptionSuccess(discussionId, postId, true));
    }
  }
}

function* handleSavePost(action: DiscussionAction<DiscussionActionTypes.SAVE_DISCUSSION_POST>) {
  const { discussionId, textContent, media } = action.payload;

  yield put(
    RequestActions.postAuthed<DiscussionPostType>(
      Urls.DISCUSSION_POSTS(discussionId),
      createdPost => DiscussionActions.savePostSuccess(discussionId, createdPost),
      DiscussionActionTypes.SAVE_DISCUSSION_POST_FAILURE,
      undefined,
      { content: textContent, media }
    )
  );
}

function* handleSavePostSuccess(
  action: DiscussionAction<DiscussionActionTypes.SAVE_DISCUSSION_POST_SUCCESS>
) {
  const { discussionId, post } = action.payload;

  const isSplitView = window.__router.isSplitViewLayout('discussion');
  const isDiscussionInMain = window.__router.getMain().routeName === 'discussion';
  if (isSplitView && isDiscussionInMain) {
    window.__router.navigate('thread', { discussionId, postId: post.id });
  }
}

function* handleEditPost(action: DiscussionAction<DiscussionActionTypes.EDIT_DISCUSSION_POST>) {
  const { discussionId, postId, textContent, media } = action.payload;

  yield put(
    RequestActions.patchAuthed<DiscussionPostType>(
      Urls.DISCUSSION_POST_ITEM(discussionId, postId),
      editedPost => DiscussionActions.editPostSuccess(discussionId, editedPost),
      DiscussionActionTypes.EDIT_DISCUSSION_POST_FAILURE,
      undefined,
      { content: textContent, media }
    )
  );
}

function* watchFetchProgress() {
  yield takeLatest(DiscussionActionTypes.FETCH_DISCUSSION_PROGRESS, handleFetchProgress);
}

function* watchFetchDiscussion() {
  yield takeLatest(DiscussionActionTypes.FETCH_DISCUSSION, handleFetchDiscussion);
}

function* watchFetchDiscussionSuccess() {
  yield takeLatest(DiscussionActionTypes.FETCH_DISCUSSION_SUCCESS, handleFetchDiscussionSuccess);
}

function* watchFetchPosts() {
  yield takeLatest(DiscussionActionTypes.FETCH_DISCUSSION_POSTS, handleFetchPosts);
}

function* watchFetchPostsSuccess() {
  yield takeLatest(DiscussionActionTypes.FETCH_DISCUSSION_POSTS_SUCCESS, handleFetchPostsSuccess);
}

function* watchFetchManyPostsComments() {
  yield takeLatest(
    DiscussionActionTypes.FETCH_DISCUSSION_MANY_POSTS_COMMENTS,
    handleFetchManyPostsComments
  );
}

function* watchFetchPostComments() {
  yield takeLatest(DiscussionActionTypes.FETCH_DISCUSSION_POST_COMMENTS, handleFetchPostComments);
}

function* watchFetchPostItem() {
  yield takeLatest(DiscussionActionTypes.FETCH_DISCUSSION_POST_ITEM, handleFetchPostItem);
}

function* watchEditPostSubscription() {
  yield takeLatest(
    DiscussionActionTypes.EDIT_DISCUSSION_POST_SUBSCRIPTION,
    handleEditPostSubscription
  );
}

function* watchSaveComment() {
  yield takeLatest(DiscussionActionTypes.SAVE_DISCUSSION_POST_COMMENT, handleSaveComment);
}

function* watchSavePost() {
  yield takeLatest(DiscussionActionTypes.SAVE_DISCUSSION_POST, handleSavePost);
}

function* watchSavePostSuccess() {
  yield takeLatest(DiscussionActionTypes.SAVE_DISCUSSION_POST_SUCCESS, handleSavePostSuccess);
}

function* watchEditPost() {
  yield takeLatest(DiscussionActionTypes.EDIT_DISCUSSION_POST, handleEditPost);
}

function* watchFetchDiscussionWithProgress() {
  yield takeLatest(
    DiscussionActionTypes.FETCH_DISCUSSION_WITH_PROGRESS,
    handleFetchDiscussionWithProgress
  );
}

const discussionsSagas = [
  fork(watchFetchProgress),
  fork(watchFetchDiscussion),
  fork(watchFetchDiscussionSuccess),
  fork(watchFetchPosts),
  fork(watchFetchPostsSuccess),
  fork(watchFetchManyPostsComments),
  fork(watchFetchPostComments),
  fork(watchFetchPostItem),
  fork(watchEditPostSubscription),
  fork(watchSaveComment),
  fork(watchSavePost),
  fork(watchSavePostSuccess),
  fork(watchEditPost),
  fork(watchFetchDiscussionWithProgress)
];
export { discussionsSagas };
