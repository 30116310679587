import { fork, put, takeLatest } from 'redux-saga/effects';
import { LoadingActions } from './actions';

function* handleFailures() {
  yield put(LoadingActions.stopLoading());
}

function* watchFailures() {
  yield takeLatest((action: any) => /_FAILURE/.test(action.type), handleFailures);
}

const loadingSagas = [fork(watchFailures)];

export { loadingSagas };
