import type { RouteName } from '@maggie/core/router/types';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

const getFallbackRoute = (state: LxStoreState): RouteName => {
  if (UserSelectors.getToken(state)) {
    return 'home';
  } else {
    return 'login';
  }
};

const getPlaylistIdFromCourse = (state: LxStoreState) => state.navigation.course.from.playlistId;
const getRouteName = (state: LxStoreState) => state.navigation.route;

export const NavigationSelectors = {
  getFallbackRoute,
  getPlaylistIdFromCourse,
  getRouteName
};
