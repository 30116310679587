import type { FeatureFlagsState } from './types';
import type { Reducer } from 'redux';
import type { Flags } from './constants';
import { initialFeatureFlagsState } from './constants';
import type { FeatureFlagsActions } from './actions';
import { FeatureFlagsActionTypes } from './actions';
import type { ActionsUnion, ActionWithPayload } from '@edapp/utils';
import type { FeatureFlagsResponse } from '@edapp/feature-flags';
import { mergeFeatureFlags } from '@edapp/feature-flags';
import type { ErrorResponseType } from '@edapp/request';

export const featureFlagsReducer: Reducer<FeatureFlagsState> = (
  state = initialFeatureFlagsState,
  action:
    | ActionsUnion<typeof FeatureFlagsActions>
    | ActionWithPayload<
        FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_SUCCESS,
        FeatureFlagsResponse<Flags>
      >
    | ActionWithPayload<FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_FAILURE, ErrorResponseType>
) => {
  switch (action.type) {
    case FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_FAILURE: {
      return {
        ...state,
        fetched: true,
        error: action.payload?.message
      };
    }

    case FeatureFlagsActionTypes.FETCH_FEATURE_FLAGS_SUCCESS: {
      return {
        ...state,
        flags: mergeFeatureFlags(state.flags, action.payload, 300),
        fetched: true
      };
    }

    default:
      return state;
  }
};
