import { t } from 'i18next';

import type { CardProps as BriefcaseSummary } from '@maggie/components/common/card-list/types';
import type { StatusType } from '@maggie/components/common/card-status';
import type {
  DocumentPrereqProps,
  DocumentProgressType,
  DocumentType,
  DocumentWithProgressAndStatusType
} from '@maggie/store/courseware/documents/types';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { CourseSelectors } from '../courses/selectors';
import { LessonSelectors } from '../lessons/selectors';
import type { UnlockPayload } from '../types';

const isDocumentAvailable = (documentId: string, state: LxStoreState) => {
  const progress = getDocumentProgress(documentId, state);
  // If we haven't received progress yet, consider course as unlocked
  const unlocked = progress?.unlocked ?? false;

  return UserSelectors.isReviewer(state.user) || unlocked;
};

const getDocumentStatus = (document: DocumentType, state: LxStoreState): StatusType => {
  if (!DocumentSelectors.isDocumentAvailable(document.id, state)) {
    return {
      id: 'locked',
      text: t('course.status.locked', {
        ns: 'learners-experience'
      })
    };
  }

  return {
    id: 'active',
    text: t('course.status.active', {
      ns: 'learners-experience'
    })
  };
};

const getDocuments = (state: LxStoreState) => {
  return state.courseware.documents.documents;
};

const getDocument = (id: string, state: LxStoreState): DocumentType | undefined => {
  return state.courseware.documents.documents[id];
};

const getCourseDocumentsWithProgress = (documents: DocumentType[], state: LxStoreState) => {
  return documents.map<DocumentWithProgressAndStatusType>((document: DocumentType) => ({
    ...document,
    progress: getDocumentProgress(document.id, state),
    status: getDocumentStatus(document, state)
  }));
};

const getCourseDocumentWithProgress = (
  id: string,
  state: LxStoreState
): DocumentWithProgressAndStatusType | undefined => {
  const document = getDocument(id, state);
  if (!document) return;
  return {
    ...document,
    progress: getDocumentProgress(id, state),
    status: getDocumentStatus(document, state)
  };
};

const getDocumentProgress = (documentId: string, state: LxStoreState) => {
  return state.courseware.documents.documentsProgress[documentId];
};

const getDocumentsForCourseId = (courseId: string, state: LxStoreState) => {
  const documents = DocumentSelectors.getDocuments(state);
  return Object.values(documents).filter(doc => doc.courseId === courseId);
};

const getBriefcaseSummaryCardList = (courseId: string) => {
  return (state: LxStoreState): BriefcaseSummary[] => {
    const d = getDocumentsForCourseId(courseId, state);
    if (!d) return [];

    const documents = getCourseDocumentsWithProgress(d, state);
    return documents.map(document => {
      return {
        id: document.id,
        title: document.title,
        description: document.description,
        status: document.status
      };
    });
  };
};

const getDocumentPrerequisites =
  (courseId: string, document?: DocumentWithProgressAndStatusType) =>
  (state: LxStoreState): DocumentPrereqProps[] => {
    const course = CourseSelectors.getCourse(courseId, state);
    if (!course || !document?.prerequisites.length) return [];
    return document.prerequisites
      .map(prereqId => {
        const lesson = course?.lessonSummaries?.find(lesson => lesson.lessonId === prereqId);
        if (!lesson) return null;
        const lessonProgress = LessonSelectors.getLessonProgress(lesson.lessonId, state);
        if (lessonProgress && !lessonProgress?.completed) {
          return {
            title: lesson.title,
            prereqId: lesson.lessonId
          };
        } else {
          return null;
        }
      })
      .filter((d): d is DocumentPrereqProps => !!d);
  };

const shouldUnlockDocument = (item: DocumentProgressType) => (state: LxStoreState) => {
  if (item.unlocked) {
    return false; // already unlocked
  }

  const doc = state.courseware.documents.documents[item.briefcaseDocumentId];
  if (!doc) {
    return false;
  }

  const lessonsProgress = Object.values(state.courseware.lessons.lessonsProgress);
  return doc.prerequisites.every(pre => {
    const matchingLessonProgress = lessonsProgress.find(l => l.lessonId === pre);
    return !!(matchingLessonProgress && matchingLessonProgress.completed);
  });
};

/**
 * Returns the payload to unlock documents that had a prerequisite dependency on that lesson
 *
 * @param lessonId It's the lesson that has been completed
 * @param courseId It's the course that owns the lesson, used to find other briefcase docs
 */
const getUnlockPayloadFromPrerequisites =
  (lessonId: string, courseId: string) =>
  (state: LxStoreState): UnlockPayload[] => {
    const documents = getDocuments(state);
    const progress = LessonSelectors.getLessonsProgress(state);

    return Object.values(documents)
      .filter(doc => {
        if (doc.courseId === courseId) {
          return false;
        }

        if (doc.prerequisites.includes(lessonId)) {
          return doc.prerequisites.every(prereq => !!progress[prereq]?.completed);
        }

        return false;
      })
      .map<UnlockPayload>(doc => ({ id: doc.id, title: doc.title, unlocked: true }));
  };

const getTitle = (state: LxStoreState) => {
  const docId = state.navigation.documentId;
  const doc = getDocument(docId, state);
  return doc?.title || '';
};

export const DocumentSelectors = {
  getDocuments,
  getDocument,
  getCourseDocumentsWithProgress,
  getCourseDocumentWithProgress,
  isDocumentAvailable,
  getDocumentStatus,
  getDocumentsForCourseId,
  getBriefcaseSummaryCardList,
  getDocumentPrerequisites,
  shouldUnlockDocument,
  getUnlockPayloadFromPrerequisites,
  getTitle
};
