import Dexie from 'dexie';
import type { Interaction } from './hippo-interaction-types';

// if the schema ever needs to be changed,
// we need to keep both schemas active,
// and specify migrations.
// See http://dexie.org/docs/Tutorial/Design.
const ORIGINAL_DB_VERSION = 1;
const REMOVE_COMPOUND_INDEXES_DB_VERSION = 2;

export type UserIdentification = {
  userId: string;
  applicationId: string;
};

type InteractionElement = {
  id: string;
  toPost: Interaction;
  serverErrors: number;
  priority?: number;
  startedWorking?: number;
  order?: string;
  type?: string;
} & {
  // Like Partial<UserIdentification> but mandatory
  [key in keyof UserIdentification]: UserIdentification[key] | undefined;
};

const leftPad = (num: number) => {
  return `0000${num}`.slice(-4);
};

const orderKey = (element: InteractionElement) => {
  return `${leftPad(element.priority || 0)}-${element.toPost.timestamp}`;
};

class InteractionDatabase extends Dexie {
  elements: Dexie.Table<InteractionElement, string>;

  constructor(prefix: string) {
    super(`concurrent-queue-${prefix}`);

    this.version(ORIGINAL_DB_VERSION).stores({
      elements: '&id'
    });

    this.elements = this.table('elements');

    // This version doesn't rely on compound indexes,
    // so we can have stable support in Edge
    this.version(REMOVE_COMPOUND_INDEXES_DB_VERSION)
      .stores({
        elements: '&id, [order]'
      })
      .upgrade(tx => {
        return tx
          .table<InteractionElement, number>('elements')
          .toCollection()
          .modify(element => {
            if (element.priority || -1 < 0 || element.priority == null) {
              element.priority = 0;
            }
            return (element.order = orderKey(element));
          });
      });
  }
}

export { InteractionDatabase, leftPad, orderKey, InteractionElement };
