import { cloneDeep, pick } from 'lodash';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import type { DictionaryType } from '@edapp/utils';
import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';
import { StatusBarManager } from '@maggie/cordova/status-bar';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import type {
  ConfigurationType,
  LessonType,
  SlideEarnedAvailableStarsType
} from '@maggie/store/courseware/lessons/types';
import { FeatureFlagsSelectors } from '@maggie/store/feature-flags/selectors';
import { SlideProgressSelectors } from '@maggie/store/slide-progress/selectors';
import { ThomasActions } from '@maggie/store/thomas/actions';
import { UserSelectors } from '@maggie/store/user/selectors';
import { deepMap } from '@maggie/utils/deepMap';

import { Asset, isAsset } from '../asset';
import { LessonAttempt } from '../attempt/lesson-attempt';
import type { AttemptSlideInteraction } from '../attempt/types';
import { SlideScoring } from '../scoring/slide-scoring';
import { SocialLearningService } from '../social_learning/social_learning_service';
import { ThomasSocialLearningBridge } from '../thomas/thomas-social-learning-bridge';
import { ThomasPlayer } from './thomas-player';
import type { ConfigurationForThomas, ContextForThomas } from './thomas-player-interface';

export class LessonThomasPlayer extends ThomasPlayer {
  /**
   * !Note: DO NOT USE `lesson.configuration` directly.
   *
   * Instead, use `this.thomasAssets.getConfiguration()`
   */
  private readonly lesson: Readonly<LessonType>;
  private readonly attempt: Readonly<LessonAttempt>;
  private thomasSocialLearningBridge: ThomasSocialLearningBridge;

  constructor(lesson: LessonType, iFrameParent: string) {
    super(lesson.id, lesson.configuration, lesson.engine.thomasVersion, iFrameParent);
    this.lesson = lesson;
    this.attempt = this.createAttempt(lesson);
    this.thomasSocialLearningBridge = new ThomasSocialLearningBridge();
  }

  /**
   * This function looks up which slide index the lesson should be resumed from.
   *
   * There is some logic around interactive slides
   *    if is interactive slide behave differently depending if user has answered or not.
   *
   * The reason for this is that we wanna make resume based on when user clicks "Continue" in thomas slides.
   * But we don't have a specific event for when that happens.
   * So, depending on the type of slide we will have to behave differently.
   */
  private findSlideIndexForResume = () => {
    const slides = this.lesson.configuration.slides;

    // This logic is to find index of slide in relation to the lesson
    // The logic is basically to NOT DEPEND on BE to return "slide answers" in the correct order
    const lastAccessedSlideIndex = this.attempt.interactions.reduce((result, attemptSlide) => {
      const index = slides.findIndex(slide => slide.id === attemptSlide.id);
      if (index > result) {
        return index;
      }

      return result;
    }, -1);

    if (lastAccessedSlideIndex < 0) {
      return 0; // no attempt interactions - user starts from scratch
    }

    const slide = slides[lastAccessedSlideIndex];
    const isVideo = slide.type === 'video';
    if (isVideo) {
      // video slide - always resume from there (to avoid user skip watching the video)
      return lastAccessedSlideIndex;
    }

    const isInteractive = SlideScoring.getSlideWeight(slide) > 0;
    if (!isInteractive) {
      // not interactive && has already been viewed: resume from next one
      return lastAccessedSlideIndex + 1;
    }

    const attemptSlide = this.attempt.interactions.find(s => s.id === slide.id);
    const score = attemptSlide?.weightedScore;
    if (typeof score !== 'number' || isNaN(score)) {
      // interactive slide && not answered: user resumes from there
      return lastAccessedSlideIndex;
    } else if (lastAccessedSlideIndex + 1 === slides.length) {
      // interactive slide && answered && last one (lesson without exit slide): user resumes from there
      // (edge case)
      return lastAccessedSlideIndex;
    } else {
      // interactive slide && answered: user resumes from next slide
      return lastAccessedSlideIndex + 1;
    }
  };

  private createAttempt = (lesson: LessonType): LessonAttempt => {
    const attempt = LessonSelectors.getLessonAttempt(lesson.id)(window.__store.getState());
    if (!attempt) {
      // nothing to resume from
      return new LessonAttempt(lesson);
    }

    const interactions = attempt.slides.reduce(
      (res, s) => ({ ...res, [s.id]: s }),
      {} as DictionaryType<AttemptSlideInteraction>
    );
    return new LessonAttempt(lesson, attempt.attemptId, interactions);
  };

  /**
   * Returns the lesson configuration object.
   */
  private getLessonConfigurationForThomas = (config: ConfigurationType): ConfigurationForThomas => {
    let configuration = cloneDeep(config);

    if (!UserSelectors.hasSocialLearningEnabled(window.__store.getState().user)) {
      for (const slide of configuration.slides) {
        if (slide?.data?.socialLearning?.enabled) {
          slide.data.socialLearning.enabled = false;
        }
      }
    }

    configuration = deepMap(configuration, (value: any) =>
      isAsset(value) ? new Asset(value, 'lessons', this.lesson.id).remoteURL : value
    );

    return {
      ...configuration,
      // @ts-expect-error TODO: EDAPP-42821 Property 'scormEncodedData' is missing in type 'SlideProgressType' but required here
      userState: SlideProgressSelectors.getSlideProgressForThomas(this.lesson.id)(
        window.__store.getState()
      ),
      config: {
        ...configuration.styleConfiguration,
        contextAllowsFreeNavigation: false
      },
      title: configuration.title ? configuration.title : this.lesson.title
    };
  };

  /**
   * Returns dictionary information about each slide and their stars information
   */
  private getStarInfoForSlides = (): DictionaryType<SlideEarnedAvailableStarsType> => {
    const lessonSlidesProgress = SlideProgressSelectors.getForLesson(this.lesson.id)(
      window.__store.getState()
    );
    const slidesProgress = lessonSlidesProgress ? lessonSlidesProgress.items : {};

    const starInfoBySlideId: Record<string, SlideEarnedAvailableStarsType> = {};

    for (const slide of this.lesson.configuration.slides) {
      if (!slide.data.challenge_configuration) {
        continue;
      }

      const totalStars =
        slide.data.challenge_configuration.nb_stars ?? slide.data.challenge_configuration.weighting;
      const earnedStars = slidesProgress[slide.id]?.earnedStars || 0;
      starInfoBySlideId[slide.id] = {
        earned: earnedStars,
        available: totalStars - earnedStars
      };
    }

    return starInfoBySlideId;
  };

  protected getContextForThomas = (): ContextForThomas => {
    const state = window.__store.getState();
    const enableStars = LessonSelectors.hasStarsEnabled(this.lesson)(state);
    const configuration = this.thomasAssets.getConfiguration();

    return {
      slidedeck: {
        id: this.lesson.id,
        userId: UserSelectors.getId(state),
        email: UserSelectors.getEmail(state),
        firstName: UserSelectors.getFirstName(state),
        lastName: UserSelectors.getLastName(state),
        name: UserSelectors.getName(state),
        stars: enableStars ? this.getStarInfoForSlides() : {},
        disableAnswerFeedback: !configuration?.styleConfiguration?.hasAnswerFeedback,
        enableStars,
        alreadyCompleted: this.lesson.progress && this.lesson.progress.completed,
        isScored: LessonSelectors.isScored(this.lesson),
        platform: Platform.get(),
        attempt: this.attempt.attemptId,
        config: this.getLessonConfigurationForThomas(configuration),
        aiccURL: ENV.apiUrl('/aicc')
      },
      uploadConfiguration: {},
      downloadConfiguration: {
        CLOUDINARY_CDN_URL: ENV.CLOUDINARY_CDN_URL,
        CLOUDINARY_URL: ENV.CLOUDINARY_URL,
        CLOUDINARY_VIDEO_FOLDER: ENV.CLOUDINARY_VIDEO_FOLDER,
        CLOUDINARY_IMAGE_FOLDER: ENV.CLOUDINARY_IMAGE_FOLDER,
        CLOUDINARY_IMAGE_MOBILE_TRANSFORMATION: ENV.CLOUDINARY_IMAGE_MOBILE_TRANSFORMATION,
        CLOUDINARY_IMAGE_DESKTOP_TRANSFORMATION: ENV.CLOUDINARY_IMAGE_DESKTOP_TRANSFORMATION
      },
      featureFlags: pick(FeatureFlagsSelectors.getFlagsEnabled(state), [])
    };
  };

  protected completed = () => {
    super.completed();
    this.attempt.finishAttempt();
  };

  /**
   * @throws error if it does not successfully open
   */
  public open = async () => {
    // send user analytics
    itly.lessonStarted({
      id: this.lesson.id,
      attempt_id: this.attempt.attemptId,
      attempt_slide_completed_count: this.attempt.interactions.length
    });

    this.attempt.startAttempt();
    this.attempt.startRecording(this.lesson.id);

    window.__store.dispatch(
      LessonActions.updateLessonOpened(
        this.lesson.id,
        this.lesson.courseVersionNumber,
        this.attempt.attemptId
      )
    );

    await super.open();

    // set navigation based on current state attempt interactions
    const slideIndexForResume = this.findSlideIndexForResume();
    if (slideIndexForResume > 0) {
      window.__store.dispatch(ThomasActions.setLastCompletedSlideIndex(slideIndexForResume - 1));
      this.goToPage(slideIndexForResume);
    }

    this.thomasBridge.registerStarListener();

    if (SocialLearningService.isSocialLearningEnabled()) {
      this.thomasSocialLearningBridge.registerSocialLearningEvents(this.lesson.id);
    }
  };

  public close = async () => {
    await super.close();

    this.attempt.stopRecording(this.lesson.id);

    const progress = LessonSelectors.getLessonProgress(this.lesson.id, window.__store.getState());
    itly.lessonClosed({
      id: this.lesson.id,
      attempt_id: this.attempt.attemptId,
      has_seen_last_slide: this.attempt.hasSeenLastOrExitSlide(),
      time_spent: (this.attempt.endedAt || Date.now()) - this.attempt.startedAt,
      interaction_type: progress?.completed ? 'Reattempt' : 'First attempt'
    });

    this.thomasSocialLearningBridge.destroySocialLearningListeners(this.lesson.id);

    StatusBarManager.show();

    window.__store.dispatch(LessonActions.closeLesson(this.lesson.id, this.attempt.successful));
  };

  public goToPage(pageIndex: number) {
    if (pageIndex >= this.lesson.configuration.slides.length) {
      ErrorLogger.captureEvent('Overflow! navigation to slide', 'error', {
        pageIndex,
        slideCount: this.lesson.configuration.slides.length
      });
      return; // overflow! do nothing
    }

    this.thomasBridge.goToPage(pageIndex);
  }

  public toggleHeader(mode: 'desktop' | 'mobile') {
    this.thomasBridge.toggleHeader(mode);
  }
}
