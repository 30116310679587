import { takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';

import { SessionActionTypes } from '../actions';
import type { SessionAction } from '../actions';

function* handleLoginFailure(action: SessionAction<SessionActionTypes.LOGIN_FAILURE>) {
  const { error, from } = action.payload;
  itly.failedLogin({ value: error?.code, from });
}

export function* watchLoginFailure() {
  yield takeLatest(SessionActionTypes.LOGIN_FAILURE, handleLoginFailure);
}
