import { put, select } from 'redux-saga/effects';

import { ObservationActions } from '@maggie/store/courseware/observations/actions';
import { initialObservationObject } from '@maggie/store/courseware/observations/constants';
import { ObservationSelectors } from '@maggie/store/courseware/observations/selectors';
import type { ObservationType } from '@maggie/store/courseware/observations/types';

export function* handleDidNavigateToObservationFeedback(observationId: string) {
  const selectedObservation: ObservationType = yield select(
    ObservationSelectors.getSelectedObservation
  );

  // Only run if selected observation doesn't exist
  if (selectedObservation.id === initialObservationObject.id) {
    yield put(ObservationActions.fetchObservation(observationId));
  }
}
