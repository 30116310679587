import { put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';

import { RegisterActionsTypes } from './actions';
import type { RegisterAction } from './types';

function* handleFetchInviteCodeSettings(
  action: RegisterAction<RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS>
) {
  const { applicationId } = action.payload;

  yield put(
    RequestActions.postUnauthed(
      Urls.INVITE_SETTINGS,
      RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_SUCCESS,
      RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS_FAILURE,
      undefined,
      { applicationId, locale: window.__l10n.language }
    )
  );
}

export function* watchFetchInviteCodeSettings() {
  yield takeLatest(RegisterActionsTypes.FETCH_INVITE_CODE_SETTINGS, handleFetchInviteCodeSettings);
}
