import { createAction } from '@edapp/utils';
import type { PlaylistNextItemType } from './types';

enum PlaylistsActionTypes {
  FETCH_PLAYLISTS = 'FETCH_PLAYLISTS',
  FETCH_PLAYLISTS_SUCCESS = 'FETCH_PLAYLISTS_SUCCESS',
  FETCH_PLAYLISTS_FAILURE = 'FETCH_PLAYLISTS_FAILURE',
  FETCH_PLAYLISTS_COMPLETED = 'FETCH_PLAYLISTS_COMPLETED',

  FETCH_PLAYLIST_ITEM = 'FETCH_PLAYLIST_ITEM',
  FETCH_PLAYLIST_ITEM_SUCCESS = 'FETCH_PLAYLIST_ITEM_SUCCESS',
  FETCH_PLAYLIST_ITEM_FAILURE = 'FETCH_PLAYLIST_ITEM_FAILURE',

  FETCH_PLAYLIST_ITEM_WITH_PROGRESS = 'FETCH_PLAYLIST_ITEM_WITH_PROGRESS',
  FETCH_PLAYLIST_ITEM_WITH_PROGRESS_SUCCESS = 'FETCH_PLAYLIST_ITEM_WITH_PROGRESS_SUCCESS',
  FETCH_PLAYLIST_ITEM_WITH_PROGRESS_FAILURE = 'FETCH_PLAYLIST_ITEM_WITH_PROGRESS_FAILURE',

  FETCH_PLAYLIST_PROGRESSES = 'FETCH_PLAYLIST_PROGRESSES',
  FETCH_PLAYLIST_PROGRESSES_SUCCESS = 'FETCH_PLAYLIST_PROGRESSES_SUCCESS',
  FETCH_PLAYLIST_PROGRESSES_FAILURE = 'FETCH_PLAYLIST_PROGRESSES_FAILURE',

  UPDATE_PLAYLIST_NEXT_ITEM = 'UPDATE_PLAYLIST_NEXT_ITEM',
  UPDATE_PLAYLIST_NEXT_ITEM_SUCCESS = 'UPDATE_PLAYLIST_NEXT_ITEM_SUCCESS'
}

const PlaylistsActions = {
  fetchPlaylists: () => createAction(PlaylistsActionTypes.FETCH_PLAYLISTS),
  fetchPlaylistsCompleted: () => createAction(PlaylistsActionTypes.FETCH_PLAYLISTS_COMPLETED),
  fetchPlaylistItem: (id: string) => createAction(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM, { id }),
  fetchPlaylistItemWithProgress: (id: string) =>
    createAction(PlaylistsActionTypes.FETCH_PLAYLIST_ITEM_WITH_PROGRESS, { id }),
  fetchPlaylistProgresses: (ids: string[]) =>
    createAction(PlaylistsActionTypes.FETCH_PLAYLIST_PROGRESSES, { ids }),
  updatePlaylistNextItem: (id: string) =>
    createAction(PlaylistsActionTypes.UPDATE_PLAYLIST_NEXT_ITEM, { id }),
  updatePlaylistNextItemSuccess: (id: string, nextItem: PlaylistNextItemType) =>
    createAction(PlaylistsActionTypes.UPDATE_PLAYLIST_NEXT_ITEM_SUCCESS, { id, nextItem })
};

export { PlaylistsActions, PlaylistsActionTypes };
