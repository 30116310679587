import { all, fork, put, select, takeLatest } from 'redux-saga/effects';

import { Platform } from '@maggie/cordova/platform';
import { BackboneActionTypes } from '@maggie/store/backbone/actions';
import { LessonActionTypes } from '@maggie/store/courseware/lessons/actions';
import { SessionActionTypes } from '@maggie/store/session/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { LocalNotificationActions } from './actions';
import { DEBUG_LOCAL_NOTIFICATIONS, DEFAULT_NOTIFICATION_TIME } from './constants';
import { watchScheduleLessonNotifications } from './schedulers/schedule-lesson-sagas';
import { watchSchedulePrizingNotifications } from './schedulers/schedule-prizing-sagas';

if (DEBUG_LOCAL_NOTIFICATIONS) {
  /* istanbul ignore next */
  console.warn('Local Notification Debug Mode is Enabled!');
}

function getNotificationTime(time?: string) {
  const [hour, minute] = time?.split(':').map(d => parseInt(d, 10)) || [];
  return [hour, minute];
}

function* handleScheduleUpcomingNotifications() {
  const enabled: ReturnType<typeof UserSelectors.hasLocalNotificationsEnabled> = yield select<
    LxStoreState
  >(s => UserSelectors.hasLocalNotificationsEnabled(s.user));

  if (!enabled) {
    // Only schedule if account has notifications enabled
    return;
  }

  const lessonNotificationTime: [number, number] = yield select<LxStoreState>(s =>
    getNotificationTime(
      UserSelectors.getLessonNotificationTime(s.user) || DEFAULT_NOTIFICATION_TIME
    )
  );
  const prizingNotificationTime: [number, number] = yield select((s: LxStoreState) =>
    getNotificationTime(
      UserSelectors.hasPrizingNotificationsEnabled(s.user) || DEFAULT_NOTIFICATION_TIME
    )
  );

  yield all([
    // We are removing BB notifications in BB v2 work, ignore for now

    put(LocalNotificationActions.scheduleLessonNotifications(...lessonNotificationTime)),
    put(LocalNotificationActions.schedulePrizingNotifications(...prizingNotificationTime))
  ]);
}

export const LISTEN_TO_ACTIONS = [
  BackboneActionTypes.APPLICATION_RESUME,
  LessonActionTypes.UPDATE_LESSON_COMPLETED,
  SessionActionTypes.SESSION_LOGIN_SUCCESS
];
export function* watchScheduleUpcomingNotifications() {
  const platform = Platform.get();
  if (platform === 'Android' || platform === 'iOS' || DEBUG_LOCAL_NOTIFICATIONS) {
    yield takeLatest(LISTEN_TO_ACTIONS, handleScheduleUpcomingNotifications);
  }
}

export const localNotificationSagas = [
  fork(watchScheduleUpcomingNotifications),
  fork(watchScheduleLessonNotifications),
  fork(watchSchedulePrizingNotifications)
];
