import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { routes as authoringRoutes } from '@edapp/authoring-logic';
import { useCourseListContext } from '@edapp/course-list/src/context/CourseListContext';
import { useBreakpointMatchMedia } from '@edapp/ed-components';
import { useImportCourses } from '@edapp/hippo-client';
import { ErrorLogger } from '@edapp/monitoring';
import { Link, useToast } from '@edapp/sc-web-ui';

type Props = {
  showSuccessToast?: boolean;
  onImportCourseSuccess?: (newCourseId: string) => void;
};

export const useImportCoursesFromCourseLibrary = ({
  showSuccessToast = true,
  onImportCourseSuccess
}: Props) => {
  const { t } = useTranslation('translation');
  const { refetch } = useCourseListContext();
  const { addToast } = useToast();
  const history = useHistory();
  const isMobile = useBreakpointMatchMedia('sm', 'max-width');

  const { mutateAsync: sendImportCoursesRequest, isLoading, isSuccess } = useImportCourses({
    onSuccess: data => {
      refetch?.();

      const courseId = data?.[0]?.newCourseId; // only support importing one course as of now
      if (!courseId) return;

      if (showSuccessToast) {
        const link = authoringRoutes.allInOneEditor.getRoute({ courseId });
        addToast({
          mode: 'positive',
          message: t('content-library.course-imported') || '',
          action: (
            <StyledLink onClick={() => history.push(link)}>
              {isMobile
                ? t('content-library.import.view-course')
                : t('content-library.import.open-in-editor')}
            </StyledLink>
          )
        });
      }

      onImportCourseSuccess?.(courseId);
    },
    onError: (error, variables) => {
      addToast({ mode: 'negative', message: t('common.something-went-wrong') || '' });
      ErrorLogger.captureException(new Error(JSON.stringify(error)), {
        selectedCourseIds: variables?.courseIds
      });
    }
  });

  return { sendImportCoursesRequest, isLoading, isSuccess };
};

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
`;
