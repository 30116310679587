import type { LxStoreState } from '@maggie/store/types';

import type { SearchEntityType } from './types';

/**
 * @returns `true` if matches lastSearchItem and navigated to back to search
 */
const shouldGoBackToSearch = (id: string, type: SearchEntityType, state: LxStoreState) => {
  const { lastSearchItemSelected, isOpen } = state.search;
  if (
    !lastSearchItemSelected ||
    !isOpen ||
    state.navigation.route === 'home' ||
    state.navigation.route === 'search'
  ) {
    return false;
  }

  const shouldGoBack = lastSearchItemSelected.id === id && lastSearchItemSelected.type === type;
  if (shouldGoBack) {
    return true;
  }

  return false;
};

export const SearchUtils = {
  shouldGoBackToSearch
};
