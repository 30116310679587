import type { LxStoreState } from '@maggie/store/types';

import type { PrizesState } from './types';

const getUpcomingPrizes = (state: LxStoreState): PrizesState => {
  const gameId = state.game.lottery.starBar.gameId;
  const prizes = state.game.prizes;

  if (gameId === 'AUCTION') {
    return prizes
      .filter(p => new Date(p.end) > new Date())
      .sort((a, b) => {
        const prizeADate = new Date(a.end);
        const prizeBDate = new Date(b.end);
        if (prizeADate < prizeBDate) {
          return -1;
        }
        if (prizeADate > prizeBDate) {
          return 1;
        }
        return 0;
      });
  } else {
    return prizes;
  }
};

export const GamePrizesSelectors = {
  getUpcomingPrizes
};
