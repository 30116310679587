import { StarActionTypes } from '@maggie/store/star/actions';
import type { StarActionUnionType } from '@maggie/store/star/types';

import { SlideProgressActionTypes } from './actions';
import type { SlideProgressState, SlideProgressType, SlideProgressUnionType } from './types';

export const initialSlideProgressState: SlideProgressState = {};

type UpdateFuncType = (slideProgress: SlideProgressType) => SlideProgressType;

const updateSingleProgress = (
  lessonId: string,
  slideId: string,
  state: SlideProgressState,
  updateFunc: UpdateFuncType
): SlideProgressState => {
  let createdNewSlideProgress = false;

  // create a slide progress if none exists
  let slideProgressesForLesson = state[lessonId];
  if (slideProgressesForLesson == null) {
    slideProgressesForLesson = { totalCount: 0, items: {} };
  }

  let slideProgress: SlideProgressType = slideProgressesForLesson.items[slideId];
  if (slideProgress == null) {
    slideProgress = {
      earnedStars: 0,
      lessonId,
      slideId
    };
    createdNewSlideProgress = true;
  }

  slideProgress = updateFunc(slideProgress);

  return {
    ...state,
    [lessonId]: {
      ...state[lessonId],
      totalCount: createdNewSlideProgress
        ? slideProgressesForLesson.totalCount + 1
        : slideProgressesForLesson.totalCount,
      items: {
        ...slideProgressesForLesson.items,
        [slideId]: slideProgress
      }
    }
  };
};

export const slideProgressReducer = (
  state: SlideProgressState = initialSlideProgressState,
  action: SlideProgressUnionType | StarActionUnionType
): SlideProgressState => {
  switch (action.type) {
    case SlideProgressActionTypes.FETCH_SLIDE_PROGRESS_SUCCESS: {
      const { payload } = action;

      const slidesProgressByLessonId: SlideProgressState = payload.items.reduce<SlideProgressState>(
        (acc, slideProgress) => {
          acc[slideProgress.lessonId] = acc[slideProgress.lessonId] || {
            totalCount: payload.totalCount,
            items: {}
          };
          acc[slideProgress.lessonId].items[slideProgress.slideId] = slideProgress;
          return acc;
        },
        {}
      );

      return {
        ...state,
        ...slidesProgressByLessonId
      };
    }

    case SlideProgressActionTypes.UPDATE_SLIDE_STATE: {
      const { lessonId, slideId, slideState } = action.payload;

      return updateSingleProgress(lessonId, slideId, state, progress => ({
        ...progress,
        contentEngineState: slideState
      }));
    }

    case StarActionTypes.REWARD_STARS_FROM_SLIDE: {
      const { lessonId, slideId, stars } = action.payload;

      return updateSingleProgress(lessonId, slideId, state, progress => ({
        ...progress,
        earnedStars: progress.earnedStars + stars
      }));
    }

    case SlideProgressActionTypes.CLEAR_ALL_SLIDE_PROGRESS: {
      return {};
    }

    default:
      return state;
  }
};
