import {
  endOfDay,
  intervalToDuration,
  isBefore,
  isWithinInterval,
  parseISO,
  startOfDay
} from 'date-fns';
import * as _ from 'lodash';

import { removeUTCOffset } from '@edapp/utils';
import { ENV } from '@maggie/config/env';
import { Asset } from '@maggie/core/lessons/asset';
import colors from '@maggie/theme/colors';

import type { CollectionType } from './collections/types';
import type { CourseProgressType, CourseType, LessonSummaryType } from './courses/types';
import type { LessonProgressType, LessonType } from './lessons/types';
import type { StyleType } from './types';
import { CoursewareTypeNames } from './types';

export const DEFAULT_BACKGROUND_COLOR = colors.cardBlue;
export const DEFAULT_BRANDING_TEXT_COLOR = 'black';
export const DEFAULT_TEXT_COLOR = 'white';

const isClientAhead = (
  client: CourseProgressType | LessonProgressType | undefined | null,
  server: CourseProgressType | LessonProgressType,
  key: 'unlockedDate' | 'completedDate' | 'openedDate'
): boolean => {
  const clientDate = client?.[key];
  if (!clientDate) {
    return false;
  }

  const serverDate = server?.[key];
  if (!serverDate) {
    return true;
  }

  return isBefore(parseISO(serverDate), parseISO(clientDate));
};

const isLessonInAvailableDates = (
  startDateTime: string | undefined,
  endDateTime: string | undefined
): boolean => {
  if (!startDateTime || !endDateTime) {
    return true;
  }
  return isInLocalDateRange(startDateTime, endDateTime);
};

// Courses currently have Access Start Date deprecated
// So a course can have an endDate without a startDate
// Older courses still have previously set access start dates.
const isCourseInAvailableDates = (
  startDateTime: string | undefined,
  endDateTime: string | undefined
): boolean => {
  if (!endDateTime) {
    return true;
  }
  const defaultStartTime = new Date(0).toISOString(); // beginning of all time
  return isInLocalDateRange(startDateTime || defaultStartTime, endDateTime);
};

// Check if current time is between local start of day 'StartDate' and end of day 'EndDate'
const isInLocalDateRange = (startDate: string, endDate: string) => {
  try {
    // Parse the UTC server dates as if they were local dates
    const localStart = new Date(removeUTCOffset(startDate)!);
    const localEnd = new Date(removeUTCOffset(endDate)!);
    return isWithinInterval(new Date(), { start: startOfDay(localStart), end: endOfDay(localEnd) });
  } catch (err) {
    // RangeError: Invalid interval
    // this is possible if user has start date ahead of end date
    return false;
  }
};

const getDeviceType = () => {
  if (window.device && window.device.platform) {
    return window.device.platform;
  }
  if (/iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent)) {
    return 'browser-device';
  } else {
    return 'browser-desktop';
  }
};

const isAvailableForPlatform = (data?: LessonSummaryType | LessonType, platform?: string) => {
  if (!data) {
    return false;
  }

  if (!platform) {
    platform = getDeviceType();
  }

  if ((data.platformsAvailable || []).length === 0) {
    return true;
  }

  return data.platformsAvailable.includes(platform);
};

const preProcessBrandingImage = (url: string): string => {
  // this is to handle the 3 cases of image URL formats
  // we are currently storing on the server (see BrandingContent.tsx in rio for a more detailed comment)
  const processedUrl = ENV.imageUrl(url);
  return processedUrl;
};

type StyleObjectArgs = {
  brandingImage?: string;
  brandingTextColor?: 'white' | 'black';
  colors?: {
    text?: 'white' | 'black';
    background?: string;
  };
};
const getBrandingStyleObject = (data: StyleObjectArgs): StyleType => {
  if (!!data && !!data.brandingImage) {
    return {
      text: data.brandingTextColor || DEFAULT_BRANDING_TEXT_COLOR,
      background: {
        image: `url(${preProcessBrandingImage(data.brandingImage)})`
      }
    };
  }

  return {
    text: !!data && !!data.colors && !!data.colors.text ? data.colors.text : DEFAULT_TEXT_COLOR,
    background: {
      color:
        !!data && !!data.colors && !!data.colors.background
          ? data.colors.background
          : DEFAULT_BACKGROUND_COLOR
    }
  };
};

const getCourseStyleObject = (course?: CourseType): StyleType => {
  const { styleConfiguration } = course || {};

  return {
    text: styleConfiguration?.colors?.text || DEFAULT_TEXT_COLOR,
    background: {
      image: styleConfiguration?.background ? `url("${styleConfiguration.background}")` : undefined,
      color: styleConfiguration?.colors?.background || DEFAULT_BACKGROUND_COLOR
    }
  };
};

const getBackgroundAssetUrl = (lesson?: LessonType | LessonSummaryType | null) => {
  if (!lesson) {
    return '';
  }
  const background =
    lesson.typeName === CoursewareTypeNames.LESSON_SUMMARY
      ? lesson.styleConfiguration?.background
      : lesson.configuration.styleConfiguration.background;
  const lessonId =
    lesson.typeName === CoursewareTypeNames.LESSON_SUMMARY ? lesson.lessonId : lesson.id;

  const backgroundAsset = background
    ? new Asset(background, 'lessons', lessonId).remoteURL
    : undefined;
  return backgroundAsset;
};

const getLessonStyleObject = (lesson?: LessonType | LessonSummaryType | null): StyleType => {
  const styleColors =
    lesson?.typeName === CoursewareTypeNames.LESSON_SUMMARY
      ? lesson.styleConfiguration?.colors
      : lesson?.configuration.styleConfiguration.colors;
  const backgroundUrl = getBackgroundAssetUrl(lesson);
  return {
    text: styleColors?.text ?? 'black',
    background: {
      color: styleColors ? styleColors.background : '',
      image: backgroundUrl ? `url(${backgroundUrl})` : ''
    }
  };
};

const isManualCourseCollection = (data: CollectionType) => {
  return !!data.isManual || data.numberOfCourse > 1;
};

const getCSSProp = (style: StyleType, prop: 'text' | 'background'): string => {
  const styleProp = style[prop];
  if (!styleProp) {
    return '';
  }

  switch (prop) {
    case 'text':
      return `color: ${styleProp};`;

    case 'background':
      let background = '';
      const stylePropsObj = styleProp as StyleType['background']
      for (const key of _.keys(styleProp)) {
        if (!!styleProp) {
          background += `background-${key}: ${stylePropsObj[key as keyof StyleType['background']]}; `;
        }
      }
      return background;

    default:
      return '';
  }
};

/*
  If hours present -> h:mm:ss
    minutes will be padded with zero ad hoc, e.g. 1:01:00
  Otherwise -> m:ss
  Will not handle time >24h
 */
const formatSeconds = (s: number): string => {
  const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: s * 1000 });
  const hr = hours ? `${hours}:` : '';
  const min = !!hr && String(minutes).length === 1 ? String(minutes).padStart(2, '0') : minutes;
  const sec = String(seconds).length === 1 ? String(seconds).padStart(2, '0') : seconds;
  return `${hr}${min}:${sec}`;
};

const CoursewareUtils = {
  isAvailableForPlatform,
  isLessonInAvailableDates,
  isCourseInAvailableDates,
  isClientAhead,
  getBrandingStyleObject,
  getCourseStyleObject,
  getLessonStyleObject,
  isManualCourseCollection,
  getCSSProp,
  preProcessBrandingImage,
  formatSeconds
};

export { CoursewareUtils };
