import { ErrorCode } from '@edapp/request';
import { getErrorMessage } from '@maggie/store/request/errors';

import { WatchStatus } from '../types';
import type { AssessmentActionsUnionType } from './actions';
import { AssessmentActionTypes } from './actions';
import { initialAssessmentState } from './constants';
import type { AssessmentsState } from './types';
import { AssessmentStatus } from './types';

const assessmentReducer = (
  state: AssessmentsState = initialAssessmentState,
  action: AssessmentActionsUnionType
): AssessmentsState => {
  switch (action.type) {
    case AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS: {
      return { ...state, fetchingProgressLoading: true, fetchingProgressError: '' };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS_SUCCESS: {
      const { assessmentId, progress } = action.payload;

      return {
        ...state,
        fetchingProgressLoading: false,
        fetchingProgressError: '',
        progress: {
          ...state.progress,
          [assessmentId]: progress
        }
      };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_PROGRESS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingProgressLoading: false, fetchingProgressError: message };
    }

    case AssessmentActionTypes.FETCH_ASSESSMENT: {
      return {
        ...state,
        fetchingAssessmentLoading: true,
        fetchingAssessmentError: '',
        fetchingAssessmentErrorCode: undefined
      };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_SUCCESS: {
      const assessment = action.payload;
      return {
        ...state,
        fetchingAssessmentLoading: false,
        fetchingAssessmentError: '',
        fetchingAssessmentErrorCode: undefined,
        assessments: {
          ...state.assessments,
          [assessment.id]: assessment
        }
      };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      const errorCode = action.payload?.code;
      return {
        ...state,
        fetchingAssessmentLoading: false,
        fetchingAssessmentError: message,
        fetchingAssessmentErrorCode: errorCode
      };
    }

    case AssessmentActionTypes.FETCH_ASSESSMENT_POSTS: {
      return { ...state, fetchingPostsLoading: true, fetchingPostsError: '' };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POSTS_SUCCESS: {
      const { page, assessmentId, postsData } = action.payload;
      return {
        ...state,
        fetchingPostsLoading: false,
        fetchingPostsError: '',
        posts: {
          ...state.posts,
          [assessmentId]: {
            ...state.posts[assessmentId],
            totalCount: postsData.totalCount,
            items:
              page === 1
                ? [...postsData.items]
                : [...(state.posts[assessmentId]?.items || []), ...postsData.items]
          }
        }
      };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POSTS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingPostsLoading: false, fetchingPostsError: message };
    }

    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS: {
      return { ...state, fetchingPostCommentsLoading: true, fetchingPostCommentsError: '' };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS_SUCCESS: {
      const { page, postId, postComments } = action.payload;

      return {
        ...state,
        fetchingPostCommentsLoading: false,
        fetchingPostCommentsError: '',
        postComments: {
          ...state.postComments,
          [postId]: {
            ...state.postComments[postId],
            totalCount: postComments.totalCount,
            items:
              page === 1
                ? [...postComments.items]
                : [...(state.postComments[postId]?.items || []), ...postComments.items]
          }
        }
      };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_COMMENTS_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingPostCommentsLoading: false, fetchingPostCommentsError: message };
    }

    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM: {
      return { ...state, fetchingPostItemLoading: true, fetchingPostItemError: '' };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM_SUCCESS: {
      const { assessmentId, post } = action.payload;
      return {
        ...state,
        fetchingPostItemLoading: false,
        fetchingPostItemError: '',
        posts: {
          ...state.posts,
          [assessmentId]: {
            totalCount: state.posts[assessmentId]?.totalCount || 1,
            items: [
              // existing posts less the new one that's coming through
              ...(state.posts[assessmentId]?.items || []).filter(i => i.id !== post.id),
              post
            ]
          }
        }
      };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_ITEM_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingPostItemLoading: false, fetchingPostItemError: message };
    }

    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK: {
      return { ...state, fetchingFeedbackLoading: true, fetchingFeedbackError: '' };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK_SUCCESS: {
      const { postId, feedback } = action.payload;

      return {
        ...state,
        fetchingFeedbackLoading: false,
        fetchingFeedbackError: '',
        feedback: {
          ...state.feedback,
          [postId]: feedback
        }
      };
    }
    case AssessmentActionTypes.FETCH_ASSESSMENT_POST_FEEDBACK_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, fetchingFeedbackLoading: false, fetchingFeedbackError: message };
    }

    case AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION: {
      return { ...state, editPostSubscriptionLoading: true, editPostSubscriptionError: '' };
    }
    case AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION_SUCCESS: {
      const { assessmentId, postId, isWatching } = action.payload;
      const watchingStatus = isWatching ? WatchStatus.WATCHING : WatchStatus.NOT_WATCHING;

      return {
        ...state,
        editPostSubscriptionLoading: false,
        editPostSubscriptionError: '',
        posts: {
          ...state.posts,
          [assessmentId]: {
            ...state.posts[assessmentId],
            totalCount: state.posts[assessmentId]?.totalCount || 0,
            items: [
              ...(state.posts[assessmentId]?.items || []).map(post => {
                if (postId === post.id) {
                  return { ...post, watchingStatus };
                }
                return post;
              })
            ]
          }
        }
      };
    }
    case AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUBSCRIPTION_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, editPostSubscriptionLoading: false, editPostSubscriptionError: message };
    }

    case AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT: {
      return { ...state, savingPostCommentError: '', savingPostCommentLoading: true };
    }
    case AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT_SUCCESS: {
      const { postId, postComment } = action.payload;
      return {
        ...state,
        savingPostCommentLoading: false,
        savingPostCommentError: '',
        postComments: {
          ...state.postComments,
          [postId]: {
            ...state.postComments[postId],
            totalCount: (state.postComments[postId]?.totalCount || 0) + 1,
            items: [...(state.postComments[postId]?.items || []), postComment]
          }
        }
      };
    }
    case AssessmentActionTypes.SAVE_ASSESSMENT_POST_COMMENT_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, savingPostCommentLoading: false, savingPostCommentError: message };
    }

    case AssessmentActionTypes.SAVE_ASSESSMENT_POST:
    case AssessmentActionTypes.EDIT_ASSESSMENT_POST: {
      return { ...state, savingPostLoading: true, savingPostCommentError: '' };
    }
    case AssessmentActionTypes.SAVE_ASSESSMENT_POST_SUCCESS:
    case AssessmentActionTypes.EDIT_ASSESSMENT_POST_SUCCESS: {
      const { assessmentId, post } = action.payload;
      return {
        ...state,
        savingPostLoading: false,
        savingPostError: '',
        posts: {
          ...state.posts,
          [assessmentId]: {
            ...state.posts[assessmentId],
            totalCount: state.posts[assessmentId]?.totalCount || 0,
            items: [post, ...(state.posts[assessmentId]?.items || [])]
          }
        },
        // optimistically update assessment progress
        progress: {
          ...state.progress,
          [assessmentId]: {
            status: AssessmentStatus.SUBMITTED,
            postId: post.id,
            hasPosted: true,
            hasCommented: state.progress[assessmentId]?.hasCommented ?? false
          }
        }
      };
    }
    case AssessmentActionTypes.SAVE_ASSESSMENT_POST_FAILURE:
    case AssessmentActionTypes.EDIT_ASSESSMENT_POST_FAILURE: {
      const message = action.payload?.message || getErrorMessage(ErrorCode.NullResponse);
      return { ...state, savingPostLoading: false, savingPostError: message };
    }

    case AssessmentActionTypes.CLEAR_ASSESSMENT_POSTS: {
      const { assessmentId } = action.payload;

      const newPosts = { ...state.posts };
      delete newPosts[assessmentId];

      return { ...state, posts: { ...newPosts } };
    }

    default:
      return state;
  }
};

export { assessmentReducer };
